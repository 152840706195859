<template>
  <div>

    <div class=" loading-search" v-if="loading">
      <div class="fa-3x text-center">
        <i class="fas fa-circle-notch fa-spin text-success" style=""></i>
      </div>
    </div>
    <!-- BEGIN breadcrumb -->
    <ol class="breadcrumb float-xl-end">
      <li class="breadcrumb-item"><a href="javascript:;">Reportes</a></li>
      <li class="breadcrumb-item"><a href="javascript:;">Corresponsalia</a></li>
      <li class="breadcrumb-item active"><a href="javascript:;">Estación</a></li>
    </ol>

    <h1 class="page-header">Corresponsalia Estación <small></small></h1>



    <form @submit.prevent="search">
      <div class="row">
        <div class="col-md-8">
          <div class="form-group row">

            <div class="col-md-3">
              <label class="col-form-label">Fecha</label>
              <div class="input-group">
                <date-picker id="dateTimeInput" v-model="date" :config="options"></date-picker>
                <label for="dateTimeInput" class="input-group-text">
                  <i class="fa fa-calendar"></i>
                </label>
              </div>
            </div>



            <div class="col-md-3">
              <label class="col-form-label">Ciudad</label>
              <select class="form-select" @change="getEstaciones()" v-model="ciudadSelect"
                :disabled="deshabilitarCombos">
                <option v-for="ciudad in ciudades" :value="ciudad.Id" v-bind:key="ciudad.Id">
                  {{ ciudad.Nombre }}
                </option>
              </select>
            </div>
            <div class="col-md-3">
              <label class="col-form-label">Estación</label>
              <select class="form-select" v-model="estacionSelect" :disabled="deshabilitarCombos">

                <option v-for="estacion in estaciones" :value="estacion.NoEstacion" v-bind:key="estacion.NoEstacion">
                  {{ estacion.Nombre }}
                </option>
              </select>
            </div>


            <div class="col-md-3">
              <label class="col-form-label">Turno</label>
              <select v-model="turnoSelected.IdTurno" class="form-control">
                <option value="0">Todos</option>
                <option v-for="turno in turnos" :value="turno.IdTurno" v-bind:key="turno.IdTurno">
                  {{ turno.Descripcion }}
                </option>
              </select>
            </div>

          </div>
        </div>

        <div class="col-md-2">
          <button type="submit" class="btn btn-success me-1 mb-1" style="margin-top: 3.1em;">{{ textoBotonBuscar
            }}</button>
        </div>
        <div class="col-md-2" style="padding-top: 2.7em;">
          <!-- <download-excel
                  v-if="searchResult.length > 0"
                  class="btn btn-success rounded-pill mt-35"
                  :data="searchResult"
                  :fields="json_fields"
                  :worksheet="estacionSelect"
                  :name="`Corresponsalia ${date}.xls`"
                  
                  >
                    Descargar en Excel <i class="fas fa-file-excel fa-fw"></i>
                </download-excel>
                <button v-else disabled class="btn btn-success rounded-pill mt-35">
                    Descargar en Excel <i class="fas fa-file-excel fa-fw"></i>
                </button> -->
        </div>
      </div>
    </form>
    <div>

      <!-- <div>
                <div v-for="(group, Fecha) in groupedObjects" :key="Fecha">
                <h2>{{ Fecha }}</h2>
                <ul>
                    <li v-for="obj in group" :key="obj.NoEstacion">{{ obj.NoEstacion }}</li>
                </ul>
                </div>
            </div> -->



      <panel title="Corresponsalia Estación" bodyClass="p-0" class="" style="margin-top: 15px;">




        <DxDataGrid id="gridContainer" :data-source="searchResult" key-expr="Transaccion" :show-borders="true"
          @exporting="onExporting" :show-column-lines="false" :show-row-lines="false" :row-alternation-enabled="true"
          :column-width="120">

          <DxScrolling column-rendering-mode="virtual" />
          <DxPager :show-page-size-selector="true" :allowed-page-sizes="[10, 20, 50, 100]"
            :show-navigation-buttons="true" />
          <DxPaging :page-size="10" :page-index="1" />
          <DxFilterRow />
          <DxHeaderFilter :visible="showHeaderFilter" />
          <DxSearchPanel :visible="true" :width="240" placeholder="Search..." />

          <DxGroupPanel :visible="true" />
          <DxGrouping :auto-expand-all="true" />
          <DxExport :enabled="true" :allow-export-selected-data="false" />
          <DxSelection mode="false" />


          <!-- <DxColumn dataField="Abrir" caption="Abrir" :cellTemplate="enviarCGButton" /> -->
          <DxColumn caption="Acciones" width="80" cell-template="delete" />
          <template #delete="{ data }">
            <div>
              <i class="fas fa-lg fa-fw fa-trash iconColor" :data-dt="data" v-on:click="delTicket(data.data)"></i>
            </div>
          </template>


          <DxColumn data-field="Transaccion" caption="Transacción">
            <DxHeaderFilter :allow-search="true" />
          </DxColumn>

          <DxColumn data-field="NoEmpleado" caption="No Empleado" />
          <DxColumn data-field="Nombre" caption="Nombre" width="200" />
          <DxColumn data-field="Turno" caption="Turno" dataType="text" width="80" />
          <DxColumn data-field="Fecha" caption="Fecha" dataType="date" width="80" />
          <DxColumn data-field="Hora" caption="Hora" dataType="text" width="80" />
          <DxColumn data-field="Bomba" caption="Bomba" dataType="text" width="80" />
          <DxColumn data-field="ImporteDespacho" caption="Importe Despacho" dataType="number">
            <DxFormat type="currency" :precision="2" />
          </DxColumn>
          <DxColumn data-field="ImporteUSD" caption="Importe USD" dataType="number">
            <DxFormat type="currency" :precision="2" />
          </DxColumn>
          <DxColumn data-field="DolaresRecibidos" caption="Dólares Recibidos" dataType="number">
            <DxFormat type="currency" :precision="2" />
          </DxColumn>
          <DxColumn data-field="TipoCambio" caption="Tipo de Cambio" dataType="number">
            <DxFormat type="currency" :precision="2" />
          </DxColumn>
          <DxColumn data-field="CambioUSD" caption="Cambio USD" dataType="number">
            <DxFormat type="currency" :precision="2" />
          </DxColumn>
          <DxColumn data-field="CambioMXN" caption="Cambio MXN" dataType="number">
            <DxFormat type="currency" :precision="2" />
          </DxColumn>
          <DxColumn data-field="FechaImpresion" caption="Fecha Impresion" dataType="date" width="150" />






          <DxSummary>






            <DxTotalItem column="ImporteDespacho" summary-type="sum" value-format="$#,##0.##" displayFormat='{0}'>
            </DxTotalItem>
            <DxTotalItem column="ImporteUSD" summary-type="sum" value-format="$#,##0.##" displayFormat='{0}'>
            </DxTotalItem>
            <DxTotalItem column="DolaresRecibidos" summary-type="sum" value-format="$#,##0.##" displayFormat='{0}'>
            </DxTotalItem>


            <DxTotalItem column="CambioUSD" summary-type="sum" value-format="$#,##0.##" displayFormat='{0}'>
            </DxTotalItem>
            <DxTotalItem column="CambioMXN" summary-type="sum" value-format="$#,##0.##" displayFormat='{0}'>
            </DxTotalItem>

          </DxSummary>

        </DxDataGrid>




      </panel>


    </div>

    <!-- bottom left vue-notification -->
    <notifications group="top-right" position="top right" :speed="1000" />
  </div>
</template>

<script>

import axios from 'axios';
import moment from 'moment';

import {
  DxDataGrid, DxFormat, DxSummary, DxTotalItem,
  DxPaging, DxPager, DxScrolling, DxColumn, DxExport, DxSelection, DxGroupPanel, DxGrouping, DxHeaderFilter, DxFilterRow, DxSearchPanel,
} from 'devextreme-vue/data-grid';
import { Workbook } from 'exceljs';
import { saveAs } from 'file-saver';
// Our demo infrastructure requires us to use 'file-saver-es'. We recommend that you use the official 'file-saver' package in your applications.
import { exportDataGrid } from 'devextreme/excel_exporter';



var numeral = require("numeral");
import Vue from "vue";

import JsonExcel from "vue-json-excel";
Vue.component("downloadExcel", JsonExcel);
Vue.filter("formatNumber", function (value) {
  return numeral(value).format("0,00.00"); // displaying other groupings/separators is possible, look at the docs
});

Vue.filter("currency", function (value) {
  return numeral(value).format("$0,0.00");
});


export default {
  mounted: function () {
    var self = this;

    var userSetting = JSON.parse(localStorage.getItem('userSettings'));
    this.usuario = userSetting.Usuario;
    this.usuarioEstacion = userSetting.Estacion;
    this.IdRol = userSetting.IdRol;
    this.getCiudades();
    this.ciudadSelect = userSetting.CiudadId;
    this.getEstaciones();
    this.estacionSelect = userSetting.Estacion;




    this.GetTurnos();
    if (this.IdRol == 3 || this.IdRol == 7) {
      this.deshabilitarCombos = true;
    }

  },
  beforeCreate() {

  },
  created() {
    // console.log('created');
  },
  beforeMount() {
    // console.log('beforeMount');
  },
  components: {
    DxTotalItem, DxDataGrid, DxSummary, DxFormat, DxPaging, DxPager, DxScrolling, DxColumn, DxExport, DxSelection, DxGroupPanel, DxGrouping, DxHeaderFilter, DxFilterRow, DxSearchPanel,
  },
  computed: {
  },
  methods: {
    async GetTurnos() {
      try {
        const response = await axios.get("Catalogos/GetTurnos");
        if (response.data.Success) {
          if (response.data.response.length == 0) {
            this.alertMessage =
              "No se encontraron turnos";
            this.alertTitle = "Error";
            this.show("top-right", "error");
          } else {
            this.turnos = response.data.response;
          }
        } else {
          this.alertMessage = response.data.Message;
          this.alertTitle = "Error";
          this.show("top-right", "error");
        }
      } catch (e) {
        this.alertMessage = "Ocurrio un error";
        this.alertTitle = "Error";
        this.show("top-right", "error");
      }
    },
    async getCiudades() {
      try {
        const response = await axios.get('Catalogos/GetCiudades');
        if (response.data.Success) {
          if (response.data.response.length == 0) {
            this.alertMessage = "No se encontró información.";
            this.alertTitle = "Error";
            this.show('top-right', 'error');
          }
        } else {
          console.log("error")
          this.alertMessage = response.data.Error;
          this.alertTitle = "Error";
          this.show('top-right', 'error');
        }
        this.ciudades = response.data.response;
      } catch (e) {
        //this.alert = true;
        //this.error = 'Invalid email/password';
      }
    },
    async getEstaciones() {
      try {
        debugger; // eslint-disable-line no-debugger
        const response = await axios.post('Estacion/GetEstaciones?ciudad=' + this.ciudadSelect);
        if (response.data.Success) {
          if (response.data.response.length == 0) {
            this.alertMessage = "No se encontró información.";
            this.alertTitle = "Error";
            this.show('top-right', 'error');
          }
        } else {
          console.log("error")
          this.alertMessage = response.data.Error;
          this.alertTitle = "Error";
          this.show('top-right', 'error');
        }
        this.estaciones = response.data.response;
        // this.productos = [];
        // this.getProductos();
      } catch (e) {
        //this.alert = true;
        //this.error = 'Invalid email/password';
      }
    },
    show(group, type = '') {
      const text = this.alertMessage;
      this.$notify({
        group,
        title: this.alertTitle,
        text,
        type,
        data: {
          randomNumber: Math.random()
        }
      })
    },
    clean(group) {
      this.$notify({ group, clean: true })
    },
    swalNotification(swalType) {
      var btnClass = (swalType == 'error') ? 'danger' : swalType;
      btnClass = (swalType == 'question') ? 'primary' : btnClass;
      this.$swal({
        title: 'Are you sure?',
        text: 'You will not be able to recover this imaginary file!',
        type: swalType,
        showCancelButton: true,
        buttonsStyling: false,
        confirmButtonText: swalType.charAt(0).toUpperCase() + swalType.substr(1).toLowerCase(),
        cancelButtonText: 'Cancel',
        confirmButtonClass: 'btn me-5px btn-' + btnClass + '',
        cancelButtonClass: 'btn btn-default',
      })
    },

    async search(e) {
      var self = this;
      e.preventDefault();


      self.textoBotonBuscar = "Buscando...";
      self.loading = true;
      try {
        self.intDate = self.date.replace('/', '');
        self.intDate = self.intDate.replace('/', '');


        self.intDateEnd = self.dateEnd.replace('/', '');
        self.intDateEnd = self.intDateEnd.replace('/', '');

        var ciudad = self.ciudadSelect != undefined ? self.ciudadSelect : 0;
        var estacion = self.estacionSelect != undefined ? self.estacionSelect : 0;
        debugger; // eslint-disable-line no-debugger

        var turno = self.turnoSelected != undefined ? self.turnoSelected.IdTurno : 0;
        var dataToSend = {
          "Data": {
            "Fecha": self.intDate,
            "Estacion": estacion,
            "Turno": turno,
          }
        };
        const response = await axios.post('Corresponsalia/GetCorreponsaliaEstacion', dataToSend);
        if (response.data.Success) {
          if (response.data.response.length == 0) {
            self.alertMessage = "No se encontró información del día especificado.";
            self.alertTitle = "Error";
            self.show('top-right', 'error');
          }
        } else {
          console.log("error")
          self.alertMessage = response.data.Message;
          self.alertTitle = "Error";
          self.show('top-right', 'error');
        }
        var respuesta = response.data.response;

        self.searchResult = respuesta;
        for (var n = 0; n < respuesta.length; n++) {
          respuesta[n].FechaString = moment(respuesta[n].Fecha).format("DD/MM/YYYY");
        }

        self.textoBotonBuscar = "Buscar";

        self.loading = false;
      } catch (e) {
        self.loading = false;
        //this.alert = true;
        //this.error = 'Invalid email/password';
      }
    },
    onExporting(e) {
      const workbook = new Workbook();
      const worksheet = workbook.addWorksheet('Dolares');

      exportDataGrid({
        component: e.component,
        worksheet: worksheet,
        autoFilterEnabled: true
      }).then(() => {
        workbook.xlsx.writeBuffer().then((buffer) => {
          saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'Dolares.xlsx');
        });
      });
      e.cancel = true;
    },

    async delTicket(data) {
      var self = this;
      self.loading = true;
      try {

        var swalType = 'question';
        var btnClass = (swalType == 'error') ? 'danger' : swalType;
        btnClass = (swalType == 'question') ? 'primary' : btnClass;

        this.$swal({
          title: 'Confirme',
          text: '¿Desea eliminar la transacción ' + data.Transaccion + '?',
          type: swalType,
          showCancelButton: true,
          buttonsStyling: false,
          confirmButtonText: 'Aceptar',
          cancelButtonText: 'Cancelar',
          confirmButtonClass: 'btn me-5px btn-' + btnClass + '',
          cancelButtonClass: 'btn btn-default',
        }).then(async (result) => {
          if (result.isConfirmed) {

            var dataToSend = {
              "Data": {
                "Estacion": data.NoEstacion,
                "Transaccion": data.Transaccion
              }
            };

            // eslint-disable-line no-debugger
            const response = await axios.post("Corresponsalia/DelTicket", dataToSend);

            self.loading = false;
            // self.textoBotonEnviar = "Enviar";
            // eslint-disable-line no-debugger
            if (response.data.Success) {
              this.search('');
              this.alertMessage = response.data.Message;
              this.alertTitle = "";
              this.show("top-right", "success");

              // self.descargarPDF(response.data.response, data.Nombre);

            }
            else {

              this.alertMessage = response.data.Message;
              this.alertTitle = "Error";
              this.show("top-right", "error");
            }

          }
        });

      } catch (e) {
        self.loading = false;
        self.textoBotonEnviar = "Enviar";
        this.alertMessage = "Ocurrio un error";
        this.alertTitle = "Error";
        this.show("top-right", "error");
      }

    },
  },
  data() {
    return {
      showHeaderFilter: true,
      turnos: [],
      turnoSelected: {
        IdTurno: 0
      },
      loading: false,
      deshabilitarCombos: false,
      ciudades: [],
      estaciones: [],
      ciudadSelect: 1,
      estacionSelect: 0,
      // date: moment().startOf('month').format("YYYY/MM/DD"),
      date: moment().format("YYYY/MM/DD"),
      intDateEnd: "",
      dateEnd: moment().format("YYYY/MM/DD"),
      intDate: "",
      options: {
        format: 'YYYY/MM/DD',
        useCurrent: false,
      },
      pageUserSettings: {},
      alertMessage: "",
      alertTitle: "",
      searchResult: [],
      textoBotonBuscar: "Buscar",


      json_fields: {
        "No Estacion": "NoEstacion",
        "Estacion": "Estacion",
        "Transaccion": "Transaccion",
        "Bomba": "Bomba",
        "Fecha": "FechaString",
        "Importe": "Importe",
        "Tipo de Cambio": "TipoCambio",
        "Dolares Recibidos": "DolaresRecibidos",
      },

    };
  }
}
</script>
<style scoped>
.dx-datagrid-header-panel {
  display: none !important;
}

.loading-search {
  position: absolute;
  width: 100%;
  left: 0px;
  height: 100%;
  background: rgba(0, 0, 0, 0.1);
  z-index: 1000;
  padding-top: 150px;
  top: 0;
}

.sumatoriasRow {
  background-color: #bbb;
}

.currencyRow {
  text-align: right !important;
}

.iconColor {
  color: #f70909;
  font-size: 20px;
  cursor: pointer;
}
</style>