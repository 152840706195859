<template>
    <div>
      <div class="loading-search" v-if="loading">
        <div class="fa-3x text-center">
          <i class="fas fa-circle-notch fa-spin text-success" style=""></i>
        </div>
      </div>
      <!-- BEGIN breadcrumb -->
      <ol class="breadcrumb float-xl-end">
              <li class="breadcrumb-item"><a href="javascript:;">Operación</a></li>
              <li class="breadcrumb-item"><a href="javascript:;">Corte</a></li>
              <li class="breadcrumb-item active"><a href="javascript:;">Resumen de transacciones bancarias</a></li>
      </ol>
      <!-- END breadcrumb -->
      <!-- BEGIN page-header -->
      <h1 class="page-header">Resumen de transacciones bancarias</h1>
      <!-- END page-header -->
  
  
  
          <form @submit.prevent="search">   
      <div class="row">
        <div class="col-md-8"> 
            <div class="form-group row">
              
              <div class="col-md-3">
                  <label class="col-form-label">Fecha</label>
                <div class="input-group">
                  <date-picker id="dateTimeInput" v-model="date" :config="options"></date-picker>
                  <label for="dateTimeInput" class="input-group-text">
                    <i class="fa fa-calendar"></i>
                  </label>
                </div>
              </div>
  
              
              <!-- <div class="col-md-3">
                  <label class="col-form-label">Fecha final</label>
                <div class="input-group">
                  <date-picker id="dateTimeInputEnd" v-model="dateEnd" :config="options"></date-picker>
                  <label for="dateTimeInputEnd" class="input-group-text">
                    <i class="fa fa-calendar"></i>
                  </label>
                </div>
              </div> -->
              <div class="col-md-3">
                  <label class="col-form-label">Ciudad</label>
                  <select class="form-select" @change="getEstaciones()" v-model="ciudadSelect" :disabled="deshabilitarCombos">
                      <option v-for="ciudad in ciudades" :value="ciudad.Id" v-bind:key="ciudad.Id">
                          {{ ciudad.Nombre }}
                      </option>
                  </select>
              </div>
              <div class="col-md-3">
                  <label class="col-form-label">Estación</label>
                  <select class="form-select" ref="mySelect" id="mySelectId" v-model="estacionSelect" :disabled="deshabilitarCombos">
                    <option value="0">Todas</option>
                      <option v-for="estacion in estaciones" :value="estacion.NoEstacion" v-bind:key="estacion.NoEstacion">
                      {{ estacion.Nombre }}
                      </option>
                    </select>
              </div>
              <div class="col-md-3">
                  <label class="col-form-label">Turno</label>
                  <select v-model="turnoSelected.IdTurno"  ref="mySelectTurno" id="mySelectIdTurno"  class="form-control">
                      <option v-for="turno in turnos" :value="turno.IdTurno" v-bind:key="turno.IdTurno">
                      {{ turno.Descripcion }}
                      </option>
                  </select>
              </div>
  
  
              <!-- <div class="col-md-6">
                <button type="submit" class="btn btn-success me-1 mb-1">{{textoBotonBuscar}}</button>
              </div> -->
            </div>
        </div>
        <div class="col-md-2">
              <button type="submit" class="btn btn-success me-1 mb-1" style="margin-top: 3.1em;">{{textoBotonBuscar}}</button>
        </div>

        <div class="col-md-2">
              <button type="button" @click="print" :disabled="!seleccionados" class="btn btn-success me-1 mb-1" style="margin-top: 3.1em;">Descargar PDF</button>
            
        </div>



        
      </div>
  
          </form>
            <panel title="Resumen de transacciones bancarias" bodyClass="p-0" class="">
                <!-- <div>
                  <button @click="print" class="btn btn-success">Imprimir seleccionados</button>
                  <button @click="downloadPDF" :disabled="!seleccionados" class="btn btn-success">descargar PDF</button>

                  
                </div> -->
                

                <div style="display: none;">
              <!-- <div> -->
                
                <div id="downloadPDF">
                  <table style="width: 100%;">
                    <tr>
                      <td><img src="/assets/img/logo-rendi.png" style="width: 250px; vertical-align: super;" alt="" /></td>
                      <td></td>
                      <td></td>
                    </tr>
                    <tr>
                      <td colspan="3"><h4 style="text-align: center;">Resumen de cobros con tarjeta</h4></td>
                    </tr>
                    <tr>
                      <td>
                        <table>
                          <tr>
                            <td colspan="2"></td>
                          </tr>
                          <tr>
                            <td>Tarjeta débito</td>
                            <td style="text-align: right;">{{ totalesEmpleados.totalDebito | currency }}</td>
                          </tr>
                          <tr>
                            <td>Tarjeta crédito</td>
                            <td style="border-bottom: 1px solid black; text-align: right;">{{ totalesEmpleados.totalCredito | currency }}</td>
                          </tr>
                          <tr>
                            <td></td>
                            <td style="text-align: right;">{{ totalesEmpleados.totalDebito + totalesEmpleados.totalCredito | currency }}</td>
                          </tr>
                          <tr>
                            <td><label for="" style="color: transparent;">*<br></label></td>
                            <td></td>
                          </tr>
                          <tr>
                            <td>AMEX</td>
                            <td style="border-bottom: 1px solid black; text-align: right;">{{ totalesEmpleados.totalAMEX | currency }}</td>
                          </tr>
                          <tr>
                            <td></td>
                            <td style="text-align: right;">{{ totalesEmpleados.totalDebito + totalesEmpleados.totalCredito + totalesEmpleados.totalAMEX | currency }}</td>
                          </tr>
                          <tr>
                            <td><label for="" style="color: transparent;">*<br></label></td>
                            <td></td>
                          </tr>
                          <tr>
                            <td>Diesel</td>
                            <td style=" text-align: right;">{{ totalesEmpleados.diesel | currency }}</td>
                          </tr>
                          <tr>
                            <td><label for="" style="color: transparent;">*<br></label></td>
                            <td></td>
                          </tr>
                          <tr>
                            <td>Devoluciones</td>
                            <td style=" text-align: right;">{{ totalesEmpleados.devoluciones | currency }}</td>
                          </tr>
                        </table>

                      </td>
                      <td></td>
                      <td>
                        <table style="width: 100%;">
                          <tr>
                            <td width="25%"></td>
                            <td></td>
                            <td>
                              <label for="">Fecha: {{date }}</label>
                              <br>
                              <label for="">Estación: {{ selectedEstacion }}</label>
                              <br>
                              <label for="">{{ selectedTurno }}</label>
                              <br>
                              <label for="">Usuario: {{ usuario }}</label>
                            </td>
                          </tr>
                        </table>
                      </td>
                    </tr>
                  </table>
                  <br>
                  <br>

                  <table style="width: 100%; border-collapse: collapse; border: 1px solid;">
                    <thead>
                      <tr>
                        <th style="border: 1px solid;">Nombre</th>
                        <th style="border: 1px solid;">Tarjetas Débito</th>
                        <th style="border: 1px solid;">Tarjetas Crédito</th>
                        <th style="border: 1px solid;">AMEX</th>
                        <th style="border: 1px solid;">Diesel</th>
                        <th style="border: 1px solid;">Devoluciones</th>
                      </tr>
                    </thead>
                    <tbody>
                      <template v-for="empleado in registros">
                        <tr v-if="empleado.seleccionado" v-bind:key="empleado.NoEmpleado">
                          <td style="border: 1px solid;">{{ empleado.Nombre }}</td>
                          <td style="border: 1px solid; text-align: right;">{{ empleado.Debito | currency}}</td>
                          <td style="border: 1px solid; text-align: right;">{{ empleado.Credito | currency}}</td>
                          <td style="border: 1px solid; text-align: right;">{{ empleado.AMEX | currency}}</td>
                          <td style="border: 1px solid; text-align: right;">{{ empleado.Diesel | currency}}</td>
                          <td style="border: 1px solid; text-align: right;">{{ empleado.Devoluciones | currency }}</td>
                        </tr>

                      </template>
                    </tbody>
                  </table>
                  
                </div>
                
              </div>



                  <div style="display: none;">
                  <!-- <div > -->

                    <div  class="row" style="margin-top: 300px;"  id="downloadPDF2">
                      <div class="col-12 text-center">
                        <img src="/assets/img/logo-rendi.png" alt="" />
                      </div>

                      <div class="col-2">
                      </div>

                      <div class="col-8">
                        <div class="row">
                              
                          <div class="col-4 text-center">
                            Fecha: {{date }}
                          </div>
                          <div class="col-4 text-center">
                            Estación: {{ selectedEstacion }}
                          </div>
                          <div class="col-4 text-center">
                            {{ selectedTurno }}
                          </div>

                        </div>
                      </div>

                      <div class="col-2">
                      </div>


                      <div class="col-2"></div>
                                                    
                      <div class="col-8" style="margin-top: 15px; margin-top: 10px; ">
                        <div class="widget widget-stats bg-white">
                          <div class="stats-icon stats-icon-lg" style="color: #ccc !important;">
                            <i class="fa fa-file-alt fa-fw" ></i>
                          </div>
                          <div class="stats-content" style="color: black !important;">
                            <div class="stats-number">Resumen de empleados</div>
                            <div class="stats-progress progress">
                              <div class="progress-bar"  style="width: 100%"></div>
                            </div>
                            <div class="stats-desc mb5 text-center"><b>Resumen de cobros con tarjeta</b> </div>
                            <div class="stats-desc mb5">
                              <div class="row">
                                <div class="col-8">Tarjeta débito</div>
                                <div class="col-4 textRight">{{ totalesEmpleados.totalDebito | currency }}</div>
                              </div>
                              <div class="row">
                                <div class="col-8">Tarjeta crédito</div>
                                <div class="col-4 textRight" style="border-bottom: 1px solid #000;">{{ totalesEmpleados.totalCredito | currency }}</div>
                              </div>
                              <div class="row">
                                <div class="col-8"></div>
                                <div class="col-4 textRight" >{{ totalesEmpleados.totalDebito + totalesEmpleados.totalCredito | currency }}</div>
                              </div>
                              <div class="row" style="margin-top: 15px;">
                                <div class="col-8">AMEX</div>
                                <div class="col-4 textRight" >{{ totalesEmpleados.totalAMEX | currency }}</div>
                              </div>
                            </div>
                            <div class="stats-progress progress">
                              <div class="progress-bar"  style="width: 100%"></div>
                            </div>
                            <div class="stats-desc mb5 textRight"> <b>{{ totalesEmpleados.totalDebito + totalesEmpleados.totalCredito + totalesEmpleados.totalAMEX | currency }}</b> </div>
                            
                            

                            
                          </div>
                        </div>
                      </div>

                      <div class="col-2">
                        
                      </div>
                      <div class="col-12">
                        
                        <div class="table-responsive">
                          <table class="table table-bordered mb-0">
                            <thead>
                              <tr>
                                <th>Nombre</th>
                                <th>Tarjetas Débito</th>
                                <th>Tarjetas Crédito</th>
                                <th>AMEX</th>
                              </tr>
                            </thead>
                            <tbody>
                              <template v-for="empleado in registros">
                                <tr v-if="empleado.seleccionado" v-bind:key="empleado.NoEmpleado">
                                  <td>{{ empleado.Nombre }}</td>
                                  <td>{{ empleado.Debito | currency}}</td>
                                  <td>{{ empleado.Credito | currency}}</td>
                                  <td>{{ empleado.AMEX | currency}}</td>
                                </tr>

                              </template>
                            </tbody>
                          </table>
                        </div>
                      </div>

                    </div>
                  </div>

                  






                <div class="row">


                              
                  <div class="col-xl-4 col-md-6" v-for="(registro, index) in registros" :key="registro.NoEmpleado"  style="margin-top: 15px; margin-top: 10px; ">
                    <div class="widget widget-stats bg-white">
                      <div class="stats-icon stats-icon-lg">
                        <i class="fa fa-file-alt fa-fw" ></i>
                      </div>
                      <div class="stats-content">
                        <div class="stats-title">
                          <div class="row">
                            <div class="col-md-6">
                              <div class="form-check">
                                <input class="form-check-input" type="checkbox" :id="'empleado-' + index" v-model="registro.seleccionado" @click="seleccionarParaPdf()"/>
                                <label class="form-check-label" :for="'empleado-' + index">Seleccionar</label>

                              </div>
                            </div>
                            <div class="col-md-6 textRight">
                              <button  class="btn btn-xs btn-success"  v-b-modal.modalDialogTransacciones @click="getTransacciones(registro)">Ver transacciones</button>
                            </div>

                            
                          </div>
                          
                        </div>
                        <div class="stats-number">{{ registro.NoEmpleado }} {{ registro.Nombre }}</div>
                        <div class="stats-progress progress">
                          <div class="progress-bar"  style="width: 100%"></div>
                        </div>
                        <div class="stats-desc mb5 text-center"><b>Resumen de cobros con tarjeta</b> </div>
                        <div class="stats-desc mb5">
                          <div class="row">
                            <div class="col-8">Tarjeta débito</div>
                            <div class="col-4 textRight">{{ registro.Debito | currency }}</div>
                          </div>
                          <div class="row">
                            <div class="col-8">Tarjeta crédito</div>
                            <div class="col-4 textRight" style="border-bottom: 1px solid #000;">{{ registro.Credito   | currency }}</div>
                          </div>
                          <div class="row">
                            <div class="col-8"></div>
                            <div class="col-4 textRight" >{{ registro.TotalTarjetas | currency }}</div>
                          </div>
                          <div class="row" style="margin-top: 15px;">
                            <div class="col-8">AMEX</div>
                            <div class="col-4 textRight" >{{ registro.AMEX | currency }}</div>
                          </div>
                        </div>
                        <div class="stats-progress progress">
                          <div class="progress-bar"  style="width: 100%"></div>
                        </div>
                        <div class="stats-desc mb5 textRight"> <b>{{ registro.TotalTarjetas + registro.AMEX | currency }}</b> </div>
                        
                        <div class="stats-progress progress">
                          <div class="progress-bar"  style="width: 100%"></div>
                        </div>
                        <div class="row" style="margin-top: 15px;">
                          <div class="col-8">Diesel</div>
                          <div class="col-4 textRight" >{{ registro.Diesel | currency }}</div>
                        </div>
                        <div class="stats-progress progress">
                          <div class="progress-bar"  style="width: 100%"></div>
                        </div>
                        <div class="row" style="margin-top: 15px;">
                          <div class="col-8">Devoluciones</div>
                          <div class="col-4 textRight" >{{ registro.Devoluciones | currency }}</div>
                        </div>
                        

                        
                      </div>
                    </div>
                  </div>

                  
                </div>
            </panel>
      
  
  
  
          
            <!-- #modal-dialog -->
            <b-modal id="modalDialogTransacciones" size="xl">
              <template slot="modal-header">
                <h4 class="modal-title">Transacciones</h4>
                <a class="btn-close" @click="$bvModal.hide('modalDialogTransacciones')"></a>
              </template>

              <div>
                
            <div>



              <b-tabs nav-wrapper-class="nav nav-tabs" content-class="p-15px bg-white">
                <!-- BEGIN tab -->
                <b-tab active>
                  <template slot="title">
                    <span class="d-sm-none">Tab 1</span>
                    <span class="d-sm-block d-none">Transacciones</span>
                  </template>
                  
                  
              <vue-good-table
                :columns="columns"
                :rows="transacciones"
                :lineNumbers="false"
                :search-options="{ enabled: true, placeholder: 'Buscar' }"
                :pagination-options="{ enabled: true, position: 'bottom' }"
              >
              </vue-good-table>

                </b-tab>
                <!-- END tab -->
                <!-- BEGIN tab -->
                <b-tab>
                  <template slot="title">
                    <span class="d-sm-none">Tab 2</span>
                    <span class="d-sm-block d-none">Transacciones Diesel</span>
                  </template>
                  
                  
              <vue-good-table
                :columns="columns"
                :rows="transaccionesDiesel"
                :lineNumbers="false"
                :search-options="{ enabled: true, placeholder: 'Buscar' }"
                :pagination-options="{ enabled: true, position: 'bottom' }"
              >
              </vue-good-table>

                  
                </b-tab>

              </b-tabs>




            </div>
              </div>

              <template slot="modal-footer">
                
                <button
                  type="button"
                  class="btn btn-secondary"
                  @click="$bvModal.hide('modalDialogTransacciones')"
                >
                  Cerrar
                </button>
              </template>
            </b-modal>
          
      <!-- bottom left vue-notification -->
      <notifications group="top-right" position="top right" :speed="1000" />
    </div>
  </template>
  
  <script>

    import axios from "axios";
    var numeral = require("numeral");
    import Vue from "vue";
    import html2pdf from 'html2pdf.js';
    import VueHtmlToPaper from "vue-html-to-paper";

    const options = {
      name: "_blank",
      specs: ["fullscreen=yes", "titlebar=no", "scrollbars=yes"],
      styles: [],
      timeout: 1000, // default timeout before the print window appears
      autoClose: false, // if false, the window will not close after printing
      windowTitle: window.document.title, // override the window title
    };

    Vue.use(VueHtmlToPaper, options);

    // // or, using the defaults with no stylesheet
    // Vue.use(VueHtmlToPaper);



    Vue.filter("formatNumber", function (value) {
        return numeral(value).format("0,00.00"); // displaying other groupings/separators is possible, look at the docs
    });
    
    Vue.filter("currency", function (value) {
        return numeral(value).format("$0,0.00"); 
    });
  import moment from 'moment';
  export default {
  async	created() {
    
      },
    mounted: function () {
          var userSetting = JSON.parse(localStorage.getItem('userSettings'));
          this.usuario = userSetting.Usuario;
          this.idUsuario = userSetting.NumeroEmpleado;
          this.nombreLogueado = userSetting.Nombre;
          this.usuarioEstacion = userSetting.Estacion;
          this.IdRol = userSetting.IdRol;
      this.getCiudades();
      this.ciudadSelect = userSetting.CiudadId;
      this.getEstaciones();
      this.estacionSelect = userSetting.Estacion;
  
      this.GetTurnos();
      if(this.IdRol == 3 || this.IdRol == 7){
        this.deshabilitarCombos = true;
      }
  
  
      
  
    },
    methods: {
      getSelectedText() {
        const selectElement = this.$refs.mySelect;
        const selectedIndex = selectElement.selectedIndex;
        const selectedOption = selectElement.options[selectedIndex];
        const selectedText = selectedOption.text;
        console.log(selectedText);
      },
      downloadPDF() {



        const element = document.getElementById('downloadPDF');
        const options = {
          margin: [0, 10, 0, 10],
          filename: 'Resumen de transacciones bancarias.pdf',
          image: { type: 'jpeg', quality: 1 },
          html2canvas: { scale: 4 },
          jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' }
        };

        html2pdf().from(element).set(options).save();
      },
        
      async print() {
        // Pass the element id here
        await this.$htmlToPaper("downloadPDF");
      },
      seleccionarParaPdf(){
        var self = this;
        self.seleccionados = false;
        self.totalesEmpleados = {
          totalDebito:0,
          totalCredito:0,
          totalAMEX:0,
          diesel:0,
          devoluciones:0,
        };
        
        

        setTimeout(() => {
            
          for(var n = 0 ; n < self.registros.length ; n++){
            if(self.registros[n].seleccionado){
              self.seleccionados = true;
              self.totalesEmpleados.totalDebito += self.registros[n].Debito;
              self.totalesEmpleados.totalCredito += self.registros[n].Credito;
              self.totalesEmpleados.totalAMEX += self.registros[n].AMEX;
              self.totalesEmpleados.diesel += self.registros[n].Diesel;
              self.totalesEmpleados.devoluciones += self.registros[n].Devoluciones;
            }
          }

        }, 0);
      },
      getTransacciones(registro){
        var self = this;
        self.transacciones = registro.Transacciones;
        self.transaccionesDiesel = registro.TransaccionesDiesel;
      },
      async GetTurnos(){
        try {
          const response = await axios.get("Catalogos/GetTurnos");
          if (response.data.Success) {
            if (response.data.response.length == 0) {
              this.alertMessage =
                "No se encontraron turnos";
              this.alertTitle = "Error";
              this.show("top-right", "error");
            } else {
              this.turnos = response.data.response;
            }
          } else {
            this.alertMessage = response.data.Message;
            this.alertTitle = "Error";
            this.show("top-right", "error");
          }
        } catch (e) {
          this.alertMessage = "Ocurrio un error";
          this.alertTitle = "Error";
          this.show("top-right", "error");
        }
      },
      async getCiudades(){
          try{
          const response = await axios.get('Catalogos/GetCiudades');
          //debugger; // eslint-disable-line no-debugger
          if(response.data.Success){
            if(response.data.response.length == 0){
              this.alertMessage = "No se encontró información.";
              this.alertTitle = "Error";
              this.show('top-right', 'error');
            }
          }else{
              console.log("error")
              this.alertMessage = response.data.Error;
              this.alertTitle = "Error";
              this.show('top-right', 'error');
          }
          this.ciudades = response.data.response;
        }catch(e){
          //this.alert = true;
          //this.error = 'Invalid email/password';
        }
      },
      async getEstaciones(){
          try{
          debugger; // eslint-disable-line no-debugger
          const response = await axios.post('Estacion/GetEstaciones?ciudad='+this.ciudadSelect);
          if(response.data.Success){
            if(response.data.response.length == 0){
              this.alertMessage = "No se encontró información.";
              this.alertTitle = "Error";
              this.show('top-right', 'error');
            }
          }else{
              console.log("error")
              this.alertMessage = response.data.Error;
              this.alertTitle = "Error";
              this.show('top-right', 'error');
          }
          this.estaciones = response.data.response;
          this.productos = [];
          this.getProductos();
        }catch(e){
          //this.alert = true;
          //this.error = 'Invalid email/password';
        }
      },
              show (group, type = '') {
                  const text = this.alertMessage;
                  this.$notify({
                      group,
                      title: this.alertTitle,
                      text,
                      type,
                      data: {
                          randomNumber: Math.random()
                      }
                  })
              },
              clean (group) {
                  this.$notify({ group, clean: true })
              },
              swalNotification(swalType) {
                  var btnClass = (swalType == 'error') ? 'danger' : swalType;
                      btnClass = (swalType == 'question') ? 'primary' : btnClass;
                  this.$swal({
                      title: 'Are you sure?',
                      text: 'You will not be able to recover this imaginary file!',
                      type: swalType,
                      showCancelButton: true,
                      buttonsStyling: false,
                      confirmButtonText: swalType.charAt(0).toUpperCase() + swalType.substr(1).toLowerCase(),
                      cancelButtonText: 'Cancel',
                      confirmButtonClass: 'btn me-5px btn-'+ btnClass +'',
                      cancelButtonClass: 'btn btn-default',
                  })
              },
        async getData(){
          
          debugger; // eslint-disable-line no-debugger
          var self = this;
        self.loading = true;
        self.textoBotonBuscar = "Buscando...";
        try{
          self.intDate = self.date.replace('/', '');
          self.intDate = self.intDate.replace('/', '');
          var fecha = moment(self.date).format("DD/MM/YYYY");
  
          var estacion = self.estacionSelect!=undefined?self.estacionSelect:0;
  
          var turno = self.turnoSelected != undefined ? self.turnoSelected.IdTurno : 0;
  

        
          const selectElement = this.$refs.mySelect;
          const selectedIndex = selectElement.selectedIndex;
          const selectedOption = selectElement.options[selectedIndex];
          self.selectedEstacion = selectedOption.text;

          const selectElementTurno = this.$refs.mySelectTurno;
          const selectedIndexTurno = selectElementTurno.selectedIndex;
          const selectedOptionTurno = selectElementTurno.options[selectedIndexTurno];
          self.selectedTurno = selectedOptionTurno.text;



          

          const response = await axios.post('ePago/GetTransaccionesTurno' ,{
          "Data": {
              "Fecha": fecha,
              // "FechaFinal":self.intDate, 
              "Estacion": estacion ,
              "Turno": turno
              }
          });
          
      debugger; // eslint-disable-line no-debugger
          if(response.data.Success){
            if(response.data.response.length == 0){
              self.alertMessage = "No se encontro información del día especificado.";
              self.alertTitle = "Error";
              self.show('top-right', 'error');
            }
          }else{
              console.log("error")
              self.alertMessage = response.data.Message;
              self.alertTitle = "Error";
              self.show('top-right', 'error');
          }
          
          self.registros = response.data.response;
  
        self.textoBotonBuscar = "Buscar";
        
        self.loading = false;
        }catch(e){
        self.loading = false;
          //this.alert = true;
          //this.error = 'Invalid email/password';
        }
  
        },
      async search(e) {
        e.preventDefault();
        this.getData();
      },
    },
    data() {
      return {
        selectedEstacion:'',
        selectedTurno: '',
        seleccionados: false,
        totalesEmpleados:{
          totalDebito:0,
          totalCredito:0,
          totalAMEX:0,
        },
        turnos:[],
        turnoSelected:{
            IdTurno:11
        },
        loading:false,
        nombreLogueado:"",
        idUsuario:undefined,
        registros:[],
        deshabilitarCombos:false,
        ciudades: [],
        estaciones: [],
        ciudadSelect: 1,
        estacionSelect: 0,
        ventaDetalle:[],
        emailReenviar:"",
        uuidReenviar:"",
        date: moment().format("YYYY/MM/DD"),
        intDateEnd: "",
        dateEnd: moment().format("YYYY/MM/DD"),
        intDate: "",
        options: {
          format: 'YYYY/MM/DD',
          useCurrent: false,
        },
        puestoSelected:{
            puesto:0
        },
        rolSelected:{
            rol:0
        },
        actualizarNIP:false,
        puestosSelect:[],
        rolesSelect:[],
        nuevoCorreo: '',
        clienteNuevo: true,
        clienteModal:{},
        textoBotonGuardar: "Guardar",
        textoBotonBuscar: "Buscar",
        clienteBuscar: "",
        items: [],
                
        transacciones:[],    
        transaccionesDiesel:[],      
        columns: [
            {
              label: "nu_operaion",
              field: "nu_operaion",
            },
            {
              label: "cd_usuario",
              field: "cd_usuario",
            },
            {
              label: "cd_empresa",
              field: "cd_empresa",
            },
            {
              label: "nu_sucursal",
              field: "nu_sucursal",
            },
            {
              label: "nu_afiliacion",
              field: "nu_afiliacion",
            },
            {
              label: "nb_referencia",
              field: "nb_referencia",
            },
            {
              label: "cc_nombre",
              field: "cc_nombre",
            },
            {
              label: "cc_num",
              field: "cc_num",
            },
            {
              label: "cc_tp",
              field: "cc_tp",
            },
            {
              label: "nu_importe",
              field: "nu_importe",
            },
            {
              label: "cd_tipopago",
              field: "cd_tipopago",
            },
            {
              label: "cd_tipocobro",
              field: "cd_tipocobro",
            },
            {
              label: "cd_instrumento",
              field: "cd_instrumento",
            },
            {
              label: "nb_response",
              field: "nb_response",
            },
            {
              label: "nu_auth",
              field: "nu_auth",
            },
            {
              label: "fh_registro",
              field: "fh_registro",
            },
            {
              label: "fh_bank",
              field: "fh_bank",
            },
            {
              label: "cd_usrtransaccion",
              field: "cd_usrtransaccion",
            },
            {
              label: "estacion",
              field: "estacion",
            },
            {
              label: "producto",
              field: "producto",
            },
            {
              label: "formaPago",
              field: "formaPago",
            },
            {
              label: "tp_operacion",
              field: "tp_operacion",
            },
            {
              label: "nb_currency",
              field: "nb_currency",
            },
            {
              label: "cd_resp",
              field: "cd_resp",
            },
            {
              label: "nb_resp",
              field: "nb_resp",
            },
        ],

      };
    },
  };
  </script>
  

<style>
.loading-search{
  position: absolute;
    width: 100%;
    left: 0px;
    height: 100%;
    background: rgba(0, 0, 0, 0.1);
    z-index: 1000;
    padding-top: 150px;
    top: 0;
}
.textRight{
  text-align: right !important;
}
.mb10{
	margin-bottom: 10px !important;
}
.mb5{
	margin-bottom: 5px !important;
}
.font-red{
	color: red !important;
}
.font-orange{
	color: orange !important;
}
.font-green{
	color: green !important;
}
.stats-title, .stats-desc{
	color: #555 !important;
}
.bg-white, .bg-teal-500 {
    background-color: #eee;
    border: 1px solid #AAB;
	color: #555 !important;
}

.widget-stats .stats-number, .widget.widget-stats .stats-number{
  font-size: 18px;
}
.widget-stats .stats-number, .widget.widget-stats .stats-number {
    font-size: 18px;
    margin-bottom: 0.25rem;
    font-weight: 600;
    letter-spacing: 1px;
}

.widget-stats .stats-icon.stats-icon-lg, .widget.widget-stats .stats-icon.stats-icon-lg {
    color: #999;
    text-shadow: 5px 10px rgb(0 0 0 / 15%);
    opacity: 0.25;
}
.widget-stats .stats-progress, .widget.widget-stats .stats-progress {
    height: 1px !important;
}
.mt-35{
	margin-top: 2rem;
}
.textRight{
	text-align: right;
}
.blink_me {
  animation: blinker 1s linear infinite;
}

@keyframes blinker {  
  50% { opacity: 0; }
}
.btn-green{
	background-color: limegreen !important;
	border-color: limegreen !important;
}
#modalDialogTransacciones .modal-body{
  background: #ddd;
}



#modalDialogTransacciones .bg-white {
    background-color: #eee;
    border: 0px solid #AAB;
	color: #555 !important;
}
/* #modalDialogTransacciones___BV_modal_content_ .modal-body{
  background: #ddd !important;
} */


</style>

