<template>
  <div>
    <ol class="breadcrumb float-xl-end">
      <li class="breadcrumb-item"><a href="javascript:;">Facturación</a></li>
      <li class="breadcrumb-item active">Servicios Administrativos</li>
    </ol>

    <h1 class="page-header">
      Facturación Clientes
    </h1>

    <div class="row">
      <div class="col-xl-6">
        <panel title="Emisor" bodyClass="p-0">
          <form class="form-horizontal form-bordered">
            <div class="form-group row">

              <label class="col-md-4 col-form-label">Ciudad</label>
              <div class="col-md-8">
                <select class="form-select" @change="getEstaciones()" v-model="ciudadSelect"
                  :disabled="deshabilitarCombos">
                  <option v-for="ciudad in ciudades" :value="ciudad.Id" v-bind:key="ciudad.Id">
                    {{ ciudad.Nombre }}
                  </option>
                </select>
              </div>

              <label class="col-md-4 col-form-label">Estación</label>
              <div class="col-md-8">
                <select class="form-select" v-model="estacionSelect" @change="getEstacion()"
                  :disabled="deshabilitarCombos">
                  <option v-for="estacion in estaciones" :value="estacion.NoEstacion" v-bind:key="estacion.NoEstacion">
                    {{ estacion.Nombre }}
                  </option>
                </select>
                <div v-if="validRFCAnteriorCheck">
                  <br>
                  <input type="checkbox" id="RFCAnteriorCheck" v-model="RFCAnteriorCheck" @change="getEstacionOLD()" />
                  <label for="RFCAnteriorCheck"> RFC Anterior</label>
                </div>
              </div>
              <label class="col-md-4 col-form-label">RFC</label>
              <div class="col-md-8">
                <input type="text" class="form-control" v-model="estacion.RFC" disabled>
              </div>

              <label class="col-md-4 col-form-label">Razón Social</label>
              <div class="col-md-8">
                <input type="text" class="form-control" v-model="estacion.RazonSocialNombre" disabled>
              </div>

              <label class="col-md-4 col-form-label">Serie</label>
              <div class="col-md-8">
                <input type="text" class="form-control" v-model="estacion.Serie" disabled>
              </div>

              <!-- <label class="col-md-4 col-form-label" style="margin-bottom: 5rem;">Forma de pago</label>
              <div class="col-md-8">
               
                <select class="form-select" name="" id="" v-model="formaPagoSelect">
                  <option :value="formaPago.ClaveSAT" v-for="formaPago in formasPago" :key="formaPago.ClaveSAT">{{formaPago.Descripcion}}</option>
                </select>
              </div> -->

            </div>
          </form>
          <div class="hljs-wrapper" slot="outsideBody" v-highlight></div>
        </panel>

        <panel title="Conceptos" bodyClass="">
          <fieldset>
            <div class="row">
              <div class="col-md-3 mb-3">
                <label class="form-label" for="exampleInputEmail1">Ticket</label>
                <input class="form-control" type="number" step="any" v-model="numeroTicket" placeholder="Ticket" />
              </div>

              <div class="col-md-2 mb-3">
                <button type="button" class="btn btn-success me-5px" style="margin-top: 1.3rem; width: 100%;"
                  :disabled="noEstacion == ''" @click="getDespacho()">Agregar</button>
              </div>
            </div>
            <!-- <div class="row" v-if="productos.length>0">
                    <div class="col-md-3 mb-3">
                      <label class="form-label" for="exampleInputEmail1">Producto</label>
                      <select class="form-select" name="" id="" v-model="productoSeleccionado" @change="getProducto()" >
                        <option :value="producto.CodigoProducto" v-for="producto in listaProductos" :key="producto.CodigoProducto">{{producto.Descripcion}}</option>
                      </select>
                    </div>
                    
                    <div class="col-md-2 mb-3">
                      <label class="form-label" for="exampleInputEmail1">Precio</label>
                      <input class="form-control" type="number" step="any" placeholder="Precio" disabled v-model="productoParaAgregar.Precio" />
                    </div>
                    
                    <div class="col-md-2 mb-3">
                      <label class="form-label" for="exampleInputEmail1">Cantidad</label>
                      <input class="form-control" type="number" step="any" placeholder="Cantidad"  v-model="productoParaAgregarCantidad"/>
                    </div>
                    
                    <div class="col-md-3 mb-3">
                      <label class="form-label" for="exampleInputEmail1">Importe</label>
                      <input class="form-control" type="number" step="any" placeholder="Importe" disabled  v-model="productoParaAgregar.Importe" />
                    </div>

                    <div class="col-md-2 mb-3">
                      
                      <button type="button" class="btn btn-success me-5px" style="margin-top: 1.3rem; width: 100%;" :disabled="!(productoParaAgregar.Importe > 0) || cargandoProducto" @click="agregarProducto()">Agregar</button>
                    </div>
               </div> -->

            <div class="row">
              <div class="col-md-12">

                <div class="table-responsive" v-if="productos.length > 0">
                  <table class="table mb-0">
                    <thead>
                      <tr>
                        <th>Transaccion</th>
                        <th>Cantidad</th>
                        <th>Descripcion</th>
                        <th>Precio</th>
                        <th>Total</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="producto in productos" :key="producto.Transaccion">
                        <td>{{ producto.Transaccion }}</td>
                        <td>{{ producto.Cantidad | formatNumber }}</td>
                        <td>{{ producto.Descripcion }}</td>
                        <td>${{ producto.Precio | formatCurrency }}</td>
                        <td>${{ producto.Total }}</td>

                      </tr>
                    </tbody>
                  </table>
                </div>

              </div>
            </div>

          </fieldset>
        </panel>
      </div>

      <div class="col-xl-6">
        <panel title="Receptor" bodyClass="p-0">
          <form class="form-horizontal form-bordered">
            <!-- <div class="row">
              <div class="col-md-12">
                
              </div>
            </div> -->
            <div class="form-group row">


              <label class="col-md-4 col-form-label-top">Buscar Cliente</label>
              <div class="col-md-8">
                <!-- <input type="text" class="form-control" v-model="receptorRFC"> -->

                <div class="input-group">
                  <input type="text" class="form-control" v-model="clienteBuscar" />
                  <button type="button" class="btn btn-success" @click="getClientes()">Buscar</button>
                </div>

                <div class="table-responsive" v-if="clientes.length > 0">
                  <table class="table mb-0">
                    <thead>
                      <tr>
                        <th>RFC</th>
                        <th>Nombre</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="cliente in clientes" :key="cliente.Id">
                        <td>{{ cliente.RFC }}</td>
                        <td>{{ cliente.Nombre }}</td>
                        <td><button type="button" class="btn btn-success btn-xs"
                            @click="seleccionarCliente(cliente)">Seleccionar</button></td>
                      </tr>
                    </tbody>
                  </table>
                </div>

              </div>

              <label class="col-md-4 col-form-label">RFC</label>
              <div class="col-md-8">
                <input type="text" class="form-control" v-model="cliente.RFC" disabled>
              </div>

              <label class="col-md-4 col-form-label">Nombre</label>
              <div class="col-md-8">
                <input type="text" class="form-control" v-model="cliente.Nombre" v-uppercase>
              </div>


              <label class="col-md-4 col-form-label">Código Postal</label>
              <div class="col-md-8">
                <input type="text" class="form-control" v-model="cliente.CodigoPostal">
              </div>

              <label class="col-md-4 col-form-label">Uso CFDI</label>
              <div class="col-md-8">
                <select name="" id="ddlUsoCFDI" class="form-control" v-model="cliente.UsoCFDI">
                  <option :value="uso.Id" v-for="uso in usosCFDI" :key="uso.Id">
                    {{ uso.Id }} - {{ uso.Descripcion }}
                  </option>
                </select>
              </div>

              <label class="col-md-4 col-form-label">Regimen Fiscal</label>
              <div class="col-md-8">
                <select class="form-select" name="" id="" v-model="cliente.RegimenFiscal">
                  <option :value="regimen.Clave" v-for="regimen in regimenesFiscales" :key="regimen.Clave">
                    {{ regimen.Denominacion }}</option>
                </select>
              </div>

              <label class="col-md-4 col-form-label">Correo</label>
              <div class="col-md-8">
                <select class="form-select" name="" id="" v-model="clienteCorreo">
                  <option :value="correo.Direccion" v-for="correo in cliente.Correos" :key="correo.Direccion">
                    {{ correo.Direccion }}</option>
                </select>
              </div>

            </div>
            <div class="row" v-if="!facturado">

              <div class="col-md-12" style="margin-top: 8px;">
                <div class="row">
                  <div class="col-md-12">
                    <h3>Ticket</h3>
                  </div>

                  <label class="col-md-4 col-form-label">Forma de pago</label>
                  <div class="col-md-8">
                    <!-- <input type="text" class="form-control" v-model="datosDespacho.TipoPago" disabled> -->
                    <select class="form-select" name="" id="" v-model="formaPagoSelect">
                      <option :value="formaPago.ClaveSAT" v-for="formaPago in formasPago" :key="formaPago.ClaveSAT">
                        {{ formaPago.Descripcion }}</option>
                    </select>
                  </div>

                  <label class="col-md-4 col-form-label">Transaccion</label>
                  <div class="col-md-8">
                    <input type="text" class="form-control" v-model="datosDespacho.Transaccion" disabled>
                  </div>

                  <label class="col-md-4 col-form-label">Turno</label>
                  <div class="col-md-8">
                    <input type="text" class="form-control" v-model="datosDespacho.Turno" disabled>
                  </div>

                  <label class="col-md-4 col-form-label">Fecha</label>
                  <div class="col-md-8">
                    <input type="text" class="form-control" v-model="datosDespacho.FechaString" disabled>
                  </div>

                  <label class="col-md-4 col-form-label">Hora</label>
                  <div class="col-md-8">
                    <input type="text" class="form-control" v-model="datosDespacho.Hora" disabled>
                  </div>

                  <label class="col-md-4 col-form-label">Bomba</label>
                  <div class="col-md-8">
                    <input type="text" class="form-control" v-model="datosDespacho.Bomba" disabled>
                  </div>

                  <label class="col-md-4 col-form-label">Total</label>
                  <div class="col-md-8">
                    <input type="text" class="form-control" v-model="datosDespacho.Total" disabled>
                  </div>

                </div>

              </div>
              <div class="col-md-4" style="margin-top: 8px;">
              </div>
              <div class="col-md-4" style="margin-top: 8px;">
                <button class="btn btn-lg btn-success" style="width: 100%;" @click="procesarTimbrar()"
                  :disabled="!(productos.length > 0) || formaPagoSelect == ''">Facturar</button>
              </div>
              <div class="col-md-4" style="margin-top: 8px;">
              </div>

            </div>
            <div class="row">

              <div class="col-md-4" style="margin-top: 8px;">
              </div>
              <div class="col-md-2 text-center">
                <button type="button" v-if="facturado" style="margin-top: 15px; width: 100%;" @click="descargarPDF"
                  class="btn btn-lg btn-primary">PDF</button>
              </div>
              <div class="col-md-2 text-center">
                <button type="button" v-if="facturado" style="margin-top: 15px; width: 100%;" @click="descargarXML"
                  class="btn btn-lg btn-primary">XML</button>
              </div>
            </div>

          </form>
          <div class="hljs-wrapper" slot="outsideBody" v-highlight></div>
        </panel>
      </div>
    </div>

    <!-- bottom left vue-notification -->
    <notifications group="top-right" position="top right" :speed="1000" />
  </div>
</template>

<script>

import Vue from "vue";
var numeral = require("numeral");
Vue.filter("formatCurrency", function (value) {
  return numeral(value).format("0,0.00"); // displaying other groupings/separators is possible, look at the docs
});
Vue.filter("formatNumber", function (value) {
  return numeral(value).format("0,0.0000"); // displaying other groupings/separators is possible, look at the docs
});

Vue.directive("uppercase", {
  update: function (el) {
    el.value = el.value.toUpperCase()
  }
})
import moment from 'moment'
import axios from "axios";
export default {
  mounted: function () {
    debugger; // eslint-disable-line no-debugger
    var userSetting = JSON.parse(localStorage.getItem('userSettings'));
    this.usuario = userSetting.Usuario;
    this.GetRegimenFiscales();
    this.GetUsosCFDI();
    this.GetFormasDePago();
    this.getCiudades();
    this.ciudadSelect = userSetting.CiudadId;
    this.getEstaciones();
    this.estacionSelect = userSetting.Estacion;
    if (this.estacionSelect != null && this.estacionSelect > 0) {
      this.getEstacion();
    }

    this.IdRol = userSetting.IdRol;

    if (this.IdRol == 3 || this.IdRol == 7) {
      this.deshabilitarCombos = true;
    }

  },
  data() {
    return {

      deshabilitarCombos: false,
      cargandoProducto: true,
      ciudades: [],
      estaciones: [],
      estacion: {},
      ciudadSelect: undefined,
      estacionSelect: undefined,
      emisorRFC: "",
      emisorRazonSocial: "",
      emisorSerie: "",
      receptorRFC: "",
      receptorNombre: "",
      receptorCodigoPostal: "",
      receptorUsoCfdi: "",
      receptorRegimenFiscal: "",
      receptorCorreo: "",
      clienteBuscar: "",
      clientes: [],
      cliente: {},
      clienteCopia: {},
      clienteCorreo: "",
      formaPagoSelect: "",
      productos: [],
      listaProductos: [],
      noEstacion: "",
      numeroTicket: "",
      gasolinera: "",
      productoParaAgregar: {},
      productoParaAgregarCantidad: 1,
      datosDespacho: {
        "Transaccion": "",
        "Gasolinera": "",
        "Turno": "",
        "Fecha": "",
        "FechaString": "",
        "Hora": "",
        "Bomba": "",
        "Total": "",
        "TipoPago": "",
        "IdTipoPago": "",
        "FormaPagoSAT": "",
      },

      facturado: false,
      pdfBase64Resend: undefined,
      uuidRespuesta: "",
      rfcRespuesta: "",
      xmlResend: undefined,
      alertMessage: "",
      alertTitle: "",
      jsonTimbrarString: "",
      usuario: "",
      subtotal: "",
      ivaTotal: "",
      total: "",
      formasPago: [],
      formaPagoSeleccionada: "03",
      clienteMostrarCorreo: "aurora.rubio@rendilitros.com",
      jsonTimbrar: {
        "tipoComprobante": "I",
        "Serie": "A",
        "RFCEmisor": "",
        "FormaPago": "03",
        "MetodoPago": "PUE",
        "Usuario": "",
        "Receptor": {
          "RFC": "",
          "Nombre": "",
          "UsoCFDI": "G03",
          "CodigoPostal": "",
          "RegimenFiscal": "",
          "Correo": "aurora.rubio@rendilitros.com"
        },
        "Conceptos": [
          {
            "ClaveProdServ": "80101500",
            "ClaveUnidad": "E48",
            "Descripcion": "Servicios administrativos",
            "NoIdentificacion": "",
            "ObjetoImp": "02",
            "Cantidad": "1.00",
            "ValorUnitario": 0,
            "Importe": 0,
            "TasaIVA": ".080000"
          }
        ]
      },
      conceptoClaveProducto: "801101500",
      conceptoClaveUnidad: "E48",
      conceptoCantidad: 1,
      conceptoDescripcion: "",
      valorUnitario: undefined,
      usosCFDI: [],
      regimenesFiscales: [],
      emisorSeleccionado: "",
      clienteSeleccionado: "",
      tasaIvaSeleccionado: ".080000",
      emisorMostrar: {
        RFC: "",
        Nombre: "",
        Certificado: "",
        Regimen: "",
        Serie: "",
      },
      clienteMostrar: {
        RFC: "",
        Nombre: "",
        Correo: "",
        Direccion: "",
      },
      EmisoresSelect: ["EPI0103167Z2", "PRO020807JB7"],
      ClientesSelect: ["SYA620911QY8", "SYA980610FH6"],
      UsosCFDI: ["Gastos Generales", "Adquisición de mercancias"],
      TasasIva: [".160000", ".080000"],
      Emisores: [
        {
          RFC: "EPI0103167Z2",
          Nombre: "ESTACION PIRU SA DE CV",
          Certificado: "00001000000502247540",
          Regimen: "General de Ley Personas Morales",
          Serie: "B",
        },
        {
          RFC: "PRO020807JB7",
          Nombre: "PRONTOGAS SA DE CV",
          Certificado: "00001000000408280487",
          Regimen: "General de Ley Personas Morales",
          Serie: "C",
        },
      ],
      Clientes: [],
      colorpicker: {
        color1: "rgb(135,83,222,0.8)",
        color2: "rgb(52,143,226)",
      },
      sliderDefault: {
        value: 9,
        max: 25,
      },
      sliderLabel: {
        value: "2018-10-08",
        width: "80%",
        tooltip: "always",
        disabled: false,
        piecewise: true,
        piecewiseLabel: true,
        style: {
          marginLeft: "10%",
        },
        data: ["2016-10-05", "2016-10-06", "2016-10-07"],
      },
      sliderInterval: {
        lazy: true,
        interval: 5,
        max: 25,
        piecewise: true,
      },
      sliderFormatter: {
        value: [0, 96],
        min: 0,
        max: 500,
        disabled: false,
        show: true,
        useKeyboard: true,
        tooltip: "always",
        formatter: "¥{value}",
        mergeFormatter: "¥{value1} ~ ¥{value2}",
      },
      sliderFixedRange: {
        value: [45, 65],
        min: 0,
        max: 100,
        interval: 1,
        fixed: true,
        processDragable: true,
      },
      tagsArray: ["tags1", "tags2"],
      date: new Date(),
      options: {
        format: "DD/MM/YYYY",
        useCurrent: false,
      },
      form: {
        time: null,
        startDate: null,
        endDate: null,
      },
      configs: {
        timePicker: {
          format: "LT",
          useCurrent: false,
        },
        start: {
          format: "DD/MM/YYYY",
          useCurrent: false,
          showClear: true,
          showClose: true,
          minDate: new Date(),
          maxDate: false,
        },
        end: {
          format: "DD/MM/YYYY",
          useCurrent: false,
          showClear: true,
          showClose: true,
          minDate: new Date(),
        },
      },
      validRFCAnteriorCheck: false,
      RFCAnteriorCheck: false,
    };
  },
  watch: {
    productoParaAgregarCantidad(cantidad) {
      debugger; // eslint-disable-line no-debugger
      var self = this;
      self.productoParaAgregar.Importe = cantidad * self.productoParaAgregar.Precio;
    },
    emisorSeleccionado(rfc) {
      // var self= this;
      this.getEmisor(rfc);
      this.GetClientesServAdministrativos();
      //   debugger; // eslint-disable-line no-debugger
    },
    clienteSeleccionado(rfc) {
      this.getCliente(rfc);
      //   debugger; // eslint-disable-line no-debugger
    },
    valorUnitario(value) {
      this.calcular();
      this.jsonTimbrar.Conceptos[0].ValorUnitario = (parseFloat(value)).toFixed(2);

    },
    tasaIvaSeleccionado(value) {
      this.calcular();
      this.jsonTimbrar.Conceptos[0].TasaIVA = value;
    },
    conceptoCantidad(value) {
      this.calcular();
      this.jsonTimbrar.Conceptos[0].Cantidad = (parseFloat(value)).toFixed(2);
    }
  },
  methods: {

    async procesarTimbrar() {
      var self = this;
      if (!self.RFCAnteriorCheck) {
        self.timbrar();
      } else {
        self.timbrarOld();
      }
    },
    async timbrar() {
      var self = this;

      //request timbrar
      var jsonToSend = {
        "Data": {
          "Gasolinera": self.datosDespacho.Gasolinera,
          "Estacion": self.noEstacion,
          "Despacho": self.datosDespacho.Transaccion,
          "tipoComprobante": "I",
          "IdCliente": self.cliente.Id,
          "RFC": self.cliente.RFC,
          "Nombre": self.cliente.Nombre,
          "UsoCFDI": self.cliente.UsoCFDI,
          "Correo": self.clienteCorreo,
          "FormaPago": self.formaPagoSelect,
          "OcultarCampos": false,
          "Usuario": self.usuario,
          "DomicilioFiscalReceptor": self.cliente.CodigoPostal,
          "RegimenFiscalReceptor": self.cliente.RegimenFiscal,

        }
      };
      try {

        const response = await axios.post("CFDI40/Timbrar", jsonToSend);
        if (response.data.Success) {
          if (response.data.response.length == 0) {
            this.alertMessage =
              "No se encontró productos";
            this.alertTitle = "Error";
            this.show("top-right", "error");
          } else {
            debugger; // eslint-disable-line no-debugger
            self.productos = [];
            self.alertMessage = "Se Timbró correctamente";
            self.alertTitle = "Correcto";
            self.show("top-right", "success");

            self.pdfBase64Resend = response.data.response.PdfBase64;
            self.xmlResend = response.data.response.XML;
            self.uuidRespuesta = response.data.response.UUID;
            self.rfcRespuesta = self.cliente.RFC;

            debugger; // eslint-disable-line no-debugger
            self.actualizarCliente();
            self.numeroTicket = "";

            self.cliente.RFC = "";
            self.cliente.Nombre = "";
            self.cliente.CodigoPostal = "";
            self.cliente.UsoCFDI = "";
            self.cliente.RegimenFiscal = "";
            self.clienteCorreo = "";

            self.facturado = true;


          }
        } else {
          this.alertMessage = response.data.Error + " - " + response.data.Message;
          this.alertTitle = "Error";
          this.show("top-right", "error");
        }
      } catch (e) {
        this.alertMessage = "Ocurrio un error";
        this.alertTitle = "Error";
        this.show("top-right", "error");
      }
    },
    async timbrarOld() {
      var self = this;

      //request timbrar
      var jsonToSend = {
        "Data": {
          "Gasolinera": self.datosDespacho.Gasolinera,
          "Estacion": self.noEstacion,
          "Despacho": self.datosDespacho.Transaccion,
          "tipoComprobante": "I",
          "IdCliente": self.cliente.Id,
          "RFC": self.cliente.RFC,
          "Nombre": self.cliente.Nombre,
          "UsoCFDI": self.cliente.UsoCFDI,
          "Correo": self.clienteCorreo,
          "FormaPago": self.formaPagoSelect,
          "OcultarCampos": false,
          "Usuario": self.usuario,
          "DomicilioFiscalReceptor": self.cliente.CodigoPostal,
          "RegimenFiscalReceptor": self.cliente.RegimenFiscal,

        }
      };
      try {

        const response = await axios.post("CFDI40/TimbrarOld", jsonToSend);
        if (response.data.Success) {
          if (response.data.response.length == 0) {
            this.alertMessage =
              "No se encontró productos";
            this.alertTitle = "Error";
            this.show("top-right", "error");
          } else {
            debugger; // eslint-disable-line no-debugger
            self.productos = [];
            self.alertMessage = "Se Timbró correctamente";
            self.alertTitle = "Correcto";
            self.show("top-right", "success");

            self.pdfBase64Resend = response.data.response.PdfBase64;
            self.xmlResend = response.data.response.XML;
            self.uuidRespuesta = response.data.response.UUID;
            self.rfcRespuesta = self.cliente.RFC;

            debugger; // eslint-disable-line no-debugger
            self.actualizarCliente();
            self.numeroTicket = "";

            self.cliente.RFC = "";
            self.cliente.Nombre = "";
            self.cliente.CodigoPostal = "";
            self.cliente.UsoCFDI = "";
            self.cliente.RegimenFiscal = "";
            self.clienteCorreo = "";

            self.facturado = true;


          }
        } else {
          this.alertMessage = response.data.Error + " - " + response.data.Message;
          this.alertTitle = "Error";
          this.show("top-right", "error");
        }
      } catch (e) {
        this.alertMessage = "Ocurrio un error";
        this.alertTitle = "Error";
        this.show("top-right", "error");
      }
    },
    async actualizarCliente() {

      var self = this;

      debugger; // eslint-disable-line no-debugger
      if (
        (self.clienteCopia.Nombre != self.cliente.Nombre ||
          self.clienteCopia.CodigoPostal != self.cliente.Nombre ||
          self.clienteCopia.UsoCFDI != self.cliente.UsoCFDI ||
          self.clienteCopia.RegimenFiscal != self.cliente.RegimenFiscal)
        && self.clienteCopia.Id == self.cliente.Id
      ) {
        var dataToSend = {
          Data: {
            Id: this.cliente.Id,
            Nombre: this.cliente.Nombre,
            UsoCFDI: this.cliente.UsoCFDI,
            RegimenFiscal: this.cliente.RegimenFiscal,
            CodigoPostal: this.cliente.CodigoPostal,
            Estatus: 1,
          },
        }
        try {

          debugger; // eslint-disable-line no-debugger
          const response = await axios.post("Cliente/UdpCliente", dataToSend);

          debugger; // eslint-disable-line no-debugger

        } catch (e) {
          debugger; // eslint-disable-line no-debugger
          // this.alertMessage = "Ocurrio un error";
          // this.alertTitle = "Error";
          // this.show("top-right", "error");
        }

      }

    },
    eliminarProducto(index) {
      this.productos.splice(index, 1);
    },
    agregarProducto() {
      debugger; // eslint-disable-line no-debugger
      var self = this;
      var productoSeleccionado = self.productoSeleccionado;

      console.log(productoSeleccionado);
      if (productoSeleccionado != "0") {
        self.productoParaAgregar.Cantidad = self.productoParaAgregarCantidad;



        self.productoParaAgregar.Subtotal = self.productoParaAgregarCantidad * self.productoParaAgregar.ValorUnitario;
        self.productoParaAgregar.Total = self.productoParaAgregarCantidad * self.productoParaAgregar.Precio;
        self.productoParaAgregar.ImporteIVA = (self.productoParaAgregarCantidad * self.productoParaAgregar.Precio) - (self.productoParaAgregarCantidad * self.productoParaAgregar.ValorUnitario); //Math.Round( (cantidad * Producto.Precio) - (cantidad * Producto.ValorUnitario), 2)
        self.productoParaAgregar.ImporteIEPS = 0;




        self.productoParaAgregar.Combustible = false;

        self.productoParaAgregar.FechaCG = self.datosDespacho.FechaCG;
        self.productoParaAgregar.HoraCG = self.datosDespacho.HoraCG;
        self.productoParaAgregar.Fecha = self.datosDespacho.Fecha;
        self.productoParaAgregar.Hora = self.datosDespacho.Hora;
        self.productoParaAgregar.Transaccion = self.datosDespacho.Transaccion + 1;
        self.datosDespacho.Transaccion++;



        self.productos.push(self.productoParaAgregar);
      } else {
        this.alertMessage = "Seleccione un producto";
        this.alertTitle = "Error";
        this.show("top-right", "error");
      }
    },
    async getProducto() {
      debugger; // eslint-disable-line no-debugger
      var self = this;
      self.cargandoProducto = true;
      try {
        const response = await axios.post("Producto/GetProducto", {
          "Data": {
            "Gasolinera": self.gasolinera,
            "Estacion": self.noEstacion,
            "Codigo": self.productoSeleccionado
          }
        });
        if (response.data.Success) {
          if (response.data.response.length == 0) {
            this.alertMessage =
              "No se encontró productos";
            this.alertTitle = "Error";
            this.show("top-right", "error");
          } else {
            self.productoParaAgregar = response.data.response;
            self.productoParaAgregarCantidad = 1;
            self.productoParaAgregar.Importe = self.productoParaAgregarCantidad * self.productoParaAgregar.Precio;
            self.cargandoProducto = false;
            debugger; // eslint-disable-line no-debugger
          }
        } else {
          this.alertMessage = response.data.Error;
          this.alertTitle = "Error";
          this.show("top-right", "error");
        }
      } catch (e) {
        this.alertMessage = "Ocurrio un error";
        this.alertTitle = "Error";
        this.show("top-right", "error");
      }
    },
    async agregarTicket() {
      this.getDespachoFacturado();
    },
    async getDespachoFacturado() {
      var self = this;
      try {
        const response = await axios.post("Despacho/GetTicketADDES", {
          Data: {
            "Ticket": self.numeroTicket
          },
        });
        debugger; // eslint-disable-line no-debugger
        if (response.data.Success) {
          if (response.data.response == null) {
            this.alertMessage = response.data.Message;
            this.alertTitle = "Error";
            this.show("top-right", "error");
          } else {

            debugger; // eslint-disable-line no-debugger
            if (response.data.Success) {
              self.getDespacho();
            } else {
              self.alertMessage = response.data.response.Error;
              self.alertTitle = "Respuesta";
              self.show("top-right", "error");

            }

          }
        } else {
          this.alertMessage = response.data.Message;
          this.alertTitle = "Error";
          this.show("top-right", "error");
        }
      } catch (e) {
        this.alertMessage = "Ocurrio un error";
        this.alertTitle = "Error";
        this.show("top-right", "error");
      }
    },
    async getDespacho() {
      debugger; // eslint-disable-line no-debugger
      var self = this;
      var numTicket = self.numeroTicket;
      var numEstacion = parseInt(numTicket.substring(0, 5));

      //var transaccion = parseInt(numTicket.substring(5, numTicket.length));

      // var transaccion =  "";

      if (numEstacion == self.noEstacion) {
        try {


          if (!self.RFCAnteriorCheck) {
            const response = await axios.post("Despacho/GetTicketADDES", {
              "Data": {
                "Ticket": numTicket
              }
            });

            if (response.data.Success) {
              if (response.data.response == null) {
                this.alertMessage = "No se encontró información.";
                this.alertTitle = "Error";
                this.show("top-right", "error");
              } else {

                // self.productos.push(response.data.response);
                self.productos = response.data.response.Detalle;


                self.datosDespacho.Transaccion = response.data.response.Transaccion;
                self.datosDespacho.Gasolinera = response.data.response.Gasolinera;
                self.datosDespacho.Turno = response.data.response.Turno;
                self.datosDespacho.Fecha = response.data.response.Fecha;
                self.datosDespacho.FechaString = moment(response.data.response.Fecha).format("DD/MM/YYYY");
                self.datosDespacho.Hora = response.data.response.Hora;
                self.datosDespacho.Bomba = response.data.response.Bomba;
                self.datosDespacho.Total = response.data.response.Total.toFixed(2);
                self.datosDespacho.TipoPago = response.data.response.TipoPago;
                self.datosDespacho.IdTipoPago = response.data.response.IdTipoPago;
                self.datosDespacho.FormaPagoSAT = response.data.response.FormaPagoSAT;


                self.formaPagoSelect = response.data.response.FormaPagoSAT;
                // self.datosDespacho.FechaCG = response.data.response[0].Fecha;
                // self.datosDespacho.HoraCG = response.data.response[0].Hora;
                // self.datosDespacho.Fecha = response.data.response[0].Fecha;
                // self.datosDespacho.Hora = moment(response.data.response[0].Fecha).format("h:mm:ss");
                // self.datosDespacho.Transaccion = response.data.response[0].Transaccion;

                // for(var n=0;n<self.productos.length;n++){
                //   self.productos[n].Combustible = true;
                //   self.productos[n].Hora = moment(response.data.response[n].Fecha).format("h:mm:ss");
                // }
              }
            } else {
              this.alertMessage = response.data.Message;
              this.alertTitle = "Error";
              this.show("top-right", "error");
            }

          } else {

            const response = await axios.post("Despacho/GetTicketADDESold", {
              "Data": {
                "Ticket": numTicket
              }
            });

            if (response.data.Success) {
              if (response.data.response == null) {
                this.alertMessage = "No se encontró información.";
                this.alertTitle = "Error";
                this.show("top-right", "error");
              } else {

                // self.productos.push(response.data.response);
                self.productos = response.data.response.Detalle;


                self.datosDespacho.Transaccion = response.data.response.Transaccion;
                self.datosDespacho.Gasolinera = response.data.response.Gasolinera;
                self.datosDespacho.Turno = response.data.response.Turno;
                self.datosDespacho.Fecha = response.data.response.Fecha;
                self.datosDespacho.FechaString = moment(response.data.response.Fecha).format("DD/MM/YYYY");
                self.datosDespacho.Hora = response.data.response.Hora;
                self.datosDespacho.Bomba = response.data.response.Bomba;
                self.datosDespacho.Total = response.data.response.Total.toFixed(2);
                self.datosDespacho.TipoPago = response.data.response.TipoPago;
                self.datosDespacho.IdTipoPago = response.data.response.IdTipoPago;
                self.datosDespacho.FormaPagoSAT = response.data.response.FormaPagoSAT;


                self.formaPagoSelect = response.data.response.FormaPagoSAT;
                // self.datosDespacho.FechaCG = response.data.response[0].Fecha;
                // self.datosDespacho.HoraCG = response.data.response[0].Hora;
                // self.datosDespacho.Fecha = response.data.response[0].Fecha;
                // self.datosDespacho.Hora = moment(response.data.response[0].Fecha).format("h:mm:ss");
                // self.datosDespacho.Transaccion = response.data.response[0].Transaccion;

                // for(var n=0;n<self.productos.length;n++){
                //   self.productos[n].Combustible = true;
                //   self.productos[n].Hora = moment(response.data.response[n].Fecha).format("h:mm:ss");
                // }
              }
            } else {
              this.alertMessage = response.data.Message;
              this.alertTitle = "Error";
              this.show("top-right", "error");
            }
          }

        } catch (e) {
          this.alertMessage = "Ocurrio un error";
          this.alertTitle = "Error";
          this.show("top-right", "error");
        }

      } else {
        this.alertMessage = "El ticket no corresponde a la estación.";
        this.alertTitle = "Error";
        this.show("top-right", "error");
      }
    },
    async getProductos() {
      var self = this;
      try {
        const response = await axios.post("Producto/GetProductos", {
          "Data": {
            "Gasolinera": self.gasolinera,
            "Estacion": self.noEstacion,
            "Producto": 0
          }
        });
        if (response.data.Success) {
          if (response.data.response.length == 0) {
            this.alertMessage = "No se encontró productos";
            this.alertTitle = "Error";
            this.show("top-right", "error");
          } else {
            this.listaProductos = response.data.response;
          }
        } else {
          this.alertMessage = response.data.Error;
          this.alertTitle = "Error";
          this.show("top-right", "error");
        }
      } catch (e) {
        this.alertMessage = "Ocurrio un error";
        this.alertTitle = "Error";
        this.show("top-right", "error");
      }
    },
    async seleccionarCliente(cliente) {
      var self = this;
      try {
        const response = await axios.post("Cliente/GetCliente", {
          Data: {
            RFC: cliente.RFC,
            Id: 0,
            Tarjeta: "",
          },
        });
        if (response.data.Success) {
          if (response.data.response == null) {
            this.alertMessage =
              "No se encontró información del cliente especificado.";
            this.alertTitle = "Error";
            this.show("top-right", "error");
          } else {

            self.cliente = response.data.response;
            self.cliente.Nombre = response.data.response.Nombre.toUpperCase();
            self.clienteCopia = response.data.response;
            debugger; // eslint-disable-line no-debugger
            self.clientes = [];
            self.clienteBuscar = "";
            if (response.data.response.Correos.length > 0) {
              self.clienteCorreo = response.data.response.Correos[0].Direccion;
            }

          }
        } else {
          this.alertMessage = response.data.Error;
          this.alertTitle = "Error";
          this.show("top-right", "error");
        }
      } catch (e) {
        this.alertMessage = "Ocurrio un error";
        this.alertTitle = "Error";
        this.show("top-right", "error");
      }
    },
    async getClientes() {
      this.textoBotonBuscar = "Buscando...";
      try {
        const response = await axios.post("Cliente/GetClientes", {
          Data: {
            Busqueda: this.clienteBuscar,
            IncluirCancelados: 1,
          },
        });
        if (response.data.Success) {
          if (response.data.response.length == 0) {
            this.alertMessage =
              "No se encontró información del cliente especificado.";
            this.alertTitle = "Error";
            this.show("top-right", "error");
          } else {
            this.clientes = response.data.response;
          }
        } else {
          this.alertMessage = response.data.Error;
          this.alertTitle = "Error";
          this.show("top-right", "error");
        }
        this.textoBotonBuscar = "Buscar";
      } catch (e) {
        this.alertMessage = "Ocurrio un error";
        this.alertTitle = "Error";
        this.show("top-right", "error");
      }
    },
    async getCiudades() {
      try {
        const response = await axios.get('Catalogos/GetCiudades');
        //debugger; // eslint-disable-line no-debugger
        if (response.data.Success) {
          if (response.data.response.length == 0) {
            this.alertMessage = "No se encontró información.";
            this.alertTitle = "Error";
            this.show('top-right', 'error');
          }
        } else {
          console.log("error")
          this.alertMessage = response.data.Error;
          this.alertTitle = "Error";
          this.show('top-right', 'error');
        }
        this.ciudades = response.data.response;
      } catch (e) {
        //this.alert = true;
        //this.error = 'Invalid email/password';
      }
    },
    async getEstaciones() {
      try {
        debugger; // eslint-disable-line no-debugger
        const response = await axios.post('Estacion/GetEstaciones?ciudad=' + this.ciudadSelect);
        if (response.data.Success) {
          if (response.data.response.length == 0) {
            this.alertMessage = "No se encontró información.";
            this.alertTitle = "Error";
            this.show('top-right', 'error');
          }
        } else {
          console.log("error")
          this.alertMessage = response.data.Error;
          this.alertTitle = "Error";
          this.show('top-right', 'error');
        }
        this.estaciones = response.data.response;
        this.productos = [];
        // this.getProductos();
      } catch (e) {
        //this.alert = true;
        //this.error = 'Invalid email/password';
      }
    },
    async getEstacion() {
      var self = this;
      try {
        const response = await axios.post('Estacion/GetEstacion?noEstacion=' + this.estacionSelect + '&Gasolinera=0&MostrarTodo=False');
        if (response.data.Success) {
          if (response.data.response.length == 0) {
            this.alertMessage = "No se encontró información.";
            this.alertTitle = "Error";
            this.show('top-right', 'error');
          }
        } else {
          console.log("error")
          this.alertMessage = response.data.Error;
          this.alertTitle = "Error";
          this.show('top-right', 'error');
        }

        if (response.data.response.NoEstacion == 2707 || response.data.response.NoEstacion == 8385) {
          console.log(response.data.response.NoEstacion);
          self.validRFCAnteriorCheck = true;
          self.RFCAnteriorCheck = false;
        } else {
          self.validRFCAnteriorCheck = false;
          self.RFCAnteriorCheck = false;
        }


        // debugger; // eslint-disable-line no-debugger
        self.estacion = response.data.response;
        self.noEstacion = response.data.response.NoEstacion;
        self.gasolinera = response.data.response.CodGas;
        self.productoSeleccionado = "0";

        this.productos = [];
        //this.getProductos();

      } catch (e) {
        //this.alert = true;
        //this.error = 'Invalid email/password';
      }
    },
    descargarPDF() {

      debugger; // eslint-disable-line no-debugger
      var self = this;
      var filename = self.rfcRespuesta + "_" + self.uuidRespuesta + ".pdf";
      // The actual download
      var link = document.createElement("a");
      //link.href = window.URL.createObjectURL(blob);
      link.href = "data:application/pdf;base64," + self.pdfBase64Resend;
      link.download = filename;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    descargarXML() {

      debugger; // eslint-disable-line no-debugger
      var self = this;
      var xmltext = self.xmlResend;

      // var filename = "Factura_Electronica.xml";
      var filename = self.rfcRespuesta + "_" + self.uuidRespuesta + ".xml";
      var pom = document.createElement("a");
      var bb = new Blob([xmltext], { type: "text/plain" });

      pom.setAttribute("href", window.URL.createObjectURL(bb));
      pom.setAttribute("download", filename);

      pom.dataset.downloadurl = ["text/plain", pom.download, pom.href].join(
        ":"
      );
      pom.draggable = true;
      pom.classList.add("dragout");
      pom.click();
    },
    calcular() {
      this.subtotal = (this.conceptoCantidad * this.valorUnitario).toFixed(2);
      this.ivaTotal = (parseFloat(this.subtotal) * parseFloat(this.tasaIvaSeleccionado)).toFixed(2);
      this.total = (parseFloat(this.subtotal) + parseFloat(this.ivaTotal)).toFixed(2);
    },
    async facturar() {
      var self = this;
      this.jsonTimbrar.Conceptos[0].Importe = self.subtotal;
      self.jsonTimbrarString = JSON.stringify(self.jsonTimbrar);
      console.log(self.jsonTimbrarString);
      debugger; // eslint-disable-line no-debugger
      try {
        const response = await axios.post("CFDI/TimbrarSA", {
          Data: self.jsonTimbrar
        });
        if (response.data.Success) {

          debugger; // eslint-disable-line no-debugger
          if (response.data.response != undefined) {
            self.alertMessage = "Se timbró la factura exitosamente";
            self.alertTitle = "Timbrado exitoso";
            self.show("top-right", "success");


            self.pdfBase64Resend = response.data.response.PdfBase64;
            self.xmlResend = response.data.response.Xml;

            self.facturado = true;
            self.clienteSeleccionado = undefined;
            self.emisorSeleccionado = undefined;

          } else {
            self.alertMessage = "Error al timbrar";
            self.alertTitle = "Error";
            self.show("top-right", "error");

            self.facturado = false;
          }
        } else {

          debugger; // eslint-disable-line no-debugger
          self.alertMessage = response.data.Error;
          self.alertTitle = "Error";
          self.show("top-right", "error");
          self.facturado = false;
        }
      } catch (e) {

        debugger; // eslint-disable-line no-debugger
        self.alertMessage = "Ocurrio un error";
        self.alertTitle = "Error";
        self.show("top-right", "error");
        self.facturado = false;
      }
    },
    async GetFormasDePago() {

      //GetFormasPagoADDES
      try {
        // const response = await axios.get("Catalogos/GetFormasPago?aplicaEstacion=1");


        const response = await axios.post("Catalogos/GetFormasPagoADDES", {
          Data: {
            "AplicaEstacion": 1
          },
        });
        if (response.data.Success) {
          if (response.data.response.length == 0) {
            this.alertMessage =
              "No se encontró formas de pago";
            this.alertTitle = "Error";
            this.show("top-right", "error");
          } else {
            this.formasPago = response.data.response;
          }
        } else {
          this.alertMessage = response.data.Error;
          this.alertTitle = "Error";
          this.show("top-right", "error");
        }
      } catch (e) {
        this.alertMessage = "Ocurrio un error";
        this.alertTitle = "Error";
        this.show("top-right", "error");
      }
    },
    async GetClientesServAdministrativos() {
      var emisorSeleccionado = this.emisorSeleccionado;
      debugger; // eslint-disable-line no-debugger

      try {
        const response = await axios.post("Cliente/GetClientesServAdministrativos", {
          Data: {
            Busqueda: emisorSeleccionado
          },
        });
        if (response.data.Success) {
          if (response.data.response.length == 0) {
            this.alertMessage =
              "No se encontró clientes";
            this.alertTitle = "Error";
            this.show("top-right", "error");
          } else {
            this.Clientes = response.data.response;
          }
        } else {
          this.alertMessage = response.data.Error;
          this.alertTitle = "Error";
          this.show("top-right", "error");
        }
      } catch (e) {
        this.alertMessage = "Ocurrio un error";
        this.alertTitle = "Error";
        this.show("top-right", "error");
      }
    },
    async GetRegimenFiscales() {
      try {
        debugger; // eslint-disable-line no-debugger
        const response = await axios.get("Catalogos/GetRegimenFiscales");
        if (response.data.Success) {
          if (response.data.response.length == 0) {
            this.alertMessage =
              "No se encontró usos CFDI";
            this.alertTitle = "Error";
            this.show("top-right", "error");
          } else {
            debugger; // eslint-disable-line no-debugger
            this.regimenesFiscales = response.data.response;
          }
        } else {
          this.alertMessage = response.data.Error;
          this.alertTitle = "Error";
          this.show("top-right", "error");
        }
      } catch (e) {
        this.alertMessage = "Ocurrio un error";
        this.alertTitle = "Error";
        this.show("top-right", "error");
      }
    },
    async GetUsosCFDI() {
      try {
        const response = await axios.get("Catalogos/GetUsosCFDI");
        if (response.data.Success) {
          if (response.data.response.length == 0) {
            this.alertMessage =
              "No se encontró usos CFDI";
            this.alertTitle = "Error";
            this.show("top-right", "error");
          } else {
            this.usosCFDI = response.data.response;
            this.usoCfdiSeleccionado = "G03";
          }
        } else {
          this.alertMessage = response.data.Error;
          this.alertTitle = "Error";
          this.show("top-right", "error");
        }
      } catch (e) {
        this.alertMessage = "Ocurrio un error";
        this.alertTitle = "Error";
        this.show("top-right", "error");
      }
    },
    getCliente(rfc) {
      debugger; // eslint-disable-line no-debugger
      if (rfc != null) {
        for (var n = 0; n < this.Clientes.length; n++) {
          if (this.Clientes[n].RFC == rfc) {
            this.clienteMostrar = this.Clientes[n];
            this.jsonTimbrar.Receptor.RFC = this.clienteMostrar.RFC;
            this.jsonTimbrar.Receptor.Nombre = this.clienteMostrar.Nombre;
            //this.jsonTimbrar.Receptor.UsoCFDI = this.clienteMostrar.UsoCFDI;
            this.jsonTimbrar.Receptor.CodigoPostal = this.clienteMostrar.CodigoPostal;
            this.jsonTimbrar.Receptor.RegimenFiscal = this.clienteMostrar.RegimenFiscal;


            console.log(JSON.stringify(this.clienteMostrar));
          }
        }
      } else {
        this.clienteMostrar = {
          RFC: "",
          Nombre: "",
          Correo: "",
          Direccion: "",
        };
      }
    },
    getEmisor(rfc) {
      //   debugger; // eslint-disable-line no-debugger
      if (rfc != null) {
        for (var n = 0; n < this.Emisores.length; n++) {
          if (this.Emisores[n].RFC == rfc) {
            this.emisorMostrar = this.Emisores[n];

            this.jsonTimbrar.Serie = this.emisorMostrar.Serie;

          }
        }
        this.jsonTimbrar.RFCEmisor = rfc;
      } else {
        this.emisorMostrar = {
          RFC: "",
          Nombre: "",
          Certificado: "",
          Regimen: "",
        };
      }
    },
    onStartChange(e) {
      this.$set(this.configs.end, "minDate", e.date || null);
    },
    onEndChange(e) {
      this.$set(this.configs.start, "maxDate", e.date || null);
    },
    show(group, type = '') {
      const text = this.alertMessage;
      this.$notify({
        group,
        title: this.alertTitle,
        text,
        type,
        data: {
          randomNumber: Math.random()
        }
      })
    },
    clean(group) {
      this.$notify({ group, clean: true })
    },
    swalNotification(swalType) {
      var btnClass = (swalType == 'error') ? 'danger' : swalType;
      btnClass = (swalType == 'question') ? 'primary' : btnClass;
      this.$swal({
        title: 'Are you sure?',
        text: 'You will not be able to recover this imaginary file!',
        type: swalType,
        showCancelButton: true,
        buttonsStyling: false,
        confirmButtonText: swalType.charAt(0).toUpperCase() + swalType.substr(1).toLowerCase(),
        cancelButtonText: 'Cancel',
        confirmButtonClass: 'btn me-5px btn-' + btnClass + '',
        cancelButtonClass: 'btn btn-default',
      })
    },
    showNotification(title, text, type) {
      this.alertTitle = title;
      this.alertMessage = text;
      this.show("top-right", type);
    },
    async getEstacionOLD() {
      var self = this;
      try {

        var dataToSend = {
          Data: {
            Estacion: self.noEstacion
          },
        };

        if (self.RFCAnteriorCheck) {
          const response = await axios.post('Estacion/GetvEstacionOLD', dataToSend);
          if (response.data.Success) {
            let data = response.data.response;
            self.estacion.RFC = data.RFC;
            self.estacion.RazonSocialNombre = data.RazonSocialNombre;

          } else {
            console.log("error")
            this.alertMessage = response.data.Error;
            this.alertTitle = "Error";
            this.show('top-right', 'error ' + response.data.Error);
          }
        } else {
          self.getEstacion();
          // const response = await axios.post('Despacho/GetTicketWEB', dataToSend);
          // if (response.data.Success) {
          //   console.log(response.data);

          // } else {
          //   console.log("error")
          //   this.alertMessage = response.data.Error;
          //   this.alertTitle = "Error";
          //   this.show('top-right', 'error ' + response.data.Error);
          // }
        }

      } catch (e) {
        //this.alert = true;
        //this.error = 'Invalid email/password';
      }
    },
  },
};
</script>
<style>
.form-horizontal.form-bordered .form-group .col-form-label-top {
  padding: 0.9375rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  -webkit-box-align: center;
  align-items: top;
  margin-bottom: 0;
  font-size: inherit;
  font-weight: 600;
  line-height: 1.5;
}

.habilitar {
  background-color: #21ad9a;
  color: #000;
  font-weight: bolder;
}
</style>