<template>
	<div>
    
        <div class=" loading-search" v-if="loading">
        <div class="fa-3x text-center">
            <i class="fas fa-circle-notch fa-spin text-success" style=""></i>
        </div>
        </div>
		<!-- BEGIN breadcrumb -->
		<ol class="breadcrumb float-xl-end">
			<li class="breadcrumb-item"><a href="javascript:;">Reportes</a></li>
			<li class="breadcrumb-item"><a href="javascript:;">Facturación</a></li>
			<li class="breadcrumb-item active"><a href="javascript:;">Cuadre de ventas vs facturas</a></li>
		</ol>
        
		<h1 class="page-header">Cuadre de ventas vs facturas <small></small></h1>



        <form @submit.prevent="search">   
          <div class="row">
              <div class="col-md-8"> 
                  <div class="form-group row">
                    
                    <div class="col-md-3">
                        <label class="col-form-label">Fecha inicial</label>
                      <div class="input-group">
                        <date-picker id="dateTimeInput" v-model="date" :config="options"></date-picker>
                        <label for="dateTimeInput" class="input-group-text">
                          <i class="fa fa-calendar"></i>
                        </label>
                      </div>
                    </div>

                    
                    <div class="col-md-3">
                        <label class="col-form-label">Fecha final</label>
                      <div class="input-group">
                        <date-picker id="dateTimeInputEnd" v-model="dateEnd" :config="options"></date-picker>
                        <label for="dateTimeInputEnd" class="input-group-text">
                          <i class="fa fa-calendar"></i>
                        </label>
                      </div>
                    </div>
                    <div class="col-md-3">
                        <label class="col-form-label">Ciudad</label>
                        <select class="form-select" @change="getEstaciones()" v-model="ciudadSelect" :disabled="deshabilitarCombos">
                            <option v-for="ciudad in ciudades" :value="ciudad.Id" v-bind:key="ciudad.Id">
                                {{ ciudad.Nombre }}
                            </option>
                        </select>
                    </div>
                    <!-- <div class="col-md-3">
                        <label class="col-form-label">Estación</label>
                        <select class="form-select" v-model="estacionSelect" :disabled="deshabilitarCombos">
                          <option value="0">Todas</option>
                            <option v-for="estacion in estaciones" :value="estacion.NoEstacion" v-bind:key="estacion.NoEstacion">
                            {{ estacion.Nombre }}
                            </option>
                          </select>
                    </div> -->

                  </div>
              </div>
              <div class="col-md-2">
                    <button type="submit" class="btn btn-success me-1 mb-1" style="margin-top: 3.1em;">{{textoBotonBuscar}}</button>
              </div>
              <div class="col-md-2"  style="padding-top: 2.7em;">
                <download-excel
                  v-if="registrosLineales.length > 0"
                  class="btn btn-success rounded-pill mt-35"
                  :data="registrosLineales"
                  :fields="json_fields"
                  :worksheet="estacionSelect"
                  :name="`Cuadre Venta vs Factura ${date}.xls`"
                  
                  >
                    Descargar en Excel <i class="fas fa-file-excel fa-fw"></i>
                </download-excel>
                <button v-else disabled class="btn btn-success rounded-pill mt-35">
                    Descargar en Excel <i class="fas fa-file-excel fa-fw"></i>
                </button>
              </div>
            </div>
        </form>
        <div>

            <!-- <div>
                <div v-for="(group, Fecha) in groupedObjects" :key="Fecha">
                <h2>{{ Fecha }}</h2>
                <ul>
                    <li v-for="obj in group" :key="obj.NoEstacion">{{ obj.NoEstacion }}</li>
                </ul>
                </div>
            </div> -->


            
            <panel title="Cuadre de ventas vs facturas" bodyClass="p-0" class="" style="margin-top: 15px;">
                <!-- <vue-good-table
                    :columns="columns"
                    :rows="searchResult"
                    :lineNumbers="false"
                    :search-options="{ enabled: false, placeholder: 'Buscar' }"
                    :pagination-options="{ enabled: true, position: 'bottom' }"
                >
                
                </vue-good-table> -->
                
						<div class="table-responsive" style="margin-top: 15px;;">
							<table class="table table-bordered">
								<thead>
									<tr>
                    <th>Estación</th>
                    <th>No Estación</th>
                    <th>Importe Corte</th>
                    <th>Importe Despachos Corte</th>
                    <th>Importe Despachos 24H</th>
                    <th>Importe Productos</th>
                    <th>Importe Factura Global</th>
                    <th>Importe Factura Clientes</th>
                    <th>Importe Factura Global Productos</th>
                    <th>Importe Factura Clientes Productos</th>
                    <th>Importe Notas Crédito</th>
                    <th>Total Venta</th>
                    <th>Total Facturado</th>
                    <th>Diferencia</th>
                    <th>acciones</th>
									</tr>
								</thead>
								<tbody>
                    <tr v-for="registro in registrosLineales" :key="registro.Fecha">

                      <td v-if="registro.isHeader">{{registro.Estacion}}</td>
                      <td v-if="registro.isHeader">{{registro.NoEstacion}}</td>
                      <td v-if="registro.isHeader" class="sumatoriasRow currencyRow">{{registro.ImporteCorte | currency}}</td>
                      <td v-if="registro.isHeader" class="sumatoriasRow currencyRow">{{registro.ImporteDespachosCorte | currency}}</td>
                      <td v-if="registro.isHeader" class="sumatoriasRow currencyRow">{{registro.ImporteDespachos24H | currency}}</td>
                      <td v-if="registro.isHeader" class="sumatoriasRow currencyRow">{{registro.ImporteProductos | currency}}</td>
                      <td v-if="registro.isHeader" class="sumatoriasRow currencyRow">{{registro.ImporteFacturaGlobal | currency}}</td>
                      <td v-if="registro.isHeader" class="sumatoriasRow currencyRow">{{registro.ImporteFacturaClientes | currency}}</td>
                      <td v-if="registro.isHeader" class="sumatoriasRow currencyRow">{{registro.ImporteFacturaGlobalProductos | currency}}</td>
                      <td v-if="registro.isHeader" class="sumatoriasRow currencyRow">{{registro.ImporteFacturaClientesProductos | currency}}</td>
                      <td v-if="registro.isHeader" class="sumatoriasRow currencyRow">{{registro.ImporteNotasCredito | currency}}</td>
                      <td v-if="registro.isHeader" class="sumatoriasRow currencyRow">{{registro.TotalVenta | currency }}</td>
                      <td v-if="registro.isHeader" class="sumatoriasRow currencyRow">{{registro.TotalFacturado | currency }}</td>
                      <td v-if="registro.isHeader" class="sumatoriasRow currencyRow">{{registro.Diff | currency }}</td>
                      <td v-if="registro.isHeader">
                        
                      </td>


                      <td v-if="registro.isDetail">{{registro.Estacion}}</td>
                      <td v-if="registro.isDetail">{{registro.NoEstacion}}</td>
                      <td v-if="registro.isDetail" class="currencyRow">{{registro.ImporteCorte | currency}}</td>
                      <td v-if="registro.isDetail" class="currencyRow">{{registro.ImporteDespachosCorte | currency}}</td>
                      <td v-if="registro.isDetail" class="currencyRow">{{registro.ImporteDespachos24H | currency}}</td>
                      <td v-if="registro.isDetail" class="currencyRow">{{registro.ImporteProductos | currency}}</td>
                      <td v-if="registro.isDetail" class="currencyRow">{{registro.ImporteFacturaGlobal | currency}}</td>
                      <td v-if="registro.isDetail" class="currencyRow">{{registro.ImporteFacturaClientes | currency}}</td>
                      <td v-if="registro.isDetail" class="currencyRow">{{registro.ImporteFacturaGlobalProductos | currency}}</td>
                      <td v-if="registro.isDetail" class="currencyRow">{{registro.ImporteFacturaClientesProductos | currency}}</td>
                      <td v-if="registro.isDetail" class="currencyRow">{{registro.ImporteNotasCredito | currency}}</td>
                      <td v-if="registro.isDetail" class="currencyRow">{{ registro.TotalVenta | currency }}</td>
                      <td v-if="registro.isDetail" class="currencyRow">{{ registro.TotalFacturado | currency }}</td>
                      <td v-if="registro.isDetail" class="currencyRow">{{ registro.Diff | currency }}</td>
                      <td v-if="registro.isDetail">
                      
                        <button class="btn btn-xs btn-success" @click="abrirLink(registro)">Abrir</button>
                      </td>
                      
                    </tr>

									
								</tbody>
							</table>
						</div>
            </panel>

            <!-- <div v-for="registro in registrosLineales" :key="registro.Fecha">
              {{ registro.Fecha }}
            </div> -->


            <!-- <div>
              <div v-for="group in groupedObjectsWithSums" :key="group.Fecha">
                <ul>
                  <li v-for="obj in group.objects" :key="obj.Estacion">{{ obj.Estacion }},{{ obj.ImporteCorte }} </li>
                </ul>
                <h2>{{ group.Fecha }}</h2>
                <p style="background-color: gray;">Sum: {{ group.sumImporteCorte }}</p>
              </div>
            </div> -->

        </div>

		<!-- bottom left vue-notification -->
		<notifications group="top-right" position="top right" :speed="1000" />
	</div>
</template>

<script>

  import axios from 'axios';
  import moment from 'moment';

  var numeral = require("numeral");
  import Vue from "vue";

  import JsonExcel from "vue-json-excel";
  Vue.component("downloadExcel", JsonExcel);
  Vue.filter("formatNumber", function (value) {
      return numeral(value).format("0,00.00"); // displaying other groupings/separators is possible, look at the docs
  });
  
  Vue.filter("currency", function (value) {
      return numeral(value).format("$0,0.00"); 
  });


export default {
  mounted: function () {
    debugger; // eslint-disable-line no-debugger
    var userSetting = JSON.parse(localStorage.getItem('userSettings'));
    this.usuario = userSetting.Usuario;
    this.usuarioEstacion = userSetting.Estacion;
    this.IdRol = userSetting.IdRol;
    this.getCiudades();
    this.ciudadSelect = userSetting.CiudadId;
    this.getEstaciones();
    this.estacionSelect = userSetting.Estacion;

    if(this.IdRol == 3 || this.IdRol == 7){
      this.deshabilitarCombos = true;
    }

  },
	beforeCreate() {
        
	},
	created() {
		// console.log('created');
	},
	beforeMount() {
		// console.log('beforeMount');
	},
	components: {
        
	},  
    computed: {
        groupedObjectsWithSums() {
          // Assuming your array of objects is named 'objects'
          const grouped = this.searchResult.reduce((result, obj) => {
            const date = moment(obj.Fecha).format('YYYY-MM-DD'); // Format the date as needed
            if (!result[date]) {
              result[date] = {
                FechaString: '',
                Estacion: '',
                NoEstacion: '',
                TipoPago: '',
                ImporteCorte: 0,
                ImporteDespachosCorte: 0,
                ImporteDespachos24H: 0,
                ImporteProductos: 0,
                ImporteFacturaGlobal: 0,
                ImporteFacturaClientes: 0,
                ImporteFacturaGlobalProductos: 0,
                ImporteFacturaClientesProductos: 0,
                ImporteNotasCredito: 0,
                TotalVenta: 0,
                TotalFacturado: 0,
                Diff: 0,
                objects: []
              };
            }
            
            // debugger; // eslint-disable-line no-debugger
            // Assuming there's a 'ImporteCorte' property to sum

            result[date].ImporteCorte += obj.ImporteCorte;
            result[date].ImporteDespachosCorte += obj.ImporteDespachosCorte;
            result[date].ImporteDespachos24H += obj.ImporteDespachos24H;
            result[date].ImporteProductos += obj.ImporteProductos;
            result[date].ImporteFacturaGlobal += obj.ImporteFacturaGlobal;
            result[date].ImporteFacturaClientes += obj.ImporteFacturaClientes;
            result[date].ImporteFacturaGlobalProductos += obj.ImporteFacturaGlobalProductos;
            result[date].ImporteFacturaClientesProductos += obj.ImporteFacturaClientesProductos;
            result[date].ImporteNotasCredito += obj.ImporteNotasCredito;
            result[date].objects.push(obj);
            return result;
          }, {});

          // Convert the grouped object into an array for easier iteration in the template
          return Object.entries(grouped).map(([Fecha, group]) => ({
            Fecha,
            ImporteCorte: group.ImporteCorte,
            ImporteDespachosCorte: group.ImporteDespachosCorte,
            ImporteDespachos24H: group.ImporteDespachos24H,
            ImporteProductos: group.ImporteProductos,
            ImporteFacturaGlobal: group.ImporteFacturaGlobal,
            ImporteFacturaClientes: group.ImporteFacturaClientes,
            ImporteFacturaGlobalProductos: group.ImporteFacturaGlobalProductos,
            ImporteFacturaClientesProductos: group.ImporteFacturaClientesProductos,
            ImporteNotasCredito: group.ImporteNotasCredito,
            objects: group.objects
          }));
        },
        registrosLineales(){
          var registrosCopy = [...this.groupedObjectsWithSums]; 
          var array = [];
          
          debugger; // eslint-disable-line no-debugger
          if(registrosCopy != undefined && registrosCopy.length > 0){
            
            
            for(var h = 0; h < registrosCopy.length ; h++){
              //Total Venta = (Importe Despacho 24H + Importe Productos) 
              //Total Facturado = (Importe Factura Global + Importe Factura Clientes + Importe Factura Global Productos + Importe Factura Clientes Productos - Importe Notas Credito)
              var sumTotalVenta = 0;
              var sumTotalFacturado = 0;
              var diff = 0;
              for(var d = 0; d <  registrosCopy[h].objects.length ; d++){
                var obj = registrosCopy[h].objects[d];
                registrosCopy[h].objects[d].isHeader = false;
                registrosCopy[h].objects[d].isDetail = true;
                
                //(Importe Despacho 24H + Importe Productos)
                registrosCopy[h].objects[d].TotalVenta = obj.ImporteDespachos24H + obj.ImporteProductos;
                
                sumTotalVenta += registrosCopy[h].objects[d].TotalVenta;

                //Total Facturado = (Importe Factura Global + Importe Factura Clientes + Importe Factura Global Productos + Importe Factura Clientes Productos - Importe Notas Credito)
                registrosCopy[h].objects[d].TotalFacturado = (
                  obj.ImporteFacturaGlobal +
                  obj.ImporteFacturaClientes +
                  obj.ImporteFacturaGlobalProductos +
                  obj.ImporteFacturaClientesProductos -
                  obj.ImporteNotasCredito
                );
                sumTotalFacturado += registrosCopy[h].objects[d].TotalFacturado;

                registrosCopy[h].objects[d].FechaString = moment(registrosCopy[h].objects[d].Fecha).format('DD/MM/YYYY');
                registrosCopy[h].objects[d].Diff = registrosCopy[h].objects[d].TotalVenta - registrosCopy[h].objects[d].TotalFacturado;
                array.push(registrosCopy[h].objects[d]);
              }

              
              registrosCopy[h].TotalVenta = sumTotalVenta;
              registrosCopy[h].TotalFacturado = sumTotalFacturado;

              
        debugger; // eslint-disable-line no-debugger
              registrosCopy[h].Diff = parseFloat(sumTotalVenta.toFixed(2))  - parseFloat(sumTotalFacturado.toFixed(2)) ;
              
              registrosCopy[h].isHeader = true;
              registrosCopy[h].isDetail = false;
              array.push(registrosCopy[h]);

            }

          }
          return array;
        },
        groupedObjects() {
            // Assuming your array of objects is named 'objects'
            return this.searchResult.reduce((result, obj) => {
                const date = moment(obj.Fecha).format('YYYY-MM-DD'); // Format the date as needed
                if (!result[date]) {
                result[date] = [];
                }
                result[date].push(obj);
                return result;
            }, {});
        }
    },
    methods: {
    abrirLink(row){
        var self = this;
        debugger; // eslint-disable-line no-debugger
        this.$router.push({ path: '/reportes/facturacion/ventavsfacturaestacion'+'/'+self.intDate+'/'+self.intDateEnd+'/'+self.ciudadSelect+'/'+row.NoEstacion});

    },
	async getCiudades(){
		try{
            const response = await axios.get('Catalogos/GetCiudades');
            if(response.data.Success){
            if(response.data.response.length == 0){
                this.alertMessage = "No se encontró información.";
                this.alertTitle = "Error";
                this.show('top-right', 'error');
            }
            }else{
                console.log("error")
                this.alertMessage = response.data.Error;
                this.alertTitle = "Error";
                this.show('top-right', 'error');
            }
            this.ciudades = response.data.response;
        }catch(e){
            //this.alert = true;
            //this.error = 'Invalid email/password';
        }
	},
	async getEstaciones(){
		try{
		debugger; // eslint-disable-line no-debugger
        const response = await axios.post('Estacion/GetEstaciones?ciudad='+this.ciudadSelect);
        if(response.data.Success){
          if(response.data.response.length == 0){
            this.alertMessage = "No se encontró información.";
            this.alertTitle = "Error";
            this.show('top-right', 'error');
          }
        }else{
            console.log("error")
            this.alertMessage = response.data.Error;
            this.alertTitle = "Error";
            this.show('top-right', 'error');
        }
        this.estaciones = response.data.response;
        this.productos = [];
        this.getProductos();
      }catch(e){
        //this.alert = true;
        //this.error = 'Invalid email/password';
      }
	},
    redirectTanques(){
      
          this.$router.push({ path: '/tanques' });
    },
			show (group, type = '') {
				const text = this.alertMessage;
				this.$notify({
					group,
					title: this.alertTitle,
					text,
					type,
					data: {
						randomNumber: Math.random()
					}
				})
			},
			clean (group) {
				this.$notify({ group, clean: true })
			},
			swalNotification(swalType) {
				var btnClass = (swalType == 'error') ? 'danger' : swalType;
					btnClass = (swalType == 'question') ? 'primary' : btnClass;
				this.$swal({
					title: 'Are you sure?',
					text: 'You will not be able to recover this imaginary file!',
					type: swalType,
					showCancelButton: true,
					buttonsStyling: false,
					confirmButtonText: swalType.charAt(0).toUpperCase() + swalType.substr(1).toLowerCase(),
					cancelButtonText: 'Cancel',
					confirmButtonClass: 'btn me-5px btn-'+ btnClass +'',
					cancelButtonClass: 'btn btn-default',
				})
			},
            
    async search(e){
        var self = this;
      e.preventDefault();

      
      if(moment(self.date).format('MM') != moment(self.dateEnd).format('MM')){

            self.alertMessage = "Las fechas deben corresponder al mismo mes."
            self.alertTitle = "Error";
            self.show('top-right', 'error');
            return;
      }
      self.textoBotonBuscar = "Buscando...";
      self.loading = true;
      try{
        self.intDate = self.date.replace('/', '');
        self.intDate = self.intDate.replace('/', '');


        self.intDateEnd = self.dateEnd.replace('/', '');
        self.intDateEnd = self.intDateEnd.replace('/', '');

        var ciudad = self.ciudadSelect!=undefined?self.ciudadSelect:0;
        var estacion = self.estacionSelect!=undefined?self.estacionSelect:0;
        debugger; // eslint-disable-line no-debugger

        var dataToSend = {
          "Data": {
              "FechaInicial": self.intDate,
              "FechaFinal":self.intDateEnd,
              "Ciudad": ciudad, 
            // "FechaInicial": "20230401",
            // "FechaFinal": "20230430",
            // "Estacion": 5157
          }
        };
        const response = await axios.post('Factura/GetFacturasCuadreVenta', dataToSend);
        if(response.data.Success){
          if(response.data.response.length == 0){
            self.alertMessage = "No se encontró información del día especificado.";
            self.alertTitle = "Error";
            self.show('top-right', 'error');
          }
        }else{
            console.log("error")
            self.alertMessage = response.data.Error;
            self.alertTitle = "Error";
            self.show('top-right', 'error');
        }
        var respuesta = response.data.response;
        
        self.searchResult = respuesta;
        // for(var n=0;n<respuesta.length;n++){
        //   respuesta[n].FechaString = moment(respuesta[n].Fecha).format("DD/MM/YYYY");
        // }

        
        
        

      self.textoBotonBuscar = "Buscar";
      
      self.loading = false;
      }catch(e){
      self.loading = false;
        //this.alert = true;
        //this.error = 'Invalid email/password';
      }
    }
  },
    data() {
		return {

            loading:false,
            deshabilitarCombos:false,
            ciudades: [],
            estaciones: [],
            ciudadSelect: 1,
            estacionSelect: 0,
            date: moment().startOf('month').format("YYYY/MM/DD"),
            intDateEnd: "",
            dateEnd: moment().format("YYYY/MM/DD"),
            intDate: "",
            options: {
                format: 'YYYY/MM/DD',
                useCurrent: false,
            },
            pageUserSettings: {},
            alertMessage: "",
            alertTitle: "",
            searchResult: [],
            textoBotonBuscar: "Buscar",		
            json_fields: {
              "Estación": "Estación",
              "No Estación": "NoEstacion",
              "Importe Corte": "ImporteCorte",
              "Importe Despachos Corte": "ImporteDespachosCorte",
              "Importe Despachos 24H": "ImporteDespachos24H",
              "Importe Productos": "ImporteProductos",
              "Importe Factura Global": "ImporteFacturaGlobal",
              "Importe Factura Clientes": "ImporteFacturaClientes",
              "Importe Factura Global Productos": "ImporteFacturaGlobalProductos",
              "Importe Factura Clientes Productos": "ImporteFacturaClientesProductos",
              "Importe Notas Crédito": "ImporteNotasCredito",
              "Total Venta": "TotalVenta",
              "Total Facturado": "TotalFacturado",
              "Diferencia": "Diff",
            },
            
        };
	}
}
</script>
<style scoped>
.dx-datagrid-header-panel{
  display: none !important;
}
.loading-search{
  position: absolute;
    width: 100%;
    left: 0px;
    height: 100%;
    background: rgba(0, 0, 0, 0.1);
    z-index: 1000;
    padding-top: 150px;
    top: 0;
}
.sumatoriasRow{
  background-color: #bbb;
}
.currencyRow{
  text-align: right;
}
</style>