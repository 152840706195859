<template>
	<div>
    
        <div class=" loading-search" v-if="loading">
        <div class="fa-3x text-center">
            <i class="fas fa-circle-notch fa-spin text-success" style=""></i>
        </div>
        </div>
		<!-- BEGIN breadcrumb -->
		<ol class="breadcrumb float-xl-end">
			<li class="breadcrumb-item"><a href="javascript:;">Corresponsalia</a></li>
			<li class="breadcrumb-item"><a href="javascript:;">Santander</a></li>
			<li class="breadcrumb-item active"><a href="javascript:;">Cuadre</a></li>
		</ol>
        
		<h1 class="page-header">Cuadre <small></small></h1>



        <form @submit.prevent="search">   
          <div class="row">
              <div class="col-md-8"> 
                  <div class="form-group row">
                    
                    <div class="col-md-3">
                        <label class="col-form-label">Fecha inicial</label>
                      <div class="input-group">
                        <date-picker id="dateTimeInput" v-model="date" :config="options"></date-picker>
                        <label for="dateTimeInput" class="input-group-text">
                          <i class="fa fa-calendar"></i>
                        </label>
                      </div>
                    </div>

                    
                    <div class="col-md-3">
                        <label class="col-form-label">Fecha final</label>
                      <div class="input-group">
                        <date-picker id="dateTimeInputEnd" v-model="dateEnd" :config="options"></date-picker>
                        <label for="dateTimeInputEnd" class="input-group-text">
                          <i class="fa fa-calendar"></i>
                        </label>
                      </div>
                    </div>
                    <div class="col-md-3">
                        <label class="col-form-label">Ciudad</label>
                        <select class="form-select" @change="getEstaciones()" v-model="ciudadSelect" :disabled="deshabilitarCombos">
                            <option v-for="ciudad in ciudades" :value="ciudad.Id" v-bind:key="ciudad.Id">
                                {{ ciudad.Nombre }}
                            </option>
                        </select>
                    </div>
                    <div class="col-md-3">
                        <label class="col-form-label">Estación</label>
                        <select class="form-select" v-model="estacionSelect" :disabled="deshabilitarCombos">
                          <option value="0">Todas</option>
                            <option v-for="estacion in estaciones" :value="estacion.NoEstacion" v-bind:key="estacion.NoEstacion">
                            {{ estacion.Nombre }}
                            </option>
                          </select>
                    </div>

                  </div>
              </div>
              <div class="col-md-2">
                    <button type="submit" class="btn btn-success me-1 mb-1" style="margin-top: 3.1em;">{{textoBotonBuscar}}</button>
              </div>
              <div class="col-md-2"  style="padding-top: 2.7em;">
                <!-- <download-excel
                  v-if="searchResult.length > 0"
                  class="btn btn-success rounded-pill mt-35"
                  :data="searchResult"
                  :fields="json_fields"
                  :worksheet="estacionSelect"
                  :name="`Compras CG ${date}.xls`"
                  
                  >
                    Descargar en Excel <i class="fas fa-file-excel fa-fw"></i>
                </download-excel>
                <button v-else disabled class="btn btn-success rounded-pill mt-35">
                    Descargar en Excel <i class="fas fa-file-excel fa-fw"></i>
                </button> -->
              </div>
            </div>
        </form>
        <div>

            <!-- <div>
                <div v-for="(group, Fecha) in groupedObjects" :key="Fecha">
                <h2>{{ Fecha }}</h2>
                <ul>
                    <li v-for="obj in group" :key="obj.NoEstacion">{{ obj.NoEstacion }}</li>
                </ul>
                </div>
            </div> -->


            
            <panel title="Cuadre" bodyClass="p-0" class="" style="margin-top: 15px;">




 
              <DxDataGrid
                id="gridContainer"
                :data-source="searchResult"
                key-expr="NoEstacion"
                :show-borders="true"
                @exporting="onExporting"
                :show-column-lines="false"
                :show-row-lines="false"
                :row-alternation-enabled="true"
                
              >
              
              <DxScrolling column-rendering-mode="virtual"/>
              <DxPager
                      :show-page-size-selector="true"
                      :allowed-page-sizes="[10, 20, 50, 100]"
                      :show-navigation-buttons="true"
                  />
              <DxPaging
              
                      :page-size="10"
                      :page-index="1" /> 
              <DxFilterRow/>
                <DxHeaderFilter
                  :visible="showHeaderFilter"
                />
                <DxSearchPanel
                  :visible="true"
                  :width="240"
                  placeholder="Search..."
                />

                <DxGroupPanel :visible="true"/>
                <DxGrouping :auto-expand-all="true"/>
                <DxExport
                  :enabled="true"
                  :allow-export-selected-data="false"
                />
                <DxSelection mode="false"/>

                
                <DxColumn dataField="Abrir" caption="Abrir" :cellTemplate="enviarCGButton" />
                
                  <DxColumn data-field="NoEstacion" caption="No Estación"  width="200">
                    <DxHeaderFilter :allow-search="true"/>
                  </DxColumn>

                  

                <DxColumn data-field="Estacion" caption="Estacion" width="250" />
                <DxColumn data-field="Ciudad" caption="Ciudad" />
                <DxColumn data-field="Fecha" dataType="date" caption="Fecha" />
                <DxColumn data-field="Turno" caption="Turno"/>
                <DxColumn data-field="ImporteCorte" caption="ImporteCorte" dataType="number">
                    <DxFormat
                        type="currency"
                        :precision="2"
                        />
                </DxColumn>
                <DxColumn data-field="ImporteTickets" caption="ImporteTickets" dataType="number">
                    <DxFormat
                        type="currency"
                        :precision="2"
                        />
                </DxColumn>
                <DxColumn data-field="CambioUSD" caption="CambioUSD" dataType="number">
                    <DxFormat
                        type="currency"
                        :precision="2"
                        />
                </DxColumn>
                <DxColumn data-field="Diferencia" caption="Diferencia" dataType="number">
                    <DxFormat
                        type="currency"
                        :precision="2"
                        />
                </DxColumn>
                            
                  
<!--                   
                  <DxColumn data-field="Diferencia" caption="Total" dataType="number" width="170">
                    <DxFormat
                        type="currency"
                        :precision="2"
                        />
                  </DxColumn> -->
                  
                  
                  
        <DxSummary>
            
            <DxTotalItem
                column="ImporteTickets"
                summary-type="sum"
                value-format="$#,##0.##"
                displayFormat='{0}'
                >
            </DxTotalItem>
            <DxTotalItem
                column="ImporteCorte"
                summary-type="sum"
                value-format="$#,##0.##"
                displayFormat='{0}'
                >
            </DxTotalItem>
            <DxTotalItem
                column="CambioUSD"
                summary-type="sum"
                value-format="$#,##0.##"
                displayFormat='{0}'
                >
            </DxTotalItem>
            <DxTotalItem
                column="Diferencia"
                summary-type="sum"
                value-format="$#,##0.##"
                displayFormat='{0}'
                >
            </DxTotalItem>

            
        </DxSummary>

              </DxDataGrid>
                
            </panel>
            

        </div>

		<!-- bottom left vue-notification -->
		<notifications group="top-right" position="top right" :speed="1000" />
	</div>
</template>

<script>

  import axios from 'axios';
  import moment from 'moment';


  
import { DxDataGrid, DxFormat, DxSummary, DxTotalItem,
  DxPaging, DxPager, DxScrolling, DxColumn, DxExport, DxSelection, DxGroupPanel, DxGrouping, DxHeaderFilter,DxFilterRow,DxSearchPanel, } from 'devextreme-vue/data-grid';
import { Workbook } from 'exceljs';
import { saveAs } from 'file-saver';
// Our demo infrastructure requires us to use 'file-saver-es'. We recommend that you use the official 'file-saver' package in your applications.
import { exportDataGrid } from 'devextreme/excel_exporter';
//import service from './data.js';


  var numeral = require("numeral");
  import Vue from "vue";

  import JsonExcel from "vue-json-excel";
  Vue.component("downloadExcel", JsonExcel);
  Vue.filter("formatNumber", function (value) {
      return numeral(value).format("0,00.00"); // displaying other groupings/separators is possible, look at the docs
  });
  
  Vue.filter("currency", function (value) {
      return numeral(value).format("$0,0.00"); 
  });


export default {
  // beforeRouteEnter(to, from, next){
  //   var fromPath = from.path;
  //   if(fromPath.includes("cuadredetalle")){
  //     this.fromCuadreDetalle = true;
  //   }
  //   next();
  //   debugger; // eslint-disable-line no-debugger
  // },
  // beforeRouteUpdate(to, from, next){
  //   var fromPath = from.path;
  //   if(fromPath.includes("cuadredetalle")){
  //     this.fromCuadreDetalle = true;
  //   }
  //   next();
  //   debugger; // eslint-disable-line no-debugger
  // },
  mounted: function () {
    var self = this;
    
    var userSetting = JSON.parse(localStorage.getItem('userSettings'));
    this.usuario = userSetting.Usuario;
    this.usuarioEstacion = userSetting.Estacion;
    this.IdRol = userSetting.IdRol;
    this.getCiudades();
    this.ciudadSelect = userSetting.CiudadId;
    this.getEstaciones();
    this.estacionSelect = userSetting.Estacion;

    

    // debugger; // eslint-disable-line no-debugger
    // var dateIn = self.$route.params.fechaIn;
    // var fechaFin = self.$route.params.fechaFin;
    // var ciudad = self.$route.params.ciudad;
    // var estacion = self.$route.params.estacion;
    // if(dateIn != undefined){
    //   self.date = moment(dateIn).format("YYYY/MM/DD");
    //   self.dateEnd = moment(fechaFin).format("YYYY/MM/DD");
    //   self.ciudadSelect = ciudad;
    //   self.estacionSelect = estacion;
      
    // }

    if(this.IdRol == 3 || this.IdRol == 7){
      this.deshabilitarCombos = true;
    }

    
    var parametrosCuadre = JSON.parse(localStorage.getItem('parametrosCuadre'));
    /* eslint-disable no-debugger */
    debugger; 
    // if(self.fromCuadreDetalle && parametrosCuadre != null){
    if(parametrosCuadre != null){
        self.date = parametrosCuadre.date;
        self.dateEnd = parametrosCuadre.dateEnd;
        self.ciudadSelect = parametrosCuadre.ciudadSelect;
        self.estacionSelect = parametrosCuadre.estacionSelect;
        self.seachCuadre();
    }

  },
	beforeCreate() {
        
	},
	created() {
		// console.log('created');
	},
	components: {
		DxTotalItem, DxDataGrid, DxSummary, DxFormat,DxPaging,DxPager, DxScrolling, DxColumn, DxExport, DxSelection, DxGroupPanel, DxGrouping, DxHeaderFilter,DxFilterRow,DxSearchPanel,
	},
	beforeMount() {
		// console.log('beforeMount');
	},
    computed: {
    },
    methods: {
      enviarCGButton(cellElement, cellInfo) {
        
        const button = document.createElement("button");
        button.innerText = "Abrir";
        button.className = "btn btn-sm btn-success";
        button.addEventListener("click", () => {
          
          this.handleButtonClick(cellInfo.data);
        });
  
        cellElement.appendChild(button);
      },
      handleButtonClick(data) {
      debugger; // eslint-disable-line no-debugger
      
      // alert(data.NoEstacion);
      
      //'/corresponsalia/santander/cuadredetalle/:estacion?/:fecha?/:turno?'
      var fechaInt = moment(data.Fecha).format('YYYYMMDD')

      // self.parametrosCuadre = {
      //     "date": self.date,
      //     "dateEnd": self.dateEnd,
      //     "ciudadSelect": self.ciudadSelect,
      //     "estacionSelect": self.estacionSelect
      //   }
        
      localStorage.setItem("parametrosCuadre",JSON.stringify(this.parametrosCuadre));
      this.$router.push({ path: '/corresponsalia/santander/cuadredetalle'+'/'+data.NoEstacion+'/'+fechaInt+'/'+data.Turno+'/'+this.ciudadSelect+'/'+data.ImporteCorte});
        
      },
      
      async enviarCG(data){
        //api/Precio/SetAcusePrecio
  
        
        var self = this;
          // self.textoBotonEnviar = "Enviando...";
          self.loading = true;
        try {
          // const segments = data.Path.split('/');
  
          // // Get the last element of the array, which is the filename
          // const fileName = segments[segments.length - 1];
  
          debugger; // eslint-disable-line no-debugger
          var dataToSend = {
            "Data": {
              "Path": data.Path, 
              "Usuario": self.usuario
              // "Path": "CRE/ACUSES/2023/10/23/PL3988EXPES2015.pdf", 
              // "Usuario": "juan.garcia"
            }
          };
          debugger; // eslint-disable-line no-debugger
          const response = await axios.post("Precio/GetAcusePDF", dataToSend);
  
          self.loading = false;
          // self.textoBotonEnviar = "Enviar";
          debugger; // eslint-disable-line no-debugger
          if (response.data.Success) {
              
            this.alertMessage = response.data.Message;
            this.alertTitle = "";
            this.show("top-right", "success");
  
            
            self.descargarPDF(response.data.response, data.Nombre);
          }
          else{
            
            this.alertMessage = response.data.Message;
            this.alertTitle = "Error";
            this.show("top-right", "error");
          }
  
  
        } catch (e) {
          self.loading = false;
          self.textoBotonEnviar = "Enviar";
          this.alertMessage = "Ocurrio un error";
          this.alertTitle = "Error";
          this.show("top-right", "error");
        }
      },





    onExporting(e) {
      const workbook = new Workbook();
      const worksheet = workbook.addWorksheet('Pedidos');

      exportDataGrid({
        component: e.component,
        worksheet: worksheet,
        autoFilterEnabled: true
      }).then(() => {
        workbook.xlsx.writeBuffer().then((buffer) => {
          saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'Pedidos.xlsx');
        });
      });
      e.cancel = true;
    },
	async getCiudades(){
		try{
            const response = await axios.get('Catalogos/GetCiudades');
            if(response.data.Success){
            if(response.data.response.length == 0){
                this.alertMessage = "No se encontró información.";
                this.alertTitle = "Error";
                this.show('top-right', 'error');
            }
            }else{
                console.log("error")
                this.alertMessage = response.data.Error;
                this.alertTitle = "Error";
                this.show('top-right', 'error');
            }
            this.ciudades = response.data.response;
        }catch(e){
            //this.alert = true;
            //this.error = 'Invalid email/password';
        }
	},
	async getEstaciones(){
		try{
        const response = await axios.post('Estacion/GetEstaciones?ciudad='+this.ciudadSelect);
        if(response.data.Success){
          if(response.data.response.length == 0){
            this.alertMessage = "No se encontró información.";
            this.alertTitle = "Error";
            this.show('top-right', 'error');
          }
        }else{
            console.log("error")
            this.alertMessage = response.data.Error;
            this.alertTitle = "Error";
            this.show('top-right', 'error');
        }
        this.estaciones = response.data.response;
        
		debugger; // eslint-disable-line no-debugger
        // this.productos = [];
        // this.getProductos();
      }catch(e){
        //this.alert = true;
        //this.error = 'Invalid email/password';
      }
	},
			show (group, type = '') {
				const text = this.alertMessage;
				this.$notify({
					group,
					title: this.alertTitle,
					text,
					type,
					data: {
						randomNumber: Math.random()
					}
				})
			},
			clean (group) {
				this.$notify({ group, clean: true })
			},
			swalNotification(swalType) {
				var btnClass = (swalType == 'error') ? 'danger' : swalType;
					btnClass = (swalType == 'question') ? 'primary' : btnClass;
				this.$swal({
					title: 'Are you sure?',
					text: 'You will not be able to recover this imaginary file!',
					type: swalType,
					showCancelButton: true,
					buttonsStyling: false,
					confirmButtonText: swalType.charAt(0).toUpperCase() + swalType.substr(1).toLowerCase(),
					cancelButtonText: 'Cancel',
					confirmButtonClass: 'btn me-5px btn-'+ btnClass +'',
					cancelButtonClass: 'btn btn-default',
				})
			},
            
    async seachCuadre(){
		debugger; // eslint-disable-line no-debugger
        var self = this;
        

      
      self.textoBotonBuscar = "Buscando...";
      self.loading = true;
      try{

        self.parametrosCuadre = {
          "date": self.date,
          "dateEnd": self.dateEnd,
          "ciudadSelect": self.ciudadSelect,
          "estacionSelect": self.estacionSelect
        }

        


        self.intDate = self.date.replace('/', '');
        self.intDate = self.intDate.replace('/', '');


        self.intDateEnd = self.dateEnd.replace('/', '');
        self.intDateEnd = self.intDateEnd.replace('/', '');

        var ciudad = self.ciudadSelect!=undefined?self.ciudadSelect:0;
        var estacion = self.estacionSelect!=undefined?self.estacionSelect:0;

        
        const startDate = moment(self.date).format('YYYY-MM-DD');
        const endDate = moment(self.dateEnd).format('YYYY-MM-DD');



        // var gasolinera = 0;
        // for(var es = 0 ; es < self.estaciones.length ; es++){
        //     if(estacion == self.estaciones[es].NoEstacion){
        //         gasolinera = self.estaciones[es].Gasolinera;
        //         break;
        //     }
        // }

        var dataToSend = {
          "Data": {
              "FechaIni": self.intDate,
              "FechaFin":self.intDateEnd,
              "Ciudad": ciudad, 
              "Estacion": estacion,
              
          }
        };

        
        

        const response = await axios.post('Corresponsalia/GetCorrsponsalCuadre', dataToSend);
        debugger; // eslint-disable-line no-debugger
        
        self.loading = false;
        self.textoBotonBuscar = "Buscar";
        if(response.data.Success){
          if(response.data.response.length == 0){
            self.alertMessage = "No se encontró información del día especificado.";
            self.alertTitle = "Error";
            self.show('top-right', 'error');
          }else{
            
            debugger; // eslint-disable-line no-debugger
            // var respuesta = response.data.response.Detalle;
            //self.RespuestaCompras = response.data.response.Pedidos;
            self.searchResult = response.data.response;
            
            


          }


        }else{
            console.log("error")
            self.alertMessage = response.data.Message;
            self.alertTitle = response.data.Error;
            self.show('top-right', 'error');
        }
        
        
        

      
      self.loading = false;
      }catch(e){
      self.loading = false;
        //this.alert = true;
        //this.error = 'Invalid email/password';
      }
    },
    async search(e){

      e.preventDefault();
      this.seachCuadre();
    }
  },
    data() {
		return {
      fromCuadreDetalle: false,
            parametrosCuadre: null,
            showHeaderFilter: true,
            RespuestaCompras: [],
            loading:false,
            deshabilitarCombos:false,
            ciudades: [],
            estaciones: [],
            ciudadSelect: 1,
            estacionSelect: 0,
            date: moment().startOf('month').format("YYYY/MM/DD"),
            intDateEnd: "",
            dateEnd: moment().format("YYYY/MM/DD"),
            intDate: "",
            options: {
                format: 'YYYY/MM/DD',
                useCurrent: false,
            },
            pageUserSettings: {},
            alertMessage: "",
            alertTitle: "",
            searchResult: [],
            textoBotonBuscar: "Buscar",	      
            columns: [
            {
                label: "No Estación",
                field: "NoEstacion",
                tdClass: "text-nowrap",
                thClass: "text-nowrap",
            },
            {
                label: "Estación",
                field: "Estacion",
                tdClass: "text-nowrap",
                thClass: "text-nowrap",
            },
            {
                label: "Número",
                field: "Numero",
                tdClass: "text-nowrap",
                thClass: "text-nowrap",
            },
            {
                label: "Factura",
                field: "Factura",
                tdClass: "text-nowrap",
                thClass: "text-nowrap",
            },
            {
                label: "Remisión",
                field: "Remision",
                tdClass: "text-nowrap",
                thClass: "text-nowrap",
            },
            {
                label: "Fecha",
                field: "FechaString",
                tdClass: "text-center text-nowrap",
                thClass: "text-nowrap",
            },
            // {
            //     label: "Codigo del producto",
            //     field: "CodigoProducto",
            //     tdClass: "text-nowrap",
            //     thClass: "text-nowrap",
            // },
            {
                label: "Producto",
                field: "Producto",
                tdClass: "text-nowrap",
                thClass: "text-nowrap",
            },
            {
                label: "Recibido",
                field: "Recibido",
                type: "number",
                tdClass: "text-nowrap",
                thClass: "text-nowrap",
            },
            {
                label: "Facturado",
                field: "Facturado",
                type: "number",
                tdClass: "text-nowrap",
                thClass: "text-nowrap",
            },
            {
                label: "Importe",
                field: "Importe",
                type: "number",
                tdClass: "text-nowrap",
                thClass: "text-nowrap",
            },
            {
                label: "IEPS",
                field: "IEPS",
                type: "number",
                tdClass: "text-nowrap",
                thClass: "text-nowrap",
            },
            {
                label: "IVA",
                field: "IVA",
                type: "number",
                tdClass: "text-nowrap",
                thClass: "text-nowrap",
            },
            {
                label: "Recargos",
                field: "Recargos",
                type: "number",
                tdClass: "text-nowrap",
                thClass: "text-nowrap",
            },
            {
                label: "Descuentos",
                field: "Descuentos",
                type: "number",
                tdClass: "text-nowrap",
                thClass: "text-nowrap",
            },
            {
                label: "Retenciones",
                field: "Retenciones",
                type: "number",
                tdClass: "text-nowrap",
                thClass: "text-nowrap",
            },
            {
                label: "UUID",
                field: "UUID",
                tdClass: "text-nowrap",
                thClass: "text-nowrap",
            },
            {
                label: "Referencia",
                field: "Referencia",
                tdClass: "text-nowrap",
                thClass: "text-nowrap",
            },
            ],	
            json_fields: {
                "Numero": "Numero",
                "Factura": "Factura",
                "Remision": "Remision",
                "Fecha": "Fecha",
                "Codigo Producto": "CodigoProducto",
                "Producto": "Producto",
                "Recibido": "Recibido",
                "Facturado": "Facturado",
                "Importe": "Importe",
                "IEPS": "IEPS",
                "IVA": "IVA",
                "Recargos": "Recargos",
                "Descuentos": "Descuentos",
                "Retenciones": "Retenciones",
                "UUID": "UUID",
                "Referencia": "Referencia",
            },
            
        };
	}
}
</script>
<style scoped>
.dx-datagrid-header-panel{
  display: none !important;
}
.loading-search{
  position: absolute;
    width: 100%;
    left: 0px;
    height: 100%;
    background: rgba(0, 0, 0, 0.1);
    z-index: 1000;
    padding-top: 150px;
    top: 0;
}
.sumatoriasRow{
  background-color: #bbb;
}
.currencyRow{
  text-align: right !important;
}

.vgt-fixed-header {
  overflow-y: auto;
}

.vgt-table__header {
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: #f5f5f5;
}
</style>