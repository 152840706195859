<template>
  <div>
    <!-- BEGIN breadcrumb -->
    <ol class="breadcrumb float-xl-end">
			<li class="breadcrumb-item"><a href="javascript:;">Reportes</a></li>
			<li class="breadcrumb-item"><a href="javascript:;">Ventas</a></li>
			<li class="breadcrumb-item active"><a href="javascript:;">App</a></li>
    </ol>
    <!-- END breadcrumb -->
    <!-- BEGIN page-header -->
    <h1 class="page-header">Reporte de ventas App</h1>
    <!-- END page-header -->


	<div class="row" style="margin-bottom: 15px;;">
      <div class="col-md-8">
        <form @submit.prevent="search">    
          <div class="form-group row">
            
            <div class="col-md-3">
                        <label class="col-form-label">Fecha inicial</label>
              <div class="input-group">
                <date-picker id="dateTimeInput" v-model="date" :config="options"></date-picker>
                <label for="dateTimeInput" class="input-group-text">
                  <i class="fa fa-calendar"></i>
                </label>
              </div>
            </div>

            
            <div class="col-md-3">
              <label class="col-form-label">Fecha final</label>
              <div class="input-group">
                <date-picker id="dateTimeInputEnd" v-model="dateEnd" :config="options"></date-picker>
                <label for="dateTimeInputEnd" class="input-group-text">
                  <i class="fa fa-calendar"></i>
                </label>
              </div>
            </div>

            <!-- <div class="col-md-3">
                <label class="col-form-label">Estatus</label>
                <select class="form-select"  v-model="estatusSelect" >
                
                  <option value="finalized">Finalized</option>
                </select>
            </div>
             -->

            <div class="col-md-3" style="margin-top: 2.25rem;">
              <button type="submit" class="btn btn-success me-1 mb-1">{{textoBotonBuscar}}</button>
            </div>
          </div>
        </form>
      </div>
      <!-- <div class="col-md-4">
        <div class="col-md-12 text-end">
          <button type="button" v-b-modal.modalDialog class="btn btn-sm btn-success" @click="getCliente('',0)">Agregar Colaborador</button>
        </div>
      </div> -->
    </div>
    <panel title="Reporte de ventas App" bodyClass="p-0" class="">




<!--       
      <vue-good-table
        :columns="columns"
        :rows="items"
        :lineNumbers="false"
        :search-options="{ enabled: true, placeholder: 'Buscar' }"
        :pagination-options="{ enabled: true, position: 'bottom' }"
      >
        <template slot="table-row" slot-scope="props">
          <span v-if="props.column.label == 'Acciones'">
            
            <button v-b-modal.modalDialog class="btn btn-xs btn-success" @click="mostrarDetalle(props.row)">Detalle</button>
          </span>
        </template>
      </vue-good-table>
 -->

 
 <DxDataGrid
      id="gridContainer"
      :data-source="items"
      key-expr="numero"
      :show-borders="true"
      @exporting="onExporting"
      :show-column-lines="false"
      :show-row-lines="false"
      :row-alternation-enabled="true"
      :column-width="100"
    >
    
    <DxScrolling column-rendering-mode="virtual"/>
    <DxPager
            :show-page-size-selector="true"
            :allowed-page-sizes="[10, 20, 50, 100]"
            :show-navigation-buttons="true"
        />
    <DxPaging
    
            :page-size="10"
            :page-index="1" /> 
    <DxFilterRow/>
      <DxHeaderFilter
        :visible="showHeaderFilter"
      />
      <DxSearchPanel
        :visible="true"
        :width="240"
        placeholder="Search..."
      />

      <DxGroupPanel :visible="true"/>
      <DxGrouping :auto-expand-all="true"/>
      <DxExport
        :enabled="true"
        :allow-export-selected-data="false"
      />
      <DxSelection mode="false"/>
        <DxColumn data-field="numero" caption="Numero">
          <DxHeaderFilter :allow-search="true"/>
        </DxColumn>

        <DxColumn data-field="numero" caption="Número"/>
        
        <DxColumn data-field="created_date" caption="Fecha de creación" width="150"/>
        <DxColumn data-field="finalization_date" caption="Fecha Finalizado" width="200"/>
        <DxColumn data-field="status_qr" caption="Estatus"/>
        <DxColumn data-field="pay_status" caption="Estatus Pago" width="150"/>
        <DxColumn data-field="total" caption="Total" dataType="number">
          <DxFormat
              type="currency"
              :precision="2"
              />
        </DxColumn>
        <DxColumn data-field="discount" caption="Descuento" dataType="number">
          <DxFormat
              type="currency"
              :precision="2"
              />
        </DxColumn>
        <DxColumn data-field="ticket_number" caption="Ticket" width="150"/>
        <DxColumn data-field="branch_name" caption="Estación"/>
        <DxColumn data-field="id_branch" caption="No Estación"/>
        <DxColumn data-field="id_fueling" caption="QR" width="300"/>
        <DxColumn data-field="id_client" caption="Cliente"/>
        <DxColumn data-field="name" caption="Nombre" width="150"/>
        <DxColumn data-field="email" caption="Correo" width="250" />
        <DxColumn data-field="fuel_type" caption="Producto" width="200"/>
        
        <DxColumn data-field="liters" caption="Litros"/>
        <DxColumn data-field="pay_err" caption="Pago error"/>


        
    </DxDataGrid>


      
    </panel>
	

    


	<!-- bottom left vue-notification -->
	<notifications group="top-right" position="top right" :speed="1000" />
  </div>
</template>

<script>

import axios from "axios";
import moment from 'moment';

import { DxDataGrid, DxFormat,
  DxPaging, DxPager, DxScrolling, DxColumn, DxExport, DxSelection, DxGroupPanel, DxGrouping, DxHeaderFilter,DxFilterRow,DxSearchPanel, } from 'devextreme-vue/data-grid';
import { Workbook } from 'exceljs';
import { saveAs } from 'file-saver';
// Our demo infrastructure requires us to use 'file-saver-es'. We recommend that you use the official 'file-saver' package in your applications.
import { exportDataGrid } from 'devextreme/excel_exporter';
//import service from './data.js';

export default {
async	created() {
  
	},
	components: {
		DxDataGrid, DxFormat,DxPaging,DxPager, DxScrolling, DxColumn, DxExport, DxSelection, DxGroupPanel, DxGrouping, DxHeaderFilter,DxFilterRow,DxSearchPanel,
	},
  methods: {
    onExporting(e) {
      const workbook = new Workbook();
      const worksheet = workbook.addWorksheet('Transacciones');

      exportDataGrid({
        component: e.component,
        worksheet: worksheet,
        autoFilterEnabled: true
      }).then(() => {
        workbook.xlsx.writeBuffer().then((buffer) => {
          saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'Transacciones APP.xlsx');
        });
      });
      e.cancel = true;
    },
    async mostrarDetalle(item){
      var self = this;
      self.ventaSeleccionada = item;
      try {
        debugger; // eslint-disable-line no-debugger
    
        const response = await axios.post("Ventas/GetVentaDetalle",
            {"Data":
              {"Id": item.Id}
            });
        
        if (response.data.Success) {
          
        debugger; // eslint-disable-line no-debugger
          if (response.data.response.length == 0) {
            this.alertMessage =
              "No se encontraron archivos";
            this.alertTitle = "Error";
            this.show("top-right", "error");
          } else {
            
            
            self.ventaDetalle = response.data.response;

              // self.$bvModal.show('modalDialog');
          }
        } else {
        debugger; // eslint-disable-line no-debugger
          this.alertMessage = response.data.Error;
          this.alertTitle = "Error";
          this.show("top-right", "error");
        }
        this.textoBotonBuscar = "Buscar";
      } catch (e) {
          this.alertMessage = "Ocurrio un error";
          this.alertTitle = "Error";
          this.show("top-right", "error");
      }

    },
    reenviarModal(item){
      var self = this;
      self.uuidReenviar = item.UUID;
    },
    async reenviar(){
      var self = this;
      try {
        debugger; // eslint-disable-line no-debugger
    
        const response = await axios.post("CFDI/SendCFDISA",
            {"Data":
              {"UUID": self.uuidReenviar,
              "Correo": self.emailReenviar}
            });
        
        if (response.data.Success) {
          
        debugger; // eslint-disable-line no-debugger
          if (response.data.response.length == 0) {
            this.alertMessage =
              "No se encontraron archivos";
            this.alertTitle = "Error";
            this.show("top-right", "error");
          } else {
            
            
            self.alertMessage = "Se envió la factura exitosamente";
            self.alertTitle = "Envio exitoso";
            self.show("top-right", "success");
            
              self.$bvModal.hide('modalDialog');
          }
        } else {
        debugger; // eslint-disable-line no-debugger
          this.alertMessage = response.data.Error;
          this.alertTitle = "Error";
          this.show("top-right", "error");
        }
        this.textoBotonBuscar = "Buscar";
      } catch (e) {
          this.alertMessage = "Ocurrio un error";
          this.alertTitle = "Error";
          this.show("top-right", "error");
      }

    },
    showNotification(title,text,type){
      this.alertTitle = title;
      this.alertMessage = text;
      this.show("top-right", type);
    },
			show (group, type = '') {
				const text = this.alertMessage;
				this.$notify({
					group,
					title: this.alertTitle,
					text,
					type,
					data: {
						randomNumber: Math.random()
					}
				})
			},
			clean (group) {
				this.$notify({ group, clean: true })
			},
			swalNotification(swalType) {
				var btnClass = (swalType == 'error') ? 'danger' : swalType;
					btnClass = (swalType == 'question') ? 'primary' : btnClass;
				this.$swal({
					title: 'Are you sure?',
					text: 'You will not be able to recover this imaginary file!',
					type: swalType,
					showCancelButton: true,
					buttonsStyling: false,
					confirmButtonText: swalType.charAt(0).toUpperCase() + swalType.substr(1).toLowerCase(),
					cancelButtonText: 'Cancel',
					confirmButtonClass: 'btn me-5px btn-'+ btnClass +'',
					cancelButtonClass: 'btn btn-default',
				})
			},
      async getData(){
        this.textoBotonBuscar = "Buscando...";
        try {
      debugger; // eslint-disable-line no-debugger
      
        this.intDate = this.date.replace('/', '-');
        this.intDate = this.intDate.replace('/', '-');

        
        this.intDateEnd = this.dateEnd.replace('/', '-');
        this.intDateEnd = this.intDateEnd.replace('/', '-');
          const response = await axios.post("Bambu/GetTransacciones",
            {"Data":
              {
                "FechaInicial":this.intDate,
                "FechaFinal":this.intDateEnd,
                "Estatus": this.estatusSelect
              }
            });
          
          if (response.data.Success) {
            if (response.data.response.length == 0) {
              this.alertMessage =
                "No se encontró información de usuarios";
              this.alertTitle = "Error";
              this.show("top-right", "error");
            } else {
              this.items = response.data.response;
            }
          } else {
            this.alertMessage = response.data.Error;
            this.alertTitle = "Error";
            this.show("top-right", "error");
          }
          this.textoBotonBuscar = "Buscar";
        } catch (e) {
            this.alertMessage = "Ocurrio un error";
            this.alertTitle = "Error";
            this.show("top-right", "error");
        }

      },
    async search(e) {
      e.preventDefault();
      this.getData();
    },
  },
  data() {
    return {
      showHeaderFilter: true,
      estatusSelect: 'finalized',
      ventaSeleccionada:{},
      ventaDetalle:[],
      emailReenviar:"",
      uuidReenviar:"",
      date: moment().startOf('month').format("YYYY/MM/DD"),
      intDateEnd: "",
      dateEnd: moment().format("YYYY/MM/DD"),
      intDate: "",
      options: {
        format: 'YYYY/MM/DD',
        useCurrent: false,
      },
      puestoSelected:{
          puesto:0
      },
      rolSelected:{
          rol:0
      },
      actualizarNIP:false,
      puestosSelect:[],
      rolesSelect:[],
      nuevoCorreo: '',
      clienteNuevo: true,
      clienteModal:{},
      textoBotonGuardar: "Guardar",
      textoBotonBuscar: "Buscar",
      clienteBuscar: "",
      columns: [
        {
          label: "Número",
          field: "numero",
          tdClass: "text-center text-nowrap",
          thClass: "text-center text-nowrap",
        },
        {
          label: "Ticket",
          field: "ticket_number",
          tdClass: "text-center text-nowrap",
          thClass: "text-center text-nowrap",
        },
        {
          label: "Estación",
          field: "branch_name",
          tdClass: "text-center text-nowrap",
          thClass: "text-center text-nowrap",
        },
        {
          label: "No Estación",
          field: "id_branch",
          tdClass: "text-center text-nowrap",
          thClass: "text-center text-nowrap",
        },

        {
          label: "QR",
          field: "id_fueling",
          tdClass: "text-center text-nowrap",
          thClass: "text-center text-nowrap",
        },
        {
          label: "Cliente",
          field: "id_client",
          tdClass: "text-center text-nowrap",
          thClass: "text-center text-nowrap",
        },
        {
          label: "Nombre",
          field: "name",
          tdClass: "text-center text-nowrap",
          thClass: "text-center text-nowrap",
        },
        {
          label: "Correo",
          field: "email",
          tdClass: "text-center text-nowrap",
          thClass: "text-center text-nowrap",
        },
        {
          label: "Producto",
          field: "fuel_type",
          tdClass: "text-center text-nowrap",
          thClass: "text-center text-nowrap",
        },
        {
          label: "Total",
          field: "total",
          tdClass: "text-center text-nowrap",
          thClass: "text-center text-nowrap",
        },
        {
          label: "Litros",
          field: "liters",
          tdClass: "text-center text-nowrap",
          thClass: "text-center text-nowrap",
        },
        {
          label: "Estatus",
          field: "status_qr",
          tdClass: "text-center text-nowrap",
          thClass: "text-center text-nowrap",
        },
        {
          label: "Estatus Pago",
          field: "pay_status",
          tdClass: "text-center text-nowrap",
          thClass: "text-center text-nowrap",
        },
        {
          label: "Pago error",
          field: "pay_err",
          tdClass: "text-center text-nowrap",
          thClass: "text-center text-nowrap",
        },
        {
          label: "Finalizado",
          field: "finalization_date",
          tdClass: "text-center text-nowrap",
          thClass: "text-center text-nowrap",
        },
      ],
      items: [],
    };
  },
};
</script>

<style scoped>
.dx-datagrid-header-panel{
  display: none !important;
}
/* #gridContainer {
  height: 440px;
} */
</style>