<template>
  <div>

    <div class=" loading-search" v-if="loading">
      <div class="fa-3x text-center">
        <i class="fas fa-circle-notch fa-spin text-success" style=""></i>
      </div>
    </div>
    <!-- BEGIN breadcrumb -->
    <ol class="breadcrumb float-xl-end">
      <li class="breadcrumb-item"><a href="javascript:;">Corresponsalia</a></li>
      <li class="breadcrumb-item"><a href="javascript:;">Santander</a></li>
      <li class="breadcrumb-item active"><a href="javascript:;">Cuadre Detalle</a></li>
    </ol>

    <h1 class="page-header">Cuadre Detalle<small></small></h1>
    <div>
      <button class="btn btn-primary" @click="regresar()">
        <i class="fas fa-lg fa-fw me-10px fa-arrow-alt-circle-left"></i> Regresar
      </button>
    </div>

    <form @submit.prevent="search">
      <div class="row">
        <div class="col-md-8">
          <div class="form-group row">

            <!-- <div class="col-md-3">
                        <label class="col-form-label">Fecha inicial</label>
                      <div class="input-group">
                        <date-picker id="dateTimeInput" v-model="date" :config="options"></date-picker>
                        <label for="dateTimeInput" class="input-group-text">
                          <i class="fa fa-calendar"></i>
                        </label>
                      </div>
                    </div> -->


            <!-- <div class="col-md-3">
                        <label class="col-form-label">Fecha final</label>
                      <div class="input-group">
                        <date-picker id="dateTimeInputEnd" v-model="dateEnd" :config="options"></date-picker>
                        <label for="dateTimeInputEnd" class="input-group-text">
                          <i class="fa fa-calendar"></i>
                        </label>
                      </div>
                    </div> -->

            <!-- <div class="col-md-3">
                        <label class="col-form-label">Ciudad</label>
                        <select class="form-select" @change="getEstaciones()" v-model="ciudadSelect" :disabled="deshabilitarCombos">
                            <option v-for="ciudad in ciudades" :value="ciudad.Id" v-bind:key="ciudad.Id">
                                {{ ciudad.Nombre }}
                            </option>
                        </select>
                    </div> -->

            <!-- <div class="col-md-3">
                        <label class="col-form-label">Estación</label>
                        <select class="form-select" v-model="estacionSelect" :disabled="deshabilitarCombos">
                          <option value="0">Todas</option>
                            <option v-for="estacion in estaciones" :value="estacion.NoEstacion" v-bind:key="estacion.NoEstacion">
                            {{ estacion.Nombre }}
                            </option>
                          </select>
                    </div> -->
          </div>
        </div>
        <div class="col-md-2">
          <!-- <button type="submit" class="btn btn-success me-1 mb-1" style="margin-top: 3.1em;">{{textoBotonBuscar}}</button> -->
        </div>
        <div class="col-md-2" style="padding-top: 2.7em;">
          <!-- <download-excel
                  v-if="searchResult.length > 0"
                  class="btn btn-success rounded-pill mt-35"
                  :data="searchResult"
                  :fields="json_fields"
                  :worksheet="estacionSelect"
                  :name="`Compras CG ${date}.xls`"
                  
                  >
                    Descargar en Excel <i class="fas fa-file-excel fa-fw"></i>
                </download-excel>
                <button v-else disabled class="btn btn-success rounded-pill mt-35">
                    Descargar en Excel <i class="fas fa-file-excel fa-fw"></i>
                </button> -->
        </div>
      </div>
    </form>
    <div>

      <panel title="Detalle" bodyClass="p-0" class="" style="margin-top: 15px;">

        <div class="col-12" style="padding: 15px;; background: #ddd;">

          <b-tabs nav-wrapper-class="nav nav-tabs" content-class="p-15px bg-white">
            <!-- BEGIN tab -->
            <b-tab active>
              <template slot="title">
                <span class="d-sm-none">Tab 1</span>
                <span class="d-sm-block d-none">Dolares Capturados</span>
              </template>
              <div class="row">
                <div class="col-md-12">
                  <button type="button" @click="search()" class="btn btn-success me-1 mb-1"
                    style="margin-top: 3.1em;">{{
                      textoBotonBuscar }}</button>
                </div>
                <div class="col-12" style="margin-bottom: 50px;">
                  <template v-if="searchResult.length > 0">
                    <div class="row">
                      <div class="col-3">
                        <label for="">Estación: <b>{{ searchResult[0].Estacion }}</b></label>
                      </div>
                      <div class="col-3">
                        <label for="">Fecha: <b>{{ formatDate(searchResult[0].Fecha) }}</b></label>
                      </div>
                      <div class="col-3">
                        <label for="">Turno: <b>{{ searchResult[0].Turno }}</b></label>
                      </div>
                      <div class="col-3" style="text-align: right; padding-right: 50px;">

                        <table style="width: 250px;">
                          <tr>
                            <td>Importe Corte:</td>
                            <td><b>{{ importeCorteUrl | currency }}</b></td>
                          </tr>
                          <tr>
                            <td>Cambio USD:</td>
                            <td><b>{{ sumatoriaCambioUSD | currency }}</b></td>
                          </tr>
                          <tr>
                            <td>Dolares Recibidos:</td>
                            <td><b>{{ sumatoriaDolaresRecibidos | currency }}</b></td>
                          </tr>
                          <tr>
                            <td>Faltan:</td>
                            <td><b>{{ importeCorteUrl - (sumatoriaDolaresRecibidos - sumatoriaCambioUSD) | currency
                                }}</b>
                            </td>
                          </tr>
                        </table>
                      </div>
                    </div>
                  </template>
                </div>
                <div class="col-12">

                  <DxDataGrid id="gridContainer" :data-source="searchResult" key-expr="Transaccion" :show-borders="true"
                    @exporting="onExporting" :show-column-lines="false" :show-row-lines="false"
                    :row-alternation-enabled="true">
                    <DxScrolling column-rendering-mode="virtual" />
                    <DxPager :show-page-size-selector="true" :allowed-page-sizes="[10, 20, 50, 100]"
                      :show-navigation-buttons="true" />
                    <DxPaging :page-size="10" :page-index="1" />
                    <DxFilterRow />
                    <DxHeaderFilter :visible="showHeaderFilter" />
                    <DxSearchPanel :visible="true" :width="240" placeholder="Search..." />

                    <DxGroupPanel :visible="true" />
                    <DxGrouping :auto-expand-all="true" />
                    <DxExport :enabled="true" :allow-export-selected-data="false" />
                    <DxSelection mode="false" />

                    <DxColumn dataField="Abrir" caption="" :cellTemplate="enviarCGButton" width="60" />
                    <DxColumn dataField="Eliminar" caption="" :cellTemplate="eliminarButton" width="60" />

                    <DxColumn data-field="Transaccion" caption="Transacción" width="100">
                      <DxHeaderFilter :allow-search="true" />
                    </DxColumn>

                    <!-- <DxColumn data-field="Estacion" caption="Estación" /> -->
                    <!-- <DxColumn data-field="NoEmpleado" caption="No Empleado" /> -->
                    <DxColumn data-field="Nombre" caption="Nombre" width="220" />
                    <!-- <DxColumn data-field="Turno" caption="Turno" /> -->
                    <!-- <DxColumn data-field="Fecha" dataType="date" caption="Fecha" /> -->
                    <DxColumn data-field="Fecha" caption="Fecha Venta" dataType="date" format="dd/MM/yyyy" />
                    <DxColumn data-field="FechaCorte" caption="Fecha Corte" dataType="date" format="dd/MM/yyyy" />
                    <DxColumn data-field="Hora" caption="Hora" />

                    <DxColumn data-field="ImporteDespacho" caption="Importe MXN" width="140" dataType="number">
                      <DxFormat type="currency" :precision="2" />
                    </DxColumn>
                    <DxColumn data-field="ImporteUSD" caption="Importe USD" width="140" dataType="number">
                      <DxFormat type="currency" :precision="2" />
                    </DxColumn>
                    <DxColumn data-field="DolaresRecibidos" caption="Dolares Recibidos" width="160" dataType="number">
                      <DxFormat type="currency" :precision="2" />
                    </DxColumn>

                    <DxColumn data-field="TipoCambio" caption="Tipo Cambio" dataType="number">
                      <DxFormat type="currency" :precision="2" />
                    </DxColumn>
                    <DxColumn data-field="CambioUSD" caption="Cambio USD" dataType="number">
                      <DxFormat type="currency" :precision="2" />
                    </DxColumn>
                    <DxColumn data-field="CambioMXN" caption="Cambio MXN" dataType="number">
                      <DxFormat type="currency" :precision="2" />
                    </DxColumn>

                    <DxSummary>
                      <DxTotalItem column="ImporteDespacho" summary-type="sum" value-format="$#,##0.##"
                        displayFormat='{0}'>
                      </DxTotalItem>
                      <DxTotalItem column="ImporteUSD" summary-type="sum" value-format="$#,##0.##" displayFormat='{0}'>
                      </DxTotalItem>
                      <DxTotalItem column="DolaresRecibidos" summary-type="sum" value-format="$#,##0.##"
                        displayFormat='{0}'>
                      </DxTotalItem>

                      <DxTotalItem column="CambioUSD" summary-type="sum" value-format="$#,##0.##" displayFormat='{0}'>
                      </DxTotalItem>
                      <DxTotalItem column="CambioMXN" summary-type="sum" value-format="$#,##0.##" displayFormat='{0}'>
                      </DxTotalItem>

                    </DxSummary>

                  </DxDataGrid>
                </div>
              </div>

            </b-tab>
            <!-- END tab -->
            <!-- BEGIN tab -->
            <b-tab>
              <template slot="title">
                <span class="d-sm-none">Tab 2</span>
                <span class="d-sm-block d-none">Agregar</span>
              </template>

              <div class="row">
                <div class="col-md-12">
                  <button type="button" @click="searchDetalle()" class="btn btn-success me-1 mb-1"
                    style="margin-top: 3.1em;">Buscar</button>
                </div>
                <div class="col-12 dxDataDridRendi">

                  <DxDataGrid id="gridContainerDetalle" :data-source="searchResultDetalle" key-expr="Transaccion"
                    :show-borders="true" @exporting="onExporting" :show-column-lines="false" :show-row-lines="false"
                    :row-alternation-enabled="true">
                    <DxScrolling column-rendering-mode="virtual" />
                    <DxPager :show-page-size-selector="true" :allowed-page-sizes="[10, 20, 50, 100]"
                      :show-navigation-buttons="true" />
                    <DxPaging :page-size="10" :page-index="1" />
                    <DxFilterRow />
                    <DxHeaderFilter :visible="showHeaderFilter" />
                    <DxSearchPanel :visible="true" :width="240" placeholder="Search..." />

                    <DxGroupPanel :visible="true" />
                    <DxGrouping :auto-expand-all="true" />
                    <DxExport :enabled="true" :allow-export-selected-data="false" />
                    <DxSelection mode="false" />

                    <DxColumn dataField="Abrir" caption="Agregar" :cellTemplate="agregarButton" />

                    <DxColumn data-field="Transaccion" caption="No Transacción" width="200">
                      <DxHeaderFilter :allow-search="true" />
                    </DxColumn>
                    <DxColumn data-field="Fecha" dataType="date" caption="Fecha Venta" format="dd/MM/yyyy" />
                    <DxColumn data-field="FechaCorte" dataType="date" caption="Fecha Corte" format="dd/MM/yyyy" />
                    <DxColumn data-field="Hora" caption="Hora" />
                    <DxColumn data-field="Turno" caption="Turno" />
                    <DxColumn data-field="Bomba" caption="Bomba" />

                    <DxColumn data-field="ImporteDespacho" caption="Importe Despacho" dataType="number">
                      <DxFormat type="currency" :precision="2" />
                    </DxColumn>

                    <DxColumn data-field="TipoCambio" caption="Tipo de cambio" dataType="number">
                      <DxFormat type="currency" :precision="2" />
                    </DxColumn>

                    <DxColumn data-field="ImporteUSD" caption="Importe USD" dataType="number">
                      <DxFormat type="currency" :precision="2" />
                    </DxColumn>

                    <DxSummary>

                      <DxTotalItem column="ImporteDespacho" summary-type="sum" value-format="$#,##0.##"
                        displayFormat='{0}'>
                      </DxTotalItem>

                    </DxSummary>

                  </DxDataGrid>

                </div>
              </div>

            </b-tab>
            <!-- END tab -->

          </b-tabs>

        </div>

      </panel>

    </div>

    <!-- #modal-dialog -->
    <b-modal id="modalDialog" size="md">
      <template slot="modal-header">
        <h4 class="modal-title">Transacción {{ datosModal.Transaccion }}</h4>
        <a class="btn-close" @click="$bvModal.hide('modalDialog')"></a>
      </template>

      <div class="row">
        <div class="col-12">
          <form action="/" method="POST">
            <fieldset>

              <div class="mb-3">
                <label class="form-label" for="txtRFC">Importe</label>
                <input class="form-control" type="number" step="1" placeholder="Importe" disabled="true"
                  v-model="datosModal.ImporteDespacho" />
              </div>

              <div class="mb-3">
                <label class="form-label" for="txtRFC">Tipo de Cambio</label>
                <!--traer de API Catalogos/GetTipoCambio -->
                <!-- <input class="form-control" v-model.number="datosModal.TipoCambio" v-number-mask type="number" step="1" id="txtRFC" placeholder="tipo de cambio" /> -->

                <input class="form-control" v-model="datosModal.TipoCambio" disabled="true" type="number" step="1"
                  id="txtRFC" placeholder="tipo de cambio" />
              </div>
              <div class="mb-3">
                <label class="form-label" for="txtRFC">Importe USD</label>
                <input class="form-control" type="number" step="1" disabled="true" placeholder=""
                  v-model="datosModal.ImporteUSD" />
              </div>

              <div class="mb-3">
                <label class="form-label" for="txtRFC">Dolares Recibidos</label>
                <!-- <input class="form-control"  type="number" step="1" id="txtRFC" placeholder="" v-model="datosModal.DolaresRecibidos" /> -->
                <input class="form-control" type="number" step="1" placeholder="" v-on:input="updateDatosModal1(true)"
                  v-model="datosModal.DolaresRecibidos" />

                <!-- @update:model-value="updateSearchText" -->
              </div>


              <div class="mb-3">
                <label class="form-label" for="txtRFC">Cambio MXN</label>
                <input class="form-control" type="number" step="1" placeholder="" v-model="datosModal.CambioMXN"
                  disabled="true" />
              </div>

              <div class="mb-3">
                <label class="form-label" for="txtRFC">Cambio USD</label>
                <!-- v-on:input="updateDatosModal2" -->
                <input class="form-control" type="number" step="1" placeholder="" v-on:input="updateDatosModal1(false)"
                  v-model="datosModal.CambioUSD" />
              </div>

            </fieldset>
          </form>
        </div>
      </div>

      <template slot="modal-footer">
        <button type="button" class="btn btn-primary mr-auto" @click="guardarAgregar()">Guardar</button>
        <button type="button" class="btn btn-secondary" @click="$bvModal.hide('modalDialog')">Cancelar</button>
      </template>
    </b-modal>

    <b-modal id="modalDialogVisor" size="xl" centered cancel-variant="default">
      <template slot="modal-header">
        <h4 class="modal-title">Visualizando Ticket</h4>
        <a class="btn-close" @click="$bvModal.hide('modalDialogVisor')"></a>
      </template>
      <div class="card">
        <object type="application/pdf" :data="Archivo.base64" width="100%" height="600px"></object>
      </div>

      <template #modal-footer>
        <div class="row">
          <div class="col">
            <b-button class="float-right" variant="primary" @click="$bvModal.hide('modalDialogVisor')">
              Close
            </b-button>
          </div>
        </div>
      </template>

    </b-modal>

    <!-- bottom left vue-notification -->
    <notifications group="top-right" position="top right" :speed="1000" />
  </div>
</template>

<script>

import axios from 'axios';
import moment from 'moment';

import {
  DxDataGrid, DxFormat, DxSummary, DxTotalItem,
  DxPaging, DxPager, DxScrolling, DxColumn, DxExport, DxSelection, DxGroupPanel, DxGrouping, DxHeaderFilter, DxFilterRow, DxSearchPanel,
} from 'devextreme-vue/data-grid';
import { Workbook } from 'exceljs';
import { saveAs } from 'file-saver';
// Our demo infrastructure requires us to use 'file-saver-es'. We recommend that you use the official 'file-saver' package in your applications.
import { exportDataGrid } from 'devextreme/excel_exporter';
//import service from './data.js';


var numeral = require("numeral");
import Vue from "vue";

import JsonExcel from "vue-json-excel";
Vue.component("downloadExcel", JsonExcel);
Vue.filter("formatNumber", function (value) {
  return numeral(value).format("0,00.00"); // displaying other groupings/separators is possible, look at the docs
});

Vue.filter("currency", function (value) {
  return numeral(value).format("$0,0.00");
});

Vue.directive('number-mask', {
  bind(el, binding) {
    el.addEventListener('input', function (event) {

      // eslint-disable-line no-debugger
      // Remove non-numeric characters
      let value = event.target.value.replace(/[^0-9.]/g, '');

      // Format the number with two decimal places
      value = parseFloat(value).toFixed(2);

      // Update the input value
      event.target.value = value;
    });
  },
});

export default {
  beforeRouteLeave(to, from, next) {
    // eslint-disable-line no-debugger  
    var toPath = to.path;
    // alert('salir')
    if (toPath != '/corresponsalia/santander/cuadre') {
      localStorage.removeItem('parametrosCuadre');
    }
    next();
  },
  // beforeDestroy(){

  //   alert('salir')
  //     localStorage.removeItem('parametrosCuadre');

  // },

  created() {
    // Add an event listener for beforeunload
    window.addEventListener('beforeunload', this.handleBeforeUnload);
  },
  beforeDestroy() {
    // Remove the event listener when the component is destroyed
    window.removeEventListener('beforeunload', this.handleBeforeUnload);
  },
  mounted: function () {
    /* eslint-disable no-debugger */
    //
    var self = this;

    var userSetting = JSON.parse(localStorage.getItem('userSettings'));
    this.usuario = userSetting.Usuario;
    this.usuarioEstacion = userSetting.Estacion;
    this.IdRol = userSetting.IdRol;
    // this.getCiudades();
    // this.ciudadSelect = userSetting.CiudadId;
    // this.getEstaciones();
    // this.estacionSelect = userSetting.Estacion;



    // eslint-disable-line no-debugger
    self.estacionSearch = self.$route.params.estacion;
    self.fechaSearch = self.$route.params.fecha;
    self.turnoSearch = self.$route.params.turno;
    self.ciudadSearch = self.$route.params.ciudad;
    self.importeCorteUrl = self.$route.params.importecorte;

    if (self.$route.params.fecha != undefined) {

      self.search();
    }

    if (this.IdRol == 3 || this.IdRol == 7) {
      this.deshabilitarCombos = true;
      this.botonEliminarVisible = false;

    }

  },
  beforeCreate() {

  },

  components: {
    DxTotalItem, DxDataGrid, DxSummary, DxFormat, DxPaging, DxPager, DxScrolling, DxColumn, DxExport, DxSelection, DxGroupPanel, DxGrouping, DxHeaderFilter, DxFilterRow, DxSearchPanel,
  },
  beforeMount() {
    // console.log('beforeMount');
  },
  computed: {
  },
  methods: {
    handleBeforeUnload(event) {
      // alert('salir')
      localStorage.removeItem('parametrosCuadre');
    },
    regresar() {

      this.$router.push({ path: '/corresponsalia/santander/cuadre' });
    },
    formatDate(date) {
      return moment(date).locale('es').format('DD MMM YYYY');
    },
    // updateDatosModal2() {
    //   var self = this;

    //   var tipoCambio = self.datosModal.TipoCambio;
    //   var dolaresRecibidos = self.datosModal.DolaresRecibidos;
    //   var cambioMXN = self.datosModal.CambioMXN;
    //   var cambioUSD = self.datosModal.cambioUSD;
    //   var importeUSD = self.datosModal.ImporteUSD;

    //   // eslint-disable-line no-debugger
    //   var dif = dolaresRecibidos - importeUSD;
    //   // var dif = 0;


    //   if (dif > 0) {
    //     self.datosModal.CambioMXN = 0;
    //     self.datosModal.CambioUSD = dif;

    //   } else {
    //     self.datosModal.CambioMXN = 0;
    //     self.datosModal.CambioUSD = 0;
    //   }
    // },
    updateDatosModal1(param) {
      var self = this;

      var tipoCambio = self.datosModal.TipoCambio;
      var dolaresRecibidos = self.datosModal.DolaresRecibidos;
      var cambioMXN = self.datosModal.CambioMXN;
      var cambioUSD = 0;
      var importeUSD = self.datosModal.ImporteUSD;

      var dif = 0;
      var usd = 0;
      var centavos = 0;

      if (param) {

        const filteredValue = self.datosModal.DolaresRecibidos.replace(/[^0-9]/g, '');
        self.datosModal.DolaresRecibidos = filteredValue;

        if (self.datosModal.DolaresRecibidos < 0) {
          self.datosModal.DolaresRecibidos = 0;
        }

        if (dolaresRecibidos > 250) {
          self.datosModal.DolaresRecibidos = 0;
          this.alertTitle = "Error";
          this.alertMessage = "No se puede recibir mas de $250 DLLS";
          this.show('top-right', 'error');
          return;
        }

        debugger;
        //self.datosModal.CambioUSD = 0;
        // self.datosModal.CambioMXN = 0;

        cambioUSD = dolaresRecibidos - importeUSD;

        if (dolaresRecibidos > importeUSD) {
          dif = dolaresRecibidos - importeUSD;

          usd = dif.toFixed(0);
          centavos = dif - usd;

          self.datosModal.CambioUSD = dif.toFixed(0);

          if (centavos > 0) {
            self.datosModal.CambioMXN = centavos * tipoCambio;
          }

          self.datosModal.CambioMXN = self.datosModal.CambioMXN.toFixed(2);

          if (cambioUSD >= dolaresRecibidos) {
            debugger;
            // self.datosModal.CambioUSD = 0;
            this.alertTitle = "Error";
            this.alertMessage = "El cambio no puede ser mayor a los Dolares recibidos";
            this.show('top-right', 'error');
            return;
          }
        } else {
          cambioUSD = 0;
          self.datosModal.CambioUSD = 0;
          self.datosModal.CambioMXN = 0;
        }

      } else {
        cambioUSD = 0;
        const filtereCambioUSD = self.datosModal.CambioUSD.replace(/[^0-9]/g, '');
        self.datosModal.CambioUSD = filtereCambioUSD;
        cambioUSD = self.datosModal.CambioUSD;

        if (parseInt(cambioUSD) >= parseInt(dolaresRecibidos)) {
          this.alertTitle = "Error";
          this.alertMessage = "El cambio no puede ser mayor o iguala los dolares recibidos";
          this.show('top-right', 'error');
          return;
        }

        usd = dolaresRecibidos - importeUSD - cambioUSD;
        if (usd > 0) {
          self.datosModal.CambioMXN = usd * tipoCambio;
        }
        self.datosModal.CambioMXN = self.datosModal.CambioMXN.toFixed(2);
      }
    },
    // updateDatosModal1() {
    //   var self = this;

    //   var tipoCambio = self.datosModal.TipoCambio;
    //   var dolaresRecibidos = self.datosModal.DolaresRecibidos;
    //   var cambioMXN = self.datosModal.CambioMXN;
    //   var cambioUSD = self.datosModal.cambioUSD;
    //   var importeUSD = self.datosModal.ImporteUSD;

    //   // eslint-disable-line no-debugger
    //   var dif = dolaresRecibidos - importeUSD;
    //   // var dif = 0;

    //   if (dif > 0) {
    //     self.datosModal.CambioUSD = 0;
    //     self.datosModal.CambioMXN = dif * tipoCambio;
    //   } else {
    //     self.datosModal.CambioMXN = 0;
    //     self.datosModal.CambioUSD = 0;
    //   }
    // },
    async guardarAgregar() {

      var self = this;
      try {

        if (self.datosModal.DolaresRecibidos > 250) {
          this.alertTitle = "Error";
          this.alertMessage = "No se puede recibir mas de $250 DLLS";
          this.show('top-right', 'error');
          return;
        }
        if (self.datosModal.DolaresRecibidos < 1) {
          this.alertTitle = "Error";
          this.alertMessage = "No se puede recibir menos de $1 DLL";
          this.show('top-right', 'error');
          return;
        }

        if (parseInt(self.datosModal.CambioUSD) >= parseInt(self.datosModal.DolaresRecibidos)) {
          this.alertTitle = "Error";
          this.alertMessage = "El cambio no puede ser mayor o iguala los dolares recibidos";
          this.show('top-right', 'error');
          return;
        }

        self.despacho.ImporteUSD = self.datosModal.ImporteUSD;
        self.despacho.DolaresRecibidos = self.datosModal.DolaresRecibidos;
        self.despacho.TipoCambio = self.datosModal.TipoCambio;
        self.despacho.CambioUSD = self.datosModal.CambioUSD;
        self.despacho.CambioMXN = self.datosModal.CambioMXN;
        self.despacho.Estacion = self.estacionSearch;
        self.despacho.Usuario = this.usuario;

        var dataToSend = {
          "Data": self.despacho
        }
        // eslint-disable-line no-debugger
        const response = await axios.post('Corresponsalia/SetTicket', dataToSend);

        // eslint-disable-line no-debugger

        if (response.data.Success) {

          this.alertTitle = '';
          this.alertMessage = response.data.Message;
          this.show('top-right', 'success');
          this.$bvModal.hide('modalDialog');

        } else {

          this.alertTitle = response.data.Error;
          this.alertMessage = response.data.Message;
          this.show('top-right', 'error');
        }

      } catch (e) {

        // this.alertTitle = response.data.Error;
        this.alertMessage = "Error GetDespacho";
        this.show('top-right', 'error');
      }
    },
    async getDespacho(transaccion) {

      var self = this;
      try {
        self.despacho = {};
        var dataToSend = {
          "Data": {
            "Estacion": self.estacionSearch,
            "Transaccion": transaccion
          }
        }
        const response = await axios.post('Corresponsalia/GetDespacho', dataToSend);

        // eslint-disable-line no-debugger
        if (response.data.Success) {
          self.despacho = response.data.response;
          for (var d = 0; d < self.despacho.Detalle.length; d++) {
            self.despacho.Detalle[d].Estacion = self.estacionSearch;
          }

        } else {

          this.alertTitle = response.data.Error;
          this.alertMessage = response.data.Message;
          this.show('top-right', 'error');
        }

      } catch (e) {

        // this.alertTitle = response.data.Error;
        this.alertMessage = "Error GetDespacho";
        this.show('top-right', 'error');
      }
    },

    eliminarButton(cellElement, cellInfo) {
      var self = this;
      if (self.botonEliminarVisible) {
        const button = document.createElement("i");
        button.innerText = "";//Ticket
        // button.className = "btn btn-sm btn-success";
        button.className = "btn btn-md btn-danger fas fa-trash iconoCuadreDetalle";
        button.addEventListener("click", () => {

          this.handleButtonClickDelTicket(cellInfo.data);
        });

        cellElement.appendChild(button);

      }
    },
    enviarCGButton(cellElement, cellInfo) {

      const button = document.createElement("i");
      button.innerText = "";//Ticket
      // button.className = "btn btn-sm btn-success";
      button.className = "btn btn-md btn-success fas fa-file-alt iconoCuadreDetalle";
      button.addEventListener("click", () => {

        this.handleButtonClick(cellInfo.data);
      });

      cellElement.appendChild(button);
    },
    agregarButton(cellElement, cellInfo) {

      // const button = document.createElement("button");
      const button = document.createElement("button");
      button.innerText = "";
      button.className = "btn btn-xs btn-success fas fa-plus";
      // button.className = "btn btn-md btn-success fas fa-file-alt ";

      // button.className = "btn btn-md btn-success fas fa-file-alt iconoCuadreDetalle";
      button.addEventListener("click", () => {

        this.handleButtonClickAgregar(cellInfo.data);
      });

      cellElement.appendChild(button);
    },
    async handleButtonClick(data) {
      var self = this;

      self.Archivo = [];
      // eslint-disable-line no-debugger
      // alert(data.NoEstacion);  
      // this.$swal({
      //   title: '',
      //   text: '¿Disponible proximamente',
      //   type: 'primary',
      //   showCancelButton: false,
      //   buttonsStyling: false,
      //   confirmButtonText: 'Aceptar',
      //   cancelButtonText: 'Cerrar',
      //   confirmButtonClass: 'btn me-5px btn-primary',
      //   cancelButtonClass: 'btn btn-default',
      // })
      self.loading = true;
      try {

        var dataToSend = {
          "Data": {
            "Estacion": data.NoEstacion,
            "Transaccion": data.Transaccion,
            "Usuario": self.usuario
          }
        };

        // eslint-disable-line no-debugger
        const response = await axios.post("Corresponsalia/GetTicketPDF", dataToSend);

        self.loading = false;

        if (response.data.Success) {

          this.alertMessage = response.data.Message;
          this.alertTitle = "";
          this.show("top-right", "success");

          self.Archivo.base64 = "data:application/pdf;base64," + response.data.response;

          console.log(self.Archivo.base64);


          self.$bvModal.show('modalDialogVisor');

        } else {

          this.alertMessage = response.data.Message;
          this.alertTitle = "Error";
          this.show("top-right", "error");
        }

      } catch (e) {
        self.loading = false;
        self.textoBotonEnviar = "Enviar";
        this.alertMessage = "Ocurrio un error";
        this.alertTitle = "Error";
        this.show("top-right", "error");
      }



    },
    handleButtonClickDelTicket(data) {
      // eslint-disable-line no-debugger
      // if(confirm('Desea eliminar la transacción '+data.Transaccion)){
      //   this.delTicket(data)
      // }

      var swalType = 'question';

      var btnClass = (swalType == 'error') ? 'danger' : swalType;
      btnClass = (swalType == 'question') ? 'primary' : btnClass;

      this.$swal({
        title: 'Confirme',
        text: '¿Desea eliminar la transacción ' + data.Transaccion + '?',
        type: swalType,
        showCancelButton: true,
        buttonsStyling: false,
        confirmButtonText: 'Aceptar',
        cancelButtonText: 'Cancelar',
        confirmButtonClass: 'btn me-5px btn-' + btnClass + '',
        cancelButtonClass: 'btn btn-default',
      }).then((result) => {
        if (result.isConfirmed) {
          // eslint-disable-line no-debugger
          // User clicked the confirm button
          this.delTicket(data)
        }
      });

      // this.$swal({
      //   title: 'Are you sure?',
      //   text: 'You will not be able to recover this imaginary file!',
      //   icon: 'warning',
      //   showCancelButton: true,
      //   confirmButtonColor: '#3085d6',
      //   cancelButtonColor: '#d33',
      //   confirmButtonText: 'Yes, delete it!',
      // }).then((result) => {
      //   if (result.isConfirmed) {
      //     // User clicked the confirm button
      //     this.handleConfirmedAction();
      //   }
      // });

    },

    async delTicket(data) {
      //api/Precio/SetAcusePrecio


      var self = this;
      // self.textoBotonEnviar = "Enviando...";
      self.loading = true;
      try {

        // eslint-disable-line no-debugger
        var dataToSend = {
          "Data": {
            "Estacion": self.estacionSearch,
            "Transaccion": data.Transaccion
          }
        };
        // eslint-disable-line no-debugger
        const response = await axios.post("Corresponsalia/DelTicket", dataToSend);

        self.loading = false;
        // self.textoBotonEnviar = "Enviar";
        // eslint-disable-line no-debugger
        if (response.data.Success) {

          this.alertMessage = response.data.Message;
          this.alertTitle = "";
          this.show("top-right", "success");


          // self.descargarPDF(response.data.response, data.Nombre);
          this.search()
        }
        else {

          this.alertMessage = response.data.Message;
          this.alertTitle = "Error";
          this.show("top-right", "error");
        }


      } catch (e) {
        self.loading = false;
        self.textoBotonEnviar = "Enviar";
        this.alertMessage = "Ocurrio un error";
        this.alertTitle = "Error";
        this.show("top-right", "error");
      }
    },

    handleButtonClickAgregar(data) {
      // eslint-disable-line no-debugger

      let datastr = JSON.stringify(data);
      let dataM = JSON.parse(datastr);
      var self = this;
      self.datosModal = {};
      self.datosModal = dataM;
      self.datosModal.Transaccion = data.Transaccion + "0";

      self.getDespacho(self.datosModal.Transaccion);
      //   alert(data.NoEstacion);
      this.$bvModal.show('modalDialog');

    },

    async enviarCG(data) {
      //api/Precio/SetAcusePrecio


      var self = this;
      // self.textoBotonEnviar = "Enviando...";
      self.loading = true;
      try {
        // const segments = data.Path.split('/');

        // // Get the last element of the array, which is the filename
        // const fileName = segments[segments.length - 1];

        // eslint-disable-line no-debugger
        var dataToSend = {
          "Data": {
            "Path": data.Path,
            "Usuario": self.usuario
            // "Path": "CRE/ACUSES/2023/10/23/PL3988EXPES2015.pdf", 
            // "Usuario": "juan.garcia"
          }
        };
        // eslint-disable-line no-debugger
        const response = await axios.post("Precio/GetAcusePDF", dataToSend);

        self.loading = false;
        // self.textoBotonEnviar = "Enviar";
        // eslint-disable-line no-debugger
        if (response.data.Success) {

          this.alertMessage = response.data.Message;
          this.alertTitle = "";
          this.show("top-right", "success");


          self.descargarPDF(response.data.response, data.Nombre);
        }
        else {

          this.alertMessage = response.data.Message;
          this.alertTitle = "Error";
          this.show("top-right", "error");
        }


      } catch (e) {
        self.loading = false;
        self.textoBotonEnviar = "Enviar";
        this.alertMessage = "Ocurrio un error";
        this.alertTitle = "Error";
        this.show("top-right", "error");
      }
    },





    onExporting(e) {
      const workbook = new Workbook();
      const worksheet = workbook.addWorksheet('Pedidos');

      exportDataGrid({
        component: e.component,
        worksheet: worksheet,
        autoFilterEnabled: true
      }).then(() => {
        workbook.xlsx.writeBuffer().then((buffer) => {
          saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'Pedidos.xlsx');
        });
      });
      e.cancel = true;
    },
    async getCiudades() {
      try {
        const response = await axios.get('Catalogos/GetCiudades');
        if (response.data.Success) {
          if (response.data.response.length == 0) {
            this.alertMessage = "No se encontró información.";
            this.alertTitle = "Error";
            this.show('top-right', 'error');
          }
        } else {
          console.log("error")
          this.alertMessage = response.data.Error;
          this.alertTitle = "Error";
          this.show('top-right', 'error');
        }
        this.ciudades = response.data.response;
      } catch (e) {
        //this.alert = true;
        //this.error = 'Invalid email/password';
      }
    },
    async getEstaciones() {
      try {
        const response = await axios.post('Estacion/GetEstaciones?ciudad=' + this.ciudadSelect);
        if (response.data.Success) {
          if (response.data.response.length == 0) {
            this.alertMessage = "No se encontró información.";
            this.alertTitle = "Error";
            this.show('top-right', 'error');
          }
        } else {
          console.log("error")
          this.alertMessage = response.data.Error;
          this.alertTitle = "Error";
          this.show('top-right', 'error');
        }
        this.estaciones = response.data.response;

        // eslint-disable-line no-debugger
        // this.productos = [];
        // this.getProductos();
      } catch (e) {
        //this.alert = true;
        //this.error = 'Invalid email/password';
      }
    },
    show(group, type = '') {
      const text = this.alertMessage;
      this.$notify({
        group,
        title: this.alertTitle,
        text,
        type,
        data: {
          randomNumber: Math.random()
        }
      })
    },
    clean(group) {
      this.$notify({ group, clean: true })
    },
    swalNotification(swalType) {
      var btnClass = (swalType == 'error') ? 'danger' : swalType;
      btnClass = (swalType == 'question') ? 'primary' : btnClass;
      this.$swal({
        title: 'Are you sure?',
        text: 'You will not be able to recover this imaginary file!',
        type: swalType,
        showCancelButton: true,
        buttonsStyling: false,
        confirmButtonText: swalType.charAt(0).toUpperCase() + swalType.substr(1).toLowerCase(),
        cancelButtonText: 'Cancel',
        confirmButtonClass: 'btn me-5px btn-' + btnClass + '',
        cancelButtonClass: 'btn btn-default',
      })
    },

    async search() {
      // eslint-disable-line no-debugger
      var self = this;
      //e.preventDefault();

      self.searchResult = [];

      self.textoBotonBuscar = "Buscando...";
      self.loading = true;
      try {

        var dataToSend = {
          "Data": {
            "Estacion": self.estacionSearch,
            "Fecha": self.fechaSearch,
            "Turno": self.turnoSearch,
          }
        };


        // var dataToSend = {
        //   "Data": {
        //       "FechaIni": self.fechaSearch,
        //       "FechaFin":self.fechaSearch,
        //       "Ciudad": self.ciudadSearch, 
        //       "Estacion": self.estacionSearch,

        //   }
        // };

        self.sumatoriaDolaresRecibidos = 0;
        self.sumatoriaCambioUSD = 0;


        // eslint-disable-line no-debugger
        const response = await axios.post('Corresponsalia/GetTicketsUSDTurno', dataToSend);

        // eslint-disable-line no-debugger
        self.loading = false;
        self.textoBotonBuscar = "Buscar";
        if (response.data.Success) {
          if (response.data.response.length == 0) {
            self.alertMessage = "No se encontró información del día especificado.";
            self.alertTitle = "Error";
            self.show('top-right', 'error');
          } else {

            // eslint-disable-line no-debugger
            // var respuesta = response.data.response.Detalle;
            //self.RespuestaCompras = response.data.response.Pedidos;
            self.searchResult = response.data.response;

            for (var sr = 0; sr < self.searchResult.length; sr++) {
              self.sumatoriaDolaresRecibidos += self.searchResult[sr].DolaresRecibidos;
              self.sumatoriaCambioUSD += self.searchResult[sr].CambioUSD;

            }

          }


        } else {
          console.log("error")
          self.alertMessage = response.data.Message;
          self.alertTitle = response.data.Error;
          self.show('top-right', 'error');
        }





        self.loading = false;
      } catch (e) {
        self.loading = false;
        self.textoBotonBuscar = "Buscar";
        self.alertMessage = 'Error al consultar';
        self.alertTitle = '';
        self.show('top-right', 'error');
        //this.alert = true;
        //this.error = 'Invalid email/password';
      }
    },

    async searchDetalle() {
      // eslint-disable-line no-debugger
      var self = this;
      //e.preventDefault();


      //   self.textoBotonBuscar = "Buscando...";
      self.loading = true;
      try {

        var dataToSend = {
          "Data": {
            "Estacion": self.estacionSearch,
            "Fecha": self.fechaSearch,
            "Turno": self.turnoSearch,
          }
        };


        // var dataToSend = {
        //   "Data": {
        //       "FechaIni": self.fechaSearch,
        //       "FechaFin":self.fechaSearch,
        //       "Ciudad": self.ciudadSearch, 
        //       "Estacion": self.estacionSearch,

        //   }
        // };


        self.searchResultDetalle = [];

        // eslint-disable-line no-debugger
        const response = await axios.post('Corresponsalia/GetDespachos', dataToSend);

        // eslint-disable-line no-debugger
        self.loading = false;
        // self.textoBotonBuscar = "Buscar";
        if (response.data.Success) {
          if (response.data.response.length == 0) {
            self.alertMessage = "No se encontró información del día especificado.";
            self.alertTitle = "Error";
            self.show('top-right', 'error');
            self.searchResultDetalle = [];
          } else {

            // eslint-disable-line no-debugger
            // var respuesta = response.data.response.Detalle;
            //self.RespuestaCompras = response.data.response.Pedidos;
            self.searchResultDetalle = response.data.response;




          }


        } else {
          self.searchResultDetalle = [];
          console.log("error")
          self.alertMessage = response.data.Message;
          self.alertTitle = response.data.Error;
          self.show('top-right', 'error');
        }


        self.loading = false;
      } catch (e) {
        self.loading = false;
        self.loading = false;
        self.textoBotonBuscar = "Buscar";
        self.alertMessage = 'Error al consultar';
        self.alertTitle = '';
        self.show('top-right', 'error');
        //this.alert = true;
        //this.error = 'Invalid email/password';
      }
    }
  },
  data() {
    return {
      sumatoriaDolaresRecibidos: 0,
      sumatoriaCambioUSD: 0,
      despacho: {},
      datosModal: {},
      estacionSearch: null,
      fechaSearch: null,
      turnoSearch: 11,
      turnoSelect: 11,
      showHeaderFilter: true,
      RespuestaCompras: [],
      loading: false,
      deshabilitarCombos: false,
      botonEliminarVisible: true,
      ciudades: [],
      estaciones: [],
      ciudadSelect: 1,
      estacionSelect: 0,
      date: moment().startOf('month').format("YYYY/MM/DD"),
      intDateEnd: "",
      dateEnd: moment().format("YYYY/MM/DD"),
      intDate: "",
      options: {
        format: 'YYYY/MM/DD',
        useCurrent: false,
      },
      pageUserSettings: {},
      alertMessage: "",
      alertTitle: "",
      searchResult: [],
      textoBotonBuscar: "Buscar",
      Archivo: {
        base64: '',
      },

    };
  }
}
</script>
<style scoped>
.dx-datagrid-header-panel {
  display: none !important;
}

.loading-search {
  position: absolute;
  width: 100%;
  left: 0px;
  height: 100%;
  background: rgba(0, 0, 0, 0.1);
  z-index: 1000;
  padding-top: 150px;
  top: 0;
}

.sumatoriasRow {
  background-color: #bbb;
}

.currencyRow {
  text-align: right !important;
}

.vgt-fixed-header {
  overflow-y: auto;
}

.vgt-table__header {
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: #f5f5f5;
}

.iconoCuadreDetalle {
  padding: 3px 8px !important;
}

.iconoCuadreDetalle::before {
  font-size: 2rem !important;
}

/* .dxDataDridRendi .dx-datagrid-nowrap, 
.dxDataDridRendi .dx-datagrid-nowrap .dx-header-row>td>.dx-datagrid-text-content {
    white-space: normal !important;
} */
</style>