<template>
	<div>
		<!-- BEGIN breadcrumb -->
		<ol class="breadcrumb float-xl-end">
			<li class="breadcrumb-item"><a href="javascript:;">Reportes</a></li>
			<li class="breadcrumb-item"><a href="javascript:;">Bancos</a></li>
			<li class="breadcrumb-item active"><a href="javascript:;">Transacciones Dobles</a></li>
		</ol>
		<!-- END breadcrumb -->
		<!-- BEGIN page-header -->
		<h1 class="page-header">Transacciones Dobles<small>.</small></h1>
        <!-- <button class="btn" v-on:click="redirectTanques()">tanques</button> -->
		<!-- END page-header -->
		<!-- <a href="javascript:;" v-on:click="show('top-right', 'warn')" class="btn btn-sm btn-primary">Demo</a> -->
		
    <div class="row">
      <div class="col-md-8">
        <form @submit.prevent="search">    
          <div class="form-group row">
            <div class="col-md-3">
                <label class="col-form-label">Fecha</label>
              <div class="input-group">
                <date-picker id="dateTimeInput" v-model="date" :config="options"></date-picker>
                <label for="dateTimeInput" class="input-group-text">
                  <i class="fa fa-calendar"></i>
                </label>
              </div>
            </div>
      
            <div class="col-md-3">
                <label class="col-form-label">Ciudad</label>
                <select class="form-select" @change="getEstaciones()" v-model="ciudadSelect" :disabled="deshabilitarCombos">
                    <option v-for="ciudad in ciudades" :value="ciudad.Id" v-bind:key="ciudad.Id">
                        {{ ciudad.Nombre }}
                    </option>
                </select>
            </div>
            <div class="col-md-3">
                <label class="col-form-label">Estación</label>
                <select class="form-select" v-model="estacionSelect" :disabled="deshabilitarCombos">
                  <option value="0">Seleccione</option>
                    <option v-for="estacion in estaciones" :value="estacion.NoEstacion" v-bind:key="estacion.NoEstacion">
                    {{ estacion.Nombre }}
                    </option>
                  </select>
            </div>
            <div class="col-md-3" style="padding-top: 2.65em;">
              <button type="submit" class="btn btn-success me-1 mb-1 pull-right">{{textoBotonBuscar}}</button>
            </div>
          </div>
        </form>
      </div>
      



    </div>


    <DxDataGrid
      id="gridContainer"
      :data-source="ReporteGetNet"
      key-expr="nu_operaion"
      :show-borders="true"
      @exporting="onExporting"
      :show-column-lines="false"
      :show-row-lines="false"
      :row-alternation-enabled="true"
      :column-width="100"
    >
    
    <DxScrolling column-rendering-mode="virtual"/>
    <DxPager
            :show-page-size-selector="true"
            :allowed-page-sizes="[10, 20, 50, 100]"
            :show-navigation-buttons="true"
        />
    <DxPaging
    
            :page-size="10"
            :page-index="1" /> 
    <DxFilterRow/>
      <DxHeaderFilter
        :visible="showHeaderFilter"
      />
      <DxSearchPanel
        :visible="true"
        :width="240"
        placeholder="Search..."
      />

      <DxGroupPanel :visible="true"/>
      <DxGrouping :auto-expand-all="true"/>
      <DxExport
        :enabled="true"
        :allow-export-selected-data="false"
      />
      <DxSelection mode="false"/>
        <DxColumn data-field="nu_operaion" caption="Operación">
          <DxHeaderFilter :allow-search="true"/>
        </DxColumn>
        <DxColumn data-field="nu_auth" caption="Autorización"/>
        <DxColumn data-field="nu_afiliacion" caption="Afiliación"/>

        
        <DxColumn data-field="cc_nombre" caption="Nombre"/>
        <DxColumn data-field="cc_tp"  caption="Tipo Tarjeta"/>
        <DxColumn data-field="cd_instrumento" caption="Tipo Pago"/>
        <DxColumn data-field="cc_num"  caption="4 digitos"/>
        <DxColumn dataField="Repeticiones" caption="Multiples Movimientos" :cellTemplate="StatusCellTemplate" />
        <!-- <DxColumn data-field="Repeticiones"  caption="Repeticiones"/> -->
        <!-- <DxColumn data-field="MultiplesTransacciones"  caption="Multiple"/> -->
        
        <DxColumn data-field="nu_importe" caption="Importe" dataType="number">
          <DxFormat
              type="currency"
              :precision="2"
              />
        </DxColumn>
        
        
        <DxColumn data-field="fh_registro" caption="Fecha registro"  width="150"/>
        <DxColumn data-field="cd_usrtransaccion" caption="Usr GetNet"  width="250"/>
        <DxColumn data-field="usuario" caption="Usuario"/>
        <DxColumn data-field="transaccion" caption="Transacción"/>
        <DxColumn data-field="nb_resp"  caption="Respuesta"/>
        <DxColumn data-field="nb_referencia" caption="Referencia" />
        <DxColumn data-field="nu_sucursal" caption="Sucursal"/>

        <!--  -->

        <DxSummary>
            <DxGroupItem
                column="cd_instrumento"
                summary-type="count"
            />
            <DxGroupItem
                column="importeReal"
                summary-type="sum"
                value-format="$#,##0.##"
                displayFormat='Total: {0}'
            />
            <DxGroupItem
                column="importeReal"
                summary-type="max"
            />
        </DxSummary>
    </DxDataGrid>


		<!-- bottom left vue-notification -->
		<notifications group="top-right" position="top right" :speed="1000" />
	</div>
</template>

<script>

import axios from 'axios';
import moment from 'moment';
import { DxDataGrid, DxFormat,
  DxPaging, DxPager, DxScrolling, DxColumn, DxExport, DxSelection, DxGroupPanel, DxSummary, DxGroupItem, DxGrouping, DxHeaderFilter,DxFilterRow,DxSearchPanel, } from 'devextreme-vue/data-grid';
import { Workbook } from 'exceljs';
import { saveAs } from 'file-saver';
// Our demo infrastructure requires us to use 'file-saver-es'. We recommend that you use the official 'file-saver' package in your applications.
import { exportDataGrid } from 'devextreme/excel_exporter';
//import service from './data.js';


function setCountOfRepeatedField(arr, elementKey) {
  const elementOccurrences = {};

  // Iterate through the array and count occurrences of each element within the same item
  arr.forEach(obj => {
    const element = obj[elementKey];
    if (elementOccurrences[element]) {
      elementOccurrences[element]++;
    } else {
      elementOccurrences[element] = 1;
    }
    obj.countOfRepetitions = elementOccurrences[element];
  });
}
function compareDates(a, b) {
    const dateA = new Date(a.fh_registro);
    const dateB = new Date(b.fh_registro);
    return dateA - dateB;
}
// const elementKeyToCheck = 'cc_num';
const elementKeyToCheck = 'Criteriosrepetido';



export default {
	beforeCreate() {
    // var userSetting = localStorage.getItem('userSettings');
    // if(userSetting != undefined){
    //   console.log(JSON.parse(userSetting));
    //   this.pageUserSettings = JSON.parse(userSetting);
    // }else{
		// 	this.$router.push('/login');
    // }
	},
	created() {
		// console.log('created');
	},
	beforeMount() {
		// console.log('beforeMount');
	},
	mounted() {
    debugger; // eslint-disable-line no-debugger
		var userSetting = JSON.parse(localStorage.getItem('userSettings'));
		this.usuario = userSetting.Usuario;
		this.idUsuario = userSetting.NumeroEmpleado;
		this.nombreLogueado = userSetting.Nombre;
		this.usuarioEstacion = userSetting.Estacion;
		this.IdRol = userSetting.IdRol;
    this.getCiudades();
    this.ciudadSelect = userSetting.CiudadId;
    this.getEstaciones();
    this.estacionSelect = userSetting.Estacion;

    if(this.IdRol == 3 || this.IdRol == 7){
      this.deshabilitarCombos = true;
    }
	},
	components: {
		DxDataGrid, DxFormat,
  DxPaging,DxPager, DxScrolling, DxColumn, DxExport, DxSelection, DxGroupPanel, DxGrouping, DxSummary, DxGroupItem, 
  DxHeaderFilter,DxFilterRow,DxSearchPanel,
	},
  methods: {
    RepeticionesCellTemplate(cellElement, cellInfo) {
		debugger; // eslint-disable-line no-debugger
      // Custom cell template to show a template if "Repeticiones" is greater than 1
      const Repeticiones = cellInfo.data.Repeticiones;
      console.log(Repeticiones)
      if (Repeticiones > 1) {
        cellElement.innerHTML = `
          <div>
            <span>Repetitions: ${Repeticiones}</span>
            <button @click="handleButtonClick(cellInfo)">Click Me</button>
          </div>
        `;
      } else {
        cellElement.textContent = Repeticiones;
      }
    },
    handleButtonClick(cellInfo) {
      // Handle button click event here, e.g., show a modal or perform some action
      alert(`Button clicked for ID: ${cellInfo.data.id}`);
    },
    StatusCellTemplate(cellElement, cellInfo) {
		debugger; // eslint-disable-line no-debugger
      // Custom cell template to show different icons based on the "status" value
      const repeticiones = cellInfo.data.Repeticiones;
      const iconSrc = repeticiones > 1 ? '/assets/img/user/user-13.jpg' : 'https://www.pngfind.com/pngs/m/639-6399088_img-success-svg-png-icon-free-download-download.png';
      const repeticionesString = repeticiones>1? repeticiones.toString():"";
      if(repeticiones == 1){
        cellElement.innerHTML = `<span>${repeticiones}</span><i class="fas fa-lg fa-fw me-10px fa-exclamation-triangle"  style="color: darkseagreen;"></i>`;
      }else if(repeticiones == 2){
        cellElement.innerHTML = `<span>${repeticionesString}</span><i class="fas fa-lg fa-fw me-10px fa-exclamation-triangle"  style="color: orange;"></i>`;
      }else if(repeticiones > 2){
        cellElement.innerHTML = `<span>${repeticionesString}</span><i class="fas fa-lg fa-fw me-10px fa-exclamation-triangle" style="color: #ff3631;"></i>`;
      }
      
      
      
    },
	async getCiudades(){
		try{
        const response = await axios.get('Catalogos/GetCiudades');
		//debugger; // eslint-disable-line no-debugger
        if(response.data.Success){
          if(response.data.response.length == 0){
            this.alertMessage = "No se encontró información.";
            this.alertTitle = "Error";
            this.show('top-right', 'error');
          }
        }else{
            console.log("error")
            this.alertMessage = response.data.Error;
            this.alertTitle = "Error";
            this.show('top-right', 'error');
        }
        this.ciudades = response.data.response;
      }catch(e){
        //this.alert = true;
        //this.error = 'Invalid email/password';
      }
	},
	async getEstaciones(){
		try{
		debugger; // eslint-disable-line no-debugger
        const response = await axios.post('Estacion/GetEstaciones?ciudad='+this.ciudadSelect);
        if(response.data.Success){
          if(response.data.response.length == 0){
            this.alertMessage = "No se encontró información.";
            this.alertTitle = "Error";
            this.show('top-right', 'error');
          }
        }else{
            console.log("error")
            this.alertMessage = response.data.Error;
            this.alertTitle = "Error";
            this.show('top-right', 'error');
        }
        this.estaciones = response.data.response;
        this.productos = [];
        this.getProductos();
      }catch(e){
        //this.alert = true;
        //this.error = 'Invalid email/password';
      }
	},
			show (group, type = '') {
				const text = this.alertMessage;
				this.$notify({
					group,
					title: this.alertTitle,
					text,
					type,
					data: {
						randomNumber: Math.random()
					}
				})
			},
			clean (group) {
				this.$notify({ group, clean: true })
			},
			swalNotification(swalType) {
				var btnClass = (swalType == 'error') ? 'danger' : swalType;
					btnClass = (swalType == 'question') ? 'primary' : btnClass;
				this.$swal({
					title: 'Are you sure?',
					text: 'You will not be able to recover this imaginary file!',
					type: swalType,
					showCancelButton: true,
					buttonsStyling: false,
					confirmButtonText: swalType.charAt(0).toUpperCase() + swalType.substr(1).toLowerCase(),
					cancelButtonText: 'Cancel',
					confirmButtonClass: 'btn me-5px btn-'+ btnClass +'',
					cancelButtonClass: 'btn btn-default',
				})
			},
    onExporting(e) {
      const workbook = new Workbook();
      const worksheet = workbook.addWorksheet('ReporteGetNet');

      exportDataGrid({
        component: e.component,
        worksheet: worksheet,
        autoFilterEnabled: true
      }).then(() => {
        workbook.xlsx.writeBuffer().then((buffer) => {
          saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'Centro de pagos.xlsx');
        });
      });
      e.cancel = true;
    },
    async search(e){
		debugger; // eslint-disable-line no-debugger
      e.preventDefault();
      var self = this;
      self.textoBotonBuscar = "Buscando...";
      try{
        self.ReporteGetNetCompleto = [];
        self.ReporteGetNet = [];
        // this.intDate = this.date.replace('/', '');
        // this.intDate = this.intDate.replace('/', '');
        var sendDate = self.date;
        var estacion = self.estacionSelect;
        if(estacion == "0"){
            this.alertMessage = "Seleccione estación";
            this.alertTitle = "Error";
            this.show('top-right', 'error');
          return;
        }
        const response = await axios.post('ePago/GetTransacciones' ,{
                "Data": {
                    "Estacion": estacion,
                    "Fecha": sendDate//   D/M/Y
                }
            }
        );
        
		debugger; // eslint-disable-line no-debugger
        if(response.data.Success){

          if(response.data.response.length == 0){
            this.alertMessage = "No se erncontro información del día especificado.";
            this.alertTitle = "Error";
            this.show('top-right', 'error');
            this.ReporteGetNet = [];
          }else{
            
            self.ReporteGetNetCompleto = response.data.response.filter(item => item.nb_resp === 'Aprobado');

            self.ReporteGetNetCompleto.sort(compareDates);


            /*
                   

                  
            */
            
            for(var r = 0; r  < self.ReporteGetNetCompleto.length ; r++){
              self.ReporteGetNetCompleto[r].Repeticiones = 0;
              self.ReporteGetNetCompleto[r].MultiplesTransacciones = "";
              self.ReporteGetNetCompleto[r].Criteriosrepetido = self.ReporteGetNetCompleto[r].cc_nombre + self.ReporteGetNetCompleto[r].cc_tp + self.ReporteGetNetCompleto[r].cd_instrumento + self.ReporteGetNetCompleto[r].cc_num
              
              
              if(self.ReporteGetNetCompleto[r].nb_resp == 'Aprobado'){
                self.ReporteGetNetCompleto[r].importeReal = self.ReporteGetNetCompleto[r].nu_importe;
              }else{
                self.ReporteGetNetCompleto[r].importeReal = "0.00";
              }


            }

            const elementOccurrences = {};
            self.ReporteGetNetCompleto.forEach(obj => {
              const element = obj[elementKeyToCheck];
              if (elementOccurrences[element]) {
                elementOccurrences[element]++;
                obj.MultiplesTransacciones = "si";
              } else {
                elementOccurrences[element] = 1;
              }
              obj.Repeticiones = elementOccurrences[element];
            });


            var  ReporteGetNetTemporal = self.ReporteGetNetCompleto;
            for(var c = 0 ; c < self.ReporteGetNetCompleto.length ; c++){
                    
                for(var t = 0 ; t < ReporteGetNetTemporal.length ; t++){
                    if( (ReporteGetNetTemporal[t].Criteriosrepetido == self.ReporteGetNetCompleto[c].Criteriosrepetido) && (ReporteGetNetTemporal[t].Repeticiones == 1 && self.ReporteGetNetCompleto[c].MultiplesTransacciones == 'si')){
                        ReporteGetNetTemporal[t].MultiplesTransacciones = 'si';
                    }
                    
                }

            }

            for(var x = 0 ; x < ReporteGetNetTemporal.length ;x++){
                if(ReporteGetNetTemporal[x].MultiplesTransacciones == "si"){
                    self.ReporteGetNet.push(ReporteGetNetTemporal[x]);
                }
            }
            
            if(self.ReporteGetNet.length == 0){
                
              this.alertMessage = "No se encontró transacciones dobles.";
              this.alertTitle = "Error";
              this.show('top-right', 'error');
            }
            // self.ReporteGetNet = self.ReporteGetNetCompleto;
          }
        }else{
            this.ReporteGetNet = [];
            console.log("error")
            this.alertMessage = response.data.Message;
            this.alertTitle = "Error";
            this.show('top-right', 'error');
        }

      this.textoBotonBuscar = "Buscar";
      }catch(e){
            this.ReporteGetNet = [];
            this.alertMessage = "Ocurrió un error";
            this.alertTitle = "Error";
            this.show('top-right', 'error');
        //this.alert = true;
        //this.error = 'Invalid email/password';
      }
    }
  },
	data() {
		return {
        ReporteGetNetCompleto:[],
      showHeaderFilter: true,
      loading:false,
      nombreLogueado:"",
      idUsuario:undefined,
      reportefajillas:[],
      deshabilitarCombos:false,
      ciudades: [],
      estaciones: [],
      ciudadSelect: 1,
      estacionSelect: 0,

      pageUserSettings: {},
      alertMessage: "",
      alertTitle: "",
      ReporteGetNet: [],
      textoBotonBuscar: "Buscar",
      date: moment().format("DD/MM/YYYY"),
      intDate: "",
      options: {
        format: 'DD/MM/YYYY',
        useCurrent: false,
      }
		};
	}
}
</script>
<style scoped>
.dx-datagrid-header-panel{
  display: none !important;
}
/* #gridContainer {
  height: 440px;
} */
</style>