<template>
	<div>
    
    <div class=" loading-search" v-if="loading">
      <div class="fa-3x text-center">
        <i class="fas fa-circle-notch fa-spin text-success" style=""></i>
      </div>
    </div>
		<!-- BEGIN breadcrumb -->
		<ol class="breadcrumb float-xl-end">
			<!-- <li class="breadcrumb-item"><a href="javascript:;">Facturación</a></li> -->
			<li class="breadcrumb-item"><a href="javascript:;">Reportes</a></li>
			<li class="breadcrumb-item active"><a href="javascript:;">Corresponsalia</a></li>
		</ol>
		<!-- END breadcrumb -->
		<!-- BEGIN page-header -->
		<h1 class="page-header">Reporte Corresponsalia<small></small></h1>
    <!-- <button class="btn" v-on:click="redirectTanques()">tanques</button> -->
		<!-- END page-header -->
		<!-- <a href="javascript:;" v-on:click="show('top-right', 'warn')" class="btn btn-sm btn-primary">Demo</a> -->
		
        

          <div class="row">
            <form @submit.prevent="search">   
              <div class="col-md-12" v-if="radioBusqueda == 'filtros'"> 
                  <div class="form-group row">
                    
                    <div class="col-md-2">
                        <label class="col-form-label">Fecha inicial</label>
                      <div class="input-group">
                        <date-picker id="dateTimeInput" v-model="date" :config="options"></date-picker>
                        <label for="dateTimeInput" class="input-group-text">
                          <i class="fa fa-calendar"></i>
                        </label>
                      </div>
                    </div>

                    
                    <div class="col-md-2">
                        <label class="col-form-label">Fecha final</label>
                      <div class="input-group">
                        <date-picker id="dateTimeInputEnd" v-model="dateEnd" :config="options"></date-picker>
                        <label for="dateTimeInputEnd" class="input-group-text">
                          <i class="fa fa-calendar"></i>
                        </label>
                      </div>
                    </div>

                    <div class="col-md-2">
                      <button type="submit" class="btn btn-success me-1 mb-1" style="margin-top: 3.1em;">{{textoBotonBuscar}}</button>
                    </div>
                  </div>
              </div>
        </form>
      </div>

        <div style="margin-top: 15px;">
          <panel title="Reporte" bodyClass="p-0" class="">
            <vue-good-table
              :columns="columns"
              :rows="facturas"
              :lineNumbers="false"
              :search-options="{ enabled: true, placeholder: 'Buscar' }"
              :pagination-options="{ enabled: true, position: 'bottom' }"
            >
              <template slot="table-row" slot-scope="props">
                
                <span v-if="props.column.label == 'Acciones'">
                  <button class="btn btn-xs btn-success" @click="getTickets(props.row)">Descargar</button>
                  &nbsp;
                  <button class="btn btn-xs btn-success" @click="seleccionarRow(props.row)" v-b-modal.modalDialog>TXT</button>
                </span>

                <!-- <span v-else-if="props.column.label == 'Descarga'">
                  <div class="form-check form-switch mb-2">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      id="flexSwitchCheckChecked"
                      v-model="props.row.Seleccionado"
                      @click="contadorSeleccionados(props.row)"
                      :disabled="(disableSeleccionar && !props.row.Seleccionado) && !permitirSeleccionarTodas"
                    />
                  </div>
                </span> -->
                
              </template>
            </vue-good-table>
          </panel>
        </div>


    <!-- #modal-dialog -->
    <b-modal id="modalDialog"  size="xl">
      <template slot="modal-header">
        <h4 class="modal-title">Generar TXT Bancario</h4>
        <a class="btn-close" @click="$bvModal.hide('modalDialog')"></a>
      </template>

      <div>
        <div class="row"> 
              <div class="col-md-12"> 
                  <div class="form-group row">
                    
                    <div class="col-md-2">
                        <label class="col-form-label">Fecha</label>
                      <div class="input-group">
                        <date-picker id="dateTimeInputModal" v-model="dateModal" :config="options"></date-picker>
                        <label for="dateTimeInputModal" class="input-group-text">
                          <i class="fa fa-calendar"></i>
                        </label>
                      </div>
                    </div>

                    <div class="col-md-2">
                      <button type="button" @click="getTransaccionesTXT()" class="btn btn-success me-1 mb-1" style="margin-top: 3.1em;">{{textoBotonBuscar}}</button>
                    </div>
                  </div>
              </div>

              
      </div>

        <!-- reporte modal -->
        <vue-good-table
              :columns="columnsModal"
              :rows="reporteModal"
              :lineNumbers="false"
              :search-options="{ enabled: true, placeholder: 'Buscar' }"
              :pagination-options="{ enabled: true, position: 'bottom' }"
            >
              <template slot="table-row" slot-scope="props">
                
                <span v-if="props.column.label == 'Acciones'">
                  
                  
                </span>
              </template>
            </vue-good-table>
      </div>

      <template slot="modal-footer">
        <button
          :disabled="respuestaTxt == null"
          type="button"
          class="btn btn-primary mr-auto"
          @click="descargarTXT()"
        >
        Descargar
        </button>
        <button
          type="button"
          class="btn btn-secondary"
          @click="$bvModal.hide('modalDialog')"
        >
          Cancelar
        </button>
      </template>
    </b-modal>

		<!-- bottom left vue-notification -->
		<notifications group="top-right" position="top right" :speed="1000" />
	</div>
</template>

<script>

import axios from 'axios';
import moment from 'moment';

export default {
  mounted: function () {
    debugger; // eslint-disable-line no-debugger
    var userSetting = JSON.parse(localStorage.getItem('userSettings'));
    this.usuario = userSetting.Usuario;
    this.usuarioEstacion = userSetting.Estacion;
    this.IdRol = userSetting.IdRol;
    this.getCiudades();
    this.ciudadSelect = userSetting.CiudadId;
    this.getEstaciones();
    this.estacionSelect = userSetting.Estacion;

    if(this.IdRol == 3 || this.IdRol == 7){
        this.deshabilitarCombos = true;
    }


    

  },
	beforeCreate() {
	},
	created() {
		// console.log('created');
	},
	beforeMount() {
		// console.log('beforeMount');
	},
	components: {
        
	},
  methods: {
    descargaUnitaria(factura){
      var self = this;
      debugger; // eslint-disable-line no-debugger
      var selectedDate = moment(factura.FechaFactura).format('YYYYMMDD');
      var today = moment();
      var diferenciaDecimal = today.diff(selectedDate, 'months', true);

      if(diferenciaDecimal < 3){
        self.descargaUnitariaReciente(factura)
      }else{
        var filesArray = [factura.XmlPath];
        self.descargarAntiguas(filesArray);
      }

    },
    
    async descargarAntiguas(filesArray){
      debugger; // eslint-disable-line no-debugger
      var self = this;
      self.loading = true;
      self.descargarFacturasBtnTxt = "Descargando...";
      const bodyData = {
        "Data": {
            "Usuario": self.usuario,        
            "Files": filesArray        
          }
      };
      try {
        const response = await axios.post("BlobStorage/GetZipFiles", bodyData);
        if (response.data.Success) {
          if (response.data.response.length == 0) {
            this.alertMessage =
              "No se encontró información";
            this.alertTitle = "Error";
            this.show("top-right", "error");
          } else {

            debugger; // eslint-disable-line no-debugger
            
            self.descargarZip(response.data.response.zip);
            
          }
        } else {
          this.alertMessage = response.data.Error;
          this.alertTitle = "Error";
          this.show("top-right", "error");
          self.loading = false;
          self.descargarFacturasBtnTxt = "Descargar Tickets";
        }
        
      } catch (e) {
        this.alertMessage = "Ocurrio un error";
        this.alertTitle = "Error";
        this.show("top-right", "error");
          self.loading = false;
          self.descargarFacturasBtnTxt = "Descargar Tickets";
      }
    },
    async descargaUnitariaReciente(factura) {
      var self = this;
      self.loading = true;
      self.descargarFacturasBtnTxt = "Descargando...";
      try {
        const response = await axios.post("CFDI/GetArchivosCFDI?UUID="+factura.UUID, {});
        if (response.data.Success) {
          if (response.data.response.length == 0) {
            this.alertMessage =
              "No se encontró información";
            this.alertTitle = "Error";
            this.show("top-right", "error");
          } else {

            debugger; // eslint-disable-line no-debugger
            self.descargarXML(response.data.response.XML);
            self.descargarPDF(response.data.response.PDF);
            self.loading = false;
            self.descargarFacturasBtnTxt = "Descargar Tickets";
          }
        } else {
          this.alertMessage = response.data.Error;
          this.alertTitle = "Error";
          this.show("top-right", "error");
          self.loading = false;
          self.descargarFacturasBtnTxt = "Descargar Tickets";
        }
        
      } catch (e) {
        this.alertMessage = "Ocurrio un error";
        this.alertTitle = "Error";
        this.show("top-right", "error");
        self.loading = false;
        self.descargarFacturasBtnTxt = "Descargar Tickets";
      }
    },
    descargarXML(xmlString){
            debugger; // eslint-disable-line no-debugger

          var xmltext = xmlString;

          var filename = "Factura_Electronica.xml";
          var pom = document.createElement('a');
          var bb = new Blob([xmltext], {type: 'text/plain'});

          pom.setAttribute('href', window.URL.createObjectURL(bb));
          pom.setAttribute('download', filename);

          pom.dataset.downloadurl = ['text/plain', pom.download, pom.href].join(':');
          pom.draggable = true; 
          pom.classList.add('dragout');

          pom.click();
          //document.body.removeChild(pom);
      },
      mostrarAlerta(val){
        alert(val);
      },
      descargarPDF(pdfBase64){
            debugger; // eslint-disable-line no-debugger

            var filename = "Factura_Electronica.pdf";
            // The actual download
            var link = document.createElement('a');
            //link.href = window.URL.createObjectURL(blob);
            link.href = "data:application/pdf;base64,"+pdfBase64;
            link.download = filename;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
      },
    contadorSeleccionados(row){
      var val = row.Seleccionado;

    debugger; // eslint-disable-line no-debugger
      var self = this
      self.facturas[row.originalIndex].Seleccionado = !val;
      if(!val){
        self.contador++;
      }else{
        self.contador--;
      }
      

      if(self.contador > 9){
        self.disableSeleccionar = true;
      }else{
        self.disableSeleccionar = false;
      }
    },
    limpiarCampoCliente(){
      var self = this;
      
          setTimeout(function(){
            self.clientes = [];
            self.cliente.RFC = "";
            self.cliente.Nombre = "";
          },0);

    },
    descargaMultiple(){
      debugger; // eslint-disable-line no-debugger
      var self = this;
      var selectedDate = moment(self.date).format('YYYYMMDD');
      var today = moment();
      var diferenciaDecimal = today.diff(selectedDate, 'months', true);


      if(diferenciaDecimal < 3){
        var UUIDsArray = [];
        for(var n = 0 ; n < self.facturas.length ; n++){
          if(self.facturas[n].Seleccionado){
            UUIDsArray.push(self.facturas[n].UUID);
          }
        }
        self.descargaMultipleReciente(UUIDsArray);
      }else{
        
        var filesArray = [];
        for(var i = 0 ; i < self.facturas.length ; i++){
          if(self.facturas[i].Seleccionado){
            filesArray.push(self.facturas[i].XmlPath);
          }
        }
        self.descargarAntiguas(filesArray);
      }
    },
    async descargaMultipleReciente(UUIDsArray){
      debugger; // eslint-disable-line no-debugger
      var self = this;
      self.loading = true;
      self.descargarFacturasBtnTxt = "Descargando...";
      const bodyData = {
        "Data": {
            "Usuario": self.usuario,        
            "Files": UUIDsArray        
          }
      };
      try {
        const response = await axios.post("CFDI/GetZipFiles", bodyData);
        if (response.data.Success) {
          if (response.data.response.length == 0) {
            this.alertMessage =
              "No se encontró información";
            this.alertTitle = "Error";
            this.show("top-right", "error");
          } else {

            debugger; // eslint-disable-line no-debugger
            if(response.data.response.CfdiNoFound.length > 0){
              this.alertMessage = "No se encontraron algunas facturas: "+response.data.response.CfdiNoFound;
              this.alertTitle = "Error";
              this.show("top-right", "info");
            }
            self.descargarZip(response.data.response.zip);
            // self.descargarXML(response.data.response.XML);
            // self.descargarPDF(response.data.response.PDF);
          }
        } else {
          this.alertMessage = response.data.Error;
          this.alertTitle = "Error";
          this.show("top-right", "error");
          self.loading = false;
          self.descargarFacturasBtnTxt = "Descargar Tickets";
        }
        
      } catch (e) {
        this.alertMessage = "Ocurrio un error";
        this.alertTitle = "Error";
        this.show("top-right", "error");
          self.loading = false;
          self.descargarFacturasBtnTxt = "Descargar Tickets";
      }
    },
    seleccionarRow(row){
      debugger; // eslint-disable-line no-debugger
      var self = this;
      self.selectedRow = row;
    },
    async getTransaccionesTXT(){
      debugger; // eslint-disable-line no-debugger
      var self = this;
      self.loading = true;
      // self.descargarFacturasBtnTxt = "Descargando...";
      self.intDateModal = self.dateModal.replace('/', '');
      self.intDateModal = self.intDateModal.replace('/', '');
      const bodyData = {
        "Data": {
            "Fecha" : self.intDate,
            "FechaDeposito" : self.intDateModal,
            "Estacion" : self.selectedRow.Estacion,
            "Id":self.selectedRow.Id,
          }
      };
      try {
        
        const response = await axios.post("Corresponsalia/GetTransaccionesTXT", bodyData);
        if (response.data.Success) {
            debugger; // eslint-disable-line no-debugger
            
            self.loading = false;
            self.reporteModal = response.data.response;

            self.respuestaTxt = response.data;
          
            
        } else {
          this.alertMessage = response.data.Error;
          this.alertTitle = "Error";
          this.show("top-right", "error");
          self.loading = false;
          self.reporteModal = [];
          self.respuestaTxt = null;
          // self.descargarFacturasBtnTxt = "Descargar Tickets";
        }
        
      } catch (e) {
        this.alertMessage = "Ocurrio un error";
        this.alertTitle = "Error";
        this.show("top-right", "error");
        self.loading = false;
        self.reporteModal = [];
        self.respuestaTxt = null;
          // self.descargarFacturasBtnTxt = "Descargar Tickets";
      }
    },
    async getTickets(row){
      debugger; // eslint-disable-line no-debugger
      var self = this;
      self.loading = true;
      self.descargarFacturasBtnTxt = "Descargando...";
      const bodyData = {
        "Data": {
            "Estacion" : row.Estacion,
            "Id":row.Id,
            "Usuario" : row.Usuario
          }
      };
      try {
        
        const response = await axios.post("Corresponsalia/GetTickets", bodyData);
        if (response.data.Success) {
            debugger; // eslint-disable-line no-debugger
            
            self.descargarZip(response.data.response);
          
            
        } else {
          this.alertMessage = response.data.Error;
          this.alertTitle = "Error";
          this.show("top-right", "error");
          self.loading = false;
          self.descargarFacturasBtnTxt = "Descargar Tickets";
        }
        
      } catch (e) {
        this.alertMessage = "Ocurrio un error";
        this.alertTitle = "Error";
        this.show("top-right", "error");
          self.loading = false;
          self.descargarFacturasBtnTxt = "Descargar Tickets";
      }
    },
    
    async descargarTXT(){
      debugger; // eslint-disable-line no-debugger
      var self = this;
      self.loading = true;
      // self.descargarFacturasBtnTxt = "Descargando...";
      const bodyData = {
        "Data": {
          "Usuario": self.usuario,
          "Estacion": self.selectedRow.Estacion,
          "Transacciones": self.reporteModal
        }
        
      };
      try {
        
        const response = await axios.post("Corresponsalia/GetTXT", bodyData);
        if (response.data.Success) {
            debugger; // eslint-disable-line no-debugger
            
            self.descargarTXT64(response.data.response);
          
            
        } else {
          this.alertMessage = response.data.Error;
          this.alertTitle = "Error";
          this.show("top-right", "error");
          self.loading = false;
          // self.descargarFacturasBtnTxt = "Descargar Tickets";
        }
        
      } catch (e) {
        this.alertMessage = "Ocurrio un error";
        this.alertTitle = "Error";
        this.show("top-right", "error");
          self.loading = false;
          // self.descargarFacturasBtnTxt = "Descargar Tickets";
      }
    },
    descargarTXT64(base64){
      var self = this;
      

      var filename = self.selectedRow.Estacion+" "+self.intDate+".txt";//nombreArchivo;
      var link = document.createElement("a");
      link.href = "data:application/txt;base64," + base64;
      link.download = filename;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      self.loading = false;
      // self.descargarFacturasBtnTxt = "Descargar Tickets";
    },
    descargarZip(base64Zip){
      var self = this;
      

      var filename = "tickets.zip";//nombreArchivo;
      var link = document.createElement("a");
      link.href = "data:application/zip;base64," + base64Zip;
      link.download = filename;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      self.loading = false;
      self.descargarFacturasBtnTxt = "Descargar Tickets";
    },
    async seleccionarCliente(cliente) {
      var self = this;
        try {
          const response = await axios.post("Cliente/GetCliente", {
            Data: {
              RFC: cliente.RFC,
              Id: 0,
              Tarjeta: "",
            },
          });
          if (response.data.Success) {
            if (response.data.response == null) {
              this.alertMessage =
                "No se encontró información del cliente especificado.";
              this.alertTitle = "Error";
              this.show("top-right", "error");
            } else {
              
              debugger; // eslint-disable-line no-debugger
              self.cliente = response.data.response;
              self.clientes = [];
              self.clienteBuscar = "";
              if(response.data.response.Correos.length>0){
                self.clienteCorreo = response.data.response.Correos[0].Direccion;
              }
              return;
            }
          } else {
            this.alertMessage = response.data.Error;
            this.alertTitle = "Error";
            this.show("top-right", "error");
          }
        } catch (e) {
          this.alertMessage = "Ocurrio un error";
          this.alertTitle = "Error";
          this.show("top-right", "error");
        }
    },
    async getClientes() {
      this.textoBotonBuscar = "Buscando Facturas...";
      try {
        const response = await axios.post("Cliente/GetClientes", {
          Data: {
            Busqueda: this.clienteBuscar,
            IncluirCancelados: 1,
          },
        });
        if (response.data.Success) {
          if (response.data.response.length == 0) {
            this.alertMessage =
              "No se encontró información del cliente especificado.";
            this.alertTitle = "Error";
            this.show("top-right", "error");
          } else {
            this.clientes = response.data.response;
          }
        } else {
          this.alertMessage = response.data.Error;
          this.alertTitle = "Error";
          this.show("top-right", "error");
        }
        this.textoBotonBuscar = "Buscar";
      } catch (e) {
        this.alertMessage = "Ocurrio un error";
        this.alertTitle = "Error";
        this.show("top-right", "error");
      }
    },

	async getCiudades(){
		try{
        const response = await axios.get('Catalogos/GetCiudades');
		//debugger; // eslint-disable-line no-debugger
        if(response.data.Success){
          if(response.data.response.length == 0){
            this.alertMessage = "No se encontró información.";
            this.alertTitle = "Error";
            this.show('top-right', 'error');
          }
        }else{
            console.log("error")
            this.alertMessage = response.data.Error;
            this.alertTitle = "Error";
            this.show('top-right', 'error');
        }
        this.ciudades = response.data.response;
      }catch(e){
        //this.alert = true;
        //this.error = 'Invalid email/password';
      }
	},
	async getEstaciones(){
		try{
		debugger; // eslint-disable-line no-debugger
        const response = await axios.post('Estacion/GetEstaciones?ciudad='+this.ciudadSelect);
        if(response.data.Success){
          if(response.data.response.length == 0){
            this.alertMessage = "No se encontró información.";
            this.alertTitle = "Error";
            this.show('top-right', 'error');
          }
        }else{
            console.log("error")
            this.alertMessage = response.data.Error;
            this.alertTitle = "Error";
            this.show('top-right', 'error');
        }
        this.estaciones = response.data.response;
        this.productos = [];
        this.getProductos();
      }catch(e){
        //this.alert = true;
        //this.error = 'Invalid email/password';
      }
	},
    redirectTanques(){
      
          this.$router.push({ path: '/tanques' });
    },
			show (group, type = '') {
				const text = this.alertMessage;
				this.$notify({
					group,
					title: this.alertTitle,
					text,
					type,
					data: {
						randomNumber: Math.random()
					}
				})
			},
			clean (group) {
				this.$notify({ group, clean: true })
			},
			swalNotification(swalType) {
				var btnClass = (swalType == 'error') ? 'danger' : swalType;
					btnClass = (swalType == 'question') ? 'primary' : btnClass;
				this.$swal({
					title: 'Are you sure?',
					text: 'You will not be able to recover this imaginary file!',
					type: swalType,
					showCancelButton: true,
					buttonsStyling: false,
					confirmButtonText: swalType.charAt(0).toUpperCase() + swalType.substr(1).toLowerCase(),
					cancelButtonText: 'Cancel',
					confirmButtonClass: 'btn me-5px btn-'+ btnClass +'',
					cancelButtonClass: 'btn btn-default',
				})
			},
      async buscarPorTicket(){
        var self = this;
        // e.preventDefault();
      
  
      
      self.textoBotonBuscar = "Buscando Facturas...";
      self.loading = true;
      try{

        
        debugger; // eslint-disable-line no-debugger
        //alert(self.estatusFacturas);
        const response = await axios.post('CFDI/GetFacturasTicket' ,{
        "Data": {
            "Ticket": self.ticketBuscar,
            }
        });
        if(response.data.Success){
          if(response.data.response.length == 0){
            self.alertMessage = "No se encontro información del día especificado.";
            self.alertTitle = "Error";
            self.show('top-right', 'error');
          }
        }else{
            console.log("error")
            self.alertMessage = response.data.Error;
            self.alertTitle = "Error";
            self.show('top-right', 'error');
        }

        self.contador=0;
        self.disableSeleccionar = false;
        for(var n=0 ; n < response.data.response.length ; n++){
          response.data.response[n].Seleccionado = false;
          response.data.response[n].FechaVentaString = moment(response.data.response[n].FechaVenta).format("DD/MM/YYYY h:mm a");
          response.data.response[n].FechaFacturaString = moment(response.data.response[n].FechaFactura).format("DD/MM/YYYY h:mm a");
        }
        
        self.facturas = response.data.response;


      self.textoBotonBuscar = "Buscar";
      
      self.loading = false;
      }catch(e){
      self.loading = false;
        //this.alert = true;
        //this.error = 'Invalid email/password';
      }
    },
    async search(){
        var self = this;
      // e.preventDefault();
      
      if(moment(self.date).format('MM') != moment(self.dateEnd).format('MM')){

            self.alertMessage = "Las fechas deben corresponder al mismo mes."
            self.alertTitle = "Error";
            self.show('top-right', 'error');
            return;
      }
      self.textoBotonBuscar = "Buscando...";
      self.loading = true;
      try{

        self.intDate = self.date.replace('/', '');
        self.intDate = self.intDate.replace('/', '');


        self.intDateEnd = self.dateEnd.replace('/', '');
        self.intDateEnd = self.intDateEnd.replace('/', '');

        
        // var estacion = self.estacionSelect!=undefined?self.estacionSelect:0;
        // if(self.date != self.dateEnd || estacion == 0){
        //   self.permitirSeleccionarTodas = false;
        //   self.seleccionarTodas = false;
        // }else{
        //   self.permitirSeleccionarTodas = true;
        //   self.seleccionarTodas = false;
        // }
        debugger; // eslint-disable-line no-debugger
        
        const response = await axios.post('Corresponsalia/GetCorreponsalia' ,{
        "Data": {
            "FechaInicial": self.intDate,
            "FechaFin":self.intDateEnd,
            // "Estacion": estacion
            }
        });
        self.textoBotonBuscar = "Buscar";
        self.loading = false;
        self.facturas = [];
        if(response.data.Success){
          if(response.data.response.length == 0){
            self.alertMessage = "No se encontro información del día especificado.";
            self.alertTitle = "Error";
            self.show('top-right', 'error');
          }
        }else{
            console.log("error")
            self.alertMessage = response.data.Error;
            self.alertTitle = "Error";
            self.show('top-right', 'error');
        }

        // self.contador=0;
        // self.disableSeleccionar = false;
        // for(var n=0 ; n < response.data.response.length ; n++){
        //   response.data.response[n].Seleccionado = false;
        //   response.data.response[n].FechaVentaString = moment(response.data.response[n].FechaVenta).format("DD/MM/YYYY h:mm a");
        //   response.data.response[n].FechaFacturaString = moment(response.data.response[n].FechaFactura).format("DD/MM/YYYY h:mm a");
        // }
        
        self.facturas = response.data.response;


      }catch(e){
        self.textoBotonBuscar = "Buscar";
        self.loading = false;
        self.facturas = [];
        
        self.alertMessage = "Error";
        self.alertTitle = "";
        self.show('top-right', 'error');
        //this.alert = true;
        //this.error = 'Invalid email/password';
      }
    }
  },
  watch:{
    
  },
	data() {
		return {
      respuestaTxt: null,
      selectedRow:{},
      ticketBuscar:'',
      radioBusqueda: 'filtros',
      estatusFacturas: 0,
      descargarFacturasBtnTxt:'Descargar Tickets',
      contador: 0,
      permitirSeleccionarTodas: true,
      seleccionarTodas: false,
      base64Zip: "",
      clienteBuscar:"",
      clientes: [],
      cliente:{
        RFC:''
      },

      loading:false,
      showHeaderFilter: true,
      deshabilitarCombos:false,
      ciudades: [],
      estaciones: [],
      ciudadSelect: 1,
      estacionSelect: 0,
      // date: moment().startOf('month').format("YYYY/MM/DD"),
      // intDateEnd: "",
      // dateEnd: moment().format("YYYY/MM/DD"),
      
      date: "2016/09/01",//,moment().startOf('month').format("YYYY/MM/DD"),
      dateModal: "2016/09/01",//,moment().startOf('month').format("YYYY/MM/DD"),
      intDateEnd: "",
      dateEnd: "2016/09/30",//moment().format("YYYY/MM/DD"),
      intDate: "",
      options: {
          format: 'YYYY/MM/DD',
          useCurrent: false,
      },
      pageUserSettings: {},
      alertMessage: "",
      alertTitle: "",
      textoBotonBuscar: "Buscar",
      reporteModal:[],
      columnsModal: [
      {
          label: "FechaTransaccion",
          field: "FechaTransaccion",
          tdClass: "text-center text-nowrap",
          thClass: "text-center text-nowrap",
        },
        {
          label: "FechaValor",
          field: "FechaValor",
          tdClass: "text-center text-nowrap",
          thClass: "text-center text-nowrap",
        },
        {
          label: "InstitucionFinanciera",
          field: "InstitucionFinanciera",
          tdClass: "text-center text-nowrap",
          thClass: "text-center text-nowrap",
        },
        {
          label: "RfcCorresponsal",
          field: "RfcCorresponsal",
          tdClass: "text-center text-nowrap",
          thClass: "text-center text-nowrap",
        },
        {
          label: "ActividadEconomica",
          field: "ActividadEconomica",
          tdClass: "text-center text-nowrap",
          thClass: "text-center text-nowrap",
        },
        {
          label: "TipoCorresponsal",
          field: "TipoCorresponsal",
          tdClass: "text-center text-nowrap",
          thClass: "text-center text-nowrap",
        },
        {
          label: "ClaveMunicipio",
          field: "ClaveMunicipio",
          tdClass: "text-center text-nowrap",
          thClass: "text-center text-nowrap",
        },
        {
          label: "MontoOperacion",
          field: "MontoOperacion",
          tdClass: "text-center text-nowrap",
          thClass: "text-center text-nowrap",
        },
        {
          label: "TipoPersona",
          field: "TipoPersona",
          tdClass: "text-center text-nowrap",
          thClass: "text-center text-nowrap",
        },
        {
          label: "NombrePersona",
          field: "NombrePersona",
          tdClass: "text-center text-nowrap",
          thClass: "text-center text-nowrap",
        },
        {
          label: "NumeroPasaporte",
          field: "NumeroPasaporte",
          tdClass: "text-center text-nowrap",
          thClass: "text-center text-nowrap",
        },
        {
          label: "NacionalidadPasaporte",
          field: "NacionalidadPasaporte",
          tdClass: "text-center text-nowrap",
          thClass: "text-center text-nowrap",
        },
        {
          label: "NombreCajero",
          field: "NombreCajero",
          tdClass: "text-center text-nowrap",
          thClass: "text-center text-nowrap",
        },
        {
          label: "RegistroVenta",
          field: "RegistroVenta",
          tdClass: "text-center text-nowrap",
          thClass: "text-center text-nowrap",
        },
        {
          label: "NumeroHabitacion",
          field: "NumeroHabitacion",
          tdClass: "text-center text-nowrap",
          thClass: "text-center text-nowrap",
        },
        {
          label: "Aerolinea",
          field: "Aerolinea",
          tdClass: "text-center text-nowrap",
          thClass: "text-center text-nowrap",
        },
        {
          label: "NumeroVuelo",
          field: "NumeroVuelo",
          tdClass: "text-center text-nowrap",
          thClass: "text-center text-nowrap",
        },
        {
          label: "TipoCambio",
          field: "TipoCambio",
          tdClass: "text-center text-nowrap",
          thClass: "text-center text-nowrap",
        },
        {
          label: "FechaNacimiento",
          field: "FechaNacimiento",
          tdClass: "text-center text-nowrap",
          thClass: "text-center text-nowrap",
        },
        {
          label: "FechaHospedaje",
          field: "FechaHospedaje",
          tdClass: "text-center text-nowrap",
          thClass: "text-center text-nowrap",
        },
        {
          label: "IdEstablecimiento",
          field: "IdEstablecimiento",
          tdClass: "text-center text-nowrap",
          thClass: "text-center text-nowrap",
        },
      ],
      columns: [
      {
          label: "Estacion",
          field: "Estacion",
          tdClass: "text-center text-nowrap",
          thClass: "text-center text-nowrap",
        },
        {
          label: "Gasolinera",
          field: "Gasolinera",
          tdClass: "text-center text-nowrap",
          thClass: "text-center text-nowrap",
        },
        {
          label: "FechaInicio",
          field: "FechaInicio",
          tdClass: "text-center text-nowrap",
          thClass: "text-center text-nowrap",
        },
        {
          label: "FechaFin",
          field: "FechaFin",
          tdClass: "text-center text-nowrap",
          thClass: "text-center text-nowrap",
        },
        {
          label: "ImporteUSD",
          field: "ImporteUSD",
          tdClass: "text-center text-nowrap",
          thClass: "text-center text-nowrap",
        },
        {
          label: "ImporteMXN",
          field: "ImporteMXN",
          tdClass: "text-center text-nowrap",
          thClass: "text-center text-nowrap",
        },
        {
          label: "ImporteUSDRequerido",
          field: "ImporteUSDRequerido",
          tdClass: "text-center text-nowrap",
          thClass: "text-center text-nowrap",
        },
        {
          label: "Usuario",
          field: "Usuario",
          tdClass: "text-center text-nowrap",
          thClass: "text-center text-nowrap",
        },
        {
          label: "FechaRegistro",
          field: "FechaRegistro",
          tdClass: "text-center text-nowrap",
          thClass: "text-center text-nowrap",
        },

        {
          label: "Acciones",
          field: "Acciones",
          width: '20%',
          tdClass: "text-center text-nowrap",
          thClass: "text-center text-nowrap",
        },
        // {
        //   label: "Descarga",
        //   field: "Descarga",
        //   width: '10%',
        //   tdClass: "text-center text-nowrap",
        //   thClass: "text-center text-nowrap",
        // }
      ],
      facturas: [],
		};
	}
}
</script>
<style scoped>
.dx-datagrid-header-panel{
  display: none !important;
}
.loading-search{
  position: absolute;
    width: 100%;
    left: 0px;
    height: 100%;
    background: rgba(0, 0, 0, 0.1);
    z-index: 100000;
    padding-top: 150px;
    top: 0;
}
</style>