<template>
	<div>
		<!-- BEGIN breadcrumb -->
		<ol class="breadcrumb float-xl-end">
			<li class="breadcrumb-item"><a href="javascript:;">Operacion</a></li>
			<!-- <li class="breadcrumb-item"><a href="javascript:;">Bancos</a></li> -->
			<li class="breadcrumb-item active"><a href="javascript:;">Aplicar Precios</a></li>
		</ol>
		<!-- END breadcrumb -->
		<!-- BEGIN page-header -->
		<h1 class="page-header">Aplicar Precios<small></small></h1>
        <!-- <button class="btn" v-on:click="redirectTanques()">tanques</button> -->
		<!-- END page-header -->
		<!-- <a href="javascript:;" v-on:click="show('top-right', 'warn')" class="btn btn-sm btn-primary">Demo</a> -->
		
    <div class="row">
      <div class="col-md-12">
        <form @submit.prevent="search">    
          <div class="form-group row">
            <div class="col-md-3">
                <label class="col-form-label">Fecha</label>
              <div class="input-group">
                <date-picker id="dateTimeInput" v-model="date" :config="options"></date-picker>
                <label for="dateTimeInput" class="input-group-text">
                  <i class="fa fa-calendar"></i>
                </label>
              </div>
            </div>
      
            <div class="col-md-3">
                <label class="col-form-label">Ciudad</label>
                <select class="form-select" @change="getEstaciones()" v-model="ciudadSelect" :disabled="deshabilitarCombos">
                    <option v-for="ciudad in ciudades" :value="ciudad.Id" v-bind:key="ciudad.Id">
                        {{ ciudad.Nombre }}
                    </option>
                </select>
            </div>
            <!-- <div class="col-md-3">
                <label class="col-form-label">Estación</label>
                <select class="form-select" v-model="estacionSelect" :disabled="deshabilitarCombos">
                  <option value="0">Seleccione</option>
                    <option v-for="estacion in estaciones" :value="estacion.NoEstacion" v-bind:key="estacion.NoEstacion">
                    {{ estacion.Nombre }}
                    </option>
                  </select>
            </div> -->
            <div class="col-md-3" style="padding-top: 2.65em;">
              <button type="submit" class="btn btn-success me-1 mb-1 pull-right">{{textoBotonBuscar}}</button>
            </div>
            <div class="col-md-3" style="padding-top: 2.65em; text-align: right;">
              <button type="button" v-on:click="aplicarPrecios()" class="btn btn-success me-1 mb-1 pull-right">{{textoBotonAplicar}}</button>
            </div>
          </div>
        </form>
      </div>
      



    </div>


    <panel title="Aplicar Precios" bodyClass="p-0" class="">
      <vue-good-table
        :columns="columns"
        :rows="precios"
        :lineNumbers="false"
        :search-options="{ enabled: false, placeholder: 'Buscar' }"
        :pagination-options="{ enabled: true, position: 'bottom' }"
      >
        <template slot="table-row" slot-scope="props">

          <span v-if="props.column.label == 'Aplicar'">
            
            <div class="form-check form-switch mb-2">
                <input :disabled="props.row.EstatusCG == 1" class="form-check-input" type="checkbox" id="flexSwitchCheckDefault" v-on:click="check(props.row, props.row.Aplicar)" v-model="props.row.Aplicar">
                <label class="form-check-label" for="flexSwitchCheckDefault">
                    Aplicar
            </label>
            </div>
          </span>
				<span v-else-if="props.column.field == 'Fecha'">
					<label v-html='convertirFecha(props.row.Fecha)'></label>
				</span>
				<span v-else-if="props.column.field == 'Diferencia'">
					<label v-html='convertirDecimal(props.row.Diferencia)'></label>
				</span>
				<span v-else-if="props.column.field == 'Cambio'">
          <i v-if="props.row.Cambio == 'BAJA'" style="color: rgb(190, 34, 34);" class="fas fa-lg fa-fw me-10px fa-arrow-down"></i>
          <i v-if="props.row.Cambio == 'AUMENTA'" style="color: rgb(41, 173, 29);" class="fas fa-lg fa-fw me-10px fa-arrow-up"></i>
          <span v-if="props.row.Cambio == 'SIN CAMBIO'" style="font-weight: 900; font-size: 1.5rem; line-height: 0px;">=</span>
				</span>

				<span v-else-if="props.column.field == 'PrecioActual'">
          <span>{{ props.row.PrecioActual | currency }}</span>
				</span>
				<span v-else-if="props.column.field == 'PrecioNuevo'">
          <span>{{ props.row.PrecioNuevo | currency }}</span>
				</span>
				<span v-else-if="props.column.field == 'TasaIVA'">
          <span>{{ props.row.TasaIVA}}%</span>
				</span>
				<span v-else-if="props.column.field == 'EstatusCG'">
          <i v-if="props.row.EstatusCG == 0" style="color: rgb(126, 123, 123);" class="fas fa-lg fa-fw me-10px fa-circle"></i>
          <i v-if="props.row.EstatusCG == 1" style="color: rgb(47, 155, 88);" class="fas fa-lg fa-fw me-10px fa-circle"></i>
				</span>

        <!--  -->



        

        </template>
      </vue-good-table>


      
	


    </panel>

		<!-- bottom left vue-notification -->
		<notifications group="top-right" position="top right" :speed="1000" />
	</div>
</template>

<script>

import axios from 'axios';
import moment from 'moment';




var numeral = require("numeral");
  import Vue from "vue";

  Vue.filter("formatNumber", function (value) {
      return numeral(value).format("0,00.00"); // displaying other groupings/separators is possible, look at the docs
  });
  
  Vue.filter("currency", function (value) {
      return numeral(value).format("$0,0.00"); 
  });

export default {
	beforeCreate() {
	},
	created() {
		// console.log('created');
	},
	beforeMount() {
		// console.log('beforeMount');
	},
	mounted() {
    debugger; // eslint-disable-line no-debugger
		var userSetting = JSON.parse(localStorage.getItem('userSettings'));
		this.usuario = userSetting.Usuario;
		this.idUsuario = userSetting.NumeroEmpleado;
		this.nombreLogueado = userSetting.Nombre;
		this.usuarioEstacion = userSetting.Estacion;
		this.IdRol = userSetting.IdRol;
    this.getCiudades();
    this.ciudadSelect = userSetting.CiudadId;
    this.getEstaciones();
    this.estacionSelect = userSetting.Estacion;

    if(this.IdRol == 3 || this.IdRol == 7){
      this.deshabilitarCombos = true;
    }
	},
	components: {
	},
  methods: {
	convertirFecha(fecha){
		// moment.locale('es');
		var fechaFormato = moment(fecha).format("DD/MM/YYYY");
		return fechaFormato;
	},
	convertirDecimal(numero){
		var numeroFormato = numero.toFixed(2);
		return numeroFormato;
	},
    check(row, aplicar){
		debugger; // eslint-disable-line no-debugger
        var self = this;
        self.precios[row.originalIndex].Aplicar = !aplicar;
        self.setPrecioAplicar(row.Id, !aplicar);
    },

    aplicarPrecios(){
        var self = this;
        self.intentosLlamadoAPI = 0;
        for(var n = 0; n < self.precios.length; n++){
            if(self.precios[n].Aplicar){
                self.aplicarPreciosCall(self.precios[n]);

            }
        }
    },

    
    async setPrecioAplicar(id, estatus){
    var self = this;
      if(estatus){
        self.aplicadosClic++;
      }else{
        self.aplicadosClic--;
      }
      try {
        const response = await axios.post("Precio/SetPrecioAplicar", {
          Data: {
            "Id":id,
            "Estatus":estatus?1:0
            }
        });

		debugger; // eslint-disable-line no-debugger
        if (!response.data.Success) {
          self.alertMessage = response.data.Message;
          self.alertTitle = "Error";
          self.show("top-right", "error");
        }

      } catch (e) {
        self.alertMessage = "Ocurrio un error";
        self.alertTitle = "Error";
        self.show("top-right", "error");
      }
    },

    async aplicarPreciosCall(item){
    var self = this;
    self.textoBotonAplicar = "Aplicando...";
      try {
        var fechaFormat = moment(item.Fecha).format("YYYY/MM/DD");
        
        var fechaInt = fechaFormat.replace('/', '');
        fechaInt = fechaInt.replace('/', '');
        var estacion = "";
        if(item.Estacion.toString().length == 4){
          estacion = "E0"+item.Estacion;
        }else{
          estacion = "E"+item.Estacion;
        }
        debugger; // eslint-disable-line no-debugger
        var hora = moment(item.Hora, 'HH:mm:ss').format('HH:mm');
        var dataToSend = {
          Data: {
            "Gasolinera":item.Gasolinera,
            "Estacion": estacion,
            "Producto":item.CodigoProducto,
            "Nombre":"",
            "FechaInicio": fechaInt,
            "HoraInicio": hora, 
            "Precio":item.PrecioNuevo,
            "CuotaIEPS":item.CuotaIEPS,
            "TasaIVA": (item.TasaIVA/100).toFixed(4),
            "CostoVenta":0
            }
        };
        debugger; // eslint-disable-line no-debugger
        const response = await axios.post("Precio/SetPrecioCG", dataToSend);

        debugger; // eslint-disable-line no-debugger
        if (response.data.Success) {
          self.SetPrecioEstatus(item.Id);
        }else{
          this.alertMessage = response.data.Error+": "+response.data.Message;
          this.alertTitle = "Error";
          this.show("top-right", "error");

        }
        self.textoBotonAplicar = "Aplicar";

      } catch (e) {
        this.alertMessage = "Ocurrio un error";
        this.alertTitle = "Error";
        this.show("top-right", "error");
      }
    },

    async SetPrecioEstatus(id){
    var self = this;
    self.textoBotonAplicar = "Aplicando...";
      try {
        const response = await axios.post("Precio/SetPrecioEstatus", {
          Data: {
            "Id":id
            }
        });
        self.intentosLlamadoAPI++;
        if (!response.data.Success) {
          this.alertMessage = response.data.Error+". "+response.data.Message;
          this.alertTitle = "Error";
          this.show("top-right", "error");

        }
        self.textoBotonAplicar = "Aplicar";
        debugger; // eslint-disable-line no-debugger
        if(self.aplicadosClic == self.intentosLlamadoAPI){

          self.buscar();
        }

      } catch (e) {
        this.alertMessage = "Ocurrio un error";
        this.alertTitle = "Error";
        this.show("top-right", "error");
      }
    },

	async getCiudades(){
		try{
        const response = await axios.get('Catalogos/GetCiudades');
		//debugger; // eslint-disable-line no-debugger
        if(response.data.Success){
          if(response.data.response.length == 0){
            this.alertMessage = "No se encontró información.";
            this.alertTitle = "Error";
            this.show('top-right', 'error');
          }
        }else{
            console.log("error")
            this.alertMessage = response.data.Error;
            this.alertTitle = "Error";
            this.show('top-right', 'error');
        }
        this.ciudades = response.data.response;
      }catch(e){
        //this.alert = true;
        //this.error = 'Invalid email/password';
      }
	},
	async getEstaciones(){
		try{
		debugger; // eslint-disable-line no-debugger
        const response = await axios.post('Estacion/GetEstaciones?ciudad='+this.ciudadSelect);
        if(response.data.Success){
          if(response.data.response.length == 0){
            this.alertMessage = "No se encontró información.";
            this.alertTitle = "Error";
            this.show('top-right', 'error');
          }
        }else{
            console.log("error")
            this.alertMessage = response.data.Error;
            this.alertTitle = "Error";
            this.show('top-right', 'error');
        }
        this.estaciones = response.data.response;
        this.productos = [];
        this.getProductos();
      }catch(e){
        //this.alert = true;
        //this.error = 'Invalid email/password';
      }
	},
			show (group, type = '') {
				const text = this.alertMessage;
				this.$notify({
					group,
					title: this.alertTitle,
					text,
					type,
					data: {
						randomNumber: Math.random()
					}
				})
			},
			clean (group) {
				this.$notify({ group, clean: true })
			},
			swalNotification(swalType) {
				var btnClass = (swalType == 'error') ? 'danger' : swalType;
					btnClass = (swalType == 'question') ? 'primary' : btnClass;
				this.$swal({
					title: 'Are you sure?',
					text: 'You will not be able to recover this imaginary file!',
					type: swalType,
					showCancelButton: true,
					buttonsStyling: false,
					confirmButtonText: swalType.charAt(0).toUpperCase() + swalType.substr(1).toLowerCase(),
					cancelButtonText: 'Cancel',
					confirmButtonClass: 'btn me-5px btn-'+ btnClass +'',
					cancelButtonClass: 'btn btn-default',
				})
			},
      async buscar(){
      var self = this;
      self.intentosLlamadoAPI = 0;
      self.aplicadosClic = 0;
      self.textoBotonBuscar = "Buscando...";
      try{
        this.intDate = this.date.replace('/', '');
        this.intDate = this.intDate.replace('/', '');
        // var sendDate = self.date;
        // var estacion = self.estacionSelect;
        // if(estacion == "0"){
        //     this.alertMessage = "Seleccione estación";
        //     this.alertTitle = "Error";
        //     this.show('top-right', 'error');
        //   return;
        // }
        const response = await axios.post('Precio/GetPreciosAplicar' ,{
                "Data": {
                    "Ciudad": self.ciudadSelect,
                    "Fecha": self.intDate
                }
            }
        );
        
		debugger; // eslint-disable-line no-debugger
        if(response.data.Success){

          if(response.data.response.length == 0){
            this.alertMessage = "No se encontró información del día especificado.";
            this.alertTitle = "Error";
            this.show('top-right', 'error');
            this.precios = [];
          }else{
            self.precios = response.data.response;
            for(var x=0; x<self.precios.length;x++){
              if(self.precios[x].Aplicar){
                self.aplicadosClic++;
              }
            }
          }
        }else{
            this.precios = [];
            console.log("error")
            this.alertMessage = response.data.Error;
            this.alertTitle = "Error";
            this.show('top-right', 'error');
        }

      this.textoBotonBuscar = "Buscar";
      }catch(e){
            this.precios = [];
            this.alertMessage = "Ocurrió un error";
            this.alertTitle = "Error";
            this.show('top-right', 'error');
        //this.alert = true;
        //this.error = 'Invalid email/password';
      }

      },
    async search(e){
      e.preventDefault();
      this.buscar();
    }
  },
	data() {
		return {
      intentosLlamadoAPI:0,
      aplicadosClic:0,      
      columns: [
      // {
      //     label: "Id",
      //     field: "Id",
      //     tdClass: "text-center text-nowrap",
      //     thClass: "text-center text-nowrap",
      //   },
        {
          label: "Estacion",
          field: "Estacion",
          tdClass: "text-center text-nowrap",
          thClass: "text-center text-nowrap",
        },
        // {
        //   label: "Gasolinera",
        //   field: "Gasolinera",
        //   tdClass: "text-center text-nowrap",
        //   thClass: "text-center text-nowrap",
        // },
        {
          label: "Nombre",
          field: "Nombre",
          tdClass: "text-nowrap",
          thClass: "text-center text-nowrap",
        },
        {
          label: "Fecha",
          field: "Fecha",
          tdClass: "text-center text-nowrap",
          thClass: "text-center text-nowrap",
        },
        {
          label: "Hora",
          field: "Hora",
          tdClass: "text-center text-nowrap",
          thClass: "text-center text-nowrap",
        },
        {
          label: "Producto",
          field: "Producto",
          tdClass: "text-nowrap",
          thClass: "text-center text-nowrap",
        },
        {
          label: "Precio Actual",
          field: "PrecioActual",
          type: "number",
          tdClass: "text-nowrap",
          thClass: "text-center text-nowrap",
        },
        {
          label: "Precio Nuevo",
          field: "PrecioNuevo",
          type: "number",
          tdClass: "text-nowrap",
          thClass: "text-center text-nowrap",
        },
        {
          label: "Diferencia",
          field: "Diferencia",
          type: "number",
          tdClass: "text-nowrap",
          thClass: "text-center text-nowrap",
        },
        {
          label: "Cambio",
          field: "Cambio",
          tdClass: "text-center text-nowrap",
          thClass: "text-center text-nowrap",
        },
        {
          label: "Tasa IVA",
          field: "TasaIVA",
          type: "number",
          tdClass: "text-nowrap",
          thClass: "text-center text-nowrap",
        },
        {
          label: "Cuota IEPS",
          field: "CuotaIEPS",
          type: "number",
          tdClass: "text-nowrap",
          thClass: "text-center text-nowrap",
        },
        {
          label: "Estatus CG",
          field: "EstatusCG",
          tdClass: "text-center text-nowrap",
          thClass: "text-center text-nowrap",
        },
        {
          label: "Aplicar",
          field: "Aplicar",
          tdClass: "text-center text-nowrap",
          thClass: "text-center text-nowrap",
        },
      ],
      precios: [],
      showHeaderFilter: true,
      loading:false,
      nombreLogueado:"",
      idUsuario:undefined,
      reportefajillas:[],
      deshabilitarCombos:false,
      ciudades: [],
      estaciones: [],
      ciudadSelect: 1,
      estacionSelect: 0,

      pageUserSettings: {},
      alertMessage: "",
      alertTitle: "",
      ReporteGetNet: [],
      textoBotonBuscar: "Buscar",
      textoBotonAplicar: "Aplicar",
      date: moment().format("YYYY/MM/DD"),
      intDate: "",
      options: {
        format: 'YYYY/MM/DD',
        useCurrent: false,
      }
		};
	}
}
</script>