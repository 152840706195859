<template>
    <div>
    <div class=" loading-search" v-if="loading">
      <div class="fa-3x text-center">
        <i class="fas fa-circle-notch fa-spin text-success" style=""></i>
      </div>
    </div>
      <!-- BEGIN breadcrumb -->
      <ol class="breadcrumb float-xl-end">
        <li class="breadcrumb-item"><a href="javascript:;">Reportes</a></li>
        <li class="breadcrumb-item"><a href="javascript:;">Facturación</a></li>
        <li class="breadcrumb-item active">
          <a href="javascript:;">Reporte anual</a>
        </li>
      </ol>
      <!-- END breadcrumb -->
      <!-- BEGIN page-header -->
      <h1 class="page-header">Reporte Anual</h1>
      <!-- END page-header -->
  
      <div class="row">
        <div class="col-md-8">
          <form @submit.prevent="search">
            <div class="form-group row">
              
            <div class="col-md-3">
              <label class="col-form-label">Año</label>
                <select class="form-select" v-model="yearSelect">
                    <option v-for="year in years" :value="year.Year" v-bind:key="year.Year">
                        {{ year.Year }}
                    </option>
                </select>
            </div>
            
					<div class="col-md-3" style="padding-top: 2.5rem;">
						<input class="form-check-input" type="checkbox" value="" id="incluirCanceladas" v-model="incluirCanceladas" />
						<label class="form-check-label" for="incluirCanceladas">
							&nbsp;Incluir canceladas
						</label>
					</div>

              <div class="col-md-3" style="padding-top: 2.65em;">
                <button type="submit" class="btn btn-success me-1 mb-1">
                  {{ textoBotonBuscar }}
                </button>
              </div>
            </div>
          </form>
        </div>
        <div class="col-md-4">
          
            
        </div>
      </div>
      <panel title="Anual" bodyClass="p-0" class="">
        <div>
          
          <DxPivotGrid
            id="sales"
            :allow-sorting-by-summary="true"
            :allow-sorting="true"
            :allow-filtering="true"
            :allow-expand-all="true"
            :height="600"
            :show-borders="true"
            :data-source="dataSource"
            @exporting="onExporting"
          >      
          
            <DxExport :enabled="true"/>
            <DxFieldChooser
              :enabled="true"
              :allow-search="true"
              :apply-changes-mode="applyChangesMode"
            />
          </DxPivotGrid>
        </div>
      </panel>
  
      
      <!-- bottom left vue-notification -->
      <notifications group="top-right" position="top right" :speed="1000" />
    </div>
  </template>
  
  <script>
  import axios from "axios";
    import moment from 'moment';

    import DxPivotGrid, {
      DxExport,
      DxFieldChooser,
    } from 'devextreme-vue/pivot-grid';
    import PivotGridDataSource from 'devextreme/ui/pivot_grid/data_source';


  import { Workbook } from 'exceljs';
  import { saveAs } from 'file-saver-es';

  import { exportPivotGrid } from 'devextreme/excel_exporter';
  export default {
    mounted: function () {
        this.getYears();
    },
    components: {
      DxPivotGrid,
      DxExport,
      DxFieldChooser,
    },
    methods: {
        
  getYears(){
    var self = this;
		debugger; // eslint-disable-line no-debugger
    var currentYear = parseInt(moment().format("YYYY"));
    var minimumYear = 2021;
    self.years = [];
    
    for(var n = minimumYear ; n <= currentYear ; n++){
      var year = {"Year": n};
      self.years.push(year);
    }
		debugger; // eslint-disable-line no-debugger
  },
      showNotification(title, text, type) {
        this.alertTitle = title;
        this.alertMessage = text;
        this.show("top-right", type);
      },
      show(group, type = "") {
        const text = this.alertMessage;
        this.$notify({
          group,
          title: this.alertTitle,
          text,
          type,
          data: {
            randomNumber: Math.random(),
          },
        });
      },
      clean(group) {
        this.$notify({ group, clean: true });
      },
      swalNotification(swalType) {
        var btnClass = swalType == "error" ? "danger" : swalType;
        btnClass = swalType == "question" ? "primary" : btnClass;
        this.$swal({
          title: "Are you sure?",
          text: "You will not be able to recover this imaginary file!",
          type: swalType,
          showCancelButton: true,
          buttonsStyling: false,
          confirmButtonText:
            swalType.charAt(0).toUpperCase() + swalType.substr(1).toLowerCase(),
          cancelButtonText: "Cancel",
          confirmButtonClass: "btn me-5px btn-" + btnClass + "",
          cancelButtonClass: "btn btn-default",
        });
      },
      onExporting(e) {
		debugger; // eslint-disable-line no-debugger
        var self = this;
      const workbook = new Workbook();
      const worksheet = workbook.addWorksheet(self.yearSelect.toString());

      exportPivotGrid({
        component: e.component,
        worksheet,
      }).then(() => {
		debugger; // eslint-disable-line no-debugger
        workbook.xlsx.writeBuffer().then((buffer) => {
          saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'Facturacion '+self.yearSelect.toString()+'.xlsx');
        });
      });
      e.cancel = true;
    },
      async getRegistros() {
        var self = this;
        self.loading = true;
        self.textoBotonBuscar = "Buscando...";
        try {
          
		debugger; // eslint-disable-line no-debugger
          const response = await axios.post("Factura/GetFacturasEstacionMes", {
            Data: {
              Ejercicio: self.yearSelect,
              IncluirCancelados: self.incluirCanceladas,
            },
          });
          self.loading = false;
          if (response.data.Success) {
            if (response.data.response.length == 0) {
              this.alertMessage = "No se encontró información del cliente especificado.";
              this.alertTitle = "Error";
              this.show("top-right", "error");
            } else {
              self.registros = response.data.response;


                    
              self.dataSource = new PivotGridDataSource({
                fields: [
                  {
                    caption: 'Ciudad',
                    dataField: 'Ciudad',
                    area: 'row',
                  },
                  {
                    dataField: 'Mes',
                    dataType: 'Mes',
                    area: 'column',
                  },
                  {
                    caption: 'Facturas',
                    dataField: 'Facturas',
                    dataType: 'number',
                    summaryType: 'sum',
                    format: '#,##0.##',
                    area: 'data',
                  },




                  // {
                  //   caption: 'City',
                  //   dataField: 'city',
                  //   width: 150,
                  //   area: 'row',
                  //   selector(data) {
                  //     return `${data.city} (${data.country})`;
                  //   },
                  // },
                  // {
                  //   dataField: 'date',
                  //   dataType: 'date',
                  //   area: 'column',
                  // },
                  // {
                  //   caption: 'Sales',
                  //   dataField: 'amount',
                  //   dataType: 'number',
                  //   summaryType: 'sum',
                  //   format: 'currency',
                  //   area: 'data',
                  // },
                ],
                store: self.registros,
              });



            }
          } else {
            this.alertMessage = response.data.Error;
            this.alertTitle = "Error";
            this.show("top-right", "error");
          }
          this.textoBotonBuscar = "Buscar";
        } catch (e) {
          self.loading = false;
          this.alertMessage = "Ocurrio un error";
          this.alertTitle = "Error";
          this.show("top-right", "error");
        }
      },
      async search(e) {
        e.preventDefault();
        this.getRegistros();
      },
    },
    data() {
      return {
      incluirCanceladas: false,
      allowSearch: true,
      showRelevantValues: true,

      applyChangesMode: 'instantly',
      
      loading:false,
      showHeaderFilter: true,
      deshabilitarCombos:false,
        dataSource: {
        },
        years: [],
        yearSelect: parseInt(moment().format("YYYY")),
        registros:[],
        textoBotonBuscar: "Buscar",
        
      };
    },
  };
  </script>
  
<style scoped>
/* .dx-datagrid-header-panel{
  display: none !important;
} */
.loading-search{
  position: absolute;
    width: 100%;
    left: 0px;
    height: 100%;
    background: rgba(0, 0, 0, 0.1);
    z-index: 1000;
    padding-top: 150px;
    top: 0;
}
</style>