<template>
  <div>

    <div class=" loading-search" v-if="loading">
      <div class="fa-3x text-center">
        <i class="fas fa-circle-notch fa-spin text-success" style=""></i>
      </div>
    </div>
    <!-- BEGIN breadcrumb -->
    <ol class="breadcrumb float-xl-end">
      <li class="breadcrumb-item"><a href="javascript:;">Reportes</a></li>
      <li class="breadcrumb-item"><a href="javascript:;">Compras</a></li>
      <li class="breadcrumb-item active"><a href="javascript:;">Compras CG</a></li>
    </ol>

    <h1 class="page-header">Compras CG <small></small></h1>
    <form @submit.prevent="search">
      <div class="row">
        <div class="col-md-8">
          <div class="form-group row">

            <div class="col-md-3">
              <label class="col-form-label">Fecha inicial</label>
              <div class="input-group">
                <date-picker id="dateTimeInput" v-model="date" :config="options"></date-picker>
                <label for="dateTimeInput" class="input-group-text">
                  <i class="fa fa-calendar"></i>
                </label>
              </div>
            </div>
            <div class="col-md-3">
              <label class="col-form-label">Fecha final</label>
              <div class="input-group">
                <date-picker id="dateTimeInputEnd" v-model="dateEnd" :config="options"></date-picker>
                <label for="dateTimeInputEnd" class="input-group-text">
                  <i class="fa fa-calendar"></i>
                </label>
              </div>
            </div>
            <div class="col-md-3">
              <label class="col-form-label">Ciudad</label>
              <select class="form-select" @change="getEstaciones()" v-model="ciudadSelect"
                :disabled="deshabilitarCombos">
                <option v-for="ciudad in ciudades" :value="ciudad.Id" v-bind:key="ciudad.Id">
                  {{ ciudad.Nombre }}
                </option>
              </select>
            </div>
            <div class="col-md-3">
              <label class="col-form-label">Estación</label>
              <select class="form-select" v-model="estacionSelect" :disabled="deshabilitarCombos">
                <option value="0">Todas</option>
                <option v-for="estacion in estaciones" :value="estacion.NoEstacion" v-bind:key="estacion.NoEstacion">
                  {{ estacion.Nombre }}
                </option>
              </select>
            </div>

          </div>
        </div>
        <div class="col-md-2">
          <button type="submit" class="btn btn-success me-1 mb-1" style="margin-top: 3.1em;">{{ textoBotonBuscar
            }}</button>
        </div>
        <div class="col-md-2" style="padding-top: 2.7em;">
          <!-- <download-excel
                  v-if="searchResult.length > 0"
                  class="btn btn-success rounded-pill mt-35"
                  :data="searchResult"
                  :fields="json_fields"
                  :worksheet="estacionSelect"
                  :name="`Compras CG ${date}.xls`"
                  
                  >
                    Descargar en Excel <i class="fas fa-file-excel fa-fw"></i>
                </download-excel>
                <button v-else disabled class="btn btn-success rounded-pill mt-35">
                    Descargar en Excel <i class="fas fa-file-excel fa-fw"></i>
                </button> -->
        </div>
      </div>
    </form>
    <div>

      <!-- <div>
                <div v-for="(group, Fecha) in groupedObjects" :key="Fecha">
                <h2>{{ Fecha }}</h2>
                <ul>
                    <li v-for="obj in group" :key="obj.NoEstacion">{{ obj.NoEstacion }}</li>
                </ul>
                </div>
            </div> -->

      <panel title="Compras CG" bodyClass="p-0" class="" style="margin-top: 15px;">

        <div class="row" style="margin-top: 15px;;" v-if="searchResult.length > 0">

          <!-- <div class="col-xl-4 col-md-6">
                  <div class="widget widget-stats bg-blue">
                    <div class="stats-icon"><i class="fa fa-desktop"></i></div>
                    <div class="stats-info">
                      <h4>TOTAL VISITORS</h4>
                      <p>3,291,922</p>	
                    </div>
                    <div class="stats-link">
                      <a href="javascript:;">View Detail <i class="fa fa-arrow-alt-circle-right"></i></a>
                    </div>
                  </div>
                </div> -->

          <div class="col-xl-4 col-md-6">
            <div class="widget widget-stats bg-info">
              <div class="stats-icon">
                <!-- <i class="fa fa-link"></i> -->
              </div>
              <div class="stats-info">
                <h1>Compras</h1>
                <p>{{ RespuestaCompras.VolumenCompras | formatNumber }} Litros</p>
              </div>
              <div class="stats-link">
                <a href="javascript:;" style="cursor: auto;">{{ RespuestaCompras.Compras }} compras</a>
              </div>
            </div>
          </div>


          <div class="col-xl-4 col-md-6">
            <div class="widget widget-stats bg-orange">
              <div class="stats-icon">
                <!-- <i class="fa fa-link"></i> -->
              </div>
              <div class="stats-info">
                <h1>Incrementos</h1>
                <p>{{ RespuestaCompras.VolumenIncrementos | formatNumber }} Litros</p>
              </div>
              <div class="stats-link">
                <a href="javascript:;" style="cursor: auto;">{{ RespuestaCompras.Incrementos }} incrementos</a>
              </div>
            </div>
          </div>


          <div class="col-xl-4 col-md-6">
            <div class="widget widget-stats bg-red">
              <div class="stats-icon">
                <!-- <i class="fa fa-link"></i> -->
              </div>
              <div class="stats-info">
                <h1>Diferencia</h1>
                <p>{{ RespuestaCompras.VolumenDiferencia | formatNumber }} Litros</p>
              </div>
              <div class="stats-link">
                <a href="javascript:;" style="cursor: auto;">{{ RespuestaCompras.Diferencia }} dif</a>
              </div>
            </div>
          </div>


        </div>
        <DxDataGrid id="gridContainer" :data-source="searchResult" key-expr="UUID" :show-borders="true"
          @exporting="onExporting" :show-column-lines="false" :show-row-lines="false" :row-alternation-enabled="true"
          :column-width="100">

          <DxScrolling column-rendering-mode="virtual" />
          <DxPager :show-page-size-selector="true" :allowed-page-sizes="[10, 20, 50, 100]"
            :show-navigation-buttons="true" />
          <DxPaging :page-size="10" :page-index="1" />
          <DxFilterRow />
          <DxHeaderFilter :visible="showHeaderFilter" />
          <DxSearchPanel :visible="true" :width="240" placeholder="Search..." />

          <DxGroupPanel :visible="true" />
          <DxGrouping :auto-expand-all="true" />
          <DxExport :enabled="true" :allow-export-selected-data="false" />
          <DxSelection mode="false" />

          <DxColumn data-field="NoEstacion" caption="No Estación" width="100" />
          <DxColumn data-field="Estacion" caption="Estación" width="150" />
          <DxColumn data-field="Compra" caption="Compra">
            <DxHeaderFilter :allow-search="true" />
          </DxColumn>
          <DxColumn data-field="Factura" caption="Factura" width="150" />
          <DxColumn data-field="Remision" caption="Remisión" width="150" />
          <DxColumn data-field="FechaString" caption="Fecha" width="90" />
          <DxColumn data-field="Cantidad" caption="Cantidad" width="90" />

          <DxColumn data-field="Incremento" caption="Incremento" dataType="number" width="120">
            <DxFormat :precision="2" />
          </DxColumn>

          <DxColumn data-field="CodigoProducto" caption="Codigo Producto" width="100" />
          <DxColumn data-field="Producto" caption="Producto" width="150" />

          <DxColumn data-field="Importe" caption="Importe" dataType="number">
            <DxFormat type="currency" :precision="2" />
          </DxColumn>

          <DxColumn data-field="IEPS" caption="IEPS" dataType="number">
            <DxFormat type="currency" :precision="2" />
          </DxColumn>

          <DxColumn data-field="IVA" caption="IVA" dataType="number">
            <DxFormat type="currency" :precision="2" />
          </DxColumn>
          <DxColumn data-field="Recargos" caption="Recargos" width="150" />
          <DxColumn data-field="Descuentos" caption="Descuentos" width="150" />
          <DxColumn data-field="Retenciones" caption="Retenciones" width="150" />
          <DxColumn data-field="UUID" caption="UUID" width="150" />

          <DxColumn caption="Acciones" cell-template="actions" :width="100" search="false" filter="false"
            sorting="false" :fixed="true" fixed-position="right" />

          <template #actions="{ data }">
            <div style="text-align: center;">
              <button class="btn btn-xs btn-success" @click="openIncrementos(data)">Aumentos</button>
            </div>

          </template>

        </DxDataGrid>

      </panel>

      <b-modal id="modalDialog" size="xl">
        <template slot="modal-header">
          <h4 class="modal-title">Incrementos</h4>
          <a class="btn-close" @click="$bvModal.hide('modalDialog')" style="cursor: pointer;"></a>
        </template>
        <div class=" loading-search" v-if="loading">
          <div class="fa-3x text-center">
            <i class="fas fa-circle-notch fa-spin text-success" style=""></i>
          </div>
        </div>

        <div class="row">
          <div class="col">
            <b-card no-body>
              <DxDataGrid id="gridContainer" :data-source="responseIncrementos" key-expr="Transaccion"
                :show-borders="true" @exporting="onExporting" :show-column-lines="false" :show-row-lines="false"
                :row-alternation-enabled="true" :allow-column-resizing="true" :column-auto-width="true">

                <DxScrolling column-rendering-mode="virtual" />
                <DxPager :show-page-size-selector="true" :allowed-page-sizes="[10, 20, 50, 100]"
                  :show-navigation-buttons="true" />
                <DxPaging :page-size="10" :page-index="1" />
                <DxFilterRow />
                <DxHeaderFilter :visible="showHeaderFilter" />
                <DxSearchPanel :visible="true" :width="240" placeholder="Search..." />

                <DxGroupPanel :visible="true" />
                <DxGrouping :auto-expand-all="true" />
                <DxExport :enabled="true" :allow-export-selected-data="false" />
                <DxSelection mode="false" />

                <DxColumn data-field="Transaccion" caption="Transaccion" width="110" />
                <DxColumn data-field="Fecha" caption="Fecha" data-type="date" format="yyyy-MM-dd" />
                <DxColumn data-field="Producto" caption="Producto" />
                <DxColumn data-field="Gasolinera" caption="Gasolinera" />
                <DxColumn data-field="Hora" caption="Hora" />
                <DxColumn data-field="Compra" caption="Compra" />
                <DxColumn data-field="VolumenRecibido" caption="VolumenRecibido" data-type="number" format="#0.00" />
                <DxColumn data-field="Consecutivo" caption="Consecutivo" />
                <DxColumn data-field="Tanque" caption="Tanque" />
                <DxColumn data-field="Tipo" caption="Tipo" />
                <DxColumn data-field="Volumen" caption="Volumen" data-type="number" format="#0.00" />


                <DxColumn caption="Acciones" cell-template="actions" :width="100" search="false" filter="false"
                  sorting="false" :fixed="true" fixed-position="right" />

                <template #actions="{ data }">
                  <div style="text-align: center;">
                    <button class="btn btn-xs btn-success" @click="setAsociar(data)">Asociar</button>
                  </div>

                </template>
              </DxDataGrid>

            </b-card>
          </div>
        </div>

        <template slot="modal-footer">
          <button type="button" class="btn btn-secondary" @click="$bvModal.hide('modalDialog')">Cerrar</button>
        </template>
      </b-modal>


    </div>

    <!-- bottom left vue-notification -->
    <notifications group="top-right" position="top right" :speed="1000" />
  </div>
</template>

<script>

import axios from 'axios';
import moment from 'moment';

import {
  DxDataGrid, DxFormat,
  DxPaging, DxPager, DxScrolling, DxColumn, DxExport, DxSelection, DxGroupPanel, DxGrouping, DxHeaderFilter, DxFilterRow, DxSearchPanel,
} from 'devextreme-vue/data-grid';
import { Workbook } from 'exceljs';
import { saveAs } from 'file-saver';
// Our demo infrastructure requires us to use 'file-saver-es'. We recommend that you use the official 'file-saver' package in your applications.
import { exportDataGrid } from 'devextreme/excel_exporter';
//import service from './data.js';

import Swal from 'sweetalert2';

var numeral = require("numeral");
import Vue from "vue";

import JsonExcel from "vue-json-excel";
Vue.component("downloadExcel", JsonExcel);
Vue.filter("formatNumber", function (value) {
  return numeral(value).format("0,00.00"); // displaying other groupings/separators is possible, look at the docs
});

Vue.filter("currency", function (value) {
  return numeral(value).format("$0,0.00");
});


export default {
  mounted: function () {
    var self = this;

    var userSetting = JSON.parse(localStorage.getItem('userSettings'));
    this.usuario = userSetting.Usuario;
    this.usuarioEstacion = userSetting.Estacion;
    this.IdRol = userSetting.IdRol;
    this.getCiudades();
    this.ciudadSelect = userSetting.CiudadId;
    this.getEstaciones();
    this.estacionSelect = userSetting.Estacion;



    // debugger; // eslint-disable-line no-debugger
    // var dateIn = self.$route.params.fechaIn;
    // var fechaFin = self.$route.params.fechaFin;
    // var ciudad = self.$route.params.ciudad;
    // var estacion = self.$route.params.estacion;
    // if(dateIn != undefined){
    //   self.date = moment(dateIn).format("YYYY/MM/DD");
    //   self.dateEnd = moment(fechaFin).format("YYYY/MM/DD");
    //   self.ciudadSelect = ciudad;
    //   self.estacionSelect = estacion;

    // }

    if (this.IdRol == 3 || this.IdRol == 7) {
      this.deshabilitarCombos = true;
    }

  },
  beforeCreate() {

  },
  created() {
    // console.log('created');
  },
  components: {
    DxDataGrid, DxFormat, DxPaging, DxPager, DxScrolling, DxColumn, DxExport, DxSelection, DxGroupPanel, DxGrouping, DxHeaderFilter, DxFilterRow, DxSearchPanel,
  },
  beforeMount() {
    // console.log('beforeMount');
  },
  computed: {
  },
  methods: {
    onExporting(e) {
      const workbook = new Workbook();
      const worksheet = workbook.addWorksheet('Compras CG');

      exportDataGrid({
        component: e.component,
        worksheet: worksheet,
        autoFilterEnabled: true
      }).then(() => {
        workbook.xlsx.writeBuffer().then((buffer) => {
          saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'Compras CG.xlsx');
        });
      });
      e.cancel = true;
    },
    async getCiudades() {
      try {
        const response = await axios.get('Catalogos/GetCiudades');
        if (response.data.Success) {
          if (response.data.response.length == 0) {
            this.alertMessage = "No se encontró información.";
            this.alertTitle = "Error";
            this.show('top-right', 'error');
          }
        } else {
          console.log("error")
          this.alertMessage = response.data.Error;
          this.alertTitle = "Error";
          this.show('top-right', 'error');
        }
        this.ciudades = response.data.response;
      } catch (e) {
        //this.alert = true;
        //this.error = 'Invalid email/password';
      }
    },
    async getEstaciones() {
      try {
        const response = await axios.post('Estacion/GetEstaciones?ciudad=' + this.ciudadSelect);
        if (response.data.Success) {
          if (response.data.response.length == 0) {
            this.alertMessage = "No se encontró información.";
            this.alertTitle = "Error";
            this.show('top-right', 'error');
          }
        } else {
          console.log("error")
          this.alertMessage = response.data.Error;
          this.alertTitle = "Error";
          this.show('top-right', 'error');
        }
        this.estaciones = response.data.response;

        // debugger; // eslint-disable-line no-debugger
        // this.productos = [];
        // this.getProductos();
      } catch (e) {
        //this.alert = true;
        //this.error = 'Invalid email/password';
      }
    },
    show(group, type = '') {
      const text = this.alertMessage;
      this.$notify({
        group,
        title: this.alertTitle,
        text,
        type,
        data: {
          randomNumber: Math.random()
        }
      })
    },
    clean(group) {
      this.$notify({ group, clean: true })
    },
    swalNotification(swalType) {
      var btnClass = (swalType == 'error') ? 'danger' : swalType;
      btnClass = (swalType == 'question') ? 'primary' : btnClass;
      this.$swal({
        title: 'Are you sure?',
        text: 'You will not be able to recover this imaginary file!',
        type: swalType,
        showCancelButton: true,
        buttonsStyling: false,
        confirmButtonText: swalType.charAt(0).toUpperCase() + swalType.substr(1).toLowerCase(),
        cancelButtonText: 'Cancel',
        confirmButtonClass: 'btn me-5px btn-' + btnClass + '',
        cancelButtonClass: 'btn btn-default',
      })
    },

    async search(e) {
      var self = this;
      e.preventDefault();


      //   if(moment(self.date).format('MM') != moment(self.dateEnd).format('MM')){

      //         self.alertMessage = "Las fechas deben corresponder al mismo mes."
      //         self.alertTitle = "Error";
      //         self.show('top-right', 'error');
      //         return;
      //   }
      self.textoBotonBuscar = "Buscando...";
      self.loading = true;
      try {
        self.intDate = self.date.replace('/', '');
        self.intDate = self.intDate.replace('/', '');


        self.intDateEnd = self.dateEnd.replace('/', '');
        self.intDateEnd = self.intDateEnd.replace('/', '');

        var ciudad = self.ciudadSelect != undefined ? self.ciudadSelect : 0;
        var estacion = self.estacionSelect != undefined ? self.estacionSelect : 0;
        var gasolinera = 0;
        for (var es = 0; es < self.estaciones.length; es++) {
          if (estacion == self.estaciones[es].NoEstacion) {
            gasolinera = self.estaciones[es].Gasolinera;
            break;
          }
        }
        //debugger; // eslint-disable-line no-debugger
        self.searchResult = [];

        var dataToSend = {
          "Data": {
            "FechaInicial": self.intDate,
            "FechaFinal": self.intDateEnd,
            "Ciudad": ciudad,
            "Gasolinera": gasolinera,
            // "FechaInicial": "20230401",
            // "FechaFinal": "20230430",
            // "Gasolinera": 148
          }
        };
        const response = await axios.post('Compras/GetComprasCG', dataToSend);

        //debugger; // eslint-disable-line no-debugger
        self.loading = false;
        self.textoBotonBuscar = "Buscar";
        if (response.data.Success) {
          if (response.data.response.Detalle.length == 0) {
            self.alertMessage = "No se encontró información del día especificado.";
            self.alertTitle = "Error";
            self.show('top-right', 'error');
          } else {

            //debugger; // eslint-disable-line no-debugger
            var respuesta = response.data.response.Detalle;
            self.RespuestaCompras = response.data.response;
            self.searchResult = respuesta;

            for (var n = 0; n < self.searchResult.length; n++) {
              self.searchResult[n].FechaString = moment(self.searchResult[n].Fecha).format("DD/MM/YYYY");
            }
          }

        } else {
          console.log("error")
          self.alertMessage = response.data.Message;
          self.alertTitle = "Error";
          self.show('top-right', 'error');
        }
        self.loading = false;
      } catch (e) {
        self.loading = false;
        //this.alert = true;
        //this.error = 'Invalid email/password';
      }
    },
    openIncrementos(data) {
      //console.log(data.row.data);
      this.getIncrementos(data.row.data.NoEstacion, data.row.data.Compra);
      this.$bvModal.show('modalDialog');
    },
    async getIncrementos(estacion, Compra) {
      this.loading = true;
      this.responseIncrementos = [];

      this.intDate = this.date.replace('/', '');
      this.intDate = this.intDate.replace('/', '');


      this.intDateEnd = this.dateEnd.replace('/', '');
      this.intDateEnd = this.intDateEnd.replace('/', '');

      try {
        var dataToSend = {
          "Data": {
            "Estacion": estacion,
            "FechaIni": this.intDate,
            "FechaFin": this.intDateEnd,
          }
        };

        const response = await axios.post('Compras/GetIncrementos', dataToSend);
        const data = response.data;
        console.log(data);
        if (data.Success) {

          if (data.response.length != 0) {

            for (let index = 0; index < data.response.length; index++) {
              data.response[index].Estacion = estacion;
              data.response[index].Compra = Compra;
            }

            this.responseIncrementos = data.response;
            console.log(this.responseIncrementos);

          } else {
            this.alertMessage = data.Message;
            this.alertTitle = "Sin";
            this.show('top-right', 'warning');
            this.responseIncrementos = [];
          }

          this.loading = false;
        } else {
          this.alertMessage = data.Message;
          this.alertTitle = data.Error;
          this.show('top-right', 'error');
          this.responseIncrementos = [];
          this.loading = false;
        }
      } catch (e) {
        //this.alert = true;
        //this.error = 'Invalid email/password';
        this.responseIncrementos = [];
        this.loading = false;
      }
    },
    async setAsociar(data) {
      this.loading = true;

      var datos = data.row.data;
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-danger"
        },
        buttonsStyling: true
      });

      swalWithBootstrapButtons.fire({
        title: 'Asociar?',
        text: `desea asociar la Transaccion ${datos.Transaccion} con la Compra ${datos.Compra} y Estacion ${datos.Estacion}`,
        icon: "warning",
        showCancelButton: true,
        cancelButtonText: 'Cancelar',
        confirmButtonText: 'Asociar',
        reverseButtons: true
      }).then(async (result) => {
        if (result.isConfirmed) {

          try {
            var dataToSend = {
              "Data": {
                "Estacion": datos.Estacion,
                "Transaccion": datos.Transaccion,
                "Consecutivo": datos.Consecutivo,
                "Compra": datos.Compra,
                "VolumenRec": datos.VolumenRecibido
              }
            };

            const response = await axios.post('Compras/SetCompraIncremento', dataToSend);
            const data = response.data;

            if (data.Success) {
              this.$bvModal.hide('modalDialog')
              this.alertTitle = "Registrado";
              this.alertMessage = 'Se realizo la asociacion!';
              this.show('top-right', 'success');

              this.loading = false;
            } else {
              this.alertMessage = data.Message;
              this.alertTitle = data.Error;
              this.show('top-right', 'error');
              this.responseIncrementos = [];
              this.loading = false;
            }
            this.loading = false;
          } catch (e) {
            //this.alert = true;
            //this.error = 'Invalid email/password';
            this.responseIncrementos = [];
            this.loading = false;
          }

        } else if (
          /* Read more about handling dismissals below */
          result.dismiss === Swal.DismissReason.cancel
        ) {
          this.loading = false;
          console.log("I was closed");
        }
      });
    }
  },
  data() {
    return {
      showHeaderFilter: true,
      RespuestaCompras: {
        "Compras": 0,
        "VolumenCompras": 0,
        "Incrementos": 0,
        "VolumenIncrementos": 0,
        "Dieferencia": 0,
        "VolumenDiferencia": 0,
        "Detalle": []
      },
      loading: false,
      deshabilitarCombos: false,
      ciudades: [],
      estaciones: [],
      ciudadSelect: 1,
      estacionSelect: 0,
      date: moment().startOf('month').format("YYYY/MM/DD"),
      intDateEnd: "",
      dateEnd: moment().format("YYYY/MM/DD"),
      intDate: "",
      options: {
        format: 'YYYY/MM/DD',
        useCurrent: false,
      },
      pageUserSettings: {},
      alertMessage: "",
      alertTitle: "",
      searchResult: [],
      textoBotonBuscar: "Buscar",
      columns: [
        {
          label: "No Estación",
          field: "NoEstacion",
          tdClass: "text-nowrap",
          thClass: "text-nowrap",
        },
        {
          label: "Estación",
          field: "Estacion",
          tdClass: "text-nowrap",
          thClass: "text-nowrap",
        },
        {
          label: "Número",
          field: "Numero",
          tdClass: "text-nowrap",
          thClass: "text-nowrap",
        },
        {
          label: "Factura",
          field: "Factura",
          tdClass: "text-nowrap",
          thClass: "text-nowrap",
        },
        {
          label: "Remisión",
          field: "Remision",
          tdClass: "text-nowrap",
          thClass: "text-nowrap",
        },
        {
          label: "Fecha",
          field: "FechaString",
          tdClass: "text-center text-nowrap",
          thClass: "text-nowrap",
        },
        // {
        //     label: "Codigo del producto",
        //     field: "CodigoProducto",
        //     tdClass: "text-nowrap",
        //     thClass: "text-nowrap",
        // },
        {
          label: "Producto",
          field: "Producto",
          tdClass: "text-nowrap",
          thClass: "text-nowrap",
        },
        {
          label: "Recibido",
          field: "Recibido",
          type: "number",
          tdClass: "text-nowrap",
          thClass: "text-nowrap",
        },
        {
          label: "Facturado",
          field: "Facturado",
          type: "number",
          tdClass: "text-nowrap",
          thClass: "text-nowrap",
        },
        {
          label: "Importe",
          field: "Importe",
          type: "number",
          tdClass: "text-nowrap",
          thClass: "text-nowrap",
        },
        {
          label: "IEPS",
          field: "IEPS",
          type: "number",
          tdClass: "text-nowrap",
          thClass: "text-nowrap",
        },
        {
          label: "IVA",
          field: "IVA",
          type: "number",
          tdClass: "text-nowrap",
          thClass: "text-nowrap",
        },
        {
          label: "Recargos",
          field: "Recargos",
          type: "number",
          tdClass: "text-nowrap",
          thClass: "text-nowrap",
        },
        {
          label: "Descuentos",
          field: "Descuentos",
          type: "number",
          tdClass: "text-nowrap",
          thClass: "text-nowrap",
        },
        {
          label: "Retenciones",
          field: "Retenciones",
          type: "number",
          tdClass: "text-nowrap",
          thClass: "text-nowrap",
        },
        {
          label: "UUID",
          field: "UUID",
          tdClass: "text-nowrap",
          thClass: "text-nowrap",
        },
        {
          label: "Referencia",
          field: "Referencia",
          tdClass: "text-nowrap",
          thClass: "text-nowrap",
        },
      ],
      json_fields: {
        "Numero": "Numero",
        "Factura": "Factura",
        "Remision": "Remision",
        "Fecha": "Fecha",
        "Codigo Producto": "CodigoProducto",
        "Producto": "Producto",
        "Recibido": "Recibido",
        "Facturado": "Facturado",
        "Importe": "Importe",
        "IEPS": "IEPS",
        "IVA": "IVA",
        "Recargos": "Recargos",
        "Descuentos": "Descuentos",
        "Retenciones": "Retenciones",
        "UUID": "UUID",
        "Referencia": "Referencia",
      },
      responseIncrementos: [],

    };
  }
}
</script>
<style scoped>
.dx-datagrid-header-panel {
  display: none !important;
}

.loading-search {
  position: absolute;
  width: 100%;
  left: 0px;
  height: 100%;
  background: rgba(0, 0, 0, 0.1);
  z-index: 1000;
  padding-top: 150px;
  top: 0;
}

.sumatoriasRow {
  background-color: #bbb;
}

.currencyRow {
  text-align: right !important;
}

.vgt-fixed-header {
  overflow-y: auto;
}

.vgt-table__header {
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: #f5f5f5;
}
</style>