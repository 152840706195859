import { render, staticRenderFns } from "./operacion-comisiones-envio-ventas.vue?vue&type=template&id=06060784&scoped=true&"
import script from "./operacion-comisiones-envio-ventas.vue?vue&type=script&lang=js&"
export * from "./operacion-comisiones-envio-ventas.vue?vue&type=script&lang=js&"
import style0 from "./operacion-comisiones-envio-ventas.vue?vue&type=style&index=0&id=06060784&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "06060784",
  null
  
)

export default component.exports