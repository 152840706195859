<template>
  <div>

    <div class=" loading-search" v-if="loading">
      <div class="fa-3x text-center">
        <i class="fas fa-circle-notch fa-spin text-success" style=""></i>
      </div>
    </div>
    <!-- BEGIN breadcrumb -->
    <ol class="breadcrumb float-xl-end">
      <li class="breadcrumb-item"><a href="javascript:;">Reportes</a></li>
      <li class="breadcrumb-item"><a href="javascript:;">Ventas</a></li>
      <li class="breadcrumb-item active"><a href="javascript:;">Despachos por hora</a></li>
    </ol>

    <h1 class="page-header">Despachos Hora <small></small></h1>

    <form @submit.prevent="search">
      <div class="row">
        <div class="col-md-8">
          <div class="form-group row">

            <div class="col-md-3">
              <label class="col-form-label">Fecha</label>
              <div class="input-group">
                <date-picker id="dateTimeInput" v-model="date" :config="options"></date-picker>
                <label for="dateTimeInput" class="input-group-text">
                  <i class="fa fa-calendar"></i>
                </label>
              </div>
            </div>


            <!-- <div class="col-md-3">
                        <label class="col-form-label">Fecha final</label>
                      <div class="input-group">
                        <date-picker id="dateTimeInputEnd" v-model="dateEnd" :config="options"></date-picker>
                        <label for="dateTimeInputEnd" class="input-group-text">
                          <i class="fa fa-calendar"></i>
                        </label>
                      </div>
                    </div> -->
            <div class="col-md-3">
              <label class="col-form-label">Ciudad</label>
              <select class="form-select" @change="getEstaciones()" v-model="ciudadSelect"
                :disabled="deshabilitarCombos">
                <option v-for="ciudad in ciudades" :value="ciudad.Id" v-bind:key="ciudad.Id">
                  {{ ciudad.Nombre }}
                </option>
              </select>
            </div>
            <!-- <div class="col-md-3">
                        <label class="col-form-label">Estación</label>
                        <select class="form-select" v-model="estacionSelect" :disabled="deshabilitarCombos">
                          <option value="0">Todas</option>
                            <option v-for="estacion in estaciones" :value="estacion.NoEstacion" v-bind:key="estacion.NoEstacion">
                            {{ estacion.Nombre }}
                            </option>
                          </select>
                    </div> -->

          </div>
        </div>
        <div class="col-md-2">
          <button type="submit" class="btn btn-success me-1 mb-1" style="margin-top: 3.1em;">{{ textoBotonBuscar
            }}</button>
        </div>
        <div class="col-md-2" style="padding-top: 2.7em;">

        </div>
      </div>
    </form>
    <div>
      <panel title="Despachos Hora" bodyClass="p-0" class="" style="margin-top: 15px;">
        <DxDataGrid id="gridContainerHoras" :data-source="searchResult" key-expr="DespachosH0" :show-borders="true"
          @exporting="onExporting" :show-column-lines="false" :show-row-lines="false" :row-alternation-enabled="true"
          :column-width="75">


          <!-- <DxLoadPanel :enabled="true"/>
              <DxScrolling mode="virtual"/>
              <DxPager
                      :show-page-size-selector="true"
                      :allowed-page-sizes="[10, 20, 50, 100]"
                      :show-navigation-buttons="true"
                  /> -->

          <DxPaging :enabled="false" />
          <DxScrolling column-rendering-mode="virtual" />

          <DxFilterRow />
          <DxHeaderFilter :visible="showHeaderFilter" />
          <DxSearchPanel :visible="true" :width="240" placeholder="Search..." />

          <DxGroupPanel :visible="true" />
          <DxGrouping :auto-expand-all="true" />
          <DxExport :enabled="true" :allow-export-selected-data="false" />
          <DxSelection mode="false" />

          <DxColumnFixing :enabled="true" />

          <DxColumn dataField="Estacion" caption="Estación" width="100" :fixed="true" fixed-position="left"
            :allow-fixing="true" />

          <DxColumn dataField="Bomba" caption="Bomba" width="80" :fixed="true" fixed-position="left"
          :allow-fixing="true"/>

          <!-- <DxColumn dataField="NoEstacion" caption="No Estación" width="80" :allow-fixing="true" /> -->
          <!-- <DxColumn dataField="Fecha" caption="Fecha" dataType="date" width="100"  /> -->
          <DxColumn dataField="DespachosH0" caption="0 Hrs" :cellTemplate="StatusCellTemplate" />
          <DxColumn dataField="DespachosH1" caption="1 Hrs" :cellTemplate="StatusCellTemplate" />
          <DxColumn dataField="DespachosH2" caption="2 Hrs" :cellTemplate="StatusCellTemplate" />
          <DxColumn dataField="DespachosH3" caption="3 Hrs" :cellTemplate="StatusCellTemplate" />
          <DxColumn dataField="DespachosH4" caption="4 Hrs" :cellTemplate="StatusCellTemplate" />
          <DxColumn dataField="DespachosH5" caption="5 Hrs" :cellTemplate="StatusCellTemplate" />
          <DxColumn dataField="DespachosH6" caption="6 Hrs" :cellTemplate="StatusCellTemplate" />
          <DxColumn dataField="DespachosH7" caption="7 Hrs" :cellTemplate="StatusCellTemplate" />
          <DxColumn dataField="DespachosH8" caption="8 Hrs" :cellTemplate="StatusCellTemplate" />
          <DxColumn dataField="DespachosH9" caption="9 Hrs" :cellTemplate="StatusCellTemplate" />
          <DxColumn dataField="DespachosH10" caption="10 Hrs" :cellTemplate="StatusCellTemplate" />
          <DxColumn dataField="DespachosH11" caption="11 Hrs" :cellTemplate="StatusCellTemplate" />
          <DxColumn dataField="DespachosH12" caption="12 Hrs" :cellTemplate="StatusCellTemplate" />
          <DxColumn dataField="DespachosH13" caption="13 Hrs" :cellTemplate="StatusCellTemplate" />
          <DxColumn dataField="DespachosH14" caption="14 Hrs" :cellTemplate="StatusCellTemplate" />
          <DxColumn dataField="DespachosH15" caption="15 Hrs" :cellTemplate="StatusCellTemplate" />
          <DxColumn dataField="DespachosH16" caption="16 Hrs" :cellTemplate="StatusCellTemplate" />
          <DxColumn dataField="DespachosH17" caption="17 Hrs" :cellTemplate="StatusCellTemplate" />
          <DxColumn dataField="DespachosH18" caption="18 Hrs" :cellTemplate="StatusCellTemplate" />
          <DxColumn dataField="DespachosH19" caption="19 Hrs" :cellTemplate="StatusCellTemplate" />
          <DxColumn dataField="DespachosH20" caption="20 Hrs" :cellTemplate="StatusCellTemplate" />
          <DxColumn dataField="DespachosH21" caption="21 Hrs" :cellTemplate="StatusCellTemplate" />
          <DxColumn dataField="DespachosH22" caption="22 Hrs" :cellTemplate="StatusCellTemplate" />
          <DxColumn dataField="DespachosH23" caption="23 Hrs" :cellTemplate="StatusCellTemplate" />
          <!-- <DxColumn dataField="Abrir" caption="Abrir" :cellTemplate="enviarCGButton" /> -->

          <!-- <DxColumn data-field="Transaccion" caption="Transacción"  width="200">
                    <DxHeaderFilter :allow-search="true"/>
                  </DxColumn> -->



          <!-- <DxColumn data-field="Tipo" caption="Tipo" /> -->


          <!-- <DxColumn data-field="Importe" caption="Importe"  dataType="number">
                    <DxFormat
                        type="currency"
                        :precision="2"
                        />
                </DxColumn> -->
          <!-- <DxColumn data-field="FechaDespacho" dataType="date" caption="Fecha Despacho" /> -->


          <!-- <DxColumn data-field="Diferencia" caption="Diferencia" dataType="number">
                    <DxFormat
                        type="currency"
                        :precision="2"
                        />
                </DxColumn> -->

          <!-- <DxColumn data-field="Diferencia" caption="Diferencia" dataType="number">
                    <DxFormat
                        type="currency"
                        :precision="2"
                        />
                </DxColumn> -->


          <!-- <DxSummary>
                    
                    <DxTotalItem
                        column="Importe"
                        summary-type="sum"
                        value-format="$#,##0.##"
                        displayFormat='{0}'
                        >
                    </DxTotalItem>
                    
                </DxSummary> -->

        </DxDataGrid>

      </panel>
    </div>

    <!-- bottom left vue-notification -->
    <notifications group="top-right" position="top right" :speed="1000" />
  </div>
</template>

<script>

import axios from 'axios';
import moment from 'moment';



import { DxSorting, DxFormat, DxColumnFixing, DxDataGrid, DxPaging, DxScrolling, DxColumn, DxExport, DxSelection, DxGroupPanel, DxGrouping, DxHeaderFilter, DxFilterRow, DxSearchPanel, } from 'devextreme-vue/data-grid';
import { Workbook } from 'exceljs';
import { saveAs } from 'file-saver';
// Our demo infrastructure requires us to use 'file-saver-es'. We recommend that you use the official 'file-saver' package in your applications.
import { exportDataGrid } from 'devextreme/excel_exporter';
//import service from './data.js';


var numeral = require("numeral");
import Vue from "vue";

import JsonExcel from "vue-json-excel";
Vue.component("downloadExcel", JsonExcel);
Vue.filter("formatNumber", function (value) {
  return numeral(value).format("0,00.00"); // displaying other groupings/separators is possible, look at the docs
});

Vue.filter("currency", function (value) {
  return numeral(value).format("$0,0.00");
});


export default {
  mounted: function () {
    /* eslint-disable no-debugger */
    debugger;
    var self = this;

    var userSetting = JSON.parse(localStorage.getItem('userSettings'));
    this.usuario = userSetting.Usuario;
    this.usuarioEstacion = userSetting.Estacion;
    this.IdRol = userSetting.IdRol;
    this.getCiudades();
    this.ciudadSelect = userSetting.CiudadId;
    this.getEstaciones();
    this.estacionSelect = userSetting.Estacion;

    if (this.IdRol == 3 || this.IdRol == 7) {
      this.deshabilitarCombos = true;
    }

  },
  beforeCreate() {

  },
  created() {
    // console.log('created');
  },
  components: {
    DxColumnFixing, DxDataGrid, DxPaging, DxScrolling, DxColumn, DxExport, DxSelection, DxGroupPanel, DxGrouping, DxHeaderFilter, DxFilterRow, DxSearchPanel,
  },
  beforeMount() {
    // console.log('beforeMount');
  },
  computed: {
  },
  methods: {
    // StatusCellTemplate(cellElement, cellInfo) {
    // 	debugger; // eslint-disable-line no-debugger
    //   // Custom cell template to show different icons based on the "status" value
    //   const repeticiones = cellInfo.data.Repeticiones;
    //   const iconSrc = repeticiones > 1 ? '/assets/img/user/user-13.jpg' : 'https://www.pngfind.com/pngs/m/639-6399088_img-success-svg-png-icon-free-download-download.png';
    //   const repeticionesString = repeticiones>1? repeticiones.toString():"";
    //   if(repeticiones == 1){
    //     cellElement.innerHTML = `<span>${repeticiones}</span><i class="fas fa-lg fa-fw me-10px fa-exclamation-triangle"  style="color: darkseagreen;"></i>`;
    //   }else if(repeticiones == 2){
    //     cellElement.innerHTML = `<span>${repeticionesString}</span><i class="fas fa-lg fa-fw me-10px fa-exclamation-triangle"  style="color: orange;"></i>`;
    //   }else if(repeticiones > 2){
    //     cellElement.innerHTML = `<span>${repeticionesString}</span><i class="fas fa-lg fa-fw me-10px fa-exclamation-triangle" style="color: #ff3631;"></i>`;
    //   }



    // },
    StatusCellTemplate(cellElement, cellInfo) {
      //debugger; // eslint-disable-line no-debugger
      var valor = 0;
      switch (cellInfo.column.dataField) {

        case 'DespachosH0':
          valor = cellInfo.data.DespachosH0;
          if (cellInfo.data.DespachosH0 < 10) {
            cellElement.innerHTML = `<span style="color: #ff3631;">${valor}</span>`;
          } else {
            cellElement.innerHTML = `<span style="color: #000;">${valor}</span>`;
          }
          break;



        case 'DespachosH1':
          valor = cellInfo.data.DespachosH1;
          if (cellInfo.data.DespachosH1 < 10) {
            cellElement.innerHTML = `<span style="color: #ff3631;">${valor}</span>`;
          } else {
            cellElement.innerHTML = `<span style="color: #000;">${valor}</span>`;
          }
          break;


        case 'DespachosH2':
          valor = cellInfo.data.DespachosH2;
          if (cellInfo.data.DespachosH2 < 10) {
            cellElement.innerHTML = `<span style="color: #ff3631;">${valor}</span>`;
          } else {
            cellElement.innerHTML = `<span style="color: #000;">${valor}</span>`;
          }
          break;


        case 'DespachosH3':
          valor = cellInfo.data.DespachosH3;
          if (cellInfo.data.DespachosH3 < 10) {
            cellElement.innerHTML = `<span style="color: #ff3631;">${valor}</span>`;
          } else {
            cellElement.innerHTML = `<span style="color: #000;">${valor}</span>`;
          }
          break;


        case 'DespachosH4':
          valor = cellInfo.data.DespachosH4;
          if (cellInfo.data.DespachosH4 < 10) {
            cellElement.innerHTML = `<span style="color: #ff3631;">${valor}</span>`;
          } else {
            cellElement.innerHTML = `<span style="color: #000;">${valor}</span>`;
          }
          break;


        case 'DespachosH5':
          valor = cellInfo.data.DespachosH5;
          if (cellInfo.data.DespachosH5 < 10) {
            cellElement.innerHTML = `<span style="color: #ff3631;">${valor}</span>`;
          } else {
            cellElement.innerHTML = `<span style="color: #000;">${valor}</span>`;
          }
          break;


        case 'DespachosH6':
          valor = cellInfo.data.DespachosH6;
          if (cellInfo.data.DespachosH6 < 10) {
            cellElement.innerHTML = `<span style="color: #ff3631;">${valor}</span>`;
          } else {
            cellElement.innerHTML = `<span style="color: #000;">${valor}</span>`;
          }
          break;


        case 'DespachosH7':
          valor = cellInfo.data.DespachosH7;
          if (cellInfo.data.DespachosH7 < 10) {
            cellElement.innerHTML = `<span style="color: #ff3631;">${valor}</span>`;
          } else {
            cellElement.innerHTML = `<span style="color: #000;">${valor}</span>`;
          }
          break;


        case 'DespachosH8':
          valor = cellInfo.data.DespachosH8;
          if (cellInfo.data.DespachosH8 < 10) {
            cellElement.innerHTML = `<span style="color: #ff3631;">${valor}</span>`;
          } else {
            cellElement.innerHTML = `<span style="color: #000;">${valor}</span>`;
          }
          break;


        case 'DespachosH9':
          valor = cellInfo.data.DespachosH9;
          if (cellInfo.data.DespachosH9 < 10) {
            cellElement.innerHTML = `<span style="color: #ff3631;">${valor}</span>`;
          } else {
            cellElement.innerHTML = `<span style="color: #000;">${valor}</span>`;
          }
          break;


        case 'DespachosH10':
          valor = cellInfo.data.DespachosH10;
          if (cellInfo.data.DespachosH10 < 10) {
            cellElement.innerHTML = `<span style="color: #ff3631;">${valor}</span>`;
          } else {
            cellElement.innerHTML = `<span style="color: #000;">${valor}</span>`;
          }
          break;


        case 'DespachosH11':
          valor = cellInfo.data.DespachosH11;
          if (cellInfo.data.DespachosH11 < 10) {
            cellElement.innerHTML = `<span style="color: #ff3631;">${valor}</span>`;
          } else {
            cellElement.innerHTML = `<span style="color: #000;">${valor}</span>`;
          }
          break;


        case 'DespachosH12':
          valor = cellInfo.data.DespachosH12;
          if (cellInfo.data.DespachosH12 < 10) {
            cellElement.innerHTML = `<span style="color: #ff3631;">${valor}</span>`;
          } else {
            cellElement.innerHTML = `<span style="color: #000;">${valor}</span>`;
          }
          break;


        case 'DespachosH13':
          valor = cellInfo.data.DespachosH13;
          if (cellInfo.data.DespachosH13 < 10) {
            cellElement.innerHTML = `<span style="color: #ff3631;">${valor}</span>`;
          } else {
            cellElement.innerHTML = `<span style="color: #000;">${valor}</span>`;
          }
          break;


        case 'DespachosH14':
          valor = cellInfo.data.DespachosH14;
          if (cellInfo.data.DespachosH14 < 10) {
            cellElement.innerHTML = `<span style="color: #ff3631;">${valor}</span>`;
          } else {
            cellElement.innerHTML = `<span style="color: #000;">${valor}</span>`;
          }
          break;


        case 'DespachosH15':
          valor = cellInfo.data.DespachosH15;
          if (cellInfo.data.DespachosH15 < 10) {
            cellElement.innerHTML = `<span style="color: #ff3631;">${valor}</span>`;
          } else {
            cellElement.innerHTML = `<span style="color: #000;">${valor}</span>`;
          }
          break;


        case 'DespachosH16':
          valor = cellInfo.data.DespachosH16;
          if (cellInfo.data.DespachosH16 < 10) {
            cellElement.innerHTML = `<span style="color: #ff3631;">${valor}</span>`;
          } else {
            cellElement.innerHTML = `<span style="color: #000;">${valor}</span>`;
          }
          break;


        case 'DespachosH17':
          valor = cellInfo.data.DespachosH17;
          if (cellInfo.data.DespachosH17 < 10) {
            cellElement.innerHTML = `<span style="color: #ff3631;">${valor}</span>`;
          } else {
            cellElement.innerHTML = `<span style="color: #000;">${valor}</span>`;
          }
          break;


        case 'DespachosH18':
          valor = cellInfo.data.DespachosH18;
          if (cellInfo.data.DespachosH18 < 10) {
            cellElement.innerHTML = `<span style="color: #ff3631;">${valor}</span>`;
          } else {
            cellElement.innerHTML = `<span style="color: #000;">${valor}</span>`;
          }
          break;


        case 'DespachosH19':
          valor = cellInfo.data.DespachosH19;
          if (cellInfo.data.DespachosH19 < 10) {
            cellElement.innerHTML = `<span style="color: #ff3631;">${valor}</span>`;
          } else {
            cellElement.innerHTML = `<span style="color: #000;">${valor}</span>`;
          }
          break;


        case 'DespachosH20':
          valor = cellInfo.data.DespachosH20;
          if (cellInfo.data.DespachosH20 < 10) {
            cellElement.innerHTML = `<span style="color: #ff3631;">${valor}</span>`;
          } else {
            cellElement.innerHTML = `<span style="color: #000;">${valor}</span>`;
          }
          break;



        case 'DespachosH21':
          valor = cellInfo.data.DespachosH21;
          if (cellInfo.data.DespachosH21 < 10) {
            cellElement.innerHTML = `<span style="color: #ff3631;">${valor}</span>`;
          } else {
            cellElement.innerHTML = `<span style="color: #000;">${valor}</span>`;
          }
          break;



        case 'DespachosH22':
          valor = cellInfo.data.DespachosH22;
          if (cellInfo.data.DespachosH22 < 10) {
            cellElement.innerHTML = `<span style="color: #ff3631;">${valor}</span>`;
          } else {
            cellElement.innerHTML = `<span style="color: #000;">${valor}</span>`;
          }
          break;



        case 'DespachosH23':
          valor = cellInfo.data.DespachosH23;
          if (cellInfo.data.DespachosH23 < 10) {
            cellElement.innerHTML = `<span style="color: #ff3631;">${valor}</span>`;
          } else {
            cellElement.innerHTML = `<span style="color: #000;">${valor}</span>`;
          }
          break;



      }
      //   const repeticiones = cellInfo.data.Repeticiones;
      //   const iconSrc = repeticiones > 1 ? '/assets/img/user/user-13.jpg' : 'https://www.pngfind.com/pngs/m/639-6399088_img-success-svg-png-icon-free-download-download.png';
      //   const repeticionesString = repeticiones>1? repeticiones.toString():"";
      //   if(repeticiones == 1){
      //     cellElement.innerHTML = `<span>${repeticiones}</span><i class="fas fa-lg fa-fw me-10px fa-exclamation-triangle"  style="color: darkseagreen;"></i>`;
      //   }else if(repeticiones == 2){
      //     cellElement.innerHTML = `<span>${repeticionesString}</span><i class="fas fa-lg fa-fw me-10px fa-exclamation-triangle"  style="color: orange;"></i>`;
      //   }else if(repeticiones > 2){
      //     cellElement.innerHTML = `<span>${repeticionesString}</span><i class="fas fa-lg fa-fw me-10px fa-exclamation-triangle" style="color: #ff3631;"></i>`;
      //   }



    },



    enviarCGButton(cellElement, cellInfo) {

      const button = document.createElement("button");
      button.innerText = "Abrir";
      button.className = "btn btn-sm btn-success";
      button.addEventListener("click", () => {

        this.handleButtonClick(cellInfo.data);
      });

      cellElement.appendChild(button);
    },
    handleButtonClick(data) {
      debugger; // eslint-disable-line no-debugger

      // alert(data.NoEstacion);


    },

    async enviarCG(data) {
      //api/Precio/SetAcusePrecio


      var self = this;
      // self.textoBotonEnviar = "Enviando...";
      self.loading = true;
      try {
        // const segments = data.Path.split('/');

        // // Get the last element of the array, which is the filename
        // const fileName = segments[segments.length - 1];

        debugger; // eslint-disable-line no-debugger
        var dataToSend = {
          "Data": {
            "Path": data.Path,
            "Usuario": self.usuario
            // "Path": "CRE/ACUSES/2023/10/23/PL3988EXPES2015.pdf", 
            // "Usuario": "juan.garcia"
          }
        };
        debugger; // eslint-disable-line no-debugger
        const response = await axios.post("Precio/GetAcusePDF", dataToSend);

        self.loading = false;
        // self.textoBotonEnviar = "Enviar";
        debugger; // eslint-disable-line no-debugger
        if (response.data.Success) {

          this.alertMessage = response.data.Message;
          this.alertTitle = "";
          this.show("top-right", "success");


          self.descargarPDF(response.data.response, data.Nombre);
        }
        else {

          this.alertMessage = response.data.Message;
          this.alertTitle = "Error";
          this.show("top-right", "error");
        }


      } catch (e) {
        self.loading = false;
        self.textoBotonEnviar = "Enviar";
        this.alertMessage = "Ocurrio un error";
        this.alertTitle = "Error";
        this.show("top-right", "error");
      }
    },





    onExporting(e) {
      const workbook = new Workbook();
      const worksheet = workbook.addWorksheet('Pedidos');

      exportDataGrid({
        component: e.component,
        worksheet: worksheet,
        autoFilterEnabled: true
      }).then(() => {
        workbook.xlsx.writeBuffer().then((buffer) => {
          saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'Pedidos.xlsx');
        });
      });
      e.cancel = true;
    },
    async getCiudades() {
      try {
        const response = await axios.get('Catalogos/GetCiudades');
        if (response.data.Success) {
          if (response.data.response.length == 0) {
            this.alertMessage = "No se encontró información.";
            this.alertTitle = "Error";
            this.show('top-right', 'error');
          }
        } else {
          console.log("error")
          this.alertMessage = response.data.Error;
          this.alertTitle = "Error";
          this.show('top-right', 'error');
        }
        this.ciudades = response.data.response;
      } catch (e) {
        //this.alert = true;
        //this.error = 'Invalid email/password';
      }
    },
    async getEstaciones() {
      try {
        const response = await axios.post('Estacion/GetEstaciones?ciudad=' + this.ciudadSelect);
        if (response.data.Success) {
          if (response.data.response.length == 0) {
            this.alertMessage = "No se encontró información.";
            this.alertTitle = "Error";
            this.show('top-right', 'error');
          }
        } else {
          console.log("error")
          this.alertMessage = response.data.Error;
          this.alertTitle = "Error";
          this.show('top-right', 'error');
        }
        this.estaciones = response.data.response;

        debugger; // eslint-disable-line no-debugger
        // this.productos = [];
        // this.getProductos();
      } catch (e) {
        //this.alert = true;
        //this.error = 'Invalid email/password';
      }
    },
    show(group, type = '') {
      const text = this.alertMessage;
      this.$notify({
        group,
        title: this.alertTitle,
        text,
        type,
        data: {
          randomNumber: Math.random()
        }
      })
    },
    clean(group) {
      this.$notify({ group, clean: true })
    },
    swalNotification(swalType) {
      var btnClass = (swalType == 'error') ? 'danger' : swalType;
      btnClass = (swalType == 'question') ? 'primary' : btnClass;
      this.$swal({
        title: 'Are you sure?',
        text: 'You will not be able to recover this imaginary file!',
        type: swalType,
        showCancelButton: true,
        buttonsStyling: false,
        confirmButtonText: swalType.charAt(0).toUpperCase() + swalType.substr(1).toLowerCase(),
        cancelButtonText: 'Cancel',
        confirmButtonClass: 'btn me-5px btn-' + btnClass + '',
        cancelButtonClass: 'btn btn-default',
      })
    },

    async seachDespachos() {
      debugger; // eslint-disable-line no-debugger
      var self = this;



      self.textoBotonBuscar = "Buscando...";
      self.loading = true;
      try {



        self.intDate = self.date.replace('/', '');
        self.intDate = self.intDate.replace('/', '');


        // self.intDateEnd = self.dateEnd.replace('/', '');
        // self.intDateEnd = self.intDateEnd.replace('/', '');

        var ciudad = self.ciudadSelect != undefined ? self.ciudadSelect : 0;
        var estacion = self.estacionSelect != undefined ? self.estacionSelect : 0;


        // const startDate = moment(self.date).format('YYYY-MM-DD');
        // const endDate = moment(self.dateEnd).format('YYYY-MM-DD');




        debugger; // eslint-disable-line no-debugger

        var dataToSend = {
          "Data": {
            "Fecha": self.intDate,
            //   "FechaFin":self.intDateEnd,
            "Ciudad": ciudad,
            // "Estacion": estacion,

          }
        };


        self.searchResult = [];

        //const response = await axios.post('Despacho/GetDespachosHora', dataToSend);
        const response = await axios.post('Despacho/GetDespachosBombaHora ', dataToSend);

        self.loading = false;
        self.textoBotonBuscar = "Buscar";
        if (response.data.Success) {
          if (response.data.response.length == 0) {
            self.alertMessage = "No se encontró información del día especificado.";
            self.alertTitle = "Error";
            self.show('top-right', 'error');
          } else {

            debugger; // eslint-disable-line no-debugger
            // var respuesta = response.data.response.Detalle;
            //self.RespuestaCompras = response.data.response.Pedidos;
            self.searchResult = response.data.response;




          }


        } else {
          console.log("error")
          self.alertMessage = response.data.Message;
          self.alertTitle = response.data.Error;
          self.show('top-right', 'error');
        }





        self.loading = false;
      } catch (e) {
        self.loading = false;
        self.alertMessage = "Error al consultar";
        self.alertTitle = 'Error';
        self.show('top-right', 'error');
        //this.alert = true;
        //this.error = 'Invalid email/password';
      }
    },
    async search(e) {

      e.preventDefault();
      this.seachDespachos();
    }
  },
  data() {
    return {
      fromCuadreDetalle: false,
      parametrosCuadre: null,
      showHeaderFilter: true,
      RespuestaCompras: [],
      loading: false,
      deshabilitarCombos: false,
      ciudades: [],
      estaciones: [],
      ciudadSelect: 1,
      estacionSelect: 0,
      date: moment().format("YYYY/MM/DD"),
      intDateEnd: "",
      dateEnd: moment().format("YYYY/MM/DD"),
      intDate: "",
      options: {
        format: 'YYYY/MM/DD',
        useCurrent: false,
      },
      pageUserSettings: {},
      alertMessage: "",
      alertTitle: "",
      searchResult: [],
      textoBotonBuscar: "Buscar",

    };
  }
}
</script>
<style scoped>
.dx-datagrid-header-panel {
  display: none !important;
}

.loading-search {
  position: absolute;
  width: 100%;
  left: 0px;
  height: 100%;
  background: rgba(0, 0, 0, 0.1);
  z-index: 1000;
  padding-top: 150px;
  top: 0;
}

.sumatoriasRow {
  background-color: #bbb;
}

.currencyRow {
  text-align: right !important;
}

.vgt-fixed-header {
  overflow-y: auto;
}

.vgt-table__header {
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: #f5f5f5;
}

#gridContainerHoras {
  height: 600px;
}
</style>