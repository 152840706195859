<template>
	<div>
		<!-- BEGIN #header -->
		<div id="header" class="app-header" v-bind:class="{ 'app-header-inverse': appOptions.appHeaderInverse }">
			<!-- BEGIN navbar-header -->
			<div class="navbar-header">
				<button type="button" class="navbar-mobile-toggler" v-on:click="toggleSidebarEndMobile" v-if="appOptions.appSidebarTwo">
					<span class="icon-bar"></span>
					<span class="icon-bar"></span>
					<span class="icon-bar"></span>
				</button>
				<router-link to="/" class="navbar-brand"><span class="navbar-logo"></span> <b>Addes</b> </router-link>
				<button type="button" class="navbar-mobile-toggler" v-on:click="toggleTopMenuMobile" v-if="appOptions.appTopMenu && !appOptions.appSidebarNone">
					<span class="fa-stack fa-lg text-inverse">
						<i class="far fa-square fa-stack-2x"></i>
						<i class="fa fa-cog fa-stack-1x"></i>
					</span>
				</button>
				<button type="button" class="navbar-mobile-toggler" v-on:click="toggleTopMenuMobile" v-if="appOptions.appTopMenu && appOptions.appSidebarNone">
					<span class="icon-bar"></span>
					<span class="icon-bar"></span>
					<span class="icon-bar"></span>
				</button>
				<button type="button" class="navbar-mobile-toggler" v-on:click="toggleHeaderMegaMenuMobile" v-if="appOptions.appHeaderMegaMenu">
					<span class="fa-stack fa-lg text-inverse m-t-2">
						<i class="far fa-square fa-stack-2x"></i>
						<i class="fa fa-cog fa-stack-1x"></i>
					</span>
				</button>
				<button type="button" class="navbar-mobile-toggler" v-on:click="toggleSidebarMobile" v-if="!appOptions.appSidebarNone">
					<span class="icon-bar"></span>
					<span class="icon-bar"></span>
					<span class="icon-bar"></span>
				</button>
			</div>
			<!-- END navbar-header -->


			<!-- BEGIN header-nav -->
			<div class="navbar-nav">
				<header-mega-menu v-if="appOptions.appHeaderMegaMenu"></header-mega-menu>
				<div class="navbar-item navbar-form">
					<!-- <form action="" method="POST" name="search">
						<div class="form-group">
							<input type="text" class="form-control" placeholder="Enter keyword" />
							<button type="submit" class="btn btn-search"><i class="fa fa-search"></i></button>
						</div>
					</form> -->
				</div>

				<b-nav-item-dropdown class="navbar-item" menu-class="dropdown-menu media-list dropdown-menu-end" toggle-class="navbar-link dropdown-toggle icon" no-caret>
					<!-- <template slot="button-content">
						<i class="fa fa-bell"></i>
						<span class="badge">5</span>
					</template> -->
					<div class="dropdown-header">NOTIFICATIONS (5)</div>
					<a href="javascript:;" class="dropdown-item media">
						<div class="media-left">
							<i class="fa fa-bug media-object bg-gray-500"></i>
						</div>
						<div class="media-body">
							<h6 class="media-heading">Server Error Reports <i class="fa fa-exclamation-circle text-danger"></i></h6>
							<div class="text-muted fs-10px">3 minutes ago</div>
						</div>
					</a>
					<a href="javascript:;" class="dropdown-item media">
						<div class="media-left">
							<img src="/assets/img/user/user-1.jpg" class="media-object" alt="" />
							<i class="fab fa-facebook-messenger text-blue media-object-icon"></i>
						</div>
						<div class="media-body">
							<h6 class="media-heading">John Smith</h6>
							<p>Quisque pulvinar tellus sit amet sem scelerisque tincidunt.</p>
							<div class="text-muted fs-10px">25 minutes ago</div>
						</div>
					</a>
					<a href="javascript:;" class="dropdown-item media">
						<div class="media-left">
							<img src="/assets/img/user/user-2.jpg" class="media-object" alt="" />
							<i class="fab fa-facebook-messenger text-blue media-object-icon"></i>
						</div>
						<div class="media-body">
							<h6 class="media-heading">Olivia</h6>
							<p>Quisque pulvinar tellus sit amet sem scelerisque tincidunt.</p>
							<div class="text-muted fs-10px">35 minutes ago</div>
						</div>
					</a>
					<a href="javascript:;" class="dropdown-item media">
						<div class="media-left">
							<i class="fa fa-plus media-object bg-gray-500"></i>
						</div>
						<div class="media-body">
							<h6 class="media-heading"> New User Registered</h6>
							<div class="text-muted fs-10px">1 hour ago</div>
						</div>
					</a>
					<a href="javascript:;" class="dropdown-item media">
						<div class="media-left">
							<i class="fa fa-envelope media-object bg-gray-500"></i>
							<i class="fab fa-google text-warning media-object-icon fs-14px"></i>
						</div>
						<div class="media-body">
							<h6 class="media-heading"> New Email From John</h6>
							<div class="text-muted fs-10px">2 hour ago</div>
						</div>
					</a>
					<div class="dropdown-footer text-center">
						<a href="javascript:;" class="text-decoration-none">View more</a>
					</div>
				</b-nav-item-dropdown>
				<b-nav-item-dropdown class="navbar-item" toggle-class="navbar-link dropdown-toggle" no-caret  v-if="appOptions.appHeaderLanguageBar">
					<template slot="button-content">
						<span class="flag-icon flag-icon-us me-1" title="us"></span>
						<span class="name d-none d-sm-inline me-1">EN</span> <b class="caret"></b>
					</template>
					<b-dropdown-item href="javascript:;"><span class="flag-icon flag-icon-us" title="us"></span> English</b-dropdown-item>
					<b-dropdown-item href="javascript:;"><span class="flag-icon flag-icon-cn" title="cn"></span> Chinese</b-dropdown-item>
					<b-dropdown-item href="javascript:;"><span class="flag-icon flag-icon-jp" title="jp"></span> Japanese</b-dropdown-item>
					<b-dropdown-item href="javascript:;"><span class="flag-icon flag-icon-be" title="be"></span> Belgium</b-dropdown-item>
					<b-dropdown-divider class="m-b-0"></b-dropdown-divider>
					<b-dropdown-item href="javascript:;" class="text-center">more options</b-dropdown-item>
				</b-nav-item-dropdown>
				<b-nav-item-dropdown menu-class="dropdown-menu-end me-1" class="navbar-item navbar-user dropdown" toggle-class="navbar-link dropdown-toggle d-flex align-items-center" no-caret>
					<template slot="button-content">
						<img src="/assets/img/user/avatar.png" alt="" />
						<span class="d-none d-md-inline">{{pageUserSettings.Nombre}}</span> <b class="caret"></b>
					</template>
					<a href="javascript:;" class="dropdown-item"  v-b-modal.modalPasswordDialog  @click="getUsusarioContrasena()">Cambiar Contraseña</a>
					<!-- <a href="javascript:;" class="dropdown-item"><span class="badge bg-danger float-end rounded-pill">2</span> Inbox</a>
					<a href="javascript:;" class="dropdown-item">Calendar</a>
					<a href="javascript:;" class="dropdown-item">Setting</a> -->
					<div class="dropdown-divider"></div>
					<a href="javascript:;" class="dropdown-item" v-on:click="handleClick">Cerrar Sesión</a>
				</b-nav-item-dropdown>
				<div class="navbar-divider d-none d-md-block" v-if="appOptions.appSidebarTwo"></div>
				<div class="navbar-item d-none d-md-block" v-if="appOptions.appSidebarTwo">
					<a href="javascript:;" v-on:click="toggleSidebarEnd" class="navbar-link icon">
						<i class="fa fa-th"></i>
					</a>
				</div>
			</div>
			<!-- end header navigation right -->
		</div>
		<!-- end #header -->

		
	<!-- #modal-dialog -->
	<b-modal id="modalPasswordDialog">
		<template slot="modal-header">
			<h4 class="modal-title">Actualizar Contraseña</h4>
			<a class="btn-close" @click="$bvModal.hide('modalPasswordDialog')"></a>
		</template>
    
					<div>
						<form action="/" method="POST">
							<fieldset>
								<div class="mb-3">
									<label class="form-label" for="txtNoEmpleado">Número de empleado</label>
									<input class="form-control" type="text" id="txtNoEmpleado" placeholder="Número de empleado" :disabled="true" v-model="pageUserSettings.NumeroEmpleado"/>
								</div>
								<div class="mb-3">
									<label class="form-label" for="txtContrasena">Contraseña</label>
									<input class="form-control" type="password" id="txtContrasena" placeholder="Contrasena"  v-model="txtContrasena"/>
								</div>
									<!-- <div class="form-check form-switch mb-2">
										<input class="form-check-input" type="checkbox" id="flexSwitchCheckCheckedNIP" v-model="actualizarNIP">
										<label class="form-check-label" for="flexSwitchCheckCheckedNIP">Cambiar NIP?</label>
									</div>
								<div class="mb-3">
									<label class="form-label" for="txtNIP">NIP</label>
									<input class="form-control" type="number" id="txtNIP" placeholder="NIP" :disabled="!actualizarNIP"  v-model="clienteModal.NIP"/>
								</div> -->

                
                

							</fieldset>
						</form>
					</div>
          
      <template slot="modal-footer">
          <button type="button" class="btn btn-primary mr-auto" @click="savePassword()">{{textoBotonGuardar}}</button>
          <button type="button" class="btn btn-secondary" @click="$bvModal.hide('modalPasswordDialog')">Cancelar</button>
      </template>
	</b-modal>

	</div>
</template>

<script>
// import {mapGetters} from 'vuex'
import axios from "axios";
import AppOptions from '../../config/AppOptions.vue'
import HeaderMegaMenu from './HeaderMegaMenu.vue'

export default {
	created() {
		var userSetting = localStorage.getItem('userSettings');
		if(userSetting != undefined){
		// console.log(JSON.parse(userSetting));
		this.pageUserSettings = JSON.parse(userSetting);
		}
		// else{
		// 	this.$router.push('/login');
		// }
	},
  name: 'Header',
	components: {
		HeaderMegaMenu
	},
  data() {
	return {
		txtContrasena:"",
		textoBotonGuardar:"Guardar",
		clienteModal:{},
		pageUserSettings: {},
		appOptions: AppOptions
	}
  },
	methods: {
			show (group, type = '') {
				const text = this.alertMessage;
				this.$notify({
					group,
					title: this.alertTitle,
					text,
					type,
					data: {
						randomNumber: Math.random()
					}
				})
			},
			clean (group) {
				this.$notify({ group, clean: true })
			},
			swalNotification(swalType) {
				var btnClass = (swalType == 'error') ? 'danger' : swalType;
					btnClass = (swalType == 'question') ? 'primary' : btnClass;
				this.$swal({
					title: 'Are you sure?',
					text: 'You will not be able to recover this imaginary file!',
					type: swalType,
					showCancelButton: true,
					buttonsStyling: false,
					confirmButtonText: swalType.charAt(0).toUpperCase() + swalType.substr(1).toLowerCase(),
					cancelButtonText: 'Cancel',
					confirmButtonClass: 'btn me-5px btn-'+ btnClass +'',
					cancelButtonClass: 'btn btn-default',
				})
			},
    showNotification(title,text,type){
      this.alertTitle = title;
      this.alertMessage = text;
      this.show("top-right", type);
    },
    async savePassword() {
      
      this.textoBotonGuardar = "Guardando...";
      if(this.txtContrasena == ""){
          this.showNotification("Validacion","Capture la contraseña","warning");
          this.textoBotonGuardar = "Guardar";
          return;
        }
        try {
			var dataToSend =  {"Data":{
				"NumeroEmpleado":this.pageUserSettings.NumeroEmpleado,
				"EmpresaTress":this.pageUserSettings.EmpresaTress,
				"Pass":this.txtContrasena,
				"NIP":"",			
				"ActualizarNIP":false
				}
			};
			console.log(JSON.stringify(dataToSend));
          const response = await axios.post("Colaborador/UpdPassword", dataToSend );
          console.log('response guardar'+response.data.response);
          if (response.data.Success) {
              this.alertMessage = "Se actualizó la contraseña";
              this.alertTitle = "Guardado con exito";
              this.show("top-right", "success");
				this.txtContrasena = "";
              this.$bvModal.hide('modalPasswordDialog');
          } else {
            this.alertMessage = response.data.Error;
            this.alertTitle = "Error";
            this.show("top-right", "error");
          }
          this.textoBotonGuardar = "Guardar";
        } catch (e) {
            this.alertMessage = "Ocurrio un error";
            this.alertTitle = "Error";
            this.show("top-right", "error");
        }
        
          this.textoBotonGuardar = "Guardar";
    },
		getUsusarioContrasena(){
			this.clienteModal.NumeroEmpleado = this.pageUserSettings.NumeroEmpleado;
			this.clienteModal.EmpresaTress = this.pageUserSettings.EmpresaTress;
			this.clienteModal.Pass = "";
			this.clienteModal.ActualizarNIP = false;
			console.log(this.pageUserSettings.NumeroEmpleado)
		},
		handleClick(){
			//forLogin
			// localStorage.removeItem('token');
			// this.$store.dispatch('user', null);
			// this.$router.push('/login').catch(()=>{});
			
			localStorage.removeItem('userSettings');
			localStorage.removeItem('parametrosCuadre');
			this.$router.push('/login').catch(()=>{});

		},
		toggleSidebarMobile() {
			this.appOptions.appSidebarMobileToggled = !this.appOptions.appSidebarMobileToggled;
		},
		toggleSidebarEnd() {
			this.appOptions.appSidebarEndToggled = !this.appOptions.appSidebarEndToggled;
		},
		toggleSidebarEndMobile() {
			this.appOptions.appSidebarEndMobileToggled = !this.appOptions.appSidebarEndMobileToggled;
		},
		toggleTopMenuMobile() {
			this.appOptions.appTopMenuMobileToggled = !this.appOptions.appTopMenuMobileToggled;
		},
		toggleHeaderMegaMenuMobile() {
			this.appOptions.appHeaderMegaMenuMobileToggled = !this.appOptions.appHeaderMegaMenuMobileToggled;
		},
		checkForm: function(e) {
			e.preventDefault();
			this.$router.push({ path: '/extra/search' })
		}
	},
	computed: {
		//...mapGetters(['user'])
	}
}
</script>
