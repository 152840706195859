<template>
  <div>

    <div class=" loading-search" v-if="loading">
      <div class="fa-3x text-center">
        <i class="fas fa-circle-notch fa-spin text-success" style=""></i>
      </div>
    </div>
    <!-- BEGIN breadcrumb -->
    <ol class="breadcrumb float-xl-end">
      <li class="breadcrumb-item"><a href="javascript:;">Reportes</a></li>
      <li class="breadcrumb-item"><a href="javascript:;">Compras</a></li>
      <li class="breadcrumb-item active"><a href="javascript:;">Get Pedidos</a></li>
    </ol>

    <h1 class="page-header">Pedidos <small></small></h1>



    <form @submit.prevent="search">
      <div class="row">
        <div class="col-md-8">
          <div class="form-group row">

            <div class="col-md-3">
              <label class="col-form-label">Fecha inicial</label>
              <div class="input-group">
                <date-picker id="dateTimeInput" v-model="date" :config="options"></date-picker>
                <label for="dateTimeInput" class="input-group-text">
                  <i class="fa fa-calendar"></i>
                </label>
              </div>
            </div>


            <div class="col-md-3">
              <label class="col-form-label">Fecha final</label>
              <div class="input-group">
                <date-picker id="dateTimeInputEnd" v-model="dateEnd" :config="options"></date-picker>
                <label for="dateTimeInputEnd" class="input-group-text">
                  <i class="fa fa-calendar"></i>
                </label>
              </div>
            </div>
            <div class="col-md-3">
              <label class="col-form-label">Ciudad</label>
              <select class="form-select" @change="getEstaciones()" v-model="ciudadSelect"
                :disabled="deshabilitarCombos">
                <option v-for="ciudad in ciudades" :value="ciudad.Id" v-bind:key="ciudad.Id">
                  {{ ciudad.Nombre }}
                </option>
              </select>
            </div>
            <div class="col-md-3">
              <label class="col-form-label">Estación</label>
              <select class="form-select" v-model="estacionSelect" :disabled="deshabilitarCombos">
                <!-- <option value="0">Todas</option> -->
                <option v-for="estacion in estaciones" :value="estacion.NoEstacion" v-bind:key="estacion.NoEstacion">
                  {{ estacion.Nombre }}
                </option>
              </select>
            </div>

          </div>
        </div>
        <div class="col-md-2">
          <button type="submit" class="btn btn-success me-1 mb-1" style="margin-top: 3.1em;">{{ textoBotonBuscar
            }}</button>
        </div>
        <div class="col-md-2" style="padding-top: 2.7em;">
          <!-- <download-excel
                  v-if="searchResult.length > 0"
                  class="btn btn-success rounded-pill mt-35"
                  :data="searchResult"
                  :fields="json_fields"
                  :worksheet="estacionSelect"
                  :name="`Compras CG ${date}.xls`"
                  
                  >
                    Descargar en Excel <i class="fas fa-file-excel fa-fw"></i>
                </download-excel>
                <button v-else disabled class="btn btn-success rounded-pill mt-35">
                    Descargar en Excel <i class="fas fa-file-excel fa-fw"></i>
                </button> -->
        </div>
      </div>
    </form>
    <div>

      <!-- <div>
                <div v-for="(group, Fecha) in groupedObjects" :key="Fecha">
                <h2>{{ Fecha }}</h2>
                <ul>
                    <li v-for="obj in group" :key="obj.NoEstacion">{{ obj.NoEstacion }}</li>
                </ul>
                </div>
            </div> -->



      <panel title="Pedidos" bodyClass="p-0" class="" style="margin-top: 15px;">





        <DxDataGrid id="gridContainer" :data-source="searchResult" key-expr="UUID" :show-borders="true"
          @exporting="onExporting" :show-column-lines="false" :show-row-lines="false" :row-alternation-enabled="true"
          :column-width="100">

          <DxScrolling column-rendering-mode="virtual" />
          <DxPager :show-page-size-selector="true" :allowed-page-sizes="[10, 20, 50, 100]"
            :show-navigation-buttons="true" />
          <DxPaging :page-size="10" :page-index="1" />
          <DxFilterRow />
          <DxHeaderFilter :visible="showHeaderFilter" />
          <DxSearchPanel :visible="true" :width="240" placeholder="Search..." />

          <DxGroupPanel :visible="true" />
          <DxGrouping :auto-expand-all="true" />
          <DxExport :enabled="true" :allow-export-selected-data="false" />
          <DxSelection mode="false" />

          <DxColumn caption="Acciones" cell-template="acciones" />

          <template #acciones="{ data }">
            <div>
              <i class="fas fa-lg fa-fw  fa-file-pdf iconColor btn" v-on:click="obtenerAcusePDFCall(data.data)"
                title="Abrir"></i>
              &nbsp;

              <i v-if="data.data.CompraCG == 0 || data.data.CompraCG == '' || data.data.CompraCG == null"
                class="icon fa fa-clipboard-check btn iconColorCheck" v-on:click="SetCompra(data.data)"
                title="Registrar Compra"></i>
            </div>
          </template>

          <DxColumn data-field="CompraCG" caption="Compra">
            <DxHeaderFilter :allow-search="true" />
          </DxColumn>

          <DxColumn data-field="Estacion" caption="Estación" width="150" />
          <DxColumn data-field="NombreTransportista" caption="Nombre Transportista" width="150" />
          <DxColumn data-field="PermisoCRE" caption="Permiso CRE" width="200" />
          <DxColumn data-field="PermisoTransportista" caption="Permiso Transportista" width="200" />
          <DxColumn data-field="PlacasVehiculo" caption="Placas Vehículo" width="150" />
          <DxColumn data-field="Referencia" caption="Referencia" width="150" />
          <DxColumn data-field="Remision" caption="Remision" width="150" />
          <DxColumn data-field="UUID" caption="UUID" width="280" />




          <DxColumn data-field="Total" caption="Total" dataType="number" width="170">
            <DxFormat type="currency" :precision="2" />
          </DxColumn>

        </DxDataGrid>
        <b-modal id="modalDialogVisor" size="xl" centered cancel-variant="default">
          <template slot="modal-header">
            <h4 class="modal-title">Factura</h4>
            <a class="btn-close" @click="$bvModal.hide('modalDialogVisor')"></a>
          </template>
          <div class="card">
            <object type="application/pdf" :data="Archivo.base64" width="100%" height="600px"></object>
          </div>

          <template #modal-footer>
            <div class="row">
              <div class="col">
                <b-button class="float-right" variant="primary" @click="$bvModal.hide('modalDialogVisor')">
                  Cerrar
                </b-button>
              </div>
            </div>
          </template>

        </b-modal>

      </panel>


    </div>

    <!-- bottom left vue-notification -->
    <notifications group="top-right" position="top right" :speed="1000" />
  </div>
</template>

<script>

import axios from 'axios';
import moment from 'moment';



import {
  DxDataGrid, DxFormat,
  DxPaging, DxPager, DxScrolling, DxColumn, DxExport, DxSelection, DxGroupPanel, DxGrouping, DxHeaderFilter, DxFilterRow, DxSearchPanel,
} from 'devextreme-vue/data-grid';
import { Workbook } from 'exceljs';
import { saveAs } from 'file-saver';
// Our demo infrastructure requires us to use 'file-saver-es'. We recommend that you use the official 'file-saver' package in your applications.
import { exportDataGrid } from 'devextreme/excel_exporter';
//import service from './data.js';


var numeral = require("numeral");
import Vue from "vue";

import JsonExcel from "vue-json-excel";
Vue.component("downloadExcel", JsonExcel);
Vue.filter("formatNumber", function (value) {
  return numeral(value).format("0,00.00"); // displaying other groupings/separators is possible, look at the docs
});

Vue.filter("currency", function (value) {
  return numeral(value).format("$0,0.00");
});


export default {
  mounted: function () {
    /* eslint-disable no-debugger */
    debugger;
    var self = this;

    var userSetting = JSON.parse(localStorage.getItem('userSettings'));
    this.usuario = userSetting.Usuario;
    this.usuarioEstacion = userSetting.Estacion;
    this.IdRol = userSetting.IdRol;
    this.getCiudades();
    this.ciudadSelect = userSetting.CiudadId;
    this.getEstaciones();
    this.estacionSelect = userSetting.Estacion;



    // debugger; // eslint-disable-line no-debugger
    // var dateIn = self.$route.params.fechaIn;
    // var fechaFin = self.$route.params.fechaFin;
    // var ciudad = self.$route.params.ciudad;
    // var estacion = self.$route.params.estacion;
    // if(dateIn != undefined){
    //   self.date = moment(dateIn).format("YYYY/MM/DD");
    //   self.dateEnd = moment(fechaFin).format("YYYY/MM/DD");
    //   self.ciudadSelect = ciudad;
    //   self.estacionSelect = estacion;

    // }

    if (this.IdRol == 3 || this.IdRol == 7) {
      this.deshabilitarCombos = true;
    }

  },
  beforeCreate() {

  },
  created() {
    // console.log('created');
  },
  components: {
    DxDataGrid, DxFormat, DxPaging, DxPager, DxScrolling, DxColumn, DxExport, DxSelection, DxGroupPanel, DxGrouping, DxHeaderFilter, DxFilterRow, DxSearchPanel,
  },
  beforeMount() {
    // console.log('beforeMount');
  },
  computed: {
  },
  methods: {
    onExporting(e) {
      const workbook = new Workbook();
      const worksheet = workbook.addWorksheet('Pedidos');

      exportDataGrid({
        component: e.component,
        worksheet: worksheet,
        autoFilterEnabled: true
      }).then(() => {
        workbook.xlsx.writeBuffer().then((buffer) => {
          saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'Pedidos.xlsx');
        });
      });
      e.cancel = true;
    },
    async getCiudades() {
      try {
        const response = await axios.get('Catalogos/GetCiudades');
        if (response.data.Success) {
          if (response.data.response.length == 0) {
            this.alertMessage = "No se encontró información.";
            this.alertTitle = "Error";
            this.show('top-right', 'error');
          }
        } else {
          console.log("error")
          this.alertMessage = response.data.Error;
          this.alertTitle = "Error";
          this.show('top-right', 'error');
        }
        this.ciudades = response.data.response;
      } catch (e) {
        //this.alert = true;
        //this.error = 'Invalid email/password';
      }
    },
    async getEstaciones() {
      try {
        const response = await axios.post('Estacion/GetEstaciones?ciudad=' + this.ciudadSelect);
        if (response.data.Success) {
          if (response.data.response.length == 0) {
            this.alertMessage = "No se encontró información.";
            this.alertTitle = "Error";
            this.show('top-right', 'error');
          }
        } else {
          console.log("error")
          this.alertMessage = response.data.Error;
          this.alertTitle = "Error";
          this.show('top-right', 'error');
        }
        this.estaciones = response.data.response;

        debugger; // eslint-disable-line no-debugger
        // this.productos = [];
        // this.getProductos();
      } catch (e) {
        //this.alert = true;
        //this.error = 'Invalid email/password';
      }
    },
    show(group, type = '') {
      const text = this.alertMessage;
      this.$notify({
        group,
        title: this.alertTitle,
        text,
        type,
        data: {
          randomNumber: Math.random()
        }
      })
    },
    clean(group) {
      this.$notify({ group, clean: true })
    },
    swalNotification(swalType) {
      var btnClass = (swalType == 'error') ? 'danger' : swalType;
      btnClass = (swalType == 'question') ? 'primary' : btnClass;
      this.$swal({
        title: 'Are you sure?',
        text: 'You will not be able to recover this imaginary file!',
        type: swalType,
        showCancelButton: true,
        buttonsStyling: false,
        confirmButtonText: swalType.charAt(0).toUpperCase() + swalType.substr(1).toLowerCase(),
        cancelButtonText: 'Cancel',
        confirmButtonClass: 'btn me-5px btn-' + btnClass + '',
        cancelButtonClass: 'btn btn-default',
      })
    },

    async search(e) {
      debugger; // eslint-disable-line no-debugger
      var self = this;
      e.preventDefault();


      self.textoBotonBuscar = "Buscando...";
      self.loading = true;
      try {
        // self.intDate = self.date.replace('/', '');
        // self.intDate = self.intDate.replace('/', '');


        // self.intDateEnd = self.dateEnd.replace('/', '');
        // self.intDateEnd = self.intDateEnd.replace('/', '');

        var ciudad = self.ciudadSelect != undefined ? self.ciudadSelect : 0;
        var estacion = self.estacionSelect != undefined ? self.estacionSelect : 0;


        const startDate = moment(self.date).format('YYYY-MM-DD');
        const endDate = moment(self.dateEnd).format('YYYY-MM-DD');



        // var gasolinera = 0;
        // for(var es = 0 ; es < self.estaciones.length ; es++){
        //     if(estacion == self.estaciones[es].NoEstacion){
        //         gasolinera = self.estaciones[es].Gasolinera;
        //         break;
        //     }
        // }
        debugger; // eslint-disable-line no-debugger

        // var dataToSend = {
        //   "Data": {
        //       "FechaInicial": self.intDate,
        //       "FechaFinal":self.intDateEnd,
        //       "Ciudad": ciudad, 
        //       "Gasolinera": gasolinera,

        //   }
        // };

        // var formated = 'E00000'; 
        // if(estacion.toString().length == 4){
        //   formated = 'E0'+estacion;
        // }
        // if(estacion.toString().length == 5){
        //   formated = 'E'+estacion;
        // }

        var dataToSend = {
          "Data": {
            "FechaInicio": startDate,
            "FechaFin": endDate,
            "Estacion": estacion
            // "Estacion":"E07025"
          }
        }



        const response = await axios.post('Binarapp/GetPedidos', dataToSend);
        JSON.stringify(response.data)
        self.loading = false;
        self.textoBotonBuscar = "Buscar";
        if (response.data.Success) {
          if (response.data.response.Pedidos.length == 0) {
            self.alertMessage = "No se encontró información del día especificado.";
            self.alertTitle = "Error";
            self.show('top-right', 'error');
          } else {

            debugger; // eslint-disable-line no-debugger
            // var respuesta = response.data.response.Detalle;
            //self.RespuestaCompras = response.data.response.Pedidos;
            self.searchResult = response.data.response.Pedidos;

          }


        } else {
          console.log("error")
          self.alertMessage = response.data.Message;
          self.alertTitle = response.data.Error;
          self.show('top-right', 'error');
        }





        self.loading = false;
      } catch (e) {
        self.loading = false;
        //this.alert = true;
        //this.error = 'Invalid email/password';
      }
    },
    async obtenerAcusePDFCall(data) {
      var self = this;
      self.loading = true;
      self.Archivo = [];
      try {

        var dataToSend = {
          "Data": {
            "UUID": data.UUID,
            "Tipo": "venta"
          },
        };
        const response = await axios.post("Binarapp/GetFactura", dataToSend);

        self.loading = false;
        if (response.data.Success) {

          this.alertTitle = "";

          self.Archivo.base64 = "data:application/pdf;base64," + response.data.response.PDF;

          self.$bvModal.show('modalDialogVisor');

        }
        else {

          this.alertMessage = response.data.Message;
          this.alertTitle = "Error";
          this.show("top-right", "error");
        }

      } catch (e) {
        self.loading = false;
        self.textoBotonEnviar = "Enviar";
        this.alertMessage = "Ocurrio un error";
        this.alertTitle = "Error";
        this.show("top-right", "error");
      }
    },
    async SetCompra(data) {
      var self = this;
      self.loading = true;

      this.$swal({
        title: "Aviso",
        text: "Desea Registrar la Compra!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Si, registrar!"
      }).then(async (result) => {
        if (result.isConfirmed) {

          try {

            var dataToSend = {
              "Data": {
                "NombreTransportista": data.NombreTransportista,
                "PermisoCRE": data.PermisoCRE,
                "PermisoTransportista": data.PermisoTransportista,
                "PlacasVehiculo": data.PlacasVehiculo,
                "Referencia": data.Referencia,
                "Remision": data.Remision,
                "UUID": data.UUID
              }
            };

            const response = await axios.post("Binarapp/SetCompra", dataToSend);

            self.loading = false;
            if (response.data.Success) {

              this.alertMessage = response.data.Message;
              this.alertTitle = "";
              this.show("top-right", "success");
            } else {

              this.alertMessage = response.data.Message;
              this.alertTitle = "Error";
              this.show("top-right", "error");
            }
          } catch (e) {
            self.loading = false;
            self.textoBotonEnviar = "Enviar";
            this.alertMessage = "Ocurrio un error";
            this.alertTitle = "Error";
            this.show("top-right", "error");
          }
        } else {
          self.loading = false;
        }
      });
    },
  },
  data() {
    return {
      showHeaderFilter: true,
      RespuestaCompras: [],
      loading: false,
      deshabilitarCombos: false,
      ciudades: [],
      estaciones: [],
      ciudadSelect: 1,
      estacionSelect: 0,
      date: moment().startOf('month').format("YYYY/MM/DD"),
      intDateEnd: "",
      dateEnd: moment().format("YYYY/MM/DD"),
      intDate: "",
      options: {
        format: 'YYYY/MM/DD',
        useCurrent: false,
      },
      pageUserSettings: {},
      alertMessage: "",
      alertTitle: "",
      searchResult: [],
      textoBotonBuscar: "Buscar",
      columns: [
        {
          label: "No Estación",
          field: "NoEstacion",
          tdClass: "text-nowrap",
          thClass: "text-nowrap",
        },
        {
          label: "Estación",
          field: "Estacion",
          tdClass: "text-nowrap",
          thClass: "text-nowrap",
        },
        {
          label: "Número",
          field: "Numero",
          tdClass: "text-nowrap",
          thClass: "text-nowrap",
        },
        {
          label: "Factura",
          field: "Factura",
          tdClass: "text-nowrap",
          thClass: "text-nowrap",
        },
        {
          label: "Remisión",
          field: "Remision",
          tdClass: "text-nowrap",
          thClass: "text-nowrap",
        },
        {
          label: "Fecha",
          field: "FechaString",
          tdClass: "text-center text-nowrap",
          thClass: "text-nowrap",
        },
        // {
        //     label: "Codigo del producto",
        //     field: "CodigoProducto",
        //     tdClass: "text-nowrap",
        //     thClass: "text-nowrap",
        // },
        {
          label: "Producto",
          field: "Producto",
          tdClass: "text-nowrap",
          thClass: "text-nowrap",
        },
        {
          label: "Recibido",
          field: "Recibido",
          type: "number",
          tdClass: "text-nowrap",
          thClass: "text-nowrap",
        },
        {
          label: "Facturado",
          field: "Facturado",
          type: "number",
          tdClass: "text-nowrap",
          thClass: "text-nowrap",
        },
        {
          label: "Importe",
          field: "Importe",
          type: "number",
          tdClass: "text-nowrap",
          thClass: "text-nowrap",
        },
        {
          label: "IEPS",
          field: "IEPS",
          type: "number",
          tdClass: "text-nowrap",
          thClass: "text-nowrap",
        },
        {
          label: "IVA",
          field: "IVA",
          type: "number",
          tdClass: "text-nowrap",
          thClass: "text-nowrap",
        },
        {
          label: "Recargos",
          field: "Recargos",
          type: "number",
          tdClass: "text-nowrap",
          thClass: "text-nowrap",
        },
        {
          label: "Descuentos",
          field: "Descuentos",
          type: "number",
          tdClass: "text-nowrap",
          thClass: "text-nowrap",
        },
        {
          label: "Retenciones",
          field: "Retenciones",
          type: "number",
          tdClass: "text-nowrap",
          thClass: "text-nowrap",
        },
        {
          label: "UUID",
          field: "UUID",
          tdClass: "text-nowrap",
          thClass: "text-nowrap",
        },
        {
          label: "Referencia",
          field: "Referencia",
          tdClass: "text-nowrap",
          thClass: "text-nowrap",
        },
      ],
      json_fields: {
        "Numero": "Numero",
        "Factura": "Factura",
        "Remision": "Remision",
        "Fecha": "Fecha",
        "Codigo Producto": "CodigoProducto",
        "Producto": "Producto",
        "Recibido": "Recibido",
        "Facturado": "Facturado",
        "Importe": "Importe",
        "IEPS": "IEPS",
        "IVA": "IVA",
        "Recargos": "Recargos",
        "Descuentos": "Descuentos",
        "Retenciones": "Retenciones",
        "UUID": "UUID",
        "Referencia": "Referencia",
      },
      Archivo: {
        base64: '',
      },

    };
  }
}
</script>
<style scoped>
.dx-datagrid-header-panel {
  display: none !important;
}

.loading-search {
  position: absolute;
  width: 100%;
  left: 0px;
  height: 100%;
  background: rgba(0, 0, 0, 0.1);
  z-index: 1000;
  padding-top: 150px;
  top: 0;
}

.sumatoriasRow {
  background-color: #bbb;
}

.currencyRow {
  text-align: right !important;
}

.vgt-fixed-header {
  overflow-y: auto;
}

.vgt-table__header {
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: #f5f5f5;
}

.iconColor {
  color: #f70909;
  font-size: 20px;
}

.iconColorCheck {
  color: #00acac;
  font-size: 20px;
}
</style>