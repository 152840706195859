<template>
    <div>
      <div class=" loading-search" v-if="loading">
        <div class="fa-3x text-center">
          <i class="fas fa-circle-notch fa-spin text-success" style=""></i>
        </div>
      </div>
      <!-- BEGIN breadcrumb -->
      <ol class="breadcrumb float-xl-end">
        <li class="breadcrumb-item"><a href="javascript:;">Catalogos</a></li>
        <li class="breadcrumb-item active">
          <a href="javascript:;">Asignar Horarios</a>
        </li>
      </ol>
      <!-- END breadcrumb -->
      <!-- BEGIN page-header -->
      <h1 class="page-header">Asignar Horarios</h1>
      <!-- END page-header -->
  
  
      <panel title="Asignación Horarios" bodyClass="p-0" class="">
        <div class="row" style="margin-top: 15px;">
          <div class="col-md-4">
            <div class="row">
              <div class="col-4">
                  <label class="form-label" for="txtCiudad">Ciudad</label>
                  <select class="form-select" @change="getEstaciones()" v-model="ciudadSelect" :disabled="deshabilitarCombos">
                      <option v-for="ciudad in ciudades" :value="ciudad.Id" v-bind:key="ciudad.Id">
                          {{ ciudad.Nombre }}
                      </option>
                  </select>
              </div>
              <div class="col-4">
                    <label class="form-label" for="txtEstacion">Estación</label>
                    <select class="form-select" v-model="estacionSelect" :disabled="deshabilitarCombos">
                        <option v-for="estacion in estaciones" :value="estacion.NoEstacion" v-bind:key="estacion.NoEstacion">
                        {{ estacion.Nombre }}
                        </option>
                    </select>
              </div>
              <div class="col-4" style="padding-top: 1.65em;">
                
                <button class="btn btn-primary" @click="getAsignaciones()">Buscar</button>
                
              </div>

            </div>

          </div>
          <div class="col-md-8">
            <div class="row">
              <div class="col-8 text-center" style="padding-top: 1.65em;">
                    <div class="btn-group">
                      <button class="btn btn-white" @click="cambiarSemana(-1)"><i class="fas fa-lg fa-fw fa-angle-double-left"></i> Semana Anterior</button>
                      <button class="btn btn-white" @click="cambiarSemana(0)">Semana Actual</button>
                      <button class="btn btn-white" @click="cambiarSemana(+1)">Semana Próxima <i class="fas fa-lg fa-fw fa-angle-double-right"></i></button>
                    </div>
                  <p>Semana del: {{ fechaInicial }} al {{ fechaFinal }}</p>
                  
              </div>
              <div class="col-4" style="padding-top: 1.65em;">
                <button class="btn btn-primary" @click="guardarCambiosTodo()" :disabled="!cambiosPendientes">Guardar Cambios</button>
                <p v-if="cambiosPendientes">Hay cambios pendientes por guardar</p>
              </div>

            </div>

          </div>
            
        </div>
          <div class="row">
              <div class="col-4" style="border-right: 1px solid lightgray;">
                  <h3>Colaboradores</h3>
                  <vue-good-table
                      :columns="columns"
                      :rows="venderores"
                      :lineNumbers="false"
                      :search-options="{ enabled: true, placeholder: 'Buscar' }"
                      :pagination-options="{ enabled: true, position: 'bottom' }"
                  >
                      <template slot="table-row" slot-scope="props">
                      <span v-if="props.column.label == 'Acciones'">
                          <button
                            v-b-modal.modalDialog
                            class="btn btn-xs btn-success"
                            @click="seleccionarHorario(props.row, true)"
                          >
                          Seleccionar
                          </button>
                          
                      </span>
                      </template>
                  </vue-good-table>
              </div>


             
              
              <div class="col-8">
                  <h3>Turnos</h3>
                  <vue-good-table
                      :columns="columnsAsignados"
                      :rows="asignaciones"
                      :lineNumbers="false"
                      :search-options="{ enabled: true, placeholder: 'Buscar' }"
                      :pagination-options="{ enabled: false, position: 'bottom' }"
                  >
                      <template slot="table-row" slot-scope="props">
                        <span v-if="props.column.label == 'Cambiar'">
                            <button
                              class="btn btn-xs btn-success"
                              v-b-modal.modalDialog
                              @click="seleccionarHorario(props.row, false)"
                              >
                              Cambiar Horario
                            </button>
                        </span>
                        
                        <span v-else-if="props.column.field == 'Lunes'">
                          <span v-if="props.formattedRow[props.column.field] == '11'">1</span>
                          <span v-if="props.formattedRow[props.column.field] == '21'">2</span>
                          <span v-if="props.formattedRow[props.column.field] == '31'">3</span>
                          <span v-if="props.formattedRow[props.column.field] == 'D'">D</span>
                        </span>
                        <span v-else-if="props.column.field == 'Martes'">
                          <span v-if="props.formattedRow[props.column.field] == '11'">1</span>
                          <span v-if="props.formattedRow[props.column.field] == '21'">2</span>
                          <span v-if="props.formattedRow[props.column.field] == '31'">3</span>
                          <span v-if="props.formattedRow[props.column.field] == 'D'">D</span>
                        </span>
                        <span v-else-if="props.column.field == 'Miercoles'">
                          <span v-if="props.formattedRow[props.column.field] == '11'">1</span>
                          <span v-if="props.formattedRow[props.column.field] == '21'">2</span>
                          <span v-if="props.formattedRow[props.column.field] == '31'">3</span>
                          <span v-if="props.formattedRow[props.column.field] == 'D'">D</span>
                        </span>
                        <span v-else-if="props.column.field == 'Jueves'">
                          <span v-if="props.formattedRow[props.column.field] == '11'">1</span>
                          <span v-if="props.formattedRow[props.column.field] == '21'">2</span>
                          <span v-if="props.formattedRow[props.column.field] == '31'">3</span>
                          <span v-if="props.formattedRow[props.column.field] == 'D'">D</span>
                        </span>
                        <span v-else-if="props.column.field == 'Viernes'">
                          <span v-if="props.formattedRow[props.column.field] == '11'">1</span>
                          <span v-if="props.formattedRow[props.column.field] == '21'">2</span>
                          <span v-if="props.formattedRow[props.column.field] == '31'">3</span>
                          <span v-if="props.formattedRow[props.column.field] == 'D'">D</span>
                        </span>
                        <span v-else-if="props.column.field == 'Sabado'">
                          <span v-if="props.formattedRow[props.column.field] == '11'">1</span>
                          <span v-if="props.formattedRow[props.column.field] == '21'">2</span>
                          <span v-if="props.formattedRow[props.column.field] == '31'">3</span>
                          <span v-if="props.formattedRow[props.column.field] == 'D'">D</span>
                        </span>
                        <span v-else-if="props.column.field == 'Domingo'">
                          <span v-if="props.formattedRow[props.column.field] == '11'">1</span>
                          <span v-if="props.formattedRow[props.column.field] == '21'">2</span>
                          <span v-if="props.formattedRow[props.column.field] == '31'">3</span>
                          <span v-if="props.formattedRow[props.column.field] == 'D'">D</span>
                        </span>
                        
                      </template>
                  </vue-good-table>
              </div>
  
          </div>
      </panel>
  




      
    <b-modal id="modalDialog">
      <template slot="modal-header">
        <h4 class="modal-title">Cambiar Horario</h4>
        <a class="btn-close" @click="$bvModal.hide('modalDialog')"></a>
      </template>

      <div>
        <form action="/" method="POST">
          <fieldset>
            <div class="mb-3">
              <label class="form-label" for="txtNombre">Nombre</label>
              <input
                class="form-control"
                type="text"
                id="txtNombre"
                placeholder="Nombre"
                :disabled="true"
                v-model="colaboradorModal.Nombre"
              />
            </div>




            <div class="mb-3">
              <label class="form-label" for="ddlLunes">Lunes</label>
              <select name="" id="ddlLunes" class="form-control" @change="handleSelectChange" style="width: 150px;" v-model="colaboradorModal.Lunes">
                <option value="11">1</option>
                <option value="21">2</option>
                <option value="31">3</option>
                <option value="D">D</option>
              </select>
            </div>

            <div class="mb-3">
              <label class="form-label" for="ddlMartes">Martes</label>
              <select name="" id="ddlMartes" class="form-control" @change="handleSelectChange" style="width: 150px;" v-model="colaboradorModal.Martes">
                <option value="11">1</option>
                <option value="21">2</option>
                <option value="31">3</option>
                <option value="D">D</option>
              </select>
            </div>

            <div class="mb-3">
              <label class="form-label" for="ddlMiercoles">Miercoles</label>
              <select name="" id="ddlMiercoles" class="form-control" @change="handleSelectChange" style="width: 150px;" v-model="colaboradorModal.Miercoles">
                <option value="11">1</option>
                <option value="21">2</option>
                <option value="31">3</option>
                <option value="D">D</option>
              </select>
            </div>

            <div class="mb-3">
              <label class="form-label" for="ddlJueves">Jueves</label>
              <select name="" id="ddlJueves" class="form-control" @change="handleSelectChange" style="width: 150px;" v-model="colaboradorModal.Jueves">
                <option value="11">1</option>
                <option value="21">2</option>
                <option value="31">3</option>
                <option value="D">D</option>
              </select>
            </div>

            <div class="mb-3">
              <label class="form-label" for="ddlViernes">Viernes</label>
              <select name="" id="ddlViernes" class="form-control" @change="handleSelectChange" style="width: 150px;" v-model="colaboradorModal.Viernes">
                <option value="11">1</option>
                <option value="21">2</option>
                <option value="31">3</option>
                <option value="D">D</option>
              </select>
            </div>

            <div class="mb-3">
              <label class="form-label" for="ddlSabado">Sabado</label>
              <select name="" id="ddlSabado" class="form-control" @change="handleSelectChange" style="width: 150px;" v-model="colaboradorModal.Sabado">
                <option value="11">1</option>
                <option value="21">2</option>
                <option value="31">3</option>
                <option value="D">D</option>
              </select>
            </div>

            <div class="mb-3">
              <label class="form-label" for="ddlDomingo">Domingo</label>
              <select name="" id="ddlDomingo" class="form-control" @change="handleSelectChange" style="width: 150px;" v-model="colaboradorModal.Domingo">
                <option value="11">1</option>
                <option value="21">2</option>
                <option value="31">3</option>
                <option value="D">D</option>
              </select>
            </div>


          </fieldset>
        </form>
      </div>

      <template slot="modal-footer">
        <button
          v-if="colaboradorModal.esNuevo"
          type="button"
          class="btn btn-primary mr-auto"
          @click="guardarCambiosHorario()"
        >
          {{ textoBotonGuardar }}
        </button>
        <!-- <button
          v-if="!colaboradorModal.esNuevo"
          type="button"
          class="btn btn-primary mr-auto"
          @click="guardarCambiosHorarioBorrador()"
        >
          Guardar borrador
        </button> -->
        <button
          type="button"
          class="btn btn-secondary"
          @click="$bvModal.hide('modalDialog')"
        >
          Cerrar
        </button>
      </template>
    </b-modal>



      <!-- bottom left vue-notification -->
      <notifications group="top-right" position="top right" :speed="1000" />
    </div>
  </template>
  
  <script>
  import axios from "axios";
  import { formatDate } from "devextreme/localization";
  import moment from 'moment';
  
  import Vue from "vue";
  
  
  Vue.filter("formatDateTime", function (value) {
    
    if (value) {
      return moment(String(value)).format('DD/MM/YYYY h:mm a')
    }
  });
  export default {
    mounted: function () {
      
      var userSetting = JSON.parse(localStorage.getItem('userSettings'));
      this.usuario = userSetting.Usuario;
      this.usuarioEstacion = userSetting.Estacion;
      this.IdRol = userSetting.IdRol;
      
      debugger; // eslint-disable-line no-debugger
      this.getCiudades();
      this.ciudadSelect = userSetting.CiudadId;
      this.getEstaciones();
      this.estacionSelect = userSetting.Estacion;
  
      this.getVendedores(this.usuarioEstacion);
      // this.calculateWeekRange();
      // this.GetTurnos();
      // this.GetBombas();
  
      if(this.IdRol == 3 || this.IdRol == 7){
        this.deshabilitarCombos = true;
      }
      this.getAsignaciones();
      
    },
    
    beforeDestroy() {
      
    },
    beforeRouteLeave(to, from, next) {
      
      var self = this;
      if(self.cambiosPendientes){
        if(window.confirm('Hay cambios sin guardar, ¿desea descartarlos?')){
          next();
        }else{
          next(false);
        }
      }else{
        next();
      }
    },
    methods: {
      handleSelectChange(){
        var self = this;
        if(!this.colaboradorModal.esNuevo){
          this.cambiosPendientes = true;
          
          self.asignaciones[self.colaboradorModal.originalIndex] = self.colaboradorModal;
          self.cambiosPendientes = true;

        }
      },
      async guardarCambiosTodo(){

        var self = this;
        var asistencia = [];
        var fechaInicial =  self.fechaInicial;
        for(var h = 0 ; h < self.asignaciones.length ; h++){
          var myObject = self.asignaciones[h];
          var numEmpleado = self.asignaciones[h].NumeroEmpleado;
          var nombre = self.asignaciones[h].Nombre;
          for (const key in myObject) {
            
            if(
              key == 'Lunes' ||
              key == 'Martes' ||
              key == 'Miercoles' ||
              key == 'Jueves' ||
              key == 'Viernes' ||
              key == 'Sabado' ||
              key == 'Domingo' ||
              key == 'Lunes'
            ){
                const value = myObject[key];
                // var fechaGuardar = moment(fechaInicial).add(0,'days').format('YYYY-MM-DD');
                var objGuardar = {
                  "NumeroEmpleado": numEmpleado,
                  "Nombre": nombre,
                  "Fecha": '',
                  "Turno": value,
                  // "HoraChecada": null
                };
                var fecha = '';
                var horaChecada = null;
                switch(key){
                  
                  
                  case 'Lunes':
                    fecha = moment(fechaInicial).add(0,'days').format('YYYY-MM-DD');
                    // horaChecada = self.asignaciones[h].ChecadaLunes;
                  break;
                  
                  case 'Martes':
                    fecha = moment(fechaInicial).add(1,'days').format('YYYY-MM-DD');
                    // horaChecada = self.asignaciones[h].ChecadaMartes;
                  break;
                  
                  case 'Miercoles':
                    fecha = moment(fechaInicial).add(2,'days').format('YYYY-MM-DD');
                    // horaChecada = self.asignaciones[h].ChecadaMiercoles;
                  break;
                  
                  case 'Jueves':
                    fecha = moment(fechaInicial).add(3,'days').format('YYYY-MM-DD');
                    // horaChecada = self.asignaciones[h].ChecadaJueves;
                  break;
                  
                  case 'Viernes':
                    fecha = moment(fechaInicial).add(4,'days').format('YYYY-MM-DD');
                    // horaChecada = self.asignaciones[h].ChecadaViernes;
                  break;
                  
                  case 'Sabado':
                    fecha = moment(fechaInicial).add(5,'days').format('YYYY-MM-DD');
                    // horaChecada = self.asignaciones[h].ChecadaSabado;
                  break;
                  
                  case 'Domingo':
                    fecha = moment(fechaInicial).add(6,'days').format('YYYY-MM-DD');
                    // horaChecada = self.asignaciones[h].ChecadaDomingo;
                  break;

                }
                
                objGuardar.Fecha = fecha;
                // objGuardar.HoraChecada = horaChecada;
                asistencia.push(objGuardar);
                // // Check if the value is equal to "Lunes" or "Martes"
                // if (value === "Lunes" || value === "Martes") {
                //   console.log(`Key: ${key}, Value: ${value}`);
                //   // Do something with the key and value
                // }

            }
              
          }

          
        }

        try {
          
        debugger; // eslint-disable-line no-debugger
          
          var dataToSend = {
            "Data": {
              "Estacion": self.estacionSelect,
              "FechaInicial": self.fechaInicialInt,
              "FechaFinal": self.fechaFinalInt,
              "Usuario": self.usuario,
              "Asistencia": asistencia
            }
          };

          debugger; // eslint-disable-line no-debugger
          const response = await axios.post("Colaborador/SetColaboradorTurno", dataToSend);
          if (response.data.Success) {
            self.cambiosPendientes = false;
            this.alertMessage = response.data.Message;
            // this.alertTitle = response.data.Error;
            this.show("top-right", "success");

          } else {
            this.alertMessage = response.data.Message;
            this.alertTitle = response.data.Error;
            this.show("top-right", "error");
          }
          // this.textoBotonGuardar = "Guardar";
        } catch (e) {
          this.alertMessage = "Ocurrio un error";
          this.alertTitle = "Error";
          this.show("top-right", "error");
        }
      },
      guardarCambiosHorario(){
        var self = this;
        // self.asignaciones[self.colaboradorModal.originalIndex] = self.colaboradorModal;
        self.asignaciones.push(self.colaboradorModal)
        self.cambiosPendientes = true;
        self.$bvModal.hide("modalDialog");
        // self.asignaciones[indexCambios] = 
        // for(var h = 0 ; h < self.asignaciones.length ; h++){
        //   if(self.asignaciones[h].NumeroEmpleado == 0){

        //   }
        // }
      },
      seleccionarHorario(row, esNuevo){
          debugger; // eslint-disable-line no-debugger
        var self = this;
        // const rowEditar = JSON.parse(JSON.stringify(row));
        
        if(esNuevo){
          self.colaboradorModal = {
            "esNuevo": true,
            "NumeroEmpleado": row.NumeroEmpleado,
            "Nombre": row.Nombre,
            "Fechas": [],
            "Domingo": "11",
            "Lunes": "11",
            "Martes": "11",
            "Miercoles": "11",
            "Jueves": "11",
            "Viernes": "11",
            "Sabado": "11",
            // "vgt_id": 0,
            // "originalIndex": 0
          }
        }else{
          self.colaboradorModal = row;
        }
      },
      cambiarSemana(val){
        var self = this;
        var continuar = false;
        if(self.cambiosPendientes){
          if(confirm('Hay cambios sin guardar, ¿desea descartarlos?')){
            continuar = true;
          }else{
            continuar = false;
          }
        }else{
            continuar = true;
        }

        if(continuar){
          self.cambiosPendientes = false;
          if(val == 0){
            self.contadorSemanaAtras = -1;
          }else if(val > 0){
            self.contadorSemanaAtras++;
          }else if(val < 0){
            self.contadorSemanaAtras--;
          }
          
          self.getAsignaciones();

        }

      },
      calculateWeekRange() {
        var self = this;
        // Get the current date
        const currentDate = moment();

        // Calculate the first day of the week (monday)
        self.firstDayOfWeek = currentDate.clone().startOf('isoWeek');

        // Calculate the last day of the week (Sunday)
        self.lastDayOfWeek = currentDate.clone().endOf('isoWeek');

        // Update data properties
        this.firstDayOfWeek = self.firstDayOfWeek.format('YYYY-MM-DD');
        this.lastDayOfWeek = self.lastDayOfWeek.format('YYYY-MM-DD');

      },
      async getCiudades(){
          try{
          const response = await axios.get('Catalogos/GetCiudades');
          
          if(response.data.Success){
            if(response.data.response.length == 0){
              this.alertMessage = "No se encontró información.";
              this.alertTitle = "Error";
              this.show('top-right', 'error');
            }
          }else{
              console.log("error")
              this.alertMessage = response.data.Error;
              this.alertTitle = "Error";
              this.show('top-right', 'error');
          }
          this.ciudades = response.data.response;
        }catch(e){
          //this.alert = true;
          //this.error = 'Invalid email/password';
        }
      },
      async getEstaciones(){
          try{
            
          const response = await axios.post('Estacion/GetEstaciones?ciudad='+this.ciudadSelect);
          if(response.data.Success){
            if(response.data.response.length == 0){
              this.alertMessage = "No se encontró información.";
              this.alertTitle = "Error";
              this.show('top-right', 'error');
            }
          }else{
              console.log("error")
              this.alertMessage = response.data.Error;
              this.alertTitle = "Error";
              this.show('top-right', 'error');
          }
          this.estaciones = response.data.response;
          this.productos = [];
          this.getProductos();
        }catch(e){
          //this.alert = true;
          //this.error = 'Invalid email/password';
        }
      },
      
      
      
      
      
      showNotification(title, text, type) {
        this.alertTitle = title;
        this.alertMessage = text;
        this.show("top-right", type);
      },
      show(group, type = "") {
        const text = this.alertMessage;
        this.$notify({
          group,
          title: this.alertTitle,
          text,
          type,
          data: {
            randomNumber: Math.random(),
          },
        });
      },
      clean(group) {
        this.$notify({ group, clean: true });
      },
      swalNotification(swalType) {
        var btnClass = swalType == "error" ? "danger" : swalType;
        btnClass = swalType == "question" ? "primary" : btnClass;
        this.$swal({
          title: "Are you sure?",
          text: "You will not be able to recover this imaginary file!",
          type: swalType,
          showCancelButton: true,
          buttonsStyling: false,
          confirmButtonText:
            swalType.charAt(0).toUpperCase() + swalType.substr(1).toLowerCase(),
          cancelButtonText: "Cancel",
          confirmButtonClass: "btn me-5px btn-" + btnClass + "",
          cancelButtonClass: "btn btn-default",
        });
      },
      
      
        async getVendedores(estacion){
          var self = this;
          this.textoBotonBuscar = "Buscando...";
          try {
            const response = await axios.post("Colaborador/GetColaboradores",
              {"Data":
                {
                  "Estacion":estacion //self.usuarioEstacion
                }
              });
            
            if (response.data.Success) {
              if (response.data.response.length == 0) {
                this.alertMessage =
                  "No se encontró información de usuarios";
                this.alertTitle = "Error";
                this.show("top-right", "error");
              } else {
                this.venderores = response.data.response;
              }
            } else {
              this.alertMessage = response.data.Message;
              this.alertTitle = "Error";
              this.show("top-right", "error");
            }
            this.textoBotonBuscar = "Buscar";
          } catch (e) {
              this.alertMessage = "Ocurrio un error";
              this.alertTitle = "Error";
              this.show("top-right", "error");
          }
  
        },
        async getAsignaciones(){
          var self = this;
          self.loading = true;
          this.textoBotonBuscar = "Buscando...";

          const firstDayOfWeek = self.firstDayOfWeek.clone().add(self.contadorSemanaAtras, 'weeks');
          const lastDayOfWeek = self.lastDayOfWeek.clone().add(self.contadorSemanaAtras, 'weeks');
          
          self.fechaInicialInt = firstDayOfWeek.format('YYYYMMDD');
          self.fechaFinalInt = lastDayOfWeek.format('YYYYMMDD');

          self.fechaInicial = firstDayOfWeek.format('YYYY/MM/DD');
          self.fechaFinal = lastDayOfWeek.format('YYYY/MM/DD');

          var dataToSend = {
              "Data": {
                "Estacion": self.estacionSelect,
                "FechaInicial": self.fechaInicialInt,
                "FechaFinal": self.fechaFinalInt,
                "Usuario": self.usuario
              }
            }
          try {
            const response = await axios.post("Colaborador/GetColaboradoresTurno", dataToSend);
            
            
            self.loading = false;
            if (response.data.Success) {
              if (response.data.response.length == 0) {
              //   this.alertMessage =
              //     "No se encontró información de usuarios";
              //   this.alertTitle = "Error";
              //   this.show("top-right", "error");
              } else {
                self.asignarFechasConDiasSemana(response.data.response);
              }
            } else {
              this.asignaciones = [];
              this.alertMessage = response.data.Message;
              this.alertTitle = "Notificación";
              this.show("top-right", "info");
            }
            this.textoBotonBuscar = "Buscar";
          } catch (e) {
            
              this.asignaciones = [];
              this.alertMessage = "Ocurrio un error";
              this.alertTitle = "Error";
              this.show("top-right", "error");
            this.loading = false;
          }
  
        },

        asignarFechasConDiasSemana(colabTurnos){
          var self = this;
          
          // this.asignaciones = [];
          for(var t = 0 ; t < colabTurnos.length ; t++){
            colabTurnos[t].Lunes = 'D';
            colabTurnos[t].Martes = 'D';
            colabTurnos[t].Miercoles = 'D';
            colabTurnos[t].Jueves = 'D';
            colabTurnos[t].Viernes = 'D';
            colabTurnos[t].Sabado = 'D';
            colabTurnos[t].Domingo = 'D';

            colabTurnos[t].ChecadaLunes = null;
            colabTurnos[t].ChecadaMartes = null;
            colabTurnos[t].ChecadaMiercoles = null;
            colabTurnos[t].ChecadaJueves = null;
            colabTurnos[t].ChecadaViernes = null;
            colabTurnos[t].ChecadaSabado = null;
            colabTurnos[t].ChecadaDomingo = null;

            

            for(var f = 0 ; f < colabTurnos[t].Fechas.length ; f++){
              var turno = colabTurnos[t].Fechas[f].Turno;
              var day = colabTurnos[t].Fechas[f].Fecha;
              var dayNumber = moment(day).day();
              switch(dayNumber){

                case 1:
                  colabTurnos[t].Lunes = turno;
                  // colabTurnos[t].ChecadaLunes = colabTurnos[t].Fechas[f].HoraChecada;
                break;

                case 2:
                  colabTurnos[t].Martes = turno;
                  // colabTurnos[t].ChecadaMartes = colabTurnos[t].Fechas[f].HoraChecada;
                break;

                case 3:
                  colabTurnos[t].Miercoles = turno;
                  // colabTurnos[t].ChecadaMiercoles = colabTurnos[t].Fechas[f].HoraChecada;
                break;

                case 4:
                  colabTurnos[t].Jueves = turno;
                  // colabTurnos[t].ChecadaJueves = colabTurnos[t].Fechas[f].HoraChecada;
                break;

                case 5:
                  colabTurnos[t].Viernes = turno;
                  // colabTurnos[t].ChecadaViernes = colabTurnos[t].Fechas[f].HoraChecada;
                break;

                case 6:
                  colabTurnos[t].Sabado = turno;
                  // colabTurnos[t].ChecadaSabado = colabTurnos[t].Fechas[f].HoraChecada;
                break;

                case 0:
                  colabTurnos[t].Domingo = turno;
                  // colabTurnos[t].ChecadaDomingo = colabTurnos[t].Fechas[f].HoraChecada;
                break;

              }
            }
            
          }

          
          self.asignaciones = colabTurnos;
        },
      async search(e) {
        e.preventDefault();
        this.getVendedores(this.usuarioEstacion);
      },
    },
    data() {
      return {
        fechaInicialInt: '',
        fechaFinalInt: '',
        cambiosPendientes: false,
        fechaInicial:null,
        fechaFinal:null,
        contadorSemanaAtras: 0,
        firstDayOfWeek: moment().startOf('isoWeek'),
        lastDayOfWeek: moment().endOf('isoWeek'),
        deshabilitarCombos: false,
        loading:false,
        usuarioEstacion: undefined,
        estaciones:[],
        ciudades:[],
        ciudadSelect: 1,
        estacionSelect: 0,
        IdRol:0,
        usuario:'',
        date: moment().format("YYYY-MM-DD"),
        // date: moment().format("YYYY/MM/DD"),
        intDate: "",
        options: {
          format: 'YYYY-MM-DD',
          useCurrent: false,
        },
        esRolVendendor: false,
        empresaTressSelected:'',
        puestoSelected:{
            puesto:0
        },
        rolSelected:{
            rol:0
        },
        empresaTressSelect:[],
        actualizarNIP:false,
        puestosSelect:[],
        rolesSelect:[],
        nuevoCorreo: '',
        clienteNuevo: true,
        colaboradorModal:{},
        textoBotonGuardar: "Guardar",
        textoBotonBuscar: "Buscar",
        clienteBuscar: "",
        columns: [
        //   {
        //     label: "# Empleado",
        //     field: "NumeroEmpleado",
        //     type: "number",
        //     tdClass: "text-center text-nowrap",
        //     thClass: "text-center text-nowrap",
        //   },
        //   {
        //     label: "# Ventuk",
        //     field: "NumeroVentuk",
        //     type: "number",
        //     tdClass: "text-center text-nowrap",
        //     thClass: "text-center text-nowrap",
        //   },
          {
            label: "Nombre",
            field: "Nombre",
            tdClass: "text-nowrap",
            thClass: "text-center text-nowrap",
          },
          {
            label: "Acciones",
            field: "Acciones",
            width: '150px',
            tdClass: "text-center text-nowrap",
            thClass: "text-center text-nowrap",
          }
        ],
        columnsAsignados: [
          {
            label: "Cambiar",
            field: "Cambiar",
            tdClass: "text-center text-nowrap",
            thClass: "text-center text-nowrap",
          },
          {
            label: "# Empleado",
            field: "NumeroEmpleado",
            type: "number",
            tdClass: "text-center text-nowrap",
            thClass: "text-center text-nowrap",
          },
          {
            label: "Nombre",
            field: "Nombre",
            tdClass: "text-nowrap",
            thClass: "text-center text-nowrap",
            width: '10%',
          },
          {
              label: "Lunes",
              field: "Lunes",
              tdClass: "text-center text-nowrap",
              thClass: "text-center text-nowrap",
          },
          {
              label: "Martes",
              field: "Martes",
              tdClass: "text-center text-nowrap",
              thClass: "text-center text-nowrap",
          },
          {
              label: "Miercoles",
              field: "Miercoles",
              tdClass: "text-center text-nowrap",
              thClass: "text-center text-nowrap",
          },
          {
              label: "Jueves",
              field: "Jueves",
              tdClass: "text-center text-nowrap",
              thClass: "text-center text-nowrap",
          },
          {
              label: "Viernes",
              field: "Viernes",
              tdClass: "text-center text-nowrap",
              thClass: "text-center text-nowrap",
          },
          {
              label: "Sabado",
              field: "Sabado",
              tdClass: "text-center text-nowrap",
              thClass: "text-center text-nowrap",
          },
          {
              label: "Domingo",
              field: "Domingo",
              tdClass: "text-center text-nowrap",
              thClass: "text-center text-nowrap",
          },

        ],
        turnos:[],
        clientes: [],
        bombas: [],
        venderores: [],
        asignaciones: [],
        vendedorSeleccionado: {},
        turnoSelected:{
            IdTurno:0
        },
        bombaSelected:{
            Id:0
        },
      };
    },
  };
  </script>
  
  <style scoped>
  
  .loading-search{
    position: absolute;
      width: 100%;
      left: 0px;
      height: 100%;
      background: rgba(0, 0, 0, 0.1);
      z-index: 1000;
      padding-top: 150px;
      top: 0;
  }
  </style>