<template>
  <div>
    <div class=" loading-search" v-if="loading">
      <div class="fa-3x text-center">
        <i class="fas fa-circle-notch fa-spin text-success" style=""></i>
      </div>
    </div>
    <!-- BEGIN breadcrumb -->
    <ol class="breadcrumb float-xl-end">
      <li class="breadcrumb-item"><a href="javascript:;">Operación</a></li>
      <li class="breadcrumb-item"><a href="javascript:;">Comisiones</a></li>
      <li class="breadcrumb-item active"><a href="javascript:;">Corresponsalia tickets USD</a></li>
    </ol>
    <!-- END breadcrumb -->
    <!-- BEGIN page-header -->
    <h1 class="page-header">Corresponsalia tickets USD<small>.</small></h1>
    <!-- <button class="btn" v-on:click="redirectTanques()">tanques</button> -->
    <!-- END page-header -->
    <!-- <a href="javascript:;" v-on:click="show('top-right', 'warn')" class="btn btn-sm btn-primary">Demo</a> -->

    <form @submit.prevent="search">
      <div class="row">
        <div class="col-md-9">
          <div class="form-group row">
            <!-- <div class="col-md-3">
                <label class="col-form-label">Fecha</label>
              <div class="input-group">
                <date-picker id="dateTimeInput" v-model="date" :config="options"></date-picker>
                <label for="dateTimeInput" class="input-group-text">
                  <i class="fa fa-calendar"></i>
                </label>
              </div>
            </div> -->
            <!-- <div class="col-md-3">
              <label class="col-form-label">Año</label>
                <select class="form-select" v-model="yearSelect">
                    <option v-for="year in years" :value="year.Year" v-bind:key="year.Year">
                        {{ year.Year }}
                    </option>
                </select>
            </div>
            <div class="col-md-3">
              <label class="col-form-label">Mes</label>
                <select class="form-select" v-model="mesSelect">
                    <option value="01">Enero</option>
                    <option value="02">Febrero</option>
                    <option value="03">Marzo</option>
                    <option value="04">Abril</option>
                    <option value="05">Mayo</option>
                    <option value="06">Junio</option>
                    <option value="07">Julio</option>
                    <option value="08">Agosto</option>
                    <option value="09">Septiembre</option>
                    <option value="10">Octubre</option>
                    <option value="11">Noviembre</option>
                    <option value="12">Diciembre</option>
                </select>
            </div>
      
            <div class="col-md-3">
                <label class="col-form-label">Ciudad</label>
                <select class="form-select" @change="getEstaciones()" v-model="ciudadSelect" :disabled="deshabilitarCombos">
                    <option v-for="ciudad in ciudades" :value="ciudad.Id" v-bind:key="ciudad.Id">
                        {{ ciudad.Nombre }}
                    </option>
                </select>
            </div>
            <div class="col-md-3">
                <label class="col-form-label">Estación</label>
                <select class="form-select" v-model="estacionSelect" :disabled="deshabilitarCombos">
                  <option value="0">Seleccione</option>
                    <option v-for="estacion in estaciones" :value="estacion.NoEstacion" v-bind:key="estacion.NoEstacion">
                    {{ estacion.Nombre }}
                    </option>
                  </select>
            </div> -->
            <!-- <div class="col-md-2">
                <label class="col-form-label">Turno</label>
                <select v-model="turnoSelected.IdTurno" class="form-control">
                    <option v-for="turno in turnos" :value="turno.IdTurno" v-bind:key="turno.IdTurno">
                    {{ turno.Descripcion }}
                    </option>
                </select>
            </div> -->

            <div class="col-md-3">
              <label class="col-form-label">Fecha inicial</label>
              <div class="input-group">
                <date-picker id="dateTimeInput" v-model="date" :config="options"></date-picker>
                <label for="dateTimeInput" class="input-group-text">
                  <i class="fa fa-calendar"></i>
                </label>
              </div>
            </div>


            <div class="col-md-3">
              <label class="col-form-label">Fecha final</label>
              <div class="input-group">
                <date-picker id="dateTimeInputEnd" v-model="dateEnd" :config="options"></date-picker>
                <label for="dateTimeInputEnd" class="input-group-text">
                  <i class="fa fa-calendar"></i>
                </label>
              </div>
            </div>

            <div class="col-md-3">
              <label class="col-form-label">Banco</label>
              <select class="form-select" v-model="bancoSelect">
                <option value="0">Seleccione</option>
                <option value="Santander"> SANTANDER </option>
                <option value="Banorte"> BANORTE </option>
              </select>
            </div>
          </div>
        </div>

        <div class="col-md-3" style="padding-top: 2.65em;">
          <div class="row">
            <div class="col-md-6 text-center">
              <button type="submit" class="btn btn-success me-1 mb-1">{{ textoBotonBuscar }}</button>
            </div>
            <div class="col-md-6 text-center">

              <button type="button" class="btn btn-success me-1 mb-1" :disabled="ReporteComisiones.length == 0"
                @click="descargarTXT()">
                Descargar TXT
              </button>
            </div>

          </div>
        </div>



      </div>

    </form>

    <DxDataGrid id="gridContainer" :data-source="ReporteComisiones" key-expr="Transaccion" :show-borders="true"
      @exporting="onExporting">

      <DxScrolling column-rendering-mode="virtual" />
      <DxFilterRow />
      <DxHeaderFilter :visible="showHeaderFilter" />
      <DxSearchPanel :visible="true" :width="240" placeholder="Search..." />

      <DxGroupPanel :visible="true" />
      <DxGrouping :auto-expand-all="true" />
      <DxExport :enabled="true" :allow-export-selected-data="false" />
      <DxSelection mode="false" />

      <DxColumn dataField="Abrir" caption="Abrir" :cellTemplate="verDetalle" />
      <DxColumn data-field="Estacion" caption="Estacion">
        <DxHeaderFilter :allow-search="true" />
      </DxColumn>

      <DxColumn data-field="Transaccion" caption="Transacción" />
      <DxColumn data-field="NoEmpleado" caption="No Empleado" />
      <DxColumn data-field="Nombre" caption="Nombre" />
      <DxColumn data-field="Turno" caption="Turno" width="80" />
      <DxColumn data-field="Fecha" caption="Fecha" dataType="date" />
      <DxColumn data-field="Hora" caption="Hora" />

      <DxColumn data-field="Bomba" caption="Bomba" width="80" />
      <DxColumn data-field="TipoCambio" caption="Tipo Cambio" dataType="number">
        <DxFormat type="currency" :precision="2" />
      </DxColumn>
      <DxColumn data-field="CambioUSD" caption="Cambio USD" dataType="number">
        <DxFormat type="currency" :precision="2" />
      </DxColumn>
      <DxColumn data-field="CambioMXN" caption="Cambio MXN" dataType="number">
        <DxFormat type="currency" :precision="2" />
      </DxColumn>
      <DxColumn data-field="FechaImpresion" caption="Fecha Impresión" dataType="date" />



      <DxColumn data-field="ImporteDespacho" caption="Importe Despacho" dataType="number">
        <DxFormat type="currency" :precision="2" />
      </DxColumn>

      <DxColumn data-field="DolaresRecibidos" caption="Dolares Recibidos" dataType="number" width="150">
        <DxFormat type="currency" :precision="2" />
      </DxColumn>

      <DxSummary>
        <DxGroupItem column="DolaresRecibidos" summary-type="sum" value-format="$#,##.##" displayFormat='Total: {0}'>
        </DxGroupItem>

        <DxTotalItem column="DolaresRecibidos" summary-type="sum" value-format="$#,##0.##" displayFormat='Total: {0}'>
        </DxTotalItem>
      </DxSummary>

    </DxDataGrid>


    <!-- bottom left vue-notification -->
    <notifications group="top-right" position="top right" :speed="1000" />
  </div>
</template>

<script>

import axios from 'axios';
import moment from 'moment';
import {
  DxScrolling, DxDataGrid, DxColumn, DxExport, DxSelection, DxGroupPanel, DxSummary, DxGroupItem, DxGrouping, DxHeaderFilter, DxFilterRow, DxSearchPanel,
  DxTotalItem, DxFormat
} from 'devextreme-vue/data-grid';
import { Workbook } from 'exceljs';
import { saveAs } from 'file-saver';
// Our demo infrastructure requires us to use 'file-saver-es'. We recommend that you use the official 'file-saver' package in your applications.
import { exportDataGrid } from 'devextreme/excel_exporter';
//import service from './data.js';

export default {
  beforeCreate() {
    // var userSetting = localStorage.getItem('userSettings');
    // if(userSetting != undefined){
    //   console.log(JSON.parse(userSetting));
    //   this.pageUserSettings = JSON.parse(userSetting);
    // }else{
    // 	this.$router.push('/login');
    // }
  },
  created() {
    // console.log('created');
  },
  beforeMount() {
    // console.log('beforeMount');
  },
  mounted() {
    /* eslint-disable no-debugger */
    debugger;
    var userSetting = JSON.parse(localStorage.getItem('userSettings'));
    this.usuario = userSetting.Usuario;
    this.idUsuario = userSetting.NumeroEmpleado;
    this.nombreLogueado = userSetting.Nombre;
    this.usuarioEstacion = userSetting.Estacion;
    this.IdRol = userSetting.IdRol;
    this.getCiudades();
    this.ciudadSelect = userSetting.CiudadId;
    this.getEstaciones();
    this.estacionSelect = userSetting.Estacion;

    this.GetTurnos();
    this.getYears();
    if (this.IdRol == 3 || this.IdRol == 7) {
      this.deshabilitarCombos = true;
    }
  },
  components: {
    DxScrolling,
    DxDataGrid, DxColumn, DxExport, DxSelection, DxGroupPanel, DxGrouping, DxSummary, DxGroupItem,
    DxHeaderFilter, DxFilterRow, DxSearchPanel,
    DxTotalItem, DxFormat
  },
  methods: {
    async descargarTXT() {
      // debugger; // eslint-disable-line no-debugger
      var self = this;
      self.loading = true;
      // self.descargarFacturasBtnTxt = "Descargando...";

      let date = self.date.replace('/', '');
      let dateEnd = self.dateEnd.replace('/', '');

      date = date.replace('/', '');
      dateEnd = dateEnd.replace('/', '');

      const bodyData = {
        "Data": {
          "FechaIni": date.toString(),
          "FechaFin": dateEnd.toString(),
          "Banco": self.bancoSelect,
          "Usuario": self.usuario
        }

      };

      try {

        const response = await axios.post("Corresponsalia/GetTxtBanco", bodyData);
        if (response.data.Success) {
          debugger; // eslint-disable-line no-debugger

          self.descargarTXT64(response.data.response);

        } else {
          this.alertMessage = response.data.Error;
          this.alertTitle = "Error";
          this.show("top-right", "error");
          self.loading = false;
          // self.descargarFacturasBtnTxt = "Descargar Tickets";
        }

      } catch (e) {
        this.alertMessage = "Ocurrio un error";
        this.alertTitle = "Error";
        this.show("top-right", "error");
        self.loading = false;
        // self.descargarFacturasBtnTxt = "Descargar Tickets";
      }

    },
    descargarTXT64(base64) {
      var self = this;
      var fecha = moment().format('YYYYMMDD');

      var filename = fecha + ".txt";//nombreArchivo;
      var link = document.createElement("a");
      link.href = "data:application/txt;base64," + base64;
      link.download = filename;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      self.loading = false;
      // self.descargarFacturasBtnTxt = "Descargar Tickets";
    },
    verDetalle(cellElement, cellInfo) {

      const button = document.createElement("button");
      button.innerText = "Ticket";
      button.className = "btn btn-sm btn-success";
      button.addEventListener("click", () => {

        this.handleButtonClick(cellInfo.data);
      });

      cellElement.appendChild(button);
    },
    handleButtonClick(data) {
      debugger; // eslint-disable-line no-debugger
      // alert(data.Transaccion);
      // this.clienteModalEstatus = data.Estatus == 'Active'? true:false;
      // this.enviarCG(data)

      this.alertTitle = "Desarrollo";
      this.alertMessage = "Funcionalidad en etapa de desarrollo";
      this.show("top-right", "info");

    },
    async GetTurnos() {
      try {
        const response = await axios.get("Catalogos/GetTurnos");
        if (response.data.Success) {
          if (response.data.response.length == 0) {
            this.alertMessage =
              "No se encontraron turnos";
            this.alertTitle = "Error";
            this.show("top-right", "error");
          } else {
            this.turnos = response.data.response;
          }
        } else {
          this.alertMessage = response.data.Message;
          this.alertTitle = "Error";
          this.show("top-right", "error");
        }
      } catch (e) {
        this.alertMessage = "Ocurrio un error";
        this.alertTitle = "Error";
        this.show("top-right", "error");
      }
    },
    getYears() {
      var self = this;
      debugger; // eslint-disable-line no-debugger
      var currentYear = parseInt(moment().format("YYYY"));
      var minimumYear = 2023;
      self.years = [];

      for (var n = minimumYear; n <= currentYear; n++) {
        var year = { "Year": n };
        self.years.push(year);
      }
      debugger; // eslint-disable-line no-debugger
    },
    async getCiudades() {
      try {
        const response = await axios.get('Catalogos/GetCiudades');

        if (response.data.Success) {
          if (response.data.response.length == 0) {
            this.alertMessage = "No se encontró información.";
            this.alertTitle = "Error";
            this.show('top-right', 'error');
          }
        } else {
          console.log("error")
          this.alertMessage = response.data.Error;
          this.alertTitle = "Error";
          this.show('top-right', 'error');
        }
        this.ciudades = response.data.response;
      } catch (e) {
        //this.alert = true;
        //this.error = 'Invalid email/password';
      }
    },
    async getEstaciones() {
      try {
        const response = await axios.post('Estacion/GetEstaciones?ciudad=' + this.ciudadSelect);
        if (response.data.Success) {
          if (response.data.response.length == 0) {
            this.alertMessage = "No se encontró información.";
            this.alertTitle = "Error";
            this.show('top-right', 'error');
          }
        } else {
          console.log("error")
          this.alertMessage = response.data.Error;
          this.alertTitle = "Error";
          this.show('top-right', 'error');
        }
        this.estaciones = response.data.response;
        this.productos = [];
        this.getProductos();
      } catch (e) {
        //this.alert = true;
        //this.error = 'Invalid email/password';
      }
    },
    show(group, type = '') {
      const text = this.alertMessage;
      this.$notify({
        group,
        title: this.alertTitle,
        text,
        type,
        data: {
          randomNumber: Math.random()
        }
      })
    },
    clean(group) {
      this.$notify({ group, clean: true })
    },
    swalNotification(swalType) {
      var btnClass = (swalType == 'error') ? 'danger' : swalType;
      btnClass = (swalType == 'question') ? 'primary' : btnClass;
      this.$swal({
        title: 'Are you sure?',
        text: 'You will not be able to recover this imaginary file!',
        type: swalType,
        showCancelButton: true,
        buttonsStyling: false,
        confirmButtonText: swalType.charAt(0).toUpperCase() + swalType.substr(1).toLowerCase(),
        cancelButtonText: 'Cancel',
        confirmButtonClass: 'btn me-5px btn-' + btnClass + '',
        cancelButtonClass: 'btn btn-default',
      })
    },
    onExporting(e) {
      const workbook = new Workbook();
      const worksheet = workbook.addWorksheet('ReporteComisiones');

      exportDataGrid({
        component: e.component,
        worksheet: worksheet,
        autoFilterEnabled: true
      }).then(() => {
        workbook.xlsx.writeBuffer().then((buffer) => {
          saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'Reporte Comisiones.xlsx');
        });
      });
      e.cancel = true;
    },
    async search(e) {
      e.preventDefault();
      var self = this;
      self.textoBotonBuscar = "Buscando...";
      try {
        // this.intDate = this.date.replace('/', '');
        // this.intDate = this.intDate.replace('/', '');
        // var sendDate = self.date;
        // var estacion = self.estacionSelect;
        // if (estacion == "0") {
        //   this.alertMessage = "Seleccione estación";
        //   this.alertTitle = "Error";
        //   this.show('top-right', 'error');
        //   return;
        // }
        // self.estacionBusqueda = estacion;

        // var dataToSend = {
        //     "Data": { 
        //         "Ejercicio" : 2023,
        //         "Periodo" : 12,
        //         "Estacion" : 5081
        //     }
        // }

        // var dataToSend = {
        //   "Data": {
        //     "Estacion": estacion,
        //     "Ejercicio": self.yearSelect,
        //     "Periodo": self.mesSelect
        //   }
        // }

        var dataToSend = {
          "Data": {
            "FechaIni": self.date,
            "FechaFin": self.dateEnd,
            "Banco": self.bancoSelect
          }
        }


        debugger; // eslint-disable-line no-debugger
        const response = await axios.post('Corresponsalia/GetTicketsUSD', dataToSend);

        if (response.data.Success) {

          if (response.data.response.length == 0) {
            this.alertMessage = "No se erncontro información del día especificado.";
            this.alertTitle = "Error";
            this.show('top-right', 'error');
            this.ReporteComisiones = [];
          } else {
            this.ReporteComisiones = response.data.response;
            // for(var c = 0 ; c < this.ReporteComisiones.length ;c++){
            //   this.ReporteComisiones[c].FechaString = moment(this.ReporteComisiones[c].Fecha).format("YYYY/MM/DD");
            //   this.ReporteComisiones[c].ComisionString = parseFloat(this.ReporteComisiones[c].Comision).toFixed(2);
            // }
          }
        } else {
          this.ReporteComisiones = [];
          console.log("error")
          this.alertMessage = response.data.Message;
          this.alertTitle = "Error";
          this.show('top-right', 'error');
        }

        this.textoBotonBuscar = "Buscar";
      } catch (e) {
        this.ReporteComisiones = [];
        this.alertMessage = "Ocurrió un error";
        this.alertTitle = "Error";
        this.show('top-right', 'error');
        //this.alert = true;
        //this.error = 'Invalid email/password';
      }
    }
  },
  data() {
    return {
      estacionBusqueda: null,
      turnos: [],
      turnoSelected: {
        IdTurno: 1
      },
      years: [],
      yearSelect: parseInt(moment().format("YYYY")),
      mesSelect: moment().format("MM"),
      showHeaderFilter: true,
      loading: false,
      nombreLogueado: "",
      idUsuario: undefined,
      reportefajillas: [],
      deshabilitarCombos: false,
      ciudades: [],
      estaciones: [],
      ciudadSelect: 1,
      estacionSelect: 0,

      pageUserSettings: {},
      alertMessage: "",
      alertTitle: "",
      ReporteComisiones: [],
      textoBotonBuscar: "Buscar",
      date: moment().startOf('month').format("YYYY/MM/DD"),
      dateEnd: moment().format("YYYY/MM/DD"),
      options: {
        format: 'YYYY/MM/DD',
        useCurrent: false,
      },
      bancoSelect: '',

    };
  }
}
</script>
<style scoped>
.dx-datagrid-header-panel {
  display: none !important;
}

.loading-search {
  position: absolute;
  width: 100%;
  left: 0px;
  height: 100%;
  background: rgba(0, 0, 0, 0.1);
  z-index: 100000;
  padding-top: 150px;
  top: 0;
}
</style>
