<template>
	<div>
    
    <div class=" loading-search" v-if="loading">
      <div class="fa-3x text-center">
        <i class="fas fa-circle-notch fa-spin text-success" style=""></i>
      </div>
    </div>
		<!-- BEGIN breadcrumb -->
		<ol class="breadcrumb float-xl-end">
			<li class="breadcrumb-item"><a href="javascript:;">Facturación</a></li>
			<li class="breadcrumb-item"><a href="javascript:;">Servicios Administrativos</a></li>
			<li class="breadcrumb-item active"><a href="javascript:;">Reporte</a></li>
		</ol>
		<!-- END breadcrumb -->
		<!-- BEGIN page-header -->
		<h1 class="page-header">Facturas <small></small></h1>
    <!-- <button class="btn" v-on:click="redirectTanques()">tanques</button> -->
		<!-- END page-header -->
		<!-- <a href="javascript:;" v-on:click="show('top-right', 'warn')" class="btn btn-sm btn-primary">Demo</a> -->
		
          <div class="row">
            <div class="col-md-12">
              
							<div class="row mb-3">
								<!-- <label class="form-label col-form-label col-md-3">Radios {{radioBusqueda}}</label> -->
								<div class="col-md-9 pt-2">
									<div class="form-check mb-2">
										<input class="form-check-input" type="radio" value="filtros" name="flexRadioDefault" id="flexRadioDefault1" checked v-model="radioBusqueda">
										<label class="form-check-label" for="flexRadioDefault1">
											Filtros
										</label>
									</div>
									<div class="form-check mb-2">
										<input class="form-check-input" type="radio" value="ticket" name="flexRadioDefault" id="flexRadioDefault2" v-model="radioBusqueda">
										<label class="form-check-label" for="flexRadioDefault2">
											Ticket
										</label>
									</div>
								</div>
							</div>
            </div>
          </div>

          <div class="row">
            <!-- <form @submit.prevent="search">    -->
              <div class="col-md-12" v-if="radioBusqueda == 'filtros'"> 
                  <div class="form-group row">
                    
                    <div class="col-md-2">
                        <label class="col-form-label">Fecha inicial</label>
                      <div class="input-group">
                        <date-picker id="dateTimeInput" v-model="date" :config="options"></date-picker>
                        <label for="dateTimeInput" class="input-group-text">
                          <i class="fa fa-calendar"></i>
                        </label>
                      </div>
                    </div>

                    
                    <div class="col-md-2">
                        <label class="col-form-label">Fecha final</label>
                      <div class="input-group">
                        <date-picker id="dateTimeInputEnd" v-model="dateEnd" :config="options"></date-picker>
                        <label for="dateTimeInputEnd" class="input-group-text">
                          <i class="fa fa-calendar"></i>
                        </label>
                      </div>
                    </div>
                    <div class="col-md-2">
                        <label class="col-form-label">Ciudad</label>
                        <select class="form-select" @change="getEstaciones()" v-model="ciudadSelect" :disabled="deshabilitarCombos">
                            <option v-for="ciudad in ciudades" :value="ciudad.Id" v-bind:key="ciudad.Id">
                                {{ ciudad.Nombre }}
                            </option>
                        </select>
                    </div>
                    <div class="col-md-2">
                        <label class="col-form-label">Estación</label>
                        <select class="form-select" v-model="estacionSelect" :disabled="deshabilitarCombos">
                          <option value="0">Todas</option>
                            <option v-for="estacion in estaciones" :value="estacion.NoEstacion" v-bind:key="estacion.NoEstacion">
                            {{ estacion.Nombre }}
                            </option>
                          </select>
                    </div>
                    <div class="col-md-1">
                        <label class="col-form-label">Estatus</label>
                        <select class="form-select" v-model="estatusFacturas">
                          <option value="0">Todas</option>
                          <option value="1">Vigentes</option>
                          <option value="2">Canceladas</option>
                          
                          </select>
                    </div>
                    <div class="col-md-3">

                                  
                      <!-- <form class="form-horizontal form-bordered"> -->
                          <label class="col-form-label">Buscar Cliente</label>
                          
                          <div class="input-group">
                            <input type="text" class="form-control" v-model="clienteBuscar" />
                            <button type="button" class="btn btn-success" @click="getClientes()" :disabled="clienteBuscar == ''">Buscar Cliente</button>
                          </div>
                      <!-- </form> -->
                      <div style="margin-top: 10px;" v-if="cliente.RFC != ''">{{cliente.RFC}} - {{cliente.Nombre}} <a @click="limpiarCampoCliente()"><i class="fa fa-window-close" style="color: red; cursor: pointer; font-size: 1.3rem;"></i></a></div>
                      <div class="table-responsive" v-if="clientes.length>0">
                        <table class="table mb-0">
                          <thead>
                            <tr>
                              <th>RFC</th>
                              <th>Nombre</th>
                              <th></th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-for="cliente in clientes" :key="cliente.Id">
                              <td>{{cliente.RFC}}</td>
                              <td>{{cliente.Nombre}}</td>
                              <td><button type="button" class="btn btn-success btn-xs" @click="seleccionarCliente(cliente)">Seleccionar</button></td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                        
                    </div>


                    <!-- <div class="col-md-6">
                      <button type="submit" class="btn btn-success me-1 mb-1">{{textoBotonBuscar}}</button>
                    </div> -->
                  </div>
              </div>

              <div class="col-md-8"  v-if="radioBusqueda == 'ticket'">
                <div class="row">
                  <div class="col-lg-6 col-md-8 col-sm-12 col-xs-12">
                    <label class="col-form-label" style="    font-size: 12px;">Buscar por ticket (Ingresar el número completo del ticket, incluyendo número de estación)</label>
                    <div class="input-group">
                      <input type="text" class="form-control" v-model="ticketBuscar" />
                      <button type="button" class="btn btn-success" @click="buscarPorTicket()">Buscar</button>
                    </div>

                  </div>
                </div>

              </div>








              <div class="col-md-4 text-center" style="margin-bottom: 15px;" v-if="radioBusqueda == 'filtros'">
              </div>


              <div class="col-md-4 text-center" style="margin-bottom: 15px;"  v-if="radioBusqueda == 'filtros'">
                    <button type="button" class="btn btn-lg btn-success me-1 mb-1" @click="search" style="margin-top: 3.1em;">{{textoBotonBuscar}}</button>
                    <!-- <button type="button" @click="descargarZip" class="btn btn-success me-1 mb-1" style="margin-top: 3.1em;">descargar</button> -->
              </div>

              
              <div class="col-md-2 text-end" style="margin-bottom: 15px;">
                    
                <div class="form-check form-switch mb-2" style="width: 180px; text-align: left; margin-top: 3.1em;" v-if="permitirSeleccionarTodas && facturas.length > 0 && radioBusqueda == 'filtros'">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    id="flexSwitchCheckChecked"
                    v-model="seleccionarTodas"
                  />
                  <label class="form-check-label" for="flexSwitchCheckChecked"
                    ><span v-if="!seleccionarTodas">Seleccionar todas</span
                    ><span v-if="seleccionarTodas">Deshacer selección</span></label
                  >
                </div>
              </div>
              
              <div class="col-md-2 text-end" style="margin-bottom: 15px;">
                    <button type="button" @click="descargaMultiple" :disabled="!(contador > 0) && !seleccionarTodas" class="btn btn-success me-1 mb-1" style="margin-top: 3.1em;">
                      {{descargarFacturasBtnTxt}}
                    </button>
              </div>
        <!-- </form> -->
            </div>

        <div>
          <panel title="Facturas" bodyClass="p-0" class="">
            <vue-good-table
              :columns="columns"
              :rows="facturas"
              :lineNumbers="false"
              :search-options="{ enabled: true, placeholder: 'Buscar' }"
              :pagination-options="{ enabled: true, position: 'bottom' }"
            >
              <template slot="table-row" slot-scope="props">
                <!--  -->
                <span v-if="props.column.label == 'Acciones'">

                  <!-- <input type="checkbox" v-model="props.row.Seleccionado"> -->


                  <button class="btn btn-xs btn-success" @click="descargaUnitaria(props.row)">Descargar</button>




                  <!-- <button v-b-modal.modalDialog class="btn btn-sm btn-success" @click="getCliente(props.formattedRow.NumeroEmpleado, props.formattedRow.EmpresaTress)">Ver</button>
                  &nbsp;
                  <button v-b-modal.modalContrasenaDialog class="btn btn-sm btn-success" @click="getCliente(props.formattedRow.NumeroEmpleado, props.formattedRow.EmpresaTress)">Contraseña / NIP</button> -->
                </span>
                <span v-else-if="props.column.label == 'Descarga'">
                  <div class="form-check form-switch mb-2">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      id="flexSwitchCheckChecked"
                      v-model="props.row.Seleccionado"
                      @click="contadorSeleccionados(props.row)"
                      :disabled="(disableSeleccionar && !props.row.Seleccionado) && !permitirSeleccionarTodas"
                    />
                    <!-- <label class="form-check-label" for="flexSwitchCheckChecked"
                      ><span v-if="props.row.Seleccionado">Seleccionado</span
                      ><span v-if="!props.row.Seleccionado">Seleccionar</span></label
                    > -->
                  </div>
                </span>
                <!-- <span v-else-if="props.column.field == 'Estatus'">
                  <span v-if="props.formattedRow[props.column.field]" class="fa fa-check"></span>
                  <span v-if="!props.formattedRow[props.column.field]" class="fa fa-ban"></span>
                </span> -->
              </template>
            </vue-good-table>
          </panel>
        </div>



		<!-- bottom left vue-notification -->
		<notifications group="top-right" position="top right" :speed="1000" />
	</div>
</template>

<script>

import axios from 'axios';
import moment from 'moment';

export default {
  mounted: function () {
    debugger; // eslint-disable-line no-debugger
		var userSetting = JSON.parse(localStorage.getItem('userSettings'));
		this.usuario = userSetting.Usuario;
		this.usuarioEstacion = userSetting.Estacion;
		this.IdRol = userSetting.IdRol;
    this.getCiudades();
    this.ciudadSelect = userSetting.CiudadId;
    this.getEstaciones();
    this.estacionSelect = userSetting.Estacion;

    if(this.IdRol == 3 || this.IdRol == 7){
      this.deshabilitarCombos = true;
    }


    

  },
	beforeCreate() {
	},
	created() {
		// console.log('created');
	},
	beforeMount() {
		// console.log('beforeMount');
	},
	components: {
        
	},
  methods: {
    descargaUnitaria(factura){
      var self = this;

    debugger; // eslint-disable-line no-debugger

      var selectedDate = moment(factura.FechaFactura).format('YYYYMMDD');
      var today = moment();

      // var difference = today.diff(selectedDate, 'months');
      // console.log('Difference', difference);

      var diferenciaDecimal = today.diff(selectedDate, 'months', true);
      if(diferenciaDecimal < 3){
        self.descargaUnitariaReciente(factura)
      }else{
        var filesArray = [factura.XmlPath];
        self.descargarAntiguas(filesArray);
      }


    },
    
    async descargarAntiguas(filesArray){
      debugger; // eslint-disable-line no-debugger
      var self = this;
      self.loading = true;
      self.descargarFacturasBtnTxt = "Descargando...";
      const bodyData = {
        "Data": {
            "Usuario": self.usuario,        
            "Files": filesArray        
          }
      };
      try {
        const response = await axios.post("BlobStorage/GetZipFiles", bodyData);
        if (response.data.Success) {
          if (response.data.response.length == 0) {
            this.alertMessage =
              "No se encontró información";
            this.alertTitle = "Error";
            this.show("top-right", "error");
          } else {

            debugger; // eslint-disable-line no-debugger
            // if(response.data.response.CfdiNoFound.length > 0){
            //   this.alertMessage = "No se encontraron algunas facturas: "+response.data.response.CfdiNoFound;
            //   this.alertTitle = "Error";
            //   this.show("top-right", "info");
            // }
            self.descargarZip(response.data.response.zip);
            // self.descargarXML(response.data.response.XML);
            // self.descargarPDF(response.data.response.PDF);
          }
        } else {
          this.alertMessage = response.data.Error;
          this.alertTitle = "Error";
          this.show("top-right", "error");
          self.loading = false;
          self.descargarFacturasBtnTxt = "Descargar Facturas";
        }
        // this.textoBotonBuscar = "Buscar Facturas";
      } catch (e) {
        this.alertMessage = "Ocurrio un error";
        this.alertTitle = "Error";
        this.show("top-right", "error");
          self.loading = false;
          self.descargarFacturasBtnTxt = "Descargar Facturas";
      }
    },
    async descargaUnitariaReciente(factura) {
      var self = this;
      self.loading = true;
      self.descargarFacturasBtnTxt = "Descargando...";
      try {
        const response = await axios.post("CFDI/GetArchivosCFDI?UUID="+factura.UUID, {});
        if (response.data.Success) {
          if (response.data.response.length == 0) {
            this.alertMessage =
              "No se encontró información";
            this.alertTitle = "Error";
            this.show("top-right", "error");
          } else {

            debugger; // eslint-disable-line no-debugger
            self.descargarXML(response.data.response.XML);
            self.descargarPDF(response.data.response.PDF);
            self.loading = false;
            self.descargarFacturasBtnTxt = "Descargar Factura";
          }
        } else {
          this.alertMessage = response.data.Error;
          this.alertTitle = "Error";
          this.show("top-right", "error");
          self.loading = false;
          self.descargarFacturasBtnTxt = "Descargar Factura";
        }
        // this.textoBotonBuscar = "Buscar Facturas";
      } catch (e) {
        this.alertMessage = "Ocurrio un error";
        this.alertTitle = "Error";
        this.show("top-right", "error");
        self.loading = false;
        self.descargarFacturasBtnTxt = "Descargar Factura";
      }
    },
    descargarXML(xmlString){
            debugger; // eslint-disable-line no-debugger

          var xmltext = xmlString;

          var filename = "Factura_Electronica.xml";
          var pom = document.createElement('a');
          var bb = new Blob([xmltext], {type: 'text/plain'});

          pom.setAttribute('href', window.URL.createObjectURL(bb));
          pom.setAttribute('download', filename);

          pom.dataset.downloadurl = ['text/plain', pom.download, pom.href].join(':');
          pom.draggable = true; 
          pom.classList.add('dragout');

          pom.click();
          //document.body.removeChild(pom);
      },
      mostrarAlerta(val){
        alert(val);
      },
      descargarPDF(pdfBase64){
            debugger; // eslint-disable-line no-debugger

            var filename = "Factura_Electronica.pdf";
            // The actual download
            var link = document.createElement('a');
            //link.href = window.URL.createObjectURL(blob);
            link.href = "data:application/pdf;base64,"+pdfBase64;
            link.download = filename;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
      },
    contadorSeleccionados(row){
      var val = row.Seleccionado;

    debugger; // eslint-disable-line no-debugger
      var self = this
      self.facturas[row.originalIndex].Seleccionado = !val;
      if(!val){
        self.contador++;
      }else{
        self.contador--;
      }
      

      if(self.contador > 9){
        self.disableSeleccionar = true;
      }else{
        self.disableSeleccionar = false;
      }
    },
    limpiarCampoCliente(){
      var self = this;
      
          setTimeout(function(){
            self.clientes = [];
            self.cliente.RFC = "";
            self.cliente.Nombre = "";
          },0);

    },
    descargaMultiple(){
      debugger; // eslint-disable-line no-debugger
      var self = this;
      var selectedDate = moment(self.date).format('YYYYMMDD');
      var today = moment();
      var diferenciaDecimal = today.diff(selectedDate, 'months', true);


      if(diferenciaDecimal < 3){
        var UUIDsArray = [];
        for(var n = 0 ; n < self.facturas.length ; n++){
          if(self.facturas[n].Seleccionado){
            UUIDsArray.push(self.facturas[n].UUID);
          }
        }
        self.descargaMultipleReciente(UUIDsArray);
      }else{
        
        var filesArray = [];
        for(var i = 0 ; i < self.facturas.length ; i++){
          if(self.facturas[i].Seleccionado){
            filesArray.push(self.facturas[i].XmlPath);
          }
        }
        self.descargarAntiguas(filesArray);
      }
    },
    async descargaMultipleReciente(UUIDsArray){
      debugger; // eslint-disable-line no-debugger
      var self = this;
      self.loading = true;
      self.descargarFacturasBtnTxt = "Descargando...";
      const bodyData = {
        "Data": {
            "Usuario": self.usuario,        
            "Files": UUIDsArray        
          }
      };
      try {
        const response = await axios.post("CFDI/GetZipFiles", bodyData);
        if (response.data.Success) {
          if (response.data.response.length == 0) {
            this.alertMessage =
              "No se encontró información";
            this.alertTitle = "Error";
            this.show("top-right", "error");
          } else {

            debugger; // eslint-disable-line no-debugger
            if(response.data.response.CfdiNoFound.length > 0){
              this.alertMessage = "No se encontraron algunas facturas: "+response.data.response.CfdiNoFound;
              this.alertTitle = "Error";
              this.show("top-right", "info");
            }
            self.descargarZip(response.data.response.zip);
            // self.descargarXML(response.data.response.XML);
            // self.descargarPDF(response.data.response.PDF);
          }
        } else {
          this.alertMessage = response.data.Error;
          this.alertTitle = "Error";
          this.show("top-right", "error");
          self.loading = false;
          self.descargarFacturasBtnTxt = "Descargar Facturas";
        }
        // this.textoBotonBuscar = "Buscar Facturas";
      } catch (e) {
        this.alertMessage = "Ocurrio un error";
        this.alertTitle = "Error";
        this.show("top-right", "error");
          self.loading = false;
          self.descargarFacturasBtnTxt = "Descargar Facturas";
      }
    },
    descargarZip(base64Zip){
      var self = this;
      

      var filename = "facturas.zip";//nombreArchivo;
      var link = document.createElement("a");
      link.href = "data:application/zip;base64," + base64Zip;
      link.download = filename;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      self.loading = false;
      self.descargarFacturasBtnTxt = "Descargar Facturas";
    },
    async seleccionarCliente(cliente) {
      var self = this;
        try {
          const response = await axios.post("Cliente/GetCliente", {
            Data: {
              RFC: cliente.RFC,
              Id: 0,
              Tarjeta: "",
            },
          });
          if (response.data.Success) {
            if (response.data.response == null) {
              this.alertMessage =
                "No se encontró información del cliente especificado.";
              this.alertTitle = "Error";
              this.show("top-right", "error");
            } else {
              
              debugger; // eslint-disable-line no-debugger
              self.cliente = response.data.response;
              self.clientes = [];
              self.clienteBuscar = "";
              if(response.data.response.Correos.length>0){
                self.clienteCorreo = response.data.response.Correos[0].Direccion;
              }
              return;
            }
          } else {
            this.alertMessage = response.data.Error;
            this.alertTitle = "Error";
            this.show("top-right", "error");
          }
        } catch (e) {
          this.alertMessage = "Ocurrio un error";
          this.alertTitle = "Error";
          this.show("top-right", "error");
        }
    },
    async getClientes() {
      this.textoBotonBuscar = "Buscando Facturas...";
      try {
        const response = await axios.post("Cliente/GetClientes", {
          Data: {
            Busqueda: this.clienteBuscar,
            IncluirCancelados: 1,
          },
        });
        if (response.data.Success) {
          if (response.data.response.length == 0) {
            this.alertMessage =
              "No se encontró información del cliente especificado.";
            this.alertTitle = "Error";
            this.show("top-right", "error");
          } else {
            this.clientes = response.data.response;
          }
        } else {
          this.alertMessage = response.data.Error;
          this.alertTitle = "Error";
          this.show("top-right", "error");
        }
        this.textoBotonBuscar = "Buscar Facturas";
      } catch (e) {
        this.alertMessage = "Ocurrio un error";
        this.alertTitle = "Error";
        this.show("top-right", "error");
      }
    },

	async getCiudades(){
		try{
        const response = await axios.get('Catalogos/GetCiudades');
		//debugger; // eslint-disable-line no-debugger
        if(response.data.Success){
          if(response.data.response.length == 0){
            this.alertMessage = "No se encontró información.";
            this.alertTitle = "Error";
            this.show('top-right', 'error');
          }
        }else{
            console.log("error")
            this.alertMessage = response.data.Error;
            this.alertTitle = "Error";
            this.show('top-right', 'error');
        }
        this.ciudades = response.data.response;
      }catch(e){
        //this.alert = true;
        //this.error = 'Invalid email/password';
      }
	},
	async getEstaciones(){
		try{
		debugger; // eslint-disable-line no-debugger
        const response = await axios.post('Estacion/GetEstaciones?ciudad='+this.ciudadSelect);
        if(response.data.Success){
          if(response.data.response.length == 0){
            this.alertMessage = "No se encontró información.";
            this.alertTitle = "Error";
            this.show('top-right', 'error');
          }
        }else{
            console.log("error")
            this.alertMessage = response.data.Error;
            this.alertTitle = "Error";
            this.show('top-right', 'error');
        }
        this.estaciones = response.data.response;
        this.productos = [];
        this.getProductos();
      }catch(e){
        //this.alert = true;
        //this.error = 'Invalid email/password';
      }
	},
    redirectTanques(){
      
          this.$router.push({ path: '/tanques' });
    },
			show (group, type = '') {
				const text = this.alertMessage;
				this.$notify({
					group,
					title: this.alertTitle,
					text,
					type,
					data: {
						randomNumber: Math.random()
					}
				})
			},
			clean (group) {
				this.$notify({ group, clean: true })
			},
			swalNotification(swalType) {
				var btnClass = (swalType == 'error') ? 'danger' : swalType;
					btnClass = (swalType == 'question') ? 'primary' : btnClass;
				this.$swal({
					title: 'Are you sure?',
					text: 'You will not be able to recover this imaginary file!',
					type: swalType,
					showCancelButton: true,
					buttonsStyling: false,
					confirmButtonText: swalType.charAt(0).toUpperCase() + swalType.substr(1).toLowerCase(),
					cancelButtonText: 'Cancel',
					confirmButtonClass: 'btn me-5px btn-'+ btnClass +'',
					cancelButtonClass: 'btn btn-default',
				})
			},
      async buscarPorTicket(){
        var self = this;
        // e.preventDefault();
      
  
      
      self.textoBotonBuscar = "Buscando Facturas...";
      self.loading = true;
      try{

        
        debugger; // eslint-disable-line no-debugger
        //alert(self.estatusFacturas);
        const response = await axios.post('CFDI/GetFacturasTicket' ,{
        "Data": {
            "Ticket": self.ticketBuscar,
            }
        });
        if(response.data.Success){
          if(response.data.response.length == 0){
            self.alertMessage = "No se encontro información del día especificado.";
            self.alertTitle = "Error";
            self.show('top-right', 'error');
          }
        }else{
            console.log("error")
            self.alertMessage = response.data.Error;
            self.alertTitle = "Error";
            self.show('top-right', 'error');
        }

        self.contador=0;
        self.disableSeleccionar = false;
        for(var n=0 ; n < response.data.response.length ; n++){
          response.data.response[n].Seleccionado = false;
          response.data.response[n].FechaVentaString = moment(response.data.response[n].FechaVenta).format("DD/MM/YYYY h:mm a");
          response.data.response[n].FechaFacturaString = moment(response.data.response[n].FechaFactura).format("DD/MM/YYYY h:mm a");
        }
        
        self.facturas = response.data.response;


      self.textoBotonBuscar = "Buscar Facturas";
      
      self.loading = false;
      }catch(e){
      self.loading = false;
        //this.alert = true;
        //this.error = 'Invalid email/password';
      }
    },
    async search(){
        var self = this;
      // e.preventDefault();
      
      if(moment(self.date).format('MM') != moment(self.dateEnd).format('MM')){

            self.alertMessage = "Las fechas deben corresponder al mismo mes."
            self.alertTitle = "Error";
            self.show('top-right', 'error');
            return;
      }
      self.textoBotonBuscar = "Buscando Facturas...";
      self.loading = true;
      try{

        self.intDate = self.date.replace('/', '');
        self.intDate = self.intDate.replace('/', '');


        self.intDateEnd = self.dateEnd.replace('/', '');
        self.intDateEnd = self.intDateEnd.replace('/', '');

        var ciudad = self.ciudadSelect!=undefined?self.ciudadSelect:0;
        var estacion = self.estacionSelect!=undefined?self.estacionSelect:0;
        if(self.date != self.dateEnd || estacion == 0){
          self.permitirSeleccionarTodas = false;
          self.seleccionarTodas = false;
        }else{
          self.permitirSeleccionarTodas = true;
          self.seleccionarTodas = false;
        }
    debugger; // eslint-disable-line no-debugger
//alert(self.estatusFacturas);
        const response = await axios.post('CFDI/GetFacturas' ,{
        "Data": {
            "FechaInicial": self.intDate,
            "FechaFinal":self.intDateEnd,
            "RFC": self.cliente.RFC,
            "Ciudad": ciudad, 
            "Estacion": estacion
            }
        });
        if(response.data.Success){
          if(response.data.response.length == 0){
            self.alertMessage = "No se encontro información del día especificado.";
            self.alertTitle = "Error";
            self.show('top-right', 'error');
          }
        }else{
            console.log("error")
            self.alertMessage = response.data.Error;
            self.alertTitle = "Error";
            self.show('top-right', 'error');
        }

        self.contador=0;
        self.disableSeleccionar = false;
        for(var n=0 ; n < response.data.response.length ; n++){
          response.data.response[n].Seleccionado = false;
          response.data.response[n].FechaVentaString = moment(response.data.response[n].FechaVenta).format("DD/MM/YYYY h:mm a");
          response.data.response[n].FechaFacturaString = moment(response.data.response[n].FechaFactura).format("DD/MM/YYYY h:mm a");
        }
        
        self.facturas = response.data.response;


      self.textoBotonBuscar = "Buscar Facturas";
      
      self.loading = false;
      }catch(e){
      self.loading = false;
        //this.alert = true;
        //this.error = 'Invalid email/password';
      }
    }
  },
  watch:{
    radioBusqueda(){
      var self = this;
      var hide = true;
      if(self.radioBusqueda == 'ticket'){
        hide = false;
      }
      for(var n = 0; n < self.columns.length ; n++){
        if(self.columns[n].label =='Despacho'){
          self.columns[n].hidden = hide;
        }
      }
      
    },
    seleccionarTodas(val){
    debugger; // eslint-disable-line no-debugger
      var self = this;
      for(var n=0 ; n < self.facturas.length ; n++){
        self.facturas[n].Seleccionado = val;
      }
      self.contador=0;
    },
  },
	data() {
		return {
      ticketBuscar:'',
      radioBusqueda: 'filtros',
      estatusFacturas: 0,
      descargarFacturasBtnTxt:'Descargar Facturas',
      contador: 0,
      permitirSeleccionarTodas: true,
      seleccionarTodas: false,
      base64Zip: "",
      clienteBuscar:"",
      clientes: [],
      cliente:{
        RFC:''
      },

      loading:false,
      showHeaderFilter: true,
      deshabilitarCombos:false,
      ciudades: [],
      estaciones: [],
      ciudadSelect: 1,
      estacionSelect: 0,
      date: moment().startOf('month').format("YYYY/MM/DD"),
      intDateEnd: "",
      dateEnd: moment().format("YYYY/MM/DD"),
      intDate: "",
      options: {
          format: 'YYYY/MM/DD',
          useCurrent: false,
      },
      pageUserSettings: {},
      alertMessage: "",
      alertTitle: "",
      textoBotonBuscar: "Buscar Facturas",
      
      columns: [
        // {
        //   label: "Id",
        //   field: "Id",
        //   tdClass: "text-center text-nowrap",
        //   thClass: "text-center text-nowrap",
        // },
        {
          label: "Estación",
          field: "Estacion",
          tdClass: "text-center text-nowrap",
          thClass: "text-center text-nowrap",
        },
        {
          label: "Nombre Estación",
          field: "EstacionNombre",
          tdClass: "text-center text-nowrap",
          thClass: "text-center text-nowrap",
        },
        {
          label: "Folio",
          field: "Folio",
          tdClass: "text-center text-nowrap",
          thClass: "text-center text-nowrap",
        },
        {
          label: "Serie",
          field: "Serie",
          tdClass: "text-center text-nowrap",
          thClass: "text-center text-nowrap",
        },
        {
          label: "Fecha Factura",
          field: "FechaFacturaString",
          tdClass: "text-center text-nowrap",
          thClass: "text-center text-nowrap",
        },
        {
          label: "Fecha Venta",
          field: "FechaVentaString",
          tdClass: "text-center text-nowrap",
          thClass: "text-center text-nowrap",
        },
        {
          label: "Despacho",
          field: "Despacho",
          tdClass: "text-center text-nowrap",
          thClass: "text-center text-nowrap",
          hidden: false
        },
        {
          label: "Estatus",
          field: "Estatus",
          tdClass: "text-center text-nowrap",
          thClass: "text-center text-nowrap",
        },
        {
          label: "Total",
          field: "Total",
          tdClass: "text-center text-nowrap",
          thClass: "text-center text-nowrap",
        },
        {
          label: "UUID",
          field: "UUID",
          tdClass: "text-center text-nowrap",
          thClass: "text-center text-nowrap",
        },
        {
          label: "RFC Emisor",
          field: "RFCEmisor",
          tdClass: "text-center text-nowrap",
          thClass: "text-center text-nowrap",
        },
        {
          label: "RFC Receptor",
          field: "RFCReceptor",
          tdClass: "text-center text-nowrap",
          thClass: "text-center text-nowrap",
        },
        {
          label: "Nombre Receptor",
          field: "NombreReceptor",
          tdClass: "text-center text-nowrap",
          thClass: "text-center text-nowrap",
        },
        // {
        //   XmlPath: "Nombre Receptor",
        //   XmlPath: "NombreReceptor",
        //   tdClass: "text-center text-nowrap",
        //   thClass: "text-center text-nowrap",
        // },


        {
          label: "Acciones",
          field: "Acciones",
          width: '20%',
          tdClass: "text-center text-nowrap",
          thClass: "text-center text-nowrap",
        },
        {
          label: "Descarga",
          field: "Descarga",
          width: '10%',
          tdClass: "text-center text-nowrap",
          thClass: "text-center text-nowrap",
        }
      ],
      facturas: [],
		};
	}
}
</script>
<style scoped>
.dx-datagrid-header-panel{
  display: none !important;
}
.loading-search{
  position: absolute;
    width: 100%;
    left: 0px;
    height: 100%;
    background: rgba(0, 0, 0, 0.1);
    z-index: 1000;
    padding-top: 150px;
    top: 0;
}
</style>