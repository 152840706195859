<template>
	<div>
    
    <div class=" loading-search" v-if="loading">
      <div class="fa-3x text-center">
        <i class="fas fa-circle-notch fa-spin text-success" style=""></i>
      </div>
    </div>
		<!-- BEGIN breadcrumb -->
		<ol class="breadcrumb float-xl-end">
			<li class="breadcrumb-item"><a href="javascript:;">Reportes</a></li>
			<li class="breadcrumb-item"><a href="javascript:;">Ventas</a></li>
			<li class="breadcrumb-item active"><a href="javascript:;">Estación</a></li>
		</ol>
		<!-- END breadcrumb -->
		<!-- BEGIN page-header -->
		<h1 class="page-header">Total Seleccionado {{totalSuma | currency}} Pesos | {{ totalSumaUSD | currency }} Dolares | <b> {{ txtImporteUSDRequerido - totalSumaUSD  | currency}} Faltante</b><small></small></h1>
    <!-- <button class="btn" v-on:click="redirectTanques()">tanques</button> -->
		<!-- END page-header -->
		<!-- <a href="javascript:;" v-on:click="show('top-right', 'warn')" class="btn btn-sm btn-primary">Demo</a> -->
		


        <form @submit.prevent="search">   
          <div class="row">
              <div class="col-md-12"> 
                  <div class="form-group row">
                    
                    <div class="col-md-2">
                        <label class="col-form-label">Fecha inicial</label>
                      <div class="input-group">
                        <date-picker id="dateTimeInput" v-model="date" :config="options"></date-picker>
                        <label for="dateTimeInput" class="input-group-text">
                          <i class="fa fa-calendar"></i>
                        </label>
                      </div>
                    </div>

                    
                    <div class="col-md-2">
                        <label class="col-form-label">Fecha final</label>
                      <div class="input-group">
                        <date-picker id="dateTimeInputEnd" v-model="dateEnd" :config="options"></date-picker>
                        <label for="dateTimeInputEnd" class="input-group-text">
                          <i class="fa fa-calendar"></i>
                        </label>
                      </div>
                    </div>
                    <div class="col-md-2">
                        <label class="col-form-label">Ciudad</label>
                        <select class="form-select" @change="getEstaciones()" v-model="ciudadSelect" :disabled="deshabilitarCombos">
                            <option v-for="ciudad in ciudades" :value="ciudad.Id" v-bind:key="ciudad.Id">
                                {{ ciudad.Nombre }}
                            </option>
                        </select>
                    </div>
                    <div class="col-md-2">
                        <label class="col-form-label">Estación</label>
                        <select class="form-select" v-model="estacionSelect" :disabled="deshabilitarCombos">
                          <option value="0">Todas</option>
                            <option v-for="estacion in estaciones" :value="estacion.NoEstacion" v-bind:key="estacion.NoEstacion">
                            {{ estacion.Nombre }}
                            </option>
                          </select>
                    </div>
                    
                    <div class="col-md-2">
                        <label class="col-form-label">Importes</label>
                        <input type="text" class="form-control" v-model="txtImportes">
                        
                    </div>
                    <div class="col-md-2">
                        <label class="col-form-label">Cantidades (Litros)</label>
                        <input type="text" class="form-control" v-model="txtCantidades">
                    </div>




                    <div class="col-md-2">
                        <label class="col-form-label">IEPS 87 Oct</label>
                        <input type="number" step="any" class="form-control" v-model="txtIEPS87">
                    </div>
                    <div class="col-md-2">
                        <label class="col-form-label">IEPS 91 Oct</label>
                        <input type="number" step="any" class="form-control" v-model="txtIEPS91">
                    </div>
                    <div class="col-md-2">
                        <label class="col-form-label">IEPS Diesel</label>
                        <input type="number" step="any" class="form-control" v-model="txtIEPSDiesel">
                    </div>
                    <div class="col-md-2">
                        <label class="col-form-label">Tipo de cambio</label>
                        <input type="number" step="any" class="form-control" v-model="txtTipoCambio">
                    </div>


                    <div class="col-md-2">
                        <label class="col-form-label">IVA</label>
                        <select class="form-select" v-model="ddlIVA" >
                          <!-- <option value="0">Seleccionar</option>
                          <option value=".08">.08</option> -->
                          <option value=".16">.16</option>
                          
                          </select>
                    </div>

                    <div class="col-md-2">
                        <label class="col-form-label">Importe USD Requerido</label>
                        <input type="number" step="any" class="form-control" v-model="txtImporteUSDRequerido">
                    </div>

                    


                    <!-- ddlIVA -->

                    <!-- <div class="col-md-6">
                      <button type="submit" class="btn btn-success me-1 mb-1">{{textoBotonBuscar}}</button>
                    </div> -->
                  </div>
              </div>
              
              <div class="col-md-12" style="text-align: right;">
                    <button type="submit" class="btn btn-success me-1 mb-1" style="margin-top: 3.1em;">{{textoBotonBuscar}}</button>
                    <button type="button" class="btn btn-success me-1 mb-1" style="margin-top: 3.1em;" @click="getSelectedData()">{{ textoBotonGuardar }}</button>
              </div>
            </div>

        </form>
        
  <div>
    <DxDataGrid
      id="gridContainer"
      :data-source="elementosGrid"
      :show-borders="true"
      key-expr="Transaccion"
      @selection-changed="onSelectionChanged"
      :ref="dataGridRef"
    >
      <DxPaging :enabled="true" :page-size="15"/>
      
      <DxSelection mode="multiple"/>
      
<!--       
      <DxColumn
        data-field="Id"
        caption="Id"
      />
      <DxColumn
        :width="160"
        data-field="Fecha"
        data-type="date"
      />
      
      <DxColumn
        data-field="Producto"
        caption="Producto"
      />
      <DxColumn
        data-field="Cantidad"
        caption="Cantidad"
      />
      
      <DxColumn
        data-field="Monto"
        alignment="right"
      /> -->
      <DxColumn
        :width="120"
        data-field="Transaccion"
        caption="Transaccion"
      />
      <DxColumn
          data-field="Gasolinera"
          caption="Gasolinera"
      />
      <DxColumn
          data-field="Estacion"
          caption="Estacion"
      />

      <DxColumn
          data-field="FechaString"
          caption="Fecha"
      />
      <DxColumn
          data-field="HoraString"
          caption="Hora"
      />

      <DxColumn
          data-field="Bomba"
          caption="Bomba"
      />

      <DxColumn
          data-field="CantidadStr"
          caption="Cantidad"
        alignment="right"
      />
      <DxColumn
          data-field="Descripcion"
          caption="Descripcion"
      />
      <DxColumn
          data-field="PrecioStr"
          caption="Precio"
        alignment="right"
      />

      <DxColumn
          data-field="IVAStr"
          caption="IVA"
        alignment="right"
      />
      <DxColumn
          data-field="IEPSStr"
          caption="IEPS"
        alignment="right"
      />
      

      <DxColumn
          data-field="ImporteDolaresStr"
          caption="Importe USD"
        alignment="right"
      />

      <DxColumn
        :width="120"
        alignment="right"
          data-field="TotalStr"
          caption="Total"
      />


      
      <DxSummary :calculate-custom-summary="calculateSelectedRow">
        <DxTotalItem
          name="SelectedRowsSummary"
          summary-type="custom"
          value-format="currency"
          display-format="Total: {0}"
          show-in-column="Total"
        />
      </DxSummary>
    </DxDataGrid>
  </div>

		<!-- bottom left vue-notification -->
		<notifications group="top-right" position="top right" :speed="1000" />
	</div>
</template>

<script>

import axios from 'axios';
import moment from 'moment';



var numeral = require("numeral");
  import Vue from "vue";
  Vue.filter("formatNumber", function (value) {
      return numeral(value).format("0,00.00"); // displaying other groupings/separators is possible, look at the docs
  });
  
  Vue.filter("currency", function (value) {
      return numeral(value).format("$0,0.00"); 
  });

// import {
//   DxPivotGrid,
//   DxFieldChooser,
// } from 'devextreme-vue/pivot-grid';

import {
  DxDataGrid,
  DxColumn,
  DxPaging,
  DxSelection,
  DxSummary,
  DxTotalItem,
} from 'devextreme-vue/data-grid';

const dataGridRef = 'dataGrid';


// import PivotGridDataSource from 'devextreme/ui/pivot_grid/data_source';
import { Workbook } from 'exceljs';
import { saveAs } from 'file-saver-es';

import { exportPivotGrid } from 'devextreme/excel_exporter';
// import { exportDataGrid } from 'devextreme/excel_exporter';

export default {
  mounted: function () {
    debugger; // eslint-disable-line no-debugger
		var userSetting = JSON.parse(localStorage.getItem('userSettings'));
		this.usuario = userSetting.Usuario;
		this.usuarioEstacion = userSetting.Estacion;
		this.IdRol = userSetting.IdRol;
    this.getCiudades();
    this.ciudadSelect = userSetting.CiudadId;
    this.getEstaciones();
    this.estacionSelect = userSetting.Estacion;

    if(this.IdRol == 3 || this.IdRol == 7){
      this.deshabilitarCombos = true;
    }
    this.selectedRowsData = [];

    

  },
	beforeCreate() {
    // var userSetting = localStorage.getItem('userSettings');
    // if(userSetting != undefined){
    //   console.log(JSON.parse(userSetting));
    //   this.pageUserSettings = JSON.parse(userSetting);
    // }else{
		// 	this.$router.push('/login');
    // }
	},
	created() {
		// console.log('created');
	},
	beforeMount() {
		// console.log('beforeMount');
	},
    components: {
        DxDataGrid,
        DxColumn,
        DxPaging,
        DxSelection,
        DxSummary,
        DxTotalItem,
  },
    computed: {
        dataGrid: function() {
		debugger; // eslint-disable-line no-debugger
            return this.$refs[dataGridRef].instance;
        }
    },
  methods: {
    getSelectedData() {
		debugger; // eslint-disable-line no-debugger
        var self = this;
        self.selectedRowsData = this.dataGrid.getSelectedRowsData();

        var estacion = self.estacionSelect!=undefined?self.estacionSelect:0;
        var dataToSend = {
        "Data": {
          "Estacion": estacion,
          "FechaInicial": self.intDate,
          "FechaFin": self.intDateEnd,
          "ImporteUSD": self.totalSumaUSD,
          "ImporteMXN": self.totalSuma,
          "ImporteUSDRequerido": self.txtImporteUSDRequerido,
          "Usuario": self.usuario,
          "Detalle": self.selectedRowsData    
          }
      };

      debugger; // eslint-disable-line no-debugger
      self.setCorreponsalia(dataToSend);
    },
    
    async setCorreponsalia(dataToSend) {
      
      var self = this;
      // this.showNotification("Validacion","Capture la contraseña","warning");
      self.textoBotonGuardar = "Enviando...";
      
      debugger; // eslint-disable-line no-debugger
      
      
      
        try {
          
          self.loading = true;
          const response = await axios.post("Corresponsalia/SetCorreponsalia", dataToSend
          );
          
      debugger; // eslint-disable-line no-debugger
          self.loading = false;
          if (response.data.Success) {
              self.alertMessage = response.data.Message;
              this.alertTitle = "";
              this.show("top-right", "success");
              self.elementosGrid = [];
              
          } else {
            this.alertMessage = response.data.Message;
            this.alertTitle = response.data.Error;
            this.show("top-right", "error");
          }
          this.textoBotonGuardar = "Enviar";
        } catch (e) {
          self.loading = false;
          self.textoBotonGuardar = "Enviar";
            this.alertMessage = "Ocurrio un error";
            this.alertTitle = "Error";
            this.show("top-right", "error");
        }
        
          self.textoBotonGuardar = "Enviar";
    },
    calculateSelectedRow(options) {
      if (options.name === 'SelectedRowsSummary') {
        if (options.summaryProcess === 'start') {
          options.totalValue = 0;
          options.totalValueUSD = 0;
        } else if (options.summaryProcess === 'calculate') {
          if (options.component.isRowSelected(options.value.Transaccion)) {
            options.totalValue += options.value.Total;
            options.totalValueUSD += options.value.ImporteDolares;
          }
        }
      }
      this.totalSuma = options.totalValue;
      this.totalSumaUSD = options.totalValueUSD;
      
      
    },
    onSelectionChanged(e) {
      e.component.refresh(true);
    },
    
    showNotification(title,text,type){
      this.alertTitle = title;
      this.alertMessage = text;
      this.show("top-right", type);
    },
	async getCiudades(){
		try{
        const response = await axios.get('Catalogos/GetCiudades');
		//debugger; // eslint-disable-line no-debugger
        if(response.data.Success){
          if(response.data.response.length == 0){
            this.alertMessage = "No se encontró información.";
            this.alertTitle = "Error";
            this.show('top-right', 'error');
          }
        }else{
            console.log("error")
            this.alertMessage = response.data.Error;
            this.alertTitle = "Error";
            this.show('top-right', 'error');
        }
        this.ciudades = response.data.response;
      }catch(e){
        //this.alert = true;
        //this.error = 'Invalid email/password';
      }
	},
	async getEstaciones(){
		try{
		debugger; // eslint-disable-line no-debugger
        const response = await axios.post('Estacion/GetEstaciones?ciudad='+this.ciudadSelect);
        if(response.data.Success){
          if(response.data.response.length == 0){
            this.alertMessage = "No se encontró información.";
            this.alertTitle = "Error";
            this.show('top-right', 'error');
          }
        }else{
            console.log("error")
            this.alertMessage = response.data.Error;
            this.alertTitle = "Error";
            this.show('top-right', 'error');
        }
        this.estaciones = response.data.response;
        this.productos = [];
        this.getProductos();
      }catch(e){
        //this.alert = true;
        //this.error = 'Invalid email/password';
      }
	},
    redirectTanques(){
      
          this.$router.push({ path: '/tanques' });
    },
			show (group, type = '') {
				const text = this.alertMessage;
				this.$notify({
					group,
					title: this.alertTitle,
					text,
					type,
					data: {
						randomNumber: Math.random()
					}
				})
			},
			clean (group) {
				this.$notify({ group, clean: true })
			},
			swalNotification(swalType) {
				var btnClass = (swalType == 'error') ? 'danger' : swalType;
					btnClass = (swalType == 'question') ? 'primary' : btnClass;
				this.$swal({
					title: 'Are you sure?',
					text: 'You will not be able to recover this imaginary file!',
					type: swalType,
					showCancelButton: true,
					buttonsStyling: false,
					confirmButtonText: swalType.charAt(0).toUpperCase() + swalType.substr(1).toLowerCase(),
					cancelButtonText: 'Cancel',
					confirmButtonClass: 'btn me-5px btn-'+ btnClass +'',
					cancelButtonClass: 'btn btn-default',
				})
			},
      onExporting(e) {
      const workbook = new Workbook();
      const worksheet = workbook.addWorksheet('Sales');

      exportPivotGrid({
        component: e.component,
        worksheet,
      }).then(() => {
        workbook.xlsx.writeBuffer().then((buffer) => {
          saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'ReporteVentasCGEstaciones.xlsx');
        });
      });
      e.cancel = true;
    },
    // onExporting(e) {
    //   const workbook = new Workbook();
    //   const worksheet = workbook.addWorksheet('ReporteVentasEstacion');

    //   exportDataGrid({
    //     component: e.component,
    //     worksheet: worksheet,
    //     autoFilterEnabled: true
    //   }).then(() => {
    //     workbook.xlsx.writeBuffer().then((buffer) => {
    //       saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'Fajillas.xlsx');
    //     });
    //   });
    //   e.cancel = true;
    // },
    async search(e){
        var self = this;
      e.preventDefault();

      debugger; // eslint-disable-line no-debugger
      if(self.ddlIVA == "0"){
            self.alertMessage = "Seleccione IVA";
            self.alertTitle = "";
            self.show('top-right', 'error');
            return;
      }



      if(self.txtImportes == ""){
            self.alertMessage = "Capture Importes";
            self.alertTitle = "Error";
            self.show('top-right', 'error');
            return;
      }

      if(self.txtIEPS87 == ""){
            self.alertMessage = "Capture IEPS 87 Oct";
            self.alertTitle = "Error";
            self.show('top-right', 'error');
            return;
      }
      if(self.txtIEPS91 == ""){
            self.alertMessage = "Capture IEPS 91 Oct";
            self.alertTitle = "Error";
            self.show('top-right', 'error');
            return;
      }
      if(self.txtTipoCambio == ""){
            self.alertMessage = "Capture Tipo de cambio";
            self.alertTitle = "Error";
            self.show('top-right', 'error');
            return;
      }

      


    
    
    

      // if(self.txtCantidades == ""){
      //       self.alertMessage = "Capture Cantidades";
      //       self.alertTitle = "Error";
      //       self.show('top-right', 'error');
      //       return;
      // }

      if(self.txtImportes == ""){
            self.alertMessage = "Capture Importes";
            self.alertTitle = "Error";
            self.show('top-right', 'error');
            return;
      }

      debugger; // eslint-disable-line no-debugger
      var cantidades = self.txtCantidades.split(",");
      var importes = self.txtImportes.split(",");
      if(cantidades != null && cantidades.length == 1 && cantidades[0] == ""){
        cantidades = [];
      }
      debugger; // eslint-disable-line no-debugger
      // var mesInicio = moment(self.date).format('MM');
      // var mesfin = moment(self.dateEnd).format('MM');

      if(moment(self.date).format('MM') != moment(self.dateEnd).format('MM')){

            self.alertMessage = "Las fechas deben corresponder al mismo mes."
            self.alertTitle = "Error";
            self.show('top-right', 'error');
            return;
      }
      self.textoBotonBuscar = "Buscando...";
      self.loading = true;
      try{
        self.intDate = self.date.replace('/', '');
        self.intDate = self.intDate.replace('/', '');


        self.intDateEnd = self.dateEnd.replace('/', '');
        self.intDateEnd = self.intDateEnd.replace('/', '');

        // var ciudad = self.ciudadSelect!=undefined?self.ciudadSelect:0;
        var estacion = self.estacionSelect!=undefined?self.estacionSelect:0;
        debugger; // eslint-disable-line no-debugger

        const response = await axios.post('Corresponsalia/GetCorreponsaliaDesp' ,{
        "Data": {
            "FechaInicial": self.intDate,
            "FechaFin": self.intDateEnd,
            "Estacion": estacion,
            "Importes": importes,
            "Cantidades": cantidades,


            "TasaIVA" : self.ddlIVA,
            "IEPS87" : self.txtIEPS87,
            "IEPS91" : self.txtIEPS91,
            "IEPSDiesel" : self.txtIEPSDiesel,
            "TipoCambio" : self.txtTipoCambio
        }
        });
        
		debugger; // eslint-disable-line no-debugger
        self.elementosGrid = [];
        
      self.textoBotonBuscar = "Buscar";
        if(response.data.Success){
          if(response.data.response.length == 0){
            self.alertMessage = "No se erncontro información del día especificado.";
            self.alertTitle = "Error";
            self.show('top-right', 'error');
            self.loading = false;
            return;
          }
        }else{
            console.log("error")
            self.alertMessage = response.data.Message;
            self.alertTitle = "Error";
            self.show('top-right', 'error');
            
            self.loading = false;
            return;
        }
        var respuesta = response.data.response;
        
		debugger; // eslint-disable-line no-debugger
        var index = 0;
        for(var n=0;n<respuesta.length;n++){
            index++;
            respuesta[n].FechaString = moment(respuesta[n].Fecha).format("DD/MM/YYYY");
            var hrStr = respuesta[n].Hora != null ? respuesta[n].Hora.split(":"):"00:00:00".split(":");
            respuesta[n].HoraString = hrStr[0]+":"+hrStr[1];

            respuesta[n].TotalStr = numeral(respuesta[n].Total).format("0,00.00");
            respuesta[n].PrecioStr = numeral(respuesta[n].Precio).format("0,00.00");
            respuesta[n].ImporteDolaresStr = numeral(respuesta[n].ImporteDolares).format("0,00.00");
            respuesta[n].IVAStr = numeral(respuesta[n].IVA).format("0,00.00");
            respuesta[n].IEPSStr = numeral(respuesta[n].IEPS).format("0,00.00");
            respuesta[n].CantidadStr = numeral(respuesta[n].Cantidad).format("0,00.00");

            
            respuesta[n].Id = index;
        }


        self.elementosGrid = respuesta;
        
        
        

      
      self.loading = false;
      }catch(e){
        self.elementosGrid = [];
        
        self.alertMessage = "";
            self.alertTitle = "Error";
            self.show('top-right', 'error');
      self.loading = false;
        //this.alert = true;
        //this.error = 'Invalid email/password';
      }
    }
  },
	data() {
		return {
      txtImporteUSDRequerido:0,
      textoBotonGuardar: "Enviar",
      ddlIVA:".16",
      txtIEPS87: "0.4369",
      txtIEPS91: "0.5331",
      txtIEPSDiesel: "0.3626",
      txtTipoCambio: "",
      Importes:[],
      Cantidades:[],
      txtImportes: "5,10,15,20",
      txtCantidades: "5,10,15,20",
      dataGridRef,
      selectedRowsData: [],
      totalSuma:0,
      totalSumaUSD:0,
      elementosGrid:[],
      allowSearch: true,
      showRelevantValues: true,
      dataSource: {
      },
      // applyChangesModes: ['instantly', 'onDemand'],
      applyChangesMode: 'instantly',
      
      loading:false,
      showHeaderFilter: true,
      deshabilitarCombos:false,
      ciudades: [],
      estaciones: [],
      ciudadSelect: 1,
      estacionSelect: 0,
            date: "2016/09/01",//,moment().startOf('month').format("YYYY/MM/DD"),
            intDateEnd: "",
            dateEnd: "2016/09/30",//moment().format("YYYY/MM/DD"),
            intDate: "",
            options: {
                format: 'YYYY/MM/DD',
                useCurrent: false,
            },
            pageUserSettings: {},
            alertMessage: "",
            alertTitle: "",
            reporteCre: [],
            textoBotonBuscar: "Buscar",
		};
	}
}
</script>
<style scoped>
.dx-datagrid-header-panel{
  display: none !important;
}
.loading-search{
  position: absolute;
    width: 100%;
    left: 0px;
    height: 100%;
    background: rgba(0, 0, 0, 0.1);
    z-index: 1000;
    padding-top: 150px;
    top: 0;
}
</style>