<template>
	<div>
		<!-- BEGIN breadcrumb -->
		<ol class="breadcrumb float-xl-end">
			<li class="breadcrumb-item"><a href="javascript:;">Inicio</a></li>
			<li class="breadcrumb-item active"><a href="javascript:;">Monitor de tanques</a></li>
		</ol>
		<!-- END breadcrumb -->
		<!-- BEGIN page-header -->
		<h1 class="page-header">Monitor de tanques</h1>
		<!-- END page-header -->
		<!-- <a href="javascript:;" v-on:click="show('bottom-left', 'warn')" class="btn btn-sm btn-primary">Demo</a> -->
		<div class="row mb-15px">
			<div class="col-md-4">
				<label class="form-label col-form-label col-md-3">Ciudad</label>
				<div class="col-md-9">
					<select class="form-select" @change="getLecturas" v-model="ciudadSelect">
						<option value="0">Todas</option>
						<option v-for="ciudad in ciudades" :value="ciudad.Id" v-bind:key="ciudad.Id">
						{{ ciudad.Nombre }}
						</option>
						<!-- <option value="0">Todas</option>
						<option value="1">Tijuana</option>
						<option value="2">Hermosillo</option>
						<option value="3">Chihuahua</option>
						<option value="4">Obregón</option>
						<option value="5">Mexicali</option>
						<option value="6">Rosarito</option>
						<option value="7">Tecate</option>
						<option value="8">Culiacán</option>
						<option value="9">Tepic</option>
						<option value="10">Puebla</option> -->
					</select>
				</div>
			</div>
			<div class="col-md-4">
				<label class="form-label col-form-label col-md-3">Ordenar por:</label>
				<div class="col-md-9">
					<select class="form-select" @change="getLecturas" v-model="sortSelect">
						<option value="1">Número de estación</option>
						<option value="2">Volumen ascendente</option>
						<option value="3">Volumen descendente</option>
						<option value="4">Nivel de H2O Descendente</option>
					</select>
				</div>
			</div>
			<div class="col-md-4">
				<download-excel
					class="btn btn-lg btn-success rounded-pill mt-35"
					:data="excelJSON"
					:fields="json_fields"
					worksheet="Lecturas"
					:name="`Lectura de tanques ${date}.xls`"
					
					>
					
						Descargar en Excel <i class="fas fa-file-excel fa-fw"></i>
				</download-excel>
			</div>
		</div>
		
		<div class="row" v-for="estacion in estaciones" :key="estacion.Estacion">
			<div class="col-md-12">
				<h2>Estacion {{ estacion.Estacion }} {{estacion.NoEstacion}} 
					<button  class="btn btn-xs btn-success"  v-b-modal.modalDialog @click="getTanqueTransacciones(estacion)">Ver lecturas</button>
				</h2>

			</div>
      
			<div class="col-xl-3 col-md-6" v-for="tanque in estacion.Lecturas" :key="tanque.CodigoTanque">
				<div class="widget widget-stats bg-white">
					<div class="stats-icon stats-icon-lg">
						<i class="fa fa-gas-pump fa-fw blink_me" style="color: red" v-if="tanque.Volumnen <= tanque.CapacidadEmergencia || tanque.VolumenH2O >= tanque.CapacidadEmergenciaH2O"></i>
						<i class="fa fa-gas-pump fa-fw" v-else></i>
					</div>
					<div class="stats-content">
						<div class="stats-title">
							<div class="row">
								<div class="col-md-4"><label>{{tanque.Producto}}</label></div>
								<div class="col-md-4"><label v-bind:class="getColorH2O(tanque.VolumenH2O, tanque.CapacidadMaxH2O)">VolumenH2O: {{tanque.VolumenH2O}}</label></div>
								<div class="col-md-4">
									<h6 class="textRight">
										<img v-if="tanque.CodigoProducto == 62 && estacion.Empresa == 'RCH'" src="/assets/img/product/mega.jpg" alt="">
										<img v-if="tanque.CodigoProducto == 63 && estacion.Empresa == 'RCH'" src="/assets/img/product/ultra.jpg" alt="">
										<img v-if="tanque.CodigoProducto == 64 && estacion.Empresa == 'RCH'" src="/assets/img/product/diesel.jpg" alt="">
										
										<img v-if="tanque.CodigoProducto == 62 && estacion.Empresa == 'USF'" src="/assets/img/product/super.jpg" alt="">
										<img v-if="tanque.CodigoProducto == 63 && estacion.Empresa == 'USF'" src="/assets/img/product/premium.jpg" alt="">
										<img v-if="tanque.CodigoProducto == 64 && estacion.Empresa == 'USF'" src="/assets/img/product/diesel.jpg" alt="">
									</h6>
								</div>

								
							</div>
							
						</div>
						<div class="stats-number" v-bind:class="getColorVolumen(tanque.Volumnen, tanque.CapacidadMinima, tanque.CapacidadCritica)">{{tanque.Volumnen | formatNumberInt}} Lts</div>
						<div class="stats-desc" style="text-align: right;">{{tanque.Porcentaje}} %</div>
						<div class="stats-progress progress">
							<div class="progress-bar"  :style="{width: tanque.Porcentaje + '%'}"></div>
						</div>
						<div class="stats-desc mb5">Capacidad Tanque: {{tanque.CapacidadTanque | formatNumber}} Lts</div>
						<div class="stats-desc mb5">Capacidad Maxima: {{tanque.CapacidadMaxima | formatNumber}} Lts</div>
						<div class="stats-desc mb5">Venta promedio por hora: {{tanque.PromedioVenta | formatNumber}} Lts</div>
						<div class="stats-desc mb5">Fecha Lectura: <label v-bind:class="getFechaLecturaClass(tanque.FechaActual, tanque.FechaLectura)">{{tanque.FechaLecturaFormato}}</label>		 </div>
						<div class="stats-desc" style="text-align: right">
							<b>
								<label 
									v-if="tanque.Volumnen <= tanque.CapacidadEmergencia || tanque.VolumenH2O >= tanque.CapacidadEmergenciaH2O"
									class="blink_me" style="color: red;"
								>
									Parar Venta
								</label>
								<label for="" v-else style="color: transparent">|</label>
							</b> 
						</div>

						<div class="stats-desc">

							<!-- EstatusPipa -->
							<button v-if="tanque.EstatusPipa == 0" class="btn btn-xs btn-gray" @click="pipaEnTransito(estacion.NoEstacion, tanque)">
								Asignar Pipa
							</button>

							<button v-if="tanque.EstatusPipa == 1" class="btn btn-xs btn-success btn-green" @click="pipaEnTransito(estacion.NoEstacion, tanque)">
								Pipa en transito
							</button>
						</div>


						
						
					</div>
				</div>
			</div>
		</div>



		
    <!-- #modal-dialog -->
    <b-modal id="modalDialog" size="xl">
      <template slot="modal-header">
        <h4 class="modal-title">Lecturas</h4>
        <a class="btn-close" @click="$bvModal.hide('modalDialog')"></a>
      </template>

      <div>
        <!-- <form action="/" method="POST">
          <fieldset>
            <div class="mb-3">
              <label class="form-label" for="txtFecha">Fecha</label>
              <input
                class="form-control"
                type="text"
                id="txtFecha"
                placeholder="Fecha"
                v-model="inputModal.Fecha"
              />
            </div>

          </fieldset>
        </form> -->
<!-- 
		<img v-bind:src="'data:image/png;base64,'+ticketBase64" style="height: 150px;"/>
		<button class="btn btn-sm btn-primary" @click="descargarTicket()">Descargar</button> -->

		<div>


			<vue-good-table
				:columns="columns"
				:rows="tanqueTransacciones"
				:lineNumbers="false"
				:search-options="{ enabled: true, placeholder: 'Buscar' }"
				:pagination-options="{ enabled: true, position: 'bottom' }"
			>
				<template slot="table-row" slot-scope="props">
				<span v-if="props.column.label == 'Acciones'">
					<button
					class="btn btn-xs btn-success"
					@click="verTicket(props.row)"
					>
					Descargar Ticket
					</button>
					
				</span>
				<span v-else-if="props.column.field == 'Fecha'">
					
					<label v-html='convertirFecha(props.row.Fecha)'></label>
					
				</span>
				</template>
			</vue-good-table>


		</div>
      </div>

      <template slot="modal-footer">
        
        <button
          type="button"
          class="btn btn-secondary"
          @click="$bvModal.hide('modalDialog')"
        >
          Cerrar
        </button>
      </template>
    </b-modal>

    <!-- bottom left vue-notification -->
    <notifications group="top-right" position="top right" :speed="1000" />
	</div>
</template>

<script>

import axios from 'axios';
import moment from 'moment';


import Vue from "vue";
import JsonExcel from "vue-json-excel";

Vue.component("downloadExcel", JsonExcel);
var numeral = require("numeral");
Vue.filter("formatNumber", function (value) {
	return numeral(value).format("0,0"); // displaying other groupings/separators is possible, look at the docs
});


Vue.filter("formatNumberInt", function (value) {
	return numeral(value).format("0,0,0"); // displaying other groupings/separators is possible, look at the docs
});

export default {
	mounted() {
		this.getCiudades();
		this.getLecturas();
				
		// debugger; // eslint-disable-line no-debugger
		var userSetting = JSON.parse(localStorage.getItem('userSettings'));
		this.usuario = userSetting.Usuario;
		this.usuarioEstacion = userSetting.Estacion;
		this.IdRol = userSetting.IdRol;

		this.intervalId = window.setInterval(() => {
			this.getLecturas()
		}, 60000)
	},
	beforeRouteLeave(to, from , next){
		window.clearInterval(this.intervalId)
		next()
	},
  methods: {
	convertirFecha(fecha){
		moment.locale('es');
		var fechaFormato = moment(fecha).format("DD/MMM/YYYY");
		return fechaFormato;
	},
	descargarTicket(){

			// debugger; // eslint-disable-line no-debugger
			var self = this;
			var filename = "ticket.png";
			// The actual download
			var link = document.createElement("a");
			//link.href = window.URL.createObjectURL(blob);
			link.href = "data:image/png;base64," + self.ticketBase64;
			link.download = filename;
			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);


	},
    async verTicket(ticket) {
		var self = this;

      try {
        const response = await axios.post("Tanque/GetTicketTransaccion", {
          Data: {
			"Gasolinera": ticket.Gasolinera,
			"Transaccion": ticket.Transaccion
			},
        });
		
		// debugger; // eslint-disable-line no-debugger
        if (response.data.Success) {
			self.ticketBase64 = response.data.response.Ticket;
			self.descargarTicket();
			self.ticketFound = true;
        } else {
			self.alertMessage = response.data.Message;
			self.alertTitle = "Error";
			self.show("top-right", "error");
			self.ticketBase64 = 'iVBORw0KGgoAAAANSUhEUgAAACQAAAAqCAIAAABUaG6/AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAA1SURBVFhH7c1BDQAgAAMx/JseJhoe5GqgZw+VEWVEGVFGlBFlRBlRRpQRZUQZUUaUEb9m2wUbYadIu2AV/AAAAABJRU5ErkJggg==';
			self.ticketFound = false;
        }
		
      } catch (e) {
		self.ticketBase64 = 'iVBORw0KGgoAAAANSUhEUgAAACQAAAAqCAIAAABUaG6/AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAA1SURBVFhH7c1BDQAgAAMx/JseJhoe5GqgZw+VEWVEGVFGlBFlRBlRRpQRZUQZUUaUEb9m2wUbYadIu2AV/AAAAABJRU5ErkJggg==';
		self.ticketFound = false;
        this.alertMessage = "Ocurrio un error";
        this.alertTitle = "Error";
        this.show("top-right", "error");
      }
    },
    async getTanqueTransacciones(estacion) {
		var self = this;
		self.ticketBase64 = 'iVBORw0KGgoAAAANSUhEUgAAACQAAAAqCAIAAABUaG6/AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAA1SURBVFhH7c1BDQAgAAMx/JseJhoe5GqgZw+VEWVEGVFGlBFlRBlRRpQRZUQZUUaUEb9m2wUbYadIu2AV/AAAAABJRU5ErkJggg==';
		self.intDate = moment().format("YYYY/MM/DD");
        self.intDate = self.intDate.replace('/', '');
        self.intDate = self.intDate.replace('/', '');
		
      try {
        const response = await axios.post("Tanque/GetTanqueTransacciones", {
          Data: {
			"Fecha": self.intDate,
			"Gasolinera": estacion.Gasolinera
			},
        });
		
		debugger; // eslint-disable-line no-debugger
        if (response.data.Success) {
          if (response.data.response.length == 0) {
			self.alertMessage = "No se encontró información del tanque seleccionado.";
			self.alertTitle = "Error";
			self.show("top-right", "error");
			self.tanqueTransacciones = [];
          } else {
            self.tanqueTransacciones = response.data.response;
          }
        } else {
			self.alertMessage = response.data.Error;
			self.alertTitle = "Error";
			self.show("top-right", "error");
			self.tanqueTransacciones = [];
        }
		
      } catch (e) {
		self.tanqueTransacciones = [];
        this.alertMessage = "Ocurrio un error";
        this.alertTitle = "Error";
        this.show("top-right", "error");
      }
    },
		async pipaEnTransito(NoEstacion, tanque ){
			var self = this;
			var textoConfirmar = "";
			var estatusPipa = 0;


			if(tanque.EstatusPipa == 0){
				estatusPipa = 1;
				textoConfirmar = "Confirma poner la pipa en transito?";
			}
			if(tanque.EstatusPipa == 1){
				estatusPipa = 0;
				textoConfirmar = "Confirma que ya se recibió la pipa?";
			}

			// this.usuarioEstacion = userSetting.Estacion;
			// this.IdRol = userSetting.IdRol;
			
			if(
				(this.IdRol == 6 || this.IdRol == 1)
				||
				((this.IdRol == 3 || this.IdRol == 7) && (this.usuarioEstacion == NoEstacion && tanque.EstatusPipa == 1))
			){
				
			if(confirm(textoConfirmar)){

						// debugger; // eslint-disable-line no-debugger
					try{
						const postData = {
						"Data":{
							"NoEstacion": NoEstacion,
							"CodigoTanque": tanque.CodigoTanque,
							"CodigoProducto": tanque.CodigoProducto,
							"Usuario": self.usuario,
							"Estatus": estatusPipa
							}
						};
						const response = await axios.post('Tanque/SetPipaTransito',postData);
						
						if(response.data.Success){
							if(response.data.response.length == 0){
								this.alertMessage = "No es posible establecer pipa en transito.";
								this.alertTitle = "Error";
								this.show('top-right', 'error');
							}
						}else{
							console.log("error")
							this.alertMessage = response.data.Error;
							this.alertTitle = "Error";
							this.show('top-right', 'error');
						}
						
						this.alertMessage = response.data.Message;
						this.alertTitle = "Correcto";
						this.show('top-right', 'success');
						self.getLecturas();
					}catch(e){
						//this.alert = true;
						//this.error = 'Invalid email/password';
					}


				}
			}
			else{
				this.alertMessage = "No cuenta con permisos para modificar";
				this.alertTitle = "No autorizado";
				this.show('top-right', 'error');
			}
		},
		buildExcelJson(data){
		var rows = [];
		var row = {};
		var estaciones = data;
		for(var n = 0 ; n < estaciones.length ; n++){
			
		// debugger; // eslint-disable-line no-debugger
			for(var x = 0; x< estaciones[n].Lecturas.length ;x++){
				row = {};
				row.Ciudad = estaciones[n].Ciudad;
				row.NoEstacion = estaciones[n].NoEstacion;
				row.Estacion = estaciones[n].Estacion;
				row.Gasolinera = estaciones[n].Gasolinera;

				row.CodigoProducto = estaciones[n].Lecturas[x].CodigoProducto;
				row.Producto = estaciones[n].Lecturas[x].Producto;
				row.CodigoTanque = estaciones[n].Lecturas[x].CodigoTanque;
				row.NumeroTanque = estaciones[n].Lecturas[x].NumeroTanque;
				row.CapacidadTanque = estaciones[n].Lecturas[x].CapacidadTanque;
				row.CapacidadMaxima = estaciones[n].Lecturas[x].CapacidadMaxima;
				// row.CapacidadOperacional = estaciones[n].Lecturas[x].CapacidadOperacional;
				row.UltimaTransaccion = estaciones[n].Lecturas[x].UltimaTransaccion;
				row.FechaLectura = estaciones[n].Lecturas[x].FechaLectura;
				row.FechaLecturaFormato = estaciones[n].Lecturas[x].FechaLecturaFormato;
				row.Volumnen = estaciones[n].Lecturas[x].Volumnen;
				row.VolumenAnterior = estaciones[n].Lecturas[x].VolumenAnterior;
				row.VolumenH2O = estaciones[n].Lecturas[x].VolumenH2O;
				row.FechaProcesamiento = estaciones[n].Lecturas[x].FechaProcesamiento;
				row.Porcentaje = estaciones[n].Lecturas[x].Porcentaje;
				row.CapacidadMinima = estaciones[n].Lecturas[x].CapacidadMinima;
				row.CapacidadCritica = estaciones[n].Lecturas[x].CapacidadCritica;
				row.CapacidadMaxH2O = estaciones[n].Lecturas[x].CapacidadMaxH2O;
				row.FechaActual = estaciones[n].Lecturas[x].FechaActual;
				rows.push(row);
			}
		}
		this.excelJSON = rows;
	},
	async getCiudades(){
		try{
        const response = await axios.get('Catalogos/GetCiudades');
		//debugger; // eslint-disable-line no-debugger
        if(response.data.Success){
          if(response.data.response.length == 0){
            this.alertMessage = "No se encontró información.";
            this.alertTitle = "Error";
            this.show('top-right', 'error');
          }
        }else{
            console.log("error")
            this.alertMessage = response.data.Error;
            this.alertTitle = "Error";
            this.show('top-right', 'error');
        }
        //console.log("lectura tanques: "+ JSON.stringify(response.data.response));
        this.ciudades = response.data.response;
      }catch(e){
        //this.alert = true;
        //this.error = 'Invalid email/password';
      }
	},
	async getLecturas(){
		try{
        const response = await axios.get('Tanque/GetLecturas?ciudad='+this.ciudadSelect+'&sort='+this.sortSelect);
		// debugger; // eslint-disable-line no-debugger
        if(response.data.Success){
          if(response.data.response.length == 0){
            this.alertMessage = "No se encontró información.";
            this.alertTitle = "Error";
            this.show('top-right', 'error');
          }
        }else{
            console.log("error")
            this.alertMessage = response.data.Error;
            this.alertTitle = "Error";
            this.show('top-right', 'error');
        }
        //console.log("lectura tanques: "+ JSON.stringify(response.data.response));
        this.estaciones = response.data.response;
		const today = new Date();
		const date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
		const time = today.getHours() + "." + today.getMinutes();
		const dateTime = date +' '+ time;
		this.date = dateTime;
		this.buildExcelJson(this.estaciones);
      }catch(e){
        //this.alert = true;
        //this.error = 'Invalid email/password';
      }
	},
	getColorVolumen(volumenActual, capacidadMinima, capacidadCritica){
		// v-bind:class="getColorClass(tanque.VolumenActual)"
		if(volumenActual > capacidadMinima){
			return 'font-green';
		}else if(volumenActual <= capacidadMinima && volumenActual >= capacidadCritica){
			return 'font-orange';
		}else if(volumenActual < capacidadCritica){
			return 'font-red';
		}
	},
	getColorH2O(volumen,capacidadMaximaH2O){
		if(volumen > capacidadMaximaH2O){
			return 'font-red';
		}else{
			return '';
		}
	},
	getFechaLecturaClass(fechaActual, fechaLectura){
		// debugger; // eslint-disable-line no-debugger
		var hours = Math.abs(Date.parse(fechaActual) - Date.parse(fechaLectura)) / 36e5
		if(hours>=1){
			return 'font-red';
		}else{
			return '';
		}

	},
	show (group, type = '') {
		
		// debugger; // eslint-disable-line no-debugger
		const text = this.alertMessage;
		this.$notify({
			group,
			title: this.alertTitle,
			text,
			type,
			data: {
				randomNumber: Math.random()
			}
		})
	},
	clean (group) {
		this.$notify({ group, clean: true })
	},
	swalNotification(swalType) {
		var btnClass = (swalType == 'error') ? 'danger' : swalType;
			btnClass = (swalType == 'question') ? 'primary' : btnClass;
		this.$swal({
			title: 'Are you sure?',
			text: 'You will not be able to recover this imaginary file!',
			type: swalType,
			showCancelButton: true,
			buttonsStyling: false,
			confirmButtonText: swalType.charAt(0).toUpperCase() + swalType.substr(1).toLowerCase(),
			cancelButtonText: 'Cancel',
			confirmButtonClass: 'btn me-5px btn-'+ btnClass +'',
			cancelButtonClass: 'btn btn-default',
		})
	},
  },
	data() {
		return {
			ticketFound:false,
		ticketBase64:'iVBORw0KGgoAAAANSUhEUgAAACQAAAAqCAIAAABUaG6/AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAA1SURBVFhH7c1BDQAgAAMx/JseJhoe5GqgZw+VEWVEGVFGlBFlRBlRRpQRZUQZUUaUEb9m2wUbYadIu2AV/AAAAABJRU5ErkJggg==',
		intDate: "",
		tanqueTransacciones:[],      
		columns: [
        {
          label: "Transaccion",
          field: "Transaccion",
          type: "number",
          tdClass: "text-center text-nowrap",
          thClass: "text-center text-nowrap",
        },
        {
          label: "Tanque",
          field: "NumeroTanque",
          type: "number",
          tdClass: "text-center text-nowrap",
          thClass: "text-center text-nowrap",
        },
        {
          label: "Producto",
          field: "Producto",
          type: "number",
          tdClass: "text-center text-nowrap",
          thClass: "text-center text-nowrap",
        },
        {
          label: "Fecha",
          field: "Fecha",
          tdClass: "text-center text-nowrap",
          thClass: "text-center text-nowrap",
			type: 'date',
			dateInputFormat: 'MM/DD/YYYY HH:mm:ss',
			dateOutputFormat: 'DD-MM-YYYY',
			formatFn: function (value) {
				return value != null ? value : null
			}
        },
        {
          label: "Hora",
          field: "Hora",
          type: "number",
          tdClass: "text-center text-nowrap",
          thClass: "text-center text-nowrap",
        },
        {
          label: "Volumen Anterior",
          field: "VolumenAnterior",
          type: "number",
          tdClass: "text-center text-nowrap",
          thClass: "text-center text-nowrap",
        },
        {
          label: "Volumen",
          field: "Volumen",
          type: "number",
          tdClass: "text-center text-nowrap",
          thClass: "text-center text-nowrap",
        },
        {
          label: "Volumen H2O",
          field: "VolumenH2O",
          type: "number",
          tdClass: "text-center text-nowrap",
          thClass: "text-center text-nowrap",
        },
        {
          label: "Temperatura",
          field: "Temperatura",
          type: "number",
          tdClass: "text-center text-nowrap",
          thClass: "text-center text-nowrap",
        },
        {
          label: "Procesado",
          field: "FechaProcesamiento",
          type: "number",
          tdClass: "text-center text-nowrap",
          thClass: "text-center text-nowrap",
        },
        {
          label: "Acciones",
          field: "Acciones",
          width: "20%",
          tdClass: "text-center text-nowrap",
          thClass: "text-center text-nowrap",
        },
      ],
			inputModal:{
				fecha:""
			},
			ciudades:[],
			usuarioEstacion:0,
			IdRol:0,
			usuario:'',
			alertMessage:'',
			alertTitle:'',
			date:"2021-12-02 10.00",
		excelJSON:[],
		json_fields: {
			"Ciudad":"Ciudad",
			"No Estacion":"NoEstacion",
			"Estacion":"Estacion",
			"Gasolinera":"Gasolinera",
			
			"Codigo Producto":"CodigoProducto",
			"Producto":"Producto",
			"Codigo Tanque":"CodigoTanque",
			"Numero Tanque":"NumeroTanque",
			"Capacidad Tanque":"CapacidadTanque",
			"Capacidad Maxima":"CapacidadMaxima",
			"Capacidad Minima":"CapacidadMinima",
			"Capacidad Critica":"CapacidadCritica",
			"Capacidad Max H2O":"CapacidadMaxH2O",
			"Ultima Transaccion":"UltimaTransaccion",
			"Fecha Lectura":"FechaLecturaFormato",
			"Volumen":"Volumnen",
			"Volumen Anterior":"VolumenAnterior",
			"Volumen H2O":"VolumenH2O",
			"Fecha Procesamiento":"FechaProcesamiento",
			"Porcentaje":"Porcentaje",
		},
		sortSelect: 1,
		ciudadSelect:0,
		estaciones:[],
		intervalId: null,
		};
	}
}
</script>

<style scoped>
.mb10{
	margin-bottom: 10px !important;
}
.mb5{
	margin-bottom: 5px !important;
}
.font-red{
	color: red !important;
}
.font-orange{
	color: orange !important;
}
.font-green{
	color: green !important;
}
.stats-title, .stats-desc{
	color: #555 !important;
}
.bg-white, .bg-teal-500 {
    background-color: #eee;
    border: 1px solid #AAB;
	color: #555 !important;
}

.widget-stats .stats-number, .widget.widget-stats .stats-number{
  font-size: 20px;
}
.widget-stats .stats-number, .widget.widget-stats .stats-number {
    font-size: 24px;
    margin-bottom: 0.25rem;
    font-weight: 600;
    letter-spacing: 1px;
}

.widget-stats .stats-icon.stats-icon-lg, .widget.widget-stats .stats-icon.stats-icon-lg {
    color: #999;
    text-shadow: 5px 10px rgb(0 0 0 / 15%);
    opacity: 0.15;
}
.widget-stats .stats-progress, .widget.widget-stats .stats-progress {
    height: 4px !important;
}
.mt-35{
	margin-top: 2rem;
}
.textRight{
	text-align: right;
}
.blink_me {
  animation: blinker 1s linear infinite;
}

@keyframes blinker {  
  50% { opacity: 0; }
}
.btn-green{
	background-color: limegreen !important;
	border-color: limegreen !important;
}
</style>