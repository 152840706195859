<template>
	<div>
    
    <div class=" loading-search" v-if="loading">
      <div class="fa-3x text-center">
        <i class="fas fa-circle-notch fa-spin text-success" style=""></i>
      </div>
    </div>
		<!-- BEGIN breadcrumb -->
		<ol class="breadcrumb float-xl-end">
			<li class="breadcrumb-item"><a href="javascript:;">Reportes</a></li>
			<li class="breadcrumb-item"><a href="javascript:;">comisiones</a></li>
			<li class="breadcrumb-item active"><a href="javascript:;">Comparativo CG vs Addes</a></li>
		</ol>
		<!-- END breadcrumb -->
		<!-- BEGIN page-header -->
		<h1 class="page-header">Comparativo CG vs Addes <small></small></h1>
    <!-- <button class="btn" v-on:click="redirectTanques()">tanques</button> -->
		<!-- END page-header -->
		<!-- <a href="javascript:;" v-on:click="show('top-right', 'warn')" class="btn btn-sm btn-primary">Demo</a> -->
		


        <form @submit.prevent="search">   
          <div class="row">
              <div class="col-md-8"> 
                  <div class="form-group row">
                    
                    <div class="col-md-3">
                        <label class="col-form-label">Fecha inicial</label>
                      <div class="input-group">
                        <date-picker id="dateTimeInput" v-model="date" :config="options"></date-picker>
                        <label for="dateTimeInput" class="input-group-text">
                          <i class="fa fa-calendar"></i>
                        </label>
                      </div>
                    </div>

                    
                    <div class="col-md-3">
                        <label class="col-form-label">Fecha final</label>
                      <div class="input-group">
                        <date-picker id="dateTimeInputEnd" v-model="dateEnd" :config="options"></date-picker>
                        <label for="dateTimeInputEnd" class="input-group-text">
                          <i class="fa fa-calendar"></i>
                        </label>
                      </div>
                    </div>
                    <div class="col-md-3">
                        <label class="col-form-label">Ciudad</label>
                        <select class="form-select"  v-model="ciudadSelect" :disabled="deshabilitarCombos">
                            <option v-for="ciudad in ciudades" :value="ciudad.Id" v-bind:key="ciudad.Id">
                                {{ ciudad.Nombre }}
                            </option>
                        </select>
                    </div>
                    <div class="col-md-3">
                        
                    </div>


                    <!-- <div class="col-md-6">
                      <button type="submit" class="btn btn-success me-1 mb-1">{{textoBotonBuscar}}</button>
                    </div> -->
                  </div>
              </div>
              <div class="col-md-4">
                    <button type="submit" class="btn btn-success me-1 mb-1" style="margin-top: 3.1em;">{{textoBotonBuscar}}</button>
              </div>
            </div>

        </form>
        

        <div style="margin-top: 15px;">
          <panel title="Reporte" bodyClass="p-0" class="">
            <vue-good-table
              :columns="columns"
              :rows="registros"
              :lineNumbers="false"
              :search-options="{ enabled: true, placeholder: 'Buscar' }"
              :pagination-options="{ enabled: true, position: 'bottom' }"
            >
              <template slot="table-row" slot-scope="props">
                <span v-if="props.column.label == 'Acciones'">
                  <button class="btn btn-xs btn-success" @click="abrirLink(props.row)">Abrir</button>
                </span>
                <span v-else-if="props.column.field == 'TotalCG'">
                    {{  props.row.TotalCG | currency}}
                </span>
                <span v-else-if="props.column.field == 'TotalADDES'">
                    {{  props.row.TotalADDES | currency}}
                </span>
                <span v-else-if="props.column.field == 'DiferenciaCantidad'">
                    <span v-if="props.row.DiferenciaCantidad > 0">
                        <i class="fa fa-times tachaRoja" v-bind:title="props.row.DiferenciaCantidad"></i>
                    </span>
                    <span v-else>
                        <i class="fa fa-check palomitaVerde" v-bind:title="props.row.DiferenciaCantidad"></i>
                    </span>
                </span>


                
              </template>
            </vue-good-table>
          </panel>
        </div>

		<!-- bottom left vue-notification -->
		<notifications group="top-right" position="top right" :speed="1000" />
	</div>
</template>

<script>

import axios from 'axios';
import moment from 'moment';


export default {
  mounted: function () {
    debugger; // eslint-disable-line no-debugger
    var userSetting = JSON.parse(localStorage.getItem('userSettings'));
    this.usuario = userSetting.Usuario;
    this.usuarioEstacion = userSetting.Estacion;
    this.IdRol = userSetting.IdRol;
    this.getCiudades();
    this.ciudadSelect = userSetting.CiudadId;
    

    if(this.IdRol == 3 || this.IdRol == 7){
      this.deshabilitarCombos = true;
    }

  },
	beforeCreate() {
        
	},
	created() {
		// console.log('created');
	},
	beforeMount() {
		// console.log('beforeMount');
	},
	components: {
        
	},
  methods: {
    abrirLink(row){
        var self = this;
        //this.$router.push({ path: '/operacion/comisiones/comparativovsventa', params: {fechaIn:"123",fechaFin:"654",estacion:"2051",} });
        //this.$router.replace({ name: "user-view", params: {id:"123"}, query: {q1: "q1"} })
        //
        var fechaIn = moment(self.date).format("YYYY-MM-DD");

        debugger; // eslint-disable-line no-debugger
        this.$router.push({ path: '/operacion/comisiones/comparativovsventa'+'/'+self.intDate+'/'+self.intDateEnd+'/'+self.ciudadSelect+'/'+row.Estacion});

    },
	async getCiudades(){
		try{
        const response = await axios.get('Catalogos/GetCiudades');
		//debugger; // eslint-disable-line no-debugger
        if(response.data.Success){
          if(response.data.response.length == 0){
            this.alertMessage = "No se encontró información.";
            this.alertTitle = "Error";
            this.show('top-right', 'error');
          }
        }else{
            console.log("error")
            this.alertMessage = response.data.Error;
            this.alertTitle = "Error";
            this.show('top-right', 'error');
        }
        this.ciudades = response.data.response;
      }catch(e){
        //this.alert = true;
        //this.error = 'Invalid email/password';
      }
	},
    redirectTanques(){
      
          this.$router.push({ path: '/tanques' });
    },
			show (group, type = '') {
				const text = this.alertMessage;
				this.$notify({
					group,
					title: this.alertTitle,
					text,
					type,
					data: {
						randomNumber: Math.random()
					}
				})
			},
			clean (group) {
				this.$notify({ group, clean: true })
			},
			swalNotification(swalType) {
				var btnClass = (swalType == 'error') ? 'danger' : swalType;
					btnClass = (swalType == 'question') ? 'primary' : btnClass;
				this.$swal({
					title: 'Are you sure?',
					text: 'You will not be able to recover this imaginary file!',
					type: swalType,
					showCancelButton: true,
					buttonsStyling: false,
					confirmButtonText: swalType.charAt(0).toUpperCase() + swalType.substr(1).toLowerCase(),
					cancelButtonText: 'Cancel',
					confirmButtonClass: 'btn me-5px btn-'+ btnClass +'',
					cancelButtonClass: 'btn btn-default',
				})
			},
    async search(e){
        var self = this;
      e.preventDefault();

        // var mesInicio = moment(self.date).format('MM');
        // var mesfin = moment(self.dateEnd).format('MM');

        //   if(moment(self.date).format('MM') != moment(self.dateEnd).format('MM')){
        //     self.alertMessage = "Las fechas deben corresponder al mismo mes."
        //     self.alertTitle = "Error";
        //     self.show('top-right', 'error');
        //     return;
        //   }
      self.textoBotonBuscar = "Buscando...";
      self.loading = true;
      try{
        self.intDate = self.date.replace('/', '');
        self.intDate = self.intDate.replace('/', '');

        self.intDateEnd = self.dateEnd.replace('/', '');
        self.intDateEnd = self.intDateEnd.replace('/', '');

        var ciudad = self.ciudadSelect!=undefined?self.ciudadSelect:0;
        // var estacion = self.estacionSelect!=undefined?self.estacionSelect:0;
        debugger; // eslint-disable-line no-debugger

        const response = await axios.post('Ventas/GetVentaProductosAddesCg' ,{
        "Data": {
            "FechaInicial": self.intDate,
            "FechaFinal":self.intDateEnd,
            "Ciudad": ciudad     
        }
        });
        if(response.data.Success){
            
          if(response.data.response.length == 0){
            self.alertMessage = "No se erncontro información del día especificado.";
            self.alertTitle = "Error";
            self.show('top-right', 'error');
            self.registros = [];
          }else{
            self.registros = response.data.response;
          }
        }else{
            console.log("error")
            self.alertMessage = response.data.Message;
            self.alertTitle = "Error";
            self.show('top-right', 'error');
            self.registros = [];
        }
        
		debugger; // eslint-disable-line no-debugger
        // for(var n=0;n<respuesta.length;n++){
        //   respuesta[n].FechaString = moment(respuesta[n].Fecha).format("DD/MM/YYYY");
        // }
        // self.dataSource = {
        // fields: [
        //   { dataField: 'Nombre', area: 'row' },
        //   { dataField: 'FechaString', area: 'column' },
        //   { dataField: 'Cantidad', area: 'data', summaryType: 'sum', dataType: "number", type:"fixedPoint", precision:2   },
        //   { dataField: 'Monto', area: 'data', summaryType: 'sum', dataType: "number", type:"fixedPoint", precision:2   },
        // ],
        // store: respuesta,
        // };
        
        

      self.textoBotonBuscar = "Buscar";
      
      self.loading = false;
      }catch(e){
            self.registros = [];
      self.loading = false;
        //this.alert = true;
        //this.error = 'Invalid email/password';
      }
    }
  },
	data() {
		return {
            registros:[],
            allowSearch: true,
            showRelevantValues: true,
            dataSource: {},
            // applyChangesModes: ['instantly', 'onDemand'],
            applyChangesMode: 'instantly',
            loading:false,
            showHeaderFilter: true,
            deshabilitarCombos:false,
            ciudades: [],
            estaciones: [],
            ciudadSelect: 1,
            estacionSelect: 0,
            date: moment().startOf('month').format("YYYY/MM/DD"),
            intDateEnd: "",
            dateEnd: moment().format("YYYY/MM/DD"),
            intDate: "",
            options: {
                format: 'YYYY/MM/DD',
                useCurrent: false,
            },
            pageUserSettings: {},
            alertMessage: "",
            alertTitle: "",
            reporteCre: [],
            textoBotonBuscar: "Buscar",
            columns: [
                {
                    label: "Acciones",
                    field: "Acciones",
                    width: '150px',
                    tdClass: "text-center text-nowrap",
                    thClass: "text-center text-nowrap",
                },
                {
                    label: "Estatus",
                    field: "DiferenciaCantidad",
                    tdClass: "text-center text-nowrap",
                    thClass: "text-center text-nowrap",
                },
                {
                    label: "Estacion",
                    field: "Estacion",
                    tdClass: "text-center text-nowrap",
                    thClass: "text-center text-nowrap",
                },
                // {
                //     label: "Gasolinera",
                //     field: "Gasolinera",
                //     tdClass: "text-center text-nowrap",
                //     thClass: "text-center text-nowrap",
                // },
                {
                    label: "Nombre",
                    field: "Nombre",
                    tdClass: "text-center text-nowrap",
                    thClass: "text-center text-nowrap",
                },
                {
                    label: "Cantidad CG",
                    field: "CantidadCG",
                    tdClass: "text-center text-nowrap",
                    thClass: "text-center text-nowrap",
                },
                {
                    label: "Total CG",
                    field: "TotalCG",
                    type: "number",
                    tdClass: "text-nowrap",
                    thClass: "text-center text-nowrap",
                },
                {
                    label: "Cantidad ADDES",
                    field: "CantidadADDES",
                    tdClass: "text-center text-nowrap",
                    thClass: "text-center text-nowrap",
                },
                {
                    label: "Total ADDES",
                    field: "TotalADDES",
                    type: "number",
                    tdClass: "text-nowrap",
                    thClass: "text-center text-nowrap",
                }
            ],
		};
	}
}
</script>
<style scoped>
/* .dx-datagrid-header-panel{
  display: none !important;
} */
.loading-search{
  position: absolute;
    width: 100%;
    left: 0px;
    height: 100%;
    background: rgba(0, 0, 0, 0.1);
    z-index: 1000;
    padding-top: 150px;
    top: 0;
}
.tachaRoja{
    font-size: 16px;
    color: indianred;
}
.palomitaVerde{
    font-size: 16px;
    color: mediumseagreen;
}
</style>