<template>
  <div>
    
    <div class=" loading-search" v-if="loading">
      <div class="fa-3x text-center">
        <i class="fas fa-circle-notch fa-spin text-success" style=""></i>
      </div>
    </div>
    <!-- BEGIN breadcrumb -->
    <ol class="breadcrumb float-xl-end">
      <li class="breadcrumb-item"><a href="javascript:;">Reportes</a></li>
      <li class="breadcrumb-item active">
        <a href="javascript:;">Cargar</a>
      </li>
    </ol>
    <!-- END breadcrumb -->
    <!-- BEGIN page-header -->
    <h1 class="page-header">Generación de archivos XML para estimulo fiscal</h1>
    <!-- END page-header -->



    <form @submit.prevent="cargar">   
        <div class="row">
            <div class="col-md-12"> 
                <div class="form-group row">
                    
                    <div class="col-md-2">
                        <label class="col-form-label">RFC</label>
                        <input type="text" class="form-control" v-model="RFC">
                    </div>
                    <div class="col-md-1">
                        <label class="col-form-label">Año</label>
                        <input type="number" class="form-control" v-model="Anio">
                    </div>
                    <div class="col-md-1">
                        <label class="col-form-label">Mes</label>
                        <input type="number" class="form-control" v-model="Mes">
                    </div>
                    <div class="col-md-2">
                        <label class="col-form-label">Tipo de Solicitud</label>
                        <input type="text" class="form-control" v-model="TipoSolicitud">
                    </div>
                    <div class="col-md-2">
                        <label class="col-form-label">Num Tramite</label>
                        <input type="text" class="form-control" v-model="NumTramite">
                    </div>
                    <div class="col-md-4">
                        <label class="col-form-label">Archivo</label>
                        <br>
                        <input type="file" @change="previewFiles" accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel">
                    </div>
                </div>
                <div class="col-md-12 text-end">
                    <button 
                        type="submit" 
                        class="btn btn-success btn-lg me-1 mb-1"
                        :disabled="archivoBase64 == ''"
                    >
                        <i class="far fa-lg fa-fw fa-file-excel"></i>{{textoBotonBuscar}}
                    </button>
                </div>
            </div>
        </div>

    </form>

    <panel title="Cargar" bodyClass="p-0" class="">
        <div style="padding-top: 30px;">
						<div class="row text-center">
							<div class="col-md-12">
								<div class="mb-2">
									<button class="btn btn-lg btn-success" style="padding-top: 15px; padding-bottom: 15px;"
                        :disabled="xmlResend == undefined"
                        @click="descargarXML">
										<span class="d-flex align-items-center text-start">
											<i class="far fa-lg fa-fw fa-file-code  fa-3x me-3"  style="color: #FFF"></i>
											<span>
												<span class="d-block" style="color: #FFF"><b>Generar XML</b></span>
												<!-- <span class="d-block fs-12px opacity-7">Version 5.0</span> -->
											</span>
										</span>
									</button>
								</div>
							</div>
						</div>
					</div>
    </panel>
	
    
	<!-- bottom left vue-notification -->
	<notifications group="top-right" position="top right" :speed="1000" />
  </div>
</template>

<script>

import axios from "axios";
import moment from 'moment';
export default {
	mounted() {
    debugger; // eslint-disable-line no-debugger
    var userSetting = JSON.parse(localStorage.getItem('userSettings'));
    this.usuario = userSetting.Usuario;
    this.usuarioEstacion = userSetting.Estacion;
    this.IdRol = userSetting.IdRol;
    // this.getCiudades();
    // this.ciudadSelect = userSetting.CiudadId;
    // this.getEstaciones();
    // this.onLoad();


	},
  methods: {
    async GetXmlEstimulo(){
      var self = this;
      self.loading = true;
        try {
          const response = await axios.post("EstimuloFiscal/GetXmlEstimulo", 
          {
            "Data": {
                "RFC": self.RFC,
                "Anio":self.Anio,
                "Mes": self.Mes, 
                "TipoSolicitud": self.TipoSolicitud,
                "NumeroTramite": self.NumTramite,
                "Usuario": self.usuario,
                "XlsBase64": self.archivoBase64
                }
            }
          );
      debugger; // eslint-disable-line no-debugger
          if (response.data.Success) {
            if (response.data.response == null) {
              this.alertMessage =
                "No se encontró información.";
              this.alertTitle = "Error";
              this.show("top-right", "error");
            } else {
              
              debugger; // eslint-disable-line no-debugger
              if(response.data.Success){
                self.xmlResend = response.data.response;
                self.filename = self.RFC+self.Anio+self.Mes+"PL";
              }else{
                self.alertMessage = response.data.response.Error;
                self.alertTitle = "Respuesta";
                self.show("top-right", "error");

              }

            }
          } else {
            this.alertMessage = response.data.Error;
            this.alertTitle = "Error";
            this.show("top-right", "error");
          }
          
      self.loading = false;
        } catch (e) {
      self.loading = false;
          this.alertMessage = "Ocurrio un error";
          this.alertTitle = "Error";
          this.show("top-right", "error");
        }
    },
    descargarXML() {
      
        debugger; // eslint-disable-line no-debugger
        var self = this;
        var xmltext = self.xmlResend;

        var filename = self.filename+".xml";
        var pom = document.createElement("a");
        var bb = new Blob([xmltext], { type: "text/plain" });

        pom.setAttribute("href", window.URL.createObjectURL(bb));
        pom.setAttribute("download", filename);

        pom.dataset.downloadurl = ["text/plain", pom.download, pom.href].join(
            ":"
        );
        pom.draggable = true;
        pom.classList.add("dragout");
        pom.click();
    },
   previewFiles(e) {
		debugger; // eslint-disable-line no-debugger
      var self = this;
      
        var files = e.target.files || e.dataTransfer.files;
        if (!files.length)
          return;
        var file = files[0];
        //var inputId = e.target.id;
        self.handleFileUpload(file);
        
   },
   async handleFileUpload(file){
        debugger; // eslint-disable-line no-debugger
        var self = this;
        const toBase64 = file => new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
        });

      if(file != undefined){
        var fileObject = {};
        var extension = file.name.split('.');
        var base64 = await toBase64(file);
        console.log(base64);
        // var base64 = "";
        base64 = base64.split('base64,');
        fileObject.ContentType = file.type;
        fileObject.Extension = "."+extension[extension.length-1];
        fileObject.FileName = file.name;
        fileObject.Base64 = base64[1];

        self.archivoBase64 = base64[1];
        
        

      }
       
   },
    showNotification(title,text,type){
      this.alertTitle = title;
      this.alertMessage = text;
      this.show("top-right", type);
    },
    show (group, type = '') {
        const text = this.alertMessage;
        this.$notify({
            group,
            title: this.alertTitle,
            text,
            type,
            data: {
                randomNumber: Math.random()
            }
        })
    },
    clean (group) {
        this.$notify({ group, clean: true })
    },
    swalNotification(swalType) {
        var btnClass = (swalType == 'error') ? 'danger' : swalType;
            btnClass = (swalType == 'question') ? 'primary' : btnClass;
        this.$swal({
            title: 'Are you sure?',
            text: 'You will not be able to recover this imaginary file!',
            type: swalType,
            showCancelButton: true,
            buttonsStyling: false,
            confirmButtonText: swalType.charAt(0).toUpperCase() + swalType.substr(1).toLowerCase(),
            cancelButtonText: 'Cancel',
            confirmButtonClass: 'btn me-5px btn-'+ btnClass +'',
            cancelButtonClass: 'btn btn-default',
        })
    },
    async cargar(e) {
      e.preventDefault();
      var self = this;
      if(self.RFC == ""){
          this.alertMessage = "Capture RFC";
          this.alertTitle = "Error";
          this.show("top-right", "error");
          return;
      }
      
      if(!(parseInt(self.Anio) > 0)){
          this.alertMessage = "El número ingresado en el campo año es incorrecto";
          this.alertTitle = "Error";
          this.show("top-right", "error");
          return;
      }

      if(!(parseInt(self.Mes) > 0) || (parseInt(self.Mes) > 12)){
          this.alertMessage = "El número ingresado en el campo mes es incorrecto.";
          this.alertTitle = "Error";
          this.show("top-right", "error");
          return;
      }

      this.GetXmlEstimulo();
    },
    
  },
  data() {
    return {
      filename:"",
      RFC: "",
      Anio: "",
      Mes: "",
      TipoSolicitud: "0000000000",
      NumTramite: "DE",
      loading:false,
    archivoBase64:"",
    xmlResend: undefined,
      estaciones:[],
      ciudades:[],
      ciudadSelect:'',
      IdRol:0,
      usuario:'',
      date: moment().format("YYYY-MM-DD"),
      intDate: "",
      options: {
        format: 'YYYY-MM-DD',
        useCurrent: false,
      },
      esRolVendendor: false,
      empresaTressSelected:'',
      puestoSelected:{
          puesto:0
      },
      rolSelected:{
          rol:0
      },
      empresaTressSelect:[],
      actualizarNIP:false,
      puestosSelect:[],
      rolesSelect:[],
      nuevoCorreo: '',
      clienteNuevo: true,
      clienteModal:{},
      textoBotonGuardar: "Guardar",
      textoBotonBuscar: "Cargar",
      clienteBuscar: "",
      clientes: [],
    };
  },
};
</script>
<style scoped>

.loading-search{
  position: absolute;
    width: 100%;
    left: 0px;
    height: 100%;
    background: rgba(0, 0, 0, 0.1);
    z-index: 1000;
    padding-top: 150px;
    top: 0;
}
</style>