<template>
    <div>
      <div class="loading-search" v-if="loading">
        <div class="fa-3x text-center">
          <i class="fas fa-circle-notch fa-spin text-success" style=""></i>
        </div>
      </div>
      <!-- BEGIN breadcrumb -->
      <ol class="breadcrumb float-xl-end">
              <li class="breadcrumb-item"><a href="javascript:;">Operación</a></li>
              <li class="breadcrumb-item"><a href="javascript:;">Compras</a></li>
              <li class="breadcrumb-item active"><a href="javascript:;">Recepción de compras</a></li>
      </ol>
      <!-- END breadcrumb -->
      <!-- BEGIN page-header -->
      <h1 class="page-header">Recepción de compras</h1>
      <!-- END page-header -->
  
  
  
          <form @submit.prevent="search">   
      <div class="row">
        <div class="col-md-8"> 
            <div class="form-group row">
<!--               
              <div class="col-md-3">
                  <label class="col-form-label">Fecha inicial</label>
                <div class="input-group">
                  <date-picker id="dateTimeInput" v-model="date" :config="options"></date-picker>
                  <label for="dateTimeInput" class="input-group-text">
                    <i class="fa fa-calendar"></i>
                  </label>
                </div>
              </div>
   -->
              
              <!-- <div class="col-md-3">
                  <label class="col-form-label">Fecha final</label>
                <div class="input-group">
                  <date-picker id="dateTimeInputEnd" v-model="dateEnd" :config="options"></date-picker>
                  <label for="dateTimeInputEnd" class="input-group-text">
                    <i class="fa fa-calendar"></i>
                  </label>
                </div>
              </div> -->
              <div class="col-md-3">
                  <label class="col-form-label">Ciudad</label>
                  <select class="form-select" @change="getEstaciones()" v-model="ciudadSelect" :disabled="deshabilitarCombos">
                      <option v-for="ciudad in ciudades" :value="ciudad.Id" v-bind:key="ciudad.Id">
                          {{ ciudad.Nombre }}
                      </option>
                  </select>
              </div>
              <div class="col-md-3">
                  <label class="col-form-label">Estación</label>
                  <select class="form-select" v-model="estacionSelect" :disabled="deshabilitarCombos">
                    <option value="0">Todas</option>
                      <option v-for="estacion in estaciones" :value="estacion.NoEstacion" v-bind:key="estacion.NoEstacion">
                      {{ estacion.Nombre }}
                      </option>
                    </select>
              </div>
              <!-- <div class="col-md-3">
                  <label class="col-form-label">Turno</label>
                  <select v-model="turnoSelected.IdTurno" class="form-control">
                      <option value="0">Todos</option>
                      <option v-for="turno in turnos" :value="turno.IdTurno" v-bind:key="turno.IdTurno">
                      {{ turno.Descripcion }}
                      </option>
                  </select>
              </div> -->
  
  
              <!-- <div class="col-md-6">
                <button type="submit" class="btn btn-success me-1 mb-1">{{textoBotonBuscar}}</button>
              </div> -->
            </div>
        </div>
        <div class="col-md-4">
              <button type="submit" class="btn btn-success me-1 mb-1" style="margin-top: 3.1em;">{{textoBotonBuscar}}</button>
        </div>
      </div>
  
          </form>
      <panel title="Autorización Fajillas" bodyClass="p-0" class="">
        <vue-good-table
          :columns="columns"
          :rows="reportefajillas"
          :lineNumbers="false"
          :search-options="{ enabled: true, placeholder: 'Buscar' }"
          :pagination-options="{ enabled: true, position: 'bottom' }"
          :sort-options="{
            enabled: true,
            initialSortBy: {field: 'Folio', type: 'desc'}
          }"
        >
          <template slot="table-row" slot-scope="props">
            <span v-if="props.column.label == 'Acciones'">
              <button
                v-b-modal.modalDialog
                class="btn btn-sm btn-success"
                @click="aceptar(props.row)"
              >
                Aceptar
              </button>
            </span>
            
            <!-- <span v-else-if="props.column.field == 'Monto'">
              {{  props.row.Monto | currency}}
            </span> -->
          </template>
        </vue-good-table>
      </panel>
      
  
      <!-- #modal-dialog -->
	<b-modal id="modalDialog"  size="lg">
      <template slot="modal-header">
        <h4 class="modal-title">Registro de compras</h4>
        <a class="btn-close" @click="$bvModal.hide('modalDialog')"></a>
      </template>

      <div>
        <form action="/" method="POST">
          <fieldset>
            <div class="row">
              <div class="col-4 mb-3">
                  <label class="form-label" for="txtEstacion">Estacion</label>
                  <input
                    class="form-control"
                    type="text"
                    id="txtEstacion"
                    placeholder="Estacion"
                    :disabled="true"
                    v-model="recepcionModal.EstacionFull"
                  />
              </div>
              <div class="col-4">
                <label class="form-label">Fecha</label>
                  <input
                    class="form-control"
                    type="text"
                    placeholder="FechaRegistro"
                    :disabled="true"
                    v-model="recepcionModal.FechaFactura"
                  />
              </div>
              <div class="col-4">
                
                <label class="form-label">Moneda</label>
                  <input
                    class="form-control"
                    type="text"
                    value="Pesos"
                    placeholder="Moneda"
                    :disabled="true"
                    
                  />
              </div>
            </div>
            <!-- <div class="row">
              <div class="col-4 mb-3">
                
              </div>
              <div class="col-4"></div>
              <div class="col-4"></div>
            </div> -->
            <!-- <div class="row">
              <div class="col-4  mb-3">
                
                <label class="form-label">Efecto Contable</label>
                  <input
                    class="form-control"
                    type="text"
                    value="N/A"
                    placeholder="Efecto Contable"
                    :disabled="true"
                    
                  />
              </div>
              <div class="col-4"></div>
              <div class="col-4"></div>
            </div> -->
            <div class="row">
              <div class="col-8  mb-3">
                
                <label class="form-label">Proveedor</label>
                  <input
                    class="form-control"
                    type="text"
                    placeholder="Proveedor"
                    :disabled="true"
                    v-model="recepcionModal.NombreEmisor"
                  />
              </div>
              
              <div class="col-4">
                
                <label class="form-label">Permiso Transporte </label>
                  <input
                    class="form-control"
                    type="text"
                    placeholder="Permiso Transporte "
                    :disabled="true"
                    v-model="recepcionModal.PermisoTransportista"
                  />
              </div>
            </div>
            <div class="row">
              <div class="col-4  mb-3">
                
                <label class="form-label">UUID Fiscal</label>
                  <input
                    class="form-control"
                    type="text"
                    placeholder="UUID Fiscal"
                    :disabled="true"
                    v-model="recepcionModal.UUID"
                  />
              </div>
              <div class="col-4">
                
                <label class="form-label">Factura</label>
                  <input
                    class="form-control"
                    type="text"
                    placeholder="Factura"
                    :disabled="true"
                    v-model="recepcionModal.Factura"
                  />
              </div>
              <div class="col-4"></div>
            </div>
            <!-- <div class="row">
              <div class="col-4  mb-3">
                
              </div>
              <div class="col-4"></div>
              <div class="col-4"></div>
            </div> -->
            <!-- <div class="mb-3">
              <label class="form-label" for="txtNombre">Nombre</label>
              <input
                class="form-control"
                type="text"
                id="txtNombre"
                placeholder="Nombre"
                v-model="clienteModal.Nombre"
              />
            </div> -->


<!-- 

            <div class="mb-3">
              <label class="form-label" for="txtUsoCFDI">Uso CFDI</label>
              <select name="" id="ddlUsoCFDI" class="form-control" v-model="clienteModal.UsoCFDI">
                <option :value="uso.Id" v-for="uso in usosCFDI" :key="uso.Id">
                  {{uso.Descripcion}}
                </option>
              </select>
              
            </div>
            <div class="mb-3">
              <label class="form-label" for="txtRegimenFiscal">Regimen Fiscal</label>
              <select name="" id="ddlRegimenFiscal" class="form-control" v-model="clienteModal.RegimenFiscal">
                <option :value="regimen.Clave" v-for="regimen in regimenesFiscales" :key="regimen.Clave">
                  {{regimen.Denominacion}}
                </option>
              </select>
              
            </div>
            <div class="mb-3">
              <label class="form-label" for="txtCodigoPostal">Codigo Postal</label>
              <input
                class="form-control"
                type="text"
                id="txtCodigoPostal"
                placeholder="CodigoPostal"
                v-model="clienteModal.CodigoPostal"
              />
            </div>


            <div class="form-check form-switch mb-2" v-if="!clienteNuevo">
              <input
                class="form-check-input"
                type="checkbox"
                id="flexSwitchCheckChecked"
                v-model="clienteModal.Estatus"
              />
              <label class="form-check-label" for="flexSwitchCheckChecked"
                ><span v-if="clienteModal.Estatus">Activo</span
                ><span v-if="!clienteModal.Estatus">Inactivo</span></label
              >
            </div>
            <div class="mb-3">
              <label class="form-label" for="txtCorreo" v-if="clienteNuevo"
                >Correo</label
              >
              <input
                class="form-control"
                type="text"
                id="txtCorreo"
                placeholder="Correo"
                v-if="clienteNuevo"
                v-model="clienteModal.Correo"
              />
            </div> -->

            <div class="row">
              <div class="col-12  mb-3">
                
                <label class="form-label">Notas</label>
                  <textarea
                    class="form-control"
                    rows="3"
                    placeholder="Notas"
                    v-model="recepcionModal.Notas"
                  >
                  </textarea>
              </div>
            </div>


            <div class="table-responsive" >
              Concepto
              <table class="table mb-0">
                <thead>
                  <tr>
                    <th>Producto</th>
                    <th>Cantidad</th>
                    <th>Precio</th>
                    <th></th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{{ recepcionModal.CodigoProducto }} {{ recepcionModal.Descripcion }}</td>
                    <td>{{ recepcionModal.Cantidad }}</td>
                    <td>{{ recepcionModal.ValorUnitario }}</td>
                    <td>{{ recepcionModal.Subtotal }}</td>
                    <td></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </fieldset>
        </form>
      </div>

      <template slot="modal-footer">
        <button
          type="button"
          class="btn btn-primary mr-auto"
          @click="aceptarConfirmacion()"
        >
          {{ textoBotonGuardar }}
        </button>
        <button
          type="button"
          class="btn btn-secondary"
          @click="$bvModal.hide('modalDialog')"
        >
          Cancelar
        </button>
      </template>
    </b-modal>
  
  
  
      <!-- bottom left vue-notification -->
      <notifications group="top-right" position="top right" :speed="1000" />
    </div>
  </template>
  
  <script>
  
  import axios from "axios";
    var numeral = require("numeral");
    import Vue from "vue";
    Vue.filter("formatNumber", function (value) {
        return numeral(value).format("0,00.00"); // displaying other groupings/separators is possible, look at the docs
    });
    
    Vue.filter("currency", function (value) {
        return numeral(value).format("$0,0.00"); 
    });
  import moment from 'moment';
  export default {
  async	created() {
    
      },
    mounted: function () {
        
          var userSetting = JSON.parse(localStorage.getItem('userSettings'));
          this.usuario = userSetting.Usuario;
          this.idUsuario = userSetting.NumeroEmpleado;
          this.nombreLogueado = userSetting.Nombre;
          this.usuarioEstacion = userSetting.Estacion;
          this.IdRol = userSetting.IdRol;
      this.getCiudades();
      this.ciudadSelect = userSetting.CiudadId;
      this.getEstaciones();
      this.estacionSelect = userSetting.Estacion;
  
      this.GetTurnos();
      if(this.IdRol == 3 || this.IdRol == 7){
        this.deshabilitarCombos = true;
      }
  
  
      
  
    },
    methods: {
      async GetTurnos(){
        try {
          const response = await axios.get("Catalogos/GetTurnos");
          if (response.data.Success) {
            if (response.data.response.length == 0) {
              this.alertMessage =
                "No se encontraron turnos";
              this.alertTitle = "Error";
              this.show("top-right", "error");
            } else {
              this.turnos = response.data.response;
            }
          } else {
            this.alertMessage = response.data.Message;
            this.alertTitle = "Error";
            this.show("top-right", "error");
          }
        } catch (e) {
          this.alertMessage = "Ocurrio un error";
          this.alertTitle = "Error";
          this.show("top-right", "error");
        }
      },
      async getCiudades(){
          try{
          const response = await axios.get('Catalogos/GetCiudades');
          
          if(response.data.Success){
            if(response.data.response.length == 0){
              this.alertMessage = "No se encontró información.";
              this.alertTitle = "Error";
              this.show('top-right', 'error');
            }
          }else{
              console.log("error")
              this.alertMessage = response.data.Error;
              this.alertTitle = "Error";
              this.show('top-right', 'error');
          }
          this.ciudades = response.data.response;
        }catch(e){
          //this.alert = true;
          //this.error = 'Invalid email/password';
        }
      },
      async getEstaciones(){
          try{
            
          const response = await axios.post('Estacion/GetEstaciones?ciudad='+this.ciudadSelect);
          if(response.data.Success){
            if(response.data.response.length == 0){
              this.alertMessage = "No se encontró información.";
              this.alertTitle = "Error";
              this.show('top-right', 'error');
            }
          }else{
              console.log("error")
              this.alertMessage = response.data.Error;
              this.alertTitle = "Error";
              this.show('top-right', 'error');
          }
          this.estaciones = response.data.response;
          this.productos = [];
          this.getProductos();
        }catch(e){
          //this.alert = true;
          //this.error = 'Invalid email/password';
        }
      },
      
      async aceptarConfirmacion(){
        
        var self = this;
        
        if(!confirm("Desea aceptar?")){
          return;
        }

        // var testData = JSON.stringify(self.recepcionModal)
        // debugger; // eslint-disable-line no-debugger
        var postData = {
        "Data": self.recepcionModal
        };

              
          try {
            const response = await axios.post("Compras/SetCompraGt",postData);
            
            if (response.data.Success) {

              alert(response.data.Message)
              this.getData();
              this.$bvModal.hide("modalDialog");
            
              // if (response.data.response.length == 0) {
              //   this.alertMessage = "Error";
              //   this.alertTitle = "Error";
              //   this.show("top-right", "error");
              // } else {
                
                
              // }
            } else {
                
              this.alertMessage = response.data.Message;
              this.alertTitle = "Error";
              this.show("top-right", "error");
            }
            this.textoBotonBuscar = "Buscar";
          } catch (e) {
              this.alertMessage = "Ocurrio un error";
              this.alertTitle = "Error";
              this.show("top-right", "error");
          }
  
  
      },
      async aceptar(item){
        
        var self = this;
        
		debugger; // eslint-disable-line no-debugger
        var postData = {
                "Data": {
                  "Id": item.Id,
                }
              };
              
              
          try {
            const response = await axios.post("Compras/GetFacturaBinarApp",postData);
            
            if (response.data.Success) {
              
                
              if (response.data.response.length == 0) {
                this.alertMessage = "Error";
                this.alertTitle = "Error";
                this.show("top-right", "error");
              } else {
                
		debugger; // eslint-disable-line no-debugger
                self.recepcionModal = response.data.response;
                self.recepcionModal.EstacionFull = self.recepcionModal.Estacion+" "+self.recepcionModal.NombreEstacion;
                self.recepcionModal.FacturaFull = self.recepcionModal.Serie+" "+self.recepcionModal.Folio;

                if(self.recepcionModal.RFCEmisor == "PTI151101TE5"){

                  self.recepcionModal.Factura = "300-" + self.recepcionModal.Serie + "-" + self.recepcionModal.Folio;
                  var dataArray = self.recepcionModal.Remision.Split('-');
                  if (dataArray.length > 0)
                  {
                    self.recepcionModal.Remision = dataArray[0] + "-RP-" + dataArray[3];
                  }

                }else{
                  
                  self.recepcionModal.Factura = self.recepcionModal.Serie + self.recepcionModal.Folio;
                  self.recepcionModal.Remision = self.recepcionModal.Factura;
                }


                /*
                
                if (item.RFCEmisor == "PTI151101TE5")
                    {
                        factura = "300-" + item.Serie + "-" + item.Folio;
                        string[] data = item.Remision.Split('-');
                        if (data.Length > 0)
                        {
                            remision = data[0] + "-RP-" + data[3];
                        }
                    }
                    else
                    {
                        factura = item.Serie + item.Folio;
                        remision = factura;
                    }
                
                */
              }
            } else {
                
              this.alertMessage = response.data.Error;
              this.alertTitle = "Error";
              this.show("top-right", "error");
            }
            this.textoBotonBuscar = "Buscar";
          } catch (e) {
              this.alertMessage = "Ocurrio un error";
              this.alertTitle = "Error";
              this.show("top-right", "error");
          }
  
  
      },
              show (group, type = '') {
                  const text = this.alertMessage;
                  this.$notify({
                      group,
                      title: this.alertTitle,
                      text,
                      type,
                      data: {
                          randomNumber: Math.random()
                      }
                  })
              },
              clean (group) {
                  this.$notify({ group, clean: true })
              },
              swalNotification(swalType) {
                  var btnClass = (swalType == 'error') ? 'danger' : swalType;
                      btnClass = (swalType == 'question') ? 'primary' : btnClass;
                  this.$swal({
                      title: 'Are you sure?',
                      text: 'You will not be able to recover this imaginary file!',
                      type: swalType,
                      showCancelButton: true,
                      buttonsStyling: false,
                      confirmButtonText: swalType.charAt(0).toUpperCase() + swalType.substr(1).toLowerCase(),
                      cancelButtonText: 'Cancel',
                      confirmButtonClass: 'btn me-5px btn-'+ btnClass +'',
                      cancelButtonClass: 'btn btn-default',
                  })
              },
        async getData(){
          
          var self = this;
        self.loading = true;
        self.textoBotonBuscar = "Buscando...";
        try{
            
          var estacion = self.estacionSelect!=undefined?self.estacionSelect:0;
  
          const response = await axios.post('Compras/GetFacturasBinarAppEstacion' ,{
          "Data": {
              "Estacion": estacion
              }
          });
          if(response.data.Success){
            if(response.data.response.length == 0){
              self.alertMessage = "No se encontro información del día especificado.";
              self.alertTitle = "Error";
              self.show('top-right', 'error');
            }
          }else{
              console.log("error")
              self.alertMessage = response.data.Message;
              // self.alertTitle = "Error";
              self.show('top-right', 'success');
          }
          
          self.reportefajillas = response.data.response;
  
        self.textoBotonBuscar = "Buscar";
        
        self.loading = false;
        }catch(e){
        self.loading = false;
          //this.alert = true;
          //this.error = 'Invalid email/password';
        }
  
        },
      async search(e) {
		debugger; // eslint-disable-line no-debugger
        e.preventDefault();
        this.getData();
      },
    },
    data() {
      return {
        recepcionModal:{
          "Id":"",
          "Gasolinera":"",
          "Estacion":"",
          "NombreEstacion":"",
          "PermisoCRE":"",
          "FechaFactura":"",
          "Serie":"",
          "Folio":"",
          "Referencia":"",
          "UUID":"",
          "RFCEmisor":"",
          "NombreEmisor":"",
          "RFCReceptor":"",
          "NombreReceptor":"",
          "CodigoProducto":"",
          "ClaveProdServ":"",
          "ClaveUnidad":"",
          "Descripcion":"",
          "Cantidad":"",
          "ValorUnitario":"",
          "PrecioCompra":"",
          "TasaIVA":"",
          "IVA":"",
          "IVARetenido":"",
          "CuotaIEPS":"",
          "IEPS":"",
          "Descuento":"",
          "IVADescuento":"",
          "Flete":"",
          "IVAFlete":"",
          "Subtotal":"",
          "Total":"",
          "PermisoTransportista":"",
          "NombreTransportista":"",
          "PlacasVehiculo":"",
          "FechaRegistro":"",
          "RutaXML":"",
          "RutaPDF":"",
          "ImporteCombustible":"",
          "Remision":"",
          "CompraCG":"",
          "FechaCompra":"",
          "EstatusCompra":"",
          "VesrionCFDI":"",
        },
        turnos:[],
        turnoSelected:{
            IdTurno:0
        },
        loading:false,
        nombreLogueado:"",
        idUsuario:undefined,
        reportefajillas:[],
        deshabilitarCombos:false,
        ciudades: [],
        estaciones: [],
        ciudadSelect: 1,
        estacionSelect: 0,
        ventaDetalle:[],
        emailReenviar:"",
        uuidReenviar:"",
        date: moment().format("YYYY/MM/DD"),
        intDateEnd: "",
        dateEnd: moment().format("YYYY/MM/DD"),
        intDate: "",
        options: {
          format: 'YYYY/MM/DD',
          useCurrent: false,
        },
        puestoSelected:{
            puesto:0
        },
        rolSelected:{
            rol:0
        },
        
        puestosSelect:[],
        rolesSelect:[],
        nuevoCorreo: '',
        clienteNuevo: true,
        clienteModal:{},
        textoBotonGuardar: "Guardar",
        textoBotonBuscar: "Buscar",
        clienteBuscar: "",
        /*
        Cantidad

        */
        columns: [
            {
                label: "Acciones",
                field: "Acciones",
                width: '150px',
                tdClass: "text-center text-nowrap",
                thClass: "text-center text-nowrap",
            },
            {
                label: "Id",
                field: "Id",
                tdClass: "text-center text-nowrap",
                thClass: "text-center text-nowrap",
            },
            {
                label: "Estacion",
                field: "Estacion",
                tdClass: "text-center text-nowrap",
                thClass: "text-center text-nowrap",
            },
            {
                label: "NombreEstacion",
                field: "NombreEstacion",
                tdClass: "text-center text-nowrap",
                thClass: "text-center text-nowrap",
            },
            {
                label: "FechaFactura",
                field: "FechaFactura",
                tdClass: "text-center text-nowrap",
                thClass: "text-center text-nowrap",
            },
            {
                label: "Serie",
                field: "Serie",
                tdClass: "text-center text-nowrap",
                thClass: "text-center text-nowrap",
            },
            {
                label: "Folio",
                field: "Folio",
                tdClass: "text-center text-nowrap",
                thClass: "text-center text-nowrap",
            },
            {
                label: "UUID",
                field: "UUID",
                tdClass: "text-center text-nowrap",
                thClass: "text-center text-nowrap",
            },
            {
                label: "Descripcion",
                field: "Descripcion",
                tdClass: "text-center text-nowrap",
                thClass: "text-center text-nowrap",
            },
            {
                label: "Cantidad",
                field: "Cantidad",
                tdClass: "text-center text-nowrap",
                thClass: "text-center text-nowrap",
            },
            {
                label: "ValorUnitario",
                field: "ValorUnitario",
                tdClass: "text-center text-nowrap",
                thClass: "text-center text-nowrap",
            },
            {
                label: "Total",
                field: "Total",
                tdClass: "text-center text-nowrap",
                thClass: "text-center text-nowrap",
            },
            {
                label: "FechaRegistro",
                field: "FechaRegistro",
                tdClass: "text-center text-nowrap",
                thClass: "text-center text-nowrap",
            },
            {
                label: "ImporteCombustible",
                field: "ImporteCombustible",
                tdClass: "text-center text-nowrap",
                thClass: "text-center text-nowrap",
            },
            {
                label: "CompraCG",
                field: "CompraCG",
                tdClass: "text-center text-nowrap",
                thClass: "text-center text-nowrap",
            },
            {
                label: "FechaCompra",
                field: "FechaCompra",
                tdClass: "text-center text-nowrap",
                thClass: "text-center text-nowrap",
            },

          
        ],
        items: [],
      };
    },
  };
  </script>
  <style scoped>
  .loading-search{
    position: absolute;
      width: 100%;
      left: 0px;
      height: 100%;
      background: rgba(0, 0, 0, 0.1);
      z-index: 1000;
      padding-top: 150px;
      top: 0;
  }
  .textRight{
    text-align: right !important;
  }
  </style>