<template>
	<div>
    
    <div class=" loading-search" v-if="loading">
      <div class="fa-3x text-center">
        <i class="fas fa-circle-notch fa-spin text-success" style=""></i>
      </div>
    </div>
		<!-- BEGIN breadcrumb -->
		<ol class="breadcrumb float-xl-end">
			<li class="breadcrumb-item"><a href="javascript:;">Operación</a></li>
			<li class="breadcrumb-item"><a href="javascript:;">Comisiones</a></li>
			<li class="breadcrumb-item active"><a href="javascript:;">Comparativo vs venta</a></li>
		</ol>
		<!-- END breadcrumb -->
		<!-- BEGIN page-header -->
		<h1 class="page-header">Comparativo vs venta<small></small></h1>
    <!-- <button class="btn" v-on:click="redirectTanques()">tanques</button> -->
		<!-- END page-header -->
		<!-- <a href="javascript:;" v-on:click="show('top-right', 'warn')" class="btn btn-sm btn-primary">Demo</a> -->
		


        <form @submit.prevent="search">   
          <div class="row">
              <div class="col-md-8"> 
                  <div class="form-group row">
                    
                    <div class="col-md-3">
                        <label class="col-form-label">Fecha inicial</label>
                      <div class="input-group">
                        <date-picker id="dateTimeInput" v-model="date" :config="options"></date-picker>
                        <label for="dateTimeInput" class="input-group-text">
                          <i class="fa fa-calendar"></i>
                        </label>
                      </div>
                    </div>

                    
                    <div class="col-md-3">
                        <label class="col-form-label">Fecha final</label>
                      <div class="input-group">
                        <date-picker id="dateTimeInputEnd" v-model="dateEnd" :config="options"></date-picker>
                        <label for="dateTimeInputEnd" class="input-group-text">
                          <i class="fa fa-calendar"></i>
                        </label>
                      </div>
                    </div>
                    <div class="col-md-3">
                        <label class="col-form-label">Ciudad</label>
                        <select class="form-select" @change="getEstaciones()" v-model="ciudadSelect" :disabled="deshabilitarCombos">
                            <option v-for="ciudad in ciudades" :value="ciudad.Id" v-bind:key="ciudad.Id">
                                {{ ciudad.Nombre }}
                            </option>
                        </select>
                    </div>
                    <div class="col-md-3">
                        <label class="col-form-label">Estación</label>
                        <select class="form-select" v-model="estacionSelect" :disabled="deshabilitarCombos">
                          <option value="0">Todas</option>
                            <option v-for="estacion in estaciones" :value="estacion.NoEstacion" v-bind:key="estacion.NoEstacion">
                            {{ estacion.Nombre }}
                            </option>
                          </select>
                    </div>


                    <!-- <div class="col-md-6">
                      <button type="submit" class="btn btn-success me-1 mb-1">{{textoBotonBuscar}}</button>
                    </div> -->
                  </div>
              </div>
              <div class="col-md-4">
                    <button type="submit" class="btn btn-success me-1 mb-1" style="margin-top: 3.1em;">{{textoBotonBuscar}}</button>
              </div>
            </div>

        </form>
        <!-- <DxPivotGrid
            id="sales"
            :allow-sorting-by-summary="true"
            :allow-sorting="true"
            :allow-filtering="true"
            :allow-expand-all="true"
            :height="440"
            :show-borders="true"
            :data-source="reporteCre"
        >
            <DxFieldChooser :enabled="false"/>
        </DxPivotGrid> -->

  <div>  
    
    <DxDataGrid
      id="gridContainer"
      :data-source="registros"
      key-expr="Diferencia"
      :show-borders="true"
      @exporting="onExporting"
    >
    <DxFilterRow/>
      <DxHeaderFilter
        :visible="showHeaderFilter"
      />
      <DxSearchPanel
        :visible="true"
        :width="240"
        placeholder="Search..."
      />

      <DxGroupPanel :visible="true"/>
      <DxGrouping :auto-expand-all="true"/>
      <DxExport
        :enabled="true"
        :allow-export-selected-data="false"
      />
      <DxSelection mode="false"/>
        <!-- <DxColumn data-field="NoEmpleado" caption="NoEmpleado">
          <DxHeaderFilter :allow-search="true"/>
        </DxColumn> -->
        <!-- <DxColumn data-field="FechaString" caption="Fecha"/>
        <DxColumn data-field="Estacion" caption="Estacion"/>
        <DxColumn data-field="Nombre" caption="Nombre"/>
        <DxColumn data-field="Bomba" caption="Bomba"/>
        <DxColumn data-field="Producto" caption="Producto"/>
        <DxColumn data-field="Cantidad" caption="Cantidad"/>
        <DxColumn data-field="ComisionString" caption="Comision"/> -->
        
        <DxColumn data-field="Fecha" caption="Fecha" dataType="date"/>
        <DxColumn data-field="Turno" caption="Turno"/>
        <DxColumn data-field="Bomba" caption="Bomba"/>
        <DxColumn data-field="CantidadCG" caption="CantidadCG" dataType="number">
            <DxFormat
                type="currency"
                :precision="2"
            />
        </DxColumn>
        <DxColumn data-field="CantidadAddes" caption="CantidadAddes"/>
        <DxColumn data-field="Diferencia" caption="Diferencia"/>

        <DxSummary>
            <DxGroupItem
                column="Turno"
                summary-type="sum"
                valueFormat="currency"
                
            />
            <!-- <DxGroupItem
                column="nu_importe"
                summary-type="sum"
            />
            <DxGroupItem
                column="nu_importe"
                summary-type="max"
            /> -->
            <DxTotalItem
                column="Diferencia"
                summary-type="sum"
            >
            
            </DxTotalItem>
        </DxSummary>
<!--         
                 -->
        
    </DxDataGrid>

  </div>

		<!-- bottom left vue-notification -->
		<notifications group="top-right" position="top right" :speed="1000" />
	</div>
</template>

<script>

import axios from 'axios';
import moment from 'moment';

import { DxDataGrid, DxColumn, DxExport, DxSelection, DxGroupPanel, DxSummary, DxGroupItem, DxGrouping, DxHeaderFilter,DxFilterRow,DxSearchPanel, 
  DxTotalItem,DxFormat} from 'devextreme-vue/data-grid';
import { Workbook } from 'exceljs';
import { saveAs } from 'file-saver';
// Our demo infrastructure requires us to use 'file-saver-es'. We recommend that you use the official 'file-saver' package in your applications.
import { exportDataGrid } from 'devextreme/excel_exporter';
//import service from './data.js';


export default {
  mounted: function () {
    var self = this;
    debugger; // eslint-disable-line no-debugger
    var userSetting = JSON.parse(localStorage.getItem('userSettings'));
    this.usuario = userSetting.Usuario;
    this.usuarioEstacion = userSetting.Estacion;
    this.IdRol = userSetting.IdRol;
    this.getCiudades();
    this.ciudadSelect = userSetting.CiudadId;
    this.getEstaciones();
    this.estacionSelect = userSetting.Estacion;

    if(this.IdRol == 3 || this.IdRol == 7){
      this.deshabilitarCombos = true;
    }


    var dateIn = self.$route.params.fechaIn;
    var fechaFin = self.$route.params.fechaFin;
    var ciudad = self.$route.params.ciudad;
    var estacion = self.$route.params.estacion;
    if(dateIn != undefined){
      self.date = moment(dateIn).format("YYYY/MM/DD");
      self.dateEnd = moment(fechaFin).format("YYYY/MM/DD");
      self.ciudadSelect = ciudad;
      self.estacionSelect = estacion;
      
    }

    debugger; // eslint-disable-line no-debugger

  },
	beforeCreate() {
    // var userSetting = localStorage.getItem('userSettings');
    // if(userSetting != undefined){
    //   console.log(JSON.parse(userSetting));
    //   this.pageUserSettings = JSON.parse(userSetting);
    // }else{
		// 	this.$router.push('/login');
    // }
	},
	created() {
		// console.log('created');
	},
	beforeMount() {
		// console.log('beforeMount');
	},
	components: {
		DxDataGrid, DxColumn, DxExport, DxSelection, DxGroupPanel, DxGrouping, DxSummary, DxGroupItem, 
        DxHeaderFilter,DxFilterRow,DxSearchPanel,
        DxTotalItem,DxFormat
	},
  methods: {
	async getCiudades(){
		try{
        const response = await axios.get('Catalogos/GetCiudades');
		//debugger; // eslint-disable-line no-debugger
        if(response.data.Success){
          if(response.data.response.length == 0){
            this.alertMessage = "No se encontró información.";
            this.alertTitle = "Error";
            this.show('top-right', 'error');
          }
        }else{
            console.log("error")
            this.alertMessage = response.data.Error;
            this.alertTitle = "Error";
            this.show('top-right', 'error');
        }
        this.ciudades = response.data.response;
      }catch(e){
        //this.alert = true;
        //this.error = 'Invalid email/password';
      }
	},
	async getEstaciones(){
		try{
		debugger; // eslint-disable-line no-debugger
        const response = await axios.post('Estacion/GetEstaciones?ciudad='+this.ciudadSelect);
        if(response.data.Success){
          if(response.data.response.length == 0){
            this.alertMessage = "No se encontró información.";
            this.alertTitle = "Error";
            this.show('top-right', 'error');
          }
        }else{
            console.log("error")
            this.alertMessage = response.data.Error;
            this.alertTitle = "Error";
            this.show('top-right', 'error');
        }
        this.estaciones = response.data.response;
        this.productos = [];
        this.getProductos();
      }catch(e){
        //this.alert = true;
        //this.error = 'Invalid email/password';
      }
	},
    redirectTanques(){
      
          this.$router.push({ path: '/tanques' });
    },
			show (group, type = '') {
				const text = this.alertMessage;
				this.$notify({
					group,
					title: this.alertTitle,
					text,
					type,
					data: {
						randomNumber: Math.random()
					}
				})
			},
			clean (group) {
				this.$notify({ group, clean: true })
			},
			swalNotification(swalType) {
				var btnClass = (swalType == 'error') ? 'danger' : swalType;
					btnClass = (swalType == 'question') ? 'primary' : btnClass;
				this.$swal({
					title: 'Are you sure?',
					text: 'You will not be able to recover this imaginary file!',
					type: swalType,
					showCancelButton: true,
					buttonsStyling: false,
					confirmButtonText: swalType.charAt(0).toUpperCase() + swalType.substr(1).toLowerCase(),
					cancelButtonText: 'Cancel',
					confirmButtonClass: 'btn me-5px btn-'+ btnClass +'',
					cancelButtonClass: 'btn btn-default',
				})
			},
    onExporting(e) {
      const workbook = new Workbook();
      const worksheet = workbook.addWorksheet('ReporteComisiones');

      exportDataGrid({
        component: e.component,
        worksheet: worksheet,
        autoFilterEnabled: true
      }).then(() => {
        workbook.xlsx.writeBuffer().then((buffer) => {
          saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'Diferencia de productos.xlsx');
        });
      });
      e.cancel = true;
    },
    // onExporting(e) {
    //   const workbook = new Workbook();
    //   const worksheet = workbook.addWorksheet('ReporteVentasEstacion');

    //   exportDataGrid({
    //     component: e.component,
    //     worksheet: worksheet,
    //     autoFilterEnabled: true
    //   }).then(() => {
    //     workbook.xlsx.writeBuffer().then((buffer) => {
    //       saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'Fajillas.xlsx');
    //     });
    //   });
    //   e.cancel = true;
    // },
    async search(e){
        var self = this;
      e.preventDefault();

      // var mesInicio = moment(self.date).format('MM');
      // var mesfin = moment(self.dateEnd).format('MM');

    //   if(moment(self.date).format('MM') != moment(self.dateEnd).format('MM')){

    //         self.alertMessage = "Las fechas deben corresponder al mismo mes."
    //         self.alertTitle = "Error";
    //         self.show('top-right', 'error');
    //         return;
    //   }
      self.textoBotonBuscar = "Buscando...";
      self.loading = true;
      try{
        self.intDate = self.date.replace('/', '');
        self.intDate = self.intDate.replace('/', '');


        self.intDateEnd = self.dateEnd.replace('/', '');
        self.intDateEnd = self.intDateEnd.replace('/', '');

        var ciudad = self.ciudadSelect!=undefined?self.ciudadSelect:0;
        var estacion = self.estacionSelect!=undefined?self.estacionSelect:0;
        debugger; // eslint-disable-line no-debugger

        const response = await axios.post('Ventas/VentaProductoDiaTurnoBomba' ,{
        "Data": {
            "FechaInicial": self.intDate,
            "FechaFinal":self.intDateEnd,
            "Ciudad": ciudad, 
            "Estacion": estacion       
        }
        });
        if(response.data.Success){
          if(response.data.response.length == 0){
            self.alertMessage = "No se erncontro información del día especificado.";
            self.alertTitle = "Error";
            self.show('top-right', 'error');
          }
        }else{
            console.log("error")
            self.alertMessage = response.data.Error;
            self.alertTitle = "Error";
            self.show('top-right', 'error');
        }
        self.registros = response.data.response;
        
		debugger; // eslint-disable-line no-debugger
        // for(var n=0;n<respuesta.length;n++){
        //   respuesta[n].FechaString = moment(respuesta[n].Fecha).format("DD/MM/YYYY");
        // }
        // self.dataSource = {
        // fields: [
        //   { dataField: 'Nombre', area: 'row' },
        //   { dataField: 'FechaString', area: 'column' },
        //   { dataField: 'Cantidad', area: 'data', summaryType: 'sum', dataType: "number", type:"fixedPoint", precision:2   },
        //   { dataField: 'Monto', area: 'data', summaryType: 'sum', dataType: "number", type:"fixedPoint", precision:2   },
        // ],
        // store: respuesta,
        // };
        
        

      self.textoBotonBuscar = "Buscar";
      
      self.loading = false;
      }catch(e){
      self.loading = false;
        //this.alert = true;
        //this.error = 'Invalid email/password';
      }
    }
  },
	data() {
		return {

        registros:[],
      allowSearch: true,
      showRelevantValues: true,

      dataSource: {
      },
      // applyChangesModes: ['instantly', 'onDemand'],
      applyChangesMode: 'instantly',
      
      loading:false,
      showHeaderFilter: true,
      deshabilitarCombos:false,
      ciudades: [],
      estaciones: [],
      ciudadSelect: 1,
      estacionSelect: 0,
            date: moment().startOf('month').format("YYYY/MM/DD"),
            intDateEnd: "",
            dateEnd: moment().format("YYYY/MM/DD"),
            intDate: "",
            options: {
                format: 'YYYY/MM/DD',
                useCurrent: false,
            },
            pageUserSettings: {},
            alertMessage: "",
            alertTitle: "",
            reporteCre: [],
            textoBotonBuscar: "Buscar",
		};
	}
}
</script>
<style scoped>
.dx-datagrid-header-panel{
  display: none !important;
}
.loading-search{
  position: absolute;
    width: 100%;
    left: 0px;
    height: 100%;
    background: rgba(0, 0, 0, 0.1);
    z-index: 1000;
    padding-top: 150px;
    top: 0;
}
</style>