import { render, staticRenderFns } from "./asignar-horarios.vue?vue&type=template&id=9def10d2&scoped=true&"
import script from "./asignar-horarios.vue?vue&type=script&lang=js&"
export * from "./asignar-horarios.vue?vue&type=script&lang=js&"
import style0 from "./asignar-horarios.vue?vue&type=style&index=0&id=9def10d2&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9def10d2",
  null
  
)

export default component.exports