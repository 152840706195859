<template>
  <div>
    <div class=" loading-search" v-if="loading">
      <div class="fa-3x text-center">
        <i class="fas fa-circle-notch fa-spin text-success" style=""></i>
      </div>
    </div>
    <!-- BEGIN breadcrumb -->
    <ol class="breadcrumb float-xl-end">
      <li class="breadcrumb-item"><a href="javascript:;">Catalogos</a></li>
      <li class="breadcrumb-item active">
        <a href="javascript:;">Asignación de Vendedores a Bomba</a>
      </li>
    </ol>
    <!-- END breadcrumb -->
    <!-- BEGIN page-header -->
    <h1 class="page-header">Asignación de Vendedores a Bomba</h1>
    <!-- END page-header -->


    <panel title="Asignación de Vendedores a Bomba" bodyClass="p-0" class="">
        <div class="row">
            <div class="col-md-5">
                <h3>Vendedores</h3>
                <vue-good-table
                    :columns="columns"
                    :rows="venderores"
                    :lineNumbers="false"
                    :search-options="{ enabled: true, placeholder: 'Buscar' }"
                    :pagination-options="{ enabled: true, position: 'bottom' }"
                >
                    <template slot="table-row" slot-scope="props">
                    <span v-if="props.column.label == 'Acciones'">
                        <button
                        class="btn btn-xs btn-success"
                        @click="seleccionarVendedor(props.row)"
                        >
                        Seleccionar
                        </button>
                    </span>
                    </template>
                </vue-good-table>
            </div>
            <div class="col-md-3" style="padding-top: 1.4rem;">
                <form @submit.prevent="SetColaboradorAsignacion">  
                    <fieldset>








                      <div class="mb-3">
                            <label class="form-label" for="txtCiudad">Ciudad</label>
                            <select class="form-select" @change="getEstaciones()" v-model="ciudadSelect" :disabled="deshabilitarCombos">
                              <option v-for="ciudad in ciudades" :value="ciudad.Id" v-bind:key="ciudad.Id">
                                  {{ ciudad.Nombre }}
                              </option>
                          </select>
                        </div>
                      
                      


                        <div class="mb-3">
                            <label class="form-label" for="txtEstacion">Estación</label>
                            <select class="form-select" v-model="estacionSelect" @change="reloadInfo" :disabled="deshabilitarCombos">
                              <!-- <option value="0">Todas</option> -->
                                <option v-for="estacion in estaciones" :value="estacion.NoEstacion" v-bind:key="estacion.NoEstacion">
                                {{ estacion.Nombre }}
                                </option>
                            </select>
                        </div>
                      
                      







                      <div class="mb-3">
                          <label class="col-form-label">Fecha inicial</label>
                          <div class="input-group">
                            <date-picker id="dateTimeInput" v-model="date" :config="options"></date-picker>
                            <label for="dateTimeInput" class="input-group-text">
                              <i class="fa fa-calendar"></i>
                            </label>
                          </div>
                        </div>
                        <div class="mb-3">
                            <label class="form-label" for="txtNoEmpleado">Número de empleado</label>
                            <input class="form-control" type="text" id="txtNoEmpleado" placeholder="Número de empleado"  disabled="true" v-model="vendedorSeleccionado.NumeroVentuk"/>
                        </div>
                        <div class="mb-3">
                            <label class="form-label" for="txtNombre">Nombre</label>
                            <input class="form-control" type="text" id="txtNombre" placeholder="Nombre"  disabled="true" v-model="vendedorSeleccionado.Nombre"/>
                        </div>
                        <div class="mb-3">
                            <label class="form-label" for="txtPuesto">Turno</label>
                            <select v-model="turnoSelected.IdTurno" class="form-control" @change="getAsignaciones">
                                <option v-for="turno in turnos" :value="turno.IdTurno" v-bind:key="turno.IdTurno">
                                {{ turno.Descripcion }}
                                </option>
                            </select>
                        </div>
                        <div class="mb-3">
                            <label class="form-label" for="txtPuesto">Bomba</label>
                            <select v-model="bombaSelected.Id" class="form-control">
                                <option v-for="bomba in bombas" :value="bomba.Id" v-bind:key="bomba.Id">
                                {{ bomba.Descripcion }}
                                </option>
                            </select>
                        </div>
                        <div class="row">
                            <div class="col-md-12 text-center">
                                <button type="submit" class="btn  btn-lg btn-success" :disabled="vendedorSeleccionado.NumeroEmpleado == undefined">Guardar</button>
                            </div>
                            <!-- <div class="col-md-6 text-center">
                                <button type="button" class="btn  btn-lg btn-default">Cancelar</button>
                            </div> -->
                        </div>
                    </fieldset>

                </form>
            </div>
            <div class="col-md-4">
                <h3>Asignados</h3>
                <vue-good-table
                    :columns="columnsAsignados"
                    :rows="asignaciones"
                    :lineNumbers="false"
                    :search-options="{ enabled: true, placeholder: 'Buscar' }"
                    :pagination-options="{ enabled: true, position: 'bottom' }"
                >
                    <template slot="table-row" slot-scope="props">
                    <span v-if="props.column.label == 'Hora'">
                    {{  props.row.HoraChecada | formatDateTime}}
                    </span>
                    </template>
                </vue-good-table>
            </div>

        </div>
    </panel>

    <!-- bottom left vue-notification -->
    <notifications group="top-right" position="top right" :speed="1000" />
  </div>
</template>

<script>
import axios from "axios";
import { formatDate } from "devextreme/localization";
import moment from 'moment';

import Vue from "vue";


Vue.filter("formatDateTime", function (value) {
    debugger; // eslint-disable-line no-debugger
  if (value) {
    return moment(String(value)).format('DD/MM/YYYY h:mm a')
  }
});
export default {
  mounted: function () {
    debugger; // eslint-disable-line no-debugger
    var userSetting = JSON.parse(localStorage.getItem('userSettings'));
    this.usuario = userSetting.Usuario;
    this.usuarioEstacion = userSetting.Estacion;
    this.IdRol = userSetting.IdRol;
    
    this.getCiudades();
    this.ciudadSelect = userSetting.CiudadId;
    this.getEstaciones();
    this.estacionSelect = userSetting.Estacion;

    this.getVendedores(this.usuarioEstacion);
    this.GetTurnos();
    this.GetBombas();

    if(this.IdRol == 3 || this.IdRol == 7){
      this.deshabilitarCombos = true;
    }
    
  },
  methods: {
	async getCiudades(){
		try{
        const response = await axios.get('Catalogos/GetCiudades');
		//debugger; // eslint-disable-line no-debugger
        if(response.data.Success){
          if(response.data.response.length == 0){
            this.alertMessage = "No se encontró información.";
            this.alertTitle = "Error";
            this.show('top-right', 'error');
          }
        }else{
            console.log("error")
            this.alertMessage = response.data.Error;
            this.alertTitle = "Error";
            this.show('top-right', 'error');
        }
        this.ciudades = response.data.response;
      }catch(e){
        //this.alert = true;
        //this.error = 'Invalid email/password';
      }
	},
	async getEstaciones(){
		try{
		debugger; // eslint-disable-line no-debugger
        const response = await axios.post('Estacion/GetEstaciones?ciudad='+this.ciudadSelect);
        if(response.data.Success){
          if(response.data.response.length == 0){
            this.alertMessage = "No se encontró información.";
            this.alertTitle = "Error";
            this.show('top-right', 'error');
          }
        }else{
            console.log("error")
            this.alertMessage = response.data.Error;
            this.alertTitle = "Error";
            this.show('top-right', 'error');
        }
        this.estaciones = response.data.response;
        this.productos = [];
        this.getProductos();
      }catch(e){
        //this.alert = true;
        //this.error = 'Invalid email/password';
      }
	},
    selectBomba(){
    debugger; // eslint-disable-line no-debugger
        var self = this;
        for(var n = 0; n<self.bombas.length ;n++ ){
            if(self.bombas[n].Id == self.bombaSelected.Id){
                self.bombaSelected = self.bombas[n];
            }
        }
    },
    async SetColaboradorAsignacion(e){
      e.preventDefault();
      var self = this;
      if (
        self.vendedorSeleccionado.NumeroEmpleado == undefined
      ) {
        this.showNotification(
          "Validacion",
          "Seleccione el vendedor",
          "warning"
        );
        return;
      }



      
      if (
        self.turnoSelected.IdTurno == 0
        || self.bombaSelected.Id == 0
      ) {
        this.showNotification(
          "Validacion",
          "Seleccione Turno y Bomba",
          "warning"
        );
        return;
      }
        this.textoBotonGuardar = "Guardando...";
        self.loading = true;
        try {
          var bombaNumero = 0;
          var bombaIsla = 0;
          for(var n = 0; n<self.bombas.length ;n++ ){
              if(self.bombas[n].Id == self.bombaSelected.Id){
                  // self.bombaSelected = self.bombas[n];
                  bombaNumero = self.bombas[n].Numero;
                  bombaIsla = self.bombas[n].IdIsla;
              }
          }
          
            debugger; // eslint-disable-line no-debugger
            // self.intDate = self.date.replace('/', '');
            // self.intDate = self.intDate.replace('/', '');
            var dataToSend = {
            Data: 
            {
                "NumeroEmpleado": self.vendedorSeleccionado.NumeroVentuk,
                "IdBomba": self.bombaSelected.Id,
                "noIsla": bombaIsla,
                "Fecha": self.date,
                "Turno": self.turnoSelected.IdTurno,
                "NoBomba": bombaNumero,
                "Estacion": self.estacionSelect
            },
          };

            debugger; // eslint-disable-line no-debugger
          if(self.bombaSelected == undefined){
            
            self.loading = true;
            this.alertMessage = "Seleccione bomba";
            this.alertTitle = "Error";
            this.show("top-right", "error");
            return;
          }
          
            
          const response = await axios.post("Colaborador/SetColaboradorAsignacion", dataToSend);
            debugger; // eslint-disable-line no-debugger
          self.loading = false;
          if (response.data.Success) {
            this.alertMessage = "Se asignó correctamente";
            this.alertTitle = "Guardado con exito";
            this.show("top-right", "success");

            this.getAsignaciones();
          } else {
            this.alertMessage = response.data.Message;
            this.alertTitle = "Error";
            this.show("top-right", "error");
          }
          this.textoBotonGuardar = "Guardar";
        } catch (e) {
          self.loading = false;
          this.alertMessage = "Ocurrio un error";
          this.alertTitle = "Error";
          this.show("top-right", "error");
        }


    },
    seleccionarVendedor(vendedor){
      var self = this;
      if(vendedor.NumeroVentuk == 0){
        this.alertMessage = "El número de empleado Ventuk es incorrecto";
        this.alertTitle = "";
        this.show("top-right", "info");
        return;
      }
      self.vendedorSeleccionado = vendedor;
    },
    async GetBombas(){
    debugger; // eslint-disable-line no-debugger
        var self = this;
      try {
        const response = await axios.post("Estacion/GetBombas?noEstacion="+self.estacionSelect);
        if (response.data.Success) {
          if (response.data.response.length == 0) {
            this.alertMessage =
              "No se encontraron bombas";
            this.alertTitle = "Error";
            this.show("top-right", "info");
          } else {
    debugger; // eslint-disable-line no-debugger
            this.bombas = response.data.response;
          }
        } else {
          this.alertMessage = response.data.Message;
          this.alertTitle = "Error";
          this.show("top-right", "error");
        }
      } catch (e) {
        this.alertMessage = "Ocurrio un error";
        this.alertTitle = "Error";
        this.show("top-right", "error");
      }
    },
    reloadInfo(){
      this.turnoSelected.IdTurno = 11;
      this.GetBombas();
      this.getAsignaciones();
      this.getVendedores(this.estacionSelect)
    },
    async GetTurnos(){
      try {
        const response = await axios.get("Catalogos/GetTurnos");
        if (response.data.Success) {
          if (response.data.response.length == 0) {
            this.alertMessage =
              "No se encontraron turnos";
            this.alertTitle = "Error";
            this.show("top-right", "error");
          } else {
            this.turnos = response.data.response;
          }
        } else {
          this.alertMessage = response.data.Message;
          this.alertTitle = "Error";
          this.show("top-right", "error");
        }
      } catch (e) {
        this.alertMessage = "Ocurrio un error";
        this.alertTitle = "Error";
        this.show("top-right", "error");
      }
    },
    async GetUsosCFDI(){
      try {
        const response = await axios.get("Catalogos/GetUsosCFDI");
        if (response.data.Success) {
          if (response.data.response.length == 0) {
            this.alertMessage =
              "No se encontró usos CFDI";
            this.alertTitle = "Error";
            this.show("top-right", "error");
          } else {
            this.usosCFDI = response.data.response;
          }
        } else {
          this.alertMessage = response.data.Message;
          this.alertTitle = "Error";
          this.show("top-right", "error");
        }
      } catch (e) {
        this.alertMessage = "Ocurrio un error";
        this.alertTitle = "Error";
        this.show("top-right", "error");
      }
    },
    showNotification(title, text, type) {
      this.alertTitle = title;
      this.alertMessage = text;
      this.show("top-right", type);
    },
    show(group, type = "") {
      const text = this.alertMessage;
      this.$notify({
        group,
        title: this.alertTitle,
        text,
        type,
        data: {
          randomNumber: Math.random(),
        },
      });
    },
    clean(group) {
      this.$notify({ group, clean: true });
    },
    swalNotification(swalType) {
      var btnClass = swalType == "error" ? "danger" : swalType;
      btnClass = swalType == "question" ? "primary" : btnClass;
      this.$swal({
        title: "Are you sure?",
        text: "You will not be able to recover this imaginary file!",
        type: swalType,
        showCancelButton: true,
        buttonsStyling: false,
        confirmButtonText:
          swalType.charAt(0).toUpperCase() + swalType.substr(1).toLowerCase(),
        cancelButtonText: "Cancel",
        confirmButtonClass: "btn me-5px btn-" + btnClass + "",
        cancelButtonClass: "btn btn-default",
      });
    },
    
    
      async getVendedores(estacion){
        var self = this;
        this.textoBotonBuscar = "Buscando...";
        try {
          const response = await axios.post("Colaborador/GetColaboradores",
            {"Data":
              {
                "Estacion":estacion //self.usuarioEstacion
              }
            });
          
          if (response.data.Success) {
            if (response.data.response.length == 0) {
              this.alertMessage =
                "No se encontró información de usuarios";
              this.alertTitle = "Error";
              this.show("top-right", "error");
            } else {
              this.venderores = response.data.response;
            }
          } else {
            this.alertMessage = response.data.Message;
            this.alertTitle = "Error";
            this.show("top-right", "error");
          }
          this.textoBotonBuscar = "Buscar";
        } catch (e) {
            this.alertMessage = "Ocurrio un error";
            this.alertTitle = "Error";
            this.show("top-right", "error");
        }

      },
      async getAsignaciones(){
        var self = this;
          self.loading = true;
        this.textoBotonBuscar = "Buscando...";
        try {
          const response = await axios.post("Colaborador/GetAsignaciones",
            {"Data":
              {
                    "Fecha": self.date,
                    "Turno": self.turnoSelected.IdTurno,
                    "Estacion":self.estacionSelect, 
                }
            }
        );
          
    debugger; // eslint-disable-line no-debugger
          self.loading = false;
          if (response.data.Success) {
            if (response.data.response.length == 0) {
            //   this.alertMessage =
            //     "No se encontró información de usuarios";
            //   this.alertTitle = "Error";
            //   this.show("top-right", "error");
            } else {
              this.asignaciones = response.data.response;
            }
          } else {
            this.asignaciones = [];
            this.alertMessage = response.data.Message;
            this.alertTitle = "Notificación";
            this.show("top-right", "info");
          }
          this.textoBotonBuscar = "Buscar";
        } catch (e) {
    debugger; // eslint-disable-line no-debugger
            this.asignaciones = [];
            this.alertMessage = "Ocurrio un error";
            this.alertTitle = "Error";
            this.show("top-right", "error");
          this.loading = false;
        }

      },
    async search(e) {
      e.preventDefault();
      this.getVendedores(this.usuarioEstacion);
    },
  },
  data() {
    return {
      deshabilitarCombos: false,
      loading:false,
      usuarioEstacion: undefined,
      estaciones:[],
      ciudades:[],
      ciudadSelect: 1,
      estacionSelect: 0,
      IdRol:0,
      usuario:'',
      date: moment().format("YYYY-MM-DD"),
      // date: moment().format("YYYY/MM/DD"),
      intDate: "",
      options: {
        format: 'YYYY-MM-DD',
        useCurrent: false,
      },
      esRolVendendor: false,
      empresaTressSelected:'',
      puestoSelected:{
          puesto:0
      },
      rolSelected:{
          rol:0
      },
      empresaTressSelect:[],
      actualizarNIP:false,
      puestosSelect:[],
      rolesSelect:[],
      nuevoCorreo: '',
      clienteNuevo: true,
      clienteModal:{},
      textoBotonGuardar: "Guardar",
      textoBotonBuscar: "Buscar",
      clienteBuscar: "",
      columns: [
        {
          label: "# Empleado",
          field: "NumeroEmpleado",
          type: "number",
          tdClass: "text-center text-nowrap",
          thClass: "text-center text-nowrap",
        },
        {
          label: "# Ventuk",
          field: "NumeroVentuk",
          type: "number",
          tdClass: "text-center text-nowrap",
          thClass: "text-center text-nowrap",
        },
        {
          label: "Nombre",
          field: "Nombre",
          tdClass: "text-center text-nowrap",
          thClass: "text-center text-nowrap",
          width: '10%',
        },
        {
          label: "Acciones",
          field: "Acciones",
          width: '20%',
          tdClass: "text-center text-nowrap",
          thClass: "text-center text-nowrap",
        }
      ],
      columnsAsignados: [
        {
          label: "Bomba",
          field: "Bomba",
          tdClass: "text-center text-nowrap",
          thClass: "text-center text-nowrap",
        },
        {
          label: "# Empleado",
          field: "NumeroEmpleado",
          type: "number",
          tdClass: "text-center text-nowrap",
          thClass: "text-center text-nowrap",
        },
        {
          label: "Nombre",
          field: "Nombre",
          tdClass: "text-center text-nowrap",
          thClass: "text-center text-nowrap",
          width: '10%',
        },
        {
          label: "Hora Checada",
          field: "HoraChecada",
          tdClass: "text-center text-nowrap",
          thClass: "text-center text-nowrap",
        }
      ],
      turnos:[],
      clientes: [],
      bombas: [],
      venderores: [],
      asignaciones: [],
      vendedorSeleccionado: {},
      turnoSelected:{
          IdTurno:0
      },
      bombaSelected:{
          Id:0
      },
    };
  },
};
</script>

<style scoped>

.loading-search{
  position: absolute;
    width: 100%;
    left: 0px;
    height: 100%;
    background: rgba(0, 0, 0, 0.1);
    z-index: 1000;
    padding-top: 150px;
    top: 0;
}
</style>