<template>
	<div>
    <div class=" loading-search" v-if="loading">
      <div class="fa-3x text-center">
          <i class="fas fa-circle-notch fa-spin text-success" style=""></i>
      </div>
    </div>
		<!-- BEGIN breadcrumb -->
		<ol class="breadcrumb float-xl-end">
			<li class="breadcrumb-item"><a href="javascript:;">Catálogos</a></li>
			<li class="breadcrumb-item"><a href="javascript:;">colaboradores</a></li>
			<li class="breadcrumb-item active"><a href="javascript:;">Autorizadores</a></li>
		</ol>
		<!-- END breadcrumb -->
		<!-- BEGIN page-header -->
		<h1 class="page-header">Autorizadores</h1>
        <!-- <button class="btn" v-on:click="redirectTanques()">tanques</button> -->
		<!-- END page-header -->
		<!-- <a href="javascript:;" v-on:click="show('top-right', 'warn')" class="btn btn-sm btn-primary">Demo</a> -->
		
    <div class="row">
      <div class="col-md-12">
        <form @submit.prevent="search">    
          <div class="form-group row">
            <div class="col-6" style="padding-top: 2.65em;">
              <button type="submit" class="btn btn-success me-1 mb-1 pull-right">{{textoBotonBuscar}}</button>
            </div>
            <div class="col-6" style="padding-top: 2.65em; text-align: right;">
              <button type="button" class="btn btn-success me-1 mb-1 pull-right" v-on:click="agregarNuevo()">Agregar</button>
            </div>
          </div>
        </form>
      </div>
      



    </div>


    <DxDataGrid
      id="gridContainer"
      :data-source="autorizadores"
      key-expr="NumeroEmpleado"
      :show-borders="true"
      @exporting="onExporting"
      :show-column-lines="false"
      :show-row-lines="false"
      :row-alternation-enabled="true"
    >
      <!-- :column-width="100" -->
    

    <DxScrolling column-rendering-mode="virtual"/>

    <DxPager
        :show-page-size-selector="true"
        :allowed-page-sizes="[10, 20, 50, 100]"
        :show-navigation-buttons="true"
    />
    <DxPaging
        :page-size="10"
        :page-index="1" /> 
    <DxFilterRow/>
      <DxHeaderFilter
        :visible="showHeaderFilter"
      />
      <DxSearchPanel
        :visible="true"
        :width="240"
        placeholder="Search..."
      />

      <DxGroupPanel :visible="true"/>
      <DxGrouping :auto-expand-all="true"/>
      <DxExport
        :enabled="true"
        :allow-export-selected-data="false"
      />
      <DxSelection mode="false"/>

        <DxColumn dataField="Abrir" caption="Abrir" :cellTemplate="openModalDialog" />
        <DxColumn data-field="NumeroEmpleado" caption="No Empleado">
          <DxHeaderFilter :allow-search="true"/>
        </DxColumn>

        <DxColumn data-field="Nombre" caption="Nombre"/>
        <DxColumn data-field="Estatus" caption="Estatus"/>
        <DxColumn data-field="Modulo" caption="Modulo"/>
        <!-- <DxColumn data-field="Correo" caption="Correo"/> -->
        <DxColumn data-field="FechaRegistro" caption="FechaRegistro"/>
        <DxColumn data-field="FechaActualizacion" caption="FechaActualizacion"/>
        <DxColumn data-field="Usuario" caption="Usuario"/>

        <!-- <DxColumn data-field="nu_auth" caption="Autorización"/>
        <DxColumn data-field="nu_afiliacion" caption="Afiliación"/>
        <DxColumn data-field="cc_nombre" caption="Nombre"/>
        <DxColumn data-field="cc_tp"  caption="Tipo Tarjeta"/>
        <DxColumn data-field="cd_instrumento" caption="Tipo Pago"/>
        <DxColumn data-field="cc_num"  caption="4 digitos"/>
        <DxColumn dataField="Repeticiones" caption="Multiples Movimientos" :cellTemplate="StatusCellTemplate" />
        <DxColumn data-field="fh_registro" caption="Fecha registro"  width="150"/>
        <DxColumn data-field="cd_usrtransaccion" caption="Usr GetNet"  width="250"/>
        <DxColumn data-field="usuario" caption="Usuario"/>
        <DxColumn data-field="transaccion" caption="Transacción"/>
        <DxColumn data-field="nb_resp"  caption="Respuesta"/>
        <DxColumn data-field="nb_referencia" caption="Referencia" />
        <DxColumn data-field="nu_sucursal" caption="Sucursal"/> -->



        <!-- <DxColumn data-field="nu_importe" caption="Importe" dataType="number">
          <DxFormat
              type="currency"
              :precision="2"
              />
        </DxColumn> -->
        <!-- <DxSummary>
            <DxGroupItem
                column="cd_instrumento"
                summary-type="count"
            />
            <DxGroupItem
                column="importeReal"
                summary-type="sum"
                value-format="$#,##0.##"
                displayFormat='Total: {0}'
            />
            <DxGroupItem
                column="importeReal"
                summary-type="max"
            />
        </DxSummary> -->
    </DxDataGrid>




	<!-- #modal-dialog -->
	<b-modal id="modalDialog" size="lg" class="modalUsuarios">
		<template slot="modal-header">
      <h4 class="modal-title" v-if="autorizadorNuevo">Nuevo Autorizador</h4>
			<h4 class="modal-title" v-if="!autorizadorNuevo">{{clienteModal.Nombre}}</h4>
    
			<a class="btn-close" @click="$bvModal.hide('modalDialog')"></a>
		</template>
          <div class="row" v-if="autorizadorNuevo">
            <div class="col-12">
              <div class="form-group row">
                <div class="col-md-12">
                    <label class="col-form-label">Buscar Autorizador</label>
                    <div class="input-group">
                      <input type="text" class="form-control" v-model="colaboradorBuscar" />
                      <button type="button" class="btn btn-success" @click="getColaboradores()">Buscar</button>
                    </div>
                  </div>
              </div>


              
              
                <div class="form-group row">
                  <div class="col-md-12">

                    <vue-good-table
                      :columns="columns"
                      :rows="colaboradores"
                      :lineNumbers="false"
                      :search-options="{ enabled: true, placeholder: 'Buscar' }"
                      :pagination-options="{ enabled: true, position: 'bottom', perPage: 5 }"
                    >
                      <template slot="table-row" slot-scope="props">
                        <span v-if="props.column.label == 'Seleccionar'">
                          <button type="button" class="btn btn-success btn-xs" @click="seleccionarColaborador(props.row)">Seleccionar</button>
                        </span>
                        <span v-else-if="props.column.field == 'Estatus'">
                          <span v-if="props.row.Estatus == 1" class="fa fa-check" style="color: green;"></span>
                          <span v-if="props.row.Estatus == 2" class="fa fa-ban" style="color: rgb(223, 39, 39);"></span>
                        </span>
                      </template>
                    </vue-good-table>
                  </div>
                </div>
            </div>
          </div>


					<div class="row">
            <div class="col-12">

              
              <form action="/" method="POST">
                      <fieldset>
                        <div class="row">
                          <div class="col-12">
                            
                            <div class="form-check form-switch mb-2" v-if="!autorizadorNuevo">
                                <input class="form-check-input" type="checkbox" id="flexSwitchCheckChecked" v-model="clienteModalEstatus" v-on:click="saveAutorizador(false, clienteModalEstatus)">
                                <label class="form-check-label" for="flexSwitchCheckChecked"><span v-if="clienteModal.Estatus == 'Active'">Autoirizador Activo</span><span v-if="!clienteModal.Estatus == 'Inactive'">Autorizador Inactivo</span></label>
                              </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-6">
                            
                            <div class="mb-3">
                              <label class="form-label" for="txtNombre">Nombre</label>
                              <input class="form-control" type="text" id="txtNombre" placeholder="Nombre" :disabled="true" v-model="clienteModal.Nombre"/>
                            </div>

                            <div class="mb-3">
                              <label class="form-label" for="txtNoEmpleado">Número de empleado</label>
                              <input class="form-control" type="text" id="txtNoEmpleado" placeholder="Número de empleado"  :disabled="true" v-model="clienteModal.NumeroEmpleado"/>
                            </div>
                            
                            
                          </div>
                          <div class="col-md-6">
                            <div class="mb-3">
                              <label class="form-label" for="txtModulo">Modulo</label>
                              <input  class="form-control" v-if="!autorizadorNuevo" type="text" id="txtModulo" placeholder="Modulo"  :disabled="true"  v-model="clienteModal.Modulo"/>
                              <select class="form-control" v-if="autorizadorNuevo" v-model="moduloSelect">
                                <option value="Viajes">Viajes</option>
                                <option value="Solicitudes">Solicitudes de compra</option>
                                <option value="Compras">Compras</option>

                                
                              </select>
                            </div>

                            <div class="mb-3">
                              <label class="form-label" for="txtUsuario">Usuario</label>
                              <input class="form-control" type="text" id="txtUsuario" placeholder="Usuario"  :disabled="true" v-model="clienteModal.Usuario"/>
                            </div>
                            
                          </div>
                        </div>
              

                      </fieldset>
                      
                    </form>
            </div>



					</div>
          
      <template slot="modal-footer">
          <button type="button" class="btn btn-primary mr-auto" @click="saveAutorizador(true, 'Active')" :disabled="clienteModal.NumeroEmpleado == ''" v-if="autorizadorNuevo">{{textoBotonGuardar}}</button>
          <button type="button" class="btn btn-secondary" @click="$bvModal.hide('modalDialog')">Cancelar</button>
      </template>
	</b-modal>



		<!-- bottom left vue-notification -->
		<notifications group="top-right" position="top right" :speed="1000" />
	</div>
</template>

<script>

import axios from 'axios';
import moment from 'moment';
// import { DxDataGrid, DxFormat, DxPaging, DxPager, DxScrolling, DxColumn, DxExport, DxSelection, DxGroupPanel, DxSummary, DxGroupItem, DxGrouping, DxHeaderFilter,DxFilterRow,DxSearchPanel, } from 'devextreme-vue/data-grid';
import { DxDataGrid, DxPaging, DxPager, DxScrolling, DxColumn, DxExport, DxSelection, DxGroupPanel, DxGrouping, DxHeaderFilter,DxFilterRow,DxSearchPanel, } from 'devextreme-vue/data-grid';
import { Workbook } from 'exceljs';
import { saveAs } from 'file-saver';
// Our demo infrastructure requires us to use 'file-saver-es'. We recommend that you use the official 'file-saver' package in your applications.
import { exportDataGrid } from 'devextreme/excel_exporter';
//import service from './data.js';

function abrirModal(){
    alert('abierto')
}

export default {
	beforeCreate() {
    // var userSetting = localStorage.getItem('userSettings');
    // if(userSetting != undefined){
    //   console.log(JSON.parse(userSetting));
    //   this.pageUserSettings = JSON.parse(userSetting);
    // }else{
		// 	this.$router.push('/login');
    // }
	},
	created() {
		// console.log('created');
	},
	beforeMount() {
		// console.log('beforeMount');
	},
	mounted() {
        // debugger; // eslint-disable-line no-debugger
		var userSetting = JSON.parse(localStorage.getItem('userSettings'));
		this.usuario = userSetting.Usuario;
		this.idUsuario = userSetting.NumeroEmpleado;
		this.nombreLogueado = userSetting.Nombre;
		this.usuarioEstacion = userSetting.Estacion;
		this.IdRol = userSetting.IdRol;
    
    // this.getCiudades();
    // this.ciudadSelect = userSetting.CiudadId;
    // this.getEstaciones();
    // this.estacionSelect = userSetting.Estacion;

    if(this.IdRol == 3 || this.IdRol == 7){
      this.deshabilitarCombos = true;
    }
	},
	components: {
		// DxDataGrid, DxFormat, DxPaging,DxPager, DxScrolling, DxColumn, DxExport, DxSelection, DxGroupPanel, DxGrouping, DxSummary, DxGroupItem, DxHeaderFilter,DxFilterRow,DxSearchPanel,
		DxDataGrid, DxPaging,DxPager, DxScrolling, DxColumn, DxExport, DxSelection, DxGroupPanel, DxGrouping, DxHeaderFilter,DxFilterRow,DxSearchPanel,
	},
  methods: {
    seleccionarColaborador(colaborador){
      var self = this;

      self.clienteModal =  colaborador;
      
    },
    async getColaboradores() {
      var self = this;
      this.textoBotonBuscar = "Buscando...";
      self.loading = true;
      try {
        const response = await axios.post("Colaborador/GetColaboradoresBusqueda", {
          Data: {
            Busqueda: this.colaboradorBuscar
          },
        });
        
      self.loading = false;
        if (response.data.Success) {
          if (response.data.response.length == 0) {
            this.alertMessage =
              "No se encontró información del cliente especificado.";
            this.alertTitle = "Error";
            this.show("top-right", "error");
          } else {
      debugger; // eslint-disable-line no-debugger
            this.colaboradores = response.data.response;
          }
        } else {
          this.alertMessage = response.data.Error;
          this.alertTitle = "Error";
          this.show("top-right", "error");
        }
        this.textoBotonBuscar = "Buscar";
      } catch (e) {
        self.loading = false;
        this.alertMessage = "Ocurrio un error";
        this.alertTitle = "Error";
        this.show("top-right", "error");
      }
    },
    async saveAutorizador(isNew, estatus){
      //Colaborador/SetAutorizador
      /*
      clienteModal.NumeroEmpleado
      clienteModal.Nombre
      moduloSelect
      clienteModal.Usuario
     

      */
     var self = this;
     
     debugger; // eslint-disable-line no-debugger
     var estatusGuardar = 'Active';
     if(isNew){
      estatusGuardar = 'Active';
     }else{
      estatusGuardar = estatus ? 'Inactive':'Active';
     }
     
      var dataToSend = {
      "Data": {
        "NoEmpleado": self.clienteModal.NumeroEmpleado,
        "Nombre": self.clienteModal.Nombre,
        "Estatus": estatusGuardar,
        "Modulo": self.moduloSelect,
        "Usuario": self.clienteModal.Usuario,
        "Correo": ""
        }
      };

      
      self.loading = true;
      debugger; // eslint-disable-line no-debugger
      try {
          const response = await axios.post("Autorizador/SetAutorizador",dataToSend);
          
          self.loading = false;
          debugger; // eslint-disable-line no-debugger
          if (response.data.Success) {
            this.alertMessage = response.data.Message;
            this.alertTitle = "Guardado";
            this.show("top-right", "success");
            self.buscarAutorizadoresGrid();
            if(isNew){
              self.$bvModal.hide('modalDialog');
            }
          } else {
            this.alertMessage = response.data.Message;
            this.alertTitle = "Error";
            this.show("top-right", "error");
          }
          this.textoBotonGuardar = "Guardar";
        } catch (e) {
            self.loading = false;
            this.alertMessage = "Ocurrio un error";
            this.alertTitle = "Error";
            this.show("top-right", "error");
        }
        
          this.textoBotonGuardar = "Guardar";

    },
    agregarNuevo(){
      var self = this;
      self.autorizadorNuevo = true;
      self.clienteModal = {
        "NumeroEmpleado": '',
        "Nombre": "",
        "Estatus": "Active",
        "Modulo": "",
        "Usuario": ""
      };
      
      this.$bvModal.show('modalDialog');
    },
    openModalDialog(cellElement, cellInfo) {
        
      const button = document.createElement("button");
      button.innerText = "Editar";
      button.className = "btn btn-sm btn-success";
      button.addEventListener("click", () => {
        
        this.handleButtonClick(cellInfo.data);
      });

      cellElement.appendChild(button);
    },
    handleButtonClick(data) {
		debugger; // eslint-disable-line no-debugger
    
      // alert(`Button clicked for ID: `+data.NumeroEmpleado);
      this.autorizadorNuevo = false;
      this.clienteModal = data;
      this.clienteModalEstatus = data.Estatus == 'Active'? true:false;
      this.$bvModal.show('modalDialog');
    },
    
    // async getColaborador(noEmpleado){
    //     try {
    //       const response = await axios.post("Colaborador/GetColaboradorADDES",{"Data":{"NoEmpleado":noEmpleado}});
    //       debugger; // eslint-disable-line no-debugger
    //       if (response.data.Success) {
    //         if (response.data.response == null) {
    //           this.alertMessage =
    //             "No se encontró información del colaborador especificado.";
    //           this.alertTitle = "Error";
    //           this.show("top-right", "error");
    //         } else {
    //           this.clienteModal = response.data.response;
              

    //           // //ToDO
    //           // this.AutorizadoresColaborador = response.data.response.ViajesAut;
    //           // this.AutorizadoresComprasColaborador = response.data.response.SolicitudesAut;

    //         }
    //       } else {
    //         this.alertMessage = response.data.Message;
    //         this.alertTitle = "Error";
    //         this.show("top-right", "error");
    //       }
    //     } catch (e) {
    //         this.alertMessage = "Ocurrio un error";
    //         this.alertTitle = "Error";
    //         this.show("top-right", "error");
    //     }
    
        
    //   },
    StatusCellTemplate(cellElement, cellInfo) {
      
      const repeticiones = cellInfo.data.Repeticiones;
      const iconSrc = repeticiones > 1 ? '/assets/img/user/user-13.jpg' : 'https://www.pngfind.com/pngs/m/639-6399088_img-success-svg-png-icon-free-download-download.png';
      const repeticionesString = repeticiones>1? repeticiones.toString():"";
      if(repeticiones == 1){
        cellElement.innerHTML = `<span>${repeticiones}</span><i class="fas fa-lg fa-fw me-10px fa-exclamation-triangle"  style="color: darkseagreen;"></i>`;
      }else if(repeticiones == 2){
        cellElement.innerHTML = `<span>${repeticionesString}</span><i class="fas fa-lg fa-fw me-10px fa-exclamation-triangle"  style="color: orange;"></i>`;
      }else if(repeticiones > 2){
        cellElement.innerHTML = `<span>${repeticionesString}</span><i class="fas fa-lg fa-fw me-10px fa-exclamation-triangle" style="color: #ff3631;"></i>`;
      }
      
      
      
    },
    
    
	// async getCiudades(){
	// 	try{
    //     const response = await axios.get('Catalogos/GetCiudades');
        
    //     if(response.data.Success){
    //       if(response.data.response.length == 0){
    //         this.alertMessage = "No se encontró información.";
    //         this.alertTitle = "Error";
    //         this.show('top-right', 'error');
    //       }
    //     }else{
    //         console.log("error")
    //         this.alertMessage = response.data.Error;
    //         this.alertTitle = "Error";
    //         this.show('top-right', 'error');
    //     }
    //     this.ciudades = response.data.response;
    //   }catch(e){
        
    //   }
	// },
	// async getEstaciones(){
	// 	try{
            
    //     const response = await axios.post('Estacion/GetEstaciones?ciudad='+this.ciudadSelect);
    //     if(response.data.Success){
    //       if(response.data.response.length == 0){
    //         this.alertMessage = "No se encontró información.";
    //         this.alertTitle = "Error";
    //         this.show('top-right', 'error');
    //       }
    //     }else{
    //         console.log("error")
    //         this.alertMessage = response.data.Error;
    //         this.alertTitle = "Error";
    //         this.show('top-right', 'error');
    //     }
    //     this.estaciones = response.data.response;
    //     this.productos = [];
    //     this.getProductos();
    //   }catch(e){
        
    //   }
	// },
			show (group, type = '') {
				const text = this.alertMessage;
				this.$notify({
					group,
					title: this.alertTitle,
					text,
					type,
					data: {
						randomNumber: Math.random()
					}
				})
			},
			clean (group) {
				this.$notify({ group, clean: true })
			},
			swalNotification(swalType) {
				var btnClass = (swalType == 'error') ? 'danger' : swalType;
					btnClass = (swalType == 'question') ? 'primary' : btnClass;
				this.$swal({
					title: 'Are you sure?',
					text: 'You will not be able to recover this imaginary file!',
					type: swalType,
					showCancelButton: true,
					buttonsStyling: false,
					confirmButtonText: swalType.charAt(0).toUpperCase() + swalType.substr(1).toLowerCase(),
					cancelButtonText: 'Cancel',
					confirmButtonClass: 'btn me-5px btn-'+ btnClass +'',
					cancelButtonClass: 'btn btn-default',
				})
			},
    onExporting(e) {
      const workbook = new Workbook();
      const worksheet = workbook.addWorksheet('autorizadores');

      exportDataGrid({
        component: e.component,
        worksheet: worksheet,
        autoFilterEnabled: true
      }).then(() => {
        workbook.xlsx.writeBuffer().then((buffer) => {
          saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'Centro de pagos.xlsx');
        });
      });
      e.cancel = true;
    },
    
    async search(e){
		// debugger; // eslint-disable-line no-debugger
      e.preventDefault();
      this.buscarAutorizadoresGrid();

    },
    async buscarAutorizadoresGrid(){
      var self = this;
      self.textoBotonBuscar = "Buscando...";
      
      self.loading = true;
      try{
        self.ReporteGetNetCompleto = [];
        self.autorizadores = [];
        
       
        
        const response = await axios.get('Autorizador/GetAutorizadores');
        
      self.loading = false;
		debugger; // eslint-disable-line no-debugger
        if(response.data.Success){

          if(response.data.response.length == 0){
            this.alertMessage = "No se encontró información";
            this.alertTitle = "Error";
            this.show('top-right', 'error');
            this.autorizadores = [];
          }else{
            
            
            self.autorizadores = response.data.response;
          }
        }else{
            self.loading = false;
            this.autorizadores = [];
            console.log("error")
            this.alertMessage = response.data.Message;
            this.alertTitle = "Error";
            this.show('top-right', 'error');
        }

      this.textoBotonBuscar = "Buscar";
      }catch(e){
            this.autorizadores = [];
            this.alertMessage = "Ocurrió un error";
            this.alertTitle = "Error";
            this.show('top-right', 'error');
        //this.alert = true;
        //this.error = 'Invalid email/password';
      }
    }
  },
	data() {
		return {
      moduloSelect: 'Viajes',
      colaborador:{},
      colaboradorBuscar:'',
      colaboradores: [],
      autorizadorNuevo: true,
      ReporteGetNetCompleto:[],
      showHeaderFilter: true,
      loading:false,
      nombreLogueado:"",
      idUsuario:undefined,
      reportefajillas:[],
      deshabilitarCombos:false,
      ciudades: [],
      estaciones: [],
      ciudadSelect: 1,
      estacionSelect: 0,

      pageUserSettings: {},
      alertMessage: "",
      alertTitle: "",
      ReporteGetNet: [],
      autorizadores: [],
      
      textoBotonGuardar: "Guardar",
      textoBotonBuscar: "Buscar",
      date: moment().format("DD/MM/YYYY"),
      intDate: "",
      options: {
        format: 'DD/MM/YYYY',
        useCurrent: false,
      },
      esRolVendendor: false,
      empresaTressSelected:'',
      puestoSelected:{
          puesto:0
      },
      rolSelected:{
          rol:0
      },
      empresaTressSelect:[],
      actualizarNIP:false,
      puestosSelect:[],
      rolesSelect:[],
      clienteModal:{},
      
      columns: [
        {
          label: "Nombre",
          field: "Nombre",
          tdClass: "text-center text-nowrap",
          thClass: "text-center text-nowrap",
        },
        {
          label: "No Empleado",
          field: "NumeroEmpleado",
          tdClass: "text-center text-nowrap",
          thClass: "text-center text-nowrap",
        },
        {
          label: "Usuario",
          field: "Usuario",
          tdClass: "text-center text-nowrap",
          thClass: "text-center text-nowrap",
        },
        {
          label: "Estatus",
          field: "Estatus",
          tdClass: "text-center text-nowrap",
          thClass: "text-center text-nowrap",
        },
        {
          label: "Seleccionar",
          field: "Seleccionar",
          width: '10%',
          tdClass: "text-center text-nowrap",
          thClass: "text-center text-nowrap",
        },
      ],
		};
	}
}
</script>
<style scoped>

.loading-search{
  position: absolute;
    width: 100%;
    left: 0px;
    height: 100%;
    background: rgba(0, 0, 0, 0.1);
    z-index: 10000;
    padding-top: 150px;
    top: 0;
}
.dx-datagrid-header-panel{
  display: none !important;
}
/* #gridContainer {
  height: 440px;
} */
</style>