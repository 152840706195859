<template>
  <div>
    <!-- BEGIN breadcrumb -->
    <ol class="breadcrumb float-xl-end">
      <li class="breadcrumb-item"><a href="javascript:;">Facturación</a></li>
      <li class="breadcrumb-item active">
        <a href="javascript:;">Rendichicas</a>
      </li>
    </ol>
    <!-- END breadcrumb -->
    <!-- BEGIN page-header -->
    <h1 class="page-header">Consulta</h1>
    <!-- END page-header -->


	<div class="row">
      <div class="col-md-8">
        <form @submit.prevent="search">    
          <div class="form-group row">
            
            <div class="col-md-3">
              <div class="input-group">
                <date-picker id="dateTimeInput" v-model="date" :config="options"></date-picker>
                <label for="dateTimeInput" class="input-group-text">
                  <i class="fa fa-calendar"></i>
                </label>
              </div>
            </div>

            
            <div class="col-md-3">
              <div class="input-group">
                <date-picker id="dateTimeInputEnd" v-model="dateEnd" :config="options"></date-picker>
                <label for="dateTimeInputEnd" class="input-group-text">
                  <i class="fa fa-calendar"></i>
                </label>
              </div>
            </div>


            <div class="col-md-6">
              <button type="submit" class="btn btn-success me-1 mb-1">{{textoBotonBuscar}}</button>
            </div>
          </div>
        </form>
      </div>
      <!-- <div class="col-md-4">
        <div class="col-md-12 text-end">
          <button type="button" v-b-modal.modalDialog class="btn btn-sm btn-success" @click="getCliente('',0)">Agregar Colaborador</button>
        </div>
      </div> -->
    </div>
    <panel title="Consulta de facturas" bodyClass="p-0" class="">
      <vue-good-table
        :columns="columns"
        :rows="items"
        :lineNumbers="false"
        :search-options="{ enabled: true, placeholder: 'Buscar' }"
        :pagination-options="{ enabled: true, position: 'bottom' }"
      >
        <template slot="table-row" slot-scope="props">
          <span v-if="props.column.label == 'Acciones'">
            <!-- <button v-b-modal.modalDialog class="btn btn-sm btn-success" @click="getXML(props.formattedRow.NumeroEmpleado)">Ver</button> -->
            <button class="btn btn-xs btn-success" @click="getArchivos(props.formattedRow, true)">PDF</button>
            &nbsp;
            <button class="btn btn-xs btn-success" @click="getArchivos(props.formattedRow, false)">XML</button>
            &nbsp;
            <button v-b-modal.modalDialog class="btn btn-xs btn-success" @click="reenviarModal(props.formattedRow)">Reenviar</button>
          </span>
          <!-- <span v-else-if="props.column.field == 'Estatus'">
            <span v-if="props.formattedRow[props.column.field]" class="fa fa-check"></span>
            <span v-if="!props.formattedRow[props.column.field]" class="fa fa-ban"></span>
          </span> -->
        </template>
      </vue-good-table>
    </panel>
	



	<!-- #modal-dialog -->
	<b-modal id="modalDialog">
		<template slot="modal-header">
			<h4 class="modal-title">Reenviar Factura</h4>
			<a class="btn-close" @click="$bvModal.hide('modalDialog')"></a>
		</template>
    
					<div>
						<form action="/" method="POST">
							<fieldset>
								<div class="mb-3">
									<label class="form-label" for="txtEmail">Email</label>
									<input class="form-control" type="email" id="txtEmail" placeholder="email"  v-model="emailReenviar"/>
								</div>
                
                

							</fieldset>
						</form>
					</div>
          
      <template slot="modal-footer">
          <button type="button" class="btn btn-primary mr-auto" @click="reenviar()">Reenviar</button>
          <button type="button" class="btn btn-secondary" @click="$bvModal.hide('modalDialog')">Cancelar</button>
      </template>
	</b-modal>



	<!-- bottom left vue-notification -->
	<notifications group="top-right" position="top right" :speed="1000" />
  </div>
</template>

<script>

import axios from "axios";
import moment from 'moment';
export default {
async	created() {
  
	},
  methods: {
    reenviarModal(item){
      var self = this;
      self.uuidReenviar = item.UUID;
    },
    async getArchivos(item, esPDF){
      var self = this;
      try {
        debugger; // eslint-disable-line no-debugger
    
        const response = await axios.post("CFDI/GetArchivosCFDISA?UUID="+item.UUID);
        
        if (response.data.Success) {
          
        debugger; // eslint-disable-line no-debugger
          if (response.data.response.length == 0) {
            this.alertMessage =
              "No se encontraron archivos";
            this.alertTitle = "Error";
            this.show("top-right", "error");
          } else {
            if(esPDF){
              self.getPDF(response.data.response);
            }else{
              self.getXML(response.data.response);
            }
            
          }
        } else {
        debugger; // eslint-disable-line no-debugger
          this.alertMessage = response.data.Error;
          this.alertTitle = "Error";
          this.show("top-right", "error");
        }
        this.textoBotonBuscar = "Buscar";
      } catch (e) {
          this.alertMessage = "Ocurrio un error";
          this.alertTitle = "Error";
          this.show("top-right", "error");
      }

    },
    getXML(item){
      console.log(item);
      
                    // var xmltext = response.response.XML;





                    var xmltext = item.XML;
                    var folio = "123";
                    // var pom = document.createElement("a");

                    var filename = "Factura_Electronica_" + folio + ".xml";
                    var pom = document.createElement("a");
                    var bb = new Blob([xmltext], { type: "text/plain" });

                    pom.setAttribute("href", window.URL.createObjectURL(bb));
                    pom.setAttribute("download", filename);

                    pom.dataset.downloadurl = ["text/plain", pom.download, pom.href].join(
                        ":"
                    );
                    pom.draggable = true;
                    pom.classList.add("dragout");

                    pom.click();
    },
    getPDF(item){
      console.log(item);
                    var folio = '123';
                    var filename = "Factura_Electronica_" + folio + ".pdf";
                    // The actual download
                    // var blob = new Blob([response.response.PDF], {
                    //     type: "application/pdf",
                    // });
                    var link = document.createElement("a");
                    // link.href = "data:application/pdf;base64," + response.response.PDF;
                    link.href = "data:application/pdf;base64," + item.PDF;
                    link.download = filename;
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
    },
    async reenviar(){
      var self = this;
      try {
        debugger; // eslint-disable-line no-debugger
    
        const response = await axios.post("CFDI/SendCFDISA",
            {"Data":
              {"UUID": self.uuidReenviar,
              "Correo": self.emailReenviar}
            });
        
        if (response.data.Success) {
          
        debugger; // eslint-disable-line no-debugger
          if (response.data.response.length == 0) {
            this.alertMessage =
              "No se encontraron archivos";
            this.alertTitle = "Error";
            this.show("top-right", "error");
          } else {
            
            
            self.alertMessage = "Se envió la factura exitosamente";
            self.alertTitle = "Envio exitoso";
            self.show("top-right", "success");
            
              self.$bvModal.hide('modalDialog');
          }
        } else {
        debugger; // eslint-disable-line no-debugger
          this.alertMessage = response.data.Error;
          this.alertTitle = "Error";
          this.show("top-right", "error");
        }
        this.textoBotonBuscar = "Buscar";
      } catch (e) {
          this.alertMessage = "Ocurrio un error";
          this.alertTitle = "Error";
          this.show("top-right", "error");
      }

    },
    showNotification(title,text,type){
      this.alertTitle = title;
      this.alertMessage = text;
      this.show("top-right", type);
    },
			show (group, type = '') {
				const text = this.alertMessage;
				this.$notify({
					group,
					title: this.alertTitle,
					text,
					type,
					data: {
						randomNumber: Math.random()
					}
				})
			},
			clean (group) {
				this.$notify({ group, clean: true })
			},
			swalNotification(swalType) {
				var btnClass = (swalType == 'error') ? 'danger' : swalType;
					btnClass = (swalType == 'question') ? 'primary' : btnClass;
				this.$swal({
					title: 'Are you sure?',
					text: 'You will not be able to recover this imaginary file!',
					type: swalType,
					showCancelButton: true,
					buttonsStyling: false,
					confirmButtonText: swalType.charAt(0).toUpperCase() + swalType.substr(1).toLowerCase(),
					cancelButtonText: 'Cancel',
					confirmButtonClass: 'btn me-5px btn-'+ btnClass +'',
					cancelButtonClass: 'btn btn-default',
				})
			},
      async getData(){
        this.textoBotonBuscar = "Buscando...";
        try {
      debugger; // eslint-disable-line no-debugger
      
        this.intDate = this.date.replace('/', '');
        this.intDate = this.intDate.replace('/', '');

        
        this.intDateEnd = this.dateEnd.replace('/', '');
        this.intDateEnd = this.intDateEnd.replace('/', '');
          const response = await axios.post("CFDI/GetFacturaSA",
            {"Data":
              {"FechaInicial":this.intDate,
              "FechaFinal":this.intDateEnd}
            });
          
          if (response.data.Success) {
            if (response.data.response.length == 0) {
              this.alertMessage =
                "No se encontró información de usuarios";
              this.alertTitle = "Error";
              this.show("top-right", "error");
            } else {
              this.items = response.data.response;
            }
          } else {
            this.alertMessage = response.data.Error;
            this.alertTitle = "Error";
            this.show("top-right", "error");
          }
          this.textoBotonBuscar = "Buscar";
        } catch (e) {
            this.alertMessage = "Ocurrio un error";
            this.alertTitle = "Error";
            this.show("top-right", "error");
        }

      },
    async search(e) {
      e.preventDefault();
      this.getData();
    },
  },
  data() {
    return {
      emailReenviar:"",
      uuidReenviar:"",
      date: moment().startOf('month').format("YYYY/MM/DD"),
      intDateEnd: "",
      dateEnd: moment().format("YYYY/MM/DD"),
      intDate: "",
      options: {
        format: 'YYYY/MM/DD',
        useCurrent: false,
      },
      puestoSelected:{
          puesto:0
      },
      rolSelected:{
          rol:0
      },
      actualizarNIP:false,
      puestosSelect:[],
      rolesSelect:[],
      nuevoCorreo: '',
      clienteNuevo: true,
      clienteModal:{},
      textoBotonGuardar: "Guardar",
      textoBotonBuscar: "Buscar",
      clienteBuscar: "",
      columns: [
        {
              label: "Serie",
              field: "Serie",
              tdClass: "text-center text-nowrap",
              thClass: "text-center text-nowrap",
        },
        {
              label: "Folio",
              field: "Folio",
              tdClass: "text-center text-nowrap",
              thClass: "text-center text-nowrap",
        },
        {
              label: "UUID",
              field: "UUID",
              tdClass: "text-nowrap",
              thClass: "text-center text-nowrap",
        },
        {
              label: "Estatus",
              field: "Estatus",
              tdClass: "text-center text-nowrap",
              thClass: "text-center text-nowrap",
        },
        {
              label: "Fecha Emision",
              field: "FechaEmision",
              tdClass: "text-nowrap",
              thClass: "text-center text-nowrap",
        },
        {
              label: "Fecha Timbrado",
              field: "FechaTimbrado",
              tdClass: "text-nowrap",
              thClass: "text-center text-nowrap",
        },
        {
              label: "RFC Emisor",
              field: "RfcEmisor",
              tdClass: "text-center text-nowrap",
              thClass: "text-center text-nowrap",
        },
        {
              label: "RFC Receptor",
              field: "RfcReceptor",
              tdClass: "text-center text-nowrap",
              thClass: "text-center text-nowrap",
        },
        {
              label: "Total",
              field: "Total",
              tdClass: "text-end text-nowrap",
              thClass: "text-center text-nowrap",
        },
        {
          label: "Acciones",
          field: "Acciones",
          width: '20%',
          tdClass: "text-center text-nowrap",
          thClass: "text-center text-nowrap",
        }
      ],
      items: [],
    };
  },
};
</script>