<template>
    <div>
    <div class=" loading-search" v-if="loading">
      <div class="fa-3x text-center">
        <i class="fas fa-circle-notch fa-spin text-success" style=""></i>
      </div>
    </div>
      <!-- BEGIN breadcrumb -->
      <ol class="breadcrumb float-xl-end">
        <li class="breadcrumb-item"><a href="javascript:;">Reportes</a></li>
        <li class="breadcrumb-item"><a href="javascript:;">Ingresos</a></li>
        <li class="breadcrumb-item active">
          <a href="javascript:;">Mezcla</a>
        </li>
      </ol>
      <!-- END breadcrumb -->
      <!-- BEGIN page-header -->
      <h1 class="page-header">Mezcla</h1>
      <!-- END page-header -->
  
      
      <form @submit.prevent="search">   
          <div class="row">
              <div class="col-md-8"> 
                  <div class="form-group row">
                    
                    <div class="col-md-3">
                        <label class="col-form-label">Fecha inicial</label>
                      <div class="input-group">
                        <date-picker id="dateTimeInput" v-model="date" :config="options"></date-picker>
                        <label for="dateTimeInput" class="input-group-text">
                          <i class="fa fa-calendar"></i>
                        </label>
                      </div>
                    </div>

                    
                    <div class="col-md-3">
                        <label class="col-form-label">Fecha final</label>
                      <div class="input-group">
                        <date-picker id="dateTimeInputEnd" v-model="dateEnd" :config="options"></date-picker>
                        <label for="dateTimeInputEnd" class="input-group-text">
                          <i class="fa fa-calendar"></i>
                        </label>
                      </div>
                    </div>
                    <div class="col-md-3">
                        <label class="col-form-label">Ciudad</label>
                        <select class="form-select" @change="getEstaciones()" v-model="ciudadSelect" :disabled="deshabilitarCombos">
                            <option v-for="ciudad in ciudades" :value="ciudad.Id" v-bind:key="ciudad.Id">
                                {{ ciudad.Nombre }}
                            </option>
                        </select>
                    </div>
                    <div class="col-md-3">
                        <label class="col-form-label">Estación</label>
                        <select class="form-select" v-model="estacionSelect" :disabled="deshabilitarCombos">
                          <option value="0">Todas</option>
                            <option v-for="estacion in estaciones" :value="estacion.NoEstacion" v-bind:key="estacion.NoEstacion">
                            {{ estacion.Nombre }}
                            </option>
                          </select>
                    </div>

                  </div>
              </div>
              <div class="col-md-2">
                    <button type="submit" class="btn btn-success me-1 mb-1" style="margin-top: 3.1em;">{{textoBotonBuscar}}</button>
              </div>
              <div class="col-md-2"  style="padding-top: 2.7em;">
                <!-- <download-excel
                  v-if="registrosLineales.length > 0"
                  class="btn btn-success rounded-pill mt-35"
                  :data="registrosLineales"
                  :fields="json_fields"
                  :worksheet="estacionSelect"
                  :name="`Cuadre Venta vs Factura ${date}.xls`"
                  
                  >
                    Descargar en Excel <i class="fas fa-file-excel fa-fw"></i>
                </download-excel>
                <button v-else disabled class="btn btn-success rounded-pill mt-35">
                    Descargar en Excel <i class="fas fa-file-excel fa-fw"></i>
                </button> -->
              </div>
            </div>
        </form>
      


      <panel title="Mezcla" bodyClass="p-0" class="">
        <div>
          
          <DxPivotGrid
            id="sales"
            :allow-sorting-by-summary="true"
            :allow-sorting="true"
            :allow-filtering="true"
            :allow-expand-all="true"
            :height="600"
            :show-borders="true"
            :data-source="dataSource"
            @exporting="onExporting"
          >      
          
            <DxExport :enabled="true"/>
            <DxFieldChooser
              :enabled="true"
              :allow-search="true"
              :apply-changes-mode="applyChangesMode"
            />
          </DxPivotGrid>
        </div>
      </panel>
  
      
      <!-- bottom left vue-notification -->
      <notifications group="top-right" position="top right" :speed="1000" />
    </div>
  </template>
  
  <script>
  import axios from "axios";
    import moment from 'moment';

    import DxPivotGrid, {
      DxExport,
      DxFieldChooser,
    } from 'devextreme-vue/pivot-grid';
    import PivotGridDataSource from 'devextreme/ui/pivot_grid/data_source';


  import { Workbook } from 'exceljs';
  import { saveAs } from 'file-saver-es';

  import { exportPivotGrid } from 'devextreme/excel_exporter';
  export default {
    mounted: function () {
        var self = this;
    debugger; // eslint-disable-line no-debugger
    var userSetting = JSON.parse(localStorage.getItem('userSettings'));
    this.usuario = userSetting.Usuario;
    this.usuarioEstacion = userSetting.Estacion;
    this.IdRol = userSetting.IdRol;
    this.getCiudades();
    this.ciudadSelect = userSetting.CiudadId;
    this.getEstaciones();
    this.estacionSelect = userSetting.Estacion;



    if(this.IdRol == 3 || this.IdRol == 7){
      this.deshabilitarCombos = true;
    }

    },
    components: {
      DxPivotGrid,
      DxExport,
      DxFieldChooser,
    },
    methods: {
        
	async getCiudades(){
		try{
            const response = await axios.get('Catalogos/GetCiudades');
            if(response.data.Success){
            if(response.data.response.length == 0){
                this.alertMessage = "No se encontró información.";
                this.alertTitle = "Error";
                this.show('top-right', 'error');
            }
            }else{
                console.log("error")
                this.alertMessage = response.data.Error;
                this.alertTitle = "Error";
                this.show('top-right', 'error');
            }
            this.ciudades = response.data.response;
        }catch(e){
            //this.alert = true;
            //this.error = 'Invalid email/password';
        }
	},
	async getEstaciones(){
		try{
		debugger; // eslint-disable-line no-debugger
        const response = await axios.post('Estacion/GetEstaciones?ciudad='+this.ciudadSelect);
        if(response.data.Success){
          if(response.data.response.length == 0){
            this.alertMessage = "No se encontró información.";
            this.alertTitle = "Error";
            this.show('top-right', 'error');
          }
        }else{
            console.log("error")
            this.alertMessage = response.data.Error;
            this.alertTitle = "Error";
            this.show('top-right', 'error');
        }
        this.estaciones = response.data.response;
      }catch(e){
        //this.alert = true;
        //this.error = 'Invalid email/password';
      }
	},
      showNotification(title, text, type) {
        this.alertTitle = title;
        this.alertMessage = text;
        this.show("top-right", type);
      },
      show(group, type = "") {
        const text = this.alertMessage;
        this.$notify({
          group,
          title: this.alertTitle,
          text,
          type,
          data: {
            randomNumber: Math.random(),
          },
        });
      },
      clean(group) {
        this.$notify({ group, clean: true });
      },
      swalNotification(swalType) {
        var btnClass = swalType == "error" ? "danger" : swalType;
        btnClass = swalType == "question" ? "primary" : btnClass;
        this.$swal({
          title: "Are you sure?",
          text: "You will not be able to recover this imaginary file!",
          type: swalType,
          showCancelButton: true,
          buttonsStyling: false,
          confirmButtonText:
            swalType.charAt(0).toUpperCase() + swalType.substr(1).toLowerCase(),
          cancelButtonText: "Cancel",
          confirmButtonClass: "btn me-5px btn-" + btnClass + "",
          cancelButtonClass: "btn btn-default",
        });
      },
      onExporting(e) {
		debugger; // eslint-disable-line no-debugger
        var self = this;
      const workbook = new Workbook();
      const worksheet = workbook.addWorksheet('Mezcla');

      exportPivotGrid({
        component: e.component,
        worksheet,
      }).then(() => {
		debugger; // eslint-disable-line no-debugger
        workbook.xlsx.writeBuffer().then((buffer) => {
          saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'Mezcla.xlsx');
        });
      });
      e.cancel = true;
    },
      async getRegistros() {
        var self = this;
        self.loading = true;
        self.textoBotonBuscar = "Buscando...";
        try {

            
        self.intDate = self.date.replace('/', '');
        self.intDate = self.intDate.replace('/', '');


        self.intDateEnd = self.dateEnd.replace('/', '');
        self.intDateEnd = self.intDateEnd.replace('/', '');

        var ciudad = self.ciudadSelect!=undefined?self.ciudadSelect:0;
        var estacion = self.estacionSelect!=undefined?self.estacionSelect:0;
        debugger; // eslint-disable-line no-debugger

        var dataToSend = {
          "Data": {
              "FechaIni": self.intDate,
              "FechaFin":self.intDateEnd,
              "Ciudad": ciudad, 
              "Estacion": estacion,
          }
        };
          
		debugger; // eslint-disable-line no-debugger
          const response = await axios.post("Ingreso/GetIngresoTipoPago", dataToSend);
          self.loading = false;
          if (response.data.Success) {
            if (response.data.response.length == 0) {
                this.alertMessage = response.data.Message;
              this.alertTitle = "Error";
              this.show("top-right", "error");
            } else {

              self.registros = response.data.response;


                    
              self.dataSource = new PivotGridDataSource({
                fields: [

                {
                    caption: 'Nombre',
                    dataField: 'Nombre',
                    area: 'row',
                },
                {
                    caption: 'TipoPago',
                    dataField: 'TipoPago',
                    area: 'column',
                },
                  {
                    caption: 'Monto',
                    dataField: 'Monto',
                    dataType: 'currency',
                    summaryType: 'sum',
                    format: '$#,##0.##',
                    area: 'data',
                  },
                // {
                //     caption: '',
                //     dataField: '',
                //     area: 'row',
                // },
                // {
                //     caption: '',
                //     dataField: '',
                //     area: 'row',
                // }

                /*
                    Estacion
                    
                    Ciudad
                    Fecha
                    Turno
                    TipoPago
                    Valor
                    Monto
                */


                //   {
                //     caption: 'Ciudad',
                //     dataField: 'Ciudad',
                //     area: 'row',
                //   },
                //   {
                //     dataField: 'Mes',
                //     dataType: 'Mes',
                //     area: 'column',
                //   },
                //   {
                //     caption: 'Facturas',
                //     dataField: 'Facturas',
                //     dataType: 'number',
                //     summaryType: 'sum',
                //     format: '#,##0.##',
                //     area: 'data',
                //   },


                ],
                store: self.registros,
              });



            }
          } else {
                this.alertMessage = response.data.Message;
            this.alertTitle = "Error";
            this.show("top-right", "error");
          }
          this.textoBotonBuscar = "Buscar";
        } catch (e) {
          self.loading = false;
          this.alertMessage = "Ocurrio un error";
          this.alertTitle = "Error";
          this.show("top-right", "error");
        }
      },
      async search(e) {
        e.preventDefault();
        this.getRegistros();
      },
    },
    data() {
      return {
        ciudades: [],
        estaciones: [],
        ciudadSelect: 1,
        estacionSelect: 0,
        date: moment().startOf('month').format("YYYY/MM/DD"),
        intDateEnd: "",
        dateEnd: moment().format("YYYY/MM/DD"),
        intDate: "",
        options: {
            format: 'YYYY/MM/DD',
            useCurrent: false,
        },
      incluirCanceladas: false,
      allowSearch: true,
      showRelevantValues: true,

      applyChangesMode: 'instantly',
      
      loading:false,
      showHeaderFilter: true,
      deshabilitarCombos:false,
        dataSource: {
        },
        years: [],
        yearSelect: parseInt(moment().format("YYYY")),
        registros:[],
        textoBotonBuscar: "Buscar",
        
      };
    },
  };
  </script>
  
<style scoped>
/* .dx-datagrid-header-panel{
  display: none !important;
} */
.loading-search{
  position: absolute;
    width: 100%;
    left: 0px;
    height: 100%;
    background: rgba(0, 0, 0, 0.1);
    z-index: 1000;
    padding-top: 150px;
    top: 0;
}
</style>