import { render, staticRenderFns } from "./binarapp-getpedidos.vue?vue&type=template&id=11b81111&scoped=true&"
import script from "./binarapp-getpedidos.vue?vue&type=script&lang=js&"
export * from "./binarapp-getpedidos.vue?vue&type=script&lang=js&"
import style0 from "./binarapp-getpedidos.vue?vue&type=style&index=0&id=11b81111&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "11b81111",
  null
  
)

export default component.exports