<template>
	<div>
		<!-- BEGIN breadcrumb -->
		<ol class="breadcrumb float-xl-end">
			<li class="breadcrumb-item"><a href="javascript:;">Inicio</a></li>
			<li class="breadcrumb-item"><a href="javascript:;">Reportes</a></li>
			<li class="breadcrumb-item active"><a href="javascript:;">Ventas</a></li>
		</ol>
		<!-- END breadcrumb -->
		<!-- BEGIN page-header -->
		<h1 class="page-header">Ventas <small>official documentation <a href="https://xaksis.github.io/vue-good-table/" target="_blank">here</a></small></h1>
		<!-- END page-header -->
		<a href="javascript:;" v-on:click="show('bottom-left', 'warn')" class="btn btn-sm btn-primary">Demo</a>
		
		


    <DxDataGrid
      id="gridContainer"
      :data-source="reporteCre"
      key-expr="CodGas"
      :show-borders="true"
      @exporting="onExporting"
    >
      <DxGroupPanel :visible="true"/>
      <DxGrouping :auto-expand-all="true"/>
      <DxExport
        :enabled="true"
        :allow-export-selected-data="false"
      />
      <DxSelection mode="false"/>
      <DxColumn data-field="Plaza"/>
      <DxColumn data-field="CodGas"/>
      <DxColumn data-field="Estacion"/>
      <DxColumn data-field="RFC"/>
      <DxColumn data-field="Empresa"/>
      <DxColumn data-field="PermisoCRE"/>
      <DxColumn data-field="CodProducto"/>
      <DxColumn data-field="Producto"/>
      <DxColumn data-field="VtasDia"/>
      <DxColumn data-field="InvInicial"/>
      <DxColumn data-field="InvFinal"/>
      <DxColumn data-field="VolCompra"/>
      <DxColumn data-field="PrecioCompra"/>
      <DxColumn data-field="PrecioCompraDec"/>
      <DxColumn data-field="Flete"/>
      <DxColumn data-field="Merma"/>



      <!-- <DxColumn data-field="FirstName"/>
      <DxColumn data-field="LastName"/>
      <DxColumn data-field="City"/>
      <DxColumn data-field="State" />
      <DxColumn data-field="Position"/>
      <DxColumn data-field="BirthDate" data-type="date" :width="100"/>
      <DxColumn ata-field="HireDate" data-type="date" :width="100"/> -->
    </DxDataGrid>
















		<!-- bottom left vue-notification -->
		<notifications group="bottom-left" position="bottom left" :speed="500" />
	</div>
</template>

<script>

import axios from 'axios';
import moment from 'moment';
import { DxDataGrid, DxColumn, DxExport, DxSelection, DxGroupPanel, DxGrouping } from 'devextreme-vue/data-grid';
import { Workbook } from 'exceljs';
import { saveAs } from 'file-saver';
// Our demo infrastructure requires us to use 'file-saver-es'. We recommend that you use the official 'file-saver' package in your applications.
import { exportDataGrid } from 'devextreme/excel_exporter';
//import service from './data.js';

export default {
	
	components: {
		DxDataGrid, DxColumn, DxExport, DxSelection, DxGroupPanel, DxGrouping
	},
  methods: {
    
			show (group, type = '') {
				const text = `
					This is notification text!
					<br>
					Date: ${new Date()}
				`
				this.$notify({
					group,
					title: `Test ${type} notification #${this.id++}`,
					text,
					type,
					data: {
						randomNumber: Math.random()
					}
				})
			},
			clean (group) {
				this.$notify({ group, clean: true })
			},
			swalNotification(swalType) {
				var btnClass = (swalType == 'error') ? 'danger' : swalType;
					btnClass = (swalType == 'question') ? 'primary' : btnClass;
				this.$swal({
					title: 'Are you sure?',
					text: 'You will not be able to recover this imaginary file!',
					type: swalType,
					showCancelButton: true,
					buttonsStyling: false,
					confirmButtonText: swalType.charAt(0).toUpperCase() + swalType.substr(1).toLowerCase(),
					cancelButtonText: 'Cancel',
					confirmButtonClass: 'btn me-5px btn-'+ btnClass +'',
					cancelButtonClass: 'btn btn-default',
				})
			},
    onExporting(e) {
      const workbook = new Workbook();
      const worksheet = workbook.addWorksheet('ReporteCRE');

      exportDataGrid({
        component: e.component,
        worksheet: worksheet,
        autoFilterEnabled: true
      }).then(() => {
        workbook.xlsx.writeBuffer().then((buffer) => {
          saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'DataGrid.xlsx');
        });
      });
      e.cancel = true;
    },
    async search(e){
      e.preventDefault();
      this.textoBotonBuscar = "Buscando...";
      try{
        this.intDate = this.date.replace('/', '');
        this.intDate = this.intDate.replace('/', '');
        const response = await axios.post('CRE/GetReporteCRE?fecha='+this.intDate ,{
          fecha: this.intDate 
        });
        
        this.reporteCre = response.data.response;

      this.textoBotonBuscar = "Buscar";
      }catch(e){
        //this.alert = true;
        //this.error = 'Invalid email/password';
      }
    }
  },
	data() {
		return {
		reporteCre: [],
		textoBotonBuscar: "Buscar",
		date: moment().format("YYYY/MM/DD"),
		intDate: "",
		options: {
			format: 'YYYY/MM/DD',
			useCurrent: false,
		}
		};
	}
}
</script>