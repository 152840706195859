<template>
	<div>
		<!-- BEGIN breadcrumb -->
		<ol class="breadcrumb float-xl-end">
			<li class="breadcrumb-item"><a href="javascript:;">Operación</a></li>
			<li class="breadcrumb-item"><a href="javascript:;">Comisiones</a></li>
			<li class="breadcrumb-item active"><a href="javascript:;">Comisión Por Plaza</a></li>
		</ol>
		<!-- END breadcrumb -->
		<!-- BEGIN page-header -->
		<h1 class="page-header">Comisión Por Plaza<small></small></h1>
        <!-- <button class="btn" v-on:click="redirectTanques()">tanques</button> -->
		<!-- END page-header -->
		<!-- <a href="javascript:;" v-on:click="show('top-right', 'warn')" class="btn btn-sm btn-primary">Demo</a> -->
		
        <form @submit.prevent="search">  
    <div class="row">
      <div class="col-md-10">  
          <div class="form-group row">
            <!-- <div class="col-md-3">
                <label class="col-form-label">Fecha</label>
              <div class="input-group">
                <date-picker id="dateTimeInput" v-model="date" :config="options"></date-picker>
                <label for="dateTimeInput" class="input-group-text">
                  <i class="fa fa-calendar"></i>
                </label>
              </div>
            </div> -->
            <div class="col-md-2">
              <label class="col-form-label">Año</label>
                <select class="form-select" v-model="yearSelect">
                    <option v-for="year in years" :value="year.Year" v-bind:key="year.Year">
                        {{ year.Year }}
                    </option>
                </select>
            </div>
            <div class="col-md-2">
              <label class="col-form-label">Mes</label>
                <select class="form-select" v-model="mesSelect">
                    <option value="01">Enero</option>
                    <option value="02">Febrero</option>
                    <option value="03">Marzo</option>
                    <option value="04">Abril</option>
                    <option value="05">Mayo</option>
                    <option value="06">Junio</option>
                    <option value="07">Julio</option>
                    <option value="08">Agosto</option>
                    <option value="09">Septiembre</option>
                    <option value="10">Octubre</option>
                    <option value="11">Noviembre</option>
                    <option value="12">Diciembre</option>
                </select>
            </div>
      
            <div class="col-md-3">
                <!-- <label class="col-form-label">Ciudad</label>
                <select class="form-select"  v-model="ciudadSelect" :disabled="deshabilitarCombos">
                    <option v-for="ciudad in ciudades" :value="ciudad.Id" v-bind:key="ciudad.Id">
                        {{ ciudad.Nombre }}
                    </option>
                </select> -->
            </div>
            <!-- <div class="col-md-3">
                <label class="col-form-label">Estación</label>
                <select class="form-select" v-model="estacionSelect" :disabled="deshabilitarCombos">
                  <option value="0">Seleccione</option>
                    <option v-for="estacion in estaciones" :value="estacion.NoEstacion" v-bind:key="estacion.NoEstacion">
                    {{ estacion.Nombre }}
                    </option>
                  </select>
            </div>
              <div class="col-md-2">
                <label class="col-form-label">Turno</label>
                <select v-model="turnoSelected.IdTurno" class="form-control">
                    <option v-for="turno in turnos" :value="turno.IdTurno" v-bind:key="turno.IdTurno">
                    {{ turno.Descripcion }}
                    </option>
                </select>
            </div> -->
          </div>
      </div>
      
      <div class="col-md-2" style="padding-top: 2.65em;">
        <button type="submit" class="btn btn-success me-1 mb-1 pull-right">{{textoBotonBuscar}}</button>
      </div>



    </div>

        </form>

    <DxDataGrid
      id="gridContainer"
      :data-source="ReporteComisiones"
      key-expr="Empleado"
      :show-borders="true"
      @exporting="onExporting"
    >
    <DxFilterRow/>
      <DxHeaderFilter
        :visible="showHeaderFilter"
      />
      <DxSearchPanel
        :visible="true"
        :width="240"
        placeholder="Search..."
      />

      <DxGroupPanel :visible="false"/>
      <DxGrouping :auto-expand-all="true"/>
      <DxExport
        :enabled="true"
        :allow-export-selected-data="false"
      />
      <!-- <DxSelection mode="false"/> -->
        <DxColumn data-field="Empleado" caption="Empleado"/>
        <DxColumn data-field="Comision" caption="Comision" dataType="number">
          <DxFormat
              type="currency"
              :precision="2"
              />
        </DxColumn>

        
        <DxColumn data-field="Concepto" caption="Concepto"/>
        <DxColumn data-field="Nombre" caption="Nombre"/>
        <DxColumn data-field="Ciudad" caption="Ciudad"/>
        <DxColumn data-field="Puesto" caption="Puesto"/>
        
        <DxSummary>
            <DxGroupItem
                column="Comision"
                summary-type="sum"
                value-format="$#,##0.##"
                displayFormat='Total: {0}'
                
            />
            
        </DxSummary>
    </DxDataGrid>


		<!-- bottom left vue-notification -->
		<notifications group="top-right" position="top right" :speed="1000" />
	</div>
</template>

<script>

import axios from 'axios';
import moment from 'moment';
import { DxDataGrid, DxColumn, DxExport, DxGroupPanel, DxSummary, DxGroupItem, DxGrouping, DxHeaderFilter,DxSearchPanel, DxFormat, DxFilterRow} from 'devextreme-vue/data-grid';
import { Workbook } from 'exceljs';
import { saveAs } from 'file-saver';
// Our demo infrastructure requires us to use 'file-saver-es'. We recommend that you use the official 'file-saver' package in your applications.
import { exportDataGrid } from 'devextreme/excel_exporter';
//import service from './data.js';

export default {
	beforeCreate() {
    // var userSetting = localStorage.getItem('userSettings');
    // if(userSetting != undefined){
    //   console.log(JSON.parse(userSetting));
    //   this.pageUserSettings = JSON.parse(userSetting);
    // }else{
		// 	this.$router.push('/login');
    // }
	},
	created() {
		// console.log('created');
	},
	beforeMount() {
		// console.log('beforeMount');
	},
	mounted() {
		var userSetting = JSON.parse(localStorage.getItem('userSettings'));
		this.usuario = userSetting.Usuario;
		this.idUsuario = userSetting.NumeroEmpleado;
		this.nombreLogueado = userSetting.Nombre;
		this.usuarioEstacion = userSetting.Estacion;
		this.IdRol = userSetting.IdRol;
    this.getCiudades();
    this.ciudadSelect = userSetting.CiudadId;
    // this.getEstaciones();
    // this.estacionSelect = userSetting.Estacion;
    
    // this.GetTurnos();
    this.getYears();
    if(this.IdRol == 3 || this.IdRol == 7){
      this.deshabilitarCombos = true;
    }
	},
	components: {
		DxDataGrid, DxColumn, DxExport, DxGroupPanel, DxGrouping, DxSummary, DxGroupItem, 
  DxHeaderFilter,DxSearchPanel,DxFormat, DxFilterRow
	},
  methods: {
    async GetTurnos(){
      try {
        const response = await axios.get("Catalogos/GetTurnos");
        if (response.data.Success) {
          if (response.data.response.length == 0) {
            this.alertMessage =
              "No se encontraron turnos";
            this.alertTitle = "Error";
            this.show("top-right", "error");
          } else {
            this.turnos = response.data.response;
          }
        } else {
          this.alertMessage = response.data.Error;
          this.alertTitle = "Error";
          this.show("top-right", "error");
        }
      } catch (e) {
        this.alertMessage = "Ocurrio un error";
        this.alertTitle = "Error";
        this.show("top-right", "error");
      }
    },
  getYears(){
    var self = this;
    var currentYear = parseInt(moment().format("YYYY"));
    var minimumYear = 2023;
    self.years = [];
    
    for(var n = minimumYear ; n <= currentYear ; n++){
      var year = {"Year": n};
      self.years.push(year);
    }
		debugger; // eslint-disable-line no-debugger
  },
	async getCiudades(){
		try{
        const response = await axios.get('Catalogos/GetCiudades');
        
        if(response.data.Success){
          if(response.data.response.length == 0){
            this.alertMessage = "No se encontró información.";
            this.alertTitle = "Error";
            this.show('top-right', 'error');
          }
        }else{
            console.log("error")
            this.alertMessage = response.data.Error;
            this.alertTitle = "Error";
            this.show('top-right', 'error');
        }
        this.ciudades = response.data.response;
      }catch(e){
        //this.alert = true;
        //this.error = 'Invalid email/password';
      }
	},
	async getEstaciones(){
		try{
        const response = await axios.post('Estacion/GetEstaciones?ciudad='+this.ciudadSelect);
        if(response.data.Success){
          if(response.data.response.length == 0){
            this.alertMessage = "No se encontró información.";
            this.alertTitle = "Error";
            this.show('top-right', 'error');
          }
        }else{
            console.log("error")
            this.alertMessage = response.data.Error;
            this.alertTitle = "Error";
            this.show('top-right', 'error');
        }
        this.estaciones = response.data.response;
        this.productos = [];
        this.getProductos();
      }catch(e){
        //this.alert = true;
        //this.error = 'Invalid email/password';
      }
	},
			show (group, type = '') {
				const text = this.alertMessage;
				this.$notify({
					group,
					title: this.alertTitle,
					text,
					type,
					data: {
						randomNumber: Math.random()
					}
				})
			},
			clean (group) {
				this.$notify({ group, clean: true })
			},
			swalNotification(swalType) {
				var btnClass = (swalType == 'error') ? 'danger' : swalType;
					btnClass = (swalType == 'question') ? 'primary' : btnClass;
				this.$swal({
					title: 'Are you sure?',
					text: 'You will not be able to recover this imaginary file!',
					type: swalType,
					showCancelButton: true,
					buttonsStyling: false,
					confirmButtonText: swalType.charAt(0).toUpperCase() + swalType.substr(1).toLowerCase(),
					cancelButtonText: 'Cancel',
					confirmButtonClass: 'btn me-5px btn-'+ btnClass +'',
					cancelButtonClass: 'btn btn-default',
				})
			},
    onExporting(e) {
      const workbook = new Workbook();
      const worksheet = workbook.addWorksheet('ReporteComisiones');

      exportDataGrid({
        component: e.component,
        worksheet: worksheet,
        autoFilterEnabled: true
      }).then(() => {
        workbook.xlsx.writeBuffer().then((buffer) => {
          saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'Reporte Comisiones.xlsx');
        });
      });
      e.cancel = true;
    },
    async search(e){
      e.preventDefault();
      var self = this;
      self.textoBotonBuscar = "Buscando...";
      try{
        // this.intDate = this.date.replace('/', '');
        // this.intDate = this.intDate.replace('/', '');
        // var sendDate = self.date;
        // var ciudad = self.ciudadSelect;
        // if(estacion == "0"){
        //     this.alertMessage = "Seleccione estación";
        //     this.alertTitle = "Error";
        //     this.show('top-right', 'error');
        //   return;
        // }
        
		debugger; // eslint-disable-line no-debugger
        const response = await axios.post('Comision/GetComisionVentuk' ,{
                "Data": {
                    "Ejercicio": self.yearSelect,
                    "Periodo": self.mesSelect
                }
            }
        );
        
        if(response.data.Success){

          if(response.data.response.length == 0){
            this.alertMessage = "No se erncontro información del día especificado.";
            this.alertTitle = "Error";
            this.show('top-right', 'error');
            this.ReporteComisiones = [];
          }else{
            this.ReporteComisiones = response.data.response;
            for(var c = 0 ; c < this.ReporteComisiones.length ;c++){
              this.ReporteComisiones[c].FechaString = moment(this.ReporteComisiones[c].Fecha).format("YYYY/MM/DD");
              this.ReporteComisiones[c].ComisionString = parseFloat(this.ReporteComisiones[c].Comision).toFixed(2);
            }
          }
        }else{
            this.ReporteComisiones = [];
            console.log("error")
            this.alertMessage = response.data.Message;
            this.alertTitle = "Error";
            this.show('top-right', 'error');
        }

      this.textoBotonBuscar = "Buscar";
      }catch(e){
            this.ReporteComisiones = [];
            this.alertMessage = "Ocurrió un error";
            this.alertTitle = "Error";
            this.show('top-right', 'error');
        //this.alert = true;
        //this.error = 'Invalid email/password';
      }
    }
  },
	data() {
		return {
        turnos:[],
        turnoSelected:{
            IdTurno:1
        },
      years: [],
      yearSelect: parseInt(moment().format("YYYY")),
      mesSelect: moment().format("MM"),
      showHeaderFilter: true,
      loading:false,
      nombreLogueado:"",
      idUsuario:undefined,
      reportefajillas:[],
      deshabilitarCombos:false,
      ciudades: [],
      estaciones: [],
      ciudadSelect: 1,
      estacionSelect: 0,

      pageUserSettings: {},
      alertMessage: "",
      alertTitle: "",
      ReporteComisiones: [],
      textoBotonBuscar: "Buscar",
      date: moment().format("DD/MM/YYYY"),
      intDate: "",
      options: {
        format: 'DD/MM/YYYY',
        useCurrent: false,
      }
		};
	}
}
</script>
<style scoped>
.dx-datagrid-header-panel{
  display: none !important;
}
</style>