<template>
  <div>
    <div class="loading-search" v-if="loading">
      <div class="fa-3x text-center">
        <i class="fas fa-circle-notch fa-spin text-success" style=""></i>
      </div>
    </div>
    <!-- BEGIN breadcrumb -->
    <ol class="breadcrumb float-xl-end">
			<li class="breadcrumb-item"><a href="javascript:;">Operacion</a></li>
			<li class="breadcrumb-item"><a href="javascript:;">Cortes</a></li>
			<li class="breadcrumb-item active"><a href="javascript:;">Resumen</a></li>
    </ol>
    <!-- END breadcrumb -->
    <!-- BEGIN page-header -->
    <h1 class="page-header">Resumen Cortes</h1>
    <!-- END page-header -->

<!-- 
  agregar Numero de empleado en la pestaña
  agregar boton "Asignar", abrir modal con listado, nueva API
  con checkbox todos seleccionados
tercera nueva api con Numero de empleado, lista de despachos



  mostrar lista en combo desde Estacion/GetAsignacionBombas
{
	"Data": {
		"Fecha": "2022-11-01",
		"Turno": 11,
		"Estacion": 6287
	}
}




 -->

        <form @submit.prevent="search">   
	<div class="row">
      <div class="col-md-9"> 
          <div class="form-group row">
            
            <div class="col-md-3">
                <label class="col-form-label">Fecha inicial</label>
              <div class="input-group">
                <date-picker id="dateTimeInput" v-model="date" :config="options"></date-picker>
                <label for="dateTimeInput" class="input-group-text">
                  <i class="fa fa-calendar"></i>
                </label>
              </div>
            </div>

            
            <!-- <div class="col-md-3">
                <label class="col-form-label">Fecha final</label>
              <div class="input-group">
                <date-picker id="dateTimeInputEnd" v-model="dateEnd" :config="options"></date-picker>
                <label for="dateTimeInputEnd" class="input-group-text">
                  <i class="fa fa-calendar"></i>
                </label>
              </div>
            </div> -->
            <div class="col-md-3">
                <label class="col-form-label">Ciudad</label>
                <select class="form-select" @change="getEstaciones()" v-model="ciudadSelect" :disabled="deshabilitarCombos">
                    <option v-for="ciudad in ciudades" :value="ciudad.Id" v-bind:key="ciudad.Id">
                        {{ ciudad.Nombre }}
                    </option>
                </select>
            </div>
            <div class="col-md-3">
                <label class="col-form-label">Estación</label>
                <select class="form-select" v-model="estacionSelect" :disabled="deshabilitarCombos">
                  <option value="0">Todas</option>
                    <option v-for="estacion in estaciones" :value="estacion.NoEstacion" v-bind:key="estacion.NoEstacion">
                    {{ estacion.Nombre }}
                    </option>
                  </select>
            </div>
            <div class="col-md-3">
                <label class="col-form-label">Turno</label>
                <select v-model="turnoSelected.IdTurno" class="form-control">
                    <option v-for="turno in turnos" :value="turno.IdTurno" v-bind:key="turno.IdTurno">
                    {{ turno.Descripcion }}
                    </option>
                </select>
            </div>

           

            <!-- <div class="col-md-6">
              <button type="submit" class="btn btn-success me-1 mb-1">{{textoBotonBuscar}}</button>
            </div> -->
          </div>
      </div>
      <div class="col-md-3">
            <button type="submit" class="btn btn-success me-1 mb-1" style="margin-top: 3.1em;">{{textoBotonBuscar}}</button>
      </div>
    </div>

        </form>
    <panel title="Resumen Cortes" bodyClass="p-0" class="">

      <div class="panel-body-m">
        <div class="row">
          
            <div class="col-md-12">
              <div>
                <h2 style="text-align: right;" >Tipo de Cambio: {{TipoCambio | currency}}</h2>
              </div>
              <!-- <table class="table tableCorte bgGreen">
                <tr>
                  <td><b>Bomba</b></td>
                  <td><b>Transacciones</b></td>
                  <td><b>Cantidad</b></td>
                  <td><b>Importe</b></td>
                </tr>
                <tr  v-for="item in corteData.VentaBomba" v-bind:key="item.NumBomba">
                  <td>{{item.NumBomba}}</td>
                  <td>{{item.Transacciones}}</td>
                  <td>{{item.Cantidad}}</td>
                  <td>{{item.Importe | currency}}</td>
                </tr>
              </table> -->
            </div>
          <div class="col-md-12">

            <div class="col-md-12"  v-for="item in corteData.VentaBomba " v-bind:key="item.NumBomba" style="border: 2px solid lightgray; margin-bottom: 40px; background-color: #f0f0f0; padding: 12px; border-radius: 5px;" >
              <h2>Bomba: {{item.NumBomba}}</h2>
              <table style="width: 100%;" class="ventaBombaTabla">
                <!-- <tr>
                  <td colspan="4"></td>
                </tr> -->
                <tr>
                  <td></td>
                  <td><b>Transacciones</b></td>
                  <td><b>QTY/LTR</b></td>
                  <td><b>Importe</b></td>
                </tr>
                <tr>
                  <td>Combustible</td>
                  <td>{{item.Transacciones}}</td>
                  <td>{{item.Litros | formatNumber}}</td>
                  <td>{{item.ImporteCombustible | currency}}</td>
                </tr>
                <tr>
                  <td>Productos</td>
                  <td>0</td>
                  <td>{{item.Productos}}</td>
                  <td>{{item.ImporteProductos | currency}}</td>
                </tr>
                <tr>
                  <td>Autojarreo</td>
                  <td>{{item.Jarreos}}</td>
                  <td>{{item.LitrosJarreos | formatNumber}}</td>
                  <td>{{item.ImporteJarreos | currency}}</td>
                </tr>
                <tr>
                  <td>Monederos</td>
                  <td>{{item.Monederos}}</td>
                  <td>{{item.LitrosMonederos | formatNumber}}</td>
                  <td>{{item.ImporteMonederos | currency}}</td>
                </tr>
                <tr>
                  <td>Fajillas MXN</td>
                  <td></td>
                  <td>{{item.FajillasMXN}}</td>
                  <td>{{item.ImporteFajillasMXN | currency}}</td>
                </tr>
                <tr>
                  <td>Fajillas USD</td>
                  <td></td>
                  <td>{{item.FajillasUSD}}</td>
                  <td>{{item.ImporteFajillasUSD | currency}}</td>
                </tr>
                <tr>
                  <td>Tarjeta de credito</td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
                <tr>
                  <td>Tarjeta debito</td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
              </table>

              <div class="col-xl-12">
                <div style="text-align: right;">
                  <button class="btn btn-sm btn-success" v-b-modal.modalDialog @click="abrirModal(item.NumBomba)">Asignar</button>
                </div>
                <!-- BEGIN nav-tabs -->
                <b-tabs nav-wrapper-class="nav nav-tabs" content-class="p-15px bg-white">
                  <b-tab active v-for="ventaEmpleado in item.VentaEmpleado" v-bind:key="ventaEmpleado.NoEmpleado">
                    <template slot="title">
                      <span class="d-sm-none">  
                        {{ventaEmpleado.NoEmpleado}}
                        
                      </span>
                      <span class="d-sm-block d-none">
                        <span v-if="ventaEmpleado.Nombre">{{ventaEmpleado.Nombre}} - {{ventaEmpleado.NoEmpleado}}</span>
                        <span v-else>Sin Asignar</span>
                        
                      </span>
                    </template>
                    
                    <!-- <h3 class="mt-10px"><i class="fa fa-cog"></i> Lorem ipsum dolor sit amet</h3> -->
                    
                    <div class="row">
                      <div class="col-md-12">
                              <h5>Empleado: 
                                <span v-if="ventaEmpleado.Nombre">{{ventaEmpleado.Nombre}} - {{ventaEmpleado.NoEmpleado}}</span>
                                <span v-else>Sin Asignar</span>
                              </h5> 
                        <table style="width: 100%;" class="ventaEmpleadoTabla">
                          
                          <tr>
                            <td></td>
                            <td><b>Transacciones</b></td>
                            <td><b>QTY/LTR</b></td>
                            <td class="textRight"><b>Importe</b></td>
                            <td class="textRight"><b>Efectivo por entregar</b> </td>
                          </tr>
                          <tr>
                            <td>Combustible</td>
                            <td>{{ventaEmpleado.Transacciones}}</td>
                            <td>{{ventaEmpleado.Litros}}</td>
                            <td class="textRight"><b>{{ventaEmpleado.ImporteCombustible | currency}}</b></td>
                            <td class="textRight">
                              <h5>
                                {{(ventaEmpleado.ImporteCombustible + ventaEmpleado.ImporteProductos - ventaEmpleado.ImporteMonederos -ventaEmpleado.ImporteFajillasMXN - (ventaEmpleado.ImporteFajillasUSD * TipoCambio) - ventaEmpleado.ImporteTCredito - ventaEmpleado.ImporteTDebito) | currency}}
                              </h5>
                              
                              <!-- <p>{{ventaEmpleado.ImporteCombustible}}</p>
                              <p>{{ventaEmpleado.ImporteProductos}}</p>
                              <p>{{ventaEmpleado.ImporteJarreos}}</p>
                              <p>{{ventaEmpleado.ImporteMonederos}}</p>
                              <p>{{ventaEmpleado.ImporteFajillasMXN}}</p>
                              <p>{{ventaEmpleado.ImporteFajillasUSD * TipoCambio}}</p>
                              <p>{{ventaEmpleado.ImporteTCredito}}</p>
                              <p>{{ventaEmpleado.ImporteTDebito}}</p> -->
                              
                              
                            </td>
                          </tr>
                          <tr>
                            <td>Productos</td>
                            <td>0</td>
                            <td>{{ventaEmpleado.Productos}}</td>
                            <td class="textRight">{{ventaEmpleado.ImporteProductos | currency}}</td>
                            <td></td>
                          </tr>
                          <tr>
                            <td>Autojarreo</td>
                            <td>{{ventaEmpleado.Jarreos}}</td>
                            <td>{{ventaEmpleado.LitrosJarreos | formatNumber}}</td>
                            <td class="textRight">{{ventaEmpleado.ImporteJarreos | currency}}</td>
                            <td></td>
                          </tr>
                          <tr>
                            <td>Monederos</td>
                            <td>{{ventaEmpleado.Monederos}}</td>
                            <td>{{ventaEmpleado.LitrosMonederos | formatNumber}}</td>
                            <td class="textRight">{{ventaEmpleado.ImporteMonederos | currency}}</td>
                            <td></td>
                          </tr>
                          <tr>
                            <td>Fajillas MXN</td>
                            <td></td>
                            <td>{{ventaEmpleado.FajillasMXN}}</td>
                            <td class="textRight">{{ventaEmpleado.ImporteFajillasMXN | currency}}</td>
                            <td></td>
                          </tr>
                          <tr>
                            <td>Fajillas USD</td>
                            <td></td>
                            <td>{{ventaEmpleado.FajillasUSD}}</td>
                            <td class="textRight">{{ventaEmpleado.ImporteFajillasUSD * TipoCambio | currency}} ({{ventaEmpleado.ImporteFajillasUSD | currency}} USD)</td>
                            <td></td>
                          </tr>
                          <tr>
                            <td>Tarjeta de credito</td>
                            <td></td>
                            <td>{{ventaEmpleado.CantTCredito}}</td>
                            <td class="textRight">{{ventaEmpleado.ImporteTCredito | currency}}</td>
                            <td></td>
                          </tr>
                          <tr>
                            <td>Tarjeta debito</td>
                            <td></td>
                            <td>{{ventaEmpleado.CantTDebito}}</td>
                            <td class="textRight">{{ventaEmpleado.ImporteTDebito | currency}}</td>
                            <td></td>
                          </tr>
                        </table>
                      </div>
                    </div>
                    <!-- <p class="text-end mb-0">
                      <a href="javascript:;" class="btn btn-white me-5px">Default</a>
                      <a href="javascript:;" class="btn btn-primary">Primary</a>
                    </p> -->
                  </b-tab>
                  
                </b-tabs>
                
                
                

              </div>
            </div>

          </div>
        </div>
      </div>
      
    </panel>
	

    <!-- #modal-dialog -->
    <b-modal id="modalDialog" size="xl">
      <template slot="modal-header">
        <h4 class="modal-title">Asignar</h4>
        <a class="btn-close" @click="$bvModal.hide('modalDialog')"></a>
      </template>

      <div>
        <form action="/" method="POST">
          <fieldset>


            <div class="col-md-3">
                <label class="col-form-label">Vendedor</label>
                <select class="form-select" v-model="vendedorSeleccionado">
                    <option v-for="vendedor in vendedores" :value="vendedor.NumeroEmpleado" v-bind:key="vendedor.NumeroEmpleado">
                    {{ vendedor.Nombre }}
                    </option>
                  </select>
            </div>
            <div class="table-responsive">
              <table class="table mb-0">
                <thead>
                  <tr>
                    <th>Bomba</th>
                    <th>Cantidad</th>
                    <th>Descripcion</th>
                    <th>Gasolinera</th>
                    <th>Hora</th>
                    <th>Precio</th>
                    <th>Producto</th>
                    <th>Tipo</th>
                    <th>Total</th>
                    <th>Transaccion</th>
                    <th>Turno</th>
                    <th>
                      <div class="form-check form-switch mb-2">
                        Todos
                        <input
                          class="form-check-input"
                          type="checkbox"
                          id="flexSwitchCheckChecked"
                          @click="seleccionarTodos(seleccionarTodosValue)"
                          v-model="seleccionarTodosValue"
                        />
                    </div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(transaccion, index) in datosModal" :key="transaccion.Transaccion">
                    <td>{{transaccion.Bomba}}</td>
                    <td>{{transaccion.Cantidad}}</td>
                    <td>{{transaccion.Descripcion}}</td>
                    <td>{{transaccion.Gasolinera}}</td>
                    <td>{{transaccion.Hora}}</td>
                    <td>{{transaccion.Precio}}</td>
                    <td>{{transaccion.Producto}}</td>
                    <td>{{transaccion.Tipo}}</td>
                    <td>{{transaccion.Total}}</td>
                    <td>{{transaccion.Transaccion}}</td>
                    <td>{{transaccion.Turno}}</td>
                    <td>
                      <div class="form-check form-switch mb-2">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          id="flexSwitchCheckChecked"
                          @click="seleccionarTransaccion(index, transaccion.Transaccion, transaccion.Seleccionado)"
                          v-model="transaccion.Seleccionado"
                        />
                    </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </fieldset>
        </form>
      </div>

      <template slot="modal-footer">
        <button
          type="button"
          class="btn btn-primary mr-auto"
          @click="guardarTransacciones()"
        >
          {{ textoBotonGuardar }}
        </button>
        <button
          type="button"
          class="btn btn-secondary"
          @click="$bvModal.hide('modalDialog')"
        >
          Cancelar
        </button>
      </template>
    </b-modal>




	<!-- bottom left vue-notification -->
	<notifications group="top-right" position="top right" :speed="1000" />
  </div>
</template>

<script>

import axios from "axios";
// import { json } from "body-parser";
import moment from 'moment';
var numeral = require("numeral");
import Vue from "vue";
Vue.filter("formatNumber", function (value) {
	return numeral(value).format("0,00.00"); // displaying other groupings/separators is possible, look at the docs
});

Vue.filter("currency", function (value) {
	return numeral(value).format("$0,0.00"); 
});
export default {
async	created() {
  
	},
  
  mounted: function () {
    debugger; // eslint-disable-line no-debugger
		var userSetting = JSON.parse(localStorage.getItem('userSettings'));
		this.usuario = userSetting.Usuario;
		this.idUsuario = userSetting.NumeroEmpleado;
		this.nombreLogueado = userSetting.Nombre;
		this.usuarioEstacion = userSetting.Estacion;
		this.IdRol = userSetting.IdRol;
    this.getCiudades();
    this.ciudadSelect = userSetting.CiudadId;
    this.getEstaciones();
    this.estacionSelect = userSetting.Estacion;

    
    this.GetTurnos();

    if(this.IdRol == 3 || this.IdRol == 7){
      this.deshabilitarCombos = true;
    }

    

  },
  methods: {
    seleccionarTodos(todos){
      debugger; // eslint-disable-line no-debugger
      var self = this;
      for(var n = 0; n < self.datosModal.length; n++){
        self.datosModal[n].Seleccionado = !todos;
      }

    },
    async getVendedores(){
      
        var self = this;
        this.textoBotonBuscar = "Buscando...";
        var dateFormat = moment(self.date).format("YYYY-MM-DD");
        var dataToSend = {
            "Data": {
              "Fecha": dateFormat,
              "Turno": self.turnoSelected.IdTurno,
              "Estacion": self.estacionSelect,
          }
        };
              
        try {
          const response = await axios.post("Estacion/GetAsignacionBombas", dataToSend);
          
          if (response.data.Success) {
            if (response.data.response.length == 0) {
              this.alertMessage =
                "No se encontró información de vendedores";
              this.alertTitle = "Error";
              this.show("top-right", "error");
            this.vendedores = [];
              self.vendedorSeleccionado = 0;
            } else {
              this.vendedores = response.data.response;
              self.vendedorSeleccionado = response.data.response[0].NumeroEmpleado;
            }
          } else {
            this.alertMessage = response.data.Error;
            this.alertTitle = "Error";
            this.show("top-right", "error");
            this.vendedores = [];
              self.vendedorSeleccionado = 0;
          }
          this.textoBotonBuscar = "Buscar";
        } catch (e) {
            this.alertMessage = "Ocurrio un error";
            this.alertTitle = "Error";
            this.show("top-right", "error");
            this.vendedores = [];
              self.vendedorSeleccionado = 0;
        }

      },
    async guardarTransacciones() {
      debugger; // eslint-disable-line no-debugger
      var self = this;
      var listaTransacciones = [];
      var gasolinera = 0;

      for(var n = 0; n < self.datosModal.length; n++){
        gasolinera = self.datosModal[n].Gasolinera;
        if(self.datosModal[n].Seleccionado){
          listaTransacciones.push(self.datosModal[n].Transaccion);
        }
      }

      debugger; // eslint-disable-line no-debugger
      if(listaTransacciones.length==0){
            this.alertMessage = "Seleccione al menos una transacción";
            this.alertTitle = "Error";
            this.show("top-right", "error");
        return;
      }

      debugger; // eslint-disable-line no-debugger
      var dataToSend = {
        "Data": {
      "Estacion": self.estacionSelect,
          "Gasolinera": gasolinera,		
          "NoEmpleado": self.vendedorSeleccionado,
          "Transacciones": listaTransacciones
          }
        }
        
      debugger; // eslint-disable-line no-debugger
        this.textoBotonGuardar = "Guardando...";
        try {
          
          const response = await axios.post("Despacho/SetDespachoEmpleado", dataToSend);
      debugger; // eslint-disable-line no-debugger
          // console.log("response guardar" + JSON.stringify(response) );
          if (response.data.Success) {
            this.alertMessage = response.data.Message;
            this.alertTitle = "Guardado con exito";
            this.show("top-right", "success");
            self.GetTipoCambio();
            this.$bvModal.hide("modalDialog");
          } else {
            this.alertMessage = response.data.Error;
            this.alertTitle = "Error";
            this.show("top-right", "error");
          }
          this.textoBotonGuardar = "Guardar";
        } catch (e) {
      debugger; // eslint-disable-line no-debugger
          this.alertMessage = "Ocurrio un error";
          this.alertTitle = "Error";
          this.show("top-right", "error");
        }
    },
    seleccionarTransaccion(index, transaccion, seleccionado){
      var self = this;
      self.datosModal[index].Seleccionado = !seleccionado;
    },
    async abrirModal(NumBomba) {

      debugger; // eslint-disable-line no-debugger
      var self = this;
      self.seleccionarTodosValue = true;

      self.getVendedores();
      var dataToSend = {
        "Data": {
            "Estacion": self.estacionSelect,
            "Fecha": self.intDate,
            "Bomba": NumBomba,
            "Turno": self.turnoSelected.IdTurno
          }
        };
        try {
          const response = await axios.post("Despacho/GetDespachosSinMarcar", dataToSend);
              debugger; // eslint-disable-line no-debugger
          if (response.data.Success) {
            if (response.data.response == null) {
              self.alertMessage =
                "No se encontró información del cliente especificado.";
              this.alertTitle = "Error";
              this.show("top-right", "error");
            } else {
              
              debugger; // eslint-disable-line no-debugger
              self.datosModal = response.data.response;
              for(var n=0;n<self.datosModal.length;n++){
                self.datosModal[n].Seleccionado = true;
              }
            }
          } else {
            this.alertMessage = response.data.Error;
            this.alertTitle = "Error";
            this.show("top-right", "error");
          }
        } catch (e) {
      debugger; // eslint-disable-line no-debugger
          this.alertMessage = "Ocurrio un error";
          this.alertTitle = "Error";
          this.show("top-right", "error");
        }
    },
    async GetTipoCambio(){
      var self = this;
      try {
        
        // const response = await axios.post('Ventas/GetVentasEstacion' ,{
        // "Data": {
        //     "FechaInicial": self.intDate,
        //     "FechaFinal":self.intDate,
        //     "Turno": turno, 
        //     "Estacion": estacion       
        //     }
        // });

        self.intDate = self.date.replace('/', '');
        self.intDate = self.intDate.replace('/', '');


        var estacion = self.estacionSelect!=undefined?self.estacionSelect:0;

        const response = await axios.post("Catalogos/GetTipoCambio" ,{
        "Data": {
            "Fecha": self.intDate,
            "Estacion": estacion       
            }
        });
        if (response.data.Success) {
          if (response.data.response.length == 0) {
            this.alertMessage =
              "No se encontraron turnos";
            this.alertTitle = "Error";
            this.show("top-right", "error");
          } else {
            self.TipoCambio = response.data.response.Valor;
            setTimeout(function () {
              self.getData(self.intDate, estacion);
          }, 0);
          }
        } else {
          this.alertMessage = response.data.Error;
          this.alertTitle = "Error";
          this.show("top-right", "error");
        }
      } catch (e) {
        this.alertMessage = "Ocurrio un error";
        this.alertTitle = "Error";
        this.show("top-right", "error");
      }
    },
    async GetTurnos(){
      try {
        const response = await axios.get("Catalogos/GetTurnos");
        if (response.data.Success) {
          if (response.data.response.length == 0) {
            this.alertMessage =
              "No se encontraron turnos";
            this.alertTitle = "Error";
            this.show("top-right", "error");
          } else {
            this.turnos = response.data.response;
          }
        } else {
          this.alertMessage = response.data.Error;
          this.alertTitle = "Error";
          this.show("top-right", "error");
        }
      } catch (e) {
        this.alertMessage = "Ocurrio un error";
        this.alertTitle = "Error";
        this.show("top-right", "error");
      }
    },
	async getCiudades(){
		try{
        const response = await axios.get('Catalogos/GetCiudades');
		//debugger; // eslint-disable-line no-debugger
        if(response.data.Success){
          if(response.data.response.length == 0){
            this.alertMessage = "No se encontró información.";
            this.alertTitle = "Error";
            this.show('top-right', 'error');
          }
        }else{
            console.log("error")
            this.alertMessage = response.data.Error;
            this.alertTitle = "Error";
            this.show('top-right', 'error');
        }
        this.ciudades = response.data.response;
      }catch(e){
        //this.alert = true;
        //this.error = 'Invalid email/password';
      }
	},
	async getEstaciones(){
		try{
		debugger; // eslint-disable-line no-debugger
        const response = await axios.post('Estacion/GetEstaciones?ciudad='+this.ciudadSelect);
        if(response.data.Success){
          if(response.data.response.length == 0){
            this.alertMessage = "No se encontró información.";
            this.alertTitle = "Error";
            this.show('top-right', 'error');
          }
        }else{
            console.log("error")
            this.alertMessage = response.data.Error;
            this.alertTitle = "Error";
            this.show('top-right', 'error');
        }
        this.estaciones = response.data.response;
        this.productos = [];
        this.getProductos();
      }catch(e){
        //this.alert = true;
        //this.error = 'Invalid email/password';
      }
	},
			show (group, type = '') {
				const text = this.alertMessage;
				this.$notify({
					group,
					title: this.alertTitle,
					text,
					type,
					data: {
						randomNumber: Math.random()
					}
				})
			},
			clean (group) {
				this.$notify({ group, clean: true })
			},
			swalNotification(swalType) {
				var btnClass = (swalType == 'error') ? 'danger' : swalType;
					btnClass = (swalType == 'question') ? 'primary' : btnClass;
				this.$swal({
					title: 'Are you sure?',
					text: 'You will not be able to recover this imaginary file!',
					type: swalType,
					showCancelButton: true,
					buttonsStyling: false,
					confirmButtonText: swalType.charAt(0).toUpperCase() + swalType.substr(1).toLowerCase(),
					cancelButtonText: 'Cancel',
					confirmButtonClass: 'btn me-5px btn-'+ btnClass +'',
					cancelButtonClass: 'btn btn-default',
				})
			},
      async getData(fecha, estacion){
        
        debugger; // eslint-disable-line no-debugger
        var self = this;
      self.loading = true;
      self.textoBotonBuscar = "Buscando...";
      try{
        // self.intDate = self.date.replace('/', '');
        // self.intDate = self.intDate.replace('/', '');




        var turno = self.turnoSelected.IdTurno;
        for(var n=0; n<self.turnos.length; n++){
          if(turno == self.turnos[n].IdTurno){
            self.turnoText = self.turnos[n].Descripcion;
          }
        } 
        // var ciudad = self.ciudadSelect!=undefined?self.ciudadSelect:0;
        //var estacion = self.estacionSelect!=undefined?self.estacionSelect:0;

        debugger; // eslint-disable-line no-debugger
        const response = await axios.post('Ventas/GetVentasEstacion' ,{
        "Data": {
            "FechaInicial": fecha,
            // "FechaFinal": fecha,
            "Turno": turno, 
            "Estacion": estacion       
            }
        });
        
        if(response.data.Success){
        debugger; // eslint-disable-line no-debugger
          self.corteData = response.data.response;
          
          //self.sumarImportes();
          
          // $(".nav-tabs ul li:nth-child(2)").addClass("active");
            
        }else{
            console.log("error")
            self.alertMessage = response.data.Error;
            self.alertTitle = "Error";
            self.show('top-right', 'error');
        }
        

      self.textoBotonBuscar = "Buscar";
      
      self.loading = false;
      }catch(e){
      self.loading = false;
      self.corteData = [];
        //this.alert = true;
        //this.error = 'Invalid email/password';
      }

      },
    async search(e) {
      e.preventDefault();
      // this.getData();
      this.GetTipoCambio();
    },
  },
  data() {
    return {
      seleccionarTodosValue: true,
      vendedorSeleccionado:0,
      vendedores: [],
      datosModal:[],
      turnoText: "1",
      TipoCambio: 0,
      turnos:[],
      turnoSelected:{
        IdTurno:11
      },
      loading:false,
      nombreLogueado:"",
      idUsuario:undefined,
      reportefajillas:[],
      deshabilitarCombos:false,
      ciudades: [],
      estaciones: [],
      ciudadSelect: 1,
      estacionSelect: 0,
      ventaDetalle:[],
      emailReenviar:"",
      uuidReenviar:"",
      date: moment().format("YYYY/MM/DD"),
      intDateEnd: "",
      dateEnd: moment().format("YYYY/MM/DD"),
      intDate: "",
      options: {
        format: 'YYYY/MM/DD',
        useCurrent: false,
      },
      puestoSelected:{
          puesto:0
      },
      rolSelected:{
          rol:0
      },
      actualizarNIP:false,
      puestosSelect:[],
      rolesSelect:[],
      nuevoCorreo: '',
      clienteNuevo: true,
      clienteModal:{},
      textoBotonGuardar: "Guardar",
      textoBotonBuscar: "Buscar",
      clienteBuscar: "",
      items: [],
      corteData: [],
      corteDataTest: {
    "Estacion": 6287,
    "VentaBomba": [
      {
        "NumBomba": 1,
        "Transacciones": 153,
        "Cantidad": 2716.15,
        "Importe": 57373.18
      },
      {
        "NumBomba": 2,
        "Transacciones": 126,
        "Cantidad": 2413.88,
        "Importe": 50754.64
      },
      {
        "NumBomba": 3,
        "Transacciones": 159,
        "Cantidad": 3377.97,
        "Importe": 71289.99
      },
      {
        "NumBomba": 4,
        "Transacciones": 142,
        "Cantidad": 2835.43,
        "Importe": 59118.99
      }
    ],
    "VentaEmpleado": [
      {
        "NoEmpleado": 0,
        "Nombre": "",
        "VentasCG": [
          {
            "NumBomba": 1,
            "IdVendedor": 0,
            "Vendedor": "",
            "TipoPago": "Efectivo",
            "Cantidad": 514.77,
            "Importe": 11189.86,
            "Transacciones": 29,
            "Producto": "Combustible"
          },
          {
            "NumBomba": 2,
            "IdVendedor": 0,
            "Vendedor": "",
            "TipoPago": "Efectivo",
            "Cantidad": 730.6,
            "Importe": 15367.48,
            "Transacciones": 45,
            "Producto": "Combustible"
          },
          {
            "NumBomba": 3,
            "IdVendedor": 0,
            "Vendedor": "",
            "TipoPago": "Efectivo",
            "Cantidad": 759.67,
            "Importe": 16275.31,
            "Transacciones": 28,
            "Producto": "Combustible"
          },
          {
            "NumBomba": 4,
            "IdVendedor": 0,
            "Vendedor": "",
            "TipoPago": "Efectivo",
            "Cantidad": 85.25,
            "Importe": 1763.84,
            "Transacciones": 5,
            "Producto": "Combustible"
          }
        ],
        "VentasGetnet": null,
        "Fajillas": [
          {
            "Moneda": "MXN",
            "Importe": 0,
            "Cantidad": 0
          },
          {
            "Moneda": "USD",
            "Importe": 0,
            "Cantidad": 0
          }
        ]
      },
      {
        "NoEmpleado": 1341,
        "Nombre": "MARIA SUSANA GONZALEZ DELGADO",
        "VentasCG": [
          {
            "NumBomba": 3,
            "IdVendedor": 1341,
            "Vendedor": "MARIA SUSANA GONZALEZ DELGADO",
            "TipoPago": "Efectivo",
            "Cantidad": 2251.42,
            "Importe": 47311.91,
            "Transacciones": 117,
            "Producto": "Combustible"
          },
          {
            "NumBomba": 3,
            "IdVendedor": 1341,
            "Vendedor": "MARIA SUSANA GONZALEZ DELGADO",
            "TipoPago": "TarjetaCredito",
            "Cantidad": 366.88,
            "Importe": 7702.77,
            "Transacciones": 14,
            "Producto": "Combustible"
          }
        ],
        "VentasGetnet": null,
        "Fajillas": [
          {
            "Moneda": "MXN",
            "Importe": 23,
            "Cantidad": 23
          },
          {
            "Moneda": "USD",
            "Importe": 4,
            "Cantidad": 4
          }
        ]
      },
      {
        "NoEmpleado": 25597,
        "Nombre": "DIANA CASTILLO",
        "VentasCG": [
          {
            "NumBomba": 4,
            "IdVendedor": 25597,
            "Vendedor": "DIANA CASTILLO",
            "TipoPago": "Efectivo",
            "Cantidad": 2349.23,
            "Importe": 48943.92,
            "Transacciones": 120,
            "Producto": "Combustible"
          },
          {
            "NumBomba": 4,
            "IdVendedor": 25597,
            "Vendedor": "DIANA CASTILLO",
            "TipoPago": "TarjetaCredito",
            "Cantidad": 400.95,
            "Importe": 8411.23,
            "Transacciones": 17,
            "Producto": "Combustible"
          }
        ],
        "VentasGetnet": null,
        "Fajillas": [
          {
            "Moneda": "MXN",
            "Importe": 36000,
            "Cantidad": 18
          },
          {
            "Moneda": "USD",
            "Importe": 120,
            "Cantidad": 2
          }
        ]
      },
      {
        "NoEmpleado": 31029,
        "Nombre": "MARY CRUZ HERNANDEZ VASCONCELOS",
        "VentasCG": [
          {
            "NumBomba": 1,
            "IdVendedor": 31029,
            "Vendedor": "MARY CRUZ HERNANDEZ VASCONCELOS",
            "TipoPago": "Efectivo",
            "Cantidad": 2201.38,
            "Importe": 46183.32,
            "Transacciones": 124,
            "Producto": "Combustible"
          }
        ],
        "VentasGetnet": null,
        "Fajillas": [
          {
            "Moneda": "MXN",
            "Importe": 36000,
            "Cantidad": 18
          },
          {
            "Moneda": "USD",
            "Importe": 180,
            "Cantidad": 3
          }
        ]
      },
      {
        "NoEmpleado": 31407,
        "Nombre": "JOSEFINA ROMERO SALGADO",
        "VentasCG": [
          {
            "NumBomba": 2,
            "IdVendedor": 31407,
            "Vendedor": "JOSEFINA ROMERO SALGADO",
            "TipoPago": "Efectivo",
            "Cantidad": 1420.33,
            "Importe": 29839.6,
            "Transacciones": 71,
            "Producto": "Combustible"
          },
          {
            "NumBomba": 2,
            "IdVendedor": 31407,
            "Vendedor": "JOSEFINA ROMERO SALGADO",
            "TipoPago": "TarjetaCredito",
            "Cantidad": 262.94,
            "Importe": 5547.56,
            "Transacciones": 10,
            "Producto": "Combustible"
          },
          {
            "NumBomba": 2,
            "IdVendedor": 31407,
            "Vendedor": "JOSEFINA ROMERO SALGADO",
            "TipoPago": "Efectivo",
            "Cantidad": 10,
            "Importe": 350,
            "Transacciones": 6,
            "Producto": "Productos"
          },
          {
            "NumBomba": 2,
            "IdVendedor": 31407,
            "Vendedor": "JOSEFINA ROMERO SALGADO",
            "TipoPago": "TarjetaCredito",
            "Cantidad": 200,
            "Importe": 150,
            "Transacciones": 4,
            "Producto": "Productos"
          }
        ],
        "VentasGetnet": null,
        "Fajillas": [
          {
            "Moneda": "MXN",
            "Importe": 28000,
            "Cantidad": 14
          },
          {
            "Moneda": "USD",
            "Importe": 180,
            "Cantidad": 3
          }
        ]
      }
    ]
  }
      
    };
  },
};
</script>
<style scoped>
.loading-search{
  position: absolute;
    width: 100%;
    left: 0px;
    height: 100%;
    background: rgba(0, 0, 0, 0.1);
    z-index: 1000;
    padding-top: 150px;
    top: 0;
}
.panel-body-m{
        margin: 1em !important;
}
.bgYellow{
  background: yellow;
}
.bgGreen{
  background: yellowgreen;
  border-bottom: 1px solid yellowgreen !important;
}
.bgPink{
  background: lightcoral;
}
.fontBlue{
  color: lightskyblue;
}



.tableCorte tr td{
  border-bottom: 1px solid #eee;
  padding: 2px 5px;
}

.tableCorte tr td:nth-child(3),
.tableCorte tr td:nth-child(4),
.tableCorte tr td:nth-child(6), 
.tableCorte tr td:nth-child(7){
  
  text-align: right;
}
.textCenter{
  text-align: center !important;
}

.textRight{
  text-align: right !important;
}

/* .ventaEmpleadoTabla table table td:nth-child(2) */
.ventaEmpleadoTabla tr td:nth-child(2),
.ventaEmpleadoTabla tr td:nth-child(3),
.ventaEmpleadoTabla tr td:nth-child(4),
.ventaEmpleadoTabla tr td:nth-child(5)
{

  text-align: right !important;
}


.ventaEmpleadoTabla tr:nth-child(odd){
  background-color: #f7f7f7;
  /* color: #fff; */
}


.ventaBombaTabla tr td:nth-child(2),
.ventaBombaTabla tr td:nth-child(3),
.ventaBombaTabla tr td:nth-child(4)
{

  text-align: right !important;
}

.ventaBombaTabla tr:nth-child(odd){
  background-color: #e8e8e8;
  /* color: #fff; */
}

body{
  background-color: #aaa !important;
}
</style>