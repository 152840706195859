<template>
	<div>
		<!-- BEGIN breadcrumb -->
		<ol class="breadcrumb float-xl-end">
			<li class="breadcrumb-item"><a href="javascript:;">Inicio</a></li>
			<li class="breadcrumb-item active"><a href="javascript:;">Fajillas Dashboard</a></li>
		</ol>
		<!-- END breadcrumb -->
		<!-- BEGIN page-header -->
		<h1 class="page-header">Fajillas Dashboard</h1>
		<!-- END page-header -->
		<!-- <a href="javascript:;" v-on:click="show('bottom-left', 'warn')" class="btn btn-sm btn-primary">Demo</a> -->
		<div class="row mb-15px">
			<div class="col-md-4">
				<label class="form-label col-form-label col-md-3">Ciudad</label>
				<div class="col-md-9">
                    <select class="form-select" @change="getLecturas" v-model="ciudadSelect">
                        <option value="0">Todas</option>
                        <option v-for="ciudad in ciudades" :value="ciudad.Id" v-bind:key="ciudad.Id">
                        {{ ciudad.Nombre }}
                        </option>
                    </select>
				</div>
			</div>
			
		</div>
		
		<div class="row">

            <div class="col-xs-12 col-sm-12 col-md-4 col-xl-4" v-for="estacion in estaciones" :key="estacion.Estacion">

				<h3><b>{{ estacion.Estacion }}</b></h3>
				<h3>{{estacion.NoEstacion}}</h3>

                    <div class="col-12">
                        <div class="widget widget-stats bg-white">
                            
                            <div class="stats-content">
                                <div class="stats-title">
                                    <div class="row">
                                        <div class="col-6" v-for="(moneda, index) in estacion.Moneda" :key="moneda.Moneda" v-bind:class="{'border-moneda':index >0}">
                                            <div class="row">

                                                <div class="col-md-12"><h3><b>{{moneda.Moneda}}</b></h3></div>
                                                
                                                
                                                <div class="col-md-9"><h4 class="font-red">> 45 min</h4></div>
                                                <div class="col-md-3"><h4 class="font-red">{{moneda.Rojo}}</h4></div>

                                                <div class="col-md-9"><h4 class="font-orange">>30 min</h4></div>
                                                <div class="col-md-3"><h4 class="font-orange">{{moneda.Amarillo}}</h4></div>

                                                <div class="col-md-9"><h4 class="font-green">{{less}} 30 min</h4></div>
                                                <div class="col-md-3"><h4 class="font-green">{{moneda.Verde}}</h4></div>



                                            </div>
                                        </div>

                                        
                                    </div>
                                    
                                </div>

                                
                                
                            </div>
                        </div>
                    </div>
			

            </div>

            







		</div>


    <!-- bottom left vue-notification -->
    <notifications group="top-right" position="top right" :speed="1000" />
	</div>
</template>

<script>

import axios from 'axios';
import moment from 'moment';


import Vue from "vue";
import JsonExcel from "vue-json-excel";

Vue.component("downloadExcel", JsonExcel);
var numeral = require("numeral");
Vue.filter("formatNumber", function (value) {
	return numeral(value).format("0,0"); // displaying other groupings/separators is possible, look at the docs
});

export default {
	mounted() {
		this.getCiudades();
		this.getLecturas();
				
		debugger; // eslint-disable-line no-debugger
		var userSetting = JSON.parse(localStorage.getItem('userSettings'));
		this.usuario = userSetting.Usuario;
		this.usuarioEstacion = userSetting.Estacion;
		this.IdRol = userSetting.IdRol;

		this.intervalId = window.setInterval(() => {
			this.getLecturas()
		}, 60000)
	},
	beforeRouteLeave(to, from , next){
		window.clearInterval(this.intervalId)
		next()
	},
  methods: {
		async pipaEnTransito(NoEstacion, tanque ){
			var self = this;
			var textoConfirmar = "";
			var estatusPipa = 0;


			if(tanque.EstatusPipa == 0){
				estatusPipa = 1;
				textoConfirmar = "Confirma poner la pipa en transito?";
			}
			if(tanque.EstatusPipa == 1){
				estatusPipa = 0;
				textoConfirmar = "Confirma que ya se recibió la pipa?";
			}

			// this.usuarioEstacion = userSetting.Estacion;
			// this.IdRol = userSetting.IdRol;
			
			if(
				(this.IdRol == 6 || this.IdRol == 1)
				||
				((this.IdRol == 3 || this.IdRol == 7) && (this.usuarioEstacion == NoEstacion && tanque.EstatusPipa == 1))
			){
				
			if(confirm(textoConfirmar)){

						debugger; // eslint-disable-line no-debugger
					try{
						const postData = {
						"Data":{
							"NoEstacion": NoEstacion,
							"CodigoTanque": tanque.CodigoTanque,
							"CodigoProducto": tanque.CodigoProducto,
							"Usuario": self.usuario,
							"Estatus": estatusPipa
							}
						};
						const response = await axios.post('Tanque/SetPipaTransito',postData);
						debugger; // eslint-disable-line no-debugger
						if(response.data.Success){
							if(response.data.response.length == 0){
								this.alertMessage = "No es posible establecer pipa en transito.";
								this.alertTitle = "Error";
								this.show('top-right', 'error');
							}
						}else{
							console.log("error")
							this.alertMessage = response.data.Error;
							this.alertTitle = "Error";
							this.show('top-right', 'error');
						}
						
						this.alertMessage = response.data.Message;
						this.alertTitle = "Correcto";
						this.show('top-right', 'success');
						self.getLecturas();
					}catch(e){
						//this.alert = true;
						//this.error = 'Invalid email/password';
					}


				}
			}
			else{
				this.alertMessage = "No cuenta con permisos para modificar";
				this.alertTitle = "No autorizado";
				this.show('top-right', 'error');
			}




		},
	async getCiudades(){
		try{
        const response = await axios.get('Catalogos/GetCiudades');
		//debugger; // eslint-disable-line no-debugger
        if(response.data.Success){
          if(response.data.response.length == 0){
            this.alertMessage = "No se encontró información.";
            this.alertTitle = "Error";
            this.show('top-right', 'error');
          }
        }else{
            console.log("error")
            this.alertMessage = response.data.Error;
            this.alertTitle = "Error";
            this.show('top-right', 'error');
        }
        //console.log("lectura tanques: "+ JSON.stringify(response.data.response));
        this.ciudades = response.data.response;
      }catch(e){
        //this.alert = true;
        //this.error = 'Invalid email/password';
      }
	},
	async getLecturas(){
		debugger; // eslint-disable-line no-debugger
        var self = this;
        self.intDate = self.date.replace('/', '');
        self.intDate = self.intDate.replace('/', '');
        
        var ciudad = self.ciudadSelect!=undefined?self.ciudadSelect:0;
		try{
        const response = await axios.post('Fajilla/GetFajillasDashboard',{
            "Data": {
                "FechaInicial": self.intDate,
                "Ciudad": ciudad
            }
        });
		//debugger; // eslint-disable-line no-debugger
        if(response.data.Success){
          if(response.data.response.length == 0){
            this.alertMessage = "No se encontró información.";
            this.alertTitle = "Error";
            this.show('top-right', 'error');
          }
        }else{
            console.log("error")
            this.alertMessage = response.data.Error;
            this.alertTitle = "Error";
            this.show('top-right', 'error');
        }
        //console.log("lectura tanques: "+ JSON.stringify(response.data.response));
        this.estaciones = response.data.response;
		// const today = new Date();
		// const date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
		// const time = today.getHours() + "." + today.getMinutes();
		// const dateTime = date +' '+ time;
		// this.date = dateTime;
		// this.buildExcelJson(this.estaciones);
      }catch(e){
        //this.alert = true;
        //this.error = 'Invalid email/password';
      }
	},
	getColorVolumen(volumenActual, capacidadMinima, capacidadCritica){
		// v-bind:class="getColorClass(tanque.VolumenActual)"
		if(volumenActual > capacidadMinima){
			return 'font-green';
		}else if(volumenActual <= capacidadMinima && volumenActual >= capacidadCritica){
			return 'font-orange';
		}else if(volumenActual < capacidadCritica){
			return 'font-red';
		}
	},
	getColorH2O(volumen,capacidadMaximaH2O){
		if(volumen > capacidadMaximaH2O){
			return 'font-red';
		}else{
			return '';
		}
	},
	getFechaLecturaClass(fechaActual, fechaLectura){
		// debugger; // eslint-disable-line no-debugger
		var hours = Math.abs(Date.parse(fechaActual) - Date.parse(fechaLectura)) / 36e5
		if(hours>=1){
			return 'font-red';
		}else{
			return '';
		}

	},
	show (group, type = '') {
		
		debugger; // eslint-disable-line no-debugger
		const text = this.alertMessage;
		this.$notify({
			group,
			title: this.alertTitle,
			text,
			type,
			data: {
				randomNumber: Math.random()
			}
		})
	},
	clean (group) {
		this.$notify({ group, clean: true })
	},
	swalNotification(swalType) {
		var btnClass = (swalType == 'error') ? 'danger' : swalType;
			btnClass = (swalType == 'question') ? 'primary' : btnClass;
		this.$swal({
			title: 'Are you sure?',
			text: 'You will not be able to recover this imaginary file!',
			type: swalType,
			showCancelButton: true,
			buttonsStyling: false,
			confirmButtonText: swalType.charAt(0).toUpperCase() + swalType.substr(1).toLowerCase(),
			cancelButtonText: 'Cancel',
			confirmButtonClass: 'btn me-5px btn-'+ btnClass +'',
			cancelButtonClass: 'btn btn-default',
		})
	},
  },
	data() {
		return {
			less: "<",
      date: moment().format("YYYY/MM/DD"),
      intDate: "",
			ciudades:[],
			usuarioEstacion:0,
			IdRol:0,
			usuario:'',
			alertMessage:'',
			alertTitle:'',
		excelJSON:[],
		json_fields: {
			"Ciudad":"Ciudad",
			"No Estacion":"NoEstacion",
			"Estacion":"Estacion",
			"Gasolinera":"Gasolinera",
			
			"Codigo Producto":"CodigoProducto",
			"Producto":"Producto",
			"Codigo Tanque":"CodigoTanque",
			"Numero Tanque":"NumeroTanque",
			"Capacidad Tanque":"CapacidadTanque",
			"Capacidad Maxima":"CapacidadMaxima",
			"Capacidad Minima":"CapacidadMinima",
			"Capacidad Critica":"CapacidadCritica",
			"Capacidad Max H2O":"CapacidadMaxH2O",
			"Ultima Transaccion":"UltimaTransaccion",
			"Fecha Lectura":"FechaLecturaFormato",
			"Volumen":"Volumnen",
			"Volumen Anterior":"VolumenAnterior",
			"Volumen H2O":"VolumenH2O",
			"Fecha Procesamiento":"FechaProcesamiento",
			"Porcentaje":"Porcentaje",
		},
		sortSelect: 1,
		ciudadSelect:1,
		estaciones:[],
		intervalId: null,
		};
	}
}
</script>

<style scoped>
.mb10{
	margin-bottom: 10px !important;
}
.mb5{
	margin-bottom: 5px !important;
}
.font-red{
	color: #D50000 !important;
}
.font-orange{
	color: #ffbb00 !important;
}
.font-green{
	color: #2D9600 !important;
}
.stats-title, .stats-desc{
	color: #555 !important;
}
.bg-white, .bg-teal-500 {
    background-color: #eee;
    border: 1px solid #AAB;
	color: #555 !important;
}

.widget-stats .stats-number, .widget.widget-stats .stats-number{
  font-size: 20px;
}
.widget-stats .stats-number, .widget.widget-stats .stats-number {
    font-size: 24px;
    margin-bottom: 0.25rem;
    font-weight: 600;
    letter-spacing: 1px;
}

.widget-stats .stats-icon.stats-icon-lg, .widget.widget-stats .stats-icon.stats-icon-lg {
    color: #999;
    text-shadow: 5px 10px rgb(0 0 0 / 15%);
    opacity: 0.15;
}
.widget-stats .stats-progress, .widget.widget-stats .stats-progress {
    height: 4px !important;
}
.mt-35{
	margin-top: 2rem;
}
.textRight{
	text-align: right;
}
.blink_me {
  animation: blinker 1s linear infinite;
}

@keyframes blinker {  
  50% { opacity: 0; }
}
.btn-green{
	background-color: limegreen !important;
	border-color: limegreen !important;
}

.border-moneda{
	border-left: 1px solid lightgray;
}
</style>