<template>
  <div>
    <!-- BEGIN breadcrumb -->
    <ol class="breadcrumb float-xl-end">
      <li class="breadcrumb-item"><a href="javascript:;">Catalogos</a></li>
      <li class="breadcrumb-item active">
        <a href="javascript:;">Clientes</a>
      </li>
    </ol>
    <!-- END breadcrumb -->
    <!-- BEGIN page-header -->
    <h1 class="page-header">Clientes</h1>
    <!-- END page-header -->

    <div class="row">
      <div class="col-md-8">
        <form @submit.prevent="search">
          <div class="form-group row">
            <div class="col-md-6">
              <div class="input-group">
                <input
                  class="form-control"
                  type="text"
                  v-model="clienteBuscar"
                />
              </div>
            </div>
            <div class="col-md-6">
              <button type="submit" class="btn btn-success me-1 mb-1">
                {{ textoBotonBuscar }}
              </button>
            </div>
          </div>
        </form>
      </div>
      <div class="col-md-4">
        <div class="col-md-12 text-end">
          <button
          v-if="allowEdit"
            type="button"
            v-b-modal.modalDialog
            class="btn btn-sm btn-success"
            @click="getCliente('')"
          >
            Agregar Cliente
          </button>
        </div>
      </div>
    </div>
    <panel title="Catalogo de clientes" bodyClass="p-0" class="">
      <vue-good-table
        :columns="columns"
        :rows="clientes"
        :lineNumbers="false"
        :search-options="{ enabled: false, placeholder: 'Buscar' }"
        :pagination-options="{ enabled: true, position: 'bottom' }"
      >
        <template slot="table-row" slot-scope="props">
          <!-- <span v-if="props.column.field == 'image'">
            <img
              v-bind:src="props.row.image"
              class="rounded my-n1 mx-auto d-block"
              width="26"
            />
          </span>
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span> -->
          <span v-if="props.column.label == 'Acciones'">
            <button
              v-b-modal.modalDialog
              class="btn btn-sm btn-success"
              @click="getCliente(props.formattedRow.RFC)"
            >
              Ver
            </button>
            &nbsp;
            <button
              v-b-modal.modalCorreosDialog
              class="btn btn-sm btn-success"
              @click="getCliente(props.formattedRow.RFC)"
            >
              Correos
            </button>
          </span>
          <span v-else-if="props.column.field == 'Estatus'">
            <span
              v-if="props.formattedRow[props.column.field]"
              class="fa fa-check"
            ></span>
            <span
              v-if="!props.formattedRow[props.column.field]"
              class="fa fa-ban"
            ></span>
          </span>
        </template>
      </vue-good-table>
    </panel>

    <!-- #modal-dialog -->
    <b-modal id="modalDialog">
      <template slot="modal-header">
        <h4 class="modal-title" v-if="clienteNuevo">Nuevo Cliente</h4>
        <h4 class="modal-title" v-if="!clienteNuevo">{{ clienteModal.RFC }}</h4>
        <a class="btn-close" @click="$bvModal.hide('modalDialog')"></a>
      </template>

      <div>
        <form action="/" method="POST">
          <fieldset>
            <!-- <legend class="mb-3">Legend</legend> -->
            <div class="mb-3">
              <label class="form-label" for="txtRFC">RFC</label>
              <input
                class="form-control"
                type="text"
                id="txtRFC"
                placeholder="RFC"
                :disabled="!clienteNuevo"
                v-model="clienteModal.RFC"
              />
            </div>
            <div class="mb-3">
              <label class="form-label" for="txtNombre">Nombre</label>
              <input
                class="form-control"
                type="text"
                id="txtNombre"
                placeholder="Nombre"
                v-model="clienteModal.Nombre"
              />
            </div>




            <div class="mb-3">
              <label class="form-label" for="txtUsoCFDI">Uso CFDI</label>
              <select name="" id="ddlUsoCFDI" class="form-control" v-model="clienteModal.UsoCFDI">
                <option :value="uso.Id" v-for="uso in usosCFDI" :key="uso.Id">
                  {{uso.Descripcion}}
                </option>
              </select>
              <!-- <input
                class="form-control"
                type="text"
                id="txtUsoCFDI"
                placeholder="Uso CFDI"
                
              /> -->
            </div>
            <div class="mb-3">
              <label class="form-label" for="txtRegimenFiscal">Regimen Fiscal</label>
              <select name="" id="ddlRegimenFiscal" class="form-control" v-model="clienteModal.RegimenFiscal">
                <option :value="regimen.Clave" v-for="regimen in regimenesFiscales" :key="regimen.Clave">
                  {{regimen.Denominacion}}
                </option>
              </select>
              <!-- <input
                class="form-control"
                type="text"
                id="txtRegimenFiscal"
                placeholder="Regimen Fiscal"
                v-model="clienteModal.RegimenFiscal"
              /> -->
            </div>
            <div class="mb-3">
              <label class="form-label" for="txtCodigoPostal">Codigo Postal</label>
              <input
                class="form-control"
                type="text"
                id="txtCodigoPostal"
                placeholder="CodigoPostal"
                v-model="clienteModal.CodigoPostal"
              />
            </div>


            <div class="form-check form-switch mb-2" v-if="!clienteNuevo">
              <input
                class="form-check-input"
                type="checkbox"
                id="flexSwitchCheckChecked"
                v-model="clienteModal.Estatus"
              />
              <label class="form-check-label" for="flexSwitchCheckChecked"
                ><span v-if="clienteModal.Estatus">Activo</span
                ><span v-if="!clienteModal.Estatus">Inactivo</span></label
              >
            </div>
            <div class="mb-3">
              <label class="form-label" for="txtCorreo" v-if="clienteNuevo"
                >Correo</label
              >
              <input
                class="form-control"
                type="text"
                id="txtCorreo"
                placeholder="Correo"
                v-if="clienteNuevo"
                v-model="clienteModal.Correo"
              />
              <!-- <input class="form-control" type="text" id="txtCorreoUpdate" placeholder="Correo" v-if="!clienteNuevo" v-model="clienteModal.Correos[0].Direccion"/> -->
            </div>

            <div class="table-responsive" v-if="!clienteNuevo">
              <table class="table mb-0">
                <thead>
                  <tr>
                    <th>Correos</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="correo in clienteModal.Correos" :key="correo.Id">
                    <td>{{ correo.Direccion }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </fieldset>
        </form>
      </div>

      <template slot="modal-footer">
        <button
          v-if="allowEdit"
          type="button"
          class="btn btn-primary mr-auto"
          @click="saveCliente()"
        >
          {{ textoBotonGuardar }}
        </button>
        <button
          type="button"
          class="btn btn-secondary"
          @click="$bvModal.hide('modalDialog')"
        >
          Cancelar
        </button>
      </template>
    </b-modal>

    <!-- nodal correos dialog -->
    <b-modal id="modalCorreosDialog">
      <template slot="modal-header">
        <h4 class="modal-title" v-if="!clienteNuevo">{{ clienteModal.RFC }}</h4>
        <a class="btn-close" @click="$bvModal.hide('modalCorreosDialog')"></a>
      </template>

      <div>
        <form action="/" method="POST">
          <fieldset>
            <!-- <legend class="mb-3">Legend</legend> -->
            <div class="mb-3"
          v-if="allowEdit">
              <label class="form-label" for="txtCorreo">Agregar Correo</label>
              <input
                class="form-control"
                type="text"
                id="txtCorreo"
                placeholder="Correo"
                v-model="nuevoCorreo"
              />
              <button
                type="button"
                class="btn btn-success"
                @click="addCorreo()"
              >
                + Agregar
              </button>
            </div>
            <div class="table-responsive">
              <table class="table mb-0">
                <thead>
                  <tr>
                    <th>Correos</th>
                    <th>Editar</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="correo in clienteModal.Correos" :key="correo.Id">
                    <td>
                      <input
                        class="form-control"
                        type="text"
                        id="txtCorreo"
                        placeholder="Correo"
                        v-model="correo.Direccion"
                      />
                    </td>
                    <td>
                      <button
                        type="button"
                        class="btn btn-success btn-xs"
                        v-if="correo.Id != 0 && allowEdit"
                        @click="UdpEmailCliente(correo)"
                      >
                        Editar
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </fieldset>
        </form>
      </div>

      <template slot="modal-footer">
        <button
          type="button"
          class="btn btn-secondary"
          @click="$bvModal.hide('modalCorreosDialog')"
        >
          Cerrar
        </button>
      </template>
    </b-modal>

    <!-- bottom left vue-notification -->
    <notifications group="top-right" position="top right" :speed="1000" />
  </div>
</template>

<script>
import axios from "axios";
export default {
  mounted: function () {
    var page = this.$route.path;
    if(page=="/catalogos/clientes/modificarclientes"){
      
      this.allowEdit = true;
    }
    this.GetRegimenFiscales();
    this.GetUsosCFDI();
  },
  methods: {
    async GetRegimenFiscales(){
      try {
        const response = await axios.get("Catalogos/GetRegimenFiscales");
        if (response.data.Success) {
          if (response.data.response.length == 0) {
            this.alertMessage =
              "No se encontró usos CFDI";
            this.alertTitle = "Error";
            this.show("top-right", "error");
          } else {
            this.regimenesFiscales = response.data.response;
          }
        } else {
          this.alertMessage = response.data.Error;
          this.alertTitle = "Error";
          this.show("top-right", "error");
        }
      } catch (e) {
        this.alertMessage = "Ocurrio un error";
        this.alertTitle = "Error";
        this.show("top-right", "error");
      }
    },
    async GetUsosCFDI(){
      try {
        const response = await axios.get("Catalogos/GetUsosCFDI");
        if (response.data.Success) {
          if (response.data.response.length == 0) {
            this.alertMessage =
              "No se encontró usos CFDI";
            this.alertTitle = "Error";
            this.show("top-right", "error");
          } else {
            this.usosCFDI = response.data.response;
          }
        } else {
          this.alertMessage = response.data.Error;
          this.alertTitle = "Error";
          this.show("top-right", "error");
        }
      } catch (e) {
        this.alertMessage = "Ocurrio un error";
        this.alertTitle = "Error";
        this.show("top-right", "error");
      }
    },
    showNotification(title, text, type) {
      this.alertTitle = title;
      this.alertMessage = text;
      this.show("top-right", type);
    },
    async UdpEmailCliente(correo) {
      if (correo.Direccion == "") {
        this.showNotification(
          "Validacion",
          "Capture un correo valido",
          "warning"
        );
        return;
      }
      this.textoBotonGuardar = "Guardando...";
      //insertar nuevo correo
      try {
        const response = await axios.post("Cliente/UdpEmailCliente", {
          Data: {
            Id: correo.Id,
            Correo: correo.Direccion,
          },
        });
        console.log("response guardar" + response);
        if (response.data.Success) {
          this.alertMessage = "Se guardó el correo del cliente";
          this.alertTitle = "Guardado con exito";
          this.show("top-right", "success");
          this.nuevoCorreo = "";
        } else {
          this.alertMessage = response.data.Mensaje;
          this.alertTitle = "Error";
          this.show("top-right", "error");
        }
        this.textoBotonGuardar = "Guardar";
      } catch (e) {
        this.alertMessage = "Ocurrio un error";
        this.alertTitle = "Error";
        this.show("top-right", "error");
      }
    },
    async addCorreo() {
      if (this.nuevoCorreo == "") {
        this.showNotification(
          "Validacion",
          "Capture un correo valido",
          "warning"
        );
        return;
      }
      this.textoBotonGuardar = "Guardando...";
      //insertar nuevo correo
      try {
        const response = await axios.post("Cliente/AddEmailCliente", {
          Data: {
            Id: this.clienteModal.Id,
            RFC: this.clienteModal.RFC,
            Correo: this.nuevoCorreo,
          },
        });
        console.log("response guardar" + response);
        if (response.data.Success) {
          this.alertMessage = "Se guardó el correo del cliente";
          this.alertTitle = "Guardado con exito";
          this.show("top-right", "success");
          this.clienteModal.Correos.push({
            Id: 0,
            Direccion: this.nuevoCorreo,
          });
          this.nuevoCorreo = "";
        } else {
          this.alertMessage = response.data.Mensaje;
          this.alertTitle = "Error";
          this.show("top-right", "error");
        }
        this.textoBotonGuardar = "Guardar";
      } catch (e) {
        this.alertMessage = "Ocurrio un error";
        this.alertTitle = "Error";
        this.show("top-right", "error");
      }
    },
    async saveCliente() {
      if (
        this.clienteModal.RFC == "" ||
        this.clienteModal.Nombre == "" ||
        this.clienteModal.Correo == ""
      ) {
        this.showNotification(
          "Validacion",
          "Capture todos los campos",
          "warning"
        );
        return;
      }
      var clien = {
            Data: this.clienteModal,
          };
      console.log(JSON.stringify(clien));
      this.textoBotonGuardar = "Guardando...";
      if (this.clienteNuevo) {
        //insertar nuevo
        try {
          
          const response = await axios.post("Cliente/AddCliente", {
            Data: this.clienteModal,
          });
          console.log("response guardar" + response);
          if (response.data.Success) {
            this.alertMessage = "Se guardó el cliente";
            this.alertTitle = "Guardado con exito";
            this.show("top-right", "success");
            this.$bvModal.hide("modalDialog");
          } else {
            this.alertMessage = response.data.Error;
            this.alertTitle = "Error";
            this.show("top-right", "error");
          }
          this.textoBotonGuardar = "Guardar";
        } catch (e) {
          this.alertMessage = "Ocurrio un error";
          this.alertTitle = "Error";
          this.show("top-right", "error");
        }
      } else {
        try {
debugger; // eslint-disable-line no-debugger
          const response = await axios.post("Cliente/UdpCliente", {
            Data: {
              Id: this.clienteModal.Id,
              Nombre: this.clienteModal.Nombre,
              UsoCFDI: this.clienteModal.UsoCFDI,
              RegimenFiscal: this.clienteModal.RegimenFiscal,
              CodigoPostal: this.clienteModal.CodigoPostal,
              Estatus: this.clienteModal.Estatus ? 1:0,
            },
          });
          if (response.data.Success) {
            this.alertMessage = "Se guardó el cliente";
            this.alertTitle = "Guardado con exito";
            this.show("top-right", "success");

            this.getClientes();
            this.$bvModal.hide("modalDialog");
          } else {
            this.alertMessage = response.data.Mensaje;
            this.alertTitle = "Error";
            this.show("top-right", "error");
          }
          this.textoBotonGuardar = "Guardar";
        } catch (e) {
          this.alertMessage = "Ocurrio un error";
          this.alertTitle = "Error";
          this.show("top-right", "error");
        }
      }
    },
    async getCliente(rfc) {
      if (rfc == "") {
        this.clienteNuevo = true;
        this.clienteModal = {
          RFC: "",
          Nombre: "",
          Correo: "",
        };
      } else {
        this.clienteNuevo = false;
        try {
          const response = await axios.post("Cliente/GetCliente", {
            Data: {
              RFC: rfc,
              Id: 0,
              Tarjeta: "",
            },
          });
          if (response.data.Success) {
            if (response.data.response == null) {
              this.alertMessage =
                "No se encontró información del cliente especificado.";
              this.alertTitle = "Error";
              this.show("top-right", "error");
            } else {
              
debugger; // eslint-disable-line no-debugger
              this.clienteModal = response.data.response;
            }
          } else {
            this.alertMessage = response.data.Error;
            this.alertTitle = "Error";
            this.show("top-right", "error");
          }
        } catch (e) {
          this.alertMessage = "Ocurrio un error";
          this.alertTitle = "Error";
          this.show("top-right", "error");
        }
      }
    },
    show(group, type = "") {
      const text = this.alertMessage;
      this.$notify({
        group,
        title: this.alertTitle,
        text,
        type,
        data: {
          randomNumber: Math.random(),
        },
      });
    },
    clean(group) {
      this.$notify({ group, clean: true });
    },
    swalNotification(swalType) {
      var btnClass = swalType == "error" ? "danger" : swalType;
      btnClass = swalType == "question" ? "primary" : btnClass;
      this.$swal({
        title: "Are you sure?",
        text: "You will not be able to recover this imaginary file!",
        type: swalType,
        showCancelButton: true,
        buttonsStyling: false,
        confirmButtonText:
          swalType.charAt(0).toUpperCase() + swalType.substr(1).toLowerCase(),
        cancelButtonText: "Cancel",
        confirmButtonClass: "btn me-5px btn-" + btnClass + "",
        cancelButtonClass: "btn btn-default",
      });
    },
    async getClientes() {
      this.textoBotonBuscar = "Buscando...";
      try {
        const response = await axios.post("Cliente/GetClientes", {
          Data: {
            Busqueda: this.clienteBuscar,
            IncluirCancelados: 1,
          },
        });
        if (response.data.Success) {
          if (response.data.response.length == 0) {
            this.alertMessage =
              "No se encontró información del cliente especificado.";
            this.alertTitle = "Error";
            this.show("top-right", "error");
          } else {
            this.clientes = response.data.response;
          }
        } else {
          this.alertMessage = response.data.Error;
          this.alertTitle = "Error";
          this.show("top-right", "error");
        }
        this.textoBotonBuscar = "Buscar";
      } catch (e) {
        this.alertMessage = "Ocurrio un error";
        this.alertTitle = "Error";
        this.show("top-right", "error");
      }
    },
    async search(e) {
      e.preventDefault();
      this.getClientes();
    },
  },
  data() {
    return {
    usosCFDI:[],
    regimenesFiscales:[],
      allowEdit:false,
      nuevoCorreo: "",
      clienteNuevo: true,
      clienteModal: {},
      textoBotonGuardar: "Guardar",
      textoBotonBuscar: "Buscar",
      clienteBuscar: "",
      columns: [
        {
          label: "ID",
          field: "Id",
          type: "number",
          tdClass: "text-center text-nowrap",
          thClass: "text-center text-nowrap",
        },
        {
          label: "RFC",
          field: "RFC",
          tdClass: "text-center text-nowrap",
          thClass: "text-center text-nowrap",
        },
        {
          label: "Nombre",
          field: "Nombre",
          tdClass: "text-nowrap",
          thClass: "text-center text-nowrap",
        },


        {
          label: "Uso CFDI",
          field: "UsoCFDI",
          tdClass: "text-center text-nowrap",
          thClass: "text-center text-nowrap",
        },
        {
          label: "Regimen Fiscal",
          field: "RegimenFiscal",
          tdClass: "text-center text-nowrap",
          thClass: "text-center text-nowrap",
        },
        {
          label: "Codigo Postal",
          field: "CodigoPostal",
          tdClass: "text-center text-nowrap",
          thClass: "text-center text-nowrap",
        },

        {
          label: "Estatus",
          field: "Estatus",
          tdClass: "text-center text-nowrap",
          thClass: "text-center text-nowrap",
        },
        {
          label: "Acciones",
          field: "Acciones",
          width: "20%",
          tdClass: "text-center text-nowrap",
          thClass: "text-center text-nowrap",
        },
      ],
      clientes: [],
    };
  },
};
</script>