<template>
  <div>

    <div class=" loading-search" v-if="loading">
      <div class="fa-3x text-center">
        <i class="fas fa-circle-notch fa-spin text-success" style=""></i>
      </div>
    </div>
    <!-- BEGIN breadcrumb -->
    <ol class="breadcrumb float-xl-end">
      <!-- <li class="breadcrumb-item"><a href="javascript:;">Reportes</a></li>
			<li class="breadcrumb-item"><a href="javascript:;">Jarreos</a></li> -->
      <li class="breadcrumb-item active"><a href="javascript:;">Tipos de cambio</a></li>
    </ol>

    <h1 class="page-header">Tipos de cambio <small></small></h1>



    <form @submit.prevent="search">
      <div class="row">
        <div class="col-md-8">
          <div class="form-group row">

            <div class="col-md-3">
              <label class="col-form-label">Fecha</label>
              <div class="input-group">
                <date-picker id="dateTimeInput" v-model="date" :config="options"></date-picker>
                <label for="dateTimeInput" class="input-group-text">
                  <i class="fa fa-calendar"></i>
                </label>
              </div>
            </div>


            <!-- <div class="col-md-3">
                        <label class="col-form-label">Fecha final</label>
                      <div class="input-group">
                        <date-picker id="dateTimeInputEnd" v-model="dateEnd" :config="options"></date-picker>
                        <label for="dateTimeInputEnd" class="input-group-text">
                          <i class="fa fa-calendar"></i>
                        </label>
                      </div>
                    </div> -->
            <div class="col-md-3">
              <label class="col-form-label">Ciudad</label>
              <select class="form-select" @change="getEstaciones()" v-model="ciudadSelect"
                :disabled="deshabilitarCombos">
                <option v-for="ciudad in ciudades" :value="ciudad.Id" v-bind:key="ciudad.Id">
                  {{ ciudad.Nombre }}
                </option>
              </select>
            </div>
            <!-- <div class="col-md-3">
                        <label class="col-form-label">Estación</label>
                        <select class="form-select" v-model="estacionSelect" :disabled="deshabilitarCombos">
                          <option value="0">Todas</option>
                            <option v-for="estacion in estaciones" :value="estacion.NoEstacion" v-bind:key="estacion.NoEstacion">
                            {{ estacion.Nombre }}
                            </option>
                          </select>
                    </div> -->

          </div>
        </div>
        <div class="col-md-2">
          <button type="submit" class="btn btn-success me-1 mb-1" style="margin-top: 3.1em;">{{ textoBotonBuscar
            }}</button>
        </div>
        <div class="col-md-2" style="padding-top: 2.7em;">

        </div>
      </div>
    </form>
    <div>



      <panel title="Tipos de cambio" bodyClass="p-0" class="" style="margin-top: 15px;">

        <div class="row">
          <div class="col-12">
            <div class="row">
              <div class="col-md-3">
                <label class="col-form-label">Tipo de cambio</label>
                <input type="number" step="any" name="" id="" v-model="tipoDeCambioAplicar" class="form-control"
                  style="max-width: 150px;">
              </div>

              <div class="col-md-2">
                <button type="button" @click="SetTipoCambioPlazaCheck()"
                  :disabled="!(estaciones.length > 0) || !(tipoDeCambioAplicar > 0)" class="btn btn-success me-1 mb-1"
                  style="margin-top: 3.1em;">Aplicar a todos</button>
              </div>

            </div>
          </div>
        </div>



        <DxDataGrid id="gridContainer" :data-source="estaciones" key-expr="Estacion" :show-borders="true"
          @exporting="onExporting" :show-column-lines="false" :show-row-lines="true" :row-alternation-enabled="true">

          <DxScrolling column-rendering-mode="virtual" />
          <DxPager :show-page-size-selector="true" :allowed-page-sizes="[10, 20, 50, 100]"
            :show-navigation-buttons="true" />
          <DxPaging :page-size="10" :page-index="1" />
          <DxFilterRow />
          <DxHeaderFilter :visible="showHeaderFilter" />
          <DxSearchPanel :visible="true" :width="240" placeholder="Search..." />

          <DxGroupPanel :visible="false" />
          <DxGrouping :auto-expand-all="true" />
          <DxExport :enabled="true" :allow-export-selected-data="false" />
          <DxSelection mode="false" />


          <DxColumn dataField="Editar" caption="Editar" :cellTemplate="enviarCGButton" />



          <!-- <DxColumn data-field="Estacion" caption="Estacion" /> -->
          <DxColumn data-field="Estacion" caption="Estacion" width="150">
            <DxHeaderFilter :allow-search="true" />
          </DxColumn>
          <DxColumn data-field="Nombre" caption="Nombre" />
          <DxColumn data-field="Fecha" caption="Fecha" dataType="date" />
          <DxColumn data-field="TC" caption="Tipo de cambio" dataType="number">
            <DxFormat type="currency" :precision="2" />
          </DxColumn>


        </DxDataGrid>

      </panel>


    </div>

    <!-- #modal-dialog -->
    <b-modal id="modalDialog" size="md">
      <template slot="modal-header">
        <h4 class="modal-title">Tipo de cambio</h4>
        <a class="btn-close" @click="$bvModal.hide('modalDialog')"></a>
      </template>
      <div class="row">
        <div class="col-12">
          <form action="/" method="POST">
            <fieldset>

              <div class="mb-3">
                <label class="form-label" for="txtTipoCambio">Tipo de cambio</label>
                <input class="form-control" type="number" step=".01" placeholder="Importe" v-model="datosModal.TC" />
              </div>

            </fieldset>
          </form>
        </div>
      </div>

      <template slot="modal-footer">
        <button type="button" class="btn btn-primary mr-auto" @click="SetTipoCambio()">Guardar</button>
        <button type="button" class="btn btn-secondary" @click="$bvModal.hide('modalDialog')">Cancelar</button>
      </template>
    </b-modal>
    <!-- bottom left vue-notification -->
    <notifications group="top-right" position="top right" :speed="1000" />
  </div>
</template>

<script>

import axios from 'axios';
import moment from 'moment';



import {
  DxDataGrid,
  DxFormat,
  // DxSummary, 
  // DxTotalItem,
  DxPaging, DxPager, DxScrolling, DxColumn, DxExport, DxSelection, DxGroupPanel, DxGrouping, DxHeaderFilter, DxFilterRow, DxSearchPanel,
} from 'devextreme-vue/data-grid';
import { Workbook } from 'exceljs';
import { saveAs } from 'file-saver';
// Our demo infrastructure requires us to use 'file-saver-es'. We recommend that you use the official 'file-saver' package in your applications.
import { exportDataGrid } from 'devextreme/excel_exporter';
//import service from './data.js';


var numeral = require("numeral");
import Vue from "vue";

import JsonExcel from "vue-json-excel";
Vue.component("downloadExcel", JsonExcel);
Vue.filter("formatNumber", function (value) {
  return numeral(value).format("0,00.00"); // displaying other groupings/separators is possible, look at the docs
});

Vue.filter("currency", function (value) {
  return numeral(value).format("$0,0.00");
});


export default {
  mounted: function () {
    /* eslint-disable no-debugger */
    debugger;
    var self = this;

    var userSetting = JSON.parse(localStorage.getItem('userSettings'));
    this.usuario = userSetting.Usuario;
    this.usuarioEstacion = userSetting.Estacion;
    this.IdRol = userSetting.IdRol;
    this.getCiudades();
    this.ciudadSelect = userSetting.CiudadId;
    // this.getEstaciones();
    // this.estacionSelect = userSetting.Estacion;

    if (this.IdRol == 3 || this.IdRol == 7) {
      this.deshabilitarCombos = true;
    }

  },
  beforeCreate() {

  },
  created() {
    // console.log('created');
  },
  components: {
    // DxTotalItem, 
    DxDataGrid,
    // DxSummary, 
    DxFormat,
    DxPaging,
    DxPager, DxScrolling, DxColumn, DxExport, DxSelection, DxGroupPanel, DxGrouping, DxHeaderFilter, DxFilterRow, DxSearchPanel,
  },
  beforeMount() {
    // console.log('beforeMount');
  },
  computed: {
  },
  methods: {



    enviarCGButton(cellElement, cellInfo) {

      var self = this;
      const currentDate = moment(); // current date and time
      const targetDate = moment(self.dateOK); // the date you want to compare against

      // Check if targetDate is in the past


      // Check if the target date is today or in the future
      if (targetDate.isSameOrAfter(currentDate, 'day')) {
        const button = document.createElement("button");
        button.innerText = "Editar";
        button.className = "btn btn-sm btn-success";
        button.addEventListener("click", () => {

          this.handleButtonClick(cellInfo.data);
        });

        cellElement.appendChild(button);

      }
    },
    handleButtonClick(data) {
      debugger; // eslint-disable-line no-debugger

      // alert(data.NoEstacion);
      this.GetTipoCambio(data);

    },


    async GetTipoCambio(data) {
      //api/Precio/SetAcusePrecio

      var dataFromRow = data;
      var self = this;
      self.loading = true;
      try {
        debugger; // eslint-disable-line no-debugger
        var dataToSend = {
          "Data": {
            "Estacion": data.Estacion,
            "Fecha": moment(data.Fecha).format('YYYYMMDD')
          }
        };
        debugger; // eslint-disable-line no-debugger
        const response = await axios.post("Corresponsalia/GetTipoCambio", dataToSend);

        self.loading = false;
        debugger; // eslint-disable-line no-debugger
        if (response.data.Success) {
          this.datosModal = response.data.response;
          this.datosModal.FechaInt = moment(dataFromRow.Fecha).format('YYYYMMDD');
          this.$bvModal.show('modalDialog');
        }
        else {

          this.datosModal = {
            "TC": 0,
            "Estacion": dataFromRow.Estacion,
            "FechaInt": moment(dataFromRow.Fecha).format('YYYYMMDD')

          };
          this.$bvModal.show('modalDialog');
        }


      } catch (e) {
        self.loading = false;
        self.textoBotonEnviar = "Enviar";
        this.alertMessage = "Ocurrio un error";
        this.alertTitle = "Error";
        this.show("top-right", "error");
      }
    },
    async SetTipoCambio() {

      var self = this;
      self.loading = true;
      try {
        debugger; // eslint-disable-line no-debugger
        var dataToSend = {
          "Data": {
            "Fecha": self.datosModal.FechaInt,
            "Ciudad": self.ciudadOK,
            "Usuario": self.usuario,
            "Estacion": self.datosModal.Estacion,
            "TipoCambio": self.datosModal.TC
          }
        };
        debugger; // eslint-disable-line no-debugger
        const response = await axios.post("Corresponsalia/SetTipoCambio", dataToSend);

        self.loading = false;
        debugger; // eslint-disable-line no-debugger
        if (response.data.Success) {

          this.alertTitle = response.data.Error;
          this.alertMessage = response.data.Message;
          this.show("top-right", "success");
          this.getEstaciones();
          this.$bvModal.hide('modalDialog');
        }
        else {

          this.alertTitle = response.data.Error;
          this.alertMessage = response.data.Message;
          this.show("top-right", "error");

        }


      } catch (e) {
        self.loading = false;
        self.textoBotonEnviar = "Enviar";
        this.alertMessage = "Ocurrio un error";
        this.alertTitle = "Error";
        this.show("top-right", "error");
      }
    },

    async enviarCG(data) {
      //api/Precio/SetAcusePrecio


      var self = this;
      // self.textoBotonEnviar = "Enviando...";
      self.loading = true;
      try {
        // const segments = data.Path.split('/');

        // // Get the last element of the array, which is the filename
        // const fileName = segments[segments.length - 1];

        debugger; // eslint-disable-line no-debugger
        var dataToSend = {
          "Data": {
            "Path": data.Path,
            "Usuario": self.usuario
            // "Path": "CRE/ACUSES/2023/10/23/PL3988EXPES2015.pdf", 
            // "Usuario": "juan.garcia"
          }
        };
        debugger; // eslint-disable-line no-debugger
        const response = await axios.post("Precio/GetAcusePDF", dataToSend);

        self.loading = false;
        // self.textoBotonEnviar = "Enviar";
        debugger; // eslint-disable-line no-debugger
        if (response.data.Success) {

          this.alertMessage = response.data.Message;
          this.alertTitle = "";
          this.show("top-right", "success");


          self.descargarPDF(response.data.response, data.Nombre);
        }
        else {

          this.alertMessage = response.data.Message;
          this.alertTitle = "Error";
          this.show("top-right", "error");
        }


      } catch (e) {
        self.loading = false;
        self.textoBotonEnviar = "Enviar";
        this.alertMessage = "Ocurrio un error";
        this.alertTitle = "Error";
        this.show("top-right", "error");
      }
    },





    onExporting(e) {
      const workbook = new Workbook();
      const worksheet = workbook.addWorksheet('Pedidos');

      exportDataGrid({
        component: e.component,
        worksheet: worksheet,
        autoFilterEnabled: true
      }).then(() => {
        workbook.xlsx.writeBuffer().then((buffer) => {
          saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'Pedidos.xlsx');
        });
      });
      e.cancel = true;
    },
    async getCiudades() {
      try {
        const response = await axios.get('Catalogos/GetCiudades');
        if (response.data.Success) {
          if (response.data.response.length == 0) {
            this.alertMessage = "No se encontró información.";
            this.alertTitle = "Error";
            this.show('top-right', 'error');
          }
        } else {
          console.log("error")
          this.alertMessage = response.data.Error;
          this.alertTitle = "Error";
          this.show('top-right', 'error');
        }
        this.ciudades = response.data.response;
      } catch (e) {
        //this.alert = true;
        //this.error = 'Invalid email/password';
      }
    },
    async getEstaciones2() {
      try {
        const response = await axios.post('Estacion/GetEstaciones?ciudad=' + this.ciudadSelect);
        if (response.data.Success) {
          if (response.data.response.length == 0) {
            this.alertMessage = "No se encontró información.";
            this.alertTitle = "Error";
            this.show('top-right', 'error');
          }
        } else {
          console.log("error")
          this.alertMessage = response.data.Error;
          this.alertTitle = "Error";
          this.show('top-right', 'error');
        }
        this.estaciones = response.data.response;

        debugger; // eslint-disable-line no-debugger
        // this.productos = [];
        // this.getProductos();
      } catch (e) {
        //this.alert = true;
        //this.error = 'Invalid email/password';
      }
    },
    show(group, type = '') {
      const text = this.alertMessage;
      this.$notify({
        group,
        title: this.alertTitle,
        text,
        type,
        data: {
          randomNumber: Math.random()
        }
      })
    },
    clean(group) {
      this.$notify({ group, clean: true })
    },
    swalNotification(swalType) {
      var btnClass = (swalType == 'error') ? 'danger' : swalType;
      btnClass = (swalType == 'question') ? 'primary' : btnClass;
      this.$swal({
        title: 'Are you sure?',
        text: 'You will not be able to recover this imaginary file!',
        type: swalType,
        showCancelButton: true,
        buttonsStyling: false,
        confirmButtonText: swalType.charAt(0).toUpperCase() + swalType.substr(1).toLowerCase(),
        cancelButtonText: 'Cancel',
        confirmButtonClass: 'btn me-5px btn-' + btnClass + '',
        cancelButtonClass: 'btn btn-default',
      })
    },
    async getEstaciones() {
      debugger; // eslint-disable-line no-debugger
      var self = this;



      self.textoBotonBuscar = "Buscando...";
      self.loading = true;
      try {

        self.dateOK = self.date;

        self.intDate = self.date.replace('/', '');
        self.intDate = self.intDate.replace('/', '');


        var ciudad = self.ciudadSelect != undefined ? self.ciudadSelect : 0;
        self.ciudadOK = ciudad;

        debugger; // eslint-disable-line no-debugger

        var dataToSend = {
          "Data": {
            "Fecha": self.intDate,
            // "FechaFin":self.intDateEnd,
            "Ciudad": ciudad,
            // "Estacion": estacion,

          }
        };

        self.remplazar = false;



        const response = await axios.post('Corresponsalia/GetTipoCambioCiudad', dataToSend);

        self.loading = false;
        self.textoBotonBuscar = "Buscar";
        if (response.data.Success) {
          if (response.data.response.length == 0) {
            self.alertMessage = "No se encontró información del día especificado.";
            self.alertTitle = "Error";
            self.show('top-right', 'error');
          } else {

            debugger; // eslint-disable-line no-debugger

            self.estaciones = response.data.response;

            for (var t = 0; t < self.estaciones.length; t++) {
              if (self.estaciones[t].TC > 0) {
                self.remplazar = true;
              }
            }


          }


        } else {
          console.log("error")
          self.alertMessage = response.data.Message;
          self.alertTitle = response.data.Error;
          self.show('top-right', 'error');
        }





        self.loading = false;
      } catch (e) {
        self.loading = false;
        self.alertMessage = 'Ocurrió un error';
        self.alertTitle = '';
        self.show('top-right', 'error');
        //this.alert = true;
        //this.error = 'Invalid email/password';
      }
    },

    async getEstaciones3() {
      debugger; // eslint-disable-line no-debugger
      var self = this;



      self.textoBotonBuscar = "Buscando...";
      self.loading = true;
      try {



        self.intDate = self.date.replace('/', '');
        self.intDate = self.intDate.replace('/', '');


        self.intDateEnd = self.dateEnd.replace('/', '');
        self.intDateEnd = self.intDateEnd.replace('/', '');

        var ciudad = self.ciudadSelect != undefined ? self.ciudadSelect : 0;
        var estacion = self.estacionSelect != undefined ? self.estacionSelect : 0;


        const startDate = moment(self.date).format('YYYY-MM-DD');
        const endDate = moment(self.dateEnd).format('YYYY-MM-DD');



        // var gasolinera = 0;
        // for(var es = 0 ; es < self.estaciones.length ; es++){
        //     if(estacion == self.estaciones[es].NoEstacion){
        //         gasolinera = self.estaciones[es].Gasolinera;
        //         break;
        //     }
        // }
        debugger; // eslint-disable-line no-debugger

        var dataToSend = {
          "Data": {
            "FechaIni": self.intDate,
            "FechaFin": self.intDateEnd,
            "Ciudad": ciudad,
            "Estacion": estacion,

          }
        };




        const response = await axios.post('Jarreo/GetJarreos', dataToSend);

        self.loading = false;
        self.textoBotonBuscar = "Buscar";
        if (response.data.Success) {
          if (response.data.response.length == 0) {
            self.alertMessage = "No se encontró información del día especificado.";
            self.alertTitle = "Error";
            self.show('top-right', 'error');
          } else {

            debugger; // eslint-disable-line no-debugger
            // var respuesta = response.data.response.Detalle;
            //self.RespuestaCompras = response.data.response.Pedidos;
            self.searchResult = response.data.response;




          }


        } else {
          console.log("error")
          self.alertMessage = response.data.Message;
          self.alertTitle = response.data.Error;
          self.show('top-right', 'error');
        }





        self.loading = false;
      } catch (e) {
        self.loading = false;
        //this.alert = true;
        //this.error = 'Invalid email/password';
      }
    },


    async SetTipoCambioPlazaCheck() {
      debugger; // eslint-disable-line no-debugger
      var self = this;


      const currentDate = moment(); // current date and time
      const targetDate = moment(self.dateOK); // the date you want to compare against

      // Check if targetDate is in the past


      // Check if the target date is today or in the future
      if (!(targetDate.isSameOrAfter(currentDate, 'day'))) {

        self.alertTitle = '';
        self.alertMessage = 'No es posible modificar registros de días anteriores, ' + moment(self.dateOK).locale('es').format('DD MMM YYYY');
        self.show('top-right', 'error');
        return;
      }



      // if (moment(app.date).format('L') == moment().format('L')){

      // }else if (targetDate.isBefore(currentDate)) {

      // } 

      // self.textoBotonBuscar = "Buscando...";
      self.loading = true;


      var continuar = false;

      if (self.remplazar) {


        var swalType = 'question';

        var btnClass = (swalType == 'error') ? 'danger' : swalType;
        btnClass = (swalType == 'question') ? 'primary' : btnClass;

        this.$swal({
          title: 'Confirme',
          text: '¿Desea remplazar el tipo de cambio para todas las estaciones del dia ' + moment(self.dateOK).locale('es').format('DD MMM YYYY') + '?',
          type: swalType,
          showCancelButton: true,
          buttonsStyling: false,
          confirmButtonText: 'Remplazar y aplicar a todas',
          cancelButtonText: 'Cancelar',
          confirmButtonClass: 'btn me-5px btn-' + btnClass + '',
          cancelButtonClass: 'btn btn-default',
        }).then((result) => {
          if (result.isConfirmed) {
            debugger; // eslint-disable-line no-debugger
            continuar = true;
            self.SetTipoCambioPlaza();

          }
        });




      } else {
        continuar = true;
        self.SetTipoCambioPlaza();
      }

      debugger; // eslint-disable-line no-debugger
      if (!continuar) {
        self.loading = false;
        return;
      }




    },

    async SetTipoCambioPlaza() {
      debugger; // eslint-disable-line no-debugger
      var self = this;



      // self.textoBotonBuscar = "Buscando...";
      self.loading = true;





      try {


        var tiposCambioEnviar = [];
        for (var t = 0; t < self.estaciones.length; t++) {
          var tipoCambio = {
            "TipoCambio": parseFloat(self.tipoDeCambioAplicar),
            "Estacion": self.estaciones[t].Estacion
          }
          tiposCambioEnviar.push(tipoCambio);

        }



        var dataToSend = {
          "Data": {
            "Fecha": self.intDate,
            "Ciudad": self.ciudadOK,
            "Usuario": self.usuario,
            "Remplazar": self.remplazar,
            "TC": tiposCambioEnviar
          }
        };

        debugger; // eslint-disable-line no-debugger


        // Corresponsalia/SetTipoCambioPlaza

        const response = await axios.post('Corresponsalia/SetTipoCambioPlaza', dataToSend);

        self.loading = false;
        // self.textoBotonBuscar = "Buscar";
        if (response.data.Success) {
          self.alertTitle = '';
          self.alertMessage = response.data.Message;
          self.show('top-right', 'success');
          self.getEstaciones()
        } else {
          console.log("error")
          self.alertMessage = response.data.Message;
          self.alertTitle = response.data.Error;
          self.show('top-right', 'error');
        }





        self.loading = false;
      } catch (e) {
        self.loading = false;
        self.alertTitle = '';
        self.alertMessage = 'Ocurrió un error';
        self.show('top-right', 'error');

      }
    },

    async search(e) {

      e.preventDefault();
      this.getEstaciones();
    }
  },
  data() {
    return {
      datosModal: {
        TC: 0,
      },
      ciudadOK: 0,
      dateOK: moment().format("YYYY/MM/DD"),
      remplazar: false,
      tipoDeCambioAplicar: 0,
      showHeaderFilter: true,
      RespuestaCompras: [],
      loading: false,
      deshabilitarCombos: false,
      ciudades: [],
      estaciones: [],
      ciudadSelect: 1,
      estacionSelect: 0,
      // date: moment().startOf('month').format("YYYY/MM/DD"),
      date: moment().format("YYYY/MM/DD"),
      intDateEnd: "",
      dateEnd: moment().format("YYYY/MM/DD"),
      intDate: "",
      options: {
        format: 'YYYY/MM/DD',
        useCurrent: false,
      },
      pageUserSettings: {},
      alertMessage: "",
      alertTitle: "",
      searchResult: [],
      textoBotonBuscar: "Buscar",

    };
  }
}
</script>
<style scoped>
.dx-datagrid-header-panel {
  display: none !important;
}

.loading-search {
  position: absolute;
  width: 100%;
  left: 0px;
  height: 100%;
  background: rgba(0, 0, 0, 0.1);
  z-index: 1000;
  padding-top: 150px;
  top: 0;
}

.sumatoriasRow {
  background-color: #bbb;
}

.currencyRow {
  text-align: right !important;
}

.vgt-fixed-header {
  overflow-y: auto;
}

.vgt-table__header {
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: #f5f5f5;
}
</style>