<template>
  <div>
    <ol class="breadcrumb float-xl-end">
      <li class="breadcrumb-item"><a href="javascript:;">Facturación</a></li>
      <li class="breadcrumb-item active">Servicios Administrativos</li>
    </ol>

    <h1 class="page-header">
      Facturación <small>Servicios Administrativos</small>
    </h1>

    <div class="row">
      <div class="col-xl-6">
        <panel title="Emisor" bodyClass="p-0">
          <form class="form-horizontal form-bordered">
            <div class="form-group row">
              <label class="col-md-4 col-form-label">Seleccionar RFC</label>
              <div class="col-md-8">
                <select class="form-select" name="" id="" v-model="emisorSeleccionado">
                  <option :value="emisor.RFC" v-for="emisor in Emisores" :key="emisor.RFC">{{emisor.RFC}}</option>
                </select>
              </div>

              <label class="col-md-4 col-form-label">Nombre</label>
              <div class="col-md-8">
                <label for="">{{ emisorMostrar.Nombre }}</label>
              </div>
              <!-- <label class="col-md-4 col-form-label">Certificado</label>
              <div class="col-md-8">
                <label for="">{{ emisorMostrar.Certificado }}</label>
              </div> -->
              <!-- <label class="col-md-4 col-form-label">Regimen</label>
              <div class="col-md-8">
                <label for="">{{ emisorMostrar.Regimen }}</label>
              </div> -->
              <label class="col-md-4 col-form-label">Serie</label>
              <div class="col-md-8">
                <label for="">{{ emisorMostrar.Serie }}</label>
              </div>
              
              <label class="col-md-4 col-form-label">Método de pago</label>
              <div class="col-md-8">
               
                <select class="form-select" name="" id="" v-model="jsonTimbrar.MetodoPago">
                  <option value="PUE">Pago en una sola exhibición</option>
                  <option value="PPD">Pago en parcialidades o diferido</option>
                  
                  <!-- <option :value="formaPago.ClaveSAT" v-for="formaPago in formasPago" :key="formaPago.ClaveSAT">{{formaPago.Descripcion}}</option> -->
                </select>
              </div>

              <label class="col-md-4 col-form-label">Forma de pago</label>
              <div class="col-md-8">
               
                <select class="form-select" name="" id="" v-model="jsonTimbrar.FormaPago">
                  <!-- <option value="03">Transferencia electronica de fondos</option> -->
                  <option :value="formaPago.ClaveSAT" v-for="formaPago in formasPago" :key="formaPago.ClaveSAT">{{formaPago.Descripcion}}</option>
                </select>
              </div>





              
              <label class="col-md-4 col-form-label">Moneda</label>
              <div class="col-md-4">
               
                <select class="form-select" name="" id="" v-model="jsonTimbrar.Moneda">
                  <!-- <option value="03">Transferencia electronica de fondos</option> -->
                  <option :value="moneda.moneda" v-for="moneda in tiposMoneda" :key="moneda.moneda">{{moneda.moneda}}</option>
                </select>
              </div>
              <div class="col-md-4">
               
                
                <input type="number" v-if="jsonTimbrar.Moneda != 'MXN'" step="any" class="form-control" v-model="jsonTimbrar.TipoCambio" placeholder="Tipo de Cambio">
              </div>





            </div>
          </form>
          <div class="hljs-wrapper" slot="outsideBody" v-highlight></div>
        </panel>
      </div>

      <div class="col-xl-6">
        <panel title="Receptor" bodyClass="p-0">
          <form class="form-horizontal form-bordered">
            <div class="form-group row">
              <label class="col-md-4 col-form-label">Seleccionar RFC</label>
              <div class="col-md-8">
                <!-- <v-select
                  :options="ClientesSelect"
                  v-model="clienteSeleccionado"
                ></v-select> -->
                
                <select class="form-select" name="" id="" v-model="clienteSeleccionado">
                  <option :value="cliente.RFC" v-for="cliente in Clientes" :key="cliente.RFC">{{cliente.RFC}}</option>
                </select>
              </div>

              <label class="col-md-4 col-form-label">Nombre</label>
              <div class="col-md-8">
                <label for="">{{ clienteMostrar.Nombre }}</label>
              </div>

              <label class="col-md-4 col-form-label">Codigo Postal</label>
              <div class="col-md-8">
                <label for="">{{ clienteMostrar.CodigoPostal }}</label>
              </div>

              <label class="col-md-4 col-form-label">Regimen Fiscal</label>
              <div class="col-md-8">
                <label for="">{{ clienteMostrar.RegimenFiscal }}</label>
              </div>

              
              <label class="col-md-4 col-form-label">Correo</label>
              <div class="col-md-8">
                <!-- <label v-for="correo in  clienteMostrar.Correos" :key="correo">{{correo}}, </label> -->
                <input type="text" class="form-control" v-model="jsonTimbrar.Receptor.Correo">
              </div>
              

              
              <label class="col-md-4 col-form-label">Uso CFDI</label>
              <div class="col-md-8">
                <!-- <v-select
                  :options="UsosCFDI"
                  v-model="usoCfdiSeleccionado"
                ></v-select> -->
                <select name="" id="ddlUsoCFDI" class="form-control" v-model="jsonTimbrar.Receptor.UsoCFDI">
                <option :value="uso.Id" v-for="uso in usosCFDI" :key="uso.Id">
                  {{uso.Descripcion}}
                </option>
              </select>
              </div>

            </div>
          </form>
          <div class="hljs-wrapper" slot="outsideBody" v-highlight></div>
        </panel>
      </div>
    </div>


    
    <div class="row">
      <div class="col-xl-6">
        <panel title="Conceptos" bodyClass="">
            <fieldset>
                <!-- <legend class="mb-3">Legend</legend> -->
                <div class="row mb-4">
                    <label class="form-label col-form-label col-md-4">Clave Producto</label>
                    <div class="col-md-8">
                        <input type="text" class="form-control" placeholder="Clave Producto" v-model="jsonTimbrar.Conceptos[0].ClaveProdServ"/>
                    </div>
                </div>
                <div class="row mb-4">
                    <label class="form-label col-form-label col-md-4">Clave de unidad</label>
                    <div class="col-md-8">
                        <input type="text" class="form-control" placeholder="Clave de unidad"  v-model="jsonTimbrar.Conceptos[0].ClaveUnidad"/>
                    </div>
                </div>

                
                <div class="row mb-4">
                    <label class="form-label col-form-label col-md-4">Cantidad</label>
                    <div class="col-md-8">
                        <input type="text" class="form-control" placeholder="Cantidad" v-model="conceptoCantidad" />
                    </div>
                </div>
                <div class="row mb-4">
                    <label class="form-label col-form-label col-md-4">Descripción</label>
                    <div class="col-md-8">
                        <input type="text" class="form-control" placeholder="Descripcion" v-model="jsonTimbrar.Conceptos[0].Descripcion" />
                    </div>
                </div>
                <div class="row mb-4">
                    <label class="form-label col-form-label col-md-4">Valor Unitario</label>
                    <div class="col-md-8">
                        <input type="number" step="any" min="0" class="form-control" placeholder="Valor Unitario" v-model="valorUnitario" />
                    </div>
                </div>
                <div class="row mb-4">
                    <label class="form-label col-form-label col-md-4">Tasa IVA</label>
                    <div class="col-md-8">
                        <v-select
                            :options="TasasIva"
                            v-model="tasaIvaSeleccionado"
                        ></v-select>
                    </div>
                </div>
                
                <div class="row mb-4">
                    <label class="form-label col-form-label col-md-4">Cuenta Predial</label>
                    <div class="col-md-8">
                        <input type="text" class="form-control" placeholder="Cuenta Predial" v-model="cuentaPredial" />
                    </div>
                </div>
                
            </fieldset>
        </panel>
      </div>

      <div class="col-xl-6">
        <panel title="Totales" bodyClass="p-0">
          <form class="form-horizontal form-bordered">
            <div class="form-group row">

              <label class="col-md-4 col-form-label">Subtotal</label>
              <div class="col-md-8">
                <label for="">${{subtotal}}</label>
              </div>

              <label class="col-md-4 col-form-label">IVA</label>
              <div class="col-md-8">
                <label for="">${{ivaTotal}}</label>
              </div>

              <label class="col-md-4 col-form-label">Total</label>
              <div class="col-md-8">
                <label for="">${{ total }}</label>
              </div>
              <!-- <div class="col-md-12">
                {{jsonTimbrarString}}
              </div> -->


              

            </div>
          </form>
          
          <div class="hljs-wrapper" slot="outsideBody" v-highlight></div>
        </panel>
        
                <div class="row">
                    <div class="col-md-8 offset-md-4 text-center">
                        <button type="button" @click="facturar" class="btn btn-primary w-100px me-5px">Facturar</button>
                        <!-- <button type="button" class="btn btn-default w-100px ">Cancel</button> -->
                    </div>
                </div>
                <div class="row" style="margin-top: 15px;" v-if="facturado">
                    <div class="col-md-4 offset-md-4 text-center">
                        <button type="button" @click="descargarPDF" class="btn btn-primary w-100px me-5px">PDF</button>
                    </div>
                    <div class="col-md-4 text-center">
                        <button type="button" @click="descargarXML" class="btn btn-primary w-100px me-5px">XML</button>
                    </div>
                </div>
      </div>
    </div>
    
    <!-- bottom left vue-notification -->
    <notifications group="top-right" position="top right" :speed="1000" />
  </div>
</template>

<script>
import axios from "axios";
export default {
  mounted: function () {
    this.GetRegimenFiscales();
    this.GetUsosCFDI();
    this.GetFormasDePago();
    debugger; // eslint-disable-line no-debugger
		var userSetting = JSON.parse(localStorage.getItem('userSettings'));
    this.jsonTimbrar.Usuario = userSetting.Usuario;
  },
  data() {
    return {
      cuentaPredial: 0,
      facturado: false,
      pdfBase64Resend: undefined,
      xmlResend: undefined,
      alertMessage:"",
      alertTitle:"",
      jsonTimbrarString:"",
      usuario:"",
      subtotal:"",
      ivaTotal:"",
      total:"",
      formasPago:[],
      tiposMoneda:[
        {"moneda":"MXN"},
        {"moneda":"USD"},
      ],
      formaPagoSeleccionada: "03",
      clienteMostrarCorreo: "aurora.rubio@rendilitros.com",
      jsonTimbrar:{
        "tipoComprobante": "I",
        "Serie": "A",
        "RFCEmisor": "",
        "FormaPago": "03",
        "MetodoPago": "PUE",
        "Usuario": "",
        "Moneda":"MXN",
        "TipoCambio":0,
        "Receptor": {
          "RFC": "",
          "Nombre": "",
          "UsoCFDI": "G03",
          "CodigoPostal": "",
          "RegimenFiscal": "",
          "Correo": "aurora.rubio@rendilitros.com"
        },
        "Conceptos": [
          {
            "ClaveProdServ": "80101500",
            "ClaveUnidad": "E48",
            "Descripcion": "Servicios administrativos",
            "NoIdentificacion": "",
            "ObjetoImp": "02",
            "Cantidad": "1.00",
            "ValorUnitario": 0,
            "Importe": 0,
            "TasaIVA": ".080000"
          }
        ]
      },
      conceptoClaveProducto: "801101500",
      conceptoClaveUnidad: "E48",
      conceptoCantidad: 1,
      conceptoDescripcion: "",
      valorUnitario: undefined,
      usosCFDI:[],
      regimenesFiscales:[],
      emisorSeleccionado: "",
      clienteSeleccionado: "",
      tasaIvaSeleccionado:".080000",
      emisorMostrar: {
        RFC: "",
        Nombre: "",
        Certificado: "",
        Regimen: "",
        Serie: "",
      },
      clienteMostrar: {
        RFC: "",
        Nombre: "",
        Correo: "",
        Direccion: "",
      },
      EmisoresSelect: ["EPI0103167Z2", "PRO020807JB7","CYO140801T84","CKI140801L82"],
      ClientesSelect: ["SYA620911QY8", "SYA980610FH6"],
      UsosCFDI: ["Gastos Generales", "Adquisición de mercancias"],
      TasasIva: [".160000", ".080000"],
      Emisores: [
        {
          RFC: "EPI0103167Z2",
          Nombre: "ESTACION PIRU SA DE CV",
          Certificado: "00001000000502247540",
          Regimen: "General de Ley Personas Morales",
          Serie: "B",
        },
        {
          RFC: "PRO020807JB7",
          Nombre: "PRONTOGAS SA DE CV",
          Certificado: "00001000000408280487",
          Regimen: "General de Ley Personas Morales",
          Serie: "C",
        },
        {
          RFC : 'CYO140801T84',
          Nombre : 'CIRCULO YOREME',
          Certificado : '00001000000503584283',
          Regimen : 'General de Ley Personas Morales',
          Serie : 'D'
        },
        {
          RFC : 'CKI140801L82',
          Nombre : 'CIRCULO KILIWA',
          Certificado : '00001000000414911525',
          Regimen : 'General de Ley Personas Morales',
          Serie : 'E'
        },
        {
          RFC : 'OTC130221QU8',
          Nombre : 'OPERADORA TIERRA CALIDA',
          Certificado : '00001000000503662053',
          Regimen : 'General de Ley Personas Morales',
          Serie : 'F'
        },

        {
          RFC : 'DPD221013FS7',
          Nombre : 'DM PROYECTOS Y DESARROLLOS INMOBILIARIOS DE LA BAJA',
          Certificado : '00001000000516275019',
          Regimen : 'General de Ley Personas Morales',
          Serie : 'G'
        },
        {
          RFC : 'RNO161115CB1',
          Nombre : 'RENDIFUEL DEL NORTE',
          Certificado : '00001000000703946219',
          Regimen : 'General de Ley Personas Morales',
          Serie : 'H'
        }
      ],
      Clientes: [],
      colorpicker: {
        color1: "rgb(135,83,222,0.8)",
        color2: "rgb(52,143,226)",
      },
      sliderDefault: {
        value: 9,
        max: 25,
      },
      sliderLabel: {
        value: "2018-10-08",
        width: "80%",
        tooltip: "always",
        disabled: false,
        piecewise: true,
        piecewiseLabel: true,
        style: {
          marginLeft: "10%",
        },
        data: ["2016-10-05", "2016-10-06", "2016-10-07"],
      },
      sliderInterval: {
        lazy: true,
        interval: 5,
        max: 25,
        piecewise: true,
      },
      sliderFormatter: {
        value: [0, 96],
        min: 0,
        max: 500,
        disabled: false,
        show: true,
        useKeyboard: true,
        tooltip: "always",
        formatter: "¥{value}",
        mergeFormatter: "¥{value1} ~ ¥{value2}",
      },
      sliderFixedRange: {
        value: [45, 65],
        min: 0,
        max: 100,
        interval: 1,
        fixed: true,
        processDragable: true,
      },
      tagsArray: ["tags1", "tags2"],
      date: new Date(),
      options: {
        format: "DD/MM/YYYY",
        useCurrent: false,
      },
      form: {
        time: null,
        startDate: null,
        endDate: null,
      },
      configs: {
        timePicker: {
          format: "LT",
          useCurrent: false,
        },
        start: {
          format: "DD/MM/YYYY",
          useCurrent: false,
          showClear: true,
          showClose: true,
          minDate: new Date(),
          maxDate: false,
        },
        end: {
          format: "DD/MM/YYYY",
          useCurrent: false,
          showClear: true,
          showClose: true,
          minDate: new Date(),
        },
      },
    };
  },
  watch: {
    emisorSeleccionado(rfc) {
      // var self= this;
      this.getEmisor(rfc);
      this.GetClientesServAdministrativos();
      //   debugger; // eslint-disable-line no-debugger
    },
    clienteSeleccionado(rfc) {
      this.getCliente(rfc);
      //   debugger; // eslint-disable-line no-debugger
    },
    valorUnitario(value){
      this.calcular();
      this.jsonTimbrar.Conceptos[0].ValorUnitario = (parseFloat(value)).toFixed(2);
      
    },
    tasaIvaSeleccionado(value){
      this.calcular();
      this.jsonTimbrar.Conceptos[0].TasaIVA = value;
    },
    conceptoCantidad(value){
      this.calcular();
      this.jsonTimbrar.Conceptos[0].Cantidad = (parseFloat(value)).toFixed(2);
    }
  },
methods: {
    descargarPDF() {
      
        debugger; // eslint-disable-line no-debugger
      var self = this;
        var filename = "Factura_Electronica.pdf";
        // The actual download
        var link = document.createElement("a");
        //link.href = window.URL.createObjectURL(blob);
        link.href = "data:application/pdf;base64," + self.pdfBase64Resend;
        link.download = filename;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    },
    descargarXML() {
      
        debugger; // eslint-disable-line no-debugger
        var self = this;
        var xmltext = self.xmlResend;

        var filename = "Factura_Electronica.xml";
        var pom = document.createElement("a");
        var bb = new Blob([xmltext], { type: "text/plain" });

        pom.setAttribute("href", window.URL.createObjectURL(bb));
        pom.setAttribute("download", filename);

        pom.dataset.downloadurl = ["text/plain", pom.download, pom.href].join(
            ":"
        );
        pom.draggable = true;
        pom.classList.add("dragout");
        pom.click();
    },
    calcular(){
      this.subtotal = (this.conceptoCantidad*this.valorUnitario).toFixed(2);
      this.ivaTotal = (parseFloat(this.subtotal)*parseFloat(this.tasaIvaSeleccionado)).toFixed(2);
      this.total = (parseFloat(this.subtotal)+parseFloat(this.ivaTotal)).toFixed(2);
    },
    async facturar(){
      var self = this;
      self.jsonTimbrar.Conceptos[0].Importe = self.subtotal;
      self.jsonTimbrar.Conceptos[0].CuentaPredial = self.cuentaPredial;
      self.jsonTimbrarString = JSON.stringify(self.jsonTimbrar);
      console.log(self.jsonTimbrarString);
        debugger; // eslint-disable-line no-debugger
      try {
        const response = await axios.post("CFDI/TimbrarSA", {
          Data: self.jsonTimbrar
        });
        if (response.data.Success) {
          
        debugger; // eslint-disable-line no-debugger
          if (response.data.response != undefined) {
            self.alertMessage = "Se timbró la factura exitosamente";
            self.alertTitle = "Timbrado exitoso";
            self.show("top-right", "success");


            self.pdfBase64Resend = response.data.response.PdfBase64;
            self.xmlResend = response.data.response.Xml;

            self.facturado = true;
            self.clienteSeleccionado = undefined;
            self.emisorSeleccionado = undefined;

          } else {
            self.alertMessage = "Error al timbrar";
            self.alertTitle = "Error";
            self.show("top-right", "error");
            
            self.facturado = false;
          }
        } else {
          
        debugger; // eslint-disable-line no-debugger
          self.alertMessage = response.data.Error;
          self.alertTitle = "Error";
          self.show("top-right", "error");
            self.facturado = false;
        }
      } catch (e) {
        
        debugger; // eslint-disable-line no-debugger
        self.alertMessage = "Ocurrio un error";
        self.alertTitle = "Error";
        self.show("top-right", "error");
            self.facturado = false;
      }
    },
    async GetFormasDePago(){
      try {
        const response = await axios.get("Catalogos/GetFormasPago?aplicaEstacion=0");
        if (response.data.Success) {
          if (response.data.response.length == 0) {
            this.alertMessage =
              "No se encontró formas de pago";
            this.alertTitle = "Error";
            this.show("top-right", "error");
          } else {
            this.formasPago = response.data.response;
          }
        } else {
          this.alertMessage = response.data.Error;
          this.alertTitle = "Error";
          this.show("top-right", "error");
        }
      } catch (e) {
        this.alertMessage = "Ocurrio un error";
        this.alertTitle = "Error";
        this.show("top-right", "error");
      }
    },
    async GetClientesServAdministrativos(){
      var emisorSeleccionado = this.emisorSeleccionado;
        debugger; // eslint-disable-line no-debugger

      try {
        const response = await axios.post("Cliente/GetClientesServAdministrativos", {
          Data: {
            Busqueda: emisorSeleccionado
          },
        });
        if (response.data.Success) {
          if (response.data.response.length == 0) {
            this.alertMessage =
              "No se encontró clientes";
            this.alertTitle = "Error";
            this.show("top-right", "error");
          } else {
            this.Clientes = response.data.response;
          }
        } else {
          this.alertMessage = response.data.Error;
          this.alertTitle = "Error";
          this.show("top-right", "error");
        }
      } catch (e) {
        this.alertMessage = "Ocurrio un error";
        this.alertTitle = "Error";
        this.show("top-right", "error");
      }
    },
    async GetRegimenFiscales(){
      try {
        const response = await axios.get("Catalogos/GetRegimenFiscales");
        if (response.data.Success) {
          if (response.data.response.length == 0) {
            this.alertMessage =
              "No se encontró usos CFDI";
            this.alertTitle = "Error";
            this.show("top-right", "error");
          } else {
            this.regimenesFiscales = response.data.response;
          }
        } else {
          this.alertMessage = response.data.Error;
          this.alertTitle = "Error";
          this.show("top-right", "error");
        }
      } catch (e) {
        this.alertMessage = "Ocurrio un error";
        this.alertTitle = "Error";
        this.show("top-right", "error");
      }
    },
    async GetUsosCFDI(){
      try {
        const response = await axios.get("Catalogos/GetUsosCFDI");
        if (response.data.Success) {
          if (response.data.response.length == 0) {
            this.alertMessage =
              "No se encontró usos CFDI";
            this.alertTitle = "Error";
            this.show("top-right", "error");
          } else {
            this.usosCFDI = response.data.response;
            this.usoCfdiSeleccionado = "G03";
          }
        } else {
          this.alertMessage = response.data.Error;
          this.alertTitle = "Error";
          this.show("top-right", "error");
        }
      } catch (e) {
        this.alertMessage = "Ocurrio un error";
        this.alertTitle = "Error";
        this.show("top-right", "error");
      }
    },
    getCliente(rfc) {
        debugger; // eslint-disable-line no-debugger
      if (rfc != null) {
        for (var n = 0; n < this.Clientes.length; n++) {
          if (this.Clientes[n].RFC == rfc) {
            this.clienteMostrar = this.Clientes[n];
            this.jsonTimbrar.Receptor.RFC = this.clienteMostrar.RFC;
            this.jsonTimbrar.Receptor.Nombre = this.clienteMostrar.Nombre;
            //this.jsonTimbrar.Receptor.UsoCFDI = this.clienteMostrar.UsoCFDI;
            this.jsonTimbrar.Receptor.CodigoPostal = this.clienteMostrar.CodigoPostal;
            this.jsonTimbrar.Receptor.RegimenFiscal = this.clienteMostrar.RegimenFiscal;


            console.log(JSON.stringify(this.clienteMostrar));
          }
        }
      } else {
        this.clienteMostrar = {
            RFC: "",
            Nombre: "",
            Correo: "",
            Direccion: "",
        };
      }
    },
    getEmisor(rfc) {
      //   debugger; // eslint-disable-line no-debugger
      if (rfc != null) {
        for (var n = 0; n < this.Emisores.length; n++) {
          if (this.Emisores[n].RFC == rfc) {
            this.emisorMostrar = this.Emisores[n];
            
            this.jsonTimbrar.Serie = this.emisorMostrar.Serie;

          }
        }
        this.jsonTimbrar.RFCEmisor = rfc;
      } else {
        this.emisorMostrar = {
          RFC: "",
          Nombre: "",
          Certificado: "",
          Regimen: "",
        };
      }
    },
    onStartChange(e) {
      this.$set(this.configs.end, "minDate", e.date || null);
    },
    onEndChange(e) {
      this.$set(this.configs.start, "maxDate", e.date || null);
    },
    show (group, type = '') {
      const text = this.alertMessage;
      this.$notify({
        group,
        title: this.alertTitle,
        text,
        type,
        data: {
          randomNumber: Math.random()
        }
      })
    },
    clean (group) {
      this.$notify({ group, clean: true })
    },
    swalNotification(swalType) {
      var btnClass = (swalType == 'error') ? 'danger' : swalType;
        btnClass = (swalType == 'question') ? 'primary' : btnClass;
      this.$swal({
        title: 'Are you sure?',
        text: 'You will not be able to recover this imaginary file!',
        type: swalType,
        showCancelButton: true,
        buttonsStyling: false,
        confirmButtonText: swalType.charAt(0).toUpperCase() + swalType.substr(1).toLowerCase(),
        cancelButtonText: 'Cancel',
        confirmButtonClass: 'btn me-5px btn-'+ btnClass +'',
        cancelButtonClass: 'btn btn-default',
      })
    },
    showNotification(title,text,type){
      this.alertTitle = title;
      this.alertMessage = text;
      this.show("top-right", type);
    },
  },
};
</script>