<template>
  <div>

    <div class=" loading-search" v-if="loading">
      <div class="fa-3x text-center">
        <i class="fas fa-circle-notch fa-spin text-success" style=""></i>
      </div>
    </div>
    <!-- BEGIN breadcrumb -->
    <ol class="breadcrumb float-xl-end">
      <li class="breadcrumb-item"><a href="javascript:;">Reportes</a></li>
      <!-- <li class="breadcrumb-item"><a href="javascript:;">Fajillas</a></li> -->
      <li class="breadcrumb-item active"><a href="javascript:;">Acuses</a></li>
    </ol>
    <!-- END breadcrumb -->
    <!-- BEGIN page-header -->
    <h1 class="page-header">Consulta Acuses <small></small></h1>
    <!-- <button class="btn" v-on:click="redirectTanques()">tanques</button> -->
    <!-- END page-header -->
    <!-- <a href="javascript:;" v-on:click="show('top-right', 'warn')" class="btn btn-sm btn-primary">Demo</a> -->



    <form @submit.prevent="search">
      <div class="row">
        <div class="col-md-8">
          <div class="form-group row">

            <div class="col-md-3">
              <label class="col-form-label">Fecha inicial</label>
              <div class="input-group">
                <date-picker id="dateTimeInput" v-model="date" :config="options"></date-picker>
                <label for="dateTimeInput" class="input-group-text">
                  <i class="fa fa-calendar"></i>
                </label>
              </div>
            </div>


            <div class="col-md-3">
              <label class="col-form-label">Fecha final</label>
              <div class="input-group">
                <date-picker id="dateTimeInputEnd" v-model="dateEnd" :config="options"></date-picker>
                <label for="dateTimeInputEnd" class="input-group-text">
                  <i class="fa fa-calendar"></i>
                </label>
              </div>
            </div>
            <div class="col-md-3">
              <label class="col-form-label">Ciudad</label>
              <select class="form-select" @change="getEstaciones()" v-model="ciudadSelect"
                :disabled="deshabilitarCombos">
                <option v-for="ciudad in ciudades" :value="ciudad.Id" v-bind:key="ciudad.Id">
                  {{ ciudad.Nombre }}
                </option>
              </select>
            </div>
            <div class="col-md-3">
              <label class="col-form-label">Estación</label>
              <select class="form-select" v-model="estacionSelect" :disabled="deshabilitarCombos">
                <option value="0">Todas</option>
                <option v-for="estacion in estaciones" :value="estacion.NoEstacion" v-bind:key="estacion.NoEstacion">
                  {{ estacion.Nombre }}
                </option>
              </select>
            </div>


            <!-- <div class="col-md-6">
                      <button type="submit" class="btn btn-success me-1 mb-1">{{textoBotonBuscar}}</button>
                    </div> -->
          </div>
        </div>
        <div class="col-md-4">
          <button type="submit" class="btn btn-success me-1 mb-1" style="margin-top: 3.1em;">{{ textoBotonBuscar
            }}</button>
        </div>
      </div>

    </form>
    <DxDataGrid id="gridContainer" :data-source="reporteCre" key-expr="Id" :show-borders="true" @exporting="onExporting"
      @row-validating="true" @editor-preparing="true" :allow-column-resizing="true">

      <DxScrolling column-rendering-mode="virtual" />
      <DxFilterRow />
      <DxHeaderFilter :visible="showHeaderFilter" />
      <DxSearchPanel :visible="true" :width="240" placeholder="Search..." />

      <DxGroupPanel :visible="true" />
      <DxGrouping :auto-expand-all="true" />
      <DxExport :enabled="true" :allow-export-selected-data="false" />
      <DxSelection mode="false" />

      <DxPaging :enabled="false" />
      <!-- <DxEditing
        :allow-updating="true"
        :use-icons="true"
        mode="row"
      /> -->
      <!-- <DxColumn
        type="buttons"
        :width="110"
      >
        <DxButton
          hint="Autorizar"
          icon="copy"
          @click="cloneIconClick"
        />
      </DxColumn> -->
      <DxColumn data-field="Id" width="50">
        <DxHeaderFilter :allow-search="true" />
      </DxColumn>
      <!-- <DxColumn data-field="Id"/> -->

      <DxColumn data-field="NoEstacion" width="80" />
      <DxColumn data-field="Estacion" />
      <DxColumn data-field="Fecha" />
      <DxColumn data-field="PermisoCRE" />
      <DxColumn data-field="Nombre" />
      <DxColumn data-field="Estatus"  width="80" cell-template="estatusLabel" />

      <template #estatusLabel="{ data }">
        <div>
          <i v-if="data.data.Estatus == 1" class="fas fa-lg fa-fw me-10px fa-check iconColorCheck" :data-estatus="data"></i>
          <i v-if="data.data.Estatus == 0" class="fas fa-lg fa-fw me-10px fa-times iconColor" :data-estatus="data"></i> 
        </div>
      </template>

      <DxColumn data-field="FechaCarga" />
      <DxColumn data-field="Turno" />
      <DxColumn data-field="Usuario" />

      <!-- <DxColumn dataField="Abrir" caption="Abrir" :cellTemplate="obtenerAcusePDF" /> -->

      <DxColumn caption="Acciones" cell-template="actions" :width="120" search="false" filter="false" sorting="false" />

      <template #actions="{ data }">
        <div v-if="data.data.Estatus != 0">
          <i class="fas fa-lg fa-fw  fa-file-pdf iconColor btn" v-on:click="obtenerAcusePDFCall(data.data)"
            title="Abrir"></i>
          &nbsp;
          <i class="far fa-lg fa-fw fa fa-ban btn iconColor" v-if="IdRol == 1 || IdRol == 7 || IdRol == 23" v-on:click="CancelarAcuse(data.data)" title="Cancelar"></i>
        </div>
      </template>
      <!-- <template #estatus-cell-template="{ data }">
        <EstatusCell :estatus-data="data"/>
      </template> -->
    </DxDataGrid>
    <b-modal id="modalDialogVisor" size="xl" centered cancel-variant="default">
      <template slot="modal-header">
        <h4 class="modal-title">Visualizando Acuse</h4>
        <a class="btn-close" @click="$bvModal.hide('modalDialogVisor')"></a>
      </template>
      <div class="card">
        <object type="application/pdf" :data="Archivo.base64" width="100%" height="600px"></object>
      </div>

      <template #modal-footer>
        <div class="row">
          <div class="col">
            <b-button class="float-right" variant="primary" @click="$bvModal.hide('modalDialogVisor')">
              Close
            </b-button>
          </div>
        </div>
      </template>

    </b-modal>

    <!-- bottom left vue-notification -->
    <notifications group="top-right" position="top right" :speed="1000" />
  </div>
</template>

<script>

import axios from 'axios';
import moment from 'moment';
import {
  DxDataGrid,
  DxColumn,
  DxExport,
  DxSelection,
  DxGroupPanel,
  DxGrouping,
  DxHeaderFilter, DxFilterRow, DxSearchPanel,
  DxScrolling,
  DxPaging
} from 'devextreme-vue/data-grid';
import { Workbook } from 'exceljs';
import { saveAs } from 'file-saver';

// import EstatusCell from './EstatusCell.vue';
// Our demo infrastructure requires us to use 'file-saver-es'. We recommend that you use the official 'file-saver' package in your applications.
import { exportDataGrid } from 'devextreme/excel_exporter';
//import service from './data.js';

export default {
  mounted: function () {
    //debugger; // eslint-disable-line no-debugger
    var userSetting = JSON.parse(localStorage.getItem('userSettings'));
    this.usuario = userSetting.Usuario;
    this.usuarioEstacion = userSetting.Estacion;
    this.IdRol = userSetting.IdRol;
    this.getCiudades();
    this.ciudadSelect = userSetting.CiudadId;
    this.getEstaciones();
    this.estacionSelect = userSetting.Estacion;

    if (this.IdRol == 3 || this.IdRol == 7) {
      this.deshabilitarCombos = true;
    }




  },
  beforeCreate() {
    // var userSetting = localStorage.getItem('userSettings');
    // if(userSetting != undefined){
    //   console.log(JSON.parse(userSetting));
    //   this.pageUserSettings = JSON.parse(userSetting);
    // }else{
    // 	this.$router.push('/login');
    // }
  },
  created() {
    // console.log('created');
  },
  beforeMount() {
    // console.log('beforeMount');
  },
  components: {
    DxDataGrid, DxColumn, DxExport, DxSelection, DxGroupPanel, DxGrouping, DxFilterRow, DxHeaderFilter, DxSearchPanel, DxScrolling, DxPaging
    // , EstatusCell
  },
  methods: {
    descargarPDF(pdfBase64, filename) {
      //debugger; // eslint-disable-line no-debugger

      // var filename = "Factura_Electronica.pdf";
      // The actual download
      var link = document.createElement('a');
      //link.href = window.URL.createObjectURL(blob);
      link.href = "data:application/pdf;base64," + pdfBase64;
      link.download = filename + ".pdf";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    obtenerAcusePDF(cellElement, cellInfo) {

      const button = document.createElement("button");
      button.innerText = "Descargar PDF";
      button.className = "btn btn-sm btn-success";
      button.addEventListener("click", () => {

        this.handleButtonClick(cellInfo.data);
      });

      cellElement.appendChild(button);
    },
    handleButtonClick(data) {
      //debugger; // eslint-disable-line no-debugger

      //   this.clienteModalEstatus = data.Estatus == 'Active'? true:false;
      this.obtenerAcusePDFCall(data)

    },

    async obtenerAcusePDFCall(data) {
      //api/Precio/SetAcusePrecio

      var self = this;
      // self.textoBotonEnviar = "Enviando...";
      self.loading = true;
      self.Archivo = [];
      try {
        // const segments = data.Path.split('/');
        // Get the last element of the array, which is the filename
        // const fileName = segments[segments.length - 1];

        //debugger; // eslint-disable-line no-debugger
        var dataToSend = {
          "Data": {
            "Path": data.Path,
            "Usuario": self.usuario
            // "Path": "CRE/ACUSES/2023/10/23/PL3988EXPES2015.pdf", 
            // "Usuario": "juan.garcia"
          },
        };

        //debugger; // eslint-disable-line no-debugger
        const response = await axios.post("Precio/GetAcusePDF", dataToSend);

        self.loading = false;
        // self.textoBotonEnviar = "Enviar";
        //debugger; // eslint-disable-line no-debugger
        if (response.data.Success) {

         // this.alertMessage = response.data.Message;
          this.alertTitle = "";
          //this.show("top-right", "success");

          self.Archivo.base64 = "data:application/pdf;base64," + response.data.response;

          self.$bvModal.show('modalDialogVisor');


          //self.descargarPDF(response.data.response, data.Nombre);
        }
        else {

          this.alertMessage = response.data.Message;
          this.alertTitle = "Error";
          this.show("top-right", "error");
        }

      } catch (e) {
        self.loading = false;
        self.textoBotonEnviar = "Enviar";
        this.alertMessage = "Ocurrio un error";
        this.alertTitle = "Error";
        this.show("top-right", "error");
      }
    },
    async getCiudades() {
      try {
        const response = await axios.get('Catalogos/GetCiudades');
        //debugger; // eslint-disable-line no-debugger
        if (response.data.Success) {
          if (response.data.response.length == 0) {
            this.alertMessage = "No se encontró información.";
            this.alertTitle = "Error";
            this.show('top-right', 'error');
          }
        } else {
          console.log("error")
          this.alertMessage = response.data.Error;
          this.alertTitle = "Error";
          this.show('top-right', 'error');
        }
        this.ciudades = response.data.response;
      } catch (e) {
        //this.alert = true;
        //this.error = 'Invalid email/password';
      }
    },
    async getEstaciones() {
      try {
        //debugger; // eslint-disable-line no-debugger
        const response = await axios.post('Estacion/GetEstaciones?ciudad=' + this.ciudadSelect);
        if (response.data.Success) {
          if (response.data.response.length == 0) {
            this.alertMessage = "No se encontró información.";
            this.alertTitle = "Error";
            this.show('top-right', 'error');
          }
        } else {
          console.log("error")
          this.alertMessage = response.data.Error;
          this.alertTitle = "Error";
          this.show('top-right', 'error');
        }
        this.estaciones = response.data.response;
        this.productos = [];
        this.getProductos();
      } catch (e) {
        //this.alert = true;
        //this.error = 'Invalid email/password';
      }
    },
    redirectTanques() {

      this.$router.push({ path: '/tanques' });
    },
    show(group, type = '') {
      const text = this.alertMessage;
      this.$notify({
        group,
        title: this.alertTitle,
        text,
        type,
        data: {
          randomNumber: Math.random()
        }
      })
    },
    clean(group) {
      this.$notify({ group, clean: true })
    },
    swalNotification(swalType) {
      var btnClass = (swalType == 'error') ? 'danger' : swalType;
      btnClass = (swalType == 'question') ? 'primary' : btnClass;
      this.$swal({
        title: 'Are you sure?',
        text: 'You will not be able to recover this imaginary file!',
        type: swalType,
        showCancelButton: true,
        buttonsStyling: false,
        confirmButtonText: swalType.charAt(0).toUpperCase() + swalType.substr(1).toLowerCase(),
        cancelButtonText: 'Cancel',
        confirmButtonClass: 'btn me-5px btn-' + btnClass + '',
        cancelButtonClass: 'btn btn-default',
      })
    },
    onExporting(e) {
      const workbook = new Workbook();
      const worksheet = workbook.addWorksheet('Acuses');

      exportDataGrid({
        component: e.component,
        worksheet: worksheet,
        autoFilterEnabled: true
      }).then(() => {
        workbook.xlsx.writeBuffer().then((buffer) => {
          saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'Reporte-Acuses.xlsx');
        });
      });
      e.cancel = true;
    },
    async search(e) {
      var self = this;
      e.preventDefault();
      self.textoBotonBuscar = "Buscando...";
      self.loading = true;
      try {
        self.intDate = self.date.replace('/', '');
        self.intDate = self.intDate.replace('/', '');


        self.intDateEnd = self.dateEnd.replace('/', '');
        self.intDateEnd = self.intDateEnd.replace('/', '');

        var ciudad = self.ciudadSelect != undefined ? self.ciudadSelect : 0;
        var estacion = self.estacionSelect != undefined ? self.estacionSelect : 0;
        //debugger; // eslint-disable-line no-debugger

        var dataToSend = {};
        var apiUrl = "";
        if (parseInt(estacion) == 0) {
          apiUrl = 'Precio/GetAcusesPrecio';
          dataToSend = {
            "Data": {
              "FechaInicial": self.intDate,
              "FechaFinal": self.intDateEnd,
              "Ciudad": ciudad,
              "Estacion": estacion
            }
          };
        } else {

          apiUrl = 'Precio/GetAcusesPrecioEstacion';
          dataToSend = {
            "Data": {
              "FechaInicial": self.intDate,
              "FechaFinal": self.intDateEnd,
              "NoEstacion": estacion
            }
          };
        }

        const response = await axios.post(apiUrl, dataToSend);

        //debugger; // eslint-disable-line no-debugger
        if (response.data.Success) {
          if (response.data.response.length == 0) {
            self.alertMessage = response.data.Message;
            self.alertTitle = response.data.Error;
            self.show('top-right', 'error');
          }
        } else {
          console.log("error")
          self.alertMessage = response.data.Error;
          self.alertTitle = "Error";
          self.show('top-right', 'error');
        }
        self.reporteCre = [];

        self.reporteCre = response.data.response;

        self.textoBotonBuscar = "Buscar";

        self.loading = false;
      } catch (e) {
        self.loading = false;
        //this.alert = true;
        //this.error = 'Invalid email/password';
      }
    },
    async CancelarAcuse(data) {
      var self = this;
      self.loading = true;
      try {

        this.$swal({
          title: "Aviso",
          text: "Desea Cancelar el Acuse!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "SI, Registrar!"
        }).then(async (result) => {
          if (result.isConfirmed) {

            var dataToSend = {
              "Data": {
                "Id": data.Id,
                "Estatus": 0
              },
            };

            console.log(dataToSend);


            const response = await axios.post("Precio/SetAcuseEstatus", dataToSend);

            self.loading = false;
            if (response.data.Success) {
              this.$swal({
                icon: "success",
                text: response.data.Message,
              });
            }
            else {
              this.$swal({
                icon: "error",
                text: response.data.Message,
              });
            }

          }
        });

      } catch (e) {
        self.loading = false;
        this.$swal({
          icon: "error",
          text: "Ocurrio un error",
        });
      }
    },
  },
  data() {
    return {
      loading: false,
      showHeaderFilter: true,
      deshabilitarCombos: false,
      ciudades: [],
      estaciones: [],
      ciudadSelect: 1,
      estacionSelect: 0,
      date: moment().startOf('month').format("YYYY/MM/DD"),
      intDateEnd: "",
      dateEnd: moment().format("YYYY/MM/DD"),
      intDate: "",
      options: {
        format: 'YYYY/MM/DD',
        useCurrent: false,
      },
      pageUserSettings: {},
      alertMessage: "",
      alertTitle: "",
      reporteCre: [],
      textoBotonBuscar: "Buscar",
      Archivo: {
        base64: '',
      },
    };
  }
}
</script>
<style scoped>
.dx-datagrid-header-panel {
  display: none !important;
}

.loading-search {
  position: absolute;
  width: 100%;
  left: 0px;
  height: 100%;
  background: rgba(0, 0, 0, 0.1);
  z-index: 1000;
  padding-top: 150px;
  top: 0;
}

.iconColor {
  color: #f70909;
  font-size: 20px;
}

.iconColorCheck {
  color: #28a70e;
  font-size: 20px;
}
</style>