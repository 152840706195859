<script>

import axios from 'axios';
import moment from 'moment';



import {
    DxDataGrid, DxFormat,
    DxPaging, DxPager, DxScrolling, DxColumn, DxExport, DxSelection, DxGroupPanel, DxGrouping, DxHeaderFilter, DxFilterRow, DxSearchPanel,
} from 'devextreme-vue/data-grid';
import { Workbook } from 'exceljs';
import { saveAs } from 'file-saver';
// Our demo infrastructure requires us to use 'file-saver-es'. We recommend that you use the official 'file-saver' package in your applications.
import { exportDataGrid } from 'devextreme/excel_exporter';
//import service from './data.js';


var numeral = require("numeral");
import Vue from "vue";

import JsonExcel from "vue-json-excel";
Vue.component("downloadExcel", JsonExcel);
Vue.filter("formatNumber", function (value) {
    return numeral(value).format("0,00.00"); // displaying other groupings/separators is possible, look at the docs
});

Vue.filter("currency", function (value) {
    return numeral(value).format("$0,0.00");
});


import * as XLSX from 'xlsx';

export default {
    data() {
        return {
            showHeaderFilter: true,
            loading: false,
            dataSource: [],
            alertMessage: "",
            alertTitle: "",
            fileName: "",
            dataEncabezado: {
                "Dato0": '', // Por ejemplo, si la primera celda es HDR
                "UsrBanorte": '', // Cambia según la estructura real
                "PwdBanorte": '', // Cambia según la estructura real
                "Registros": '', // Cantidad de registros
                "ImporteUSD": '', // Cambia según la estructura real
                "Usuario": '', // Cambia según la estructura real
                "Transacciones": []
            },
            datosProcesados: {
                "Dato0": '', // Por ejemplo, si la primera celda es HDR
                "UsrBanorte": '', // Cambia según la estructura real
                "PwdBanorte": '', // Cambia según la estructura real
                "Registros": '', // Cantidad de registros
                "ImporteUSD": '', // Cambia según la estructura real
                "Usuario": '', // Cambia según la estructura real
                "Transacciones": []
            },
            comentarios: '',

        };
    },
    components: {
        DxDataGrid, DxFormat, DxPaging, DxPager, DxScrolling, DxColumn, DxExport, DxSelection, DxGroupPanel, DxGrouping, DxHeaderFilter, DxFilterRow, DxSearchPanel,
    },
    mounted: function () {
        var self = this;

        var userSetting = JSON.parse(localStorage.getItem('userSettings'));
        this.usuario = userSetting.Usuario;
        this.usuarioEstacion = userSetting.Estacion;
        this.IdRol = userSetting.IdRol;
        this.ciudadSelect = userSetting.CiudadId;
        this.estacionSelect = userSetting.Estacion;

        if (this.IdRol == 3 || this.IdRol == 7) {
            this.deshabilitarCombos = true;
        }

    },
    beforeCreate() {

    },
    created() {
        // console.log('created');
    },

    beforeMount() {
        // console.log('beforeMount');
    },
    computed: {
    },
    methods: {
        onExporting(e) {
            const workbook = new Workbook();
            const worksheet = workbook.addWorksheet('CargaExcel');

            exportDataGrid({
                component: e.component,
                worksheet: worksheet,
                autoFilterEnabled: true
            }).then(() => {
                workbook.xlsx.writeBuffer().then((buffer) => {
                    saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'CargaExcel.xlsx');
                });
            });
            e.cancel = true;
        },
        show(group, type = '') {
            const text = this.alertMessage;
            this.$notify({
                group,
                title: this.alertTitle,
                text,
                type,
                data: {
                    randomNumber: Math.random()
                }
            })
        },
        clean(group) {
            this.$notify({ group, clean: true })
        },
        swalNotification(swalType) {
            var btnClass = (swalType == 'error') ? 'danger' : swalType;
            btnClass = (swalType == 'question') ? 'primary' : btnClass;
            this.$swal({
                title: 'Are you sure?',
                text: 'You will not be able to recover this imaginary file!',
                type: swalType,
                showCancelButton: true,
                buttonsStyling: false,
                confirmButtonText: swalType.charAt(0).toUpperCase() + swalType.substr(1).toLowerCase(),
                cancelButtonText: 'Cancel',
                confirmButtonClass: 'btn me-5px btn-' + btnClass + '',
                cancelButtonClass: 'btn btn-default',
            })
        },
        async search(e) {

            e.preventDefault();

        },
        async handleFileUpload(event) {
            this.loading = true;
            this.datosProcesados = {
                "Dato0": '', // Por ejemplo, si la primera celda es HDR
                "UsrBanorte": '', // Cambia según la estructura real
                "PwdBanorte": '', // Cambia según la estructura real
                "Registros": '', // Cantidad de registros
                "ImporteUSD": '', // Cambia según la estructura real
                "Usuario": '', // Cambia según la estructura real
                "Comentarios": '',
                "Transacciones": []
            };
            const selectedFile = event.target.files[0];
            //console.log(selectedFile);
            if (selectedFile != null) {
                this.fileName = selectedFile.name;
                const fileSizebytes = selectedFile.size; // en bytes
                const fileType = selectedFile.type;
                const fileExtension = this.fileName.slice(((this.fileName.lastIndexOf(".") - 1) >>> 0) + 2);

                if (fileExtension == 'xls' || fileExtension == 'xlsx') {
                    const reader = new FileReader();
                    reader.onload = async (e) => {

                        const data = new Uint8Array(e.target.result);
                        const workbook = XLSX.read(data, { type: 'array' });
                        const sheetName = workbook.SheetNames[0];
                        const worksheet = workbook.Sheets[sheetName];
                        //console.log('worksheet:', worksheet);

                        // Convertir la hoja a un arreglo de JSON
                        const jsonData = XLSX.utils.sheet_to_json(worksheet, {
                            header: 1, // Trata todas las filas como un array
                            raw: false // Esto convierte fechas a objetos Date
                        });

                        // Tomar la primera fila como encabezado
                        const headers = jsonData[0]; // Primera fila como encabezados
                        const registros = jsonData.slice(1); // Resto de las filas como registros

                        // Crear el objeto principal
                        const result = {
                            "Dato0": headers[0], // Por ejemplo, si la primera celda es HDR
                            "UsrBanorte": headers[1], // Cambia según la estructura real
                            "PwdBanorte": headers[2], // Cambia según la estructura real
                            "Registros": Number(headers[3]), // Cantidad de registros
                            "ImporteUSD": parseFloat((headers[4]).trim().replace(/,/g, '').replace(/\s+/g, '')), // Cambia según la estructura real
                            "Usuario": this.usuario, // Cambia según la estructura real
                            "Comentarios": '',
                            "Transacciones": []
                        };
                        //this.$set(this, 'dataEncabezado', result);

                        // Procesar cada registro
                        registros.forEach((row) => {
                            if (row.length > 0) { // Asegurarse de que la fila no esté vacía
                                result.Transacciones.push({
                                    "Dato1": Number((row[0]).trim().replace(/\s+/g, '')),
                                    "Fecha": moment(row[1]).format("YYYY-MM-DD HH:mm:ss"),
                                    "Sucursal": Number((row[2]).trim().replace(/\s+/g, '')),
                                    "Vendedor": row[3],
                                    "Dato2": Number((row[4]).trim().replace(/,/g, '').replace(/\s+/g, '')),
                                    "Dato3": Number((row[5]).trim().replace(/,/g, '').replace(/\s+/g, '')),
                                    "USD": parseFloat((row[6]).trim().replace(/,/g, '').replace(/\s+/g, '')),
                                    "MXN": parseFloat((row[7]).trim().replace(/,/g, '').replace(/\s+/g, '')),
                                    "TC": parseFloat((row[8]).trim().replace(/,/g, '').replace(/\s+/g, '')),
                                    "Dato4": Number((row[9]).trim().replace(/,/g, '').replace(/\s+/g, '')),
                                    "Ticket": (row[10]).trim().replace(/,/g, '').replace(/\s+/g, '')
                                });
                            }
                        });

                        console.log(result);

                        //this.$set(this, 'dataSource', result.Transacciones);
                        this.$set(this, 'datosProcesados', result);
                        this.$set(this, 'loading', false);
                    };
                    reader.readAsArrayBuffer(selectedFile);

                }
            }

        },
        clearFile() {
            this.fileName = null; // Limpia el nombre del archivo
            const fileInput = document.getElementById('file-upload');
            fileInput.value = ''; // Limpia la selección del input
            this.dataEncabezado = {
                "Dato0": '', // Por ejemplo, si la primera celda es HDR
                "UsrBanorte": '', // Cambia según la estructura real
                "PwdBanorte": '', // Cambia según la estructura real
                "Registros": '', // Cantidad de registros
                "ImporteUSD": '', // Cambia según la estructura real
                "Usuario": '', // Cambia según la estructura real
                "Comentarios": '',
                "Transacciones": []
            };
            this.datosProcesados = {
                "Dato0": '', // Por ejemplo, si la primera celda es HDR
                "UsrBanorte": '', // Cambia según la estructura real
                "PwdBanorte": '', // Cambia según la estructura real
                "Registros": '', // Cantidad de registros
                "ImporteUSD": '', // Cambia según la estructura real
                "Usuario": '', // Cambia según la estructura real
                "Comentarios": '',
                "Transacciones": []
            };
        },
        async setExcelApi() {
            this.loading = true;
            try {

                var dataToSend = {
                    "Data": this.datosProcesados
                };

                const response = await axios.post('Corresponsalia/SetCorresponsalXls', dataToSend);
                var resp = response.data;
                if (resp.Success) {
                    this.alertMessage = resp.Message;
                    this.alertTitle = resp.Error;
                    this.show("top-right", "success");
                    this.clearFile();
                    await this.getCorresponsalias();
                    this.loading = false;
                } else {
                    this.alertMessage = resp.Message;
                    this.alertTitle = resp.Error;
                    this.show('top-right', 'error');
                    this.loading = false;
                }
            } catch (e) {
                this.loading = false;
                this.alertMessage = "ocurrio un error al intentar guardar";
                this.alertTitle = "Error";
                this.show("top-right", "error");
            }
        },
        async getCorresponsalias() {

            this.loading = true;
            try {

                const response = await axios.get('Corresponsalia/GetCorresponsaliasXls');
                var resp = response.data;
                if (resp.Success) {
                    this.dataSource = resp.response;

                    this.$set(this, 'dataSource', resp.response);
                    this.loading = false;
                } else {
                    this.alertMessage = resp.Message;
                    this.alertTitle = resp.Error;
                    this.show('top-right', 'error');
                    this.loading = false;
                }


            } catch (e) {
                this.loading = false;
                this.alertMessage = "ocurrio un error al intentar consultar las corresponsalias";
                this.alertTitle = "Error";
                this.show("top-right", "error");
            }

        },
        async getTXT(data) {
            this.loading = true;
            try {

                var dataToSend = {
                    "Data": {
                        "Id": data.data.Id
                    }
                };
                const response = await axios.post('Corresponsalia/GetTxtCorresponsaliaXls', dataToSend);
                var resp = response.data;
                if (resp.Success) {
                    this.downloadBase64File(resp.response);
                    this.loading = false;
                } else {
                    this.alertMessage = resp.Message;
                    this.alertTitle = resp.Error;
                    this.show('top-right', 'error');
                    this.loading = false;
                }


            } catch (e) {
                this.loading = false;
                this.alertMessage = "ocurrio un error al descargar el txt";
                this.alertTitle = "Error";
                this.show("top-right", "error");
            }
        },
        formatDate(date) {
            const year = date.getFullYear(); // Obtener el año
            const month = String(date.getMonth() + 1).padStart(2, '0'); // Mes en formato 2 dígitos
            const day = String(date.getDate()).padStart(2, '0'); // Día en formato 2 dígitos
            const hour = String(date.getHours()).padStart(2, '0'); // Hora en formato 2 dígitos
            const min = String(date.getMinutes()).padStart(2, '0'); // Minutos en formato 2 dígitos
            const second = String(date.getSeconds()).padStart(2, '0'); // Segundos en formato 2 dígitos
            return `${year}${month}${day}${hour}${min}${second}`; // Formato: YYYY-MM-DD
        },
        downloadBase64File(base64String) {
            // Decodificar el Base64
            const byteCharacters = atob(base64String);
            const byteNumbers = new Uint8Array(byteCharacters.length);

            // Convertir la cadena decodificada en un array de bytes
            for (let i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
            }

            // Crear un Blob con el contenido del archivo
            const blob = new Blob([byteNumbers], { type: 'text/plain' });

            // Crear un enlace para descargar el archivo
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            const today = new Date();
            const dateString = this.formatDate(today);
            const fileName = `banorte_${dateString}.txt`;
            link.download = fileName; // Establecer el nombre del archivo
            document.body.appendChild(link); // Agregar el enlace al cuerpo del documento
            link.click(); // Simular un clic para iniciar la descarga
            document.body.removeChild(link); // Remover el enlace del DOM

            this.alertMessage = "Se descargo el archivo" + fileName;
            this.alertTitle = "";
            this.show("top-right", "success");
        }
    },

}
</script>
<style scoped>
.dx-datagrid-header-panel {
    display: none !important;
}

.loading-search {
    position: absolute;
    width: 100%;
    left: 0px;
    height: 100%;
    background: rgba(0, 0, 0, 0.1);
    z-index: 1000;
    padding-top: 150px;
    top: 0;
}

.sumatoriasRow {
    background-color: #bbb;
}

.currencyRow {
    text-align: right !important;
}

.vgt-fixed-header {
    overflow-y: auto;
}

.vgt-table__header {
    position: sticky;
    top: 0;
    z-index: 1;
    background-color: #f5f5f5;
}

.file-select {
    position: relative;
    display: inline-block;
}

.file-select-label {
    background-color: #298197;
    /* Color del botón */
    color: white;
    /* Color del texto */
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 3px;
    cursor: pointer;
    /* Cambiar el cursor a puntero */
    padding: 10px 20px;
    /* Tamaño del botón */
    transition: background-color 0.3s;
    /* Transición suave */
    width: 250px;
    height: 53px;
}

.file-select-label:hover {
    background-color: #0056b3;
    /* Color al pasar el mouse */
}

.custom-file-input {
    opacity: 0;
    /* Ocultar el input original */
    width: 200px;
    /* Ancho del input */
    height: 32px;
    /* Alto del input */
    position: absolute;
    /* Asegurarse de que cubra el área del label */
    top: 0;
    /* Posicionar en la parte superior */
    left: 0;
    /* Posicionar a la izquierda */
    cursor: pointer;
    /* Cambiar el cursor a puntero */
}

.clear-button {
    background: none;
    /* Sin fondo */
    border: none;
    /* Sin borde */
    color: #ff4d4d;
    /* Color del icono */
    cursor: pointer;
    /* Cambiar el cursor a puntero */
    position: absolute;
    /* Posicionar de manera absoluta */
    right: 10px;
    /* Ajusta la posición a la derecha */
    top: 50%;
    /* Centrar verticalmente */
    transform: translateY(-50%);
    /* Ajustar el centrado */
}

.clear-button:hover {
    color: #ff1a1a;
    /* Color al pasar el mouse */
}
</style>
<template>
    <div>
        <div class=" loading-search" v-if="loading">
            <div class="fa-3x text-center">
                <i class="fas fa-circle-notch fa-spin text-success" style=""></i>
            </div>
        </div>
        <!-- BEGIN breadcrumb -->
        <ol class="breadcrumb float-xl-end">
            <li class="breadcrumb-item"><a href="javascript:;">Corresponsalia</a></li>
            <li class="breadcrumb-item"><a href="javascript:;">Banorte</a></li>
            <li class="breadcrumb-item active"><a href="javascript:;">Cargar Excel</a></li>
        </ol>

        <h1 class="page-header">Cargar Excel<small></small></h1>
        <form>
            <div class="row">
                <div class="col-md-8">
                    <div class="file-select" id="src-file1" style="cursor: pointer;">
                        <input type="file" id="file-upload" style="cursor: pointer;" class="custom-file-input"
                            accept=".xls,.xlsx" @change="handleFileUpload" />
                        <label for="file-upload" class="file-select-label" style="cursor: pointer;">
                            {{ fileName || "Importar Excel" }}
                        </label>
                        <button v-if="fileName" class="clear-button" @click="clearFile" title="Limpiar selección">
                            <i class="fas fa-trash"></i> <!-- Icono de limpiar -->
                        </button>
                    </div>
                    <br><br>
                    <p>
                        <b-form-textarea id="textarea" v-model="datosProcesados.Comentarios" placeholder="Comentarios"
                            rows="3" max-rows="6"></b-form-textarea>
                    </p>
                </div>
                <div class="col-md-4" style="text-align: right;">
                    <button class="btn btn-primary me-1 mb-1" style="margin-top: 3.1em;"
                        @click="getCorresponsalias">Consultar</button>
                    <button v-if="datosProcesados.Dato0 != ''" class="btn btn-primary me-1 mb-1"
                        style="margin-top: 3.1em;" @click="setExcelApi">Guardar</button>
                </div>
            </div>
        </form>
        <div>
            <!-- <panel title="Encabezado" bodyClass="p-0" style="margin-top: 15px;">
                <div class="row">
                    <div class="col-sm-12">
                        <div class="row">
                            <div class="col-sm-2">
                                <label for="">Dato0:</label> {{ dataEncabezado.Dato0 }}
                            </div>
                            <div class="col-sm-2">
                                <label for="">UsrBanorte:</label>{{ dataEncabezado.UsrBanorte }}
                            </div>
                            <div class="col-sm-2">
                                <label for="">PwdBanorte:</label>{{ dataEncabezado.PwdBanorte }}
                            </div>
                            <div class="col-sm-2">
                                <label for="">Registros:</label>{{ dataEncabezado.Registros }}
                            </div>
                            <div class="col-sm-2">
                                <label for="">ImporteUSD:</label>{{ dataEncabezado.ImporteUSD }}
                            </div>
                            <div class="col-sm-2">
                                <label for="">Usuario:</label>{{ dataEncabezado.Usuario }}
                            </div>
                        </div>
                    </div>
                </div>
            </panel> -->
            <panel title="Transacciones" bodyClass="p-0" style="margin-top: 15px;">

                <DxDataGrid id="gridContainer" :data-source="dataSource" key-expr="Id" :show-borders="true"
                    @exporting="onExporting" :show-column-lines="true" :show-row-lines="true"
                    :allow-column-resizing="true" :column-auto-width="true" :row-alternation-enabled="true"
                    :allow-column-reordering="true">

                    <DxScrolling column-rendering-mode="virtual" />
                    <DxPager :show-page-size-selector="true" :allowed-page-sizes="[10, 20, 50, 100]"
                        :show-navigation-buttons="true" />
                    <DxPaging :page-size="10" :page-index="1" />
                    <DxFilterRow />
                    <DxHeaderFilter :visible="showHeaderFilter" />
                    <DxSearchPanel :visible="true" :width="240" placeholder="Search..." />
                    <DxGroupPanel :visible="false" />
                    <DxGrouping :auto-expand-all="true" />
                    <DxExport :enabled="true" :allow-export-selected-data="false" />
                    <DxSelection mode="false" />

                    <DxColumn data-field="Id" caption="Id" :sort-order="'desc'" />
                    <DxColumn data-field="Dato0" caption="Dato0" />

                    <DxColumn data-field="UsrBanorte" caption="UsrBanorte" />
                    <DxColumn data-field="PwdBanorte" caption="PwdBanorte" />
                    <DxColumn data-field="Registros" caption="Registros" />

                    <DxColumn data-field="ImporteUSD" caption="ImporteUSD" dataType="number">
                        <DxFormat type="currency" :precision="2" />
                    </DxColumn>

                    <DxColumn data-field="Usuario" caption="Usuario" />
                    <DxColumn data-field="Comentarios" caption="Comentarios" />
                    <DxColumn data-field="FechaRegistro" dataType="date" caption="Fecha Registro" />

                    <DxColumn caption="Acciones" cell-template="actions" :width="70" search="false" filter="false"
                        sorting="false" :fixed="true" fixed-position="right" />

                    <template #actions="{ data }">
                        <div style="text-align: center;">
                            <button class="btn btn-xs btn-success" @click="getTXT(data)">TXT</button>
                        </div>

                    </template>
                </DxDataGrid>
            </panel>
        </div>

        <!-- bottom left vue-notification -->
        <notifications group="top-right" position="top right" :speed="1000" />
    </div>
</template>
