<template>
  <div>
    <!-- BEGIN breadcrumb -->
    <ol class="breadcrumb float-xl-end">
      <li class="breadcrumb-item"><a href="javascript:;">Catalogos</a></li>
      <li class="breadcrumb-item active">
        <a href="javascript:;">Colaboradores</a>
      </li>
    </ol>
    <!-- END breadcrumb -->
    <!-- BEGIN page-header -->
    <h1 class="page-header">Colaboradores</h1>
    <!-- END page-header -->

    <div class="row">
      <div class="col-md-8">
        <form @submit.prevent="search">
          <div class="form-group row">
            <!-- <div class="col-md-6">
              <div class="input-group">
              <input class="form-control" type="text" v-model="clienteBuscar">
              </div>
            </div> -->
            <div class="col-md-6">
              <button type="submit" class="btn btn-success me-1 mb-1">{{ textoBotonBuscar }}</button>
            </div>
          </div>
        </form>
      </div>
      <div class="col-md-4">
        <div class="col-md-12 text-end">
          <!-- <button type="button" v-b-modal.modalDialog class="btn btn-sm btn-success" @click="getCliente('',0)">Agregar Colaborador</button> -->
        </div>
      </div>
    </div>
    <panel title="Catalogo de Colaboradores" bodyClass="p-0" class="">
      <vue-good-table :columns="columns" :rows="clientes" :lineNumbers="false"
        :search-options="{ enabled: true, placeholder: 'Buscar' }"
        :pagination-options="{ enabled: true, position: 'bottom' }">
        <template slot="table-row" slot-scope="props">
          <span v-if="props.column.label == 'Acciones'">
            <button title="Ver" v-b-modal.modalDialogColab class="btn btn-sm btn-success"
              @click="getCliente(props.row.NumeroVentuk)">
              <i class="fas fa-lg fa-fw fa-eye"></i>


            </button>
            &nbsp;
            <button v-b-modal.modalContrasenaDialog class="btn btn-sm btn-success text-center"
              @click="getCliente(props.row.NumeroVentuk)" title="Contraseña / NIP">
              <i class="fas fa-lg fa-fw fa-lock"></i>
            </button>
          </span>
          <span v-else-if="props.column.field == 'Estatus'">
            <span v-if="props.formattedRow[props.column.field]" class="fa fa-check"></span>
            <span v-if="!props.formattedRow[props.column.field]" class="fa fa-ban"></span>
          </span>
        </template>
      </vue-good-table>
    </panel>





    <!-- #modal-dialog -->
    <b-modal id="modalDialogColab" size="lg" class="modalUsuarios">
      <template slot="modal-header">
        <h4 class="modal-title" v-if="clienteNuevo">Nuevo Usuario</h4>
        <h4 class="modal-title" v-if="!clienteNuevo">{{ clienteModal.Nombre }}</h4>
        <a class="btn-close" @click="$bvModal.hide('modalDialogColab')"></a>
      </template>

      <div class="row">
        <div class="col-12">

          <b-tabs nav-wrapper-class="nav nav-tabs" content-class="p-15px bg-white">
            <!-- BEGIN tab -->
            <b-tab active>
              <template slot="title">
                <span class="d-sm-none">Tab 1</span>
                <span class="d-sm-block d-none">Colaborador</span>
              </template>


              <form action="/" method="POST">
                <fieldset>

                  <div class="row">
                    <div class="col-md-6">
                      <div class="mb-3">
                        <label class="form-label" for="txtNoEmpleado">Número de empleado <strong style="color: red;">*</strong></label>
                        <input class="form-control" type="text" id="txtNoEmpleado" placeholder="Número de empleado"
                          @change="cambiarRol()" :disabled="true" v-model="clienteModal.NumeroEmpleado" />
                      </div>

                      <div class="mb-3">
                        <label class="form-label" for="txtNombre">Nombre <strong style="color: red;">*</strong></label>
                        <input class="form-control" type="text" id="txtNombre" placeholder="Nombre" :disabled="true"
                          v-model="clienteModal.Nombre" />
                      </div>


                      <div class="mb-3">
                        <label class="form-label" for="txtPuesto">Puesto <strong style="color: red;">*</strong></label>
                        <select v-model="puestoSelected.puesto" class="form-control" :disabled="IdRol != 1">
                          <option v-for="puesto in puestosSelect" :value="puesto.Id" v-bind:key="puesto.Id" required>
                            {{ puesto.Descripcion }}
                          </option>
                        </select>
                      </div>

                      <div class="mb-3">
                        <label class="form-label" for="txtRol">Rol <strong style="color: red;">*</strong></label>
                        <select v-model="rolSelected.rol" class="form-control" @change="cambiarRol()"
                          :disabled="IdRol != 1">
                          <option v-for="rol in rolesSelect" :value="rol.Id" v-bind:key="rol.Id">
                            {{ rol.Descripcion }}
                          </option>
                        </select>
                      </div>

                      <div class="mb-3">
                        <label class="form-label" for="txtVentuk">Ventuk <strong style="color: red;">*</strong></label>
                        <input class="form-control" type="number" id="txtVentuk" placeholder="Ventuk"
                          v-model="clienteModal.NumeroVentuk" :disabled="true" />
                      </div>


                      <div class="mb-3">
                        <label class="form-label" for="txtTelefono">Telefono</label>
                        <input class="form-control" type="text" id="txtTelefono" placeholder="Telefono"
                          :disabled="IdRol != 1" v-model="clienteModal.Telefono" />
                      </div>

                      <div class="mb-3">
                        <label class="form-label" for="txtPinSantander">Pin Santander</label>
                        <input class="form-control" type="number" id="txtPinSantander" placeholder="Pin Santander"
                          v-model="clienteModal.PinSantander" v-on:keyup="onchangeDecimal" />
                      </div>


                    </div>
                    <div class="col-md-6">
                      <div class="mb-3">
                        <label class="form-label" for="txtUsuario">Usuario <strong style="color: red;">*</strong></label>
                        <input class="form-control" type="text" id="txtUsuario" placeholder="Usuario"
                          :disabled="IdRol != 1" v-model="clienteModal.Usuario" />
                      </div>

                      <div class="mb-3" v-if="clienteNuevo">
                        <label class="form-label" for="txtContrasena">Contraseña</label>
                        <input class="form-control" type="password" id="txtContrasena" placeholder="Contrasena"
                          v-model="clienteModal.Pass" />
                      </div>
                      <div class="mb-3" v-if="clienteNuevo">
                        <label class="form-label" for="txtNIP">NIP</label>
                        <input class="form-control" type="number" id="txtNIP" placeholder="NIP"
                          v-model="clienteModal.NIP" />
                      </div>

                      <div class="mb-3" v-if="!clienteNuevo">
                        <label class="form-label" for="txtFechaAlta">Fecha Alta</label>
                        <input class="form-control" type="text" id="txtFechaAlta" placeholder="Fecha Alta"
                          :disabled="!clienteNuevo" v-model="clienteModal.FechaAlta" />

                      </div>
                      <div class="mb-3">
                        <label class="form-label" for="txtFechaIngreso">FechaIngreso <strong style="color: red;">*</strong></label>
                        <!-- <input class="form-control" type="text" id="txtFechaIngreso" placeholder="Fecha Ingreso"  v-model="clienteModal.FechaIngreso"/>   -->
                        <div class="input-group">
                          <date-picker id="dateTimeInput" v-model="clienteModal.FechaIngreso" :config="options"
                            :disabled="true"></date-picker>
                          <label for="dateTimeInput" class="input-group-text">
                            <i class="fa fa-calendar"></i>
                          </label>
                        </div>
                      </div>

                      <div class="mb-3">
                        <label class="form-label" for="txtCiudad">Ciudad</label>
                        <select class="form-select" @change="getEstaciones()" v-model="ciudadSelect">
                          <option value="0">Administración</option>
                          <option v-for="ciudad in ciudades" :value="ciudad.Id" v-bind:key="ciudad.Id">
                            {{ ciudad.Nombre }}
                          </option>
                        </select>

                      </div>

                      <div class="mb-3">
                        <label class="form-label" for="txtEstacion">Estación</label>
                        <!-- <input class="form-control" type="number" id="txtEstacion" placeholder="Estacion"  v-model="clienteModal.Estacion"/> -->
                        <select class="form-select" v-model="clienteModal.Estacion">
                          <option v-for="estacion in estaciones" :value="estacion.NoEstacion"
                            v-bind:key="estacion.NoEstacion">
                            {{ estacion.Nombre }}
                          </option>
                        </select>
                      </div>

                      <div class="mb-3">
                        <label class="form-label" for="txtCorreo">Correo</label>
                        <input class="form-control" type="text" id="txtCorreo" placeholder="Correo"
                          :disabled="IdRol != 1" v-model="clienteModal.Correo" />
                      </div>
                      <div class="mb-3">
                        <label class="form-label" for="txtUsuarioIntelisis">Usuario Intelisis</label>
                        <input class="form-control" type="text" id="txtUsuarioIntelisis" placeholder="Usuario Intelisis"
                          v-model="clienteModal.UsuarioIntelisis" />
                      </div>

                      <div class="form-check form-switch mb-2" v-if="!clienteNuevo">
                        <input class="form-check-input" type="checkbox" id="flexSwitchCheckChecked"
                          v-model="clienteModal.Estatus" :disabled="true">
                        <label class="form-check-label" for="flexSwitchCheckChecked"><span
                            v-if="clienteModal.Estatus">Activo</span><span
                            v-if="!clienteModal.Estatus">Inactivo</span></label>
                      </div>
                    </div>
                  </div>
                </fieldset>

              </form>
              <!-- <p class="text-end mb-0">
                    <a href="javascript:;" class="btn btn-white me-5px">Default</a>
                    <a href="javascript:;" class="btn btn-primary">Primary</a>
                  </p> -->
            </b-tab>
            <!-- END tab -->
            <!-- BEGIN tab -->
            <b-tab v-if="IdRol == 1">
              <template slot="title">
                <span class="d-sm-none">Tab 2</span>
                <span class="d-sm-block d-none">Permisos</span>
              </template>

              <div id="accordion" class="accordion rounded-top overflow-hidden">



                <!-- BEGIN b-card -->
                <b-card class="border-0 rounded-0" no-body v-for="(modulo, index)  in clienteModal.Permisos"
                  v-bind:key="index + 1">
                  <b-card-header header-tag="header"
                    class="card-header bg-gray-300 pointer-cursor px-3 py-10px d-flex align-items-center"
                    @click="openAccordion(index + 1)" :v-b-toggle="`accordion${index + 1}`">
                    <i class="fa fa-circle fa-fw text-blue fs-8px me-2"></i> {{ modulo.Descripcion }}
                  </b-card-header>
                  <b-collapse :id="`accordion${index + 1}`" class="accordionBody" accordion="my-accordion">


                    <b-card-body>
                      <div class="row mb-3" v-for="subModulo in modulo.Submodulos" v-bind:key="subModulo.Descripcion">
                        <label class="form-label col-form-label col-md-12">{{ subModulo.Descripcion }}</label>

                        <div class="col-md-6" v-for="(permiso, indexPermiso) in subModulo.Permisos"
                          v-bind:key="indexPermiso">
                          <div class="form-check mt-2 mb-2">
                            <!-- checkbock permisos -->
                            <input v-model="permiso.Estatus" class="form-check-input" type="checkbox"
                              v-on:click="setColaboradorPermiso(permiso.IdPermiso, permiso.Estatus)"
                              :id="`chkPermiso-${permiso.IdPermiso}`">
                            <label class="form-check-label" :for="`chkPermiso-${permiso.IdPermiso}`">
                              {{ permiso.Descripcion }}
                            </label>
                          </div>
                          <!-- <div class="form-check mb-2">
                                <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked" checked>
                                <label class="form-check-label" for="flexCheckChecked">
                                  Checked checkbox
                                </label>
                              </div> -->
                        </div>
                      </div>



                    </b-card-body>
                  </b-collapse>
                </b-card>
                <!-- END b-card -->




                <!-- <b-card class="border-0 rounded-0" no-body>
                      <b-card-header header-tag="header" class="card-header bg-gray-300 pointer-cursor px-3 py-10px d-flex align-items-center" v-b-toggle.accordion2>
                        <i class="fa fa-circle fa-fw text-indigo fs-8px me-2"></i> Collapsible Group Item #2
                      </b-card-header>
                      <b-collapse id="accordion2" accordion="my-accordion">
                        <b-card-body>
                          Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo. Leggings occaecat craft beer farm-to-table, raw denim aesthetic synth nesciunt you probably haven't heard of them accusamus labore sustainable VHS.
                        </b-card-body>
                      </b-collapse>
                    </b-card> -->
              </div>

            </b-tab>
            <!-- END tab -->
            <!-- BEGIN tab -->
            <b-tab v-if="IdRol == 1">
              <template slot="title">
                <span class="d-sm-none">Tab 3</span>
                <span class="d-sm-block d-none">Viajes</span>
              </template>
              <div class="row">

                <div class="col-5">
                  <label class="col-form-label">Autorizador</label>
                  <select class="form-select" v-model="autorizadorSelect">
                    <option v-for="autorizador in autorizadores" :value="autorizador.NumeroEmpleado"
                      v-bind:key="autorizador.NumeroEmpleado">
                      {{ autorizador.Nombre }}
                    </option>
                  </select>
                </div>
                <div class="col-5">
                  <label class="col-form-label">No Autorizador</label>
                  <select class="form-select" v-model="noAutorizadorSelect">
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                  </select>
                </div>
                <div class="col-2" style="padding-top: 2.4rem;">
                  <button class="btn btn-sm btn-primary" v-on:click="agregarColaboradorAutorizador('Viajes')"
                    :disabled="autorizadorSelect == '0'">Agregar</button>
                </div>

              </div>
              <div>
                <div class="table-responsive">
                  <table class="table table-striped align-middle mb-0">
                    <thead>
                      <tr>
                        <!-- <th>ID</th> -->
                        <th>No Autorizador</th>
                        <th>Nombre</th>
                        <th>Estatus</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="autorizadorColaborador in AutorizadoresColaborador"
                        v-bind:key="autorizadorColaborador.NumeroEmpleado">
                        <!-- <td>{{ autorizadorColaborador.Id }}</td> -->
                        <td>{{ autorizadorColaborador.NumAutorizador }}</td>
                        <td>{{ autorizadorColaborador.NombreAutorizador }}</td>
                        <td>
                          <!-- {{ autorizadorColaborador.Estatus }} -->
                          <i v-if="autorizadorColaborador.Estatus == 'Active'" class="fas fa-lg fa-fw fa-check"
                            style="color: rgb(24, 117, 24);"></i>
                          <i v-if="autorizadorColaborador.Estatus == 'Inactive'" class="fas fa-lg fa-fw fa-ban"
                            style="color:rgb(165, 54, 54);"></i>
                        </td>
                        <td>
                          <button class="btn btn-xs btn-danger"
                            v-on:click="altaBajaColaboradorAutorizador('Inactive', autorizadorColaborador, 'Viajes')"
                            v-if="autorizadorColaborador.Estatus == 'Active'">Dar de baja</button>
                          <button class="btn btn-xs btn-primary"
                            v-on:click="altaBajaColaboradorAutorizador('Active', autorizadorColaborador, 'Viajes')"
                            v-if="autorizadorColaborador.Estatus == 'Inactive'">Dar de Alta</button>

                        </td>

                        <!-- <td><input type="text" class="form-control my-n1" value="" /></td>
                              <td>
                                <div class="form-check">
                                  <input type="checkbox" value="" id="table_checkbox_2" class="form-check-input" />
                                  <label for="table_checkbox_2" class="form-check-label">&nbsp;</label>
                                </div>
                              </td> -->
                      </tr>

                    </tbody>
                  </table>
                </div>

              </div>


            </b-tab>
            <!-- END tab -->

            <!-- BEGIN tab -->
            <b-tab v-if="IdRol == 1">
              <template slot="title">
                <span class="d-sm-none">Tab 4</span>
                <span class="d-sm-block d-none">Solicitudes de compra</span>
              </template>
              <div class="row">

                <div class="col-5">
                  <label class="col-form-label">Autorizador compra</label>
                  <select class="form-select" v-model="autorizadorComprasSelect">
                    <option v-for="autorizador in autorizadoresCompras" :value="autorizador.NumeroEmpleado"
                      v-bind:key="autorizador.NumeroEmpleado">
                      {{ autorizador.Nombre }}
                    </option>
                  </select>
                </div>
                <div class="col-5">
                  <label class="col-form-label">No Autorizador</label>
                  <select class="form-select" v-model="noAutorizadorComprasSelect">
                    <option value="1" selected>1</option>
                  </select>
                </div>
                <div class="col-2" style="padding-top: 2.4rem;">
                  <button class="btn btn-sm btn-primary" v-on:click="agregarColaboradorAutorizador('Solicitudes')"
                    :disabled="autorizadorComprasSelect == '0'">Agregar</button>
                </div>

              </div>
              <div>
                <div class="table-responsive">
                  <table class="table table-striped align-middle mb-0">
                    <thead>
                      <tr>
                        <!-- <th>ID</th> -->
                        <th>No Autorizador</th>
                        <th>Nombre</th>
                        <th>Estatus</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="autorizadorCompraColaborador in AutorizadoresComprasColaborador"
                        v-bind:key="autorizadorCompraColaborador.NumeroEmpleado">
                        <!-- <td>{{ autorizadorColaborador.Id }}</td> -->
                        <td>{{ autorizadorCompraColaborador.NumAutorizador }}</td>
                        <td>{{ autorizadorCompraColaborador.NombreAutorizador }}</td>
                        <td>
                          <!-- {{ autorizadorColaborador.Estatus }} -->
                          <i v-if="autorizadorCompraColaborador.Estatus == 'Active'" class="fas fa-lg fa-fw fa-check"
                            style="color: rgb(24, 117, 24);"></i>
                          <i v-if="autorizadorCompraColaborador.Estatus == 'Inactive'" class="fas fa-lg fa-fw fa-ban"
                            style="color:rgb(165, 54, 54);"></i>
                        </td>
                        <td>

                          <button class="btn btn-xs btn-danger"
                            v-on:click="altaBajaColaboradorAutorizador('Inactive', autorizadorCompraColaborador, 'Solicitudes')"
                            v-if="autorizadorCompraColaborador.Estatus == 'Active'">Dar de baja</button>
                          <button class="btn btn-xs btn-primary"
                            v-on:click="altaBajaColaboradorAutorizador('Active', autorizadorCompraColaborador, 'Solicitudes')"
                            v-if="autorizadorCompraColaborador.Estatus == 'Inactive'">Dar de Alta</button>

                        </td>
                      </tr>

                    </tbody>
                  </table>
                </div>

              </div>


            </b-tab>
            <!-- END tab -->
          </b-tabs>

        </div>



      </div>

      <template slot="modal-footer">
        <button type="button" class="btn btn-primary mr-auto" @click="saveCliente()">{{ textoBotonGuardar }}</button>
        <button type="button" class="btn btn-secondary" @click="$bvModal.hide('modalDialogColab')">Cancelar</button>
      </template>
    </b-modal>






    <!-- #modal-dialog -->
    <b-modal id="modalContrasenaDialog">
      <template slot="modal-header">
        <h4 class="modal-title">Actualizar Contraseña, {{ clienteModal.Nombre }}</h4>
        <a class="btn-close" @click="$bvModal.hide('modalContrasenaDialog')"></a>
      </template>

      <div>
        <form action="/" method="POST">
          <fieldset>
            <div class="mb-3">
              <label class="form-label" for="txtNoEmpleado">Número de empleado</label>
              <input class="form-control" type="text" id="txtNoEmpleado" placeholder="Número de empleado"
                :disabled="true" v-model="clienteModal.NumeroEmpleado" />
            </div>
            <div class="mb-3">
              <label class="form-label" for="txtContrasena">Contraseña</label>
              <input class="form-control" type="password" id="txtContrasena" placeholder="Contrasena"
                v-model="clienteModal.Pass" :disabled="actualizarNIP" />
            </div>
            <!-- <div class="form-check form-switch mb-2">
										<input class="form-check-input" type="checkbox" id="flexSwitchCheckCheckedNIP" v-model="actualizarNIP">
										<label class="form-check-label" for="flexSwitchCheckCheckedNIP">Cambiar NIP?</label>
									</div>
								<div class="mb-3">
									<label class="form-label" for="txtNIP">NIP</label>
									<input class="form-control" type="number" id="txtNIP" placeholder="NIP" :disabled="!actualizarNIP"  v-model="clienteModal.NIP"/>
								</div> -->




          </fieldset>
        </form>
      </div>

      <template slot="modal-footer">
        <button type="button" class="btn btn-primary mr-auto" @click="saveContrasena()">{{ textoBotonGuardar }}</button>
        <button type="button" class="btn btn-secondary"
          @click="$bvModal.hide('modalContrasenaDialog')">Cancelar</button>
      </template>
    </b-modal>

    <!-- bottom left vue-notification -->
    <notifications group="top-right" position="top right" :speed="1000" />
  </div>
</template>

<script>

import axios from "axios";
import moment from 'moment';
export default {
  mounted() {
    //debugger; // eslint-disable-line no-debugger
    var userSetting = JSON.parse(localStorage.getItem('userSettings'));
    this.usuario = userSetting.Usuario;
    this.usuarioEstacion = userSetting.Estacion;
    this.IdRol = userSetting.IdRol;
    this.getCiudades();
    this.ciudadSelect = userSetting.CiudadId;
    this.getEstaciones();
    this.onLoad();
    this.getAutorizadores();
    //Autorizador/GetAutorizadores
    //Autorizador/GetAutorizadorColaborador


    // this.intervalId = window.setInterval(() => {
    // 	// this.getLecturas()
    // }, 60000)
  },
  async created() {
  },
  methods: {
    altaBajaColaboradorAutorizador(estatus, row, modulo) {
      var self = this;
      //debugger; // eslint-disable-line no-debugger
      if (estatus == 'Active') {

        if (modulo == 'Viajes') {
          for (var a = 0; a < self.AutorizadoresColaborador.length; a++) {
            if (parseInt(row.NumAutorizador) == parseInt(self.AutorizadoresColaborador[a].NumAutorizador) && self.AutorizadoresColaborador[a].Estatus == "Active") {

              this.alertMessage = "Ya hay un registro con número de autorizacion " + self.noAutorizadorSelect;
              this.alertTitle = "Validación";
              this.show("top-right", "error");
              return;
            }
          }

        }
        else if (modulo == 'Solicitudes') {
          for (var c = 0; c < self.AutorizadoresComprasColaborador.length; c++) {
            if (parseInt(row.NumAutorizador) == parseInt(self.AutorizadoresComprasColaborador[c].NumAutorizador) && self.AutorizadoresComprasColaborador[c].Estatus == "Active") {

              this.alertMessage = "Ya hay un registro con número de autorizacion " + self.noAutorizadorComprasSelect;
              this.alertTitle = "Validación";
              this.show("top-right", "error");
              return;
            }
          }
        }
      }

      var dataToSend = {
        "Data":
        {
          "Id": row.Id,//para nuevos es 0, Id cuando es dar de alta baja
          "NoEmpleado": self.clienteModal.NumeroVentuk,
          "Autorizador": row.Autorizador,
          "Modulo": modulo,
          "Estatus": estatus,//Inactive para dar de baja (cuando este activo, si esta incativo entonces dar de alta)
          "NumAutorizador": row.NumAutorizador
        }
      };
      this.SetColaboradorAutorizador(dataToSend);

    },
    agregarColaboradorAutorizador(modulo) {
      var self = this;
      var noAutorizadorSeleccionado = 0;
      var autorizadorSeleccionado = 0;


      if (modulo == 'Viajes') {

        noAutorizadorSeleccionado = self.noAutorizadorSelect;
        autorizadorSeleccionado = self.autorizadorSelect;
        if (self.AutorizadoresColaborador.length > 0) {
          for (var a = 0; a < self.AutorizadoresColaborador.length; a++) {
            if (parseInt(self.noAutorizadorSelect) == parseInt(self.AutorizadoresColaborador[a].NumAutorizador) && self.AutorizadoresColaborador[a].Estatus == "Active") {

              this.alertMessage = "Ya hay un registro con número de autorizacion " + self.noAutorizadorSelect;
              this.alertTitle = "Validación";
              this.show("top-right", "error");
              return;
            }
          }
        }

      }
      else if (modulo == 'Solicitudes') {
        noAutorizadorSeleccionado = self.noAutorizadorComprasSelect;
        autorizadorSeleccionado = self.autorizadorComprasSelect;
        if (self.AutorizadoresComprasColaborador.length > 0) {
          for (var c = 0; c < self.AutorizadoresComprasColaborador.length; c++) {
            if (parseInt(self.noAutorizadorComprasSelect) == parseInt(self.AutorizadoresComprasColaborador[c].NumAutorizador) && self.AutorizadoresComprasColaborador[c].Estatus == "Active") {

              this.alertMessage = "Ya hay un registro con número de autorizacion " + self.noAutorizadorComprasSelect;
              this.alertTitle = "Validación";
              this.show("top-right", "error");
              return;
            }
          }
        }

      }

      var dataToSend = {
        "Data":
        {
          "Id": 0,//para nuevos es 0, Id cuando es dar de alta baja
          "NoEmpleado": self.clienteModal.NumeroVentuk,
          "Autorizador": autorizadorSeleccionado,
          "Modulo": modulo,
          "Estatus": "Active",//Inactive para dar de baja (cuando este activo, si esta incativo entonces dar de alta)
          "NumAutorizador": noAutorizadorSeleccionado
        }
      };
      this.SetColaboradorAutorizador(dataToSend);

    },
    async SetColaboradorAutorizador(dataToSend) {
      // alert("Permiso: "+idPermiso+", Estatus: "+ estatus);
      var self = this;


      try {
        const response = await axios.post("Colaborador/SetColaboradorAutorizador", dataToSend
        );
        // this.getAutorizadorColaborador(self.clienteModal.NumeroEmpleado)
        this.getCliente(self.clienteModal.NumeroVentuk)
        //debugger; // eslint-disable-line no-debugger
        if (response.data.Success) {
          this.alertMessage = response.data.Message;
          // this.alertTitle = "Guardado con exito";
          this.show("top-right", "success");

        } else {
          this.alertMessage = response.data.Message;
          this.alertTitle = "Error";
          this.show("top-right", "error");
        }

      } catch (e) {
        this.alertMessage = "Ocurrio un error";
        this.alertTitle = "Error";
        this.show("top-right", "error");
      }
    },
    async setColaboradorPermiso(idPermiso, estatus) {
      // alert("Permiso: "+idPermiso+", Estatus: "+ estatus);
      var self = this;

      //debugger; // eslint-disable-line no-debugger
      var dataToSend = {
        "Data":
        {
          "NoEmpleado": self.clienteModal.NumeroVentuk,
          "Permiso": idPermiso,
          "Estatus": estatus ? 0 : 1,
          "UsuarioModifico": self.usuario
        }
      };

      //debugger; // eslint-disable-line no-debugger

      try {
        const response = await axios.post("Colaborador/SetColaboradorPermiso", dataToSend
        );

        //debugger; // eslint-disable-line no-debugger
        if (response.data.Success) {
          this.alertMessage = response.data.Message;
          // this.alertTitle = "Guardado con exito";
          this.show("top-right", "success");

        } else {
          this.alertMessage = response.data.Message;
          this.alertTitle = "Error";
          this.show("top-right", "error");
        }

      } catch (e) {
        this.alertMessage = "Ocurrio un error";
        this.alertTitle = "Error";
        this.show("top-right", "error");
      }
    },

    openAccordion(index) {

      const elements = document.getElementsByClassName('accordionBody');
      const elementsArray = Array.from(elements);
      elementsArray.forEach((element) => {
        element.style.display = 'none';
      });

      const element = document.getElementById('accordion' + index);
      element.style.display = 'block';

    },
    async getCiudades() {
      try {
        const response = await axios.get('Catalogos/GetCiudades');
        //debugger; // eslint-disable-line no-debugger
        if (response.data.Success) {
          if (response.data.response.length == 0) {
            this.alertMessage = "No se encontró información.";
            this.alertTitle = "Error";
            this.show('top-right', 'error');
          }
        } else {
          console.log("error")
          this.alertMessage = response.data.Error;
          this.alertTitle = "Error";
          this.show('top-right', 'error');
        }
        this.ciudades = response.data.response;
      } catch (e) {
        //this.alert = true;
        //this.error = 'Invalid email/password';
      }
    },


    async getAutorizadorColaborador(noEmpleado) {
      try {
        //debugger; // eslint-disable-line no-debugger
        var dataToSend = {
          "Data": {
            "NoEmpleado": noEmpleado
          }
        };
        const response = await axios.post('Autorizador/GetAutorizadorColaborador', dataToSend);
        //debugger; // eslint-disable-line no-debugger
        if (response.data.Success) {
          this.AutorizadoresColaborador = response.data.response;
          /*
            "NumeroEmpleado": 697464,
            "Nombre": "Moreno Barrios Elsa Elizabeth",
            "Usurio": null,
            "Autorizador": 697464,
            "NombreAutorizador": "Elsa Elizabeth Moreno Barrios",
            "Modulo": "Viajes",
            "Correo": "elsa.moreno@rendilitros.com",
            "NumAutorizador": 1
          */

        } else {
          console.log("error")
          this.alertMessage = response.data.Error;
          this.alertTitle = "Error";
          this.show('top-right', 'error');
        }

      } catch (e) {
        //this.alert = true;
        //this.error = 'Invalid email/password';
      }
    },
    async getAutorizadores() {
      try {

        const response = await axios.get('Autorizador/GetAutorizadores');
       // debugger; // eslint-disable-line no-debugger
        if (response.data.Success) {
          if (response.data.response.length == 0) {
            this.alertMessage = "No se encontró información.";
            this.alertTitle = "Error";
            this.show('top-right', 'error');
          }
        } else {
          console.log("error")
          this.alertMessage = response.data.Error;
          this.alertTitle = "Error";
          this.show('top-right', 'error');
        }

        this.autorizadores = [];
        this.autorizadoresCompras = [];
        var allAutorizadores = response.data.response;
        for (var n = 0; n < allAutorizadores.length; n++) {

          if (allAutorizadores[n].Modulo == "Viajes") {
            this.autorizadores.push(allAutorizadores[n]);
          }

          if (allAutorizadores[n].Modulo == "Solicitudes") {
            this.autorizadoresCompras.push(allAutorizadores[n]);
          }

        }


        /*
            "NumeroEmpleado": 123411,
            "Nombre": "Angel Garcia Branbilia",
            "Estatus": "Active",
            "Modulo": "Viajes",
            "Correo": "angel.garcia@rendilitros.com",
            "FechaRegistro": "2023-08-17T18:06:34.263",
            "FechaActualizacion": "2023-08-17T18:06:34.263",
            "Usuario": "angel.garcia"
        */
      } catch (e) {
        //this.alert = true;
        //this.error = 'Invalid email/password';
      }
    },

    async getEstaciones() {
      try {

        const response = await axios.post('Estacion/GetEstaciones?ciudad=' + this.ciudadSelect);
        if (response.data.Success) {
          if (response.data.response.length == 0) {
            this.alertMessage = "No se encontró información.";
            this.alertTitle = "Error";
            this.show('top-right', 'error');
          }
        } else {
          console.log("error")
          this.alertMessage = response.data.Error;
          this.alertTitle = "Error";
          this.show('top-right', 'error');
        }
        this.estaciones = response.data.response;
      } catch (e) {
        //this.alert = true;
        //this.error = 'Invalid email/password';
      }
    },
    async onLoad() {
      var rol = this.IdRol;
      try {
        const response = await axios.get("Catalogos/GetPuestos?rol=" + rol);
        const responseRoles = await axios.get("Catalogos/GetRoles?rol=" + rol);
        const responseTress = await axios.get("Catalogos/GetEmpresasTRESS");

        //debugger; // eslint-disable-line no-debugger
        if (response.data.Success && responseRoles.data.Success && responseTress.data.Success) {

          if (response.data.response.length == 0 || responseRoles.data.response.length == 0) {
            this.alertMessage =
              "No se encontró información de puestos y roles";
            this.alertTitle = "Error";
            this.show("top-right", "error");
          } else {
            this.puestosSelect = response.data.response;

            this.rolesSelect = responseRoles.data.response;

            this.empresaTressSelect = responseTress.data.response;
          }
        } else {
          this.alertMessage = response.data.Message;
          this.alertTitle = "Error";
          this.show("top-right", "error");
        }
      } catch (e) {
        this.alertMessage = "Ocurrio un error";
        this.alertTitle = "Error";
        this.show("top-right", "error");
      }
    },
    cambiarRol() {

      debugger; // eslint-disable-line no-debugger
      var self = this;
      if (self.rolSelected.rol == 2) {
        self.esRolVendendor = true;
        self.clienteModal.Usuario = self.clienteModal.EmpresaTress + self.clienteModal.NumeroVentuk;
      } else {
        self.esRolVendendor = false;
        // self.clienteModal.Usuario = '';
      }
    },
    showNotification(title, text, type) {
      this.alertTitle = title;
      this.alertMessage = text;
      this.show("top-right", type);
    },
    async saveContrasena() {
      var self = this;
      this.textoBotonGuardar = "Guardando...";
      // this.clienteModal.ActualizarNIP = this.actualizarNIP?1:0;
      this.clienteModal.ActualizarNIP = this.actualizarNIP;
      if (!this.actualizarNIP && this.clienteModal.Pass == "") {
        this.showNotification("Validacion", "Capture la contraseña", "warning");
        this.textoBotonGuardar = "Guardar";
        return;
      }
      if (this.actualizarNIP && this.clienteModal.NIP == "") {
        this.showNotification("Validacion", "Capture el NIP", "warning");
        this.textoBotonGuardar = "Guardar";
        return;
      }

      debugger; // eslint-disable-line no-debugger
      var dataToSend = {
        "Data": {
          "NumeroVentuk": this.clienteModal.NumeroVentuk,
          "Pass": self.clienteModal.Pass
        }
      };

      console.log(JSON.stringify(dataToSend));
      try {
        const response = await axios.post("Colaborador/SetColaboradorPass", dataToSend
        );
        console.log('response guardar' + response);
        if (response.data.Success) {
          this.alertMessage = "Se guardó el cliente";
          this.alertTitle = "Guardado con exito";
          this.show("top-right", "success");
          this.$bvModal.hide('modalContrasenaDialog');
        } else {
          this.alertMessage = response.data.Message;
          this.alertTitle = "Error";
          this.show("top-right", "error");
        }
        this.textoBotonGuardar = "Guardar";
      } catch (e) {
        this.alertMessage = "Ocurrio un error";
        this.alertTitle = "Error";
        this.show("top-right", "error");
      }

      this.textoBotonGuardar = "Guardar";
    },
    onchangeDecimal() {
      var self = this;
      const filteredValue = self.clienteModal.PinSantander.replace(/[^0-9]/g, '');
      self.clienteModal.PinSantander = filteredValue;
    },
    async saveCliente() {
      // if(this.clienteModal.RFC == '' ||
      // this.clienteModal.Nombre == '' ||
      // this.clienteModal.Correo == ''
      // ){
      //   this.showNotification("Validacion","Capture todos los campos","warning");
      //   return;
      // }
      var self = this;
      this.textoBotonGuardar = "Guardando...";
      if (this.clienteNuevo) {
        this.clienteModal.IdPuesto = this.puestoSelected.puesto;
        this.clienteModal.IdRol = this.rolSelected.rol;
        debugger; // eslint-disable-line no-debugger
        var dataToSend = { "Data": this.clienteModal };
        console.log(JSON.stringify(dataToSend));
        //insertar nuevo usuario
        if (this.clienteModal.NumeroEmpleado == '' ||
          this.clienteModal.Nombre == '' ||
          !(this.clienteModal.NumeroVentuk > 0) ||
          this.clienteModal.Usuario == '' ||
          this.puestoSelected.puesto == 0 ||
          this.rolSelected.rol == 0 ||
          this.clienteModal.Pass == '' ||
          this.clienteModal.NIP == '' ||
          this.clienteModal.FechaIngreso == '') {

          this.textoBotonGuardar = "Guardar";
          this.showNotification("Validacion", "Capture todos los campos marcados *", "warning");
          return;
        }
        try {

          const response = await axios.post("Colaborador/AddColaborador", dataToSend);

          debugger; // eslint-disable-line no-debugger
          console.log('response guardar' + response);
          if (response.data.Success) {
            this.alertMessage = "Se guardó el cliente";
            this.alertTitle = "Guardado con exito";
            this.show("top-right", "success");
            this.getUsuarios();
            this.$bvModal.hide('modalDialogColab');
          } else {
            this.alertMessage = response.data.Message;
            this.alertTitle = "Error";
            this.show("top-right", "error");
          }
          this.textoBotonGuardar = "Guardar";
        } catch (e) {
          this.alertMessage = "Ocurrio un error";
          this.alertTitle = "Error";
          this.show("top-right", "error");
        }

      }
      else {
        this.clienteModal.Estatus = this.clienteModal.Estatus ? 1 : 0;
        if (
          this.clienteModal.Nombre == '' ||
          this.clienteModal.Usuario == '' ||
          !(this.clienteModal.NumeroVentuk > 0) ||
          this.puestoSelected.puesto == 0 ||
          this.rolSelected.rol == 0 ||
          this.clienteModal.FechaIngreso == '') {
            this.textoBotonGuardar = "Guardar";
          this.showNotification("Validacion", "Capture todos los campos marcados *", "warning");
          return;
        }

        var colaboradorDataToSend = {
          "NumeroVentuk": self.clienteModal.NumeroVentuk,
          "Estacion": self.clienteModal.Estacion,
          "CiudadId": self.ciudadSelect,
          "Puesto": self.puestoSelected.puesto,
          "Rol": self.rolSelected.rol,
          "Usuario": self.clienteModal.Usuario,
          "Telefono": self.clienteModal.Telefono,
          "Correo": self.clienteModal.Correo,
          "PinSantander": self.clienteModal.PinSantander,
          "UsuarioIntelisis": self.clienteModal.UsuarioIntelisis
        };
        console.log(JSON.stringify(colaboradorDataToSend));
        //debugger; // eslint-disable-line no-debugger
        try {

          const response = await axios.post("Colaborador/SetColaboradorUpd",
            { "Data": colaboradorDataToSend }
          );
          //debugger; // eslint-disable-line no-debugger
          if (response.data.Success) {
            this.alertMessage = "Se guardó el usuario";
            this.alertTitle = "Guardado con exito";
            this.show("top-right", "success");

            this.getUsuarios();
            this.$bvModal.hide('modalDialogColab');
          } else {
            this.alertMessage = response.data.Message;
            this.alertTitle = "Error";
            this.show("top-right", "error");
          }
          this.textoBotonGuardar = "Guardar";
        } catch (e) {
          this.alertMessage = "Ocurrio un error";
          this.alertTitle = "Error";
          this.show("top-right", "error");
        }
      }
    },
    async getCliente(noEmpleado) {
      // this.autorizadorSelect = 0;
      // this.noAutorizadorSelect = 1;
      // this.autorizadorComprasSelect = 0;
      // this.noAutorizadorComprasSelect = "1";


      this.AutorizadoresColaborador = [];
      if (noEmpleado == '') {
        this.clienteNuevo = true;
        this.clienteModal = {
          "NumeroEmpleado": null,
          "NumeroVentuk": 0,
          "Nombre": "",
          "Usuario": "",
          "IdPuesto": 0,
          "Pass": "",
          "NIP": null,
          "Estacion": "0",
          "IdRol": 0,
          "FechaIngreso": "",
          "Estatus": 1,
          "PinSantander": ""
        }
      } else {
        this.clienteNuevo = false;
        try {
          const response = await axios.post("Colaborador/GetColaboradorADDES", { "Data": { "NoEmpleado": noEmpleado } });
          //debugger; // eslint-disable-line no-debugger
          if (response.data.Success) {
            if (response.data.response == null) {
              this.alertMessage =
                "No se encontró información del colaborador especificado.";
              this.alertTitle = "Error";
              this.show("top-right", "error");
            } else {
              this.clienteModal = response.data.response;
              this.actualizarNIP = false;
              this.puestoSelected.puesto = response.data.response.IdPuesto;
              this.rolSelected.rol = response.data.response.IdRol;
              this.clienteModal.NIP = "";
              this.clienteModal.Pass = "";
              // this.getAutorizadorColaborador(noEmpleado);

              //ToDO
              this.AutorizadoresColaborador = response.data.response.ViajesAut;
              this.AutorizadoresComprasColaborador = response.data.response.SolicitudesAut;

            }
          } else {
            this.alertMessage = response.data.Message;
            this.alertTitle = "Error";
            this.show("top-right", "error");
          }
        } catch (e) {
          this.alertMessage = "Ocurrio un error";
          this.alertTitle = "Error";
          this.show("top-right", "error");
        }
      }
    },
    show(group, type = '') {
      const text = this.alertMessage;
      this.$notify({
        group,
        title: this.alertTitle,
        text,
        type,
        data: {
          randomNumber: Math.random()
        }
      })
    },
    clean(group) {
      this.$notify({ group, clean: true })
    },
    swalNotification(swalType) {
      var btnClass = (swalType == 'error') ? 'danger' : swalType;
      btnClass = (swalType == 'question') ? 'primary' : btnClass;
      this.$swal({
        title: 'Are you sure?',
        text: 'You will not be able to recover this imaginary file!',
        type: swalType,
        showCancelButton: true,
        buttonsStyling: false,
        confirmButtonText: swalType.charAt(0).toUpperCase() + swalType.substr(1).toLowerCase(),
        cancelButtonText: 'Cancel',
        confirmButtonClass: 'btn me-5px btn-' + btnClass + '',
        cancelButtonClass: 'btn btn-default',
      })
    },
    async getUsuarios() {
      var self = this;
      this.textoBotonBuscar = "Buscando...";
      try {
        var estacion = 0;

        if (self.IdRol != 1 && self.IdRol != 10 && self.IdRol != 11 && self.IdRol != 17) {
          estacion = self.usuarioEstacion;
        }
        var dataToSend = {
          "Estacion": estacion
        };
        //debugger; // eslint-disable-line no-debugger
        const response = await axios.post("Colaborador/GetColaboradores",
          {
            "Data":
              dataToSend
          });

        if (response.data.Success) {
          if (response.data.response.length == 0) {
            this.alertMessage =
              "No se encontró información de usuarios";
            this.alertTitle = "Error";
            this.show("top-right", "error");
          } else {
            this.clientes = response.data.response;
          }
        } else {
          this.alertMessage = response.data.Message;
          this.alertTitle = "Error";
          this.show("top-right", "error");
        }
        this.textoBotonBuscar = "Buscar";
      } catch (e) {
        this.alertMessage = "Ocurrio un error";
        this.alertTitle = "Error";
        this.show("top-right", "error");
      }

    },
    async search(e) {
      e.preventDefault();
      this.getUsuarios();
    },
  },
  data() {
    return {
      AutorizadoresColaborador: [],
      autorizadores: [],
      AutorizadoresComprasColaborador: [],
      autorizadoresCompras: [],
      estaciones: [],
      ciudades: [],
      autorizadorSelect: 0,
      noAutorizadorSelect: 1,
      autorizadorComprasSelect: 0,
      noAutorizadorComprasSelect: "1",
      ciudadSelect: '',
      IdRol: 0,
      usuario: '',
      date: moment().format("YYYY-MM-DD"),
      intDate: "",
      options: {
        format: 'YYYY-MM-DD',
        useCurrent: false,
      },
      esRolVendendor: false,
      empresaTressSelected: '',
      puestoSelected: {
        puesto: 0
      },
      rolSelected: {
        rol: 0
      },
      empresaTressSelect: [],
      actualizarNIP: false,
      puestosSelect: [],
      rolesSelect: [],
      nuevoCorreo: '',
      clienteNuevo: true,
      clienteModal: {},
      textoBotonGuardar: "Guardar",
      textoBotonBuscar: "Buscar",
      clienteBuscar: "",
      columns: [
        {
          label: "Acciones",
          field: "Acciones",
          width: '10%',
          tdClass: "text-center text-nowrap",
          thClass: "text-center text-nowrap",
        },
        {
          label: "NumeroEmpleado",
          field: "NumeroEmpleado",
          type: "number",
          tdClass: "text-center text-nowrap",
          thClass: "text-center text-nowrap",
        },
        {
          label: "Numero Ventuk",
          field: "NumeroVentuk",
          type: "number",
          tdClass: "text-center text-nowrap",
          thClass: "text-center text-nowrap",
        },
        {
          label: "Nombre",
          field: "Nombre",
          tdClass: "text-center text-nowrap",
          thClass: "text-center text-nowrap",
        },
        {
          label: "Usuario",
          field: "Usuario",
          tdClass: "text-center text-nowrap",
          thClass: "text-center text-nowrap",
        },
        {
          label: "Estatus",
          field: "Estatus",
          tdClass: "text-center text-nowrap",
          thClass: "text-center text-nowrap",
        },
        {
          label: "IdPuesto",
          field: "IdPuesto",
          type: "number",
          tdClass: "text-center text-nowrap",
          thClass: "text-center text-nowrap",
        },
        {
          label: "Puesto",
          field: "Puesto",
          tdClass: "text-center text-nowrap",
          thClass: "text-center text-nowrap",
        },
        {
          label: "Estacion",
          field: "Estacion",
          type: "number",
          tdClass: "text-center text-nowrap",
          thClass: "text-center text-nowrap",
        },
        {
          label: "ROL",
          field: "ROL",
          tdClass: "text-center text-nowrap",
          thClass: "text-center text-nowrap",
        },
        {
          label: "FechaIngreso",
          field: "FechaIngreso",
          tdClass: "text-center text-nowrap",
          thClass: "text-center text-nowrap",
        },

        {
          label: "Telefono",
          field: "Telefono",
          tdClass: "text-center text-nowrap",
          thClass: "text-center text-nowrap",
        },
        {
          label: "Correo",
          field: "Correo",
          tdClass: "text-center text-nowrap",
          thClass: "text-center text-nowrap",
        },
        {
          label: "FechaAlta",
          field: "FechaAlta",
          tdClass: "text-center text-nowrap",
          thClass: "text-center text-nowrap",
        }
      ],
      clientes: [],
    };
  },
};
</script>
<style>
#modalDialogColab .modal-body {
  background: #ddd;
}
</style>