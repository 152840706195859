import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex);

const state = {
    user: null
}


const mutations = {
    user(state, user){
      state.user = user;
    }

  }


export default new Vuex.Store({
    state,
    mutations,
    getters:{
      user: (state) => {
        return state.user
      }
    },
    actions:{
      user(context, user){
        context.commit('user', user)
      }
    }
  })