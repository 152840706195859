<template>
    <div>
      <div class="loading-search" v-if="loading">
        <div class="fa-3x text-center">
          <i class="fas fa-circle-notch fa-spin text-success" style=""></i>
        </div>
      </div>
      <!-- BEGIN breadcrumb -->
      <ol class="breadcrumb float-xl-end">
              <li class="breadcrumb-item"><a href="javascript:;">Operación</a></li>
              <li class="breadcrumb-item"><a href="javascript:;">Comisiones</a></li>
              <li class="breadcrumb-item active"><a href="javascript:;">Envio de ventas</a></li>
      </ol>
      <!-- END breadcrumb -->
      <!-- BEGIN page-header -->
      <h1 class="page-header">Envio de ventas</h1>
      <!-- END page-header -->
  
  
  
          <form @submit.prevent="search">   
      <div class="row">
        <div class="col-md-8"> 
            <div class="form-group row">
              
              <div class="col-md-3">
                  <label class="col-form-label">Fecha inicial</label>
                <div class="input-group">
                  <date-picker id="dateTimeInput" v-model="date" :config="options"></date-picker>
                  <label for="dateTimeInput" class="input-group-text">
                    <i class="fa fa-calendar"></i>
                  </label>
                </div>
              </div>
  
              
              <!-- <div class="col-md-3">
                  <label class="col-form-label">Fecha final</label>
                <div class="input-group">
                  <date-picker id="dateTimeInputEnd" v-model="dateEnd" :config="options"></date-picker>
                  <label for="dateTimeInputEnd" class="input-group-text">
                    <i class="fa fa-calendar"></i>
                  </label>
                </div>
              </div> -->
              <div class="col-md-3">
                  <label class="col-form-label">Ciudad</label>
                  <select class="form-select" @change="getEstaciones()" v-model="ciudadSelect" :disabled="deshabilitarCombos">
                      <option v-for="ciudad in ciudades" :value="ciudad.Id" v-bind:key="ciudad.Id">
                          {{ ciudad.Nombre }}
                      </option>
                  </select>
              </div>
              <div class="col-md-3">
                  <label class="col-form-label">Estación</label>
                  <select class="form-select" v-model="estacionSelect" :disabled="deshabilitarCombos">
                    <option value="0">Todas</option>
                      <option v-for="estacion in estaciones" :value="estacion.NoEstacion" v-bind:key="estacion.NoEstacion">
                      {{ estacion.Nombre }}
                      </option>
                    </select>
              </div>
  
              <div class="col-md-3">
                <label class="col-form-label">Turno</label>
                <select v-model="turnoSelected.IdTurno" class="form-control">
                    <option v-for="turno in turnos" :value="turno.IdTurno" v-bind:key="turno.IdTurno">
                    {{ turno.Descripcion }}
                    </option>
                </select>
            </div>
  
              <!-- <div class="col-md-6">
                <button type="submit" class="btn btn-success me-1 mb-1">{{textoBotonBuscar}}</button>
              </div> -->
            </div>
        </div>
        <div class="col-md-2">
              <button type="submit" class="btn btn-success me-1 mb-1" style="margin-top: 3.1em;">{{textoBotonBuscar}}</button>
        </div>
        <div class="col-md-2">
              <button type="button" class="btn btn-success me-1 mb-1" @click="validarComision()" :disabled="reporteVentas.length == 0" style="margin-top: 3.1em;">Guardar</button>
        </div>
      </div>
  
          </form>
      <panel title="Envio de ventas" bodyClass="p-0" class="">
        <vue-good-table
          :columns="columns"
          :rows="reporteVentas"
          :lineNumbers="false"
          :search-options="{ enabled: true, placeholder: 'Buscar' }"
          :pagination-options="{ enabled: true, position: 'bottom' }"
          :sort-options="{
            enabled: true,
            initialSortBy: {field: 'Fecha', type: 'desc'}
          }"
        >
          <template slot="table-row" slot-scope="props">
            <span v-if="props.column.label == 'Acciones'">
              <!-- <button class="btn btn-xs btn-success" @click="getArchivos(props.formattedRow, true)">Detalle</button> -->
              <button class="btn btn-xs btn-success" @click="autorizar(props.row)">Boton</button>
            </span>
          </template>
        </vue-good-table>
      </panel>
      
  
  
  
  
  
      <!-- bottom left vue-notification -->
      <notifications group="top-right" position="top right" :speed="1000" />
    </div>
  </template>
  
  <script>
  
  import axios from "axios";
  import moment from 'moment';
  export default {
  async	created() {
    
      },
    mounted: function () {
        debugger; // eslint-disable-line no-debugger
        var userSetting = JSON.parse(localStorage.getItem('userSettings'));
        this.usuario = userSetting.Usuario;
        this.idUsuario = userSetting.NumeroEmpleado;
        this.nombreLogueado = userSetting.Nombre;
        this.usuarioEstacion = userSetting.Estacion;
        this.IdRol = userSetting.IdRol;
        this.getCiudades();
        this.ciudadSelect = userSetting.CiudadId;
        this.getEstaciones();
        this.estacionSelect = userSetting.Estacion;

        this.GetTurnos();

        if(this.IdRol == 3 || this.IdRol == 7){
            this.deshabilitarCombos = true;
        }
  
  
      
  
    },
    methods: {
    async GetTurnos(){
      try {
        const response = await axios.get("Catalogos/GetTurnos");
        if (response.data.Success) {
          if (response.data.response.length == 0) {
            this.alertMessage =
              "No se encontraron turnos";
            this.alertTitle = "Error";
            this.show("top-right", "error");
          } else {
            this.turnos = response.data.response;
          }
        } else {
          this.alertMessage = response.data.Message;
          this.alertTitle = "Error";
          this.show("top-right", "error");
        }
      } catch (e) {
        this.alertMessage = "Ocurrio un error";
        this.alertTitle = "Error";
        this.show("top-right", "error");
      }
    },
      async getCiudades(){
          try{
          const response = await axios.get('Catalogos/GetCiudades');
          //debugger; // eslint-disable-line no-debugger
          if(response.data.Success){
            if(response.data.response.length == 0){
              this.alertMessage = "No se encontró información.";
              this.alertTitle = "Error";
              this.show('top-right', 'error');
            }
          }else{
              console.log("error")
              this.alertMessage = response.data.Message;
              this.alertTitle = "Error";
              this.show('top-right', 'error');
          }
          this.ciudades = response.data.response;
        }catch(e){
          //this.alert = true;
          //this.error = 'Invalid email/password';
        }
      },
      async getEstaciones(){
          try{
          debugger; // eslint-disable-line no-debugger
          const response = await axios.post('Estacion/GetEstaciones?ciudad='+this.ciudadSelect);
          if(response.data.Success){
            if(response.data.response.length == 0){
              this.alertMessage = "No se encontró información.";
              this.alertTitle = "Error";
              this.show('top-right', 'error');
            }
          }else{
              console.log("error")
              this.alertMessage = response.data.Message;
              this.alertTitle = "Error";
              this.show('top-right', 'error');
          }
          this.estaciones = response.data.response;
          this.productos = [];
          this.getProductos();
        }catch(e){
          //this.alert = true;
          //this.error = 'Invalid email/password';
        }
      },
      validarFecha(){
        var self = this;

        var searchDate = self.fechaDeBusqueda.split("/");

        var currentDate = moment().format("YYYY/MM/DD").split("/");

        debugger; // eslint-disable-line no-debugger
        if(moment().format("YYYY/MM") === moment(self.fechaDeBusqueda).format("YYYY/MM")){
          return true;
        }else if(parseInt(moment().format("YYYY/MM")) <= 4){
          return true;
        }else{
          false;
        }

        return false;

        

        

      },
      async validarComision(){
        debugger; // eslint-disable-line no-debugger
        var self = this;
        
        // if(!self.validarFecha()){
        //     this.alertMessage = "Expirado";
        //     this.alertTitle = "";
        //     this.show("top-right", "error");
        // }

        

        var postData = {
            "Data": {
              "Estacion": self.estacionBuscada,
              "Turno": self.turnoBuscado,
              "Fecha": self.intDate
            }
          };
              
          try {
            const response = await axios.post("Comision/GetComisionId",postData);
            
            if (response.data.Success) {
              
              debugger; // eslint-disable-line no-debugger
              if (response.data.response == 0) {
                
                self.setComision(0, false);

              } 
              if(response.data.response > 0){
                if(confirm("Ya existe información del día y turno especificado, desea reemplezarlo?")){
                  self.setComision(response.data.response, true);
                }
                
              }
            } else {
              debugger; // eslint-disable-line no-debugger
              this.alertMessage = response.data.Message;
              this.alertTitle = "Error";
              this.show("top-right", "error");
            }
            this.textoBotonBuscar = "Buscar";
          } catch (e) {
              this.alertMessage = "Ocurrio un error";
              this.alertTitle = "Error";
              this.show("top-right", "error");
          }
  
          
  
      },
      
      async setComision(id, actualizar){
        debugger; // eslint-disable-line no-debugger
        var self = this;
        
        var postData = {
            "Data": {
              "Id": id,
              "Fecha": self.intDate,
              "Estacion": self.estacionBuscada,
              "Gasolinera": self.reporteVentas[0].Gasolinera,
              "Turno": self.turnoBuscado,
              "Usuario": self.usuario,
              "Actualizar": actualizar,
              "Ventas": self.reporteVentas
            }
          };
              
          try {
            const response = await axios.post("Comision/SetComision", postData);
            
            if (response.data.Success) {
              
                debugger; // eslint-disable-line no-debugger
                this.alertMessage = response.data.Message;
                this.alertTitle = response.data.Error;
                this.show("top-right", "success");
                self.reporteVentas = [];
                
            } else {
              debugger; // eslint-disable-line no-debugger
              this.alertMessage = response.data.Message;
              this.alertTitle = "Error";
              this.show("top-right", "error");
            }
            this.textoBotonBuscar = "Buscar";
          } catch (e) {
              this.alertMessage = "Ocurrio un error";
              this.alertTitle = "Error";
              this.show("top-right", "error");
          }
  
          
  
      },
      async autorizar(item){
        debugger; // eslint-disable-line no-debugger
        var self = this;
        var arrayDate = item.Fecha.split("T");
        var stringDate = arrayDate[0];
        var intDate = stringDate.replace('-', '').replace('-', '');
        // intDate = intDate.replace('-', '');
        
        var postData = {
                "Data": {
                  "FechaInicial":intDate,
                }
              };
              
              
        if(confirm("Desea autorizar el Folio?")){
          try {
            const response = await axios.post("Fajilla/AuthFajilla",postData);
            
            if (response.data.Success) {
              
            debugger; // eslint-disable-line no-debugger
              if (response.data.response.length == 0) {
                this.alertMessage = "Error";
                this.alertTitle = "Error";
                this.show("top-right", "error");
              } else {
                
                
                this.alertMessage = "Se autorizó correctamente";
                this.alertTitle = "Autorizado";
                this.show("top-right", "success");
                
                self.getData();
              }
            } else {
            debugger; // eslint-disable-line no-debugger
              this.alertMessage = response.data.Message;
              this.alertTitle = "Error";
              this.show("top-right", "error");
            }
            this.textoBotonBuscar = "Buscar";
          } catch (e) {
              this.alertMessage = "Ocurrio un error";
              this.alertTitle = "Error";
              this.show("top-right", "error");
          }
  
        }
  
      },
              show (group, type = '') {
                  const text = this.alertMessage;
                  this.$notify({
                      group,
                      title: this.alertTitle,
                      text,
                      type,
                      data: {
                          randomNumber: Math.random()
                      }
                  })
              },
              clean (group) {
                  this.$notify({ group, clean: true })
              },
              swalNotification(swalType) {
                  var btnClass = (swalType == 'error') ? 'danger' : swalType;
                      btnClass = (swalType == 'question') ? 'primary' : btnClass;
                  this.$swal({
                      title: 'Are you sure?',
                      text: 'You will not be able to recover this imaginary file!',
                      type: swalType,
                      showCancelButton: true,
                      buttonsStyling: false,
                      confirmButtonText: swalType.charAt(0).toUpperCase() + swalType.substr(1).toLowerCase(),
                      cancelButtonText: 'Cancel',
                      confirmButtonClass: 'btn me-5px btn-'+ btnClass +'',
                      cancelButtonClass: 'btn btn-default',
                  })
              },
        async getData(){
          
            debugger; // eslint-disable-line no-debugger
            var self = this;
            self.loading = true;
            self.textoBotonBuscar = "Buscando...";
            try{
                self.fechaDeBusqueda = self.date;
                self.intDate = self.date.replace('/', '');
                self.intDate = self.intDate.replace('/', '');
        
        
                // var ciudad = self.ciudadSelect!=undefined?self.ciudadSelect:0;
                var estacion = self.estacionSelect!=undefined?self.estacionSelect:0;
                var turno = self.turnoSelected != undefined ? self.turnoSelected.IdTurno : 0;

                self.estacionBuscada = estacion;
                self.turnoBuscado = turno;
                const response = await axios.post('Ventas/GetVentaProductos' ,{
                "Data": {
                    "FechaInicial": self.intDate,
                    "Turno": turno, 
                    "Estacion": estacion       
                    }
                });
                
            debugger; // eslint-disable-line no-debugger
                if(response.data.Success){
                    if(response.data.response.length == 0){
                    self.alertMessage = "No se encontro información del día especificado.";
                    self.alertTitle = "Error";
                    self.show('top-right', 'error');
                    self.reporteVentas = [];
                    }
                }else{
                    console.log("error")
                    self.alertMessage = response.data.Message;
                    self.alertTitle = "Error";
                    self.show('top-right', 'error');
                    self.reporteVentas = [];
                }
                
                self.reporteVentas = response.data.response;
        
                self.textoBotonBuscar = "Buscar";
                
                self.loading = false;
            }catch(e){
                self.loading = false;
                //this.alert = true;
                //this.error = 'Invalid email/password';
            }
  
        },
      async search(e) {
        e.preventDefault();
        this.getData();
      },
    },
    data() {
      return {
        estacionBuscada:"",
        turnoBuscado:"",
        fechaDeBusqueda: "",
        turnos:[],
        turnoSelected:{
            IdTurno:1
        },
        loading:false,
        nombreLogueado:"",
        idUsuario:undefined,
        reporteVentas:[],
        deshabilitarCombos:false,
        ciudades: [],
        estaciones: [],
        ciudadSelect: 1,
        estacionSelect: 0,
        ventaDetalle:[],
        emailReenviar:"",
        uuidReenviar:"",
        date: moment().format("YYYY/MM/DD"),
        intDateEnd: "",
        dateEnd: moment().format("YYYY/MM/DD"),
        intDate: "",
        options: {
          format: 'YYYY/MM/DD',
          useCurrent: false,
        },
        puestoSelected:{
            puesto:0
        },
        rolSelected:{
            rol:0
        },
        actualizarNIP:false,
        puestosSelect:[],
        rolesSelect:[],
        nuevoCorreo: '',
        clienteNuevo: true,
        clienteModal:{},
        textoBotonGuardar: "Guardar",
        textoBotonBuscar: "Buscar",
        clienteBuscar: "",
        columns: [

        {
      label: "Fecha",
      field: "Fecha",
      tdClass: "text-center text-nowrap",
      thClass: "text-center text-nowrap",
  },
  {
      label: "Estacion",
      field: "Estacion",
      tdClass: "text-center text-nowrap",
      thClass: "text-center text-nowrap",
  },
  {
      label: "Turno",
      field: "Turno",
      tdClass: "text-center text-nowrap",
      thClass: "text-center text-nowrap",
  },
  {
      label: "NoEmpleado",
      field: "NoEmpleado",
      tdClass: "text-center text-nowrap",
      thClass: "text-center text-nowrap",
  },
  {
      label: "Nombre",
      field: "Nombre",
      tdClass: "text-center text-nowrap",
      thClass: "text-center text-nowrap",
  },
  {
      label: "Bomba",
      field: "Bomba",
      tdClass: "text-center text-nowrap",
      thClass: "text-center text-nowrap",
  },
  {
      label: "Producto",
      field: "Producto",
      tdClass: "text-center text-nowrap",
      thClass: "text-center text-nowrap",
  },
  {
      label: "Cantidad",
      field: "Cantidad",
      tdClass: "text-center text-nowrap",
      thClass: "text-center text-nowrap",
  },
  {
      label: "Total",
      field: "Total",
      tdClass: "text-center text-nowrap",
      thClass: "text-center text-nowrap",
  },
  {
      label: "Comisión por Producto",
      field: "ComisionProducto",
      tdClass: "text-center text-nowrap",
      thClass: "text-center text-nowrap",
  },
  {
      label: "Comisión",
      field: "Comision",
      tdClass: "text-center text-nowrap",
      thClass: "text-center text-nowrap",
  },
  
        ],
        items: [],
      };
    },
  };
  </script>
  <style scoped>
  .loading-search{
    position: absolute;
      width: 100%;
      left: 0px;
      height: 100%;
      background: rgba(0, 0, 0, 0.1);
      z-index: 1000;
      padding-top: 150px;
      top: 0;
  }
  </style>