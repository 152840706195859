<template>
    <div>
    <div class=" loading-search" v-if="loading">
      <div class="fa-3x text-center">
        <i class="fas fa-circle-notch fa-spin text-success" style=""></i>
      </div>
    </div>
      <!-- BEGIN breadcrumb -->
      <ol class="breadcrumb float-xl-end">
        <li class="breadcrumb-item"><a href="javascript:;">Catalogos</a></li>
        <li class="breadcrumb-item active">
          <a href="javascript:;">Clientes</a>
        </li>
      </ol>
      <!-- END breadcrumb -->
      <!-- BEGIN page-header -->
      <h1 class="page-header">Clientes</h1>
      <!-- END page-header -->
  
      <div class="row">
        <div class="col-md-12">
          <!-- <form @submit.prevent="search"> -->
            <div class="form-group row">
              <div class="col-md-3">
                <label class="col-md-4 col-form-label">Año</label>
                <div class="col-md-8">
                  <select class="form-select" name="" id="" v-model="currentYear">
                    <option :value="year.Year" v-for="year in years" :key="year.Year">{{year.Year}}</option>
                  </select>
                </div>
              </div>

              <div class="col-md-4">
                
                <label class="col-md-4 col-form-label">Archivo</label>
                <div class="col-md-8">
                  <input type="file" id="archivoExcel" @change="subirExcel()">
                </div>
              </div>
              <div class="col-md-5" style="padding-top: 35px; text-align: right;">
                <button class="btn btn-success" style="width: 100px; cursor: pointer;" :disabled="!(items.length > 1)" @click="enviarLista()">
                  {{textoBotonEnviar}}
                </button>
              </div>
            </div>
          <!-- </form> -->
        </div>
        
        <div class="col-md-12" style="padding-top: 15px; padding-bottom: 15px;">
          <label for="" v-if="items.length>1">Registros {{items.length-1}}</label>
        </div>
      </div>
      <panel title="Importar desde excel" bodyClass="p-0" class="">
        <div>
          <div class="row">
            <div class="col-md-12">
            </div>
          </div>
        </div>
        <div>
						<!-- BEGIN table-responsive -->
						<div class="table-responsive" style="height: 500px;">
							<table class="table table-bordered table-striped mb-0">

								<tbody>
									<tr  v-for="(item, index) in items" v-bind:key="item">
                    <td>
                        <span v-if="index != 0">{{item[0]}}</span>
                        <span v-else><b>{{item[0]}}</b></span>
                    </td>
                    <td>
                        <span v-if="index != 0">{{item[1]}}</span>
                        <span v-else><b>{{item[1]}}</b></span>
                    </td>
                    <td>
                        <span v-if="index != 0">{{item[2]}}</span>
                        <span v-else><b>{{item[2]}}</b></span>
                    </td>

                    <td>
                        <span v-if="index != 0">{{item[3]}}</span>
                        <span v-else><b>{{item[3]}}</b></span>
                    </td>
                    <td>
                        <span v-if="index != 0">{{item[4] | currency}}</span>
                        <span v-else><b>{{item[4]}}</b></span>
                    </td>
                    <td>
                        <span v-if="index != 0">{{item[5] | currency}}</span>
                        <span v-else><b>{{item[5]}}</b></span>
                    </td>
                    <td>
                        <span v-if="index != 0">{{item[6] | currency}}</span>
                        <span v-else><b>{{item[6]}}</b></span>
                    </td>
                    <td>
                        <span v-if="index != 0">{{item[7] | currency}}</span>
                        <span v-else><b>{{item[7]}}</b></span>
                    </td>
                    <td>
                        <span v-if="index != 0">{{item[8] | currency}}</span>
                        <span v-else><b>{{item[8]}}</b></span>
                    </td>
                    <td>
                        <span v-if="index != 0">{{item[9] | currency}}</span>
                        <span v-else><b>{{item[9]}}</b></span>
                    </td>
                    <td>
                        <span v-if="index != 0">{{item[10] | currency}}</span>
                        <span v-else><b>{{item[10]}}</b></span>
                    </td>
                    <td>
                        <span v-if="index != 0">{{item[11] | currency}}</span>
                        <span v-else><b>{{item[11]}}</b></span>
                    </td>
                    <td>
                        <span v-if="index != 0">{{item[12] | currency}}</span>
                        <span v-else><b>{{item[12]}}</b></span>
                    </td>
                    <td>
                        <span v-if="index != 0">{{item[13] | currency}}</span>
                        <span v-else><b>{{item[13]}}</b></span>
                    </td>
                    <td>
                        <span v-if="index != 0">{{item[14] | currency}}</span>
                        <span v-else><b>{{item[14]}}</b></span>
                    </td>
                    <td>
                        <span v-if="index != 0">{{item[15] | currency}}</span>
                        <span v-else><b>{{item[15]}}</b></span>
                    </td>
                    <td>
                        <span v-if="index != 0">{{item[16] | currency}}</span>
                        <span v-else><b>{{item[16]}}</b></span>
                    </td>
                    <td>
                        <span v-if="index != 0">{{item[17] | currency}}</span>
                        <span v-else><b>{{item[17]}}</b></span>
                    </td>
                    <td>
                        <span v-if="index != 0">{{item[18] | currency}}</span>
                        <span v-else><b>{{item[18]}}</b></span>
                    </td>
                    <td>
                        <span v-if="index != 0">{{item[19] | currency}}</span>
                        <span v-else><b>{{item[19]}}</b></span>
                    </td>
                    <td>
                        <span v-if="index != 0">{{item[20] | currency}}</span>
                        <span v-else><b>{{item[20]}}</b></span>
                    </td>
                    <td>
                        <span v-if="index != 0">{{item[21] | currency}}</span>
                        <span v-else><b>{{item[21]}}</b></span>
                    </td>
                    <td>
                        <span v-if="index != 0">{{item[22] | currency}}</span>
                        <span v-else><b>{{item[22]}}</b></span>
                    </td>
                    <td>
                        <span v-if="index != 0">{{item[23] | currency}}</span>
                        <span v-else><b>{{item[23]}}</b></span>
                    </td>
                    <td>
                        <span v-if="index != 0">{{item[24] | currency}}</span>
                        <span v-else><b>{{item[24]}}</b></span>
                    </td>
                    <td>
                        <span v-if="index != 0">{{item[25] | currency}}</span>
                        <span v-else><b>{{item[25]}}</b></span>
                    </td>
                    <td>
                        <span v-if="index != 0">{{item[26] | currency}}</span>
                        <span v-else><b>{{item[26]}}</b></span>
                    </td>
                    <td>
                        <span v-if="index != 0">{{item[27] | currency}}</span>
                        <span v-else><b>{{item[27]}}</b></span>
                    </td>

                    <td>
                        <span v-if="index != 0">{{item[28]}}</span>
                        <span v-else><b>{{item[28]}}</b></span>
                    </td>
                    <td>
                        <span v-if="index != 0">{{item[29]}}</span>
                        <span v-else><b>{{item[29 ]}}</b></span>
                    </td>


									</tr>
								</tbody>
							</table>
						</div>
						<!-- END table-responsive -->
					</div>

          
      </panel>
  
  
  
      <!-- bottom left vue-notification -->
      <notifications group="top-right" position="top right" :speed="1000" />
    </div>
  </template>
  
  <script>
  import axios from "axios";
  import readXlsFile from "read-excel-file"
  import moment from 'moment';import Vue from "vue";
var numeral = require("numeral");
  Vue.filter("formatNumber", function (value) {
    return numeral(value).format("0,00.00"); // displaying other groupings/separators is possible, look at the docs
  });

  Vue.filter("currency", function (value) {
    return numeral(value).format("$0,0.00"); 
  });
  export default {
    mounted: function () {
      var self = this;
    var userSetting = JSON.parse(localStorage.getItem('userSettings'));
    self.usuario = userSetting.Usuario;
      var currentYear = parseInt(moment().format("YYYY"));

      self.currentYear = currentYear;
      self.years.push({"Year":currentYear});
      self.years.push({"Year":currentYear-1});

    },
    watch:{
      currentYear(val){
        debugger; // eslint-disable-line no-debugger
        var self = this;

        if(self.jsonEnviar.length > 1){

            for(var n = 0 ; n < self.jsonEnviar.length ; n++){
              self.jsonEnviar[n].Ejercicio =  val;
          }
        }
      }
    },
    methods: {
      showIfValue(){
        return true;
      },
    async enviarLista(){
    var self = this;
    self.textoBotonEnviar = "Enviando...";
    self.loading = true;
      try {
        
        debugger; // eslint-disable-line no-debugger
        var dataToSend = {
          Data: {
            "Ventas":self.jsonEnviar,
            }
        };
        const response = await axios.post("Prepago/SetPrepagoVentas", dataToSend);

        self.loading = false;
        debugger; // eslint-disable-line no-debugger
        if (response.data.Success) {
          this.alertMessage = response.data.Message;
          this.alertTitle = "Datos guardados correctamente";
          this.show("top-right", "success");
        }
        else{
          
          this.alertMessage = response.data.Error+": "+response.data.Message;
          this.alertTitle = "Error";
          this.show("top-right", "error");
        }

        self.textoBotonEnviar = "Enviar";

      } catch (e) {
        self.loading = false;
        this.alertMessage = "Ocurrio un error";
        this.alertTitle = "Error";
        this.show("top-right", "error");
      }
    },
      construirJSON(rows){
        var self = this;
              debugger; // eslint-disable-line no-debugger
        for(var n = 1 ; n < rows.length ; n++){
          self.items[n][3] = moment(self.items[n][3]).format('MM/DD/YYYY');
          var fechaInt = moment(self.items[n][3]).format('YYYYMMDD');
          var fila =   {
            "Campana": rows[n][0],
            "NombreCliente": rows[n][1],
            "RFC": rows[n][2],
            "FechaAlta": fechaInt,
            "VentaEnero": rows[n][4],
            "VentaFebrero": rows[n][5],
            "VentaMarzo": rows[n][6],
            "VentaAbril": rows[n][7],
            "VentaMayo": rows[n][8],
            "VentaJunio": rows[n][9],
            "VentaJulio": rows[n][10],
            "VentaAgosto": rows[n][11],
            "VentaSeptiembre": rows[n][12],
            "VentaOctubre": rows[n][13],
            "VentaNoviembre": rows[n][14],
            "VentaDiciembre": rows[n][15],
            "FacturadoEnero": rows[n][16],
            "FacturadoFebrero": rows[n][17],
            "FacturadoMarzo": rows[n][18],
            "FacturadoAbril": rows[n][19],
            "FacturadoMayo": rows[n][20],
            "FacturadoJunio": rows[n][21],
            "FacturadoJulio": rows[n][22],
            "FacturadoAgosto": rows[n][23],
            "FacturadoSeptiembre": rows[n][24],
            "FacturadoOctubre": rows[n][25],
            "FacturadoNoviembre": rows[n][26],
            "FacturadoDiciembre": rows[n][27],
            "Ejercicio": self.currentYear,
            "Usuario": self.usuario
          };
            debugger; // eslint-disable-line no-debugger
            self.jsonEnviar.push(fila);
        }
      },
        subirExcel(){
            const input= document.getElementById("archivoExcel");
            debugger; // eslint-disable-line no-debugger
            readXlsFile(input.files[0]).then((rows) => {
              debugger; // eslint-disable-line no-debugger
              this.items =  rows;
              this.construirJSON(rows);
            })
        },
      showNotification(title, text, type) {
        this.alertTitle = title;
        this.alertMessage = text;
        this.show("top-right", type);
      },
      show(group, type = "") {
        const text = this.alertMessage;
        this.$notify({
          group,
          title: this.alertTitle,
          text,
          type,
          data: {
            randomNumber: Math.random(),
          },
        });
      },
      clean(group) {
        this.$notify({ group, clean: true });
      },
      swalNotification(swalType) {
        var btnClass = swalType == "error" ? "danger" : swalType;
        btnClass = swalType == "question" ? "primary" : btnClass;
        this.$swal({
          title: "Are you sure?",
          text: "You will not be able to recover this imaginary file!",
          type: swalType,
          showCancelButton: true,
          buttonsStyling: false,
          confirmButtonText:
            swalType.charAt(0).toUpperCase() + swalType.substr(1).toLowerCase(),
          cancelButtonText: "Cancel",
          confirmButtonClass: "btn me-5px btn-" + btnClass + "",
          cancelButtonClass: "btn btn-default",
        });
      },
      
    },
    data() {
      return {
        loading: false,
        usuario:'',
        textoBotonEnviar: "Enviar",
        currentYear:0,
        years:[],
        jsonEnviar:[],
        items:[],
        cabecera: [
            {text: "Nombre", value: '0'},
            {text: "Apellido", value: '1'},
        ],
      };
    },
  };
  </script>

  
<style scoped>

.loading-search{
  position: absolute;
    width: 100%;
    left: 0px;
    height: 100%;
    background: rgba(0, 0, 0, 0.1);
    z-index: 1000;
    padding-top: 150px;
    top: 0;
}
</style>