<template>
    <div>
    <div class=" loading-search" v-if="loading">
      <div class="fa-3x text-center">
        <i class="fas fa-circle-notch fa-spin text-success" style=""></i>
      </div>
    </div>
      <!-- BEGIN breadcrumb -->
      <ol class="breadcrumb float-xl-end">
        <li class="breadcrumb-item"><a href="javascript:;">BI</a></li>
        <li class="breadcrumb-item"><a href="javascript:;">Cargar Datos</a></li>
        <li class="breadcrumb-item active">
          <a href="javascript:;">Cliente Oculto</a>
        </li>
      </ol>
      <!-- END breadcrumb -->
      <!-- BEGIN page-header -->
      <h1 class="page-header">Calificaciones cliente oculto</h1>
      <!-- END page-header -->
  
      <div class="row">
        <div class="col-md-12">
          <!-- <form @submit.prevent="search"> -->
            <div class="form-group row">
              <div class="col-md-3">
                <label class="col-md-4 col-form-label">Año</label>
                <div class="col-md-8">
                  <select class="form-select" name="" id="" v-model="currentYear">
                    <option :value="year.Year" v-for="year in years" :key="year.Year">{{year.Year}}</option>
                  </select>
                </div>
              </div>

              <div class="col-md-3">
                <label class="col-md-4 col-form-label">Mes</label>
                <div class="col-md-8">
                  <select class="form-select" name="" id="" v-model="periodoSelect">
                    <option value="1">Enero</option>
                    <option value="2">Febrero</option>
                    <option value="3">Marzo</option>
                    <option value="4">Abril</option>
                    <option value="5">Mayo</option>
                    <option value="6">Junio</option>
                    <option value="7">Julio</option>
                    <option value="8">Agosto</option>
                    <option value="9">Septiembre</option>
                    <option value="10">Octubre</option>
                    <option value="11">Noviembre</option>
                    <option value="12">Diciembre</option>
                  </select>
                </div>
              </div>

              <div class="col-md-3">
                
                <label class="col-md-4 col-form-label">Archivo</label>
                <div class="col-md-8">
                  <input type="file" id="archivoExcel" @change="subirExcel()">
                </div>
              </div>
              <div class="col-md-3" style="padding-top: 35px; text-align: right;">
                <button class="btn btn-success" style="width: 100px; cursor: pointer;" :disabled="!(items.length > 1)" @click="GetClienteOculto()">
                  {{textoBotonEnviar}}
                </button>
              </div>
            </div>
          <!-- </form> -->
        </div>
        
        <div class="col-md-12" style="padding-top: 15px; padding-bottom: 15px;">
          <label for="" v-if="items.length>1">Registros {{items.length-1}}</label>
        </div>
      </div>
      <panel title="Importar desde excel" bodyClass="p-0" class="">
        <div>
          <div class="row">
            <div class="col-md-12">
            </div>
          </div>
        </div>
        <div>
						<!-- BEGIN table-responsive -->
						<div class="table-responsive" style="height: 500px;">
							<table class="table table-bordered table-striped mb-0">

								<tbody>
									<tr  v-for="(item, index) in items" v-bind:key="item">
                                        <td>
                                            <span v-if="index != 0">{{item[0]}}</span>
                                            <span v-else><b>{{item[0]}}</b></span>
                                        </td>
                                        <td>
                                            <span v-if="index != 0">{{item[1]}}</span>
                                            <span v-else><b>{{item[1]}}</b></span>
                                        </td>
                                        <td>
                                            <span v-if="index != 0">{{item[2]}}</span>
                                            <span v-else><b>{{item[2]}}</b></span>
                                        </td>

                                        <td style="text-align: center;" v-if="periodoSelect == '1'">
                                            <span v-if="index != 0">{{item[3]*100 | formatNumber }}%</span>
                                            <span v-else><b>{{item[3]}}</b></span>
                                        </td>
                                        <td style="text-align: center;" v-if="periodoSelect == '2'">
                                            <span v-if="index != 0">{{item[4]*100 | formatNumber }}%</span>
                                            <span v-else><b>{{item[4]}}</b></span>
                                        </td>
                                        <td style="text-align: center;" v-if="periodoSelect == '3'">
                                            <span v-if="index != 0">{{item[5]*100 | formatNumber }}%</span>
                                            <span v-else><b>{{item[5]}}</b></span>
                                        </td>
                                        <td style="text-align: center;" v-if="periodoSelect == '4'">
                                            <span v-if="index != 0">{{item[6]*100 | formatNumber }}%</span>
                                            <span v-else><b>{{item[6]}}</b></span>
                                        </td>
                                        <td style="text-align: center;" v-if="periodoSelect == '5'">
                                            <span v-if="index != 0">{{item[7]*100 | formatNumber }}%</span>
                                            <span v-else><b>{{item[7]}}</b></span>
                                        </td>
                                        <td style="text-align: center;" v-if="periodoSelect == '6'">
                                            <span v-if="index != 0">{{item[8]*100 | formatNumber }}%</span>
                                            <span v-else><b>{{item[8]}}</b></span>
                                        </td>
                                        <td style="text-align: center;" v-if="periodoSelect == '7'">
                                            <span v-if="index != 0">{{item[9]*100 | formatNumber }}%</span>
                                            <span v-else><b>{{item[9]}}</b></span>
                                        </td>
                                        <td style="text-align: center;" v-if="periodoSelect == '8'">
                                            <span v-if="index != 0">{{item[10]*100 | formatNumber }}%</span>
                                            <span v-else><b>{{item[10]}}</b></span>
                                        </td>
                                        <td style="text-align: center;" v-if="periodoSelect == '9'">
                                            <span v-if="index != 0">{{item[11]*100 | formatNumber }}%</span>
                                            <span v-else><b>{{item[11]}}</b></span>
                                        </td>
                                        <td style="text-align: center;" v-if="periodoSelect == '10'">
                                            <span v-if="index != 0">{{item[12]*100 | formatNumber }}%</span>
                                            <span v-else><b>{{item[12]}}</b></span>
                                        </td>
                                        <td style="text-align: center;" v-if="periodoSelect == '11'">
                                            <span v-if="index != 0">{{item[13]*100 | formatNumber }}%</span>
                                            <span v-else><b>{{item[13]}}</b></span>
                                        </td>
                                        <td style="text-align: center;" v-if="periodoSelect == '12'">
                                            <span v-if="index != 0">{{item[14]*100 | formatNumber }}%</span>
                                            <span v-else><b>{{item[14]}}</b></span>
                                        </td>


									</tr>
								</tbody>
							</table>
						</div>
						<!-- END table-responsive -->
					</div>

          
      </panel>
  
  
  
      <!-- bottom left vue-notification -->
      <notifications group="top-right" position="top right" :speed="1000" />
    </div>
  </template>
  
  <script>
  import axios from "axios";
  import readXlsFile from "read-excel-file"
  import moment from 'moment';import Vue from "vue";
var numeral = require("numeral");
  Vue.filter("formatNumber", function (value) {
    return numeral(value).format("0,00.00"); // displaying other groupings/separators is possible, look at the docs
  });

  Vue.filter("currency", function (value) {
    return numeral(value).format("$0,0.00"); 
  });
  export default {
    mounted: function () {
      var self = this;
    var userSetting = JSON.parse(localStorage.getItem('userSettings'));
    self.usuario = userSetting.Usuario;
      var currentYear = 2023;//parseInt(moment().format("YYYY"));

      self.currentYear = currentYear;
      self.years.push({"Year":currentYear});
      self.years.push({"Year":currentYear+1});
      self.years.push({"Year":currentYear+2});
      self.years.push({"Year":currentYear+3});
      self.years.push({"Year":currentYear+4});

    },
    watch:{
    //   currentYear(val){
    //     debugger; // eslint-disable-line no-debugger
    //     var self = this;

    //     if(self.jsonEnviar.length > 1){

    //         for(var n = 0 ; n < self.jsonEnviar.length ; n++){
    //           self.jsonEnviar[n].Ejercicio =  val;
    //       }
    //     }
    //   }
    },
    methods: {
      showIfValue(){
        return true;
      },
    async GetClienteOculto(){
        var self = this;
        self.textoBotonEnviar = "Enviando...";
        self.loading = true;
      try {
        self.construirJSON(self.items);
        debugger; // eslint-disable-line no-debugger
        var dataToSend = {
            "Data": {
                "Ejercicio": self.currentYear,
                "Periodo": self.periodoSelect
            }
        };
        const response = await axios.post("BI/GetClienteOculto", dataToSend);

        self.loading = false;
        self.textoBotonEnviar = "Enviar";
        debugger; // eslint-disable-line no-debugger
        if (response.data.Success) {
            if(confirm(response.data.Message+". Desea reemplazarlos?")){
                self.setClienteOculto();
            }
        //   this.alertMessage = response.data.Message;
        //   this.alertTitle = "Datos guardados correctamente";
        //   this.show("top-right", "success");
        }
        else{
          
            self.setClienteOculto();
        //   this.alertMessage = response.data.Error+": "+response.data.Message;
        //   this.alertTitle = "Error";
        //   this.show("top-right", "error");
        }


      } catch (e) {
        self.loading = false;
        self.textoBotonEnviar = "Enviar";
        this.alertMessage = "Ocurrio un error";
        this.alertTitle = "Error";
        this.show("top-right", "error");
      }
    },
    async setClienteOculto(){
        var self = this;
        self.textoBotonEnviar = "Enviando...";
        self.loading = true;
      try {
        
        debugger; // eslint-disable-line no-debugger
        var dataToSend = {
        "Data": {
            "Ejercicio": self.currentYear,
            "Periodo": self.periodoSelect,
            "Calificaciones": self.jsonEnviar
            }
        };
        const response = await axios.post("BI/setClienteOculto", dataToSend);

        self.loading = false;
        self.textoBotonEnviar = "Enviar";
        debugger; // eslint-disable-line no-debugger
        if (response.data.Success) {
            
          this.alertMessage = response.data.Message;
          this.alertTitle = "Datos guardados correctamente";
          this.show("top-right", "success");
        }
        else{
          
          this.alertMessage = response.data.Message;
          this.alertTitle = "Error";
          this.show("top-right", "error");
        }


      } catch (e) {
        self.loading = false;
        self.textoBotonEnviar = "Enviar";
        this.alertMessage = "Ocurrio un error";
        this.alertTitle = "Error";
        this.show("top-right", "error");
      }
    },
      construirJSON(rows){
        var self = this;
        self.jsonEnviar = [];
        for(var n = 1 ; n < rows.length ; n++){
            
            var columna = parseInt(this.periodoSelect)+2;
            var califValue = parseFloat(rows[n][columna]);
            var calificacion = 0;
            if(califValue != 'NaN' && califValue > 0){
                calificacion = (califValue*100);
            }
            
            var fila =   {
                "EJERCICIO": self.currentYear,
                "PERIODO": self.periodoSelect,
                "NO_ESTACION": rows[n][0],
                "NOMBREGENERICO": rows[n][1],
                "ESTACION": rows[n][2],
                "CALIFICACION": calificacion,
            };
            
            self.jsonEnviar.push(fila);
        }
              debugger; // eslint-disable-line no-debugger
      },
        subirExcel(){
            const input= document.getElementById("archivoExcel");
            debugger; // eslint-disable-line no-debugger
            readXlsFile(input.files[0]).then((rows) => {
              debugger; // eslint-disable-line no-debugger
              this.items =  rows;
              this.construirJSON(rows);
            })
        },
      showNotification(title, text, type) {
        this.alertTitle = title;
        this.alertMessage = text;
        this.show("top-right", type);
      },
      show(group, type = "") {
        const text = this.alertMessage;
        this.$notify({
          group,
          title: this.alertTitle,
          text,
          type,
          data: {
            randomNumber: Math.random(),
          },
        });
      },
      clean(group) {
        this.$notify({ group, clean: true });
      },
      swalNotification(swalType) {
        var btnClass = swalType == "error" ? "danger" : swalType;
        btnClass = swalType == "question" ? "primary" : btnClass;
        this.$swal({
          title: "Are you sure?",
          text: "You will not be able to recover this imaginary file!",
          type: swalType,
          showCancelButton: true,
          buttonsStyling: false,
          confirmButtonText:
            swalType.charAt(0).toUpperCase() + swalType.substr(1).toLowerCase(),
          cancelButtonText: "Cancel",
          confirmButtonClass: "btn me-5px btn-" + btnClass + "",
          cancelButtonClass: "btn btn-default",
        });
      },
      
    },
    data() {
      return {
        loading: false,
        usuario:'',
        textoBotonEnviar: "Enviar",
        currentYear:0,
        periodoSelect:parseInt(moment().format("M")) ,
        years:[],
        jsonEnviar:[],
        items:[],
        cabecera: [
            {text: "Nombre", value: '0'},
            {text: "Apellido", value: '1'},
        ],
      };
    },
  };
  </script>

  
<style scoped>

.loading-search{
  position: absolute;
    width: 100%;
    left: 0px;
    height: 100%;
    background: rgba(0, 0, 0, 0.1);
    z-index: 1000;
    padding-top: 150px;
    top: 0;
}
</style>