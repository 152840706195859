<template>
   <div>
      <div class=" loading-search" v-if="loading">
         <div class="fa-3x text-center">
            <i class="fas fa-circle-notch fa-spin text-success" style=""></i>
         </div>
      </div>
      <!-- BEGIN breadcrumb -->
      <ol class="breadcrumb float-xl-end">
         <li class="breadcrumb-item"><a href="javascript:;">Acuses</a></li>
         <!-- <li class="breadcrumb-item"><a href="javascript:;">Cargar Datos</a></li> -->
         <li class="breadcrumb-item active">
            <a href="javascript:;">Cargar</a>
         </li>
      </ol>
      <!-- END breadcrumb -->
      <!-- BEGIN page-header -->
      <h1 class="page-header">Cargar Acuses</h1>
      <!-- END page-header -->

      <div class="row">
         <div class="col-md-12" style="margin-bottom: 15px;;">
            <!-- <form @submit.prevent="search"> -->
            <div class="form-group row">


               <div class="col-md-2">
                  <label class="col-form-label">Fecha</label>
                  <div class="input-group">
                     <date-picker id="dateTimeInput" v-model="date" :config="options"></date-picker>
                     <label for="dateTimeInput" class="input-group-text">
                        <i class="fa fa-calendar"></i>
                     </label>
                  </div>
               </div>
               <div class="col-md-2">
                  <label class="col-form-label">Turno</label>
                  <select v-model="turnoSelected.IdTurno" class="form-select">
                     <option value="0">Todos</option>
                     <option v-for="turno in turnos" :value="turno.IdTurno" v-bind:key="turno.IdTurno">
                        {{ turno.Descripcion }}
                     </option>
                  </select>
               </div>


               <div class="col-md-2"></div>

               <!-- <div class="col-md-3">
                <label class="col-md-4 col-form-label">Año</label>
                <div class="col-md-8">
                  <select class="form-select" name="" id="" v-model="currentYear">
                    <option :value="year.Year" v-for="year in years" :key="year.Year">{{year.Year}}</option>
                  </select>
                </div>
              </div> -->

               <!-- <div class="col-md-3">
                <label class="col-md-4 col-form-label">Mes</label>
                <div class="col-md-8">
                  <select class="form-select" name="" id="" v-model="periodoSelect">
                    <option value="1">Enero</option>
                    <option value="2">Febrero</option>
                    <option value="3">Marzo</option>
                    <option value="4">Abril</option>
                    <option value="5">Mayo</option>
                    <option value="6">Junio</option>
                    <option value="7">Julio</option>
                    <option value="8">Agosto</option>
                    <option value="9">Septiembre</option>
                    <option value="10">Octubre</option>
                    <option value="11">Noviembre</option>
                    <option value="12">Diciembre</option>
                  </select>
                </div>
              </div> -->

               <div class="col-md-4">

                  <!-- <label class="col-md-4 col-form-label">Archivo</label> -->
                  <div class="col-md-12">
                     <!-- <input type="file" multiple id="archivoExcel" accept=".pdf" @change="subirArchivos()"> -->


                     <div class="file-drop">
                        <div class="drop-area" @dragover.prevent="handleDragOver" @dragenter.prevent="handleDragEnter"
                           @dragleave.prevent="handleDragLeave" @drop.prevent="handleDrop">
                           <p v-if="dragging">Arrastre los archivos aquí</p>
                           <p v-else>Arrastre los archivos aquí o </p>
                           <input type="file" @change="handleFileSelect" ref="fileInput" style="display: none" multiple
                              accept=".pdf" />
                           <button class="btn btn-md btn-success" @click="selectFiles">Seleccione los archivos</button>
                        </div>
                        <!-- <ul v-if="uploadedFiles.length">
                      <li v-for="(file, index) in uploadedFiles" :key="index">
                        {{ file.name }}
                      </li>
                    </ul> -->
                     </div>

                  </div>
               </div>
               <div class="col-md-4" style="padding-top: 35px; text-align: right;">
                  <button class="btn btn-success" style="width: 100px; cursor: pointer;"
                     :disabled="!(filesArray.length > 0)" @click="setAcusesBulk()">
                     {{ textoBotonEnviar }}
                  </button>

               </div>
            </div>
            <!-- </form> -->
         </div>
      </div>
      <panel title="Archivos seleccionados" bodyClass="p-0" class="">
         <!-- <div>
          <div class="row">
            <div class="col-md-12" v-for="file in filesArray" v-bind:key="file.Nombre">
                <embed :src="file.Base64" type="application/pdf" width="100%" height="200px" />
            </div>
          </div>
        </div> -->
         <div class="row" style="margin-top: 15px;">
            <div class="col-2"></div>
            <div class="col-8">
               <div class="table-responsive" style="height: 500px;" v-if="filesArray.length > 0">
                  <table class="table table-bordered mb-0">
                     <thead>
                        <tr>
                           <th>Archivo</th>
                           <th></th>
                        </tr>
                     </thead>
                     <tbody>
                        <tr v-for="(file, index) in filesArray" v-bind:key="index">
                           <td>
                              <label for=""><i class="far fa-lg fa-fw me-10px fa-file-pdf"
                                    style="color: rgba(228, 33, 33, 0.849)"></i>{{ file.Nombre }}</label>
                           </td>
                           <td class="text-center">
                              <button v-b-modal.modalDialog class="btn btn-sm btn-success"
                                 @click="abrirModal(file)">Ver</button>
                           </td>

                        </tr> 
                     </tbody>
                  </table>
               </div>
               <h1 class="text-center" v-else>Seleccione los archivos</h1>
            </div>
            <div class="col-2"></div>
         </div>

      </panel>

      <!-- #modal-dialog -->
      <b-modal id="modalDialog" size="xl">
         <template slot="modal-header">
            <h4 class="modal-title">Vista previa - {{ selectedFile.Nombre }}</h4>
            <a class="btn-close" @click="$bvModal.hide('modalDialog')"></a>
         </template>

         <div>
            <embed :src="selectedFile.Base64" type="application/pdf" width="100%" height="400px" />
         </div>

         <template slot="modal-footer">
            <button type="button" class="btn btn-secondary" @click="$bvModal.hide('modalDialog')">Cerrar</button>
         </template>
      </b-modal>



      <!-- bottom left vue-notification -->
      <notifications group="top-right" position="top right" :speed="1000" />
   </div>
</template>

<script>
import axios from "axios";
import readXlsFile from "read-excel-file"
import moment from 'moment'; import Vue from "vue";
var numeral = require("numeral");

Vue.filter("formatNumber", function (value) {
   return numeral(value).format("0,00.00"); // displaying other groupings/separators is possible, look at the docs
});

Vue.filter("currency", function (value) {
   return numeral(value).format("$0,0.00");
});
export default {

   mounted: function () {
      var self = this;
      var userSetting = JSON.parse(localStorage.getItem('userSettings'));
      self.usuario = userSetting.Usuario;
      var currentYear = 2023;//parseInt(moment().format("YYYY"));

      self.currentYear = currentYear;
      self.years.push({ "Year": currentYear });
      self.years.push({ "Year": currentYear + 1 });
      self.years.push({ "Year": currentYear + 2 });
      self.years.push({ "Year": currentYear + 3 });
      self.years.push({ "Year": currentYear + 4 });

      this.GetTurnos();

   },
   watch: {
      //   currentYear(val){
      //     debugger; // eslint-disable-line no-debugger
      //     var self = this;

      //     if(self.jsonEnviar.length > 1){

      //         for(var n = 0 ; n < self.jsonEnviar.length ; n++){
      //           self.jsonEnviar[n].Ejercicio =  val;
      //       }
      //     }
      //   }
   },
   methods: {
      handleDragOver(event) {
         event.dataTransfer.dropEffect = 'copy';
         event.preventDefault();
      },
      handleDragEnter() {
         this.dragging = true;
      },
      handleDragLeave() {
         this.dragging = false;
      },
      handleDrop(event) {
         this.dragging = false;
         event.preventDefault();
         const files = event.dataTransfer.files;
         this.uploadedFiles = [...this.uploadedFiles, ...Array.from(files)];

         this.filesArray = [];
         for (var f = 0; f < this.uploadedFiles.length; f++) {
            this.handleFile(this.uploadedFiles[f]);
         }
      },
      selectFiles() {
         this.$refs.fileInput.click();
      },
      handleFileSelect(event) {
         const files = event.target.files;
         this.uploadedFiles = [...this.uploadedFiles, ...Array.from(files)];


         this.filesArray = [];
         for (var f = 0; f < this.uploadedFiles.length; f++) {
            this.handleFile(this.uploadedFiles[f]);
         }
      },


      removeDataPrefix(dataWithPrefix) {
         // Use JavaScript string methods to remove the data prefix
         const commaIndex = dataWithPrefix.indexOf(",");
         if (commaIndex !== -1) {
            return dataWithPrefix.slice(commaIndex + 1);
         }
         return dataWithPrefix;
      },

      removeFileExtension(filename) {
         // Use JavaScript string methods to remove the file extension
         const lastIndex = filename.lastIndexOf(".");
         if (lastIndex === -1) {
            return filename; // No extension found
         } else {
            return filename.substring(0, lastIndex);
         }
      },
      setAcusesBulk() {
         debugger; // eslint-disable-line no-debugger

         var self = this;

         self.intDate = self.date.replace('/', '');
         self.intDate = self.intDate.replace('/', '');

         var fecha = moment().format('YYYYMMDD');
         var filesLenth = self.filesArray.length;
         var turno = self.turnoSelected != undefined ? self.turnoSelected.IdTurno : 0;

         for (var n = 0; n < self.filesArray.length; n++) {
            var fileName = self.removeFileExtension(self.filesArray[n].Nombre);
            var b64 = self.removeDataPrefix(self.filesArray[n].Base64);
            var file = {
               "Fecha": self.intDate,
               "Turno": turno,
               "Nombre": fileName,
               "Usuario": self.usuario,
               "Base64": b64
            };

            self.SetAcusePrecio(file);
            if (n + 1 == filesLenth) {
               self.filesArray = [];
               self.uploadedFiles
            }
         }
      },

      async SetAcusePrecio(archivo) {
         //api/Precio/SetAcusePrecio

         var self = this;
         self.textoBotonEnviar = "Enviando...";
         self.loading = true;
         try {

            //debugger; // eslint-disable-line no-debugger
            var dataToSend = {
               "Data": archivo
            };
            console.log(dataToSend);
            // debugger; // eslint-disable-line no-debugger
            const response = await axios.post("Precio/SetAcusePrecio", dataToSend);

            self.loading = false;
            self.textoBotonEnviar = "Enviar";
            // debugger; // eslint-disable-line no-debugger
            if (response.data.Success) {

               this.alertMessage = response.data.Message;
               this.alertTitle = "";
               this.show("top-right", "success");
            }
            else {

               this.alertMessage = response.data.Message;
               this.alertTitle = "Error";
               this.show("top-right", "error");
            }


         } catch (e) {
            self.loading = false;
            self.textoBotonEnviar = "Enviar";
            this.alertMessage = "Ocurrio un error";
            this.alertTitle = "Error";
            this.show("top-right", "error");
         }
      },

      showIfValue() {
         return true;
      },
      abrirModal(file) {
         this.selectedFile = file;
      },
      async GetClienteOculto() {
         var self = this;
         self.textoBotonEnviar = "Enviando...";
         self.loading = true;
         try {
            self.construirJSON(self.items);
            debugger; // eslint-disable-line no-debugger
            var dataToSend = {
               "Data": {
                  "Ejercicio": self.currentYear,
                  "Periodo": self.periodoSelect
               }
            };
            const response = await axios.post("BI/GetImagenEstacion", dataToSend);

            self.loading = false;
            self.textoBotonEnviar = "Enviar";
            debugger; // eslint-disable-line no-debugger
            if (response.data.Success) {
               if (confirm(response.data.Message + ". Desea reemplazarlos?")) {
                  self.setClienteOculto();
               }
               //   this.alertMessage = response.data.Message;
               //   this.alertTitle = "Datos guardados correctamente";
               //   this.show("top-right", "success");
            }
            else {

               self.setClienteOculto();
               //   this.alertMessage = response.data.Error+": "+response.data.Message;
               //   this.alertTitle = "Error";
               //   this.show("top-right", "error");
            }


         } catch (e) {
            self.loading = false;
            self.textoBotonEnviar = "Enviar";
            this.alertMessage = "Ocurrio un error";
            this.alertTitle = "Error";
            this.show("top-right", "error");
         }
      },
      async setClienteOculto() {
         var self = this;
         self.textoBotonEnviar = "Enviando...";
         self.loading = true;
         try {

            debugger; // eslint-disable-line no-debugger
            var dataToSend = {
               "Data": {
                  "Ejercicio": self.currentYear,
                  "Periodo": self.periodoSelect,
                  "Calificaciones": self.jsonEnviar
               }
            };
            const response = await axios.post("BI/SetImagenEstacion", dataToSend);

            self.loading = false;
            self.textoBotonEnviar = "Enviar";
            debugger; // eslint-disable-line no-debugger
            if (response.data.Success) {

               this.alertMessage = response.data.Message;
               this.alertTitle = "Datos guardados correctamente";
               this.show("top-right", "success");
            }
            else {

               this.alertMessage = response.data.Message;
               this.alertTitle = "Error";
               this.show("top-right", "error");
            }


         } catch (e) {
            self.loading = false;
            self.textoBotonEnviar = "Enviar";
            this.alertMessage = "Ocurrio un error";
            this.alertTitle = "Error";
            this.show("top-right", "error");
         }
      },

      subirArchivos() {
         const input = document.getElementById("archivoExcel");
         debugger; // eslint-disable-line no-debugger




         const files = input.files;

         this.filesArray = [];

         for (var f = 0; f < files.length; f++) {
            this.handleFile(files[f]);
         }

      },
      async handleFile(file) {
         var self = this;
         if (file && file.type == "application/pdf") {
            const reader = new FileReader();

            reader.onload = (e) => {
               // this.base64PDF = e.target.result;
               debugger; // eslint-disable-line no-debugger
               var fileObj = {
                  Base64: e.target.result,
                  Nombre: file.name
               }
               self.filesArray.push(fileObj);
               console.log(file.name)
            };

            reader.readAsDataURL(file);
         }
         else if (file.type != "application/pdf") {

            this.alertMessage = file.name + " No es un archivo PDF válido.";
            this.alertTitle = "Error";
            this.show("top-right", "error");
         }

      },
      showNotification(title, text, type) {
         this.alertTitle = title;
         this.alertMessage = text;
         this.show("top-right", type);
      },
      show(group, type = "") {
         const text = this.alertMessage;
         this.$notify({
            group,
            title: this.alertTitle,
            text,
            type,
            data: {
               randomNumber: Math.random(),
            },
         });
      },
      clean(group) {
         this.$notify({ group, clean: true });
      },
      swalNotification(swalType) {
         var btnClass = swalType == "error" ? "danger" : swalType;
         btnClass = swalType == "question" ? "primary" : btnClass;
         this.$swal({
            title: "Are you sure?",
            text: "You will not be able to recover this imaginary file!",
            type: swalType,
            showCancelButton: true,
            buttonsStyling: false,
            confirmButtonText:
               swalType.charAt(0).toUpperCase() + swalType.substr(1).toLowerCase(),
            cancelButtonText: "Cancel",
            confirmButtonClass: "btn me-5px btn-" + btnClass + "",
            cancelButtonClass: "btn btn-default",
         });
      },
      async GetTurnos() {
         try {
            const response = await axios.get("Catalogos/GetTurnos");
            if (response.data.Success) {
               if (response.data.response.length == 0) {
                  this.alertMessage =
                     "No se encontraron turnos";
                  this.alertTitle = "Error";
                  this.show("top-right", "error");
               } else {
                  this.turnos = response.data.response;
               }
            } else {
               this.alertMessage = response.data.Message;
               this.alertTitle = "Error";
               this.show("top-right", "error");
            }
         } catch (e) {
            this.alertMessage = "Ocurrio un error";
            this.alertTitle = "Error";
            this.show("top-right", "error");
         }
      },

   },
   data() {
      return {

         date: moment().format("YYYY/MM/DD"),
         intDateEnd: "",
         dateEnd: moment().format("YYYY/MM/DD"),
         intDate: "",
         options: {
            format: 'YYYY/MM/DD',
            useCurrent: false,
         },
         dragging: false,
         uploadedFiles: [],
         selectedFile: {},
         filesArray: [],
         filesArray2: [],
         loading: false,
         usuario: '',
         textoBotonEnviar: "Enviar",
         currentYear: 0,
         periodoSelect: parseInt(moment().format("M")),
         // periodoSelect: 0 ,
         years: [],
         jsonEnviar: [],
         items: [],
         cabecera: [
            { text: "Nombre", value: '0' },
            { text: "Apellido", value: '1' },
         ],

         turnos: [],
         turnoSelected: {
            IdTurno: 0
         },
      };
   },
};
</script>


<style scoped>
.loading-search {
   position: absolute;
   width: 100%;
   left: 0px;
   height: 100%;
   background: rgba(0, 0, 0, 0.1);
   z-index: 1000;
   padding-top: 150px;
   top: 0;
}


.file-drop {
   display: flex;
   flex-direction: column;
   align-items: center;
}

.drop-area {
   width: 100%;
   border: 2px dashed #ccc;
   padding: 20px;
   text-align: center;
   cursor: pointer;
}

.drop-area p {
   font-size: 16px;
}

ul {
   list-style-type: none;
   padding: 0;
   margin: 0;
   text-align: left;
}

li {
   margin: 4px 0;
}
</style>