<template>
  <div>

    <div class=" loading-search" v-if="loading">
      <div class="fa-3x text-center">
        <i class="fas fa-circle-notch fa-spin text-success" style=""></i>
      </div>
    </div>
    <ol class="breadcrumb float-xl-end">
      <li class="breadcrumb-item"><a href="javascript:;">Catalogos</a></li>
      <li class="breadcrumb-item"><a href="javascript:;">Estaciones</a></li>
      <li class="breadcrumb-item active"><a href="javascript:;">Estaciones</a></li>
    </ol>

    <h1 class="page-header">Estaciones <small></small></h1>
    <form @submit.prevent="search">
      <div class="row">
        <div class="col">
          <div class="form-group row">
            <div class="col col-sm-4">
              <label class="col-form-label">Ciudad</label>
              <select class="form-select" @change="getEstaciones()" v-model="ciudadSelect"
                :disabled="deshabilitarCombos">
                <option v-for="ciudad in ciudades" :value="ciudad.Id" v-bind:key="ciudad.Id">
                  {{ ciudad.Nombre }}
                </option>
              </select>
            </div>
            <div class="col col-sm-4">
              <button type="submit" class="btn btn-success me-1 mb-1" style="margin-top: 3.1em;">{{ textoBotonBuscar
                }}</button>
            </div>
          </div>
        </div>
      </div>
    </form>
    <div>

      <panel title="Estaciones" bodyClass="p-0" class="" style="margin-top: 15px;">

        <DxDataGrid id="gridContainer" :data-source="searchResult" key-expr="Estacion" :show-borders="true"
          @exporting="onExporting" :show-column-lines="true" :show-row-lines="true" :row-alternation-enabled="true"
          :column-width="100" :allow-column-resizing="true" :column-auto-width="true">

          <DxScrolling column-rendering-mode="virtual" />
          <DxPager :show-page-size-selector="true" :allowed-page-sizes="[10, 20, 50, 100]"
            :show-navigation-buttons="true" />
          <DxPaging :page-size="10" :page-index="1" />
          <DxHeaderFilter :visible="showHeaderFilter" />
          <DxSearchPanel :visible="true" :width="240" placeholder="Search..." />
          <DxExport :enabled="true" :allow-export-selected-data="false" />
          <DxSelection mode="false" />

          <DxColumn dataField="Acciones" caption="Acciones" :cellTemplate="enviarButton" width="100" />

          <DxColumn data-field="Estacion" caption="Estacion" width="100" />
          <DxColumn data-field="Nombre" caption="Nombre" width="200" />
          <DxColumn data-field="Ciudad" caption="Ciudad" width="100" />
          <DxColumn data-field="Direccion" caption="Direccion" width="=800" />
          <DxColumn data-field="RFC" caption="RFC" width="150" />
          <DxColumn data-field="PermisoCRE" caption="PermisoCRE" width="300" />


        </DxDataGrid>

      </panel>

      <b-modal id="modalDialog" size="xl">
        <template slot="modal-header">
          <h4 class="modal-title">Estacion</h4>
          <a class="btn-close" @click="$bvModal.hide('modalDialog')"></a>
        </template>

        <div class="row">
          <div class="col">
            <b-card no-body>
              <b-tabs active-nav-item-class="font-weight-bold text-uppercase text-danger"
                active-tab-class="font-weight-bold" content-class="mt-3" card>
                <!-- BEGIN tab -->
                <b-tab title="Datos de Estación" active>
                  <form action="/" method="POST">
                    <fieldset>
                      <div class="row">
                        <div class="col col-sm-4">
                          <!-- {{ DatosEstacion }} -->
                          En Desarrollo
                        </div>

                      </div>

                    </fieldset>
                  </form>
                </b-tab>
                <b-tab title="Facturación">
                  <form action="/" method="POST">
                    <fieldset>
                      <div class="row">
                        <div class="col col-sm-4">
                          En Desarrollo
                        </div>

                      </div>

                    </fieldset>
                  </form>
                </b-tab>
                <b-tab title="Tabulador">
                  <form action="/" method="POST">
                    <fieldset>
                      <div class="row">
                        <div class="col col-sm-4">
                          En Desarrollo
                        </div>

                      </div>

                    </fieldset>
                  </form>
                </b-tab>
                <b-tab title="Modulos APP" @click="GetModulos(DatosEstacion.Estacion)">
                  <div class="row">
                    <div class="col col-sm-12">
                      <template>
                        <b-form-group label="Modulos">
                          <div class="row">
                            <div class="col col-sm-4" v-for="(item) in GetModulosApp" :key="item.Modulo">
                              <div class="form-check form-switch form-check-inline">
                                <input class="form-check-input" type="checkbox" role="switch" v-model="item.Estatus"
                                  :value="item.Estatus" :id="item.Modulo" v-on:change="SetModulos(item)">
                                <label class="form-check-label" :for="item.Modulo"><b>
                                    {{ item.Nombre }}
                                  </b>
                                </label>
                                <br>
                              </div>
                            </div>

                          </div>
                        </b-form-group>
                      </template>
                    </div>
                  </div>
                </b-tab>
                <!-- END tab -->
              </b-tabs>
            </b-card>
          </div>
        </div>

        <template slot="modal-footer">
          <button type="button" class="btn btn-secondary" @click="$bvModal.hide('modalDialog')">Cancelar</button>
        </template>
      </b-modal>



    </div>

    <!-- bottom left vue-notification -->
    <notifications group="top-right" position="top right" :speed="1000" />
  </div>
</template>

<script>

import axios from 'axios';
import moment from 'moment';



import {
  DxDataGrid,
  DxPaging, DxPager, DxScrolling, DxColumn, DxExport, DxSelection, DxHeaderFilter, DxSearchPanel,
} from 'devextreme-vue/data-grid';
import { Workbook } from 'exceljs';
import { saveAs } from 'file-saver';
// Our demo infrastructure requires us to use 'file-saver-es'. We recommend that you use the official 'file-saver' package in your applications.
import { exportDataGrid } from 'devextreme/excel_exporter';
//import service from './data.js';


var numeral = require("numeral");
import Vue from "vue";

import { FormCheckboxPlugin } from 'bootstrap-vue'
Vue.use(FormCheckboxPlugin)

import JsonExcel from "vue-json-excel";
Vue.component("downloadExcel", JsonExcel);
Vue.filter("formatNumber", function (value) {
  return numeral(value).format("0,00.00"); // displaying other groupings/separators is possible, look at the docs
});

Vue.filter("currency", function (value) {
  return numeral(value).format("$0,0.00");
});


export default {
  mounted: function () {
    var self = this;

    var userSetting = JSON.parse(localStorage.getItem('userSettings'));
    this.usuario = userSetting.Usuario;
    this.usuarioEstacion = userSetting.Estacion;
    this.IdRol = userSetting.IdRol;
    this.getCiudades();
    this.ciudadSelect = userSetting.CiudadId;

    if (this.IdRol == 3 || this.IdRol == 7) {
      this.deshabilitarCombos = true;
    }

  },
  beforeCreate() {

  },
  created() {
    // console.log('created');
  },
  components: {
    DxDataGrid, DxPaging, DxPager, DxScrolling, DxColumn, DxExport, DxSelection, DxHeaderFilter, DxSearchPanel,
  },
  beforeMount() {
    // console.log('beforeMount');
  },
  computed: {
  },
  methods: {
    onExporting(e) {
      const workbook = new Workbook();
      const worksheet = workbook.addWorksheet('Estaciones');

      exportDataGrid({
        component: e.component,
        worksheet: worksheet,
        autoFilterEnabled: true
      }).then(() => {
        workbook.xlsx.writeBuffer().then((buffer) => {
          saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'Estaciones.xlsx');
        });
      });
      e.cancel = true;
    },
    enviarButton(cellElement, cellInfo) {
      this.DatosEstacion = {};

      const button = document.createElement("button");
      button.innerText = "Ver";
      button.className = "btn btn-sm btn-success";
      button.addEventListener("click", () => {
        console.log(cellInfo.data);
        this.DatosEstacion = cellInfo.data;
        console.log(this.DatosEstacion.Estacion);
        this.GetModulos(this.DatosEstacion.Estacion)
        this.$bvModal.show('modalDialog');
      });

      cellElement.appendChild(button);
    },
    async getCiudades() {
      try {
        const response = await axios.get('Catalogos/GetCiudades');
        if (response.data.Success) {
          if (response.data.response.length == 0) {
            this.alertMessage = "No se encontró información.";
            this.alertTitle = "Error";
            this.show('top-right', 'error');
          }
        } else {
          console.log("error")
          this.alertMessage = response.data.Error;
          this.alertTitle = "Error";
          this.show('top-right', 'error');
        }
        this.ciudades = response.data.response;
      } catch (e) {
        //this.alert = true;
        //this.error = 'Invalid email/password';
      }
    },
    async getEstaciones() {
      try {
        const response = await axios.post('Estacion/GetEstaciones?ciudad=' + this.ciudadSelect);
        if (response.data.Success) {
          if (response.data.response.length == 0) {
            this.alertMessage = "No se encontró información.";
            this.alertTitle = "Error";
            this.show('top-right', 'error');
          }
        } else {
          console.log("error")
          this.alertMessage = response.data.Error;
          this.alertTitle = "Error";
          this.show('top-right', 'error');
        }
        this.estaciones = response.data.response;

        //debugger; // eslint-disable-line no-debugger
        // this.productos = [];
        // this.getProductos();
      } catch (e) {
        //this.alert = true;
        //this.error = 'Invalid email/password';
      }
    },
    show(group, type = '') {
      const text = this.alertMessage;
      this.$notify({
        group,
        title: this.alertTitle,
        text,
        type,
        data: {
          randomNumber: Math.random()
        }
      })
    },
    clean(group) {
      this.$notify({ group, clean: true })
    },
    swalNotification(swalType) {
      var btnClass = (swalType == 'error') ? 'danger' : swalType;
      btnClass = (swalType == 'question') ? 'primary' : btnClass;
      this.$swal({
        title: 'Are you sure?',
        text: 'You will not be able to recover this imaginary file!',
        type: swalType,
        showCancelButton: true,
        buttonsStyling: false,
        confirmButtonText: swalType.charAt(0).toUpperCase() + swalType.substr(1).toLowerCase(),
        cancelButtonText: 'Cancel',
        confirmButtonClass: 'btn me-5px btn-' + btnClass + '',
        cancelButtonClass: 'btn btn-default',
      })
    },

    async search(e) {
      var self = this;
      e.preventDefault();


      //   if(moment(self.date).format('MM') != moment(self.dateEnd).format('MM')){

      //         self.alertMessage = "Las fechas deben corresponder al mismo mes."
      //         self.alertTitle = "Error";
      //         self.show('top-right', 'error');
      //         return;
      //   }
      self.textoBotonBuscar = "Buscando...";
      self.loading = true;
      try {
        self.intDate = self.date.replace('/', '');
        self.intDate = self.intDate.replace('/', '');


        self.intDateEnd = self.dateEnd.replace('/', '');
        self.intDateEnd = self.intDateEnd.replace('/', '');

        var ciudad = self.ciudadSelect != undefined ? self.ciudadSelect : 0;
        var estacion = self.estacionSelect != undefined ? self.estacionSelect : 0;
        var gasolinera = 0;
        for (var es = 0; es < self.estaciones.length; es++) {
          if (estacion == self.estaciones[es].NoEstacion) {
            gasolinera = self.estaciones[es].Gasolinera;
            break;
          }
        }
        //debugger; // eslint-disable-line no-debugger

        var dataToSend = {
          "Data": {
            "Ciudad": ciudad,
          }
        };
        const response = await axios.post('Estacion/GetEstacionesVAddes', dataToSend);

        //debugger; // eslint-disable-line no-debugger
        self.loading = false;
        self.textoBotonBuscar = "Buscar";
        if (response.data.Success) {
          if (response.data.response.length == 0) {
            self.alertMessage = "No se encontró información de la ciudad especificada.";
            self.alertTitle = "Error";
            self.show('top-right', 'error');
          } else {

            //debugger; // eslint-disable-line no-debugger
            var respuesta = response.data.response;

            self.searchResult = respuesta;

          }


        } else {
          console.log("error")
          self.alertMessage = response.data.Message;
          self.alertTitle = "Error";
          self.show('top-right', 'error');
        }





        self.loading = false;
      } catch (e) {
        self.loading = false;
        //this.alert = true;
        //this.error = 'Invalid email/password';
      }
    },
    async GetModulos(estacion) {
      var self = this;

      self.loading = true;
      try {

        //debugger; // eslint-disable-line no-debugger

        var dataToSend = {
          "Data": {
            "Estacion": estacion,
          }
        };
        const response = await axios.post('Estacion/GetModulosApp ', dataToSend);

        self.loading = false;

        if (response.data.Success) {

          var respuesta = response.data.response;

          self.GetModulosApp = respuesta;

        } else {
          console.log("error")
          self.alertMessage = response.data.Message;
          self.alertTitle = "Error Modulos APP";
          self.show('top-right', 'error');
        }
        self.loading = false;
      } catch (e) {
        self.loading = false;
      }
    },
    async SetModulos(item) {
      var self = this;

      self.loading = true;
      try {

        var dataToSend = {
          "Data": {
            "Modulo": item.Modulo,
            "Estacion": item.Estacion,
            "Nombre": item.Nombre,
            "Estatus": item.Estatus

          }
        };
        console.log(dataToSend);
        const response = await axios.post('Estacion/SetModuloApp', dataToSend);

        self.loading = false;

        if (response.data.Success) {

          self.alertMessage = response.data.Message;
          self.alertTitle = "Correcto";
          self.show('top-right', 'success');
          self.GetModulos(item.Estacion);
        } else {
          console.log("error")
          self.alertMessage = response.data.Message;
          self.alertTitle = "Error";
          self.show('top-right', 'error');
        }
        self.loading = false;
      } catch (e) {
        self.loading = false;
      }
    }

  },
  data() {
    return {
      showHeaderFilter: true,
      RespuestaCompras: [],
      loading: false,
      deshabilitarCombos: false,
      ciudades: [],
      estaciones: [],
      ciudadSelect: 1,
      estacionSelect: 0,
      date: moment().startOf('month').format("YYYY/MM/DD"),
      intDateEnd: "",
      dateEnd: moment().format("YYYY/MM/DD"),
      intDate: "",
      options: {
        format: 'YYYY/MM/DD',
        useCurrent: false,
      },
      pageUserSettings: {},
      alertMessage: "",
      alertTitle: "",
      searchResult: [],
      textoBotonBuscar: "Buscar",
      DatosEstacion: [],
      GetModulosApp: [],
    };
  }
}
</script>
<style scoped>
.dx-datagrid-header-panel {
  display: none !important;
}

.loading-search {
  position: absolute;
  width: 100%;
  left: 0px;
  height: 100%;
  background: rgba(0, 0, 0, 0.1);
  z-index: 1000;
  padding-top: 150px;
  top: 0;
}

.sumatoriasRow {
  background-color: #bbb;
}

.currencyRow {
  text-align: right !important;
}

.vgt-fixed-header {
  overflow-y: auto;
}

.vgt-table__header {
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: #f5f5f5;
}
</style>