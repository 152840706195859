<template>
	<div>
		<!-- BEGIN breadcrumb -->
		<ol class="breadcrumb float-xl-end">
			<li class="breadcrumb-item"><a href="javascript:;">Home</a></li>
			<li class="breadcrumb-item active">Calendar</li>
		</ol>
		<!-- END breadcrumb -->
		<!-- BEGIN page-header -->
		<h1 class="page-header">Calendar <small>header small text goes here...</small></h1>
		<!-- END page-header -->
		<hr />
	
		<!-- BEGIN calendar -->
		<!-- <FullCalendar :events="events" :options="calendarOptions"></FullCalendar> -->
		<!-- END calendar -->


		
		<div class="row">
			<!-- BEGIN event-list -->
			<div class="d-none d-lg-block" style="width: 300px">
                <button class="btn btn-success" @click="agregarHorario()">Agregar</button>
                <button class="btn btn-success" @click="removeEventById('event1')">Borrar</button>

				<div class="row">
					<div class="col-md-12">
						<!-- <input
							class="form-check-input"
							type="checkbox"
							id="flexSwitchCheckChecked"
						/> -->
						
					<div class="form-check mb-4">
						<input class="form-check-input" type="checkbox" value="" id="agreementCheckbox" />
						<label class="form-check-label" for="agreementCheckbox">
							Lunes
						</label>
					</div>
					</div>
				</div>
			</div>
			<!-- END event-list -->
			<div class="col-lg">
				<!-- BEGIN calendar -->
				<FullCalendar :events="events" :options="calendarOptions" ref="fullCalendar"></FullCalendar>
				<!-- END calendar -->
			</div>
		</div>
	</div>
</template>

<script>
import '@fullcalendar/core/vdom' // solves problem with Vite
import FullCalendar from '@fullcalendar/vue'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin, { Draggable } from '@fullcalendar/interaction';
import listPlugin from '@fullcalendar/list'
import bootstrapPlugin from '@fullcalendar/bootstrap'

export default {
	components: {
    FullCalendar // make the <FullCalendar> tag available
  },
	mounted() {
		var containerEl = document.getElementById('external-events');
		new Draggable(containerEl, {
			itemSelector: '.fc-event',
			eventData: function(eventEl) {
				return {
					title: eventEl.innerText,
					color: eventEl.getAttribute('data-color'),
					duration: '06:00:00'
				};
			}
		});
		
		window.dispatchEvent(new Event('resize'));
	},
	data() {
		var date = new Date();
		var year = date.getFullYear();
		var month = date.getMonth() + 1;
		month = (month < 10) ? '0' + month : month;
		
		var appVariable ={
			color: {
				componentColor: (getComputedStyle(document.body).getPropertyValue('--app-component-color')).trim(),
				componentBg: (getComputedStyle(document.body).getPropertyValue('--app-component-bg')).trim(),
				theme: (getComputedStyle(document.body).getPropertyValue('--app-theme')).trim(),
				blue: (getComputedStyle(document.body).getPropertyValue('--bs-blue')).trim(),
				teal: (getComputedStyle(document.body).getPropertyValue('--bs-teal')).trim(),
				green: (getComputedStyle(document.body).getPropertyValue('--bs-green')).trim(),
				lime: (getComputedStyle(document.body).getPropertyValue('--bs-lime')).trim(),
				orange: (getComputedStyle(document.body).getPropertyValue('--bs-orange')).trim(),
				red: (getComputedStyle(document.body).getPropertyValue('--bs-red')).trim(),
				cyan: (getComputedStyle(document.body).getPropertyValue('--bs-cyan')).trim(),
				purple: (getComputedStyle(document.body).getPropertyValue('--bs-purple')).trim(),
				yellow: (getComputedStyle(document.body).getPropertyValue('--bs-yellow')).trim(),
				indigo: (getComputedStyle(document.body).getPropertyValue('--bs-indigo')).trim(),
				pink: (getComputedStyle(document.body).getPropertyValue('--bs-pink')).trim(),
				black: (getComputedStyle(document.body).getPropertyValue('--bs-black')).trim(),
				white: (getComputedStyle(document.body).getPropertyValue('--bs-white')).trim(),
				gray: (getComputedStyle(document.body).getPropertyValue('--bs-gray')).trim(),
				dark: (getComputedStyle(document.body).getPropertyValue('--bs-dark')).trim(),
				gray100: (getComputedStyle(document.body).getPropertyValue('--bs-gray-100')).trim(),
				gray200: (getComputedStyle(document.body).getPropertyValue('--bs-gray-200')).trim(),
				gray300: (getComputedStyle(document.body).getPropertyValue('--bs-gray-300')).trim(),
				gray400: (getComputedStyle(document.body).getPropertyValue('--bs-gray-400')).trim(),
				gray500: (getComputedStyle(document.body).getPropertyValue('--bs-gray-500')).trim(),
				gray600: (getComputedStyle(document.body).getPropertyValue('--bs-gray-600')).trim(),
				gray700: (getComputedStyle(document.body).getPropertyValue('--bs-gray-700')).trim(),
				gray800: (getComputedStyle(document.body).getPropertyValue('--bs-gray-800')).trim(),
				gray900: (getComputedStyle(document.body).getPropertyValue('--bs-gray-900')).trim(),
		
				componentColorRgb: (getComputedStyle(document.body).getPropertyValue('--app-component-color-rgb')).trim(),
				componentBgRgb: (getComputedStyle(document.body).getPropertyValue('--app-component-bg-rgb')).trim(),
				themeRgb: (getComputedStyle(document.body).getPropertyValue('--app-theme-rgb')).trim(),
				blueRgb: (getComputedStyle(document.body).getPropertyValue('--bs-blue-rgb')).trim(),
				greenRgb: (getComputedStyle(document.body).getPropertyValue('--bs-green-rgb')).trim(),
				tealRbg: (getComputedStyle(document.body).getPropertyValue('--bs-teal-rgb')).trim(),
				limeRgb: (getComputedStyle(document.body).getPropertyValue('--bs-lime-rgb')).trim(),
				orangeRgb: (getComputedStyle(document.body).getPropertyValue('--bs-orange-rgb')).trim(),
				redRgb: (getComputedStyle(document.body).getPropertyValue('--bs-red-rgb')).trim(),
				cyanRgb: (getComputedStyle(document.body).getPropertyValue('--bs-cyan-rgb')).trim(),
				purpleRgb: (getComputedStyle(document.body).getPropertyValue('--bs-purple-rgb')).trim(),
				yellowRgb: (getComputedStyle(document.body).getPropertyValue('--bs-yellow-rgb')).trim(),
				indigoRgb: (getComputedStyle(document.body).getPropertyValue('--bs-indigo-rgb')).trim(),
				pinkRgb: (getComputedStyle(document.body).getPropertyValue('--bs-pink-rgb')).trim(),
				blackRgb: (getComputedStyle(document.body).getPropertyValue('--bs-black-rgb')).trim(),
				whiteRgb: (getComputedStyle(document.body).getPropertyValue('--bs-white-rgb')).trim(),
				grayRgb: (getComputedStyle(document.body).getPropertyValue('--bs-gray-rgb')).trim(),
				darkRgb: (getComputedStyle(document.body).getPropertyValue('--bs-dark-rgb')).trim(),
				gray100Rgb: (getComputedStyle(document.body).getPropertyValue('--bs-gray-100-rgb')).trim(),
				gray200Rgb: (getComputedStyle(document.body).getPropertyValue('--bs-gray-200-rgb')).trim(),
				gray300Rgb: (getComputedStyle(document.body).getPropertyValue('--bs-gray-300-rgb')).trim(),
				gray400Rgb: (getComputedStyle(document.body).getPropertyValue('--bs-gray-400-rgb')).trim(),
				gray500Rgb: (getComputedStyle(document.body).getPropertyValue('--bs-gray-500-rgb')).trim(),
				gray600Rgb: (getComputedStyle(document.body).getPropertyValue('--bs-gray-600-rgb')).trim(),
				gray700Rgb: (getComputedStyle(document.body).getPropertyValue('--bs-gray-700-rgb')).trim(),
				gray800Rgb: (getComputedStyle(document.body).getPropertyValue('--bs-gray-800-rgb')).trim(),
				gray900Rgb: (getComputedStyle(document.body).getPropertyValue('--bs-gray-900-rgb')).trim()
			}
		}
		return {
			calendarOptions: {
				plugins: [dayGridPlugin, interactionPlugin, timeGridPlugin, bootstrapPlugin],
				initialView: 'timeGridWeek',
				slotDuration: '01:00:00', // Set slot duration to 6 hours
				// slotMinTime: '06:00:00', // set the minimum time to 12:00 AM
				// slotMaxTime: '23:00:00', // set the maximum time to 1:00 AM
				allDaySlot: false,
				headerToolbar: {
					left: 'dayGridMonth,timeGridWeek,timeGridDay',
					center: 'title',
					right: 'prev,next today'
				},
				buttonText: {
					today: 'Today',
					month: 'Month',
					week: 'Week',
					day: 'Day'
				},
				editable: true,
				droppable: true,
				themeSystem: 'bootstrap',
				eventLimit: true,
                firstDay: 1,
                views: {
                    // timeGridWeek: {
                    //     // Set the first day of the week to Monday (0 is Sunday, 1 is Monday, and so on)
                    //     firstDay: 1
                    // },
                    timeGrid: {
                        eventLimit: 6
                    },
                },
				events: [
                    // {
                    //     title: 'Trip to London',
                    //     start: year + '-' + month + '-01',
                    //     end: year + '-' + month + '-05',
                    //     color: appVariable.color.theme
                    // },
                    // {
                    //     title: 'Lunch with Richard',
                    //     start: year + '-' + month + '-28T14:00:00',
                    //     color: appVariable.color.blue
                    // }, 
                    // {
                    //     title: 'Web Hosting due',
                    //     start: year + '-' + month + '-30',
                    //     color: appVariable.color.blue
                    // }

					{
                        title: 'Juan R',
                        start: '2023-11-27 06:00:00',
                        end: '2023-11-27 14:00:00',
                        color: appVariable.color.theme
                    },
					{
                        title: 'Maria A',
                        start: '2023-11-27 06:00:00',
                        end: '2023-11-27 14:00:00',
                        color: appVariable.color.theme
                    },
					{
                        title: 'Andrea T',
                        start: '2023-11-27 06:00:00',
                        end: '2023-11-27 14:00:00',
                        color: appVariable.color.theme
                    },
					{
                        title: 'Jessica V',
                        start: '2023-11-27 06:00:00',
                        end: '2023-11-27 14:00:00',
                        color: appVariable.color.theme
                    },


					//lunes turno 2
					{
                        title: 'Rocio',
                        start: '2023-11-27 14:00:00',
                        end: '2023-11-27 22:00:00',
                        color: appVariable.color.purple
                    },
					{
                        title: 'Martha A',
                        start: '2023-11-27 14:00:00',
                        end: '2023-11-27 22:00:00',
                        color: appVariable.color.purple
                    },
					{
                        title: 'Lorena P',
                        start: '2023-11-27 14:00:00',
                        end: '2023-11-27 22:00:00',
                        color: appVariable.color.purple
                    },
					{
                        title: 'Laura',
                        start: '2023-11-27 14:00:00',
                        end: '2023-11-27 22:00:00',
                        color: appVariable.color.purple
                    },



					
					{
                        title: 'Juan R',
                        start: '2023-11-28 06:00:00',
                        end: '2023-11-28 14:00:00',
                        color: appVariable.color.theme
                    },
					{
                        title: 'Maria A',
                        start: '2023-11-28 06:00:00',
                        end: '2023-11-28 14:00:00',
                        color: appVariable.color.theme
                    },
					{
                        title: 'Andrea T',
                        start: '2023-11-28 06:00:00',
                        end: '2023-11-28 14:00:00',
                        color: appVariable.color.theme
                    },
					{
                        title: 'Jessica V',
                        start: '2023-11-28 06:00:00',
                        end: '2023-11-28 14:00:00',
                        color: appVariable.color.theme
                    },
                ]
			}
		}
	},
    methods: {
        agregarHorario(){
            var self = this;


            var horario = {
                id: 'event1',
                title: 'Jose Reyes',
                // start: year + '-' + month + '-30',
                start: '2023-11-27 06:00:00',
                end: '2023-11-27 14:00:00',
                duration: '06:00:00',
                color: (getComputedStyle(document.body).getPropertyValue('--bs-blue')).trim()
            }
            self.calendarOptions.events.push(horario)


			horario = {
                id: 'event1',
                title: 'Juan C',
                // start: year + '-' + month + '-30',
                start: '2023-11-27 06:00:00',
                end: '2023-11-27 14:00:00',
                duration: '06:00:00',
                color: (getComputedStyle(document.body).getPropertyValue('--bs-blue')).trim()
            }
            self.calendarOptions.events.push(horario)

			horario = {
                id: 'event1',
                title: 'Pedro R',
                // start: year + '-' + month + '-30',
                start: '2023-11-27 06:00:00',
                end: '2023-11-27 14:00:00',
                duration: '06:00:00',
                color: (getComputedStyle(document.body).getPropertyValue('--bs-blue')).trim()
            }
            self.calendarOptions.events.push(horario)


            horario = {
                id: 'event2',
                title: 'Jose Reyes',
                // start: year + '-' + month + '-30',
                start: '2023-11-28 06:00:00',
                end: '2023-11-28 12:00:00',
                duration: '06:00:00',
                color: (getComputedStyle(document.body).getPropertyValue('--bs-blue')).trim()
            }
            self.calendarOptions.events.push(horario)


            horario = {
                id: 'event3',
                title: 'Jose Reyes',
                // start: year + '-' + month + '-30',
                start: '2023-11-29 06:00:00',
                end: '2023-11-29 12:00:00',
                duration: '06:00:00',
                color: (getComputedStyle(document.body).getPropertyValue('--bs-blue')).trim()
            }
            self.calendarOptions.events.push(horario)

        },
        removeEventById(eventId) {
            const fullCalendarApi = this.$refs.fullCalendar.getApi();
            const removedEvent = fullCalendarApi.getEventById(eventId);

            if (removedEvent) {
                removedEvent.remove();
            }
        },
    },
}
</script>

<style>
    /* Adjust row height */
    /* .fc-timegrid-slots table tbody tr {
      height: 6em;
    } */
  </style>