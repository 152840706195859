<template>
  <div>
    <div class="loading-search" v-if="loading">
      <div class="fa-3x text-center">
        <i class="fas fa-circle-notch fa-spin text-success" style=""></i>
      </div>
    </div>
    <!-- BEGIN breadcrumb -->
    <ol class="breadcrumb float-xl-end">
			<li class="breadcrumb-item"><a href="javascript:;">Reportes</a></li>
			<li class="breadcrumb-item"><a href="javascript:;">Fajillas</a></li>
			<li class="breadcrumb-item active"><a href="javascript:;">Autorizacion Fajillas</a></li>
    </ol>
    <!-- END breadcrumb -->
    <!-- BEGIN page-header -->
    <h1 class="page-header">Autorización Fajillas</h1>
    <!-- END page-header -->



        <form @submit.prevent="search">   
	<div class="row">
      <div class="col-md-8"> 
          <div class="form-group row">
            
            <div class="col-md-3">
                <label class="col-form-label">Fecha inicial</label>
              <div class="input-group">
                <date-picker id="dateTimeInput" v-model="date" :config="options"></date-picker>
                <label for="dateTimeInput" class="input-group-text">
                  <i class="fa fa-calendar"></i>
                </label>
              </div>
            </div>

            
            <!-- <div class="col-md-3">
                <label class="col-form-label">Fecha final</label>
              <div class="input-group">
                <date-picker id="dateTimeInputEnd" v-model="dateEnd" :config="options"></date-picker>
                <label for="dateTimeInputEnd" class="input-group-text">
                  <i class="fa fa-calendar"></i>
                </label>
              </div>
            </div> -->
            <div class="col-md-3">
                <label class="col-form-label">Ciudad</label>
                <select class="form-select" @change="getEstaciones()" v-model="ciudadSelect" :disabled="deshabilitarCombos">
                    <option v-for="ciudad in ciudades" :value="ciudad.Id" v-bind:key="ciudad.Id">
                        {{ ciudad.Nombre }}
                    </option>
                </select>
            </div>
            <div class="col-md-3">
                <label class="col-form-label">Estación</label>
                <select class="form-select" v-model="estacionSelect" :disabled="deshabilitarCombos">
                  <option value="0">Todas</option>
                    <option v-for="estacion in estaciones" :value="estacion.NoEstacion" v-bind:key="estacion.NoEstacion">
                    {{ estacion.Nombre }}
                    </option>
                  </select>
            </div>
            <div class="col-md-3">
                <label class="col-form-label">Turno</label>
                <select v-model="turnoSelected.IdTurno" class="form-control">
                    <option value="0">Todos</option>
                    <option v-for="turno in turnos" :value="turno.IdTurno" v-bind:key="turno.IdTurno">
                    {{ turno.Descripcion }}
                    </option>
                </select>
            </div>


            <!-- <div class="col-md-6">
              <button type="submit" class="btn btn-success me-1 mb-1">{{textoBotonBuscar}}</button>
            </div> -->
          </div>
      </div>
      <div class="col-md-4">
            <button type="submit" class="btn btn-success me-1 mb-1" style="margin-top: 3.1em;">{{textoBotonBuscar}}</button>
      </div>
    </div>

        </form>
    <panel title="Autorización Fajillas" bodyClass="p-0" class="">
      <vue-good-table
        :columns="columns"
        :rows="reportefajillas"
        :lineNumbers="false"
        :search-options="{ enabled: true, placeholder: 'Buscar' }"
        :pagination-options="{ enabled: true, position: 'bottom' }"
        :sort-options="{
          enabled: true,
          initialSortBy: {field: 'EstatusDesc', type: 'desc'}
        }"
      >
        <template slot="table-row" slot-scope="props">
          <span v-if="props.column.label == 'Acciones'">
            <!-- <button class="btn btn-xs btn-success" @click="getArchivos(props.formattedRow, true)">Detalle</button> -->
            <button class="btn btn-xs btn-success" v-if="props.row.Estatus == 1" @click="autorizar(props.row)">Autorizar</button>
          </span>
          
          <span v-else-if="props.column.field == 'Monto'">
            {{  props.row.Monto | currency}}
          </span>
        </template>
      </vue-good-table>
    </panel>
	





	<!-- bottom left vue-notification -->
	<notifications group="top-right" position="top right" :speed="1000" />
  </div>
</template>

<script>

import axios from "axios";
  var numeral = require("numeral");
  import Vue from "vue";
  Vue.filter("formatNumber", function (value) {
      return numeral(value).format("0,00.00"); // displaying other groupings/separators is possible, look at the docs
  });
  
  Vue.filter("currency", function (value) {
      return numeral(value).format("$0,0.00"); 
  });
import moment from 'moment';
export default {
async	created() {
  
	},
  mounted: function () {
    debugger; // eslint-disable-line no-debugger
		var userSetting = JSON.parse(localStorage.getItem('userSettings'));
		this.usuario = userSetting.Usuario;
		this.idUsuario = userSetting.NumeroEmpleado;
		this.nombreLogueado = userSetting.Nombre;
		this.usuarioEstacion = userSetting.Estacion;
		this.IdRol = userSetting.IdRol;
    this.getCiudades();
    this.ciudadSelect = userSetting.CiudadId;
    this.getEstaciones();
    this.estacionSelect = userSetting.Estacion;

    this.GetTurnos();
    if(this.IdRol == 3 || this.IdRol == 7){
      this.deshabilitarCombos = true;
    }

  },
  methods: {
    async GetTurnos(){
      try {
        const response = await axios.get("Catalogos/GetTurnos");
        if (response.data.Success) {
          if (response.data.response.length == 0) {
            this.alertMessage =
              "No se encontraron turnos";
            this.alertTitle = "Error";
            this.show("top-right", "error");
          } else {
            this.turnos = response.data.response;
          }
        } else {
          this.alertMessage = response.data.Message;
          this.alertTitle = "Error";
          this.show("top-right", "error");
        }
      } catch (e) {
        this.alertMessage = "Ocurrio un error";
        this.alertTitle = "Error";
        this.show("top-right", "error");
      }
    },
	async getCiudades(){
		try{
        const response = await axios.get('Catalogos/GetCiudades');
		//debugger; // eslint-disable-line no-debugger
        if(response.data.Success){
          if(response.data.response.length == 0){
            this.alertMessage = "No se encontró información.";
            this.alertTitle = "Error";
            this.show('top-right', 'error');
          }
        }else{
            console.log("error")
            this.alertMessage = response.data.Error;
            this.alertTitle = "Error";
            this.show('top-right', 'error');
        }
        this.ciudades = response.data.response;
      }catch(e){
        //this.alert = true;
        //this.error = 'Invalid email/password';
      }
	},
	async getEstaciones(){
		try{
		debugger; // eslint-disable-line no-debugger
        const response = await axios.post('Estacion/GetEstaciones?ciudad='+this.ciudadSelect);
        if(response.data.Success){
          if(response.data.response.length == 0){
            this.alertMessage = "No se encontró información.";
            this.alertTitle = "Error";
            this.show('top-right', 'error');
          }
        }else{
            console.log("error")
            this.alertMessage = response.data.Error;
            this.alertTitle = "Error";
            this.show('top-right', 'error');
        }
        this.estaciones = response.data.response;
        this.productos = [];
        this.getProductos();
      }catch(e){
        //this.alert = true;
        //this.error = 'Invalid email/password';
      }
	},
    async autorizar(item){
      debugger; // eslint-disable-line no-debugger
      var self = this;
      var arrayDate = item.Fecha.split("T");
      var stringDate = arrayDate[0];
      var intDate = stringDate.replace('-', '').replace('-', '');
      // intDate = intDate.replace('-', '');
      
      var postData = {
              "Data": {
                "FechaInicial":intDate,
                "Estacion": item.NoEstacion,
                "Turno": item.Turno,
                "Isla": item.Isla,
                "IdValor": item.IdValor,
                "Monto": item.Monto,
                "NoEmpleado": item.NoEmpleado,
                "AMEX": 0,
                "Folio": item.Folio,
                "IdCajero": self.idUsuario,
                "NombreCajero":self.nombreLogueado
              }
            };
            
      debugger; // eslint-disable-line no-debugger
      if(confirm("Desea autorizar el Folio "+item.Folio+"?")){
        try {
          const response = await axios.post("Fajilla/AuthFajilla",postData);
          
          if (response.data.Success) {
            
          debugger; // eslint-disable-line no-debugger
            if (response.data.response.length == 0) {
              this.alertMessage = "Error";
              this.alertTitle = "Error";
              this.show("top-right", "error");
            } else {
              
              
              this.alertMessage = "Se autorizó correctamente";
              this.alertTitle = "Autorizado";
              this.show("top-right", "success");
              
              self.getData();
            }
          } else {
          debugger; // eslint-disable-line no-debugger
            this.alertMessage = response.data.Error;
            this.alertTitle = "Error";
            this.show("top-right", "error");
          }
          this.textoBotonBuscar = "Buscar";
        } catch (e) {
            this.alertMessage = "Ocurrio un error";
            this.alertTitle = "Error";
            this.show("top-right", "error");
        }

      }

    },
			show (group, type = '') {
				const text = this.alertMessage;
				this.$notify({
					group,
					title: this.alertTitle,
					text,
					type,
					data: {
						randomNumber: Math.random()
					}
				})
			},
			clean (group) {
				this.$notify({ group, clean: true })
			},
			swalNotification(swalType) {
				var btnClass = (swalType == 'error') ? 'danger' : swalType;
					btnClass = (swalType == 'question') ? 'primary' : btnClass;
				this.$swal({
					title: 'Are you sure?',
					text: 'You will not be able to recover this imaginary file!',
					type: swalType,
					showCancelButton: true,
					buttonsStyling: false,
					confirmButtonText: swalType.charAt(0).toUpperCase() + swalType.substr(1).toLowerCase(),
					cancelButtonText: 'Cancel',
					confirmButtonClass: 'btn me-5px btn-'+ btnClass +'',
					cancelButtonClass: 'btn btn-default',
				})
			},
      async getData(){
        
        debugger; // eslint-disable-line no-debugger
        var self = this;
      self.loading = true;
      self.textoBotonBuscar = "Buscando...";
      try{
        self.intDate = self.date.replace('/', '');
        self.intDate = self.intDate.replace('/', '');


        var estacion = self.estacionSelect!=undefined?self.estacionSelect:0;

        var turno = self.turnoSelected != undefined ? self.turnoSelected.IdTurno : 0;

        const response = await axios.post('Fajilla/GetFajillasADDES' ,{
        "Data": {
            "FechaInicial": self.intDate,
            "FechaFinal":self.intDate, 
            "Estacion": estacion ,
            "Turno": turno
            }
        });
        if(response.data.Success){
          if(response.data.response.length == 0){
            self.alertMessage = "No se encontro información del día especificado.";
            self.alertTitle = "Error";
            self.show('top-right', 'error');
          }
        }else{
            console.log("error")
            self.alertMessage = response.data.Message;
            self.alertTitle = "Error";
            self.show('top-right', 'error');
        }
        
        self.reportefajillas = response.data.response;

      self.textoBotonBuscar = "Buscar";
      
      self.loading = false;
      }catch(e){
      self.loading = false;
        //this.alert = true;
        //this.error = 'Invalid email/password';
      }

      },
    async search(e) {
      e.preventDefault();
      this.getData();
    },
  },
  data() {
    return {
      turnos:[],
      turnoSelected:{
          IdTurno:0
      },
      loading:false,
      nombreLogueado:"",
      idUsuario:undefined,
      reportefajillas:[],
      deshabilitarCombos:false,
      ciudades: [],
      estaciones: [],
      ciudadSelect: 1,
      estacionSelect: 0,
      ventaDetalle:[],
      emailReenviar:"",
      uuidReenviar:"",
      date: moment().format("YYYY/MM/DD"),
      intDateEnd: "",
      dateEnd: moment().format("YYYY/MM/DD"),
      intDate: "",
      options: {
        format: 'YYYY/MM/DD',
        useCurrent: false,
      },
      puestoSelected:{
          puesto:0
      },
      rolSelected:{
          rol:0
      },
      actualizarNIP:false,
      puestosSelect:[],
      rolesSelect:[],
      nuevoCorreo: '',
      clienteNuevo: true,
      clienteModal:{},
      textoBotonGuardar: "Guardar",
      textoBotonBuscar: "Buscar",
      clienteBuscar: "",
      columns: [
        {
          label: "Acciones",
          field: "Acciones",
          width: '150px',
          tdClass: "text-center text-nowrap",
          thClass: "text-center text-nowrap",
        },
        {
            label: "Estatus",
            field: "EstatusDesc",
            tdClass: "text-center text-nowrap",
            thClass: "text-center text-nowrap",
        },
        {
            label: "No Empleado",
            field: "NoEmpleado",
            tdClass: "text-center text-nowrap",
            thClass: "text-center text-nowrap",
        },
        {
            label: "Nombre",
            field: "Nombre",
            tdClass: "text-center text-nowrap",
            thClass: "text-center text-nowrap",
        },
        {
            label: "Folio",
            field: "Folio",
            tdClass: "text-center text-nowrap",
            thClass: "text-center text-nowrap",
        },
        {
            label: "Fecha",
            field: "Fecha",
            tdClass: "text-center text-nowrap",
            thClass: "text-center text-nowrap",
        },
        {
            label: "Monto",
            field: "Monto",
            type: "number",
            tdClass: " text-nowrap",
            thClass: "text-center text-nowrap",
        },
        {
            label: "Turno",
            field: "Turno",
            tdClass: "text-center text-nowrap",
            thClass: "text-center text-nowrap",
        },
        {
            label: "Bomba",
            field: "Bomba",
            tdClass: "text-center text-nowrap",
            thClass: "text-center text-nowrap",
        },
        {
            label: "Moneda",
            field: "Moneda",
            tdClass: "text-center text-nowrap",
            thClass: "text-center text-nowrap",
        },
        {
            label: "Cajero",
            field: "Cajero",
            tdClass: "text-center text-nowrap",
            thClass: "text-center text-nowrap",
        },
        {
            label: "Fecha Autorizacion",
            field: "FechaAutorizacion",
            tdClass: "text-center text-nowrap",
            thClass: "text-center text-nowrap",
        }
      ],
      items: [],
    };
  },
};
</script>
<style scoped>
.loading-search{
  position: absolute;
    width: 100%;
    left: 0px;
    height: 100%;
    background: rgba(0, 0, 0, 0.1);
    z-index: 1000;
    padding-top: 150px;
    top: 0;
}
.textRight{
  text-align: right !important;
}
</style>