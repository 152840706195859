<template>
	<div>
		<!-- BEGIN breadcrumb -->
		<ol class="breadcrumb float-xl-end">
			<li class="breadcrumb-item"><a href="javascript:;">Reportes</a></li>
			<li class="breadcrumb-item"><a href="javascript:;">CRE</a></li>
			<li class="breadcrumb-item active"><a href="javascript:;">XML CRE</a></li>
		</ol>
		<!-- END breadcrumb -->
		<!-- BEGIN page-header -->
		<h1 class="page-header">XML  CRE <small>.</small></h1>
    <!-- <button class="btn" v-on:click="redirectTanques()">tanques</button> -->
		<!-- END page-header -->
		<!-- <a href="javascript:;" v-on:click="show('top-right', 'warn')" class="btn btn-sm btn-primary">Demo</a> -->
		
    <div class="row">
      <div class="col-md-4">
        <form @submit.prevent="search">    
          <div class="form-group row">
            <div class="col-md-6">
              <div class="input-group">
                <date-picker id="dateTimeInput" v-model="date" :config="options"></date-picker>
                <label for="dateTimeInput" class="input-group-text">
                  <i class="fa fa-calendar"></i>
                </label>
              </div>
            </div>
            <div class="col-md-4">
              <button type="submit" class="btn btn-success me-1 mb-1 pull-right">{{textoBotonBuscar}}</button>
            </div>
          </div>
        </form>
      </div>
      <div class="col-md-8"></div>
    </div>


    <DxDataGrid
      id="gridContainer"
      :data-source="reporteCre"
      key-expr="CodGas"
      :show-borders="true"
      @exporting="onExporting"
    >
      <DxGroupPanel :visible="true"/>
      <DxGrouping :auto-expand-all="true"/>
      <DxExport
        :enabled="true"
        :allow-export-selected-data="false"
      />
      <DxSelection mode="false"/>
      <DxColumn data-field="Plaza"/>
      <DxColumn data-field="CodGas"/>
      <DxColumn data-field="Estacion"/>
      <DxColumn data-field="RFC"/>
      <DxColumn data-field="Empresa"/>
      <DxColumn data-field="PermisoCRE"/>
      <DxColumn data-field="CodProducto"/>
      <DxColumn data-field="Producto"/>
      <DxColumn data-field="VtasDia"/>
      <DxColumn data-field="InvInicial"/>
      <DxColumn data-field="InvFinal"/>
      <DxColumn data-field="VolCompra"/>
      <DxColumn data-field="PrecioCompra"/>
      <DxColumn data-field="PrecioCompraDec"/>
      <DxColumn data-field="Flete"/>
      <DxColumn data-field="Merma"/>
    </DxDataGrid>


		<!-- bottom left vue-notification -->
		<notifications group="top-right" position="top right" :speed="1000" />
	</div>
</template>

<script>

import axios from 'axios';
import moment from 'moment';
import { DxDataGrid, DxColumn, DxExport, DxSelection, DxGroupPanel, DxGrouping } from 'devextreme-vue/data-grid';
import { Workbook } from 'exceljs';
import { saveAs } from 'file-saver';
// Our demo infrastructure requires us to use 'file-saver-es'. We recommend that you use the official 'file-saver' package in your applications.
import { exportDataGrid } from 'devextreme/excel_exporter';
//import service from './data.js';

export default {
	beforeCreate() {
    // var userSetting = localStorage.getItem('userSettings');
    // if(userSetting != undefined){
    //   console.log(JSON.parse(userSetting));
    //   this.pageUserSettings = JSON.parse(userSetting);
    // }else{
		// 	this.$router.push('/login');
    // }
	},
	created() {
		// console.log('created');
	},
	beforeMount() {
		// console.log('beforeMount');
	},
	mounted() {
		// console.log('mounted');
	},
	components: {
		DxDataGrid, DxColumn, DxExport, DxSelection, DxGroupPanel, DxGrouping
	},
  methods: {
    redirectTanques(){
      
          this.$router.push({ path: '/tanques' });
    },
			show (group, type = '') {
				const text = this.alertMessage;
				this.$notify({
					group,
					title: this.alertTitle,
					text,
					type,
					data: {
						randomNumber: Math.random()
					}
				})
			},
			clean (group) {
				this.$notify({ group, clean: true })
			},
			swalNotification(swalType) {
				var btnClass = (swalType == 'error') ? 'danger' : swalType;
					btnClass = (swalType == 'question') ? 'primary' : btnClass;
				this.$swal({
					title: 'Are you sure?',
					text: 'You will not be able to recover this imaginary file!',
					type: swalType,
					showCancelButton: true,
					buttonsStyling: false,
					confirmButtonText: swalType.charAt(0).toUpperCase() + swalType.substr(1).toLowerCase(),
					cancelButtonText: 'Cancel',
					confirmButtonClass: 'btn me-5px btn-'+ btnClass +'',
					cancelButtonClass: 'btn btn-default',
				})
			},
    onExporting(e) {
      const workbook = new Workbook();
      const worksheet = workbook.addWorksheet('ReporteCRE');

      exportDataGrid({
        component: e.component,
        worksheet: worksheet,
        autoFilterEnabled: true
      }).then(() => {
        workbook.xlsx.writeBuffer().then((buffer) => {
          saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'DataGrid.xlsx');
        });
      });
      e.cancel = true;
    },
    async search(e){
      e.preventDefault();
      this.textoBotonBuscar = "Buscando...";
      try{
        this.intDate = this.date.replace('/', '');
        this.intDate = this.intDate.replace('/', '');
        const response = await axios.post('CRE/GetReporteCRE?fecha='+this.intDate ,{
          fecha: this.intDate 
        });
        if(response.data.Success){
          if(response.data.response.length == 0){
            this.alertMessage = "No se erncontro información del día especificado.";
            this.alertTitle = "Error";
            this.show('top-right', 'error');
          }
        }else{
            console.log("error")
            this.alertMessage = response.data.Error;
            this.alertTitle = "Error";
            this.show('top-right', 'error');
        }
        console.log(response);
        this.reporteCre = response.data.response;

      this.textoBotonBuscar = "Buscar";
      }catch(e){
        //this.alert = true;
        //this.error = 'Invalid email/password';
      }
    }
  },
	data() {
		return {
      pageUserSettings: {},
      alertMessage: "",
      alertTitle: "",
      reporteCre: [],
      textoBotonBuscar: "Buscar",
      date: moment().format("YYYY/MM/DD"),
      intDate: "",
      options: {
        format: 'YYYY/MM/DD',
        useCurrent: false,
      }
		};
	}
}
</script>
<style scoped>
.dx-datagrid-header-panel{
  display: none !important;
}
</style>